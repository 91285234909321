import React from 'react'
import { View, Image } from 'react-native'
import PropTypes from 'prop-types'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Button, Typo } from '../../../../components'
import metrics from '../../../../theme/metrics'
import colors from '../../../../theme/colors'
import { I18n } from 'aws-amplify'

const PreIntroduction = ({ onPress }) => {
  const insets = useSafeAreaInsets()
  return (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 20 + insets.top,
        backgroundColor: colors.primaryGreen
      }}
    >
      <View style={{ flex: 1 }}>
        <Typo.H1White translate>challenge.numeracy.preintroduction.title</Typo.H1White>
        <Typo.T1White marginTop={24} translate>
          challenge.numeracy.preintroduction.text_01
        </Typo.T1White>
        <Typo.T1White marginTop={16} translate>
          challenge.numeracy.preintroduction.text_02
        </Typo.T1White>
        <Typo.T1White marginTop={16} translate>
          challenge.numeracy.preintroduction.text_03
        </Typo.T1White>
      </View>
      <View style={{ paddingBottom: 24 + insets.bottom }}>
        <Button
          type='white'
          onPress={onPress}
          title={I18n.get('challenge.numeracy.preintroduction.cta')}
          marginHorizontal={12}
        />
      </View>
    </View>
  )
}

PreIntroduction.propTypes = {
  onPress: PropTypes.func.isRequired
}

PreIntroduction.defaultProps = {}

export default PreIntroduction
