import { ANSWER } from './schema/answer'
import { CAREER } from './schema/career'
import { CHALLENGE_CONFIG } from './schema/challenge-config'
import { PARTNER } from './schema/partner'
import { REACH_REQUEST } from './schema/reach-request'
import { SPACE } from './schema/space'
import { SYSTEM } from './schema/system'
import { USER } from './schema/user'

export const searchNewMatchingCareersFromBasicCampaigns = /* GraphQL */ `
  query SearchNewMatchingCareersFromBasicCampaigns {
    searchNewMatchingCareersFromBasicCampaigns {
      campaign_id
      matching_score
    }
  }
`
export const generatePdf = /* GraphQL */ `
  query GeneratePdf(
    $user_id: ID
    $career_id: ID
    $type: String
    $partner_user_id: ID
    $partner_id: ID
    $show_old_ui: Boolean
    $language: String
  ) {
    generatePdf(
      user_id: $user_id
      career_id: $career_id
      type: $type
      partner_user_id: $partner_user_id
      partner_id: $partner_id
      show_old_ui: $show_old_ui
      language: $language
    )
  }
`
export const matchMeIfYouCan = /* GraphQL */ `
  query MatchMeIfYouCan($partner_id: ID, $user_id: ID) {
    matchMeIfYouCan(partner_id: $partner_id, user_id: $user_id)
  }
`
export const getMatchingCareers = /* GraphQL */ `
  query GetMatchingCareers(
    $partner_id: String
    $minMatchingScore: Int
    $riasec: AWSJSON
    $educational_level: String
    $limit: Int
    $user_level: Int
    $getUnseen: Boolean
  ) {
    getMatchingCareers(
      partner_id: $partner_id
      minMatchingScore: $minMatchingScore
      riasec: $riasec
      educational_level: $educational_level
      limit: $limit
      user_level: $user_level
      getUnseen: $getUnseen
    )
  }
`
export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) ${SYSTEM}
  }
`
export const spacesByOwner = /* GraphQL */ `
  query SpacesByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${SPACE}
      nextToken
    }
  }
`
export const listReachRequestsByOwnerByDate = /* GraphQL */ `
  query ListReachRequestsByOwnerByDate(
    $owner: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReachRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReachRequestsByOwnerByDate(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${REACH_REQUEST}
      nextToken
    }
  }
`
export const getChallengeConfig = /* GraphQL */ `
  query GetChallengeConfig($id: ID!) {
    getChallengeConfig(id: $id) ${CHALLENGE_CONFIG}
  }
`
export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) ${CAREER}
  }
`
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) ${PARTNER}
  }
`
export const userControl = /* GraphQL */ `
  query UserControl(
    $user_type: USER_TYPES
    $company_name: String
    $lead_id: ID
    $ref_link: String
    $firstname: String
    $lastname: String
    $role: String
    $groupname: String
    $email: String
    $action: String!
    $action_settings: ActionSettingsInput
  ) {
    userControl(
      user_type: $user_type
      company_name: $company_name
      lead_id: $lead_id
      ref_link: $ref_link
      firstname: $firstname
      lastname: $lastname
      role: $role
      groupname: $groupname
      email: $email
      action: $action
      action_settings: $action_settings
    )
  }
`

export const customGetSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) ${SPACE}
  }
`

export const customUniversalAppInit = /* GraphQL */ `
  query UniversalAppInit($user_id: ID, $ref_link: String, $lang: String) {
    universalAppInit(user_id: $user_id, ref_link: $ref_link, lang: $lang) {
      partner ${PARTNER}
      career ${CAREER}
      space ${SPACE}
      user ${USER}
      invitation_status
      answers ${ANSWER.replace('}', '')}
        aivy_output
      }
    }
  }
`

export const customGetPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) ${PARTNER}
  }
`

export const spaceControl = /* GraphQL */ `
  query SpaceControl($action: String!, $user_id: ID, $space_id: ID) {
    spaceControl(action: $action, user_id: $user_id, space_id: $space_id)
  }
`
// Without Person and comment
export const customUpdateSpace = `
  mutation UpdateSpace($input: UpdateSpaceInput!, $condition: ModelSpaceConditionInput) {
    updateSpace(input: $input, condition: $condition) ${SPACE}
  }
`

export const pinpointControl = /* GraphQL */ `
  query PinpointControl(
    $action: String!
    $channel_type: CHANNEL_TYPES
    $user_id: ID!
    $topic: PinpointTopics
    $address: String
    $user_attributes: AWSJSON
    $attributes: AWSJSON
    $endpoint_attributes: AWSJSON
    $location: PinpointControlLocationInput
    $demographic: PinpointControlDemographicInput
  ) {
    pinpointControl(
      action: $action
      channel_type: $channel_type
      user_id: $user_id
      topic: $topic
      address: $address
      user_attributes: $user_attributes
      attributes: $attributes
      endpoint_attributes: $endpoint_attributes
      location: $location
      demographic: $demographic
    )
  }
`

export const helperControl = /* GraphQL */ `
  query HelperControl($user_id: ID, $career_id: ID, $challenge_id: ID, $action: String) {
    helperControl(
      user_id: $user_id
      career_id: $career_id
      challenge_id: $challenge_id
      action: $action
    )
  }
`
