import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { StyleSheet, View, Animated, Platform } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import CountDownTimer from '../../../components/challenge/CountDownTimer'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import Typo from '../../../components/Typo'
import TimeIcon from '../../../components/svgs/TimeIcon'
import { CELL_GUTTER, CELL_SIZE, TIME_PER_LEVEL } from './data/path_finder'
import colors from '../../../theme/colors'
import CellElement from './CellElement'
import { AuthContext } from '../../../context'
import { trackEvent } from '../../../utils/tracking'

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.darkBlack,
    flex: 1,
    justifyContent: 'center'
  },
  row: {
    flexDirection: 'row',
    paddingHorizontal: CELL_GUTTER / 2
  },
  cell: {
    width: CELL_SIZE,
    height: CELL_SIZE,
    padding: CELL_GUTTER / 2
  },
  levelHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    top: -33,
    left: 16
  },
  separator: {
    marginHorizontal: 15,
    width: 1,
    height: 14,
    backgroundColor: 'rgba(77, 123, 243, 0.17)'
  }
})

const Level = ({ level, onLevelComplete, testRound, currentLevelIndex }) => {
  const { rootStore } = useContext(AuthContext)

  const isFirstRun = useRef(true)
  const validations = useRef(level.validations)
  const timeInSeconds = useRef(level.timeInSeconds)
  const transitionAnim = useRef(new Animated.Value(0))
  const countDownTimer = useRef()

  const [draws, setDraws] = useState(0)
  const [levelCompleted, setLevelCompleted] = useState(false)
  const [validatedPathIndex, setValidatedPathIndex] = useState(0)

  const [matrix, setMatrix] = useState(JSON.parse(JSON.stringify(level.matrix)))

  const isPlanning = useRef(true)
  const currentDraws = useRef(null)

  const t0_time_planning = useRef(null) // useRef prevent reinit
  const t1_time_planning = useRef(null) // useRef prevent reinit

  const t0_time_solving = useRef(null) // useRef prevent reinit
  const t1_time_solving = useRef(null) // useRef prevent reinit

  const startLevel = useCallback(() => {
    setLevelCompleted(false)
    countDownTimer.current && countDownTimer.current.resetTimer(timeInSeconds.current + 0.7)
    Animated.sequence([
      Animated.delay(400),
      Animated.spring(transitionAnim.current, {
        toValue: 1,
        duration: 400,
        useNativeDriver: Platform.OS !== 'web'
      })
    ]).start(() => {
      // START LEVEL
      t0_time_planning.current = new Date() // Start Tracking MS Planning
    })
  }, [])

  useEffect(() => {
    startLevel()
  }, [startLevel])

  useEffect(() => {
    if (!countDownTimer.current) return

    rootStore.pauseStore.active && countDownTimer.current.pauseTimer()
    !rootStore.pauseStore.active && countDownTimer.current.resumeTimer()
  }, [rootStore.pauseStore.active])

  const onLevelSuccess = () => {
    setLevelCompleted(true)
    t1_time_solving.current = new Date() // Tracking End for Solving

    let time_planning = t1_time_planning.current - t0_time_planning.current
    time_planning = time_planning >= 0 ? time_planning : 0
    const time_solving = t1_time_solving.current - t0_time_solving.current
    const time_needed_in_ms = time_planning + time_solving

    const time_total = time_needed_in_ms > 60000 ? 60000 : time_needed_in_ms
    const problem_solving = 60000 - time_total // Durchschnitt der verbleibende Sekunden bis zum Timeout pro Level. Briefing

    Animated.sequence([
      Animated.delay(400),
      Animated.spring(transitionAnim.current, {
        toValue: 0,
        duration: 400,
        useNativeDriver: Platform.OS !== 'web'
      })
    ]).start(() => {
      onLevelComplete({
        time_needed_in_ms,
        time_planning,
        time_solving,
        problem_solving,
        success: true,
        draws: currentDraws.current,
        score: Math.round(problem_solving / 1000)
      })
    })
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    if (isPlanning.current) {
      t1_time_planning.current = new Date() // Tracking End for time_planning
      t0_time_solving.current = new Date() // Start Tracking MS for Time_Solving
      isPlanning.current = false
    }

    let validationPassed = false
    validations.current.forEach((validation, idx) => {
      validationPassed =
        validation.filter((v) => matrix[v[0]][v[1]][1] === v[2]).length === validation.length
      if (validationPassed) {
        setValidatedPathIndex(idx)
        onLevelSuccess()
      }
    })
  }, [matrix]) // eslint-disable-line

  const onRotate = (newState, rowIndex, cellIndex) => {
    const tempMatrix = JSON.parse(JSON.stringify(matrix))

    tempMatrix[rowIndex][cellIndex][1] = newState
    setMatrix(tempMatrix) // validate state in use effect

    currentDraws.current = draws + 1
    setDraws(draws + 1)
  }

  const onTimeIsUp = () => {
    const time_planning =
      t1_time_planning.current === null
        ? TIME_PER_LEVEL * 1000
        : t1_time_planning.current - t0_time_planning.current

    Animated.sequence([
      Animated.delay(400),
      Animated.spring(transitionAnim.current, {
        toValue: 0,
        duration: 400,
        useNativeDriver: Platform.OS !== 'web'
      })
    ]).start(() => {
      onLevelComplete({
        success: false,
        draws: currentDraws.current || 0,
        score: 0,
        time_needed_in_ms: 60000,
        time_planning,
        problem_solving: 0,
        time_solving: TIME_PER_LEVEL * 1000 - time_planning
      })
    })
  }

  const renderLeftHeader = () => {
    if (testRound) return null

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TimeIcon color='#fff' containerStyle={{ marginRight: 4 }} />
        <CountDownTimer
          ref={countDownTimer}
          timeToShow={['M', 'S']}
          labelM=''
          labelS=''
          digitBgColor={colors.mainColor}
          until={level.timeInSeconds}
          digitTxtColor='#fff'
          onFinish={onTimeIsUp}
        />
        <View style={styles.separator} />
        <Typo.ButtonWhite>
          {`${I18n.get('challenge.tower_of_london.moves')}: ${draws}`}
        </Typo.ButtonWhite>
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <ChallengeHeader
        title={testRound ? I18n.get('global.challenge.testround') : undefined}
        onPress={() => {
          trackEvent('ChallengePaused', { exam_id: 'PATH_FINDER' })
          rootStore.pauseStore.start()
        }}
        titleStyle={{ color: '#fff' }}
        leftHeader={renderLeftHeader()}
      />

      <View key='matrix' style={styles.wrapper}>
        {matrix.map((row, rowIndex) => (
          <View key={`${currentLevelIndex}-${rowIndex}`} style={styles.row}>
            {row.map((cell, cellIndex) => (
              <Animated.View
                key={cellIndex}
                style={[styles.cell, { transform: [{ scale: transitionAnim.current }] }]}
              >
                {cell[0] && (
                  <CellElement
                    index={cell[0]}
                    initialState={cell[1]}
                    belongsToCorrectPath={
                      level.validations[validatedPathIndex]
                        ? level.validations[validatedPathIndex].some(
                            (v) => v[0] === rowIndex && v[1] === cellIndex
                          )
                        : false
                    }
                    levelCompleted={levelCompleted}
                    onRotate={(newState) => onRotate(newState, rowIndex, cellIndex)}
                  />
                )}
              </Animated.View>
            ))}
          </View>
        ))}
      </View>
    </View>
  )
}

Level.propTypes = {
  level: PropTypes.object.isRequired,
  onLevelComplete: PropTypes.func.isRequired,
  testRound: PropTypes.bool.isRequired,
  currentLevelIndex: PropTypes.number.isRequired
}

export default observer(Level)
