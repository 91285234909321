import React from 'react'
import { Platform, View } from 'react-native'
import {
  Circle,
  Path,
  Defs,
  LinearGradient,
  Stop,
  G
  // Text,
  // TSpan,
  // ForeignObject
} from 'react-native-svg'
import { AreaChart as AreaChartRN, Grid } from 'react-native-svg-charts'
import * as shape from 'd3-shape'
import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'
import Typo from '../../../Typo'
// import Typo from '../../../Typo'

const Gradient = ({ index }) => (
  <Defs key={index}>
    <LinearGradient id='gradient' x1='0%' y1='38.957%' x2='106.209%' y2='38.957%'>
      <Stop stopColor='#29CCC7' stopOpacity={0.04} offset='0%' />
      <Stop stopColor='#29CCC7' stopOpacity={0.28} offset='47.819%' />
      <Stop stopColor='#6DFFFB' stopOpacity={0.27} offset='100%' />
    </LinearGradient>
  </Defs>
)

Gradient.propTypes = {
  index: PropTypes.number
}

Gradient.defaultProps = {
  index: undefined
}

const DashedLine = ({ x, y, data, percent }) => {
  if (Platform.OS === 'web') return null
  return data.map((value, index) => {
    if (index === percent - 1 && percent > 6 && percent < 92) {
      return (
        <Path
          key={index}
          d='M.5 200.072V-1.5'
          stroke='#29CCC7'
          fill='none'
          fillRule='evenodd'
          strokeDasharray='2 4'
          strokeLinecap='square'
          x={x(index)}
          y={y(value) + 4}
        />
      )
    }

    return null
  })
}

DashedLine.propTypes = {
  x: PropTypes.func,
  y: PropTypes.func,
  data: PropTypes.array,
  percent: PropTypes.number.isRequired
}

DashedLine.defaultProps = {
  x: () => {},
  y: () => {},
  data: []
}

const Decorator = ({ x, y, data, percent }) =>
  data.map((value, index) => {
    if (index === percent - 1) {
      return (
        <G key={index}>
          {/* <G x={x(index) - 12} y={y(value) - 45}>
            <Path
              d='M16.25 24l-3.75 5-3.75-5H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h39a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H16.25z'
              fill='#29CCC7'
            />
            {Platform.OS === 'web' ? (
              <Text fontSize={14} fill='#fff' fontFamily='fira-sans-medium'>
                <TSpan x={8} y={17}>
                  {`${percent}%`}
                </TSpan>
              </Text>
            ) : (
              <ForeignObject key={percent} x={8} y={1}>
                <Typo.ButtonWhite>{`${percent}%`}</Typo.ButtonWhite>
              </ForeignObject>
            )}
          </G> */}

          <G fill='none' fillRule='evenodd'>
            <Circle
              strokeOpacity={0.205}
              stroke='#3BD4C7'
              fillOpacity={0.099}
              fill='#29CCC7'
              cx={x(index)}
              cy={y(value)}
              r={12.5}
            />
            <Circle fill='#29CCC7' cx={x(index)} cy={y(value)} r={5} />
            <Circle fill='#FFF' cx={x(index)} cy={y(value)} r={2} />
          </G>
        </G>
      )
    }
    return null
  })

Decorator.propTypes = {
  x: PropTypes.func,
  y: PropTypes.func,
  data: PropTypes.array,
  percent: PropTypes.number.isRequired
}

Decorator.defaultProps = {
  x: () => {},
  y: () => {},
  data: []
}

const AreaChart = ({ containerStyle, data, percent, labels }) => {
  const contentInsetBottom = percent > 16 && percent < 84 ? 0 : 20

  return (
    <View style={containerStyle}>
      <AreaChartRN
        style={{ height: 150 }}
        data={data}
        contentInset={{
          top: 50,
          left: 8,
          right: 8,
          bottom: 16
        }}
        svg={{ fill: 'url(#gradient)' }}
        curve={shape.curveNatural}
        numberOfTicks={2}
      >
        <Grid svg={{ strokeWidth: 1, stroke: colors.lightGrey }} />
        <Gradient />
        {percent && <Decorator percent={percent} />}
        {contentInsetBottom ? null : <DashedLine percent={percent} />}
      </AreaChartRN>
      {labels && labels.left !== 'ADD_TRANSLATION' && (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typo.T2LightBlack>{labels.left}</Typo.T2LightBlack>
          <Typo.T2LightBlack>{labels.right}</Typo.T2LightBlack>
        </View>
      )}
    </View>
  )
}

AreaChart.propTypes = {
  containerStyle: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  percent: PropTypes.number.isRequired,
  labels: PropTypes.object
}

AreaChart.defaultProps = {
  labels: undefined
}

export default AreaChart
