import React, { useMemo } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'

import Typo from '../../../Typo'
import { notifyBugsnag } from '../../../../utils/bugsnag'

// EXAM_ID: [ TEXT_SMALLER_50, TEXT_GREATER_THAN_EQUAL_50 ]
const JOB_MATCH = {
  DELEGATION: [
    'challenge.delegation.result.job_match_text_0',
    'challenge.delegation.result.job_match_text_1'
  ],
  PATH_FINDER: [
    'challenge.path_finder.result.job_match_text_0',
    'challenge.path_finder.result.job_match_text_1'
  ],
  EMOTIONS_GAME: [
    'challenge.emotions_game.result.job_match_text_0',
    'challenge.emotions_game.result.job_match_text_1'
  ],
  TOWER_OF_LONDON: [
    'challenge.tower_of_london.result.job_match_text_0',
    'challenge.tower_of_london.result.job_match_text_1'
  ],
  ANALOGICAL_REASONING: [
    'challenge.analogical_reasonning.result.job_match_text_0',
    'challenge.analogical_reasonning.result.job_match_text_1'
  ],
  DIGIT_SPAN: [
    'challenge.digit_span.result.job_match_text_0',
    'challenge.digit_span.result.job_match_text_1'
  ],
  FLANKER: [
    'challenge.flanker.result.job_match_text_0',
    'challenge.flanker.result.job_match_text_1'
  ],
  NBACK: ['challenge.nback.result.job_match_text_0', 'challenge.nback.result.job_match_text_1'],
  NUMERACY: [
    'challenge.numeracy.result.job_match_text_0',
    'challenge.numeracy.result.job_match_text_1'
  ]
}

const s = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: '#B6C7F2',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5
  }
})

const JobMatch = ({ exam_id, score, containerStyle }) => {
  const text = useMemo(() => {
    if (score < 50) return I18n.get(JOB_MATCH[exam_id][0])
    if (score >= 50) return I18n.get(JOB_MATCH[exam_id][1])

    notifyBugsnag(new Error(`Job-Match Text nicht gefunden - exam_id: ${exam_id}, score: ${score}`))
    return null
  }, [exam_id, score])

  if (text === 'ADD_TRANSLATION' || !text) return null

  return (
    <View style={[s.container, containerStyle]}>
      <Typo.H4Black translate>challenge.result.job_match_title</Typo.H4Black>
      <View style={{ alignItems: 'center' }}>
        <Image
          style={{ width: 96, height: 128, marginTop: 16 }}
          resizeMode='contain'
          source={require('../../../../assets/images/illustrations/paint-artist-80.png')}
        />
      </View>
      <Typo.T2Black marginTop={24}>{text}</Typo.T2Black>
    </View>
  )
}

JobMatch.propTypes = {
  exam_id: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  containerStyle: PropTypes.object.isRequired
}

export default JobMatch
