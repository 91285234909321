import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'
import Typo from './Typo'
import colors from '../theme/colors'
import { replaceTranslationKey } from '../utils/helpers'
import { AuthContext, NotificationContext } from '../context'
import { notifyBugsnag } from '../utils/bugsnag'
import { trackEvent } from '../utils/tracking'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: colors.oceanDarkBlue,
    borderRadius: 18,
    paddingVertical: 16,
    justifyContent: 'space-between',
    shadowColor: colors.mediumGrey,
    shadowOffset: {
      width: 0,
      height: 15
    },
    shadowOpacity: 0.2,
    shadowRadius: 28,

    elevation: 14
  },
  labelStyle: {
    marginBottom: 4,
    height: 32,
    backgroundColor: 'rgba(255,255,255,0.15)',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    paddingHorizontal: 12,
    borderRadius: 8
  },
  emojiStyle: {
    height: 38,
    width: 38,
    resizeMode: 'contain'
  }
})

const RATINGS = [
  { emoji: require('../assets/images/rating/1.png'), value: 1 },
  { emoji: require('../assets/images/rating/2.png'), value: 2 },
  { emoji: require('../assets/images/rating/3.png'), value: 3 },
  { emoji: require('../assets/images/rating/4.png'), value: 4 },
  { emoji: require('../assets/images/rating/5.png'), value: 5 }
]

const RatingListItem = ({ challenge, answer, containerStyles }) => {
  const { rootStore } = useContext(AuthContext)
  const { success, error } = useContext(NotificationContext)
  const isMounted = useRef(true)

  const { language } = rootStore.userStore

  const [hide, setHide] = useState(false)

  useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  const onChallengeRating = async (rating) => {
    try {
      await rootStore.answersStore.updateAnswer({
        data: { id: answer.id, rating },
        owner: rootStore.userStore.username
      })

      trackEvent('ChallengeRated', { exam_id: challenge.exam_id, rating })
      success('global.rating_list_item.update_success')

      if (isMounted.current) setHide(true)
    } catch (err) {
      error('global.rating_list_item.update_error')
      notifyBugsnag(err)
    }
  }

  if (answer.rating || hide) return null

  return (
    <View style={[styles.container, containerStyles]}>
      <View style={{ paddingLeft: 16 }}>
        <View style={styles.labelStyle}>
          <Typo.T2White translate>global.rating_list_item.header</Typo.T2White>
        </View>
        <View style={{ width: '80%' }}>
          <Typo.H1White marginBottom={4}>
            {replaceTranslationKey(
              I18n.get('global.rating_list_item.question'),
              '{{challengeName}}',
              I18n.get(challenge.title[language])
            )}
          </Typo.H1White>
        </View>
        <Typo.H3White style={{ width: '68%' }} translate>
          global.challenge.feedback.explain
        </Typo.H3White>
      </View>
      <View style={{ paddingHorizontal: 16 }}>
        <View
          style={{
            flexDirection: 'row',
            paddingTop: 24,
            marginBottom: 8,
            justifyContent: 'space-evenly'
          }}
        >
          {RATINGS.map((rating, index) => (
            <TouchableOpacity key={index} onPress={() => onChallengeRating(index + 1)}>
              <Image source={rating.emoji} style={styles.emojiStyle} />
            </TouchableOpacity>
          ))}
        </View>
        {!rootStore.spaceStore.isAivySelect && (
          <TouchableOpacity onPress={() => setHide(true)}>
            <Typo.ButtonWhite center style={{ color: colors.brightGreen }} translate>
              global.rating_list_item.do_it_later
            </Typo.ButtonWhite>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}
RatingListItem.propTypes = {
  challenge: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  containerStyles: PropTypes.object
}

RatingListItem.defaultProps = {
  containerStyles: {}
}

export default RatingListItem
