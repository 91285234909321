import React, { useState, useEffect, useContext, useRef } from 'react'
import { AppState, BackHandler, Platform, View, StyleSheet, ActivityIndicator } from 'react-native'
import { BlurView } from 'expo-blur'
import PropTypes from 'prop-types'
import { useNavigation } from '@react-navigation/native'

import { observer } from 'mobx-react'
import ChallengeSubScreen from './ChallengeSubScreen'
import { isChallengeDemo } from '../../utils/helpers'
import AsyncStorage from '../../utils/async-storage'
import { AuthContext, NotificationContext } from '../../context'
import { verifyAnswers } from '../../utils/verify-answers'
import { useBeforeUnload } from '../../hooks/use-before-unload'
import { notifyBugsnag } from '../../utils/bugsnag'
import { trackEvent } from '../../utils/tracking'

const ChallengeContainer = ({ CHALLENGE, EXAM, PRE_SCREENS, MIDDLE_SCREENS, POST_SCREENS }) => {
  const navigation = useNavigation()
  const { rootStore } = useContext(AuthContext)
  const { error } = useContext(NotificationContext)
  const t0 = useRef(null) // useRef prevent reinit
  const t1 = useRef(null) // useRef prevent reinit

  Object.entries(rootStore.appSettingsStore.components).forEach(([key, value]) => {
    if (value === false) {
      const i = POST_SCREENS.findIndex((func) => func.name.toLowerCase() === key.toLowerCase())
      if (i > -1) {
        POST_SCREENS.splice(i, 1)
      }
    }
  })

  const [submitting, setSubmitting] = useState(false)
  const [appState, setAppState] = useState(AppState.currentState)
  const [activeScreen, setActiveScreen] = useState(0)
  const [screens, setScreens] = useState([])
  const [answer, setAnswer] = useState({})

  useBeforeUnload()

  const setAttempts = async () => {
    const attemptsBefore = await AsyncStorage.getData(`${EXAM.exam_id}_attempts`)
    await AsyncStorage.storeData(
      `${EXAM.exam_id}_attempts`,
      attemptsBefore === null ? 1 : attemptsBefore + 1
    )
  }

  useEffect(() => {
    const currentScreen = screens[activeScreen]

    if (currentScreen === 'challenge') {
      t0.current = new Date()
      setAttempts()
    }
    const handleAppStateChange = (nextAppState) => {
      if (isChallengeDemo()) return
      const isInsideGame = currentScreen === 'testRound' || currentScreen === 'challenge'

      if (isInsideGame && appState === 'active' && nextAppState.match(/inactive|background/)) {
        if (!rootStore.pauseStore.active) {
          rootStore.pauseStore.start()
        }
      }

      setAppState(nextAppState)
    }

    const subscription = AppState.addEventListener('change', handleAppStateChange)

    return () => {
      subscription.remove()
    }
  }, [screens, activeScreen]) // eslint-disable-line

  useEffect(() => {
    const getScreens = () => {
      if (EXAM.hasTestRounds) {
        return PRE_SCREENS.concat('testRound')
          .concat(MIDDLE_SCREENS)
          .concat('challenge')
          .concat(POST_SCREENS)
      }
      return PRE_SCREENS.concat('challenge').concat(POST_SCREENS)
    }

    rootStore.pauseStore.reset()
    setScreens(getScreens())

    const handleBack = () => {
      rootStore.pauseStore.start()
    }

    BackHandler.addEventListener('hardwareBackPress', handleBack) // TODO: Check only Android

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handleBack)

      rootStore.pauseStore.stop()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (screens.length > 0 && screens.length === activeScreen) {
      navigation.pop()
    }
  }, [activeScreen]) // eslint-disable-line

  useEffect(() => {
    if (rootStore.pauseStore.active) {
      navigation.navigate('Pause', {
        onPressResume: () => {
          trackEvent('ChallengeResumed', {
            exam_id: EXAM.exam_id
          })
          rootStore.pauseStore.stop()
          navigation.pop()
        },
        onPressExit: () => {
          trackEvent('ChallengeClosed', {
            exam_id: EXAM.exam_id
          })
          navigation.pop()
          navigation.pop()
        }
      })
    }
  }, [rootStore.pauseStore.active]) // eslint-disable-line

  function submitToLimeSurvey(a) {
    const qualtricsRes = JSON.stringify(a)
    document.getElementById(window.aivyResult).value = qualtricsRes
    window.limeSurveySubmit()
  }

  async function submit(a) {
    rootStore.pauseStore.reset()

    // TESTROUND
    if (EXAM.hasTestRounds && activeScreen === PRE_SCREENS.length) {
      setActiveScreen(activeScreen + 1)
      return
    }
    // --END

    const challengeId = Platform.OS === 'web' ? window.aivyChallengeId : undefined
    if (challengeId !== undefined) {
      submitToLimeSurvey(a)
      return
    }

    if (isChallengeDemo()) {
      // eslint-disable-next-line no-alert
      alert('Demo erfolgreich absolviert.')
      // setActiveScreen(activeScreen + 1)
      return
    }

    if (submitting) return
    setSubmitting(true)

    const attempts = await AsyncStorage.getData(`${EXAM.exam_id}_attempts`)
    const language = (await AsyncStorage.getData('lang')) || 'de'
    t1.current = new Date()
    const total_time_needed_in_ms = t1.current - t0.current
    const data = {
      owner: rootStore.userStore.username,
      exam_id: EXAM.exam_id,
      version: EXAM.version,
      cycle: 1,
      attempts,
      total_time_needed_in_ms,
      rating: null,
      os: Platform.OS,
      language,
      user_input: JSON.stringify(a)
    }

    try {
      const result = await rootStore.answersStore.postAnswer(
        data,
        rootStore.spaceStore.isAivySelect
      )

      verifyAnswers({
        exam_id: EXAM.exam_id,
        user_input: a
      })

      trackEvent('exam_finished', { name: EXAM.exam_id })
      rootStore.challengesStore.move(result)
      setSubmitting(false)
      setActiveScreen(activeScreen + 1)
      setAnswer(result)
    } catch (err) {
      notifyBugsnag(err)
      error('notification.error')
      setSubmitting(false)
      navigation.pop()
    }
  }

  const currentScreen = screens[activeScreen]

  if (!currentScreen) return <ActivityIndicator />

  return (
    <View style={{ flex: 1 }}>
      {currentScreen === 'testRound' || currentScreen === 'challenge' ? (
        <>
          <CHALLENGE
            testRound={currentScreen === 'testRound'}
            submit={(a) => submit(a)}
            exam={EXAM}
            navigation={navigation}
          />
          {rootStore.pauseStore.active && (
            <BlurView
              pointerEvents={rootStore.pauseStore.active ? 'auto' : 'none'}
              tint='default'
              intensity={100}
              style={StyleSheet.absoluteFill}
            />
          )}
        </>
      ) : (
        <ChallengeSubScreen
          prevScreen={() => {
            // GO BACK TO INSTRUCTION FOR EVERY CHALLENGE. In Future change name of function
            setActiveScreen(1)
          }}
          nextScreen={() => {
            setActiveScreen(activeScreen + 1)
          }}
          EXAM={EXAM}
          Component={currentScreen ? currentScreen.screen : null}
          answer={answer}
        />
      )}
    </View>
  )
}

ChallengeContainer.propTypes = {
  PRE_SCREENS: PropTypes.arrayOf(PropTypes.object).isRequired,
  MIDDLE_SCREENS: PropTypes.arrayOf(PropTypes.object).isRequired,
  POST_SCREENS: PropTypes.arrayOf(PropTypes.object).isRequired,
  EXAM: PropTypes.object.isRequired,
  CHALLENGE: PropTypes.elementType.isRequired
}

export default observer(ChallengeContainer)
