import { I18n } from 'aws-amplify'
import React from 'react'
import { StyleSheet, Text } from 'react-native'
import MarkdownRenderer from 'react-native-markdown-renderer'
import PropTypes from 'prop-types'

import Colors from '../theme/colors'

const Base = (
  customStyle,
  {
    selectable = false,
    style,
    center,
    right,
    marginTop,
    marginBottom,
    translate,
    children,
    numberOfLines
  }
) => (
  <Text
    allowFontScaling={false}
    selectable={selectable}
    style={[
      customStyle,
      center ? { textAlign: 'center' } : {},
      right ? { textAlign: 'right' } : {},
      marginTop ? { marginTop } : {},
      marginBottom ? { marginBottom } : {},
      style
    ]}
    numberOfLines={numberOfLines}
  >
    {translate ? I18n.get(children) : children}
  </Text>
)

const s = StyleSheet.create({
  h1: {
    fontFamily: 'fira-sans-semi-bold',
    fontSize: 24,
    lineHeight: 32
  },
  h1Light: {
    fontFamily: 'fira-sans-light',
    fontSize: 26,
    lineHeight: 32
  },
  h1Bold: {
    fontFamily: 'fira-sans-bold',
    fontSize: 28,
    lineHeight: 38
  },
  h2: {
    fontFamily: 'fira-sans-semi-bold',
    fontSize: 20,
    lineHeight: 26
  },
  h2Light: {
    fontFamily: 'fira-sans-light',
    fontSize: 20,
    lineHeight: 26
  },
  h3: {
    fontFamily: 'fira-sans-regular',
    fontSize: 18,
    lineHeight: 22
  },
  h4: {
    fontFamily: 'fira-sans-semi-bold',
    letterSpacing: 0.9,
    fontSize: 16,
    lineHeight: 16,
    textTransform: 'uppercase'
  },
  t18: {
    fontFamily: 'fira-sans-regular',
    fontSize: 18,
    lineHeight: 22
  },
  t1: {
    fontFamily: 'fira-sans-regular',
    fontSize: 16,
    lineHeight: 22
  },
  t1Light: {
    fontFamily: 'fira-sans-light',
    fontSize: 16,
    lineHeight: 22
  },
  t2: {
    fontFamily: 'fira-sans-regular',
    fontSize: 14,
    lineHeight: 22
  },
  t2Light: {
    fontFamily: 'fira-sans-light',
    fontSize: 12,
    lineHeight: 22
  },
  button: {
    fontFamily: 'fira-sans-medium',
    textTransform: 'uppercase'
  },
  smallText: {
    fontFamily: 'fira-sans-regular',
    fontSize: 12,
    lineHeight: 18
  },
  number: {
    fontFamily: 'fira-sans-medium',
    letterSpacing: 0.9,
    fontSize: 48,
    lineHeight: 48
  },
  numberLight: {
    fontFamily: 'fira-sans-light',
    fontSize: 40,
    lineHeight: 40
  }
})

const H1Blue = (props) =>
  Base([s.h1, { fontSize: 24, lineHeight: 32, color: Colors.primaryBlue }], props)
const H1White = (props) =>
  Base([s.h1, { fontSize: 26, lineHeight: 32, color: Colors.white }], props)
const H1Black = (props) =>
  Base([s.h1, { fontSize: 26, lineHeight: 32, color: Colors.darkBlack }], props)
const H1BlackBold = (props) => Base([s.h1, { color: Colors.darkBlack }], props)

const H1LightBlue = (props) =>
  Base([s.h1Light, { fontSize: 26, lineHeight: 32, color: Colors.darkBlue }], props)
const H1LightWhite = (props) =>
  Base([s.h1Light, { fontSize: 26, lineHeight: 32, color: Colors.white }], props)
const H1LightBlack = (props) =>
  Base([s.h1Light, { fontSize: 26, lineHeight: 32, color: Colors.darkBlack }], props)

const H2Blue = (props) =>
  Base([s.h2, { fontSize: 20, lineHeight: 26, color: Colors.primaryBlue }], props)
const H2White = (props) =>
  Base([s.h2, { fontSize: 20, lineHeight: 26, color: Colors.white }], props)
const H2Black = (props) =>
  Base([s.h2, { fontSize: 20, lineHeight: 26, color: Colors.darkBlack }], props)

const H2LightBlue = (props) =>
  Base([s.h2Light, { fontSize: 20, lineHeight: 26, color: Colors.darkBlue }], props)
const H2LightWhite = (props) =>
  Base([s.h2Light, { fontSize: 20, lineHeight: 26, color: Colors.white }], props)
const H2LightBlack = (props) =>
  Base([s.h2Light, { fontSize: 20, lineHeight: 26, color: Colors.darkBlack }], props)

const H3Blue = (props) =>
  Base([s.h3, { fontSize: 18, lineHeight: 22, color: Colors.darkBlue }], props)
const H3White = (props) =>
  Base([s.h3, { fontSize: 18, lineHeight: 22, color: Colors.white }], props)
const H3Black = (props) =>
  Base([s.h3, { fontSize: 18, lineHeight: 22, color: Colors.darkBlack }], props)

const H4Blue = (props) =>
  Base([s.h4, { fontSize: 16, lineHeight: 22, color: Colors.primaryBlue }], props)
const H4White = (props) =>
  Base([s.h4, { fontSize: 16, lineHeight: 22, color: Colors.white }], props)
const H4Black = (props) =>
  Base([s.h4, { fontSize: 16, lineHeight: 22, color: Colors.darkBlack }], props)
const H4Green = (props) =>
  Base([s.h4, { fontSize: 16, lineHeight: 22, color: Colors.brightGreen }], props)

const T18Blue = (props) => Base([s.t18, { color: Colors.primaryBlue }], props)
const T18White = (props) => Base([s.t18, { color: Colors.white }], props)
const T18Black = (props) => Base([s.t18, { color: Colors.darkBlack }], props)

const T1Blue = (props) =>
  Base([s.t1, { fontSize: 16, lineHeight: 22, color: Colors.primaryBlue }], props)
const T1White = (props) =>
  Base([s.t1, { fontSize: 16, lineHeight: 22, color: Colors.white }], props)
const T1Black = (props) =>
  Base([s.t1, { fontSize: 16, lineHeight: 22, color: Colors.darkBlack }], props)

const T1LightBlue = (props) =>
  Base([s.t1Light, { fontSize: 16, lineHeight: 22, color: Colors.darkBlue }], props)
const T1LightWhite = (props) =>
  Base([s.t1Light, { fontSize: 16, lineHeight: 22, color: Colors.white }], props)
const T1LightBlack = (props) =>
  Base([s.t1Light, { fontSize: 16, lineHeight: 22, color: Colors.darkBlack }], props)

const T2Blue = (props) =>
  Base([s.t2, { fontSize: 14, lineHeight: 22, color: Colors.darkBlue }], props)
const T2White = (props) =>
  Base([s.t2, { fontSize: 14, lineHeight: 22, color: Colors.white }], props)
const T2Black = (props) =>
  Base([s.t2, { fontSize: 14, lineHeight: 22, color: Colors.darkBlack }], props)

const T6Black = (props) =>
  Base([s.t2, { fontSize: 16, lineHeight: 22, color: Colors.darkBlack }], props)

const T2LightBlue = (props) =>
  Base([s.t2Light, { fontSize: 12, lineHeight: 22, color: Colors.darkBlue }], props)
const T2LightWhite = (props) =>
  Base([s.t2Light, { fontSize: 12, lineHeight: 22, color: Colors.white }], props)
const T2LightBlack = (props) =>
  Base([s.t2Light, { fontSize: 12, lineHeight: 22, color: Colors.darkBlack }], props)

const ButtonBlue = (props) =>
  Base([s.button, { fontSize: 14, lineHeight: 22, color: Colors.primaryBlue }], props)
const ButtonWhite = (props) =>
  Base([s.button, { fontSize: 14, lineHeight: 22, color: Colors.white }], props)
const ButtonBlack = (props) =>
  Base([s.button, { fontSize: 14, lineHeight: 22, color: Colors.darkBlack }], props)

const SmallTextBlue = (props) =>
  Base([s.smallText, { fontSize: 12, lineHeight: 18, color: Colors.primaryBlue }], props)
const SmallTextWhite = (props) =>
  Base([s.smallText, { fontSize: 12, lineHeight: 18, color: Colors.white }], props)
const SmallTextBlack = (props) =>
  Base([s.smallText, { fontSize: 12, lineHeight: 18, color: Colors.darkBlack }], props)

const NumberBlue = (props) =>
  Base([s.number, { fontSize: 40, lineHeight: 48, color: Colors.primaryBlue }], props)
const NumberWhite = (props) =>
  Base([s.number, { fontSize: 48, lineHeight: 48, color: Colors.white }], props)
const NumberBlack = (props) =>
  Base([s.number, { fontSize: 48, lineHeight: 48, color: Colors.darkBlack }], props)

const NumberLightBlue = (props) =>
  Base([s.numberLight, { fontSize: 40, lineHeight: 40, color: Colors.primaryBlue }], props)
const NumberLightWhite = (props) =>
  Base([s.numberLight, { fontSize: 40, lineHeight: 40, color: Colors.white }], props)
const NumberLightBlack = (props) =>
  Base([s.numberLight, { fontSize: 40, lineHeight: 40, color: Colors.darkBlack }], props)

const Markdown = ({ style, center, marginTop, children }) => (
  <MarkdownRenderer style={style} center={center} marginTop={marginTop}>
    {children}
  </MarkdownRenderer>
)

Markdown.propTypes = {
  style: PropTypes.object,
  center: PropTypes.bool,
  marginTop: PropTypes.number,
  children: PropTypes.node.isRequired
}

Markdown.defaultProps = {
  center: false,
  marginTop: undefined,
  style: {
    text: {
      fontFamily: 'fira-sans-regular',
      fontSize: 16,
      lineHeight: 22,
      color: Colors.darkBlack
    },
    strong: {
      fontSize: 16,
      lineHeight: 22,
      fontFamily: 'fira-sans-medium'
    }
  }
}

export default {
  H1Blue,
  H1White,
  H1Black,
  H1BlackBold,
  H1LightBlue,
  H1LightWhite,
  H1LightBlack,
  H2Blue,
  H2White,
  H2Black,
  H2LightBlue,
  H2LightWhite,
  H2LightBlack,
  H3Blue,
  H3White,
  H3Black,
  H4Blue,
  H4White,
  H4Black,
  H4Green,
  T18Black,
  T18Blue,
  T18White,
  T1Blue,
  T1White,
  T1Black,
  T1LightBlue,
  T1LightWhite,
  T1LightBlack,
  T2Blue,
  T2White,
  T2Black,
  T2LightBlue,
  T2LightWhite,
  T2LightBlack,
  T6Black,
  ButtonBlue,
  ButtonWhite,
  ButtonBlack,
  SmallTextBlue,
  SmallTextWhite,
  SmallTextBlack,
  NumberBlue,
  NumberWhite,
  NumberBlack,
  NumberLightBlue,
  NumberLightWhite,
  NumberLightBlack,
  Markdown
}
