import React from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import colors from '../theme/colors'
import { rowCenter } from '../styles'
import Typo from './Typo'

const customMDStyles = StyleSheet.create({
  text: {
    fontFamily: 'fira-sans-light',
    fontSize: 20,
    lineHeight: 26
  },
  strong: {
    fontFamily: 'fira-sans-semi-bold',
    fontSize: 20,
    lineHeight: 26
  }
})

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: colors.white,
    borderRadius: 18,
    shadowColor: colors.mediumGrey,
    shadowOffset: {
      width: 0,
      height: 15
    },
    shadowOpacity: 0.2,
    shadowRadius: 28,
    elevation: 14,
    paddingTop: 24,
    paddingBottom: 16,
    paddingHorizontal: 16
  }
})

const ReverseRecruitingListItem = ({
  containerStyle,
  onPress,
  level,
  partnerLogo,
  markdownText,
  onPressDeletedItem,
  city
}) => (
  <View style={[styles.container, containerStyle]}>
    <View style={rowCenter}>
      <Image
        source={{ uri: partnerLogo }}
        style={{ width: 48, height: 48, resizeMode: 'contain' }}
      />
      <View style={{ marginLeft: 12, width: '80%' }}>
        <Typo.T2Black>{level}</Typo.T2Black>
        <Typo.T1Black>{city}</Typo.T1Black>
      </View>
    </View>
    <View style={{ marginTop: 12, width: '95%' }}>
      <Typo.Markdown style={customMDStyles}>{markdownText}</Typo.Markdown>
      {/* <Typo.H2LightBlack>
        <Typo.H2Black>{partnerTitle}</Typo.H2Black>{' '}
        {`${I18n.get('reverserecruiting.item.firstText')} `}
        <Typo.H2Black>{careerTitle}</Typo.H2Black>
        {` ${I18n.get('reverserecruiting.item.secondText')}`}
        {`\n${I18n.get('reverserecruiting.item.thirdText')}`}
      </Typo.H2LightBlack> */}
    </View>
    <View style={{ flex: 1, justifyContent: 'flex-end', paddingTop: 16 }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <TouchableOpacity onPress={onPressDeletedItem}>
          <Typo.ButtonBlue translate>reverserecruiting.item.nothanks</Typo.ButtonBlue>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={onPress}
          style={{
            backgroundColor: colors.primaryBlue,
            height: 48,
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            borderRadius: 24
          }}
        >
          <Typo.T1White translate>reverserecruiting.item.interested</Typo.T1White>
        </TouchableOpacity>
      </View>
    </View>
  </View>
)

ReverseRecruitingListItem.propTypes = {
  containerStyle: PropTypes.object,
  onPress: PropTypes.func.isRequired,
  onPressDeletedItem: PropTypes.func.isRequired,
  level: PropTypes.string,
  // partnerTitle: PropTypes.string.isRequired,
  partnerLogo: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  markdownText: PropTypes.string
}

ReverseRecruitingListItem.defaultProps = {
  containerStyle: {},
  level: '',
  markdownText: ''
}

export default ReverseRecruitingListItem
