import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { Analytics } from 'aws-amplify'

// configure and initialize Firebase for web (copy this from the Firebase Console Project Settings for the exact values)
const firebaseConfig = {
  apiKey: 'AIzaSyAbbB7aPjqnTgEJayGX_JtyMI7_tMlij2I',
  authDomain: 'aivy-mobile.firebaseapp.com',
  databaseURL: 'https://aivy-mobile.firebaseio.com',
  projectId: 'aivy-mobile',
  storageBucket: 'aivy-mobile.appspot.com',
  messagingSenderId: '38826235811',
  appId: '1:38826235811:web:533780921e6d44fa6eec16',
  measurementId: 'G-FE3DYJPECP'
}

const app = initializeApp(firebaseConfig)
const webAnalytics = getAnalytics(app)

export function trackEvent(name, params) {
  if (__DEV__ || !name) return

  if (params) {
    logEvent(webAnalytics, name, params)
    Analytics.record({
      name,
      attributes: params,
      immediate: true
    })
    return
  }

  logEvent(webAnalytics, name)
  Analytics.record({ name, immediate: true })
}
