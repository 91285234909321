import React from 'react'
import { StyleSheet, View, Image, Platform } from 'react-native'
import PropTypes from 'prop-types'
import Typo from '../Typo'

import colors from '../../theme/colors'

const styles = StyleSheet.create({
  container: {
    height: 48,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  partnerLogo: {
    height: 30,
    width: 30,
    resizeMode: Platform.OS === 'android' ? undefined : 'contain'
  },
  imageWrapperStyle: {
    height: 48,
    width: 48,
    borderRadius: 48 / 2,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: colors.lightGrey
  }
})

const Partner = ({ name, logo, careerName }) => (
  <View style={styles.container}>
    <View style={styles.imageWrapperStyle}>
      <Image style={styles.partnerLogo} source={logo} />
    </View>
    <View style={{ marginLeft: 12 }}>
      <Typo.H2Black>{name}</Typo.H2Black>
      {careerName ? <Typo.T2Black>{careerName}</Typo.T2Black> : null}
    </View>
  </View>
)

Partner.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
  careerName: PropTypes.string
}

Partner.defaultProps = {
  careerName: null
}

export default Partner
