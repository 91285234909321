export const ANSWER = `{
  id
  exam_id
  cycle
  total_time_needed_in_ms
  user_input
  rating
  rating_info
  result_rating_info
  score
  language
  version
  attempts
  space_id
  os
  createdAt
  updatedAt
}`
