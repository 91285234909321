export default {
  CHALLENGE: 'CHALLENGE',
  CHALLENGE_DONE: 'CHALLENGE_DONE',
  REVERSE_RECRUITING: 'REVERSE_RECRUITING',
  ARTICLE: 'ARTICLE',
  EVENT: 'EVENT',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SIGNUP: 'SIGNUP',
  PERSPECTIVES: 'PERSPECTIVES',
  EMPTY_TODO_TAB: 'EMPTY_TODO_TAB',
  EMPTY_DONE_TAB: 'EMPTY_DONE_TAB',
  HARD_FACTS: 'HARD_FACTS',
  NEWSLETTER: 'NEWSLETTER'
}
