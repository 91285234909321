import _ from 'lodash'
import metrics from '../../../../theme/metrics'

export const prettyFormatTime = (num) => {
  if (num === 60) return '1:00'

  if (num >= 10) return `0:${num}`

  return `0:0${num}`
}

export const CELL_GUTTER = 5
export const CELL_SIZE = (metrics.widthPercentageToDP(100) - CELL_GUTTER) / 7
export const TIME_PER_LEVEL = 60

// Validations [Y, X, CELL STATE]
export const formatValidations = (validation) => ({
  rowIndex: validation[0],
  cellIndex: validation[1],
  cellState: validation[2]
})

const TEST_LVL_1 = {
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [2, 0], [null]],
    [[null], [null], [null], [null], [null], [6, 1], [null]],
    [[null], [null], [null], [null], [null], [6, 1], [null]],
    [[null], [null], [null], [null], [null], [6, 1], [null]],
    [[null], [1, 0], [6, 0], [6, 0], [6, 0], [4, 2], [null]]
  ],
  validations: [
    [
      [4, 2, 0],
      [4, 3, 0],
      [4, 4, 0],
      [4, 5, 0],
      [3, 5, 1],
      [2, 5, 1],
      [1, 5, 1]
    ]
  ]
}

const TEST_LVL_2_1 = {
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [7, 2], [3, 0], [3, 1], [3, 1], [7, 3], [null]],
    [[null], [2, 0], [null], [null], [null], [1, 0], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 1, 2],
      [2, 2, 0],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 3]
    ]
  ]
}

const TEST_LVL_2_2 = {
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [1, 0], [null], [null], [null], [2, 0], [null]],
    [[null], [7, 1], [3, 0], [3, 1], [3, 1], [7, 0], [null]],
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 1, 1],
      [2, 2, 0],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 0]
    ]
  ]
}

const TEST_LVL_3_1 = {
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [1, 0], [4, 1], [4, 0], [2, 0], [null], [null]],
    [[null], [null], [4, 3], [4, 2], [null], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [1, 2, 3],
      [2, 2, 1],
      [2, 3, 0],
      [1, 3, 2]
    ]
  ]
}

const TEST_LVL_3_2 = {
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [4, 0], [4, 1], [null], [null], [null]],
    [[null], [1, 0], [4, 2], [4, 3], [2, 0], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 2, 0],
      [1, 2, 2],
      [1, 3, 3],
      [2, 3, 1]
    ]
  ]
}

const LVL_1_1_1 = {
  id: '1.1.1',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [null], [4, 0], [4, 2], [null], [null]],
    [[null], [null], [1, 0], [4, 0], [2, 0], [null], [null]],
    [[null], [null], [null], [4, 3], [4, 3], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 3, 0],
      [1, 3, 2],
      [1, 4, 3]
    ],
    [
      [2, 3, 3],
      [3, 3, 1],
      [3, 4, 0]
    ]
  ]
}

const LVL_1_1_2 = {
  id: '1.1.2',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [4, 1], [4, 1], [null], [null], [null]],
    [[null], [null], [2, 0], [4, 2], [1, 0], [null], [null]],
    [[null], [null], [4, 0], [4, 2], [null], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [1, 2, 2],
      [1, 3, 3],
      [2, 3, 1]
    ],
    [
      [3, 2, 1],
      [3, 3, 0],
      [2, 3, 2]
    ]
  ]
}

const LVL_1_1_3 = {
  id: '1.1.3',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [4, 3], [4, 1], [null], [null], [null]],
    [[null], [null], [2, 0], [4, 1], [1, 0], [null], [null]],
    [[null], [null], [4, 2], [4, 2], [null], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [1, 2, 2],
      [1, 3, 3],
      [2, 3, 1]
    ],
    [
      [3, 2, 1],
      [3, 3, 0],
      [2, 3, 2]
    ]
  ]
}

const LVL_1_1_4 = {
  id: '1.1.4',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [null], [4, 0], [4, 0], [null], [null]],
    [[null], [null], [1, 0], [4, 3], [2, 0], [null], [null]],
    [[null], [null], [null], [4, 3], [4, 1], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 3, 0],
      [1, 3, 2],
      [1, 4, 3]
    ],
    [
      [2, 3, 3],
      [3, 3, 1],
      [3, 4, 0]
    ]
  ]
}

const LVL_1_2_1 = {
  id: '1.2.1',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [4, 0], [1, 0], [3, 1], [null], [null]],
    [[null], [4, 1], [3, 1], [4, 0], [3, 1], [4, 1], [null]],
    [[null], [4, 1], [3, 1], [2, 0], [4, 1], [4, 1], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 2],
      [3, 1, 1],
      [3, 2, 0]
    ]
  ]
}

const LVL_1_2_2 = {
  id: '1.2.2',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [4, 2], [3, 1], [2, 0], [4, 2], [4, 2], [null]],
    [[null], [4, 2], [3, 1], [4, 3], [3, 1], [4, 2], [null]],
    [[null], [null], [4, 3], [1, 0], [3, 1], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [1, 2, 0],
      [1, 1, 2],
      [2, 1, 1],
      [2, 2, 0],
      [2, 3, 3]
    ]
  ]
}

const LVL_1_2_3 = {
  id: '1.2.3',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [null], [3, 1], [1, 0], [4, 1], [null], [null]],
    [[null], [4, 0], [3, 1], [4, 1], [3, 1], [4, 0], [null]],
    [[null], [4, 0], [4, 0], [2, 0], [3, 1], [4, 0], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 3, 1],
      [2, 4, 0],
      [2, 5, 3],
      [3, 5, 0],
      [3, 4, 0]
    ]
  ]
}

const LVL_1_2_4 = {
  id: '1.2.4',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[null], [4, 3], [4, 3], [2, 0], [3, 1], [4, 3], [null]],
    [[null], [4, 3], [3, 1], [4, 2], [3, 1], [4, 3], [null]],
    [[null], [null], [3, 1], [1, 0], [4, 2], [null], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 3, 2],
      [2, 4, 0],
      [2, 5, 0],
      [1, 5, 3],
      [1, 4, 0]
    ]
  ]
}

const LVL_1_3_1 = {
  id: '1.3.1',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [3, 0], [4, 3], [2, 0], [3, 0], [4, 3], [null]],
    [[null], [7, 2], [4, 3], [3, 0], [4, 3], [3, 0], [null]],
    [[null], [3, 0], [4, 3], [3, 0], [6, 0], [4, 3], [null]],
    [[null], [4, 3], [4, 3], [4, 3], [3, 0], [4, 3], [null]],
    [[null], [4, 3], [3, 0], [1, 0], [7, 0], [3, 0], [null]]
  ],
  validations: [
    [
      [0, 2, 2],
      [1, 2, 0],
      [1, 1, 2],
      [2, 1, 1],
      [3, 1, 1],
      [3, 2, 0],
      [2, 2, 2],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 3],
      [3, 5, 0],
      [3, 4, 0],
      [3, 3, 2]
    ],
    [
      [3, 3, 3],
      [3, 2, 1],
      [2, 2, 2],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 0],
      [1, 5, 1],
      [0, 5, 3],
      [0, 4, 0]
    ],
    [
      [4, 2, 0],
      [4, 1, 1],
      [3, 1, 2],
      [3, 2, 0],
      [2, 2, 2],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 0],
      [1, 5, 1],
      [0, 5, 3],
      [0, 4, 0]
    ]
  ]
}

const LVL_1_3_2 = {
  id: '1.3.2',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [4, 2], [3, 0], [2, 0], [4, 2], [3, 0], [null]],
    [[null], [3, 0], [4, 2], [3, 0], [4, 2], [7, 3], [null]],
    [[null], [4, 2], [6, 0], [3, 0], [4, 2], [3, 0], [null]],
    [[null], [4, 2], [3, 0], [4, 2], [4, 2], [4, 2], [null]],
    [[null], [3, 0], [7, 1], [1, 0], [3, 0], [4, 2], [null]]
  ],
  validations: [
    [
      [4, 4, 0],
      [4, 5, 0],
      [3, 5, 3],
      [3, 4, 1],
      [2, 4, 3],
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 1],
      [1, 1, 1],
      [0, 1, 2],
      [0, 2, 0]
    ],
    [
      [3, 3, 2],
      [3, 4, 0],
      [2, 4, 3],
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 1],
      [1, 1, 1],
      [0, 1, 2],
      [0, 2, 0]
    ],
    [
      [0, 4, 3],
      [1, 4, 1],
      [1, 5, 3],
      [2, 5, 1],
      [3, 5, 0],
      [3, 4, 1],
      [2, 4, 3],
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 2],
      [3, 1, 1],
      [3, 2, 0],
      [3, 3, 3]
    ]
  ]
}

const LVL_1_3_3 = {
  id: '1.3.3',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [4, 0], [3, 0], [1, 0], [7, 3], [3, 0], [null]],
    [[null], [4, 0], [4, 0], [4, 0], [3, 0], [4, 0], [null]],
    [[null], [3, 0], [4, 0], [3, 0], [6, 0], [4, 0], [null]],
    [[null], [7, 1], [4, 0], [3, 0], [4, 0], [3, 0], [null]],
    [[null], [3, 0], [4, 0], [2, 0], [3, 0], [4, 0], [null]]
  ],
  validations: [
    [
      [1, 3, 1],
      [1, 4, 0],
      [1, 5, 3],
      [2, 5, 0],
      [2, 4, 0],
      [2, 3, 0],
      [2, 2, 1],
      [1, 2, 3],
      [1, 1, 2],
      [2, 1, 1],
      [3, 1, 1],
      [3, 2, 3],
      [4, 2, 1]
    ],
    [
      [1, 3, 0],
      [1, 2, 2],
      [2, 2, 1],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 3],
      [3, 5, 1],
      [4, 5, 0],
      [4, 4, 0]
    ],
    [
      [0, 2, 0],
      [0, 1, 2],
      [1, 1, 1],
      [1, 2, 3],
      [2, 2, 1],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 3],
      [3, 5, 1],
      [4, 5, 0],
      [4, 4, 0]
    ]
  ]
}

const LVL_1_3_4 = {
  id: '1.3.4',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [3, 0], [7, 2], [1, 0], [3, 0], [4, 1], [null]],
    [[null], [4, 1], [3, 0], [4, 1], [4, 1], [4, 1], [null]],
    [[null], [4, 1], [6, 0], [3, 0], [4, 1], [3, 0], [null]],
    [[null], [3, 0], [4, 1], [3, 0], [4, 1], [7, 0], [null]],
    [[null], [4, 1], [3, 0], [2, 0], [4, 1], [3, 0], [null]]
  ],
  validations: [
    [
      [1, 3, 1],
      [1, 4, 3],
      [2, 4, 0],
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 2],
      [3, 1, 1],
      [4, 1, 1],
      [4, 2, 0]
    ],
    [
      [0, 4, 0],
      [0, 5, 3],
      [1, 5, 0],
      [1, 4, 2],
      [2, 4, 0],
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 2],
      [3, 1, 1],
      [4, 1, 1],
      [4, 2, 0]
    ],
    [
      [1, 3, 0],
      [1, 2, 0],
      [1, 1, 2],
      [2, 1, 1],
      [2, 2, 0],
      [2, 3, 0],
      [2, 4, 0],
      [1, 4, 2],
      [1, 5, 3],
      [2, 5, 1],
      [3, 5, 0],
      [3, 4, 2],
      [4, 4, 0]
    ]
  ]
}

const LVL_2_1_1 = {
  id: '2.1.1',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [4, 0], [4, 0], [4, 2], [4, 2], [null]],
    [[null], [4, 3], [3, 1], [4, 0], [3, 0], [5, 2], [null]],
    [[null], [4, 1], [2, 0], [4, 2], [null], [1, 0], [null]],
    [[null], [4, 1], [3, 1], [3, 1], [3, 0], [5, 1], [null]],
    [[null], [null], [3, 1], [4, 2], [3, 0], [3, 1], [null]]
  ],
  validations: [
    [
      [1, 5, 0],
      [1, 4, 0],
      [1, 3, 2],
      [2, 3, 0]
    ],
    [
      [1, 5, 1],
      [1, 4, 0],
      [1, 3, 2],
      [2, 3, 0]
    ],
    [
      [1, 5, 3],
      [1, 4, 0],
      [1, 3, 2],
      [2, 3, 0]
    ],
    [
      [3, 5, 1],
      [3, 4, 0],
      [3, 3, 0],
      [3, 2, 0],
      [3, 1, 1],
      [2, 1, 2]
    ],
    [
      [3, 5, 0],
      [3, 4, 0],
      [3, 3, 0],
      [3, 2, 0],
      [3, 1, 1],
      [2, 1, 2]
    ],
    [
      [1, 5, 3],
      [1, 4, 0],
      [1, 3, 1],
      [0, 3, 3],
      [0, 2, 2],
      [1, 2, 1]
    ],
    [
      [1, 5, 0],
      [1, 4, 0],
      [1, 3, 1],
      [0, 3, 3],
      [0, 2, 2],
      [1, 2, 1]
    ]
  ]
}

const LVL_2_1_2 = {
  id: '2.1.2',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [3, 0], [3, 0], [4, 0], [3, 1], [null], [null]],
    [[null], [5, 3], [3, 0], [3, 1], [3, 1], [4, 3], [null]],
    [[null], [1, 0], [null], [4, 0], [2, 0], [4, 3], [null]],
    [[null], [5, 0], [3, 0], [4, 2], [3, 1], [4, 1], [null]],
    [[null], [4, 0], [4, 0], [4, 2], [4, 2], [null], [null]]
  ],
  validations: [
    [
      [1, 1, 2],
      [1, 2, 0],
      [1, 3, 0],
      [1, 4, 0],
      [1, 5, 3],
      [2, 5, 0]
    ],
    [
      [1, 1, 3],
      [1, 2, 0],
      [1, 3, 0],
      [1, 4, 0],
      [1, 5, 3],
      [2, 5, 0]
    ],
    [
      [3, 1, 2],
      [3, 2, 0],
      [3, 3, 0],
      [2, 3, 2]
    ],
    [
      [3, 1, 1],
      [3, 2, 0],
      [3, 3, 0],
      [2, 3, 2]
    ],
    [
      [3, 1, 1],
      [3, 2, 0],
      [3, 3, 3],
      [4, 3, 1],
      [4, 4, 0],
      [3, 4, 1]
    ],
    [
      [3, 1, 2],
      [3, 2, 0],
      [3, 3, 3],
      [4, 3, 1],
      [4, 4, 0],
      [3, 4, 1]
    ]
  ]
}

const LVL_2_1_3 = {
  id: '2.1.3',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [3, 1], [4, 1], [3, 0], [3, 1], [null]],
    [[null], [4, 2], [3, 1], [3, 1], [3, 0], [5, 3], [null]],
    [[null], [4, 2], [2, 0], [4, 1], [null], [1, 0], [null]],
    [[null], [4, 0], [3, 1], [4, 3], [3, 0], [5, 2], [null]],
    [[null], [null], [4, 3], [4, 3], [4, 1], [4, 1], [null]]
  ],
  validations: [
    [
      [3, 5, 0],
      [3, 4, 0],
      [3, 3, 1],
      [2, 3, 3]
    ],
    [
      [3, 5, 1],
      [3, 4, 0],
      [3, 3, 1],
      [2, 3, 3]
    ],
    [
      [3, 5, 1],
      [3, 4, 0],
      [3, 3, 1],
      [2, 3, 3]
    ],
    [
      [1, 5, 0],
      [1, 4, 0],
      [1, 3, 0],
      [1, 2, 0],
      [1, 1, 2],
      [2, 1, 1]
    ],
    [
      [1, 5, 3],
      [1, 4, 0],
      [1, 3, 0],
      [1, 2, 0],
      [1, 1, 2],
      [2, 1, 1]
    ],
    [
      [3, 5, 1],
      [3, 4, 0],
      [3, 3, 2],
      [4, 3, 0],
      [4, 2, 1],
      [3, 2, 1]
    ],
    [
      [3, 5, 0],
      [3, 4, 0],
      [3, 3, 2],
      [4, 3, 0],
      [4, 2, 1],
      [3, 2, 1]
    ]
  ]
}

const LVL_2_1_4 = {
  id: '2.1.4',
  timeInSeconds: 999999, // TIME_PER_LEVEL
  matrix: [
    [[null], [4, 3], [4, 3], [4, 1], [4, 1], [null], [null]],
    [[null], [5, 0], [3, 0], [4, 1], [3, 1], [4, 2], [null]],
    [[null], [1, 0], [null], [4, 3], [2, 0], [4, 0], [null]],
    [[null], [5, 1], [3, 0], [3, 1], [3, 1], [4, 0], [null]],
    [[null], [3, 1], [3, 0], [4, 3], [3, 1], [null], [null]]
  ],
  validations: [
    [
      [1, 1, 1],
      [1, 2, 0],
      [1, 3, 3],
      [2, 3, 1]
    ],
    [
      [1, 1, 2],
      [1, 2, 0],
      [1, 3, 3],
      [2, 3, 1]
    ],
    [
      [1, 1, 3],
      [1, 2, 0],
      [1, 3, 3],
      [2, 3, 1]
    ],
    [
      [3, 1, 1],
      [3, 2, 0],
      [3, 3, 0],
      [3, 4, 0],
      [3, 5, 0],
      [2, 5, 3]
    ],
    [
      [1, 1, 3],
      [1, 2, 0],
      [1, 3, 0],
      [0, 3, 2],
      [0, 4, 3],
      [1, 4, 1]
    ],
    [
      [1, 1, 2],
      [1, 2, 0],
      [1, 3, 0],
      [0, 3, 2],
      [0, 4, 3],
      [1, 4, 1]
    ],
    [
      [1, 1, 3],
      [1, 2, 0],
      [1, 3, 0],
      [0, 3, 2],
      [0, 4, 3],
      [1, 4, 1]
    ]
  ]
}

const LVL_2_2_1 = {
  id: '2.2.1',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 1], [3, 1], [4, 3], [null], [4, 0], [3, 1], [4, 3]],
    [[3, 0], [null], [4, 1], [3, 1], [4, 2], [null], [3, 1]],
    [
      [2, 0],
      [3, 0],
      [4, 2],
      [4, 1],
      [3, 0],
      [3, 0],
      [1, 0]
    ],
    [[3, 1], [null], [4, 2], [7, 1], [3, 0], [null], [3, 1]],
    [[4, 2], [3, 0], [4, 2], [null], [4, 1], [3, 0], [4, 2]]
  ],
  validations: [
    [
      [1, 6, 1],
      [0, 6, 3],
      [0, 5, 0],
      [0, 4, 2],
      [1, 4, 0],
      [1, 3, 0],
      [1, 2, 1],
      [0, 2, 3],
      [0, 1, 0],
      [0, 0, 2],
      [1, 0, 1]
    ],
    [
      [1, 6, 1],
      [0, 6, 3],
      [0, 5, 0],
      [0, 4, 2],
      [1, 4, 0],
      [1, 3, 0],
      [1, 2, 2],
      [2, 2, 0],
      [2, 1, 0]
    ],
    [
      [3, 6, 1],
      [4, 6, 0],
      [4, 5, 0],
      [4, 4, 1],
      [3, 4, 1],
      [2, 4, 1],
      [1, 4, 3],
      [1, 3, 0],
      [1, 2, 1],
      [0, 2, 3],
      [0, 1, 0],
      [0, 0, 2],
      [1, 0, 1]
    ],
    [
      [3, 6, 1],
      [4, 6, 0],
      [4, 5, 0],
      [4, 4, 1],
      [3, 4, 1],
      [2, 4, 1],
      [1, 4, 3],
      [1, 3, 0],
      [1, 2, 2],
      [2, 2, 0],
      [2, 1, 0]
    ]
  ]
}

const LVL_2_2_2 = {
  id: '2.2.2',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 0], [3, 0], [4, 3], [null], [4, 0], [3, 0], [4, 0]],
    [[3, 1], [null], [3, 0], [7, 3], [4, 1], [null], [3, 1]],
    [
      [1, 0],
      [3, 0],
      [3, 0],
      [4, 1],
      [4, 2],
      [3, 0],
      [2, 0]
    ],
    [[3, 1], [null], [4, 0], [3, 1], [4, 1], [null], [3, 0]],
    [[4, 1], [3, 1], [4, 2], [null], [4, 1], [3, 1], [4, 3]]
  ],
  validations: [
    [
      [1, 0, 1],
      [0, 0, 2],
      [0, 1, 0],
      [0, 2, 3],
      [1, 2, 1],
      [2, 2, 1],
      [3, 2, 1],
      [3, 3, 0],
      [3, 4, 0],
      [2, 4, 2],
      [2, 5, 0]
    ],
    [
      [1, 0, 1],
      [0, 0, 2],
      [0, 1, 0],
      [0, 2, 3],
      [1, 2, 1],
      [2, 2, 1],
      [3, 2, 1],
      [3, 3, 0],
      [3, 4, 3],
      [4, 4, 1],
      [4, 5, 0],
      [4, 6, 0],
      [3, 6, 1]
    ],
    [
      [3, 0, 1],
      [4, 0, 1],
      [4, 1, 0],
      [4, 2, 0],
      [3, 2, 2],
      [3, 3, 0],
      [3, 4, 0],
      [2, 4, 2],
      [2, 5, 0]
    ],
    [
      [3, 0, 1],
      [4, 0, 1],
      [4, 1, 0],
      [4, 2, 0],
      [3, 2, 2],
      [3, 3, 0],
      [3, 4, 3],
      [4, 4, 1],
      [4, 5, 0],
      [4, 6, 0],
      [3, 6, 1]
    ]
  ]
}

const LVL_2_2_3 = {
  id: '2.2.3',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 2], [3, 1], [4, 1], [null], [4, 2], [3, 1], [4, 0]],
    [[3, 1], [null], [4, 1], [3, 0], [4, 2], [null], [3, 0]],
    [
      [1, 0],
      [3, 0],
      [3, 0],
      [4, 0],
      [4, 1],
      [3, 0],
      [2, 0]
    ],
    [[3, 1], [null], [3, 0], [7, 0], [4, 2], [null], [3, 1]],
    [[4, 3], [3, 0], [4, 0], [null], [4, 3], [3, 0], [4, 3]]
  ],
  validations: [
    [
      [1, 0, 1],
      [0, 0, 2],
      [0, 1, 0],
      [0, 2, 3],
      [1, 2, 1],
      [1, 3, 0],
      [1, 4, 0],
      [0, 4, 2],
      [0, 5, 0],
      [0, 6, 3],
      [1, 6, 1]
    ],
    [
      [1, 0, 1],
      [0, 0, 2],
      [0, 1, 0],
      [0, 2, 3],
      [1, 2, 1],
      [1, 3, 0],
      [1, 4, 3],
      [2, 4, 1],
      [2, 5, 0]
    ],
    [
      [3, 0, 1],
      [4, 0, 1],
      [4, 1, 0],
      [4, 2, 0],
      [3, 2, 1],
      [2, 2, 1],
      [1, 2, 2],
      [1, 3, 0],
      [1, 4, 3],
      [2, 4, 1],
      [2, 5, 0]
    ],
    [
      [3, 0, 1],
      [4, 0, 1],
      [4, 1, 0],
      [4, 2, 0],
      [3, 2, 1],
      [2, 2, 1],
      [1, 2, 2],
      [1, 3, 0],
      [1, 4, 0],
      [0, 4, 2],
      [0, 5, 0],
      [0, 6, 3],
      [1, 6, 1]
    ]
  ]
}

const LVL_2_2_4 = {
  id: '2.2.4',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 1], [3, 0], [4, 1], [null], [4, 2], [3, 0], [4, 1]],
    [[3, 1], [null], [4, 3], [7, 2], [3, 1], [null], [3, 1]],
    [
      [2, 0],
      [3, 0],
      [4, 1],
      [4, 0],
      [3, 1],
      [3, 0],
      [1, 0]
    ],
    [[3, 0], [null], [4, 1], [3, 1], [4, 3], [null], [3, 1]],
    [[4, 2], [3, 1], [4, 0], [null], [4, 3], [3, 1], [4, 0]]
  ],
  validations: [
    [
      [1, 6, 1],
      [0, 6, 3],
      [0, 5, 0],
      [0, 4, 2],
      [1, 4, 1],
      [2, 4, 1],
      [3, 4, 0],
      [3, 3, 0],
      [3, 2, 1],
      [2, 2, 3],
      [2, 1, 0]
    ],
    [
      [1, 6, 1],
      [0, 6, 3],
      [0, 5, 0],
      [0, 4, 2],
      [1, 4, 1],
      [2, 4, 1],
      [3, 4, 0],
      [3, 3, 0],
      [3, 2, 2],
      [4, 2, 0],
      [4, 1, 0],
      [4, 0, 1],
      [3, 0, 1]
    ],
    [
      [3, 6, 1],
      [4, 6, 0],
      [4, 5, 0],
      [4, 4, 1],
      [3, 4, 3],
      [3, 3, 0],
      [3, 2, 1],
      [2, 2, 3],
      [2, 1, 0]
    ],
    [
      [3, 6, 1],
      [4, 6, 0],
      [4, 5, 0],
      [4, 4, 1],
      [3, 4, 3],
      [3, 3, 0],
      [3, 2, 2],
      [4, 2, 0],
      [4, 1, 0],
      [4, 0, 1],
      [3, 0, 1]
    ]
  ]
}

const LVL_2_3_1 = {
  id: '2.3.1',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[1, 0], [4, 3], [4, 1], [4, 3], [4, 0], [4, 3], [null]],
    [[3, 0], [3, 1], [3, 1], [3, 1], [3, 0], [2, 0], [null]],
    [[4, 3], [4, 1], [4, 1], [3, 0], [3, 0], [4, 1], [null]],
    [[4, 0], [4, 1], [3, 1], [3, 0], [3, 1], [3, 0], [null]],
    [[4, 3], [3, 0], [4, 0], [4, 2], [3, 1], [4, 2], [null]]
  ],
  validations: [
    [
      [1, 0, 1],
      [2, 0, 1],
      [2, 1, 0],
      [1, 1, 1],
      [0, 1, 2],
      [0, 2, 3],
      [1, 2, 1],
      [2, 2, 1],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 0]
    ],
    [
      [1, 0, 1],
      [2, 0, 1],
      [2, 1, 3],
      [3, 1, 0],
      [3, 0, 2],
      [4, 0, 1],
      [4, 1, 0],
      [4, 2, 0],
      [3, 2, 1],
      [2, 2, 2],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 0]
    ]
  ]
}

const LVL_2_3_2 = {
  id: '2.3.2',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 2], [4, 1], [4, 2], [4, 0], [4, 2], [1, 0], [null]],
    [[2, 0], [3, 0], [3, 1], [3, 1], [3, 1], [3, 0], [null]],
    [[4, 0], [3, 0], [3, 0], [4, 0], [4, 3], [4, 2], [null]],
    [[3, 0], [3, 0], [3, 1], [3, 0], [4, 0], [4, 1], [null]],
    [[4, 3], [3, 0], [4, 2], [4, 1], [3, 1], [4, 2], [null]]
  ],
  validations: [
    [
      [1, 5, 1],
      [2, 5, 0],
      [2, 4, 1],
      [1, 4, 1],
      [0, 4, 3],
      [0, 3, 2],
      [1, 3, 1],
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 0],
      [2, 0, 1]
    ],
    [
      [1, 5, 1],
      [2, 5, 0],
      [2, 4, 2],
      [3, 4, 1],
      [3, 5, 3],
      [4, 5, 0],
      [4, 4, 0],
      [4, 3, 1],
      [3, 3, 1],
      [2, 3, 3],
      [2, 2, 0],
      [2, 1, 0],
      [2, 0, 1]
    ]
  ]
}

const LVL_2_3_3 = {
  id: '2.3.3',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 0], [3, 0], [4, 3], [4, 0], [3, 1], [4, 1], [null]],
    [[3, 0], [3, 1], [3, 1], [3, 1], [4, 2], [4, 2], [null]],
    [[4, 3], [3, 1], [3, 0], [4, 0], [4, 0], [4, 1], [null]],
    [[2, 0], [3, 0], [3, 1], [3, 1], [3, 1], [3, 0], [null]],
    [[4, 1], [4, 2], [4, 1], [4, 3], [4, 1], [1, 0], [null]]
  ],
  validations: [
    [
      [3, 5, 1],
      [2, 5, 3],
      [2, 4, 2],
      [3, 4, 1],
      [4, 4, 0],
      [4, 3, 1],
      [3, 3, 1],
      [2, 3, 3],
      [2, 2, 0],
      [2, 1, 0],
      [2, 0, 2]
    ],
    [
      [3, 5, 1],
      [2, 5, 3],
      [2, 4, 1],
      [1, 4, 2],
      [1, 5, 0],
      [0, 5, 3],
      [0, 4, 0],
      [0, 3, 2],
      [1, 3, 1],
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 0],
      [2, 0, 2]
    ]
  ]
}

const LVL_2_3_4 = {
  id: '2.3.4',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 0], [3, 0], [4, 1], [4, 3], [3, 1], [4, 1], [null]],
    [[4, 3], [4, 3], [3, 0], [3, 0], [3, 0], [3, 0], [null]],
    [[4, 0], [4, 3], [4, 1], [3, 1], [3, 1], [4, 2], [null]],
    [[3, 0], [3, 1], [3, 1], [3, 1], [3, 0], [2, 0], [null]],
    [[1, 0], [4, 0], [4, 2], [4, 0], [4, 3], [4, 0], [null]]
  ],
  validations: [
    [
      [3, 0, 1],
      [2, 0, 2],
      [2, 1, 3],
      [3, 1, 1],
      [4, 1, 1],
      [4, 2, 0],
      [3, 2, 1],
      [2, 2, 2],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 3]
    ],
    [
      [3, 0, 1],
      [2, 0, 2],
      [2, 1, 0],
      [1, 1, 3],
      [1, 0, 1],
      [0, 0, 2],
      [0, 1, 0],
      [0, 2, 3],
      [1, 2, 1],
      [2, 2, 1],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 3]
    ]
  ]
}

const LVL_3_1_1 = {
  id: '3.1.1',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[3, 1], [4, 2], [3, 0], [4, 1], [4, 1], [4, 2], [null]],
    [[2, 0], [4, 3], [4, 3], [4, 0], [3, 0], [1, 0], [null]],
    [[4, 1], [3, 1], [4, 1], [3, 0], [4, 0], [3, 0], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [1, 5, 3],
      [1, 4, 2],
      [2, 4, 1],
      [3, 4, 0],
      [3, 3, 0],
      [3, 2, 1],
      [2, 2, 2],
      [2, 3, 0],
      [1, 3, 3],
      [1, 2, 0],
      [1, 1, 2],
      [2, 1, 0]
    ],
    [
      [2, 4, 0],
      [2, 3, 1],
      [1, 3, 3],
      [1, 2, 0],
      [1, 1, 2],
      [2, 1, 0]
    ]
  ]
}

const LVL_3_1_2 = {
  id: '3.1.2',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[4, 2], [3, 1], [4, 2], [3, 0], [4, 3], [3, 0], [null]],
    [[2, 0], [4, 1], [4, 0], [4, 3], [3, 0], [1, 0], [null]],
    [[3, 1], [4, 1], [3, 0], [4, 2], [4, 2], [4, 1], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [3, 5, 0],
      [3, 4, 1],
      [2, 4, 1],
      [1, 4, 3],
      [1, 3, 0],
      [1, 2, 2],
      [2, 2, 1],
      [2, 3, 3],
      [3, 3, 0],
      [3, 2, 0],
      [3, 1, 1],
      [2, 1, 3]
    ],
    [
      [2, 4, 0],
      [2, 3, 2],
      [3, 3, 0],
      [3, 2, 0],
      [3, 1, 1],
      [2, 1, 3]
    ]
  ]
}

const LVL_3_1_3 = {
  id: '3.1.3',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[3, 0], [4, 2], [3, 0], [4, 3], [3, 1], [4, 3], [null]],
    [[1, 0], [3, 0], [4, 2], [4, 1], [4, 1], [2, 0], [null]],
    [[4, 0], [4, 3], [4, 3], [3, 0], [4, 0], [3, 1], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [3, 0, 1],
      [3, 1, 0],
      [2, 1, 1],
      [1, 1, 2],
      [1, 2, 0],
      [1, 3, 3],
      [2, 3, 0],
      [2, 2, 2],
      [3, 2, 1],
      [3, 3, 0],
      [3, 4, 0],
      [2, 4, 2]
    ],
    [
      [2, 1, 0],
      [2, 2, 3],
      [3, 2, 1],
      [3, 3, 0],
      [3, 4, 0],
      [2, 4, 2]
    ]
  ]
}

const LVL_3_1_4 = {
  id: '3.1.4',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[null], [null], [null], [null], [null], [null], [null]],
    [[4, 3], [4, 0], [4, 0], [3, 0], [4, 3], [3, 1], [null]],
    [[1, 0], [3, 0], [4, 1], [4, 2], [4, 2], [2, 0], [null]],
    [[3, 0], [4, 1], [3, 0], [4, 0], [3, 1], [4, 0], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [1, 0, 2],
      [1, 1, 3],
      [2, 1, 1],
      [3, 1, 1],
      [3, 2, 0],
      [3, 3, 0],
      [2, 3, 3],
      [2, 2, 1],
      [1, 2, 2],
      [1, 3, 0],
      [1, 4, 3],
      [2, 4, 1]
    ],
    [
      [2, 1, 0],
      [2, 2, 0],
      [1, 2, 2],
      [1, 3, 0],
      [1, 4, 3],
      [2, 4, 1]
    ]
  ]
}

const LVL_3_2_1 = {
  id: '3.2.1',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[3, 0], [4, 3], [3, 1], [4, 2], [4, 2], [4, 2], [null]],
    [[4, 1], [3, 1], [3, 1], [3, 1], [4, 3], [3, 1], [null]],
    [[1, 0], [3, 1], [4, 3], [4, 1], [4, 0], [2, 0], [null]],
    [[4, 2], [4, 1], [4, 2], [3, 1], [3, 1], [4, 2], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 1, 0],
      [2, 2, 3],
      [3, 2, 1],
      [3, 3, 0],
      [3, 4, 0],
      [3, 5, 0]
    ],
    [
      [1, 0, 2],
      [1, 1, 0],
      [1, 2, 0],
      [1, 3, 0],
      [1, 4, 0],
      [0, 4, 2],
      [0, 5, 3],
      [1, 5, 1]
    ],
    [
      [1, 0, 2],
      [1, 1, 0],
      [1, 2, 0],
      [1, 3, 0],
      [1, 4, 3],
      [2, 4, 1]
    ],
    [
      [3, 0, 1],
      [3, 1, 0],
      [2, 1, 1],
      [1, 1, 1],
      [0, 1, 2],
      [0, 2, 0],
      [0, 3, 3],
      [1, 3, 1],
      [2, 3, 0],
      [2, 2, 2],
      [3, 2, 1],
      [3, 3, 0],
      [3, 4, 0],
      [3, 5, 0]
    ]
  ]
}

const LVL_3_2_2 = {
  id: '3.2.2',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 1], [4, 2], [4, 1], [3, 1], [3, 1], [4, 1], [null]],
    [[1, 0], [3, 1], [4, 0], [4, 2], [4, 3], [2, 0], [null]],
    [[4, 2], [3, 1], [3, 1], [3, 1], [4, 0], [3, 1], [null]],
    [[3, 0], [4, 0], [3, 1], [4, 1], [4, 1], [4, 1], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [1, 1, 0],
      [1, 2, 0],
      [0, 2, 2],
      [0, 3, 0],
      [0, 4, 0],
      [0, 5, 3]
    ],
    [
      [2, 0, 1],
      [2, 1, 0],
      [2, 2, 0],
      [2, 3, 0],
      [2, 4, 3],
      [3, 4, 1],
      [3, 5, 0],
      [2, 5, 1]
    ],
    [
      [2, 0, 1],
      [2, 1, 0],
      [2, 2, 0],
      [2, 3, 0],
      [2, 4, 0],
      [1, 4, 2]
    ],
    [
      [0, 0, 2],
      [0, 1, 3],
      [1, 1, 1],
      [2, 1, 1],
      [3, 1, 1],
      [3, 2, 0],
      [3, 3, 0],
      [2, 3, 1],
      [1, 3, 3],
      [1, 2, 1],
      [0, 2, 2],
      [0, 3, 0],
      [0, 4, 0],
      [0, 5, 3]
    ]
  ]
}

const LVL_3_2_3 = {
  id: '3.2.3',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 0], [3, 1], [3, 1], [4, 0], [4, 3], [4, 0], [null]],
    [[2, 0], [4, 2], [4, 3], [4, 1], [3, 1], [1, 0], [null]],
    [[3, 1], [4, 1], [3, 1], [3, 1], [3, 1], [4, 3], [null]],
    [[4, 0], [4, 0], [4, 0], [3, 1], [4, 1], [3, 0], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 5, 0],
      [2, 4, 0],
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 1],
      [1, 1, 3]
    ],
    [
      [2, 5, 0],
      [2, 4, 0],
      [2, 3, 0],
      [2, 2, 0],
      [2, 1, 2],
      [3, 1, 0],
      [3, 0, 1],
      [2, 0, 1]
    ],
    [
      [1, 4, 0],
      [1, 3, 1],
      [0, 3, 3],
      [0, 2, 0],
      [0, 1, 0],
      [0, 0, 2]
    ],
    [
      [0, 5, 3],
      [0, 4, 2],
      [1, 4, 1],
      [2, 4, 1],
      [3, 4, 0],
      [3, 3, 0],
      [3, 2, 1],
      [2, 2, 1],
      [1, 2, 2],
      [1, 3, 0],
      [0, 3, 3],
      [0, 2, 0],
      [0, 1, 0],
      [0, 0, 2]
    ]
  ]
}

const LVL_3_2_4 = {
  id: '3.2.4',
  timeInSeconds: TIME_PER_LEVEL,
  matrix: [
    [[4, 3], [4, 3], [4, 3], [3, 1], [4, 2], [3, 0], [null]],
    [[3, 1], [4, 2], [3, 1], [3, 1], [3, 1], [4, 0], [null]],
    [[2, 0], [4, 1], [4, 0], [4, 2], [3, 1], [1, 0], [null]],
    [[4, 3], [3, 1], [3, 1], [4, 3], [4, 0], [4, 3], [null]],
    [[null], [null], [null], [null], [null], [null], [null]]
  ],
  validations: [
    [
      [2, 4, 0],
      [2, 3, 2],
      [3, 3, 0],
      [3, 2, 0],
      [3, 1, 0],
      [3, 0, 1]
    ],
    [
      [1, 5, 3],
      [1, 4, 0],
      [1, 3, 0],
      [1, 2, 0],
      [1, 1, 1],
      [0, 1, 3],
      [0, 0, 2],
      [1, 0, 1]
    ],
    [
      [1, 5, 3],
      [1, 4, 0],
      [1, 3, 0],
      [1, 2, 0],
      [1, 1, 2],
      [2, 1, 0]
    ],
    [
      [3, 5, 0],
      [3, 4, 1],
      [2, 4, 1],
      [1, 4, 1],
      [0, 4, 3],
      [0, 3, 0],
      [0, 2, 2],
      [1, 2, 1],
      [2, 2, 1],
      [2, 3, 3],
      [3, 3, 0],
      [3, 2, 0],
      [3, 1, 0],
      [3, 0, 1]
    ]
  ]
}

const LVL_3_3_1 = {
  id: '3.3.1',
  timeInSeconds: 120,
  matrix: [
    [[4, 0], [3, 0], [4, 0], [4, 0], [3, 0], [4, 0], [null]],
    [
      [1, 0],
      [3, 0],
      [4, 2],
      [3, 0],
      [4, 0],
      [3, 0],
      [4, 0]
    ],
    [[null], [4, 0], [4, 3], [4, 0], [3, 0], [4, 0], [2, 0]],
    [[null], [3, 0], [4, 0], [3, 0], [3, 0], [3, 0], [4, 0]],
    [[null], [4, 0], [4, 0], [4, 0], [3, 0], [4, 0], [null]]
  ],
  validations: [
    [
      [1, 1, 0],
      [1, 2, 3],
      [2, 2, 0],
      [2, 1, 2],
      [3, 1, 1],
      [4, 1, 1],
      [4, 2, 0],
      [3, 2, 2],
      [3, 3, 0],
      [3, 4, 0],
      [3, 5, 0],
      [3, 6, 0]
    ],
    [
      [1, 1, 0],
      [1, 2, 3],
      [2, 2, 1],
      [2, 3, 3],
      [3, 3, 1],
      [4, 3, 1],
      [4, 4, 0],
      [4, 5, 0],
      [3, 5, 1],
      [2, 5, 2]
    ],
    [
      [1, 1, 0],
      [1, 2, 3],
      [2, 2, 1],
      [2, 3, 0],
      [1, 3, 1],
      [0, 3, 2],
      [0, 4, 0],
      [0, 5, 3],
      [1, 5, 1],
      [2, 5, 1]
    ],
    [
      [1, 1, 0],
      [1, 2, 0],
      [0, 2, 2],
      [0, 3, 3],
      [1, 3, 1],
      [2, 3, 0],
      [2, 2, 2],
      [3, 2, 1],
      [3, 3, 0],
      [3, 4, 0],
      [3, 5, 0],
      [3, 6, 0]
    ]
  ]
}

export const getTestLvlForRnd = () => [_.sample([LVL_1_1_1, LVL_1_1_2, LVL_1_1_3, LVL_1_1_4])]

export const getLvlForRnd = () => [
  _.sample([LVL_1_2_1, LVL_1_2_2, LVL_1_2_3, LVL_1_2_4]),
  _.sample([LVL_1_3_1, LVL_1_3_2, LVL_1_3_3, LVL_1_3_4]),
  _.sample([LVL_2_1_1, LVL_2_1_2, LVL_2_1_3, LVL_2_1_4]),
  _.sample([LVL_2_2_1, LVL_2_2_2, LVL_2_2_3, LVL_2_2_4]),
  _.sample([LVL_2_3_1, LVL_2_3_2, LVL_2_3_3, LVL_2_3_4])
]

export const NOT_USED_DATA = {
  TEST_LVL_1,
  TEST_LVL_2_1,
  TEST_LVL_2_2,
  TEST_LVL_3_1,
  TEST_LVL_3_2,
  LVL_3_1_1,
  LVL_3_1_2,
  LVL_3_1_3,
  LVL_3_1_4,
  LVL_3_2_1,
  LVL_3_2_2,
  LVL_3_2_3,
  LVL_3_2_4,
  LVL_3_3_1
}
