import React, { useState } from 'react'
import { View, SafeAreaView, Image, TouchableOpacity, StyleSheet, Text } from 'react-native'
import { I18n } from 'aws-amplify'
import { useNavigation } from '@react-navigation/native'
import PropTypes from 'prop-types'

import metrics from '../../../../theme/metrics'
import colors from '../../../../theme/colors'
import Typo from '../../../../components/Typo'
import Button from '../../../../components/Button'
import { valueToName } from '../data/heros-journey'
import { replaceTranslationKey } from '../../../../utils/helpers'

const s = StyleSheet.create({
  missionCard: {
    shadowColor: '#E3E4E6',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.5,
    shadowRadius: 12,
    elevation: 2,
    width: metrics.widthPercentageToDP(42),
    height: metrics.widthPercentageToDP(33),
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16
  },
  missionCardTitle: {
    fontSize: 16,
    fontFamily: 'fira-sans-semi-bold',
    color: colors.primaryBlue,
    textAlign: 'center'
  }
})

const customMDStylesMission = StyleSheet.create({
  heading: {
    fontFamily: 'fira-sans-regular'
  },
  text: {
    fontSize: 16,
    lineHeight: 22,
    fontFamily: 'fira-sans-light',
    color: colors.darkBlack,
    textAlign: 'center'
  },
  strong: {
    fontFamily: 'fira-sans-medium',
    color: colors.primaryBlue,
    fontSize: 16
  }
})

const Mission = ({ hero, role_suggestion, onPress }) => {
  const active = hero.value === role_suggestion
  const activeContainerStyle = {
    backgroundColor: colors.primaryBlue,
    shadowColor: colors.powderBlue
  }
  const activeTextStyle = { color: 'white' }

  return (
    <TouchableOpacity style={[s.missionCard, active ? activeContainerStyle : {}]} onPress={onPress}>
      <Text style={[s.missionCardTitle, active ? activeTextStyle : {}]}>{I18n.get(hero.name)}</Text>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Image
          source={hero.image}
          style={{
            width: metrics.widthPercentageToDP(14),
            height: metrics.widthPercentageToDP(14)
          }}
          resizeMode='contain'
        />
      </View>
    </TouchableOpacity>
  )
}

Mission.propTypes = {
  hero: PropTypes.object.isRequired,
  role_suggestion: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired
}

const HerosJourneyMission = ({ heros, role_suggestion, loading, onPress }) => {
  const navigation = useNavigation()

  const [pressedHero, setPressedHero] = useState(role_suggestion)
  const [roleSuggestion] = useState(role_suggestion)

  const onPressMissionCard = (pressedHeroShadow) => {
    navigation.navigate('HerosJourneySelection', {
      pressedHero: pressedHeroShadow,
      heros,
      onPress: (hero) => setPressedHero(hero)
    })
  }

  return (
    <SafeAreaView
      forceInset={{ top: 'always' }}
      style={{ flex: 1, backgroundColor: colors.whisper }}
    >
      <Typo.H1Blue center marginTop={40} translate>
        challenge.heros_journey.result.your_mission
      </Typo.H1Blue>
      <View style={{ alignItems: 'center' }}>
        <Typo.Markdown center marginTop={16} style={customMDStylesMission}>
          {replaceTranslationKey(
            `${I18n.get('challenge.heros_journey.mission.role')}`,
            '{{{role_suggestion}}}',
            I18n.get(valueToName(roleSuggestion))
          )}
        </Typo.Markdown>
      </View>
      <View
        style={{
          paddingHorizontal: 16,
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginTop: 32
        }}
      >
        <Mission
          key={0}
          hero={heros[0]}
          role_suggestion={pressedHero}
          onPress={() => onPressMissionCard(heros[0].value)}
        />
        <Mission
          key={1}
          hero={heros[1]}
          role_suggestion={pressedHero}
          onPress={() => onPressMissionCard(heros[1].value)}
        />
      </View>
      <View
        style={{
          paddingHorizontal: 16,
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginTop: 16
        }}
      >
        <Mission
          key={2}
          hero={heros[2]}
          role_suggestion={pressedHero}
          onPress={() => onPressMissionCard(heros[2].value)}
        />
        <Mission
          key={3}
          hero={heros[3]}
          role_suggestion={pressedHero}
          onPress={() => onPressMissionCard(heros[3].value)}
        />
      </View>
      <Typo.T2Black style={{ color: colors.midGray }} center marginTop={16} translate>
        challenge.heros_journey.mission.description
      </Typo.T2Black>
      <View
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          paddingHorizontal: 24,
          paddingBottom: 24,
          paddingTop: 30
        }}
      >
        <Button
          isLoading={loading}
          onPress={() => onPress(pressedHero, roleSuggestion)}
          secondary
          title='challenge.global.submit'
        />
      </View>
    </SafeAreaView>
  )
}

HerosJourneyMission.propTypes = {
  heros: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  role_suggestion: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired
}

export default HerosJourneyMission
