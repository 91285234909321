import React from 'react'
import { StyleSheet, View } from 'react-native'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { correctText, falseText } from '../data'
// import colors from '../../../../constants/colors'
import { Typo } from '../../../../components'
// import { metrics.widthPercentageToDP } from '../../../../helper/responsive'
import colors from '../../../../theme/colors'
import metrics from '../../../../theme/metrics'

const s = StyleSheet.create({
  alert: {
    width: '100%',
    height: 88,
    marginRight: 24,
    paddingLeft: 26,
    justifyContent: 'center',
    backgroundColor: colors.primaryGreen,
    borderRadius: 4,
    shadowColor: colors.primaryGreen,
    shadowOffset: {
      width: 2,
      height: 1
    },
    shadowOpacity: 0.6,
    shadowRadius: 5,

    elevation: 5
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderTopWidth: 12,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: colors.primaryGreen
  }
})

const Alert = ({ answer, containerStyle }) => {
  if (_.isUndefined(answer)) return null

  const { correctness, decision } = answer

  const alertPosition =
    decision === 0
      ? { marginLeft: metrics.widthPercentageToDP(100) / 4 - 2 }
      : { marginLeft: (metrics.widthPercentageToDP(100) / 4) * 3 - 12 }

  let isAnswerCorrect = false
  if (correctness) {
    // Answer is analogical
    isAnswerCorrect = decision === 1
  } else {
    // Answer is not analogical
    isAnswerCorrect = decision === 0
  }

  return (
    <View style={containerStyle}>
      <View style={{ paddingHorizontal: 24 }}>
        <View
          style={[
            s.alert,
            !isAnswerCorrect ? { backgroundColor: colors.salmon, shadowColor: colors.salmon } : {}
          ]}
        >
          <Typo.H2White translate>
            {isAnswerCorrect ? correctText.title : falseText.title}
          </Typo.H2White>
          <Typo.T1White translate>
            {_.sample(isAnswerCorrect ? correctText.subTitle : falseText.subTitle)}
          </Typo.T1White>
        </View>
      </View>
      <View
        style={[
          s.triangle,
          !isAnswerCorrect ? { borderTopColor: colors.salmon } : {},
          alertPosition
        ]}
      />
    </View>
  )
}

Alert.propTypes = {
  answer: PropTypes.object,
  containerStyle: PropTypes.object.isRequired
}

Alert.defaultProps = {
  answer: undefined
}

export default Alert
