import React, { useState, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react'
import { isEmpty } from 'lodash'
import { View, StyleSheet, Animated, Platform } from 'react-native'
import { I18n } from 'aws-amplify'

import { Typo } from '.'
import colors from '../theme/colors'

const ANIMATION_DURATION = 400

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 42,
    width: '100%',
    paddingHorizontal: 24,
    zIndex: 20
  },
  animatedView: {
    width: '100%',
    height: '100%',
    maxWidth: 400,
    borderRadius: 8,
    borderLeftWidth: 8,
    padding: 16,
    shadowColor: colors.darkBlack,
    shadowOffset: {
      width: 0,
      height: 10
    },
    shadowOpacity: 0.3,
    shadowRadius: 30,
    elevation: 14
  }
})

const Notification = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  const [alertData, setAlertData] = useState({})
  const [opacity] = useState(new Animated.Value(0))

  const close = useCallback(() => {
    Animated.timing(opacity, {
      toValue: 0,
      duration: ANIMATION_DURATION,
      useNativeDriver: Platform.OS !== 'web'
    }).start(() => setIsOpen(false) || setAlertData({}))
  }, [opacity])

  useEffect(() => {
    if (isEmpty(alertData)) return

    setIsOpen(true)

    Animated.timing(opacity, {
      toValue: 1,
      duration: ANIMATION_DURATION,
      useNativeDriver: Platform.OS !== 'web'
    }).start(() => setTimeout(close, 4000))
  }, [alertData, opacity, close])

  useImperativeHandle(ref, () => ({
    alertWithType(type, message) {
      setAlertData({
        type,
        message
      })
    }
  }))

  const getBorderLeftColor = () => {
    switch (alertData.type) {
      case 'success':
        return colors.primaryGreen
      case 'error':
        return colors.error
      case 'notice':
        return colors.darkBlack
      default:
        return colors.darkBlack
    }
  }

  if (!isOpen) return null

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          styles.animatedView,
          { borderLeftColor: getBorderLeftColor(), backgroundColor: 'white', opacity }
        ]}
      >
        <Typo.T1Black>{I18n.get(alertData.message || 'notification.error')}</Typo.T1Black>
      </Animated.View>
    </View>
  )
})

export default Notification
