import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { getStringAsync } from 'expo-clipboard'
import * as Linking from 'expo-linking'
import { Platform } from 'react-native'
import { Auth } from 'aws-amplify'

import asyncStorage from '../utils/async-storage'
import initializeApp from '../utils/initializeApp'
import { notifyBugsnag } from '../utils/bugsnag'
import { ContentLoader, ErrorBoundaryFallBackComponent } from '../components'
import { query } from '../graphql'
import { ALL_CHALLENGES } from '../constants/challenges'
import { useMount } from '../hooks/use-mount'

const AppSettingsParamsWrapper = ({ setInvitationState, rootStore, url, children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  useMount(() => {
    setIsLoading(true)

    const aivyAlreadyOpened = async () => {
      const already_seen = await asyncStorage.getData('ALREADY_SEEN')

      if (!already_seen) await asyncStorage.storeData('ALREADY_SEEN', true)

      return !!already_seen
    }

    const checkClipboardUrl = async () => {
      let clipboardURL = null
      const alreadySeen = await aivyAlreadyOpened()

      if (!alreadySeen && Platform.OS !== 'web') {
        try {
          clipboardURL = await getStringAsync()

          if (clipboardURL) {
            const {
              queryParams: { invitation, partner }
            } = Linking.parse(clipboardURL)

            if (!invitation && !partner) clipboardURL = null
          }
        } catch (err) {
          clipboardURL = null
          notifyBugsnag(err)
        }
      }

      return clipboardURL
    }

    async function setChallengesFromSystem() {
      try {
        const challenges = await query({
          query: 'getSystem',
          variables: { id: 'CHALLENGES' },
          authMode: 'AWS_IAM'
        })

        const parsedChallenges = JSON.parse(challenges.data.getSystem.data)

        // Filter active challenges
        // eslint-disable-next-line no-restricted-syntax
        for (const [key] of Object.entries(parsedChallenges)) {
          if (parsedChallenges[key].active !== true) {
            delete parsedChallenges[key]
          }
        }

        if (Object.keys(ALL_CHALLENGES).length === 0) {
          Object.assign(ALL_CHALLENGES, parsedChallenges)
          Object.freeze(ALL_CHALLENGES)
        }
      } catch (err) {
        notifyBugsnag(new Error(`[setChallengesFromSystem] ${err.message || JSON.stringify(err)}`))
      }
    }

    async function doAsync() {
      await setChallengesFromSystem()

      const clipboardURL = await checkClipboardUrl()

      const initUrlBeforeUpdate = await asyncStorage.getData('initUrlBeforeUpdate')
      if (initUrlBeforeUpdate) await asyncStorage.removeData('initUrlBeforeUpdate')

      let resultUrl
      if (clipboardURL) resultUrl = clipboardURL
      else if (initUrlBeforeUpdate) resultUrl = initUrlBeforeUpdate
      else resultUrl = url

      resultUrl = resultUrl.replace(/\s/g, '')
      rootStore.userStore.setInitialLink(resultUrl)

      try {
        const currentUserInfo = await Auth.currentUserInfo()
        if (currentUserInfo === undefined || currentUserInfo === null) {
          await initializeApp(resultUrl, setInvitationState, rootStore)
        }
      } catch (err) {
        setError(true)
        notifyBugsnag(new Error(`[initAppWithUrl] ${err.message || JSON.stringify(err)}`))
      } finally {
        setIsLoading(false)
      }
    }

    doAsync()
  })

  if (isLoading) {
    return <ContentLoader />
  }

  if (error) {
    return <ErrorBoundaryFallBackComponent />
  }

  return children
}

AppSettingsParamsWrapper.propTypes = {
  setInvitationState: PropTypes.func.isRequired,
  rootStore: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default AppSettingsParamsWrapper
