export const SPACE = `{
  id
  all_challenges_unlocked
  history {
    eventName
    beforeStatus
    statusOption
    afterStatus
    createdAt
    message
    eventFiredBy
  }
  newsletter
  progress
  partner_id
  career_id
  partner_user_id
  language
  ref_link
  matching_score_to_space_career
  related_spaces
  invitation_id
  context
  callback_url
  expiresAt
  created_with_service
  invitation_reminder_at
  createdAt
  updatedAt
}`
