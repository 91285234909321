import { observer } from 'mobx-react'
import React, { useContext, useRef, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Platform, Image } from 'react-native'
import { Modalize } from 'react-native-modalize'
import PropTypes from 'prop-types'
import * as Linking from 'expo-linking'

import { I18n } from 'aws-amplify'
import { AuthContext } from '../../context'
import colors from '../../theme/colors'
import Typo from '../Typo'
import Interests from './Interests'
import Personality from './Personality'
import Skills from './Skills'
import StrengthEmpty from './StrengthEmpty'
import { rowCenterBetween } from '../../styles'
import metrics from '../../theme/metrics'
import { query } from '../../graphql'
import { notifyBugsnag } from '../../utils/bugsnag'
import { checkPercentiles } from '../../utils/check-percentiles'
import asyncStorage from '../../utils/async-storage'
import { trackEvent } from '../../utils/tracking'

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 20
  },
  tabStyle: {
    flex: 1,
    borderBottomWidth: 2,
    borderBottomColor: colors.mediumGrey
  },
  activeTabStyle: {
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 2,
    borderBottomColor: colors.darkBlack
  },
  inactiveTabTextStyle: {
    color: colors.mediumGrey
  },
  saveStrengthContainer: {
    width: '100%',
    height: 160,
    paddingHorizontal: 20,
    paddingVertical: 32,
    backgroundColor: colors.darkBlue,
    marginBottom: 40,
    borderRadius: 18
  }
})

const STRENGTHS = {
  INTERESTS: 'INTERESTS',
  PERSONALITY: 'PERSONALITY',
  SKILLS: 'SKILLS'
}

const StrengthModalize = ({ navigateFirstChallenge, open, navigation }) => {
  const modalizeRef = useRef(null)
  const [currentStrengthTab, setCurrentStrengthTab] = useState(STRENGTHS.INTERESTS)
  const { rootStore } = useContext(AuthContext)
  const { isAnonym, userProfile } = rootStore.userStore
  const { answers } = rootStore.answersStore
  const { challengeConfigs } = rootStore.appSettingsStore
  const strengthEmpty = rootStore.challengesStore.progress === 0
  const [modalPosition, setModalPosition] = useState('initial')
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)

  const isActiveInterests = currentStrengthTab === STRENGTHS.INTERESTS
  const isActivePersonality = currentStrengthTab === STRENGTHS.PERSONALITY
  const isActiveSkills = currentStrengthTab === STRENGTHS.SKILLS

  // const solvedRiasec = rootStore.answersStore.getAnswerForChallenge('RIASEC_IMAGES') !== undefined
  // const solvedBigFive = rootStore.answersStore.getAnswerForChallenge('BIG_FIVE') !== undefined

  const onClose = () => {
    modalizeRef.current?.close('alwaysOpen')
  }

  const downloadStrengthProfile = async () => {
    if (isAnonym) {
      onClose()
      navigation.navigate('SaveStrengthDNA')
      return
    }

    setIsLoadingPdf(true)
    try {
      const percentiles = checkPercentiles(userProfile, answers, challengeConfigs)
      if (percentiles) {
        await rootStore.userStore.updateUser(percentiles)
      }

      const language = (await asyncStorage.getData('lang')) || 'de'

      const pdf = await query({
        query: 'generatePdf',
        variables: { user_id: rootStore.userStore.username, language }
      })
      trackEvent('StrengthProfileDownloaded')
      Linking.openURL(JSON.parse(pdf.data.generatePdf).headers.Location)
    } catch (error) {
      notifyBugsnag(error)
    } finally {
      setIsLoadingPdf(false)
    }
  }

  const renderContent = () => (
    <View style={[styles.content, { paddingTop: modalPosition === 'initial' ? 18 : 32 }]}>
      {modalPosition === 'initial' ? (
        <View
          style={rowCenterBetween}
          // onPress={() => {
          //   modalizeRef.current?.open('top')
          // }}
        >
          <Typo.H3Black translate>strength.strengthprofile</Typo.H3Black>
          <View style={{ width: 96 }}>
            {rootStore.challengesStore.progress !== 100 && (
              <Image
                source={require('../../assets/images/dna-empty.png')}
                style={{
                  height: 40,
                  width: '100%',
                  position: 'absolute'
                }}
              />
            )}
            <View style={{ width: `${rootStore.challengesStore.progress}%`, overflow: 'hidden' }}>
              <Image
                source={require('../../assets/images/dna-full.png')}
                style={{
                  height: 40,
                  width: 96
                }}
              />
            </View>
          </View>
        </View>
      ) : (
        <View>
          <View style={[rowCenterBetween, { paddingTop: 12, paddingBottom: 24 }]}>
            <Typo.H1Black translate>strength.strengthprofile</Typo.H1Black>
            <TouchableOpacity
              onPress={() => {
                setModalPosition('initial')
                onClose()
              }}
            >
              <Image
                source={require('../../assets/images/x-icon-grey.png')}
                style={{ height: 32, width: 32 }}
              />
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            style={styles.saveStrengthContainer}
            disabled={isLoadingPdf}
            onPress={() => downloadStrengthProfile()}
          >
            <Image
              style={{
                ...metrics.calculateResponsiveDimensions({ height: 106, width: 131 }),
                position: 'absolute',
                right: 6,
                bottom: 14
              }}
              source={require('../../assets/images/strengthprofile-preview.png')}
            />
            <Typo.T2White translate style={{ width: '60%' }}>
              strength.strengthprofile.download.text
            </Typo.T2White>

            <Typo.ButtonWhite style={{ marginTop: 14, color: colors.brightGreen }}>
              {isLoadingPdf
                ? I18n.get('strength.strengthprofile.download.button.isLoading')
                : I18n.get('strength.strengthprofile.download.button')}
            </Typo.ButtonWhite>
          </TouchableOpacity>

          <View
            style={{ flexDirection: 'row', justifyContent: 'center', width: '100%', height: 32 }}
          >
            <TouchableOpacity
              onPress={() => setCurrentStrengthTab(STRENGTHS.INTERESTS)}
              style={[styles.tabStyle, isActiveInterests ? styles.activeTabStyle : {}]}
            >
              <Typo.T1Black
                style={!isActiveInterests ? styles.inactiveTabTextStyle : {}}
                translate
                center
              >
                global.strength_modalize.tab_interests
              </Typo.T1Black>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setCurrentStrengthTab(STRENGTHS.PERSONALITY)}
              style={[styles.tabStyle, isActivePersonality ? styles.activeTabStyle : {}]}
            >
              <Typo.T1Black
                center
                style={!isActivePersonality ? styles.inactiveTabTextStyle : {}}
                translate
              >
                global.strength_modalize.tab_personality
              </Typo.T1Black>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setCurrentStrengthTab(STRENGTHS.SKILLS)}
              style={[styles.tabStyle, isActiveSkills ? styles.activeTabStyle : {}]}
            >
              <Typo.T1Black
                center
                style={!isActiveSkills ? styles.inactiveTabTextStyle : {}}
                translate
              >
                global.strength_modalize.tab_skills
              </Typo.T1Black>
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1 }}>
            {isActiveInterests && <Interests />}
            {isActivePersonality && <Personality />}
            {isActiveSkills && <Skills />}
          </View>
        </View>
      )}
    </View>
  )

  if (!open) return null

  return (
    <Modalize
      ref={modalizeRef}
      adjustToContentHeight={strengthEmpty}
      alwaysOpen={90}
      avoidKeyboardLikeIOS
      handlePosition='inside'
      useNativeDriver={Platform.OS !== 'web'}
      onPositionChange={(position) => setModalPosition(position)}
    >
      {strengthEmpty ? (
        <StrengthEmpty navigateFirstChallenge={navigateFirstChallenge} onClose={onClose} />
      ) : (
        renderContent()
      )}
    </Modalize>
  )
}

StrengthModalize.propTypes = {
  navigateFirstChallenge: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  navigation: PropTypes.object.isRequired
}

export default observer(StrengthModalize)
