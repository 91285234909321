import { makeAutoObservable } from 'mobx'
import _ from 'lodash'

import { ALL_CHALLENGES } from '../constants/challenges'
import { moveObjectToFirst, removeDuplicates } from '../utils/helpers'
import { updateEndpoint } from '../utils/analytics'

class ChallengesStore {
  constructor() {
    this.challenges = []
    this.completedChallenges = []
    this.progress = 0
    this.calledSpaceComplete = false
    this.allChallengesCount = 0

    makeAutoObservable(this)
  }

  initChallenges(allAnswers, rootStore) {
    if (!allAnswers) return
    const { randomChallenges } = rootStore.appSettingsStore.components
    this.progress = rootStore?.spaceStore?.active_space?.progress || 0
    const { all_challenges_unlocked } = rootStore?.spaceStore?.active_space || {}
    this.completedChallenges = []

    let challenges =
      all_challenges_unlocked === true
        ? Object.values(ALL_CHALLENGES)
        : rootStore.appSettingsStore.challenges.map(
            (challenge) => ALL_CHALLENGES[challenge.exam_id]
          )

    challenges = challenges.filter((n) => n)

    this.allChallengesCount = challenges.length

    if (challenges.filter((c) => c.exam_id === 'RIASEC_IMAGES').length > 0) {
      challenges = moveObjectToFirst(
        challenges,
        challenges.filter((c) => c.exam_id === 'RIASEC_IMAGES')[0]
      )
    }

    if (challenges.length === 0) return

    challenges = removeDuplicates(challenges, 'exam_id')

    this.challenges = randomChallenges === true ? _.shuffle(challenges) : challenges

    const filteredAnswers = ChallengesStore.filterAnswers(allAnswers)
    this.splitAnswers(filteredAnswers, rootStore)
  }

  splitAnswers(allAnswers, rootStore) {
    const completedChallenges = []

    allAnswers.forEach((answer) => {
      const ob = Object.assign(ALL_CHALLENGES[answer.exam_id], answer)
      completedChallenges.push(ob)
      this.deleteFromChallenges(answer.exam_id)
    })

    this.completedChallenges = removeDuplicates(completedChallenges, 'exam_id')

    const progress = Math.round(
      (100 * this.completedChallenges.length) /
        (this.challenges.length + this.completedChallenges.length)
    )

    if (progress === 100 && this.progress !== 100) {
      // if (rootStore.appSettingsStore.components?.fbpixel && Platform.OS === 'web') {
      //   document.title = 'Aivy Assessment abgeschlossen'
      //   // eslint-disable-next-line no-undef
      //   fbq('init', rootStore.appSettingsStore.components?.fbpixel)
      //   // eslint-disable-next-line no-undef
      //   fbq('track', 'PageView')
      // }
      if (this.calledSpaceComplete === false) {
        // if (redirectUrl) {
        //   setTimeout(() => {
        //     console.log('CALL OPEN URL WITH', redirectUrl)
        //     openUrl(redirectUrl)
        //     sendSlackMessage(
        //       'aivy-realtime-messages',
        //       `Successfully redirected from Aivy App to: ${rootStore.appSettingsStore.careerRedirectUrl}`
        //     )
        //   }, 1000)
        // }
        this.calledSpaceComplete = true
      }

      if (
        rootStore.userStore.cognitoUser &&
        typeof rootStore.userStore.isAnonym === 'boolean' &&
        !rootStore.userStore.isAnonym
      ) {
        updateEndpoint({
          userStore: rootStore.userStore,
          userAttributes: {
            frontend: ['aivy-universal-app'],
            progress: ['100']
          }
        })
      }
    } else {
      // if (progress > 0) {
      //   rootStore.spaceStore.updateSpace({ progress })
      // }
      if (
        rootStore.userStore.cognitoUser &&
        typeof rootStore.userStore.isAnonym === 'boolean' &&
        !rootStore.userStore.isAnonym
      ) {
        updateEndpoint({
          userStore: rootStore.userStore,
          userAttributes: {
            frontend: ['aivy-universal-app'],
            progress: [`${progress}`]
          }
        })
      }
    }
    this.progress = progress

    return completedChallenges
  }

  static filterAnswers(allAnswers) {
    const challenges = []

    allAnswers.forEach((answer) => {
      if (!ALL_CHALLENGES[answer.exam_id]) {
        return
      }
      if (!challenges[answer.exam_id]) {
        challenges[answer.exam_id] = []
      }
      challenges[answer.exam_id].push(answer)
    })

    return Object.values(challenges).map((a) => a[0])
  }

  move(exam) {
    this.deleteFromChallenges(exam.exam_id)
    this.completedChallenges.push(ALL_CHALLENGES[exam.exam_id])
    this.completedChallenges = removeDuplicates(this.completedChallenges, 'exam_id')

    const progress = Math.round(
      (100 * this.completedChallenges.length) /
        (this.challenges.length + this.completedChallenges.length)
    )

    this.progress = progress
  }

  deleteFromChallenges(exam_id) {
    this.challenges.find((e, index) => {
      if (e.exam_id === exam_id) {
        this.challenges.splice(index, 1)
      }
      return e.exam_id === exam_id
    })
  }

  clear() {
    this.challenges = []
    this.completedChallenges = []
    this.progress = 0
    this.allChallengesCount = 0
  }
}

export default ChallengesStore
