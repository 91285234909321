import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import PropTypes from 'prop-types'
import moment from 'moment'
import { I18n } from 'aws-amplify'

import colors from '../theme/colors'
import Typo from './Typo'
import { replaceTranslationKey } from '../utils/helpers'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'flex-end',
    backgroundColor: colors.oceanDarkBlue,
    paddingHorizontal: 20,
    paddingVertical: 16,
    shadowColor: colors.primaryBlue,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.3,
    shadowRadius: 8,
    elevation: 12
  }
})

const ExpiresAtHint = ({ onPress, expiresAt }) => {
  const currentDate = moment()
  const expirationDate = moment(expiresAt)

  const duration = moment.duration(expirationDate.diff(currentDate))

  const daysDifference = Math.round(duration.asDays())

  const getMessageByDaysDifference = () => {
    switch (daysDifference) {
      case 0:
        return 'expiresat.notification.today'
      case 1:
        return 'expiresat.notification.tomorrow'
      default:
        return replaceTranslationKey(
          I18n.get('expiresat.notification.days'),
          '{{days}}',
          daysDifference
        )
    }
  }

  if (daysDifference >= 7) return null

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
          <Ionicons style={{ marginRight: 8 }} color='#fff' name='ios-timer-outline' size={24} />
          <Typo.T2White style={{ width: '65%', flexWrap: 'wrap' }} translate>
            {getMessageByDaysDifference()}
          </Typo.T2White>
        </View>
        <Ionicons name='close' size={24} color='#fff' onPress={onPress} />
      </View>
    </View>
  )
}

ExpiresAtHint.propTypes = {
  onPress: PropTypes.func.isRequired,
  expiresAt: PropTypes.string.isRequired
}

export default ExpiresAtHint
