import React from 'react'
import { View, Image } from 'react-native'
import PropTypes from 'prop-types'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Button, Typo } from '../../../../components'
import metrics from '../../../../theme/metrics'
import colors from '../../../../theme/colors'
import { I18n } from 'aws-amplify'

const FirstIntro = ({ onPress }) => {
  const insets = useSafeAreaInsets()
  return (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 20 + insets.top,
        backgroundColor: colors.primaryGreen
      }}
    >
      <View style={{ flex: 1 }}>
        <Typo.H1White translate>challenge.numeracy.introduction.01.title</Typo.H1White>
        <Typo.T1White translate marginTop={24}>
          challenge.numeracy.introduction.01.text
        </Typo.T1White>
        <Image
          source={{
            uri: 'https://aivy-assets.s3.eu-central-1.amazonaws.com/challenges/numeracy/numeracy_01.png'
          }}
          style={{
            width: metrics.widthPercentageToDP(39),
            height: metrics.widthPercentageToDP(39) * 1.8,
            borderRadius: 8,
            alignSelf: 'center',
            marginTop: 16
          }}
        />
        {/* <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#fff',
            paddingVertical: 8,
            paddingHorizontal: 8,
            borderRadius: 8,
            marginTop: 16
          }}
        >
          <MaterialCommunityIcons
            name='bank-plus'
            size={32}
            color='black'
            style={{ marginRight: 8 }}
          />
          <Typo.T1Black style={{ flexWrap: 'wrap', flex: 1 }} translate>
            challenge.delegation.firstintro.goal
          </Typo.T1Black>
        </View>

        <Image
          style={{
            alignSelf: 'center',
            marginTop: 32,
            ...metrics.calculateResponsiveDimensions({ width: 150, height: 150 })
          }}
          source={require('../../../../assets/challenges/delegation/card-icon.png')}
        /> */}
      </View>
      <View style={{ paddingBottom: 24 + insets.bottom }}>
        <Button
          type='white'
          onPress={onPress}
          title={I18n.get('button.allright')}
          marginHorizontal={12}
        />
      </View>
    </View>
  )
}

FirstIntro.propTypes = {
  onPress: PropTypes.func.isRequired
}

FirstIntro.defaultProps = {}

export default FirstIntro
