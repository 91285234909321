import { shuffle, inRange } from 'lodash'

export const BLOCK_COUNT = 4

export const DECISION_TYPES = {
  DONT_SHIP: 'DONT_SHIP',
  DELEGATE: 'DELEGATE',
  SHIP: 'SHIP'
}

export const JOB_TYPES = {
  SMALL: 'SMALL',
  BIG: 'BIG'
}

export const TEST_ROUND_TYPES = {
  PRIVATE: 'PRIVATE',
  FAMILY: 'FAMILY',
  FAMILY_MOOD_THERMOMETER: 'FAMILY_MOOD_THERMOMETER'
}

const BLOCK_IDS = [
  shuffle([59, 182, 227, 104, 100, 55, 108, 63, 239, 238]), // SELF
  shuffle([59, 182, 227, 104, 100, 55, 108, 63, 239, 238]), // GROUP
  shuffle([176, 256, 175, 58, 65, 92, 103, 257, 229, 184]), // SELF
  shuffle([176, 256, 175, 58, 65, 92, 103, 257, 229, 184]) // GROUP
  // shuffle([59, 182, 104, 227, 100, 55, 108, 63, 176, 238]), // SELF
  // shuffle([239, 256, 175, 58, 103, 92, 65, 229, 257, 184]), // GROUP
  // shuffle([218, 112, 68, 91, 64, 72, 99, 159, 221, 95]), // SELF
  // shuffle([59, 182, 104, 227, 100, 55, 108, 63, 176, 238]), // GROUP
  // shuffle([239, 256, 175, 58, 103, 92, 65, 229, 257, 184]), // SELF
  // shuffle([218, 112, 68, 91, 64, 72, 99, 159, 221, 95]) // GROUP
]

// const SELF_BLOCKS = [
//   { id: 'B1', group: 'self' },
//   { id: 'B2', group: 'self' },
//   { id: 'B3', group: 'self' },
//   { id: 'B4', group: 'self' },
//   { id: 'B5', group: 'self' },
//   { id: 'B6', group: 'self' }
// ]

// /**
//  * 2x B6 vorher
//  */
// const GROUP_BLOCKS = [
//   { id: 'B1', group: 'group' },
//   { id: 'B2', group: 'group' },
//   { id: 'B3', group: 'group' },
//   { id: 'B4', group: 'group' },
//   { id: 'B5', group: 'group' },
//   { id: 'B6', group: 'group' }
// ]

export const blockrandomSets = [
  [
    { id: 'B1', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B3', group: 'self' },
    { id: 'B1', group: 'group' },
    { id: 'B2', group: 'self' },
    { id: 'B4', group: 'group' },
    { id: 'B5', group: 'self' },
    { id: 'B2', group: 'group' },
    { id: 'B4', group: 'self' },
    { id: 'B3', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B5', group: 'group' }
  ],
  [
    { id: 'B2', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B1', group: 'self' },
    { id: 'B5', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B1', group: 'group' },
    { id: 'B4', group: 'self' },
    { id: 'B3', group: 'group' },
    { id: 'B5', group: 'self' },
    { id: 'B4', group: 'group' },
    { id: 'B3', group: 'self' },
    { id: 'B1', group: 'group' }
  ],
  [
    { id: 'B3', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B2', group: 'self' },
    { id: 'B1', group: 'group' },
    { id: 'B4', group: 'self' },
    { id: 'B5', group: 'group' },
    { id: 'B1', group: 'self' },
    { id: 'B4', group: 'group' },
    { id: 'B5', group: 'self' },
    { id: 'B3', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B2', group: 'group' }
  ],
  [
    { id: 'B4', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B2', group: 'self' },
    { id: 'B1', group: 'group' },
    { id: 'B5', group: 'self' },
    { id: 'B4', group: 'group' },
    { id: 'B3', group: 'self' },
    { id: 'B2', group: 'group' },
    { id: 'B1', group: 'self' },
    { id: 'B3', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B5', group: 'group' }
  ],
  [
    { id: 'B5', group: 'self' },
    { id: 'B4', group: 'group' },
    { id: 'B2', group: 'self' },
    { id: 'B1', group: 'group' },
    { id: 'B4', group: 'self' },
    { id: 'B2', group: 'group' },
    { id: 'B3', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B1', group: 'self' },
    { id: 'B5', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B3', group: 'group' }
  ],
  [
    { id: 'B5', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B3', group: 'self' },
    { id: 'B4', group: 'group' },
    { id: 'B2', group: 'self' },
    { id: 'B5', group: 'group' },
    { id: 'B4', group: 'self' },
    { id: 'B2', group: 'group' },
    { id: 'B1', group: 'self' },
    { id: 'B3', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B1', group: 'group' }
  ],
  [
    { id: 'B1', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B4', group: 'self' },
    { id: 'B5', group: 'group' },
    { id: 'B3', group: 'self' },
    { id: 'B4', group: 'group' },
    { id: 'B2', group: 'self' },
    { id: 'B3', group: 'group' },
    { id: 'B5', group: 'self' },
    { id: 'B1', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B2', group: 'group' }
  ],
  [
    { id: 'B2', group: 'self' },
    { id: 'B4', group: 'group' },
    { id: 'B3', group: 'self' },
    { id: 'B5', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B1', group: 'group' },
    { id: 'B5', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B1', group: 'self' },
    { id: 'B2', group: 'group' },
    { id: 'B4', group: 'self' },
    { id: 'B3', group: 'group' }
  ],
  [
    { id: 'B5', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B3', group: 'self' },
    { id: 'B1', group: 'group' },
    { id: 'B2', group: 'self' },
    { id: 'B3', group: 'group' },
    { id: 'B4', group: 'self' },
    { id: 'B5', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B2', group: 'group' },
    { id: 'B1', group: 'self' },
    { id: 'B4', group: 'group' }
  ],
  [
    { id: 'B3', group: 'self' },
    { id: 'B4', group: 'group' },
    { id: 'B5', group: 'self' },
    { id: 'B1', group: 'group' },
    { id: 'B4', group: 'self' },
    { id: 'B3', group: 'group' },
    { id: 'B2', group: 'self' },
    { id: 'B6', group: 'group' },
    { id: 'B1', group: 'self' },
    { id: 'B2', group: 'group' },
    { id: 'B6', group: 'self' },
    { id: 'B5', group: 'group' }
  ]
]

const sortedData = [
  {
    id: 92,
    win: 50,
    loss: -90,
    success: 0.4,
    irate: 0.7,
    showgain: 0.4,
    showloss: 0.3,
    showgrey: 0.3,
    trueutility: -34,
    visibleutility: -10,
    lower: -34,
    upper: 8,
    difficulty: 0.38,
    itemblock: 'B1'
  },
  {
    id: 142,
    win: 100,
    loss: -10,
    success: 0.1,
    irate: 0.7,
    showgain: 0,
    showloss: 0.7,
    showgrey: 0.3,
    trueutility: 1,
    visibleutility: -10,
    lower: -10,
    upper: 23,
    difficulty: 0.61,
    itemblock: 'B1'
  },
  {
    id: 238,
    win: 100,
    loss: -50,
    success: 0.4,
    irate: 0.4,
    showgain: 0.1,
    showloss: 0.3,
    showgrey: 0.6,
    trueutility: 10,
    visibleutility: -13,
    lower: -35,
    upper: 55,
    difficulty: 0.78,
    itemblock: 'B1'
  },
  {
    id: 55,
    win: 100,
    loss: -90,
    success: 0.6,
    irate: 0.7,
    showgain: 0.3,
    showloss: 0.4,
    showgrey: 0.3,
    trueutility: 24,
    visibleutility: -9,
    lower: -33,
    upper: 24,
    difficulty: 0.84,
    itemblock: 'B1'
  },
  {
    id: 208,
    win: 100,
    loss: -90,
    success: 0.4,
    irate: 0.4,
    showgain: 0.4,
    showloss: 0,
    showgrey: 0.6,
    trueutility: -14,
    visibleutility: 100,
    lower: -14,
    upper: 100,
    difficulty: 0.25,
    itemblock: 'B1'
  },
  {
    id: 42,
    win: 10,
    loss: -50,
    success: 0.9,
    irate: 0.7,
    showgain: 0.6,
    showloss: 0.1,
    showgrey: 0.3,
    trueutility: 4,
    visibleutility: 1,
    lower: -14,
    upper: 4,
    difficulty: 0.44,
    itemblock: 'B1'
  },
  {
    id: 63,
    win: 10,
    loss: -10,
    success: 0.6,
    irate: 0.7,
    showgain: 0.3,
    showloss: 0.4,
    showgrey: 0.3,
    trueutility: 2,
    visibleutility: -1,
    lower: -4,
    upper: 2,
    difficulty: 0.67,
    itemblock: 'B1'
  },
  {
    id: 38,
    win: 50,
    loss: -90,
    success: 0.9,
    irate: 0.7,
    showgain: 0.6,
    showloss: 0.1,
    showgrey: 0.3,
    trueutility: 36,
    visibleutility: 30,
    lower: -6,
    upper: 36,
    difficulty: 0.29,
    itemblock: 'B1'
  },
  {
    id: 262,
    win: 100,
    loss: -90,
    success: 0.1,
    irate: 0.4,
    showgain: 0,
    showloss: 0.4,
    showgrey: 0.6,
    trueutility: -71,
    visibleutility: -90,
    lower: -90,
    upper: 24,
    difficulty: 0.42,
    itemblock: 'B1'
  },
  {
    id: 125,
    win: 50,
    loss: -10,
    success: 0.4,
    irate: 0.7,
    showgain: 0.1,
    showloss: 0.6,
    showgrey: 0.3,
    trueutility: 14,
    visibleutility: -1,
    lower: -4,
    upper: 14,
    difficulty: 0.44,
    itemblock: 'B1'
  },
  {
    id: 159,
    win: 10,
    loss: -50,
    success: 0.9,
    irate: 0.4,
    showgain: 0.3,
    showloss: 0.1,
    showgrey: 0.6,
    trueutility: 4,
    visibleutility: -5,
    lower: -32,
    upper: 4,
    difficulty: 0.22,
    itemblock: 'B2'
  },
  {
    id: 184,
    win: 100,
    loss: -50,
    success: 0.6,
    irate: 0.4,
    showgain: 0.2,
    showloss: 0.2,
    showgrey: 0.6,
    trueutility: 40,
    visibleutility: 25,
    lower: -20,
    upper: 70,
    difficulty: 0.44,
    itemblock: 'B2'
  },
  {
    id: 103,
    win: 100,
    loss: -50,
    success: 0.4,
    irate: 0.7,
    showgain: 0.3,
    showloss: 0.4,
    showgrey: 0.3,
    trueutility: 10,
    visibleutility: 14,
    lower: -5,
    upper: 40,
    difficulty: 0.22,
    itemblock: 'B2'
  },
  {
    id: 95,
    win: 50,
    loss: -50,
    success: 0.4,
    irate: 0.7,
    showgain: 0.4,
    showloss: 0.3,
    showgrey: 0.3,
    trueutility: -10,
    visibleutility: 7,
    lower: -10,
    upper: 20,
    difficulty: 0.67,
    itemblock: 'B2'
  },
  {
    id: 225,
    win: 10,
    loss: -10,
    success: 0.4,
    irate: 0.4,
    showgain: 0.3,
    showloss: 0.1,
    showgrey: 0.6,
    trueutility: -2,
    visibleutility: 5,
    lower: -4,
    upper: 8,
    difficulty: 0.67,
    itemblock: 'B2'
  },
  {
    id: 108,
    win: 10,
    loss: -10,
    success: 0.4,
    irate: 0.7,
    showgain: 0.3,
    showloss: 0.4,
    showgrey: 0.3,
    trueutility: -2,
    visibleutility: -1,
    lower: -4,
    upper: 2,
    difficulty: 0.67,
    itemblock: 'B2'
  },
  {
    id: 51,
    win: 10,
    loss: -50,
    success: 0.9,
    irate: 0.7,
    showgain: 0.7,
    showloss: 0,
    showgrey: 0.3,
    trueutility: 4,
    visibleutility: 10,
    lower: -8,
    upper: 10,
    difficulty: 0.89,
    itemblock: 'B2'
  },
  {
    id: 257,
    win: 50,
    loss: -50,
    success: 0.1,
    irate: 0.4,
    showgain: 0.1,
    showloss: 0.3,
    showgrey: 0.6,
    trueutility: -40,
    visibleutility: -25,
    lower: -40,
    upper: 20,
    difficulty: 0.67,
    itemblock: 'B2'
  },
  {
    id: 109,
    win: 100,
    loss: -90,
    success: 0.4,
    irate: 0.7,
    showgain: 0.2,
    showloss: 0.5,
    showgrey: 0.3,
    trueutility: -14,
    visibleutility: -36,
    lower: -52,
    upper: 5,
    difficulty: 0.18,
    itemblock: 'B2'
  },
  {
    id: 104,
    win: 50,
    loss: -50,
    success: 0.4,
    irate: 0.7,
    showgain: 0.3,
    showloss: 0.4,
    showgrey: 0.3,
    trueutility: -10,
    visibleutility: -7,
    lower: -20,
    upper: 10,
    difficulty: 0.67,
    itemblock: 'B2'
  },
  {
    id: 87,
    win: 10,
    loss: -50,
    success: 0.6,
    irate: 0.7,
    showgain: 0.6,
    showloss: 0.1,
    showgrey: 0.3,
    trueutility: -14,
    visibleutility: 1,
    lower: -14,
    upper: 4,
    difficulty: 0.44,
    itemblock: 'B3'
  },
  {
    id: 217,
    win: 100,
    loss: -90,
    success: 0.4,
    irate: 0.4,
    showgain: 0.3,
    showloss: 0.1,
    showgrey: 0.6,
    trueutility: -14,
    visibleutility: 53,
    lower: -33,
    upper: 81,
    difficulty: 0.58,
    itemblock: 'B3'
  },
  {
    id: 130,
    win: 100,
    loss: -50,
    success: 0.1,
    irate: 0.7,
    showgain: 0.1,
    showloss: 0.6,
    showgrey: 0.3,
    trueutility: -35,
    visibleutility: -29,
    lower: -35,
    upper: 10,
    difficulty: 0.44,
    itemblock: 'B3'
  },
  {
    id: 239,
    win: 50,
    loss: -50,
    success: 0.4,
    irate: 0.4,
    showgain: 0.1,
    showloss: 0.3,
    showgrey: 0.6,
    trueutility: -10,
    visibleutility: -25,
    lower: -40,
    upper: 20,
    difficulty: 0.67,
    itemblock: 'B3'
  },
  {
    id: 182,
    win: 50,
    loss: -90,
    success: 0.6,
    irate: 0.4,
    showgain: 0.2,
    showloss: 0.2,
    showgrey: 0.6,
    trueutility: -6,
    visibleutility: -20,
    lower: -62,
    upper: 22,
    difficulty: 0.52,
    itemblock: 'B3'
  },
  {
    id: 134,
    win: 50,
    loss: -10,
    success: 0.1,
    irate: 0.7,
    showgain: 0.1,
    showloss: 0.6,
    showgrey: 0.3,
    trueutility: -4,
    visibleutility: -1,
    lower: -4,
    upper: 14,
    difficulty: 0.44,
    itemblock: 'B3'
  },
  {
    id: 99,
    win: 10,
    loss: -10,
    success: 0.4,
    irate: 0.7,
    showgain: 0.4,
    showloss: 0.3,
    showgrey: 0.3,
    trueutility: -2,
    visibleutility: 1,
    lower: -2,
    upper: 4,
    difficulty: 0.67,
    itemblock: 'B3'
  },
  {
    id: 150,
    win: 10,
    loss: -50,
    success: 0.9,
    irate: 0.4,
    showgain: 0.4,
    showloss: 0,
    showgrey: 0.6,
    trueutility: 4,
    visibleutility: 10,
    lower: -26,
    upper: 10,
    difficulty: 0.56,
    itemblock: 'B3'
  },
  {
    id: 170,
    win: 50,
    loss: -10,
    success: 0.6,
    irate: 0.4,
    showgain: 0,
    showloss: 0.4,
    showgrey: 0.6,
    trueutility: 26,
    visibleutility: -10,
    lower: -10,
    upper: 26,
    difficulty: 0.56,
    itemblock: 'B3'
  },
  {
    id: 158,
    win: 50,
    loss: -50,
    success: 0.9,
    irate: 0.4,
    showgain: 0.3,
    showloss: 0.1,
    showgrey: 0.6,
    trueutility: 40,
    visibleutility: 25,
    lower: -20,
    upper: 40,
    difficulty: 0.67,
    itemblock: 'B3'
  },
  {
    id: 221,
    win: 50,
    loss: -50,
    success: 0.4,
    irate: 0.4,
    showgain: 0.3,
    showloss: 0.1,
    showgrey: 0.6,
    trueutility: -10,
    visibleutility: 25,
    lower: -20,
    upper: 40,
    difficulty: 0.67,
    itemblock: 'B4'
  },
  {
    id: 266,
    win: 50,
    loss: -50,
    success: 0.1,
    irate: 0.4,
    showgain: 0,
    showloss: 0.4,
    showgrey: 0.6,
    trueutility: -40,
    visibleutility: -50,
    lower: -50,
    upper: 10,
    difficulty: 0.33,
    itemblock: 'B4'
  },
  {
    id: 194,
    win: 50,
    loss: -50,
    success: 0.6,
    irate: 0.4,
    showgain: 0.3,
    showloss: 0.1,
    showgrey: 0.6,
    trueutility: 10,
    visibleutility: 25,
    lower: -20,
    upper: 40,
    difficulty: 0.67,
    itemblock: 'B4'
  },
  {
    id: 65,
    win: 50,
    loss: -90,
    success: 0.6,
    irate: 0.7,
    showgain: 0.4,
    showloss: 0.3,
    showgrey: 0.3,
    trueutility: -6,
    visibleutility: -10,
    lower: -34,
    upper: 8,
    difficulty: 0.38,
    itemblock: 'B4'
  },
  {
    id: 112,
    win: 100,
    loss: -50,
    success: 0.4,
    irate: 0.7,
    showgain: 0.2,
    showloss: 0.5,
    showgrey: 0.3,
    trueutility: 10,
    visibleutility: -7,
    lower: -20,
    upper: 25,
    difficulty: 0.89,
    itemblock: 'B4'
  },
  {
    id: 190,
    win: 100,
    loss: -90,
    success: 0.6,
    irate: 0.4,
    showgain: 0.3,
    showloss: 0.1,
    showgrey: 0.6,
    trueutility: 24,
    visibleutility: 53,
    lower: -33,
    upper: 81,
    difficulty: 0.58,
    itemblock: 'B4'
  },
  {
    id: 256,
    win: 100,
    loss: -50,
    success: 0.1,
    irate: 0.4,
    showgain: 0.1,
    showloss: 0.3,
    showgrey: 0.6,
    trueutility: -35,
    visibleutility: -13,
    lower: -35,
    upper: 55,
    difficulty: 0.78,
    itemblock: 'B4'
  },
  {
    id: 248,
    win: 50,
    loss: -50,
    success: 0.4,
    irate: 0.4,
    showgain: 0,
    showloss: 0.4,
    showgrey: 0.6,
    trueutility: -10,
    visibleutility: -50,
    lower: -50,
    upper: 10,
    difficulty: 0.33,
    itemblock: 'B4'
  },
  {
    id: 149,
    win: 50,
    loss: -50,
    success: 0.9,
    irate: 0.4,
    showgain: 0.4,
    showloss: 0,
    showgrey: 0.6,
    trueutility: 40,
    visibleutility: 50,
    lower: -10,
    upper: 50,
    difficulty: 0.33,
    itemblock: 'B4'
  },
  {
    id: 244,
    win: 100,
    loss: -90,
    success: 0.4,
    irate: 0.4,
    showgain: 0,
    showloss: 0.4,
    showgrey: 0.6,
    trueutility: -14,
    visibleutility: -90,
    lower: -90,
    upper: 24,
    difficulty: 0.42,
    itemblock: 'B4'
  },
  {
    id: 204,
    win: 10,
    loss: -50,
    success: 0.6,
    irate: 0.4,
    showgain: 0.4,
    showloss: 0,
    showgrey: 0.6,
    trueutility: -14,
    visibleutility: 10,
    lower: -26,
    upper: 10,
    difficulty: 0.56,
    itemblock: 'B5'
  },
  {
    id: 72,
    win: 10,
    loss: -10,
    success: 0.6,
    irate: 0.7,
    showgain: 0.4,
    showloss: 0.3,
    showgrey: 0.3,
    trueutility: 2,
    visibleutility: 1,
    lower: -2,
    upper: 4,
    difficulty: 0.67,
    itemblock: 'B5'
  },
  {
    id: 59,
    win: 50,
    loss: -50,
    success: 0.6,
    irate: 0.7,
    showgain: 0.3,
    showloss: 0.4,
    showgrey: 0.3,
    trueutility: 10,
    visibleutility: -7,
    lower: -20,
    upper: 10,
    difficulty: 0.67,
    itemblock: 'B5'
  },
  {
    id: 64,
    win: 100,
    loss: -90,
    success: 0.6,
    irate: 0.7,
    showgain: 0.4,
    showloss: 0.3,
    showgrey: 0.3,
    trueutility: 24,
    visibleutility: 19,
    lower: -14,
    upper: 43,
    difficulty: 0.49,
    itemblock: 'B5'
  },
  {
    id: 210,
    win: 10,
    loss: -90,
    success: 0.4,
    irate: 0.4,
    showgain: 0.4,
    showloss: 0,
    showgrey: 0.6,
    trueutility: -50,
    visibleutility: 10,
    lower: -50,
    upper: 10,
    difficulty: 0.33,
    itemblock: 'B5'
  },
  {
    id: 58,
    win: 100,
    loss: -50,
    success: 0.6,
    irate: 0.7,
    showgain: 0.3,
    showloss: 0.4,
    showgrey: 0.3,
    trueutility: 40,
    visibleutility: 14,
    lower: -5,
    upper: 40,
    difficulty: 0.22,
    itemblock: 'B5'
  },
  {
    id: 212,
    win: 50,
    loss: -50,
    success: 0.4,
    irate: 0.4,
    showgain: 0.4,
    showloss: 0,
    showgrey: 0.6,
    trueutility: -10,
    visibleutility: 50,
    lower: -10,
    upper: 50,
    difficulty: 0.33,
    itemblock: 'B5'
  },
  {
    id: 100,
    win: 100,
    loss: -90,
    success: 0.4,
    irate: 0.7,
    showgain: 0.3,
    showloss: 0.4,
    showgrey: 0.3,
    trueutility: -14,
    visibleutility: -9,
    lower: -33,
    upper: 24,
    difficulty: 0.84,
    itemblock: 'B5'
  },
  {
    id: 68,
    win: 50,
    loss: -50,
    success: 0.6,
    irate: 0.7,
    showgain: 0.4,
    showloss: 0.3,
    showgrey: 0.3,
    trueutility: 10,
    visibleutility: 7,
    lower: -10,
    upper: 20,
    difficulty: 0.67,
    itemblock: 'B5'
  },
  {
    id: 91,
    win: 100,
    loss: -90,
    success: 0.4,
    irate: 0.7,
    showgain: 0.4,
    showloss: 0.3,
    showgrey: 0.3,
    trueutility: -14,
    visibleutility: 19,
    lower: -14,
    upper: 43,
    difficulty: 0.49,
    itemblock: 'B5'
  },
  {
    id: 218,
    win: 50,
    loss: -90,
    success: 0.4,
    irate: 0.4,
    showgain: 0.3,
    showloss: 0.1,
    showgrey: 0.6,
    trueutility: -34,
    visibleutility: 15,
    lower: -48,
    upper: 36,
    difficulty: 0.86,
    itemblock: 'B6'
  },
  {
    id: 48,
    win: 10,
    loss: -90,
    success: 0.9,
    irate: 0.7,
    showgain: 0.7,
    showloss: 0,
    showgrey: 0.3,
    trueutility: 0,
    visibleutility: 10,
    lower: -20,
    upper: 10,
    difficulty: 0.67,
    itemblock: 'B6'
  },
  {
    id: 229,
    win: 100,
    loss: -50,
    success: 0.4,
    irate: 0.4,
    showgain: 0.2,
    showloss: 0.2,
    showgrey: 0.6,
    trueutility: 10,
    visibleutility: 25,
    lower: -20,
    upper: 70,
    difficulty: 0.44,
    itemblock: 'B6'
  },
  {
    id: 121,
    win: 100,
    loss: -50,
    success: 0.4,
    irate: 0.7,
    showgain: 0.1,
    showloss: 0.6,
    showgrey: 0.3,
    trueutility: 10,
    visibleutility: -29,
    lower: -35,
    upper: 10,
    difficulty: 0.44,
    itemblock: 'B6'
  },
  {
    id: 83,
    win: 50,
    loss: -90,
    success: 0.6,
    irate: 0.7,
    showgain: 0.6,
    showloss: 0.1,
    showgrey: 0.3,
    trueutility: -6,
    visibleutility: 30,
    lower: -6,
    upper: 36,
    difficulty: 0.29,
    itemblock: 'B6'
  },
  {
    id: 176,
    win: 50,
    loss: -50,
    success: 0.6,
    irate: 0.4,
    showgain: 0.1,
    showloss: 0.3,
    showgrey: 0.6,
    trueutility: 10,
    visibleutility: -25,
    lower: -40,
    upper: 20,
    difficulty: 0.67,
    itemblock: 'B6'
  },
  {
    id: 201,
    win: 10,
    loss: -90,
    success: 0.6,
    irate: 0.4,
    showgain: 0.4,
    showloss: 0,
    showgrey: 0.6,
    trueutility: -30,
    visibleutility: 10,
    lower: -50,
    upper: 10,
    difficulty: 0.33,
    itemblock: 'B6'
  },
  {
    id: 227,
    win: 50,
    loss: -90,
    success: 0.4,
    irate: 0.4,
    showgain: 0.2,
    showloss: 0.2,
    showgrey: 0.6,
    trueutility: -34,
    visibleutility: -20,
    lower: -62,
    upper: 22,
    difficulty: 0.52,
    itemblock: 'B6'
  },
  {
    id: 175,
    win: 100,
    loss: -50,
    success: 0.6,
    irate: 0.4,
    showgain: 0.1,
    showloss: 0.3,
    showgrey: 0.6,
    trueutility: 40,
    visibleutility: -13,
    lower: -35,
    upper: 55,
    difficulty: 0.78,
    itemblock: 'B6'
  },
  {
    id: 167,
    win: 50,
    loss: -50,
    success: 0.6,
    irate: 0.4,
    showgain: 0,
    showloss: 0.4,
    showgrey: 0.6,
    trueutility: 10,
    visibleutility: -50,
    lower: -50,
    upper: 10,
    difficulty: 0.33,
    itemblock: 'B6'
  }
]

export const trainingData = [
  [
    {
      id: 14,
      win: 50,
      loss: -50,
      success: 0.6,
      irate: 1,
      showgain: 0.6,
      showloss: 0.4,
      showgrey: 0,
      trueutility: 10,
      visibleutility: 10,
      lower: 10,
      upper: 10,
      difficulty: 0
    },
    {
      id: 100,
      win: 100,
      loss: -90,
      success: 0.4,
      irate: 0.7,
      showgain: 0.3,
      showloss: 0.4,
      showgrey: 0.3,
      trueutility: -14,
      visibleutility: -9,
      lower: -33,
      upper: 24,
      difficulty: 0.84,
      itemblock: 'B5'
    }
  ]
]

export const MOOD_EVENTS = {
  0: {
    // 0
    INITIAL:
      'Deine Geschwister hassen dich. Sie haben jeglichen Kontakt zu dir abgebrochen und ignorieren dich vollständig.',
    ALTERNATE:
      'Deine Geschwister hassen dich. Sie haben jeglichen Kontakt zu dir abgebrochen und ignorieren dich vollständig.'
  },
  10: {
    // 10 - 1
    INITIAL:
      'Deine Geschwister zeigen inzwischen offen ihre Abneigung dir gegenüber. Beim letzten Familienevent haben sie ihren Kindern verboten mit dir zu reden.',
    ALTERNATE:
      'Deine Geschwister mögen dich offensichtlich nicht. Aber zumindest habt ihr auf geschäftlicher Ebene wieder minimalen Kontakt.'
  },
  20: {
    // 20 - 11
    INITIAL:
      'Die Beziehung zwischen dir und deinen Geschwistern hat einen neuen Tiefpunkt erreicht. Der Kontakt wurde auf ein absolutes Minimum reduziert und eine gewisse Abneigung dir gegenüber ist deutlich zu spüren.',
    ALTERNATE:
      'Die Beziehung zwischen dir und deinen Geschwistern ist noch enorm angespannt, doch du merkst, dass du nach und nach wieder mehr geduldet wirst.'
  },
  30: {
    INITIAL:
      'Die Stimmung zwischen dir und deinen Geschwistern wird immer angespannter. Zu deinem Geburtstag hat dir zum ersten Mal niemand von ihnen gratuliert.',
    ALTERNATE:
      'Die Stimmung zwischen dir und deinen Geschwistern wird langsam besser. Neulich hat sich deine Schwester sogar nach deinem privaten Wohlergehen erkundigt.'
  },
  40: {
    INITIAL:
      'Deine Geschwister werden dir gegenüber merklich unfreundlicher. Gerüchten zufolge haben sie eine WhatsApp-Gruppe ohne dich gegründet.', // First Time or wenn vorher großer war
    // Wenn vorher niedriger war
    ALTERNATE:
      'Zwischen dir und deinen Geschwistern scheint es wieder bergauf zu gehen. Bei der letzten Familienfeier wurdest du wieder (förmlich) umarmt.'
  },
  50: {
    INITIAL:
      'Die Stimmung zwischen dir und deinen Geschwistern ist neutral. Familiäre Verbundenheit ist nicht zu spüren, aber immerhin hasst ihr euch nicht...',
    ALTERNATE:
      'Die Stimmung zwischen dir und deinen Geschwistern ist neutral. Familiäre Verbundenheit ist nicht zu spüren, aber immerhin hasst ihr euch nicht...'
  },
  60: {
    // Trigger z.B. 60 oder niedriger bzw. größer 50
    // 60 bis 51
    INITIAL:
      'Die Stimmung zwischen dir und deinen Geschwistern ist freundlich. Ihr kommt miteinander klar, aber ihr wart euch schon einmal näher.',
    ALTERNATE:
      'Die Stimmung zwischen dir und deinen Geschwistern wird wieder freundlicher. Ihr wart euch früher einmal näher, aber das kann ja wieder werden.'
  },
  70: {
    // 70 bis 61
    INITIAL:
      'Du pflegst ein entspanntes Verhältnis zu deinen Geschwistern. Sowohl geschäftlich als auch privat kommt ihr wieder gut miteinander zurecht.',
    ALTERNATE:
      'Du pflegst ein entspanntes Verhältnis zu deinen Geschwistern. Sowohl geschäftlich als auch privat kommt ihr wieder gut miteinander zurecht.'
  },
  80: {
    INITIAL:
      'Deine Geschwister sind von deiner Führungsweise beeindruckt! Sie fühlen sich verstanden und haben enthusiastisch eine erste Firmenfeier organisiert.',
    ALTERNATE:
      'Obwohl du immer noch super mit deinen Geschwistern zurecht kommst, merkst du, wie sie wieder mehr deine Entscheidungen zu hinterfragen beginnen.'
  },
  90: {
    INITIAL:
      'Deine Geschwister sind begeistert von deinen Führungsqualitäten! Ihr habt auch privat viel miteinander zu tun und als du letztes Wochenende auf Clairs Tochter aufgepasst hast, hat sie gesagt, dass sie später mal so wie du sein möchte.',
    ALTERNATE:
      'Die Begeisterung deiner Geschwister für deine Führungsqualitäten hat einen kleinen Dämpfer bekommen. Ihr vollstes Vertrauen hast du aber noch immer.'
  },
  100: {
    INITIAL: 'Deine Geschwister lieben dich! James hat dich heute gebeten, sein Trauzeuge zu sein.',
    ALTERNATE:
      'Deine Geschwister lieben dich wieder! Sie schätzen sich glücklich, dass du der Kopf des Familienunternehmens bist.'
  }
}

export const getCurrentMoodBlock = (currentMood) => {
  const arr = Object.keys(MOOD_EVENTS)

  if (currentMood === 100) return 100
  for (let index = 0; index < arr.length; index++) {
    const moodBlock = parseInt(arr[index])

    if (inRange(currentMood, moodBlock - 9, moodBlock + 10)) {
      return arr[index]
    }
  }
}

// Starte 70
// Erreiche +15 dann 85
// Triggere 80er Event
//

export const initiateQuestions = () => {
  const questions = []

  for (let index = 0; index < BLOCK_COUNT; index++) {
    questions[index] = sortedData.filter((d) => BLOCK_IDS[index].includes(d.id))
  }
  return questions
}

// eslint-disable-next-line no-nested-ternary
export const limitNumber = (number, min, max) =>
  // eslint-disable-next-line no-nested-ternary
  Math.abs(number) < min ? min : Math.abs(number) > max ? max : Math.abs(number)
