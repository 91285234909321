import React, { useEffect, useMemo, useState } from 'react'
import { View, StyleSheet, FlatList, Image, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'
import { ModalHeader, Typo } from '../../../../components'
import { rowCenterBetween } from '../../../../styles'
import asyncStorage from '../../../../utils/async-storage'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  item: {
    paddingHorizontal: 20,
    height: 56,
    ...rowCenterBetween
  }
})

const Item = ({ title, onPress }) => (
  <TouchableOpacity style={styles.item} onPress={onPress}>
    <Typo.T1Black>{I18n.get(title)}</Typo.T1Black>
    <Image
      style={{ height: 12, width: 8 }}
      source={require('../../../../assets/images/arrow-right-grey.png')}
    />
  </TouchableOpacity>
)

const Legals = ({ navigation }) => {
  const [language, setLanguage] = useState('de')

  useEffect(() => {
    const getLanguage = async () => {
      const storageLanguage = await asyncStorage.getData('lang')
      setLanguage(storageLanguage || 'de')
    }

    getLanguage()
  }, [])

  const DATA = useMemo(
    () => [
      {
        title: 'global.imprint',
        onPress: () =>
          navigation.navigate('MarkdownScreen', {
            title: 'global.imprint',
            endpoint: `${language}_imprint.md`
          })
      },
      {
        title: 'global.agb',
        onPress: () =>
          navigation.navigate('MarkdownScreen', {
            title: 'global.agb',
            endpoint: `${language}_agb.md`
          })
      },
      {
        title: 'global.privacy_policy',
        onPress: () =>
          navigation.navigate('MarkdownScreen', {
            title: 'global.privacy_policy',
            endpoint: `${language}_data_protection.md`
          })
      }
    ],
    [navigation, language]
  )
  const renderItem = ({ item }) => <Item title={item.title} onPress={item.onPress} />

  return (
    <View style={styles.container}>
      <ModalHeader headerTitle='global.legal' onPress={() => navigation.goBack()} withBackButton />
      <FlatList data={DATA} renderItem={renderItem} keyExtractor={(item) => item.title} />
    </View>
  )
}

Legals.propTypes = {
  navigation: PropTypes.object.isRequired
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired
}

export default Legals
