/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { View, Image, Animated, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'

import Choice from './Choice'
import Typo from '../../../../components/Typo'
import { SECONDS_TO_ANSWER, ANSWER_OPTIONS, FOCUS_TIME } from '../data'
import colors from '../../../../theme/colors'
import metrics from '../../../../theme/metrics'

const Timer = ({ pause }) => {
  const [seconds, setSeconds] = useState(SECONDS_TO_ANSWER)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0 && !pause) {
        setSeconds(seconds - 1)
      } else {
        clearInterval(timer)
      }
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <View style={{ marginTop: 12, marginBottom: 30 }}>
      <Typo.NumberBlue
        center
        style={seconds <= 2 ? { color: seconds <= 0 ? 'red' : colors.crusta } : {}}
      >
        {seconds}
      </Typo.NumberBlue>
    </View>
  )
}

Timer.propTypes = {
  pause: PropTypes.bool.isRequired
}

const Question = ({ question, pause, onPress, timeToObserve }) => {
  const focusOpacity = useRef(new Animated.Value(1)).current
  const imageOpacity = useRef(new Animated.Value(0)).current
  const inputOpacity = useRef(new Animated.Value(0)).current

  const startTime = useRef(null)

  const [choice, setChoice] = useState(null)

  useEffect(() => {
    setTimeout(
      () =>
        Animated.timing(focusOpacity, {
          toValue: 0,
          duration: 250,
          useNativeDriver: false
        }).start(() => {
          startTime.current = new Date()
          Animated.timing(imageOpacity, {
            toValue: 1,
            duration: 250,
            useNativeDriver: false
          }).start(() => {
            setTimeout(() => {
              Animated.parallel([
                Animated.timing(imageOpacity, {
                  toValue: 0,
                  duration: 100,
                  useNativeDriver: false
                }),
                Animated.timing(inputOpacity, { toValue: 1, duration: 100, useNativeDriver: false })
              ]).start()
            }, timeToObserve)
          })
        }),
      FOCUS_TIME
    )
  }, [])

  const choicePressed = (value) => {
    // eslint-disable-next-line no-underscore-dangle
    if (inputOpacity?._value !== 1) return

    const endTime = new Date()
    const elapsedTime = endTime - startTime.current

    onPress({ value, elapsedTime })
    setChoice(value)
  }

  return (
    <>
      <Animated.View
        style={{
          ...StyleSheet.absoluteFill,
          opacity: focusOpacity,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Image
          style={{ width: 128, height: 128, marginBottom: 44 }}
          resizeMode='contain'
          source={require('../../../../assets/challenges/emotions-game/game-assets/focus-icon.png')}
        />
      </Animated.View>
      <Animated.View
        style={{ ...StyleSheet.absoluteFill, justifyContent: 'center', opacity: imageOpacity }}
      >
        <Image
          source={question.imageSource}
          style={{
            // reset header height to display the image centered
            marginBottom: 44,
            width: metrics.widthPercentageToDP(100),
            height: metrics.widthPercentageToDP(100 * 0.853),
            resizeMode: 'cover'
          }}
        />
      </Animated.View>
      <Animated.View style={{ ...StyleSheet.absoluteFill, opacity: inputOpacity }}>
        <Typo.H2LightBlue marginTop={24} center>
          {I18n.get('challenge.emotions_game.question')}
        </Typo.H2LightBlue>
        <Timer pause={pause} />
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          }}
        >
          <View>
            {ANSWER_OPTIONS.slice(0, 3).map((option) => (
              <Choice.Button
                key={option.value}
                disabled={!!choice}
                label={I18n.get(option.label)}
                active={choice === option.value}
                onPress={() => choicePressed(option.value)}
                style={{
                  width: metrics.widthPercentageToDP(43.2),
                  height: metrics.widthPercentageToDP(23.46)
                }}
              />
            ))}
          </View>
          <View>
            {ANSWER_OPTIONS.slice(3, 6).map((option) => (
              <Choice.Button
                key={option.value}
                disabled={!!choice}
                label={I18n.get(option.label)}
                active={choice === option.value}
                onPress={() => choicePressed(option.value)}
                style={{
                  width: metrics.widthPercentageToDP(43.2),
                  height: metrics.widthPercentageToDP(23.46)
                }}
              />
            ))}
          </View>
        </View>
      </Animated.View>
    </>
  )
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  pause: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  timeToObserve: PropTypes.number.isRequired
}

export default Question
