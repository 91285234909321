import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

const s = StyleSheet.create({
  container: {
    flex: 1
  }
})

const ScreenContainer = ({
  children,
  centerTop,
  center,
  backgroundColor,
  paddingHorizontal,
  paddingVertical,
  paddingBottom,
  style
}) => (
  <View
    style={[
      s.container,
      centerTop ? { alignItems: 'center' } : {},
      center ? { alignItems: 'center', justifyContent: 'center' } : {},
      { backgroundColor, paddingHorizontal, paddingVertical, paddingBottom },
      style
    ]}
  >
    {children}
  </View>
)

ScreenContainer.propTypes = {
  center: PropTypes.bool,
  centerTop: PropTypes.bool,
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  paddingHorizontal: PropTypes.number,
  paddingVertical: PropTypes.number,
  paddingBottom: PropTypes.number,
  style: PropTypes.object
}

ScreenContainer.defaultProps = {
  center: false,
  centerTop: false,
  children: null,
  backgroundColor: '#fff',
  paddingHorizontal: 0,
  paddingVertical: 0,
  paddingBottom: 0,
  style: {}
}

export default ScreenContainer
