import React, { useContext } from 'react'
import { View } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import ScienceText from './components/ScienceText'
import TradeOff from './components/TradeOff'
import NormalDistributionCurve from './components/NormalDistributionCurve'
import JobMatch from './components/JobMatch'
import { AuthContext } from '../../../context'

const PathFinderResult = ({ answer }) => {
  const { rootStore } = useContext(AuthContext)

  const { challengeConfigs } = rootStore.appSettingsStore
  const filteredChallengeConfigsById = challengeConfigs.filter((c) => c.id === 'PATH_FINDER')

  const heighest_score_answer_translated =
    filteredChallengeConfigsById[0]?.heighest_score_answer_translated

  const problem_solving_mean_percent = answer.aivy_output.translatedzScores.problem_solving_mean

  const UGeschw = answer.aivy_output.translatedzScores.avg_time_solving
  const PGeschw = answer.aivy_output.translatedzScores.avg_time_planning

  const UGeschw_of_heighestScoreAnswer = heighest_score_answer_translated?.avg_time_solving || 0

  const PGeschw_of_heighestScoreAnswer = heighest_score_answer_translated?.avg_time_planning || 0

  const tradeoff = 100 - PGeschw - (100 - UGeschw)
  const tradeoffHeighestScoreAnswer =
    100 - PGeschw_of_heighestScoreAnswer - (100 - UGeschw_of_heighestScoreAnswer)

  return (
    <View>
      <NormalDistributionCurve
        containerStyle={{ marginTop: 32 }}
        title={I18n.get('challenge.path_finder.result.normal_distribution_curve.title')}
        percent={Math.round(problem_solving_mean_percent)}
        description={`${
          problem_solving_mean_percent <= 30 // eslint-disable-line
            ? I18n.get('challenge.path_finder.result.problem_solving_mean_percent_1')
            : problem_solving_mean_percent > 30 && problem_solving_mean_percent < 70
            ? I18n.get('challenge.path_finder.result.problem_solving_mean_percent_2')
            : I18n.get('challenge.path_finder.result.problem_solving_mean_percent_3')
        }`}
        labels={{
          left: I18n.get('challenge.path_finder.result.scale_endpoint.left'),
          right: I18n.get('challenge.path_finder.result.scale_endpoint.right')
        }}
      />
      <TradeOff
        containerStyle={{ marginTop: 16 }}
        title={I18n.get('global.planning_implementation')}
        leftSkala={I18n.get('global.challenge.result.planning')}
        rightSkala={I18n.get('global.challenge.result.implementation')}
        tradeoff={tradeoff}
        firstDescription={
          tradeoff >= 0
            ? I18n.get('challenge.path_finder.result.tradeoff_1')
            : I18n.get('challenge.path_finder.result.tradeoff_2')
        }
        secondDescription={
          tradeoffHeighestScoreAnswer >= 0
            ? I18n.get('challenge.pathfinder.tradeoff.seconddescription_1')
            : I18n.get('challenge.pathfinder.tradeoff.seconddescription_2')
        }
      />
      <JobMatch
        exam_id='PATH_FINDER'
        score={problem_solving_mean_percent}
        containerStyle={{ marginTop: 16 }}
      />
      <ScienceText containerStyle={{ marginTop: 16 }} exam_id='PATH_FINDER' />
    </View>
  )
}

PathFinderResult.propTypes = {
  answer: PropTypes.object.isRequired
}

export default PathFinderResult
