import { Platform } from 'react-native'
import { Auth } from 'aws-amplify'
// import { updateUserProperties } from './tracking' // TODO: 2.0 RACI
import { notifyBugsnag } from './bugsnag'
import invitationStates from '../constants/invitationStates'
import AppSettingsStore from '../stores/AppSettingsStore'
import { query } from '../graphql'
import asyncStorage from './async-storage'
import { AWS_AUTH_MODES } from './helpers'

/**
 *
 * @param {Object} PartnerCareerObject Object containing partner and career as res object
 * @param {*} rootStore
 */
export async function handleAppSettings({ partner, career }, rootStore) {
  let appSettingsPartner = null
  let appSettingsCareer = null

  if (career !== null && partner !== null) {
    appSettingsCareer = await AppSettingsStore.getSettingsForCareer(career, partner)
    rootStore.appSettingsStore.setAppSettings(appSettingsCareer)

    return appSettingsCareer
  }

  if (partner !== null) {
    appSettingsPartner = await AppSettingsStore.getSettingsForPartner(partner)
    rootStore.appSettingsStore.setAppSettings(appSettingsPartner)

    return appSettingsPartner
  }
}

export default async (url, setInvitationState, rootStore) => {
  url = url.replace(/\s/g, '')
  let currentUser = null
  try {
    currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
  } catch (err) {
    notifyBugsnag(err)
  }

  // check script integration
  if (Platform.OS === 'web') {
    if (typeof window.localStorage === 'undefined') {
      window.location.href = 'https://aivy.app/error'
      return
    }

    const scriptTag = document.getElementById('aivy')
    const partnerTag = scriptTag?.getAttribute('partner')
    const languageTag = scriptTag?.getAttribute('language')

    if (partnerTag) {
      url = `https://webapp.aivy.app/?partner=${partnerTag}&language=${languageTag || 'de'}`
      // CALL INIT APP WITH PARTNER URL
    }
  }

  const res = await query({
    query: 'customUniversalAppInit',
    variables: {
      lang: 'de',
      ref_link: url,
      user_id: currentUser?.signInUserSession?.accessToken?.payload?.username
    },
    authMode: currentUser ? AWS_AUTH_MODES.AMAZON_COGNITO_USER_POOLS : AWS_AUTH_MODES.AWS_IAM
  })

  // invitation_status can be 'EXPIRED', 'USED', 'DELETED' or 'VALID'

  const { partner, career, space, user, invitation_status, answers } = res.data.universalAppInit

  const parsedAnswers = answers
    ? answers?.map((answer) => ({
        ...answer,
        aivy_output: JSON.parse(answer.aivy_output),
        user_input: JSON.parse(answer.user_input)
      }))
    : []

  if (invitation_status && invitation_status === 'USED') {
    setInvitationState(invitationStates.USED)
    // notifyBugsnag(
    //   new Error(`Invitation Used for Partner-ID: ${partner?.id} | Career-ID: ${career?.id}`)
    // )
  } else if (invitation_status && invitation_status === 'EXPIRED') {
    setInvitationState(invitationStates.EXPIRED)
  } else if (invitation_status && invitation_status === 'DELETED') {
    setInvitationState(invitationStates.DELETED)
  }

  const { id: partner_id, name: partner_name } = partner || {}
  const { id: career_id } = career || {}
  const { id: space_id } = space || {}

  // passing null / undefined as value is not supported
  await asyncStorage.storeData('partner-id', partner_id)
  await asyncStorage.storeData('partner-name', partner_name)
  await asyncStorage.storeData('career-id', career_id)
  await asyncStorage.storeData('space-id', space_id)

  rootStore.spaceStore.initSpace(space)
  rootStore.userStore.initUserProfile(user)
  rootStore.userStore.setInvitationState(invitation_status)
  rootStore.answersStore.setAnswers(parsedAnswers)
  await handleAppSettings({ partner, career }, rootStore)
  rootStore.challengesStore.initChallenges(parsedAnswers, rootStore)

  // INIT ALL STORES
  return null
}
