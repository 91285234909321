import React, { useContext } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import Typo from '../Typo'
import metrics from '../../theme/metrics'
import Button from '../Button'
import { AuthContext } from '../../context'

const styles = StyleSheet.create({
  container: {
    paddingVertical: 32,
    paddingHorizontal: 32
  }
})

const StrengthEmpty = ({ navigateFirstChallenge, onClose }) => {
  const { rootStore } = useContext(AuthContext)

  const getImage = () => {
    const { progress } = rootStore.challengesStore
    if (progress === 0) {
      return require('../../assets/images/strength-empty.png')
    }
    if (progress <= 25) {
      return require('../../assets/images/strength-20.png')
    }
    return require('../../assets/images/strength-50.png')
  }

  const getText = () => {
    const { progress } = rootStore.challengesStore
    if (progress === 0) {
      return I18n.get('global.strength_modalize.progress.text.start')
    }
    if (progress <= 25) {
      return I18n.get('global.strength_modalize.progress.text.firstChallenge')
    }
    return I18n.get('global.strength_modalize.progress.text.play')
  }

  return (
    <View style={styles.container}>
      <Typo.H2Black center translate>
        global.strength_modalize.title
      </Typo.H2Black>
      <Image
        style={{
          marginTop: 38,
          alignSelf: 'center',
          marginBottom: 28,
          ...metrics.calculateResponsiveDimensions({ height: 96, width: 252 })
        }}
        source={getImage()}
      />
      <Typo.T1Black center>{getText()}</Typo.T1Black>
      {rootStore.challengesStore.progress === 0 && (
        <Button
          title='global.strength_modalize.button_action'
          onPress={() => {
            onClose()
            navigateFirstChallenge()
          }}
          type='outline'
          containerStyle={{ marginTop: 30 }}
        />
      )}
    </View>
  )
}

StrengthEmpty.propTypes = {
  navigateFirstChallenge: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default observer(StrengthEmpty)
