import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'
import Typo from '../../../Typo'
import AreaChart from './AreaChart'
import { areaChartData } from '../utils/area-chart-data'

const customMDStyles = StyleSheet.create({
  text: {
    fontFamily: 'fira-sans-regular',
    fontSize: 14,
    lineHeight: 22,
    color: colors.darkBlack
  },
  strong: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'fira-sans-medium'
  }
})

const s = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: colors.lightBlue,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5
  }
})

/**
 * This component shows the Normal Distribution Curve inside a card container including
 * a title and a text below the Curve.
 *
 * This component will be imported normally in the Challenges Result screens.
 */
const NormalDistributionCurve = ({
  containerStyle,
  title,
  percent,
  description,
  onlyChart,
  labels
}) => {
  if (onlyChart) {
    return <AreaChart data={areaChartData} />
  }

  return (
    <View style={[s.container, containerStyle]}>
      {title !== 'ADD_TRANSLATION' && <Typo.H4Black marginBottom={16}>{title}</Typo.H4Black>}
      <AreaChart
        containerStyle={{ marginBottom: 24 }}
        data={areaChartData}
        percent={percent === 0 ? 1 : parseInt(percent)}
        labels={labels}
      />
      <Typo.Markdown style={customMDStyles}>{description}</Typo.Markdown>
    </View>
  )
}

NormalDistributionCurve.propTypes = {
  containerStyle: PropTypes.object,
  title: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  onlyChart: PropTypes.bool,
  labels: PropTypes.object
}

NormalDistributionCurve.defaultProps = {
  containerStyle: {},
  onlyChart: false,
  labels: undefined
}

export default NormalDistributionCurve
