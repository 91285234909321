import React from 'react'
import { I18n } from 'aws-amplify'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import metrics from '../../../../theme/metrics'
import Typo from '../../../../components/Typo'
import colors from '../../../../theme/colors'
import DottedLine from './DottedLine'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: 'center'
  },
  card: {
    backgroundColor: '#fff',
    width: '100%',
    paddingHorizontal: 18,
    paddingVertical: 24,
    borderRadius: 4,
    shadowColor: colors.powderBlue,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65
  },
  dottedLine: {
    borderStyle: 'dotted',
    borderWidth: 1,
    borderRadius: 1,
    marginTop: 12,
    borderColor: colors.mediumGrey
  }
})

const Question = ({ item }) => {
  const { pair1_stimA, pair1_stimB, pair2_stimA, pair2_stimB } = item
  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <Typo.H2Blue center translate>
          {I18n.get(pair1_stimA)} <Typo.H2LightBlue translate>global.and</Typo.H2LightBlue>{' '}
          {I18n.get(pair1_stimB)}
        </Typo.H2Blue>
        <DottedLine
          containerStyle={{ marginTop: 12, marginBottom: 35 }}
          width={metrics.widthPercentageToDP(100)}
        />
        <Typo.H3Black center translate>
          challenge.analogical_reasonning.is_like
        </Typo.H3Black>
        <Typo.H2Blue marginTop={39} center translate>
          {I18n.get(pair2_stimA)} <Typo.H2LightBlue translate>global.and</Typo.H2LightBlue>{' '}
          {I18n.get(pair2_stimB)}
        </Typo.H2Blue>
        <DottedLine containerStyle={{ marginTop: 12 }} width={metrics.widthPercentageToDP(100)} />
      </View>
    </View>
  )
}

Question.propTypes = {
  item: PropTypes.object.isRequired
}

export default Question
