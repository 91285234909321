export default [
  [
    { id: 10, round: 1, item_correct: 0, symbolName: 'fish' },
    { id: 5, round: 2, item_correct: 0, symbolName: 'cube' },
    { id: 2, round: 3, item_correct: 0, symbolName: 'lemon' },
    { id: 7, round: 4, item_correct: 0, symbolName: 'hammer' },
    { id: 6, round: 5, item_correct: 0, symbolName: 'hat' },
    { id: 10, round: 6, item_correct: 0, symbolName: 'fish' },
    { id: 7, round: 7, item_correct: 0, symbolName: 'hammer' },
    { id: 10, round: 8, item_correct: 1, symbolName: 'fish' },
    { id: 2, round: 9, item_correct: 0, symbolName: 'lemon' },
    { id: 3, round: 10, item_correct: 0, symbolName: 'candy' },
    { id: 2, round: 11, item_correct: 1, symbolName: 'lemon' },
    { id: 8, round: 12, item_correct: 0, symbolName: 'dog' },
    { id: 5, round: 13, item_correct: 0, symbolName: 'cube' },
    { id: 4, round: 14, item_correct: 0, symbolName: 'cocktail' },
    { id: 6, round: 15, item_correct: 0, symbolName: 'hat' },
    { id: 4, round: 16, item_correct: 1, symbolName: 'cocktail' },
    { id: 10, round: 17, item_correct: 0, symbolName: 'fish' },
    { id: 3, round: 18, item_correct: 0, symbolName: 'candy' },
    { id: 8, round: 19, item_correct: 0, symbolName: 'dog' },
    { id: 3, round: 20, item_correct: 1, symbolName: 'candy' },
    { id: 10, round: 21, item_correct: 0, symbolName: 'fish' },
    { id: 2, round: 22, item_correct: 0, symbolName: 'lemon' },
    { id: 10, round: 23, item_correct: 1, symbolName: 'fish' },
    { id: 9, round: 24, item_correct: 0, symbolName: 'ghost' },
    { id: 3, round: 25, item_correct: 0, symbolName: 'candy' },
    { id: 9, round: 26, item_correct: 1, symbolName: 'ghost' },
    { id: 4, round: 27, item_correct: 0, symbolName: 'cocktail' },
    { id: 2, round: 28, item_correct: 0, symbolName: 'lemon' },
    { id: 8, round: 29, item_correct: 0, symbolName: 'dog' },
    { id: 2, round: 30, item_correct: 1, symbolName: 'lemon' },
    { id: 9, round: 31, item_correct: 0, symbolName: 'ghost' },
    { id: 2, round: 32, item_correct: 1, symbolName: 'lemon' }
  ],
  [
    { id: 1, round: 1, item_correct: 0, symbolName: 'carrot' },
    { id: 2, round: 2, item_correct: 0, symbolName: 'lemon' },
    { id: 7, round: 3, item_correct: 0, symbolName: 'hammer' },
    { id: 2, round: 4, item_correct: 1, symbolName: 'lemon' },
    { id: 10, round: 5, item_correct: 0, symbolName: 'fish' },
    { id: 7, round: 6, item_correct: 0, symbolName: 'hammer' },
    { id: 6, round: 7, item_correct: 0, symbolName: 'hat' },
    { id: 9, round: 8, item_correct: 0, symbolName: 'ghost' },
    { id: 5, round: 9, item_correct: 0, symbolName: 'cube' },
    { id: 6, round: 10, item_correct: 0, symbolName: 'hat' },
    { id: 5, round: 11, item_correct: 1, symbolName: 'cube' },
    { id: 8, round: 12, item_correct: 0, symbolName: 'dog' },
    { id: 3, round: 13, item_correct: 0, symbolName: 'candy' },
    { id: 9, round: 14, item_correct: 0, symbolName: 'ghost' },
    { id: 6, round: 15, item_correct: 0, symbolName: 'hat' },
    { id: 9, round: 16, item_correct: 1, symbolName: 'ghost' },
    { id: 7, round: 17, item_correct: 0, symbolName: 'hammer' },
    { id: 9, round: 18, item_correct: 1, symbolName: 'ghost' },
    { id: 1, round: 19, item_correct: 0, symbolName: 'carrot' },
    { id: 4, round: 20, item_correct: 0, symbolName: 'cocktail' },
    { id: 1, round: 21, item_correct: 1, symbolName: 'carrot' },
    { id: 7, round: 22, item_correct: 0, symbolName: 'hammer' },
    { id: 9, round: 23, item_correct: 0, symbolName: 'ghost' },
    { id: 8, round: 24, item_correct: 0, symbolName: 'dog' },
    { id: 9, round: 25, item_correct: 1, symbolName: 'ghost' },
    { id: 7, round: 26, item_correct: 0, symbolName: 'hammer' },
    { id: 8, round: 27, item_correct: 0, symbolName: 'dog' },
    { id: 7, round: 28, item_correct: 1, symbolName: 'hammer' },
    { id: 5, round: 29, item_correct: 0, symbolName: 'cube' },
    { id: 6, round: 30, item_correct: 0, symbolName: 'hat' },
    { id: 7, round: 31, item_correct: 0, symbolName: 'hammer' },
    { id: 6, round: 32, item_correct: 1, symbolName: 'hat' }
  ],
  [
    { id: 5, round: 1, item_correct: 0, symbolName: 'cube' },
    { id: 2, round: 2, item_correct: 0, symbolName: 'lemon' },
    { id: 5, round: 3, item_correct: 1, symbolName: 'cube' },
    { id: 5, round: 4, item_correct: 0, symbolName: 'cube' },
    { id: 2, round: 5, item_correct: 0, symbolName: 'lemon' },
    { id: 6, round: 6, item_correct: 0, symbolName: 'hat' },
    { id: 8, round: 7, item_correct: 0, symbolName: 'dog' },
    { id: 7, round: 8, item_correct: 0, symbolName: 'hammer' },
    { id: 8, round: 9, item_correct: 1, symbolName: 'dog' },
    { id: 10, round: 10, item_correct: 0, symbolName: 'fish' },
    { id: 3, round: 11, item_correct: 0, symbolName: 'candy' },
    { id: 5, round: 12, item_correct: 0, symbolName: 'cube' },
    { id: 3, round: 13, item_correct: 1, symbolName: 'candy' },
    { id: 4, round: 14, item_correct: 0, symbolName: 'cocktail' },
    { id: 3, round: 15, item_correct: 1, symbolName: 'candy' },
    { id: 5, round: 16, item_correct: 0, symbolName: 'cube' },
    { id: 2, round: 17, item_correct: 0, symbolName: 'lemon' },
    { id: 5, round: 18, item_correct: 1, symbolName: 'cube' },
    { id: 3, round: 19, item_correct: 0, symbolName: 'candy' },
    { id: 5, round: 20, item_correct: 0, symbolName: 'cube' },
    { id: 8, round: 21, item_correct: 0, symbolName: 'dog' },
    { id: 4, round: 22, item_correct: 0, symbolName: 'cocktail' },
    { id: 8, round: 23, item_correct: 1, symbolName: 'dog' },
    { id: 2, round: 24, item_correct: 0, symbolName: 'lemon' },
    { id: 7, round: 25, item_correct: 0, symbolName: 'hammer' },
    { id: 9, round: 26, item_correct: 0, symbolName: 'ghost' },
    { id: 6, round: 27, item_correct: 0, symbolName: 'hat' },
    { id: 9, round: 28, item_correct: 1, symbolName: 'ghost' },
    { id: 3, round: 29, item_correct: 0, symbolName: 'candy' },
    { id: 4, round: 30, item_correct: 0, symbolName: 'cocktail' },
    { id: 2, round: 31, item_correct: 0, symbolName: 'lemon' },
    { id: 4, round: 32, item_correct: 1, symbolName: 'cocktail' }
  ],
  [
    { id: 3, round: 1, item_correct: 0, symbolName: 'candy' },
    { id: 7, round: 2, item_correct: 0, symbolName: 'hammer' },
    { id: 5, round: 3, item_correct: 0, symbolName: 'cube' },
    { id: 8, round: 4, item_correct: 0, symbolName: 'dog' },
    { id: 3, round: 5, item_correct: 0, symbolName: 'candy' },
    { id: 8, round: 6, item_correct: 1, symbolName: 'dog' },
    { id: 2, round: 7, item_correct: 0, symbolName: 'lemon' },
    { id: 10, round: 8, item_correct: 0, symbolName: 'fish' },
    { id: 1, round: 9, item_correct: 0, symbolName: 'carrot' },
    { id: 10, round: 10, item_correct: 1, symbolName: 'fish' },
    { id: 6, round: 11, item_correct: 0, symbolName: 'hat' },
    { id: 7, round: 12, item_correct: 0, symbolName: 'hammer' },
    { id: 6, round: 13, item_correct: 1, symbolName: 'hat' },
    { id: 10, round: 14, item_correct: 0, symbolName: 'fish' },
    { id: 8, round: 15, item_correct: 0, symbolName: 'dog' },
    { id: 3, round: 16, item_correct: 0, symbolName: 'candy' },
    { id: 2, round: 17, item_correct: 0, symbolName: 'lemon' },
    { id: 3, round: 18, item_correct: 1, symbolName: 'candy' },
    { id: 9, round: 19, item_correct: 0, symbolName: 'ghost' },
    { id: 2, round: 20, item_correct: 0, symbolName: 'lemon' },
    { id: 4, round: 21, item_correct: 0, symbolName: 'cocktail' },
    { id: 5, round: 22, item_correct: 0, symbolName: 'cube' },
    { id: 4, round: 23, item_correct: 1, symbolName: 'cocktail' },
    { id: 6, round: 24, item_correct: 0, symbolName: 'hat' },
    { id: 4, round: 25, item_correct: 1, symbolName: 'cocktail' },
    { id: 5, round: 26, item_correct: 0, symbolName: 'cube' },
    { id: 9, round: 27, item_correct: 0, symbolName: 'ghost' },
    { id: 5, round: 28, item_correct: 1, symbolName: 'cube' },
    { id: 2, round: 29, item_correct: 0, symbolName: 'lemon' },
    { id: 7, round: 30, item_correct: 0, symbolName: 'hammer' },
    { id: 2, round: 31, item_correct: 1, symbolName: 'lemon' },
    { id: 2, round: 32, item_correct: 0, symbolName: 'lemon' }
  ],
  [
    { id: 6, round: 1, item_correct: 0, symbolName: 'hat' },
    { id: 9, round: 2, item_correct: 0, symbolName: 'ghost' },
    { id: 5, round: 3, item_correct: 0, symbolName: 'cube' },
    { id: 2, round: 4, item_correct: 0, symbolName: 'lemon' },
    { id: 5, round: 5, item_correct: 1, symbolName: 'cube' },
    { id: 1, round: 6, item_correct: 0, symbolName: 'carrot' },
    { id: 6, round: 7, item_correct: 0, symbolName: 'hat' },
    { id: 10, round: 8, item_correct: 0, symbolName: 'fish' },
    { id: 3, round: 9, item_correct: 0, symbolName: 'candy' },
    { id: 9, round: 10, item_correct: 0, symbolName: 'ghost' },
    { id: 3, round: 11, item_correct: 1, symbolName: 'candy' },
    { id: 8, round: 12, item_correct: 0, symbolName: 'dog' },
    { id: 3, round: 13, item_correct: 1, symbolName: 'candy' },
    { id: 5, round: 14, item_correct: 0, symbolName: 'cube' },
    { id: 7, round: 15, item_correct: 0, symbolName: 'hammer' },
    { id: 5, round: 16, item_correct: 1, symbolName: 'cube' },
    { id: 4, round: 17, item_correct: 0, symbolName: 'cocktail' },
    { id: 9, round: 18, item_correct: 0, symbolName: 'ghost' },
    { id: 8, round: 19, item_correct: 1, symbolName: 'dog' },
    { id: 3, round: 20, item_correct: 0, symbolName: 'candy' },
    { id: 8, round: 21, item_correct: 1, symbolName: 'dog' },
    { id: 6, round: 22, item_correct: 0, symbolName: 'hat' },
    { id: 1, round: 23, item_correct: 0, symbolName: 'carrot' },
    { id: 6, round: 24, item_correct: 1, symbolName: 'hat' },
    { id: 7, round: 25, item_correct: 0, symbolName: 'hammer' },
    { id: 10, round: 26, item_correct: 0, symbolName: 'fish' },
    { id: 1, round: 27, item_correct: 0, symbolName: 'carrot' },
    { id: 9, round: 28, item_correct: 0, symbolName: 'ghost' },
    { id: 3, round: 29, item_correct: 0, symbolName: 'candy' },
    { id: 4, round: 30, item_correct: 0, symbolName: 'cocktail' },
    { id: 2, round: 31, item_correct: 0, symbolName: 'lemon' },
    { id: 4, round: 32, item_correct: 1, symbolName: 'cocktail' }
  ],
  [
    { id: 7, round: 1, item_correct: 0, symbolName: 'hammer' },
    { id: 8, round: 2, item_correct: 0, symbolName: 'dog' },
    { id: 4, round: 3, item_correct: 0, symbolName: 'cocktail' },
    { id: 8, round: 4, item_correct: 1, symbolName: 'dog' },
    { id: 3, round: 5, item_correct: 0, symbolName: 'candy' },
    { id: 2, round: 6, item_correct: 0, symbolName: 'lemon' },
    { id: 3, round: 7, item_correct: 1, symbolName: 'candy' },
    { id: 5, round: 8, item_correct: 0, symbolName: 'cube' },
    { id: 4, round: 9, item_correct: 0, symbolName: 'cocktail' },
    { id: 7, round: 10, item_correct: 0, symbolName: 'hammer' },
    { id: 1, round: 11, item_correct: 0, symbolName: 'carrot' },
    { id: 7, round: 12, item_correct: 1, symbolName: 'hammer' },
    { id: 8, round: 13, item_correct: 0, symbolName: 'dog' },
    { id: 4, round: 14, item_correct: 0, symbolName: 'cocktail' },
    { id: 10, round: 15, item_correct: 0, symbolName: 'fish' },
    { id: 5, round: 16, item_correct: 0, symbolName: 'cube' },
    { id: 10, round: 17, item_correct: 1, symbolName: 'fish' },
    { id: 7, round: 18, item_correct: 0, symbolName: 'hammer' },
    { id: 6, round: 19, item_correct: 0, symbolName: 'hat' },
    { id: 9, round: 20, item_correct: 0, symbolName: 'ghost' },
    { id: 10, round: 21, item_correct: 0, symbolName: 'fish' },
    { id: 9, round: 22, item_correct: 1, symbolName: 'ghost' },
    { id: 9, round: 23, item_correct: 0, symbolName: 'ghost' },
    { id: 3, round: 24, item_correct: 0, symbolName: 'candy' },
    { id: 2, round: 25, item_correct: 0, symbolName: 'lemon' },
    { id: 4, round: 26, item_correct: 0, symbolName: 'cocktail' },
    { id: 2, round: 27, item_correct: 1, symbolName: 'lemon' },
    { id: 3, round: 28, item_correct: 0, symbolName: 'candy' },
    { id: 2, round: 29, item_correct: 1, symbolName: 'lemon' },
    { id: 10, round: 30, item_correct: 0, symbolName: 'fish' },
    { id: 7, round: 31, item_correct: 0, symbolName: 'hammer' },
    { id: 10, round: 32, item_correct: 1, symbolName: 'fish' }
  ],
  [
    { id: 4, round: 1, item_correct: 0, symbolName: 'cocktail' },
    { id: 5, round: 2, item_correct: 0, symbolName: 'cube' },
    { id: 4, round: 3, item_correct: 1, symbolName: 'cocktail' },
    { id: 9, round: 4, item_correct: 0, symbolName: 'ghost' },
    { id: 4, round: 5, item_correct: 1, symbolName: 'cocktail' },
    { id: 2, round: 6, item_correct: 0, symbolName: 'lemon' },
    { id: 8, round: 7, item_correct: 0, symbolName: 'dog' },
    { id: 2, round: 8, item_correct: 1, symbolName: 'lemon' },
    { id: 1, round: 9, item_correct: 0, symbolName: 'carrot' },
    { id: 9, round: 10, item_correct: 0, symbolName: 'ghost' },
    { id: 2, round: 11, item_correct: 0, symbolName: 'lemon' },
    { id: 9, round: 12, item_correct: 1, symbolName: 'ghost' },
    { id: 3, round: 13, item_correct: 0, symbolName: 'candy' },
    { id: 7, round: 14, item_correct: 0, symbolName: 'hammer' },
    { id: 3, round: 15, item_correct: 1, symbolName: 'candy' },
    { id: 8, round: 16, item_correct: 0, symbolName: 'dog' },
    { id: 4, round: 17, item_correct: 0, symbolName: 'cocktail' },
    { id: 8, round: 18, item_correct: 1, symbolName: 'dog' },
    { id: 9, round: 19, item_correct: 0, symbolName: 'ghost' },
    { id: 3, round: 20, item_correct: 0, symbolName: 'candy' },
    { id: 6, round: 21, item_correct: 0, symbolName: 'hat' },
    { id: 10, round: 22, item_correct: 0, symbolName: 'fish' },
    { id: 2, round: 23, item_correct: 0, symbolName: 'lemon' },
    { id: 1, round: 24, item_correct: 0, symbolName: 'carrot' },
    { id: 2, round: 25, item_correct: 1, symbolName: 'lemon' },
    { id: 6, round: 26, item_correct: 0, symbolName: 'hat' },
    { id: 9, round: 27, item_correct: 0, symbolName: 'ghost' },
    { id: 5, round: 28, item_correct: 0, symbolName: 'cube' },
    { id: 7, round: 29, item_correct: 0, symbolName: 'hammer' },
    { id: 5, round: 30, item_correct: 1, symbolName: 'cube' },
    { id: 2, round: 31, item_correct: 0, symbolName: 'lemon' },
    { id: 4, round: 32, item_correct: 0, symbolName: 'cocktail' }
  ],
  [
    { id: 3, round: 1, item_correct: 0, symbolName: 'candy' },
    { id: 1, round: 2, item_correct: 0, symbolName: 'carrot' },
    { id: 3, round: 3, item_correct: 1, symbolName: 'candy' },
    { id: 4, round: 4, item_correct: 0, symbolName: 'cocktail' },
    { id: 9, round: 5, item_correct: 0, symbolName: 'ghost' },
    { id: 4, round: 6, item_correct: 1, symbolName: 'cocktail' },
    { id: 10, round: 7, item_correct: 0, symbolName: 'fish' },
    { id: 4, round: 8, item_correct: 1, symbolName: 'cocktail' },
    { id: 4, round: 9, item_correct: 0, symbolName: 'cocktail' },
    { id: 10, round: 10, item_correct: 0, symbolName: 'fish' },
    { id: 6, round: 11, item_correct: 0, symbolName: 'hat' },
    { id: 1, round: 12, item_correct: 0, symbolName: 'carrot' },
    { id: 6, round: 13, item_correct: 1, symbolName: 'hat' },
    { id: 5, round: 14, item_correct: 0, symbolName: 'cube' },
    { id: 7, round: 15, item_correct: 0, symbolName: 'hammer' },
    { id: 9, round: 16, item_correct: 0, symbolName: 'ghost' },
    { id: 7, round: 17, item_correct: 1, symbolName: 'hammer' },
    { id: 7, round: 18, item_correct: 0, symbolName: 'hammer' },
    { id: 3, round: 19, item_correct: 0, symbolName: 'candy' },
    { id: 6, round: 20, item_correct: 0, symbolName: 'hat' },
    { id: 4, round: 21, item_correct: 0, symbolName: 'cocktail' },
    { id: 6, round: 22, item_correct: 1, symbolName: 'hat' },
    { id: 10, round: 23, item_correct: 0, symbolName: 'fish' },
    { id: 7, round: 24, item_correct: 0, symbolName: 'hammer' },
    { id: 10, round: 25, item_correct: 1, symbolName: 'fish' },
    { id: 2, round: 26, item_correct: 0, symbolName: 'lemon' },
    { id: 9, round: 27, item_correct: 0, symbolName: 'ghost' },
    { id: 9, round: 28, item_correct: 0, symbolName: 'ghost' },
    { id: 8, round: 29, item_correct: 0, symbolName: 'dog' },
    { id: 4, round: 30, item_correct: 0, symbolName: 'cocktail' },
    { id: 8, round: 31, item_correct: 1, symbolName: 'dog' },
    { id: 1, round: 32, item_correct: 0, symbolName: 'carrot' }
  ],
  [
    { id: 8, round: 1, item_correct: 0, symbolName: 'dog' },
    { id: 4, round: 2, item_correct: 0, symbolName: 'cocktail' },
    { id: 9, round: 3, item_correct: 0, symbolName: 'ghost' },
    { id: 7, round: 4, item_correct: 0, symbolName: 'hammer' },
    { id: 6, round: 5, item_correct: 0, symbolName: 'hat' },
    { id: 2, round: 6, item_correct: 0, symbolName: 'lemon' },
    { id: 6, round: 7, item_correct: 1, symbolName: 'hat' },
    { id: 4, round: 8, item_correct: 0, symbolName: 'cocktail' },
    { id: 5, round: 9, item_correct: 0, symbolName: 'cube' },
    { id: 4, round: 10, item_correct: 1, symbolName: 'cocktail' },
    { id: 9, round: 11, item_correct: 0, symbolName: 'ghost' },
    { id: 6, round: 12, item_correct: 0, symbolName: 'hat' },
    { id: 9, round: 13, item_correct: 1, symbolName: 'ghost' },
    { id: 9, round: 14, item_correct: 0, symbolName: 'ghost' },
    { id: 1, round: 15, item_correct: 0, symbolName: 'carrot' },
    { id: 4, round: 16, item_correct: 0, symbolName: 'cocktail' },
    { id: 9, round: 17, item_correct: 0, symbolName: 'ghost' },
    { id: 4, round: 18, item_correct: 1, symbolName: 'cocktail' },
    { id: 7, round: 19, item_correct: 0, symbolName: 'hammer' },
    { id: 1, round: 20, item_correct: 0, symbolName: 'carrot' },
    { id: 5, round: 21, item_correct: 0, symbolName: 'cube' },
    { id: 1, round: 22, item_correct: 1, symbolName: 'carrot' },
    { id: 4, round: 23, item_correct: 0, symbolName: 'cocktail' },
    { id: 1, round: 24, item_correct: 1, symbolName: 'carrot' },
    { id: 9, round: 25, item_correct: 0, symbolName: 'ghost' },
    { id: 5, round: 26, item_correct: 0, symbolName: 'cube' },
    { id: 9, round: 27, item_correct: 1, symbolName: 'ghost' },
    { id: 2, round: 28, item_correct: 0, symbolName: 'lemon' },
    { id: 3, round: 29, item_correct: 0, symbolName: 'candy' },
    { id: 10, round: 30, item_correct: 0, symbolName: 'fish' },
    { id: 6, round: 31, item_correct: 0, symbolName: 'hat' },
    { id: 10, round: 32, item_correct: 1, symbolName: 'fish' }
  ],
  [
    { id: 8, round: 1, item_correct: 0, symbolName: 'dog' },
    { id: 10, round: 2, item_correct: 0, symbolName: 'fish' },
    { id: 8, round: 3, item_correct: 1, symbolName: 'dog' },
    { id: 1, round: 4, item_correct: 0, symbolName: 'carrot' },
    { id: 5, round: 5, item_correct: 0, symbolName: 'cube' },
    { id: 1, round: 6, item_correct: 1, symbolName: 'carrot' },
    { id: 6, round: 7, item_correct: 0, symbolName: 'hat' },
    { id: 1, round: 8, item_correct: 1, symbolName: 'carrot' },
    { id: 3, round: 9, item_correct: 0, symbolName: 'candy' },
    { id: 5, round: 10, item_correct: 0, symbolName: 'cube' },
    { id: 8, round: 11, item_correct: 0, symbolName: 'dog' },
    { id: 5, round: 12, item_correct: 1, symbolName: 'cube' },
    { id: 1, round: 13, item_correct: 0, symbolName: 'carrot' },
    { id: 9, round: 14, item_correct: 0, symbolName: 'ghost' },
    { id: 8, round: 15, item_correct: 0, symbolName: 'dog' },
    { id: 6, round: 16, item_correct: 0, symbolName: 'hat' },
    { id: 7, round: 17, item_correct: 0, symbolName: 'hammer' },
    { id: 6, round: 18, item_correct: 1, symbolName: 'hat' },
    { id: 6, round: 19, item_correct: 0, symbolName: 'hat' },
    { id: 8, round: 20, item_correct: 0, symbolName: 'dog' },
    { id: 4, round: 21, item_correct: 0, symbolName: 'cocktail' },
    { id: 9, round: 22, item_correct: 0, symbolName: 'ghost' },
    { id: 4, round: 23, item_correct: 1, symbolName: 'cocktail' },
    { id: 6, round: 24, item_correct: 0, symbolName: 'hat' },
    { id: 9, round: 25, item_correct: 0, symbolName: 'ghost' },
    { id: 5, round: 26, item_correct: 0, symbolName: 'cube' },
    { id: 9, round: 27, item_correct: 1, symbolName: 'ghost' },
    { id: 4, round: 28, item_correct: 0, symbolName: 'cocktail' },
    { id: 8, round: 29, item_correct: 0, symbolName: 'dog' },
    { id: 7, round: 30, item_correct: 0, symbolName: 'hammer' },
    { id: 3, round: 31, item_correct: 0, symbolName: 'candy' },
    { id: 7, round: 32, item_correct: 1, symbolName: 'hammer' }
  ]
]
