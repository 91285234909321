import { useContext, useEffect } from 'react'
import { query } from '../graphql'
import { AuthContext } from '../context'

export const useHandleOnChallengeAnswer = () => {
  const { rootStore } = useContext(AuthContext)
  useEffect(() => {
    query({
      query: 'spaceControl',
      variables: {
        user_id: rootStore.userStore.username,
        action: 'handleOnChallengeAnswer'
      }
    })
  }, [rootStore])
}
