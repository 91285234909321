import React, { useContext } from 'react'
import { View } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import ScienceText from './components/ScienceText'
import TradeOff from './components/TradeOff'
import NormalDistributionCurve from './components/NormalDistributionCurve'
import JobMatch from './components/JobMatch'
import { AuthContext } from '../../../context'

const AnalogicalReasoningResult = ({ answer }) => {
  const { rootStore } = useContext(AuthContext)

  const { challengeConfigs } = rootStore.appSettingsStore
  const filteredChallengeConfigsById = challengeConfigs.filter(
    (c) => c.id === 'ANALOGICAL_REASONING'
  )

  const heighest_score_answer_translated =
    filteredChallengeConfigsById[0]?.heighest_score_answer_translated

  const score_percent = answer.aivy_output.translatedzScores.score

  const pGeschwHeighestScoreAnswer = heighest_score_answer_translated?.avg_speed || 0

  const pGenauHeighestScoreAnswer = heighest_score_answer_translated?.avg_accuracy || 0

  const pGeschw = answer.aivy_output.translatedzScores.avg_speed
  const pGenau = answer.aivy_output.translatedzScores.avg_accuracy

  // ATTENTION CHART GOES FROM [-100, 100];
  const tradeoff = 100 - pGeschw - pGenau
  const tradeoffHeighestScoreAnswer = 100 - pGeschwHeighestScoreAnswer - pGenauHeighestScoreAnswer

  return (
    <View>
      <NormalDistributionCurve
        containerStyle={{ marginTop: 32 }}
        title={I18n.get('challenge.analogical_reasonning.result.normal_distribution_curve.title')}
        percent={Math.round(score_percent)}
        description={`${
          score_percent <= 30 // eslint-disable-line
            ? I18n.get('challenge.analogical_reasonning.result.score_percent_1')
            : score_percent > 30 && score_percent < 70
            ? I18n.get('challenge.analogical_reasonning.result.score_percent_2')
            : I18n.get('challenge.analogical_reasonning.result.score_percent_3')
        }`}
        labels={{
          left: I18n.get('challenge.analogical_reasonning.result.scale_endpoint.left'),
          right: I18n.get('challenge.analogical_reasonning.result.scale_endpoint.right')
        }}
      />
      <TradeOff
        containerStyle={{ marginTop: 16 }}
        title={I18n.get('global.accuracy_speed')}
        leftSkala={I18n.get('global.accuracy')}
        rightSkala={I18n.get('global.speed')}
        tradeoff={tradeoff}
        firstDescription={`${I18n.get(
          'challenge.analogical_reasoninng.result.tradeoff_description_1'
        )}, ${
          tradeoff >= 0
            ? I18n.get('challenge.analogical_reasonning.result.tradeoff_1')
            : I18n.get('challenge.analogical_reasonning.result.tradeoff_2')
        }.`}
        secondDescription={`${I18n.get('challenge.result.tradeoff.pretext_second')} ${
          tradeoffHeighestScoreAnswer >= 0 ? I18n.get('global.speed') : I18n.get('global.accuracy')
        }**.`}
      />
      <JobMatch
        exam_id='ANALOGICAL_REASONING'
        score={score_percent}
        containerStyle={{ marginTop: 16 }}
      />
      <ScienceText containerStyle={{ marginTop: 16 }} exam_id='ANALOGICAL_REASONING' />
    </View>
  )
}

AnalogicalReasoningResult.propTypes = {
  answer: PropTypes.object.isRequired
}

export default AnalogicalReasoningResult
