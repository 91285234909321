import React, { useContext } from 'react'
import { View } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import ScienceText from './components/ScienceText'
import TradeOff from './components/TradeOff'
import NormalDistributionCurve from './components/NormalDistributionCurve'
import JobMatch from './components/JobMatch'

import { AuthContext } from '../../../context'

const TowerOfLondonResult = ({ answer }) => {
  const { rootStore } = useContext(AuthContext)

  const { challengeConfigs } = rootStore.appSettingsStore
  const filteredChallengeConfigsById = challengeConfigs.filter((c) => c.id === 'PATH_FINDER')

  const heighest_score_answer_translated =
    filteredChallengeConfigsById[0]?.heighest_score_answer_translated

  const { avg_time_planning } = answer.aivy_output.translatedzScores

  const { planning_ability } = answer.aivy_output.translatedzScores

  const speedPercentageOfHeighestScoreAnswer =
    heighest_score_answer_translated?.avg_time_planning || 0

  const precisionPercentageOfHeighestScoreAnswer =
    heighest_score_answer_translated?.planning_ability || 0

  const planning_abilityPercentage = answer.aivy_output.translatedzScores.planning_ability

  const tradeoff = 100 - avg_time_planning - planning_ability
  const tradeoff_highscore =
    100 - speedPercentageOfHeighestScoreAnswer - precisionPercentageOfHeighestScoreAnswer

  return (
    <View>
      <NormalDistributionCurve
        title={I18n.get('challenge.tower_of_london.result.normal_distribution_curve.title')}
        containerStyle={{ marginTop: 32 }}
        description={`${
          planning_abilityPercentage <= 30 // eslint-disable-line
            ? I18n.get('challenge.tower_of_london.result.planning_ability_1')
            : planning_abilityPercentage > 30 && planning_abilityPercentage < 70
            ? I18n.get('challenge.tower_of_london.result.planning_ability_2')
            : I18n.get('challenge.tower_of_london.result.planning_ability_3')
        }`}
        percent={Math.round(planning_abilityPercentage)}
        labels={{
          left: I18n.get('challenge.tower_of_london.result.scale_endpoint.left'),
          right: I18n.get('challenge.tower_of_london.result.scale_endpoint.right')
        }}
      />
      <TradeOff
        containerStyle={{ marginTop: 16 }}
        title={I18n.get('global.accuracy_speed')}
        leftSkala={I18n.get('global.accuracy')}
        rightSkala={I18n.get('global.speed')}
        firstDescription={
          tradeoff >= 0
            ? I18n.get('challenge.tower_of_london.result.tradeoff_1')
            : I18n.get('challenge.tower_of_london.result.tradeoff_2')
        }
        secondDescription={
          tradeoff_highscore >= 0
            ? I18n.get('challenge.tower_of_london.result.tradeoff_highscore_1')
            : I18n.get('challenge.tower_of_london.result.tradeoff_highscore_2')
        }
        tradeoff={tradeoff}
      />
      <JobMatch
        exam_id='TOWER_OF_LONDON'
        score={planning_abilityPercentage}
        containerStyle={{ marginTop: 16 }}
      />
      <ScienceText containerStyle={{ marginTop: 16 }} exam_id='TOWER_OF_LONDON' />
    </View>
  )
}

TowerOfLondonResult.propTypes = {
  answer: PropTypes.object.isRequired
}

export default TowerOfLondonResult
