import React from 'react'
import { Modal, Platform } from 'react-native'
import ModalWeb from 'modal-react-native-web'

import PropTypes from 'prop-types'

const UniversalModal = ({ animationType, transparent, visible, children }) => {
  const UniversalComponent = Platform.OS === 'web' ? ModalWeb : Modal

  return (
    <UniversalComponent
      animationType={animationType}
      transparent={transparent}
      visible={visible}
      // {...props}
    >
      {children}
    </UniversalComponent>
  )
}

UniversalModal.propTypes = {
  animationType: PropTypes.string.isRequired,
  transparent: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}

export default UniversalModal
