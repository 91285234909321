import { useEffect } from 'react'
import { useFonts as useFontsExpo } from 'expo-font'
// import * as SplashScreen from 'expo-splash-screen'

import { notifyBugsnag } from '../utils/bugsnag'

export const useFonts = () => {
  const [fontsLoaded, err] = useFontsExpo({
    'fira-sans-light': require('../assets/fonts/FiraSans-Light.ttf'),
    'fira-sans-semi-bold': require('../assets/fonts/FiraSans-SemiBold.ttf'),
    'fira-sans-medium': require('../assets/fonts/FiraSans-Medium.ttf'),
    'fira-sans-regular': require('../assets/fonts/FiraSans-Regular.ttf'),
    'fira-sans-bold': require('../assets/fonts/FiraSans-Bold.ttf')
  })

  useEffect(() => {
    if (!err) return

    notifyBugsnag(err)
  }, [err])

  return fontsLoaded
}
