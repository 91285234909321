import React from 'react'
import { StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'

import Typo from '../../../Typo'
import colors from '../../../../theme/colors'
import LottieAnimation from '../../LottieAnimation'

const customMDStyles = StyleSheet.create({
  text: {
    fontFamily: 'fira-sans-regular',
    fontSize: 14,
    lineHeight: 22
  },
  strong: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'fira-sans-medium'
  }
})

const s = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: '#B6C7F2',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5
  },
  verticalLine: {
    height: 240,
    width: 2,
    backgroundColor: colors.lightBlue,
    position: 'relative',
    alignSelf: 'center'
  },
  horizontalLine: {
    width: '100%',
    height: 2,
    backgroundColor: colors.lightBlue,
    position: 'absolute',
    top: '50%',
    shadowColor: colors.primaryBlue,
    shadowOffset: {
      width: 0,
      height: 8
    },
    shadowOpacity: 0.16,
    shadowRadius: 2,
    elevation: 5
  }
})

const pointStyle = (positionOfPoint) => {
  if (positionOfPoint === 0) {
    return { position: 'absolute', left: '25%', top: '25%' }
  }
  if (positionOfPoint === 1) {
    return { position: 'absolute', left: '75%', top: '25%' }
  }
  if (positionOfPoint === 2) {
    return { position: 'absolute', left: '25%', top: '75%' }
  }
  if (positionOfPoint === 3) {
    return { position: 'absolute', left: '75%', top: '75%' }
  }
}

const HerosJournesMatrix = ({ containerStyle, positionOfPoint, resultText }) => (
  <View style={[s.container, containerStyle]}>
    <View style={{ marginBottom: 8 }}>
      <Typo.SmallTextBlack translate center>
        challenge.dreamteam.flexibility
      </Typo.SmallTextBlack>
    </View>
    <View style={{ height: 240, width: '100%' }}>
      <View style={{ position: 'absolute', top: '52%' }}>
        <Typo.SmallTextBlack translate>challenge.dreamteam.independence</Typo.SmallTextBlack>
      </View>
      <View style={{ position: 'absolute', top: '52%', right: 0 }}>
        <Typo.SmallTextBlack translate>challenge.dreamteam.cooperation</Typo.SmallTextBlack>
      </View>
      <View style={s.verticalLine} />
      <View style={s.horizontalLine} />
      <View style={pointStyle(positionOfPoint)}>
        <LottieAnimation
          loop
          autoplay
          width={32}
          height={32}
          animationData={require('../../../../assets/animations/matrixindicator.json')}
        />
      </View>
    </View>
    <View style={{ marginTop: 8 }}>
      <Typo.SmallTextBlack translate center>
        challenge.dreamteam.stability
      </Typo.SmallTextBlack>
    </View>
    <View style={{ marginTop: 16 }}>
      <Typo.Markdown style={customMDStyles}>{resultText}</Typo.Markdown>
    </View>
  </View>
)

HerosJournesMatrix.propTypes = {
  containerStyle: PropTypes.object.isRequired,
  positionOfPoint: PropTypes.number.isRequired,
  resultText: PropTypes.string.isRequired
}

export default HerosJournesMatrix
