import React from 'react'
import { View, StyleSheet, TouchableOpacity, Platform, Image, SafeAreaView } from 'react-native'
import { I18n } from 'aws-amplify'
import _ from 'lodash'
import { Ionicons } from '@expo/vector-icons'
import PropTypes from 'prop-types'

import metrics from '../../../theme/metrics'
import colors from '../../../theme/colors'
import Swiper from './components/Swiper'

import ChallengeResultWrapper from '../ChallengeResultWrapper'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import Instruction from '../../../components/challenge/Instruction'
import Intro from '../../../components/challenge/Intro'
import CountDownTimer from '../../../components/challenge/CountDownTimer'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import Loader from '../../../components/Loader'
import Typo from '../../../components/Typo'
import ChallengeContainer from '../ChallengeContainer'
import TimeIcon from '../../../components/svgs/TimeIcon'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import { getRandomMainQuestions, RIASEC_TEST_QUESTIONS } from './data'
import { AuthContext } from '../../../context'
import { trackEvent } from '../../../utils/tracking'

const block_time_ms = 300

class RiasecImages extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    this.countDown = React.createRef()
    this.swiperRef = React.createRef()

    const questions = props.testRound ? RIASEC_TEST_QUESTIONS : getRandomMainQuestions()
    //questions.length = 3

    this.state = {
      loading: true,
      currentQuestionIndex: 0,
      questions,
      answers: [],
      buttonsDisabled: false,
      countdownColor: colors.primaryBlue
    }
  }

  startTime = null
  endTime = null

  componentDidMount = async () => {
    // let images = []
    // const { questions } = this.state
    // questions.map((question) => {
    //   images.push(
    //     `https://s3.eu-central-1.amazonaws.com/react-native-app-content/riasec-images/${question.photo}.jpg`
    //   )
    // })
    // const imageAssets = this.cacheImages(images)
    // await Promise.all([...imageAssets])
    this.setState({ loading: false }, () => {
      this.startTime = new Date()
    })
    this._ismounted = true
  }

  componentWillUnmount() {
    this._ismounted = false
  }

  cacheImages = (images) => {
    return images.map((image) => Image.prefetch(image))
  }

  submit = async () => {
    const answer = this.state.answers.reduce((result, answer, idx) => {
      result[`round_${idx + 1}`] = answer
      return result
    }, {})
    this.props.submit(answer)
  }

  animateProgress = () => {
    this.context.rootStore.pauseStore.reset()
    this.onRoundStart()

    const lastQuestionReached = this.state.answers.length >= this.state.questions.length

    lastQuestionReached ? this.submit() : this.onRoundStart()
  }

  onRoundStart = () => {
    this.startTime = new Date()
    this.setState({ countdownColor: colors.primaryBlue })
  }

  setAnswer(decision) {
    this.endTime = new Date()
    this.countDown.current.resetTimer(4)
    const { answers, currentQuestionIndex, questions } = this.state
    const currentQuestion = questions[currentQuestionIndex]

    let time_needed_in_ms = this.endTime - this.startTime
    let calc_time_needed_in_ms =
      time_needed_in_ms < 300 || time_needed_in_ms > 5000 ? null : time_needed_in_ms // Vorbereitungsschritte
    let pause_in_ms = this.context.rootStore.pauseStore.sum
    let median = currentQuestion.median

    let calc_resp_latency = calc_time_needed_in_ms // Antwortlatenz
      ? 5000 - calc_time_needed_in_ms
      : null
    calc_resp_latency = calc_resp_latency >= 0 ? calc_resp_latency : null
    let calc_decision = decision === 0 ? -1 : 1
    let item_score = calc_resp_latency === null ? median : calc_decision * calc_resp_latency

    this.setState(
      {
        answers: answers.concat({
          id: currentQuestion.id,
          decision: decision,
          time_needed_in_ms,
          pause_in_ms,
          category: currentQuestion.value,
          resp_latency: calc_resp_latency,
          item_score
        }),
        currentQuestionIndex: Math.min(questions.length - 1, currentQuestionIndex + 1)
      },
      this.animateProgress
    )
  }

  lockInteraction = (ms) => {
    this.setState(
      {
        buttonsDisabled: true
      },
      () => {
        setTimeout(() => {
          if (!this._ismounted) return

          this.setState((prevState) => ({ buttonsDisabled: !prevState.buttonsDisabled }))
        }, ms)
      }
    )
  }

  onPressSwipeButton = (type) => {
    this.setState({ buttonsDisabled: true })
    if (this.swiperRef) {
      if (type) {
        // swipe right
        this.swiperRef.current.swipeRight()
      } else {
        // swipe left
        this.swiperRef.current.swipeLeft()
      }
    }
  }

  onSwiped = (type) => {
    this.lockInteraction(block_time_ms)
    this.setAnswer(type)
  }

  renderLeftHeader = () => {
    const { countdownColor } = this.state
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TimeIcon containerStyle={{ marginRight: 4 }} color={countdownColor} />
        <CountDownTimer
          ref={this.countDown}
          timeToShow={['M', 'S']}
          labelM={''}
          labelS={''}
          digitTxtColor={countdownColor}
          until={4}
          onFinish={() => this.setState({ countdownColor: colors.dislikeRed })}
          size={14}
        />
      </View>
    )
  }

  render() {
    const { loading, currentQuestionIndex, questions, buttonsDisabled } = this.state
    const { testRound } = this.props
    const currentQuestion = questions[currentQuestionIndex]
    const sizeButton = metrics.widthPercentageToDP(30) // (38 * width) / 100; 42% Button Größe von Screen Breite

    if (loading) return <Loader />

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: colors.lightPaleGrey }}>
        <ChallengeHeader
          title={
            testRound
              ? I18n.get('global.challenge.testround')
              : `${currentQuestionIndex + 1}/${questions.length}`
          }
          onPress={() => {
            trackEvent('ChallengePaused', { exam_id: 'RIASEC_IMAGES' })
            this.context.rootStore.pauseStore.start()
          }}
          leftHeader={this.renderLeftHeader()}
        />
        <View style={{ flex: 1 }}>
          <View style={[s.contentContainer]}>
            <Typo.H2LightBlack center marginTop={12} translate>
              challenge.riasec_images.header_question
            </Typo.H2LightBlack>
            <View style={{ position: 'relative' }}>
              <Swiper
                ref={this.swiperRef}
                cards={questions}
                renderCard={(card) => {
                  return (
                    <View style={s.card}>
                      <Image
                        style={{
                          height: 200,
                          width: '100%',
                          borderRadius: 4
                        }}
                        source={{
                          uri: `https://s3.eu-central-1.amazonaws.com/react-native-app-content/riasec-images/${card?.photo}.jpg`
                        }}
                      />
                    </View>
                  )
                }}
                verticalSwipe={false}
                onSwipedLeft={() => this.onSwiped(0)}
                onSwipedRight={() => this.onSwiped(1)}
                disableLeftSwipe={true}
                disableRightSwipe={true}
                stackSize={Platform.OS === 'web' ? 1 : 2}
                useViewOverflow={Platform.OS === 'ios'}
                cardIndex={currentQuestionIndex}
                backgroundColor={colors.lightPaleGrey}
              />
            </View>
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: 50
              }}
            >
              <Typo.T1Black center translate>
                {currentQuestion === undefined ? '' : currentQuestion.title}
              </Typo.T1Black>
            </View>
          </View>

          <View style={s.footer}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
              <TouchableOpacity
                disabled={buttonsDisabled || currentQuestionIndex + 1 === questions.length + 1}
                onPress={() => this.onPressSwipeButton(0)}
              >
                <View style={[s.dislikeButtonStyle, { height: sizeButton, width: sizeButton }]}>
                  <Ionicons name='md-close' size={40} color='white' />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={buttonsDisabled || currentQuestionIndex + 1 === questions.length + 1}
                onPress={() => this.onPressSwipeButton(1)}
              >
                <View style={[s.likeButtonStyle, { height: sizeButton, width: sizeButton }]}>
                  <Ionicons name='md-heart' size={40} color='white' />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </SafeAreaView>
    )
  }
}

const s = StyleSheet.create({
  likeButtonStyle: {
    backgroundColor: colors.brightGreen,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: colors.brightGreen,
    shadowOffset: {
      width: 0,
      height: 7
    },
    shadowOpacity: 0.41,
    shadowRadius: 9.11
  },
  dislikeButtonStyle: {
    backgroundColor: colors.carnationPink,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: colors.carnationPink,
    shadowOffset: {
      width: 0,
      height: 7
    },
    shadowOpacity: 0.41,
    shadowRadius: 9.11
  },
  contentContainer: {
    flex: 1 // pushes the footer to the end of the screen
  },
  footer: {
    paddingHorizontal: 20,
    paddingBottom: 40,
    paddingTop: 24
  },
  card: {
    height: 180,
    width: '100%',
    borderRadius: 4,
    justifyContent: 'center'
  },
  text: {
    textAlign: 'center',
    fontSize: 50,
    backgroundColor: 'transparent'
  }
})

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

const RiasecImagesChallenge = ({ onChallengeComplete }) => {
  return (
    <ChallengeContainer
      CHALLENGE={RiasecImages}
      EXAM={ALL_CHALLENGES.RIASEC_IMAGES}
      PRE_SCREENS={[
        { screen: IntroScreen, name: 'IntroScreen' },
        { screen: InstructionScreen, name: 'InstructionScreen' }
      ]}
      MIDDLE_SCREENS={[{ screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' }]}
      POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
      onChallengeComplete={onChallengeComplete}
    />
  )
}

RiasecImagesChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

RiasecImagesChallenge.defaultProps = { onChallengeComplete: () => {} }

export default RiasecImagesChallenge
