/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import {
  View,
  StyleSheet,
  ImageBackground,
  Image,
  SafeAreaView,
  TouchableOpacity,
  TextInput,
  LayoutAnimation,
  Animated,
  Easing
} from 'react-native'
import PropTypes from 'prop-types'
import { MaterialIcons } from '@expo/vector-icons'
import { I18n } from 'aws-amplify'

import ChallengeContainer from '../ChallengeContainer'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import colors from '../../../theme/colors'
import metrics from '../../../theme/metrics'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import TimeIcon from '../../../components/svgs/TimeIcon'
import CountDownTimer from '../../../components/challenge/CountDownTimer'
import { Typo } from '../../../components'
import Numpad from './components/Numpad'
import FirstIntro from './components/FirstIntroductionScreen'
import { ITEMS, TEST_ITEMS } from './data'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import Intro from '../../../components/challenge/Intro'
import Instruction from '../../../components/challenge/Instruction'
import SecondIntro from './components/SecondIntroductionScreen'
import ThirdIntro from './components/ThirdIntroductionScreen'
import asyncStorage from '../../../utils/async-storage'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import PreIntroduction from './components/PreIntroduction'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.darkBlack
  },
  contentContainer: {
    flex: 1
  },
  footer: {
    position: 'relative',
    backgroundColor: colors.linkWater
  }
})

const IMMEDIATE_FEEDBACK = { RIGHT: 'RIGHT', FALSE: 'FALSE' }

const Numeracy = ({ testRound, submit }) => {
  const countdown = useRef(null)
  const MAIN_QUESTIONS = testRound ? TEST_ITEMS : ITEMS
  const animatedValue = useRef(new Animated.Value(0)).current
  const t0 = useRef(new Date()) // useRef prevent reinit
  const t1 = useRef(null) // useRef prevent reinit
  const [language, setLanguage] = useState('de')

  useEffect(() => {
    const getLanguage = async () => {
      setLanguage((await asyncStorage.getData('lang')) || 'de')
    }

    getLanguage()
  }, [])

  const answers = useRef(
    MAIN_QUESTIONS[0].map((a) => ({
      correctness: 0,
      ...a
    }))
  )

  const [currentBlockIndex, setCurrentBlockIndex] = useState(0)
  const [currentRoundIndex, setCurrentRoundIndex] = useState(0)
  const [immediateFeedback, setImmediateFeedback] = useState(null)
  const [showIntro, setShowIntro] = useState(!!testRound)

  const [correctAnswer, setCorrectAnswer] = useState(null)

  const currentQuestion = MAIN_QUESTIONS[currentBlockIndex][currentRoundIndex]
  const maxRoundQuestionsLength = MAIN_QUESTIONS[currentBlockIndex].length
  const [currentAnswer, setCurrentAnswer] = useState('')

  const winningAnimation = () => {
    Animated.loop(
      Animated.timing(animatedValue, {
        toValue: 2 * metrics.screenHeight,
        duration: 15000,
        useNativeDriver: false,
        easing: Easing.linear
      })
    ).start()
  }

  const standardAnimation = () => {
    Animated.loop(
      Animated.timing(animatedValue, {
        toValue: 2 * metrics.screenHeight,
        duration: 100000,
        useNativeDriver: false,
        easing: Easing.linear
      })
    ).start()
  }

  const handleInput = (input) => {
    setCurrentAnswer((prevValue) => prevValue + input)
  }

  const handleSubmit = () => {
    t1.current = new Date()
    const time_needed_in_ms = t1.current - t0.current
    const isRight = parseInt(currentAnswer) === currentQuestion.right_answer
    const correctness = isRight ? 1 : 0

    const item_score = correctness * (5000 - time_needed_in_ms)

    // id,
    // category: dimension,
    // time_needed_in_ms,
    // retries,
    // decision,
    // pause_in_ms,
    // item_score,
    // resp_latency: calc_resp_latency

    answers.current[currentRoundIndex] = {
      id: currentQuestion.id,
      time_needed_in_ms,
      decision: currentAnswer,
      correctness,
      item_score,
      ...currentQuestion
    }

    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    if (isRight) {
      setCorrectAnswer(true)
      winningAnimation()
      setImmediateFeedback(IMMEDIATE_FEEDBACK.RIGHT)
    } else {
      setCorrectAnswer(false)
      setImmediateFeedback(IMMEDIATE_FEEDBACK.FALSE)
    }
    setCurrentAnswer('')

    setTimeout(() => {
      if (isRight) standardAnimation()
      setImmediateFeedback(null)
      t0.current = new Date()
      setCorrectAnswer(null)

      const isLastRound = currentRoundIndex === maxRoundQuestionsLength - 1
      const isLastBlock = testRound
        ? currentBlockIndex === 2 && isLastRound
        : currentBlockIndex === 0 && isLastRound

      if (isLastBlock) {
        setCurrentRoundIndex(0)
        setCurrentBlockIndex(0)
        if (testRound) submit([])
        const submitAnswer = answers.current.reduce((result, answer, idx) => {
          result[`round_${idx + 1}`] = answer
          return result
        }, {})
        submit(submitAnswer)
        return
      }

      if (isLastRound) {
        setCurrentRoundIndex(0)
        setCurrentBlockIndex(currentBlockIndex + 1)
        setShowIntro(true)
      } else {
        setCurrentRoundIndex(currentRoundIndex + 1)
      }
    }, 1500)
  }

  const clearAnswer = () => {
    setCurrentAnswer('')
  }

  const startGame = () => {
    if (showIntro === true) setShowIntro(false)

    t0.current = new Date()
    standardAnimation()
  }

  const countDownTimerOver = () => {
    const submitAnswer = answers.current.reduce((result, answer, idx) => {
      result[`round_${idx + 1}`] = answer
      return result
    }, {})
    submit(submitAnswer)
  }

  const resetGame = () => {}

  const renderLeftHeader = () => {
    if (testRound) return null

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TimeIcon containerStyle={{ marginRight: 4 }} color='#fff' />
        <CountDownTimer
          ref={countdown}
          timeToShow={['M', 'S']}
          labelM=''
          labelS=''
          digitTxtColor='#fff'
          until={300}
          size={14}
          onFinish={countDownTimerOver}
        />
      </View>
    )
  }

  if (showIntro && currentBlockIndex === 0) {
    return (
      <FirstIntro
        onPress={() => {
          startGame()
        }}
      />
    )
  }
  if (showIntro && currentBlockIndex === 1) {
    return (
      <SecondIntro
        onPress={() => {
          startGame()
        }}
      />
    )
  }
  if (showIntro && currentBlockIndex === 2) {
    return (
      <ThirdIntro
        onPress={() => {
          startGame()
        }}
      />
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <ChallengeHeader
        title={
          testRound
            ? I18n.get('global.challenge.testround')
            : `${currentRoundIndex + 1}/${maxRoundQuestionsLength}`
        }
        // onPress={() => {
        //   trackEvent('ChallengePaused', { exam_id: 'ANALOGICAL_REASONING' })
        //   rootStore.pauseStore.start()
        // }}
        titleStyle={{ color: '#fff' }}
        leftHeader={renderLeftHeader()}
      />
      <Animated.View
        style={{
          position: 'absolute',
          bottom: 0,
          height: 3 * metrics.screenHeight,
          flexDirection: 'column',
          transform: [{ translateY: animatedValue }]
        }}
      >
        {Array.from(Array(10).keys()).map(() => (
          <Image
            style={{
              width: metrics.screenWidth,
              height: metrics.screenHeight,
              resizeMode: 'repeat'
            }}
            source={require('../../../assets/challenges/numeracy/stars.png')}
          />
        ))}
      </Animated.View>
      <Image
        source={require('../../../assets/challenges/numeracy/planet.png')}
        style={{ height: 113, width: 102, marginLeft: 24 }}
      />
      <View style={{ flex: 1, justifyContent: 'flex-end', position: 'relative' }}>
        <Image
          style={{ width: 104, height: 126, position: 'absolute', bottom: 0, left: 24 }}
          source={
            correctAnswer === true
              ? require('../../../assets/challenges/numeracy/spaceship-fire.png')
              : require('../../../assets/challenges/numeracy/spaceship.png')
          }
        />
        <View style={{ alignItems: 'flex-end' }}>
          <View style={{ marginRight: 20, alignItems: 'flex-end' }}>
            <ImageBackground
              imageStyle={{
                ...metrics.calculateResponsiveDimensions({ height: 226, width: 281 })
              }}
              style={{
                ...metrics.calculateResponsiveDimensions({ height: 226, width: 281 }),
                marginBottom: 8,
                alignItems: 'center',
                justifyContent: 'center',
                paddingHorizontal: currentQuestion.type === 'TEXT' ? 32 : 16,
                paddingBottom: 20
              }}
              source={require('../../../assets/challenges/numeracy/speech-bubble.png')}
            >
              <Typo.T2Black marginBottom={4} center>
                {currentQuestion.type === 'NUMBER_SERIES'
                  ? I18n.get('challenge.numeracy.introduction.numberseries')
                  : currentQuestion.type === 'OPERATIONS'
                  ? I18n.get('challenge.numeracy.introduction.operation')
                  : ''}
              </Typo.T2Black>
              {currentQuestion.type === 'TEXT' ? (
                <Typo.T2Black style={{ fontSize: 14 }} center>
                  {language === 'de' ? currentQuestion.text_de : currentQuestion.text_en}
                </Typo.T2Black>
              ) : (
                <Typo.H1Black center>{currentQuestion.text}</Typo.H1Black>
              )}
            </ImageBackground>
            <Image
              style={{ width: 64, height: 110, marginRight: 40 }}
              source={require('../../../assets/challenges/numeracy/astronaut.png')}
            />
          </View>
        </View>
      </View>
      <View style={{ padding: 10, position: 'relative', justifyContent: 'center' }}>
        <TextInput
          style={{
            height: 40,
            width: '100%',
            backgroundColor:
              correctAnswer === true
                ? colors.buttonGreen
                : correctAnswer === false
                ? colors.buttonRed
                : '#fff',
            paddingHorizontal: 5,
            fontFamily: 'fira-sans-regular',
            borderRadius: 8,
            color: correctAnswer === null ? colors.darkBlack : '#fff'
          }}
          value={currentAnswer}
          editable={false}
          placeholder={
            correctAnswer === true
              ? I18n.get('challenge.numeracy.correct')
              : correctAnswer === false
              ? I18n.get('challenge.numeracy.false')
              : I18n.get('challenge.numeracy.input.title')
          }
        />
        {correctAnswer === null && (
          <TouchableOpacity style={{ position: 'absolute', right: 16 }} onPress={clearAnswer}>
            <MaterialIcons name='highlight-remove' size={24} color={colors.darkBlack} />
          </TouchableOpacity>
        )}
      </View>
      <Numpad
        onInput={handleInput}
        submitButtonDisabled={currentAnswer === ''}
        onSubmit={handleSubmit}
      />
    </SafeAreaView>
  )
}

Numeracy.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

const FirstGameIntroductionScreen = ({ nextScreen, exam, prevScreen }) => (
  <FirstIntro onPress={nextScreen} onPressBack={prevScreen} exam={exam} />
)

const SecondGameIntroductionScreen = ({ nextScreen, exam, prevScreen }) => (
  <SecondIntro onPress={nextScreen} onPressBack={prevScreen} exam={exam} />
)

const ThirdGameIntroductionScreen = ({ nextScreen, exam, prevScreen }) => (
  <ThirdIntro onPress={nextScreen} onPressBack={prevScreen} exam={exam} />
)

const PreIntroductionScreen = ({ nextScreen, exam, prevScreen }) => (
  <PreIntroduction onPress={nextScreen} onPressBack={prevScreen} exam={exam} />
)

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const NumeracyChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={Numeracy}
    EXAM={ALL_CHALLENGES.NUMERACY}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: PreIntroductionScreen, name: 'PreIntroductionScreen' }
    ]}
    MIDDLE_SCREENS={[{ screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' }]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

NumeracyChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

NumeracyChallenge.defaultProps = { onChallengeComplete: () => {} }

export default NumeracyChallenge
