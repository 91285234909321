import React, { useState, useEffect, useRef, useContext } from 'react'
import { View, Animated } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { observer } from 'mobx-react'
import colors from '../../../../theme/colors'
import LottieAnimation from '../../../../components/challenge/LottieAnimation'
import Typo from '../../../../components/Typo'
import Countdown from '../../../../components/challenge/Countdown'
import ChallengeHeader from '../../../../components/challenge/ChallengeHeader'
import {
  isAnswerCorrect,
  TIME_TO_OBSERVE,
  SYMBOLS,
  ROUND_LENGTH,
  checkPartialSolution,
  getBackgroundColor
} from '../data'
import SymbolContainer from './SymbolContainer'
import DigitSpanButton from './DigitSpanButton'
import Lock from './Lock'
import { AuthContext } from '../../../../context'
import { trackEvent } from '../../../../utils/tracking'
import { notifyBugsnag } from '../../../../utils/bugsnag'

const Question = ({ numberSequence, onQuestionComplete, backgroundColor, testRound, round }) => {
  const { rootStore } = useContext(AuthContext)
  const isFirstRun = useRef(true)

  const startTime = useRef(null)
  const endTime = useRef(null)
  const elapsedTime = useRef(null)

  const shakeAnim = useRef(new Animated.Value(0)).current
  const lottieAnim = useRef(null)
  const insets = useSafeAreaInsets()

  const [answer, setAnswer] = useState([])
  const [digit, setDigit] = useState(numberSequence[0])
  const [isVisibleNumpad, setIsVisibleNumpad] = useState(false)
  const [isLockedButton, setIsLockedButton] = useState(false)
  const [questionResult, setQuestionResult] = useState(null)
  const [isLockVisible, setIsLockVisible] = useState(true)
  const [isCountdownVisible, setIsCountdownVisible] = useState(true)

  const animation = useRef(new Animated.Value(numberSequence.length))
  const [backgroundColorInterpolation] = useState(
    animation.current.interpolate({
      inputRange: [3, 4, 5, 6, 7],
      outputRange: [
        colors.primaryBlue,
        colors.gigas,
        colors.hopbush,
        colors.cloudBurst,
        colors.brightSun
      ],
      extrapolate: 'clamp'
    })
  )

  const { imageWidth, imageHeight, imageSource } = SYMBOLS[parseInt(digit) - 1]

  const startTimer = () => {
    startTime.current = new Date()
  }

  const stopTimer = () => {
    endTime.current = new Date()

    elapsedTime.current = endTime.current - startTime.current

    if (elapsedTime.current < 0) {
      notifyBugsnag(
        `PathFinder Timing Log: startTime: ${startTime.current} || endTime: ${endTime.current} || elapsedTime: ${elapsedTime.current}`
      )
      elapsedTime.current = 5000
      return elapsedTime.current
    }

    return elapsedTime.current
  }

  const resetTimer = () => {
    startTime.current = null
    endTime.current = null
    elapsedTime.current = null
  }

  useEffect(() => {
    if (!isFirstRun.current) {
      let i = 1
      const interval = setInterval(() => {
        setDigit(numberSequence[i])
        i++
        if (i >= numberSequence.length) {
          clearInterval(interval)
        }
      }, TIME_TO_OBSERVE)
      setTimeout(() => {
        // SHOW NUMPAD
        setIsVisibleNumpad(true)
        startTimer()
      }, numberSequence.length * TIME_TO_OBSERVE)
    }
    isFirstRun.current = false
  }, [isCountdownVisible]) // eslint-disable-line

  const finishRound = () => {
    const time_needed_in_ms = elapsedTime.current
    resetTimer()

    const decision = isAnswerCorrect(numberSequence, answer)
    setTimeout(() => {
      onQuestionComplete({
        time_needed_in_ms,
        decision,
        numberSequence
      })
    }, 750)
  }

  const startShake = (animationCallback) => {
    Animated.sequence([
      Animated.timing(shakeAnim, { toValue: 10, duration: 100, useNativeDriver: false }),
      Animated.timing(shakeAnim, { toValue: -10, duration: 100, useNativeDriver: false }),
      Animated.timing(shakeAnim, { toValue: 10, duration: 100, useNativeDriver: false }),
      Animated.timing(shakeAnim, { toValue: 0, duration: 100, useNativeDriver: false })
    ]).start(animationCallback)
  }

  useEffect(() => {
    let isMounted = true
    stopTimer()
    if (answer.length === numberSequence.length && isMounted) {
      const decision = isAnswerCorrect(numberSequence, answer)
      setQuestionResult(decision)
      setIsLockedButton(true)
      if (decision === false) {
        // not solved
        startShake(finishRound)
      } else {
        // solved
        setTimeout(() => {
          if (!isMounted) return
          setIsLockVisible(false)
          lottieAnim.current.goToAndPlay()
          Animated.timing(animation.current, {
            toValue: numberSequence.length + 1,
            duration: 1500,
            delay: 1000,
            useNativeDriver: false
          }).start()
        }, 500)
      }
    } else if (!checkPartialSolution(answer, numberSequence)) {
      setIsLockedButton(true)
      startShake(finishRound)
    }

    return () => {
      isMounted = false
    }
  }, [answer]) // eslint-disable-line

  const saveAnswer = (digitShadow) => {
    setAnswer([...answer, digitShadow])
    // setEndTime(new Date());
  }

  const getAnimationData = () => {
    switch (numberSequence.length) {
      case 3:
        return require('../../../../assets/challenges/digit-span/game-assets/01.json')
      case 4:
        return require('../../../../assets/challenges/digit-span/game-assets/02.json')
      case 5:
        return require('../../../../assets/challenges/digit-span/game-assets/03.json')
      case 6:
        return require('../../../../assets/challenges/digit-span/game-assets/04.json')
      case 7:
        return require('../../../../assets/challenges/digit-span/game-assets/05.json')
      default:
        return require('../../../../assets/challenges/digit-span/game-assets/05.json')
    }
  }

  if (isCountdownVisible) {
    return (
      <Countdown
        onPress={() => setIsCountdownVisible(false)}
        backgroundColor={getBackgroundColor(numberSequence.length)}
      />
    )
  }

  if (!isVisibleNumpad) {
    return (
      <Animated.View
        style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor }}
      >
        <SymbolContainer
          imageSource={imageSource}
          imageWidth={imageWidth * 2}
          imageHeight={imageHeight * 2}
        />
      </Animated.View>
    )
  }

  return (
    <Animated.View
      style={{
        flex: 1,
        backgroundColor: backgroundColorInterpolation,
        paddingTop: insets.top + 12
      }}
    >
      <ChallengeHeader
        title={testRound ? I18n.get('global.challenge.testround') : `${round + 1}/${ROUND_LENGTH}`}
        onPress={() => {
          trackEvent('ChallengePaused', { exam_id: 'DIGIT_SPAN' })
          rootStore.pauseStore.start()
        }}
        titleStyle={{ color: '#fff' }}
      />
      <View style={{ flex: 1, alignItems: 'center' }}>
        <LottieAnimation
          ref={lottieAnim}
          loop={false}
          autoplay={false}
          width='100%'
          height={260}
          animationData={getAnimationData()}
          onAnimationFinish={finishRound}
        />
        <Animated.View
          style={{
            position: 'absolute',
            top: 120,
            transform: [{ translateX: shakeAnim }]
          }}
        >
          {isLockVisible && (
            <Lock
              length={numberSequence.length}
              answer={answer}
              error={questionResult === false || !checkPartialSolution(answer, numberSequence)}
              success={questionResult === true}
            />
          )}
        </Animated.View>
      </View>
      <View style={{ alignSelf: 'center', marginBottom: 64 }}>
        {questionResult === false && (
          <Typo.H1White center translate>
            immediate_feedback.keep_up
          </Typo.H1White>
        )}
        {questionResult === true && (
          <Typo.H1White center translate>
            immediate_feedback.very_good
          </Typo.H1White>
        )}
      </View>
      <View style={{ paddingHorizontal: 24, flexDirection: 'row', justifyContent: 'space-around' }}>
        <DigitSpanButton
          imageHeight={SYMBOLS[0].imageHeight}
          imageWidth={SYMBOLS[0].imageWidth}
          imageSource={SYMBOLS[0].imageSource}
          onPress={() => saveAnswer('1')}
          disabled={isLockedButton}
        />
        <DigitSpanButton
          imageHeight={SYMBOLS[1].imageHeight}
          imageWidth={SYMBOLS[1].imageWidth}
          imageSource={SYMBOLS[1].imageSource}
          onPress={() => saveAnswer('2')}
          disabled={isLockedButton}
        />
        <DigitSpanButton
          imageHeight={SYMBOLS[2].imageHeight}
          imageWidth={SYMBOLS[2].imageWidth}
          imageSource={SYMBOLS[2].imageSource}
          onPress={() => saveAnswer('3')}
          disabled={isLockedButton}
        />
      </View>
      <View
        style={{
          paddingHorizontal: 24,
          flexDirection: 'row',
          paddingBottom: 48,
          justifyContent: 'space-around'
        }}
      >
        <DigitSpanButton
          imageHeight={SYMBOLS[3].imageHeight}
          imageWidth={SYMBOLS[3].imageWidth}
          imageSource={SYMBOLS[3].imageSource}
          onPress={() => saveAnswer('4')}
          containerStyle={{ marginTop: 40 }}
          disabled={isLockedButton}
        />
        <DigitSpanButton
          imageHeight={SYMBOLS[4].imageHeight}
          imageWidth={SYMBOLS[4].imageWidth}
          imageSource={SYMBOLS[4].imageSource}
          onPress={() => saveAnswer('5')}
          containerStyle={{ marginTop: 40 }}
          disabled={isLockedButton}
        />
        <DigitSpanButton
          imageHeight={SYMBOLS[5].imageHeight}
          imageWidth={SYMBOLS[5].imageWidth}
          imageSource={SYMBOLS[5].imageSource}
          onPress={() => saveAnswer('6')}
          containerStyle={{ marginTop: 40 }}
          disabled={isLockedButton}
        />
      </View>
    </Animated.View>
  )
}

Question.propTypes = {
  numberSequence: PropTypes.array.isRequired,
  onQuestionComplete: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  testRound: PropTypes.bool.isRequired,
  round: PropTypes.number.isRequired
}

export default observer(Question)
