import _ from 'lodash'
import questions from './questions'

export const START_ANIM_AFTER_MS = 1500

export const SYMBOL_HEIGHT = 80 // Caution: ONLY CHANGE WHEN REPLACING LOTTIE FILE
export const SYMBOL_WIDTH = 138 // Caution; ONLY CHANGE WHEN REPLACING LOTTIE FILE

// SCORE ABZUG BEI FALSCH DRÜCKEN =  -1 * (Münzverlust beim Drücken/Münzgewinn bei richtiger Antwort)
// SCORE GEWINN BEI RICHTIG DRÜCKEN = 1
// SUMME SCORE / [Anzahl Correct Items] * 60 // ATTENTION IF < 0 then 0

export const STARTING_BALANCE = 200 // Nutzer startet mit 200 Münzen
export const SPIN_COST = 30 // Münzverlust beim Drücken
export const COIN_WIN = 80 // Münzgewinn bei richtiger Antwort

export const DURATION_PER_SYMBOL = 1500
// TIME NEEDED NEVER GREATER THEN DURATION_PER_SYMBOL

const SMYBOL_URL = 'https://react-native-app-content.s3.eu-central-1.amazonaws.com/nback'

export const SYMBOLS = [
  {
    id: 1,
    name: 'carrot',
    source: { uri: `${SMYBOL_URL}/carrot.png` }
  },
  {
    id: 2,
    name: 'lemon',
    source: { uri: `${SMYBOL_URL}/lemon.png` }
  },
  {
    id: 3,
    name: 'candy',
    source: { uri: `${SMYBOL_URL}/candy.png` }
  },
  {
    id: 4,
    name: 'cocktail',
    source: { uri: `${SMYBOL_URL}/cocktail.png` }
  },
  {
    id: 5,
    name: 'cube',
    source: { uri: `${SMYBOL_URL}/cube.png` }
  },
  {
    id: 6,
    name: 'hat',
    source: { uri: `${SMYBOL_URL}/hat.png` }
  },
  {
    id: 7,
    name: 'hammer',
    source: { uri: `${SMYBOL_URL}/hammer.png` }
  },
  {
    id: 8,
    name: 'dog',
    source: { uri: `${SMYBOL_URL}/dog.png` }
  },
  {
    id: 9,
    name: 'ghost',
    source: { uri: `${SMYBOL_URL}/ghost.png` }
  },
  {
    id: 10,
    name: 'fish',
    source: { uri: `${SMYBOL_URL}/fish.png` }
  },
  {
    id: 11,
    name: 'dollar',
    source: { uri: `${SMYBOL_URL}/dollar.png` }
  },
  {
    id: 12,
    name: 'radioactive',
    source: { uri: `${SMYBOL_URL}/radioactive.png` }
  },
  {
    id: 13,
    name: 'robot',
    source: { uri: `${SMYBOL_URL}/robot.png` }
  },
  {
    id: 14,
    name: 'heart',
    source: { uri: `${SMYBOL_URL}/heart.png` }
  }
]

export const getSymbolByPos = (pos, questionsShadow) => {
  const posAbs = Math.abs(Math.round(pos)) + SYMBOL_HEIGHT / 2 // Track Middle Pos
  for (let i = 0; i < questionsShadow.length; i++) {
    const maxPos = SYMBOL_HEIGHT * (i + 1)
    if (posAbs <= maxPos) {
      return questionsShadow[i]
    }
  }
}

export const getRandomQuestion = () => _.sample(questions)

export const question = [
  { id: 7, round: 1, item_correct: 0, symbolName: 'hammer' },
  { id: 4, round: 2, item_correct: 0, symbolName: 'cocktail' },
  { id: 2, round: 3, item_correct: 0, symbolName: 'lemon' },
  { id: 4, round: 4, item_correct: 1, symbolName: 'cocktail' },
  { id: 5, round: 5, item_correct: 0, symbolName: 'cube' },
  { id: 4, round: 6, item_correct: 1, symbolName: 'cocktail' },
  { id: 1, round: 7, item_correct: 0, symbolName: 'carrot' },
  { id: 4, round: 8, item_correct: 1, symbolName: 'cocktail' },
  { id: 2, round: 9, item_correct: 0, symbolName: 'lemon' },
  { id: 4, round: 10, item_correct: 1, symbolName: 'cocktail' },
  { id: 10, round: 11, item_correct: 0, symbolName: 'fish' },
  { id: 4, round: 12, item_correct: 1, symbolName: 'cocktail' },
  { id: 3, round: 13, item_correct: 0, symbolName: 'candy' },
  { id: 4, round: 14, item_correct: 1, symbolName: 'cocktail' },
  { id: 7, round: 15, item_correct: 0, symbolName: 'hammer' },
  { id: 2, round: 16, item_correct: 0, symbolName: 'lemon' },
  { id: 10, round: 17, item_correct: 0, symbolName: 'fish' },
  { id: 2, round: 18, item_correct: 1, symbolName: 'lemon' },
  { id: 7, round: 19, item_correct: 0, symbolName: 'hammer' },
  { id: 6, round: 20, item_correct: 0, symbolName: 'hat' },
  { id: 4, round: 21, item_correct: 0, symbolName: 'cocktail' },
  { id: 7, round: 22, item_correct: 0, symbolName: 'hammer' },
  { id: 5, round: 23, item_correct: 0, symbolName: 'cube' },
  { id: 6, round: 24, item_correct: 0, symbolName: 'hat' },
  { id: 5, round: 25, item_correct: 1, symbolName: 'cube' },
  { id: 6, round: 26, item_correct: 1, symbolName: 'hat' },
  { id: 1, round: 27, item_correct: 0, symbolName: 'carrot' },
  { id: 10, round: 28, item_correct: 0, symbolName: 'fish' },
  { id: 6, round: 29, item_correct: 0, symbolName: 'hat' },
  { id: 10, round: 30, item_correct: 1, symbolName: 'fish' },
  { id: 4, round: 31, item_correct: 0, symbolName: 'cocktail' },
  { id: 1, round: 32, item_correct: 0, symbolName: 'carrot' }
]
