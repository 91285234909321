import React from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'

import Typo from '../Typo'
import colors from '../../theme/colors'

const Separator = () => (
  <View
    style={{
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderColor: colors.lightGrey
    }}
  />
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(24, 32, 51, 0.7)'
  },
  pausedTextStyle: {
    fontSize: 26,
    color: '#fff',
    lineHeight: 30
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 180,
    height: 48,
    backgroundColor: '#fff'
  },
  buttonTextStyle: {
    fontSize: 14,
    color: colors.darkBlack,
    textTransform: 'uppercase'
  }
})

const Pause = ({ route }) => {
  const { onPressResume, onPressExit } = route.params

  return (
    <View style={styles.container}>
      <Typo.H1LightWhite marginBottom={20} translate>
        global.challenge.paused
      </Typo.H1LightWhite>
      <View>
        <TouchableOpacity onPress={onPressResume}>
          <View
            style={[styles.buttonContainer, { borderTopLeftRadius: 4, borderTopRightRadius: 4 }]}
          >
            <Typo.ButtonBlack style={{ color: colors.primaryBlue }} translate>
              global.challenge.continue
            </Typo.ButtonBlack>
          </View>
        </TouchableOpacity>
        <Separator />
        <TouchableOpacity onPress={onPressExit}>
          <View
            style={[
              styles.buttonContainer,
              { borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }
            ]}
          >
            <Typo.ButtonBlack translate>global.challenge.end</Typo.ButtonBlack>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}

Pause.propTypes = {
  route: PropTypes.object.isRequired
}

export default Pause
