import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'

const Dot = ({ onPress }) => (
  <TouchableOpacity onPress={onPress}>
    <View
      style={{
        backgroundColor: colors.primaryBlue,
        height: 2,
        width: 16,
        marginHorizontal: 8
      }}
    />
  </TouchableOpacity>
)

Dot.propTypes = {
  // index: PropTypes.number.isRequired,
  // currentIndex: PropTypes.number.isRequired,
  onPress: PropTypes.func.isRequired
}

export default Dot
