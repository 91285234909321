import React from 'react'
import { TouchableOpacity, Text } from 'react-native'
import PropTypes from 'prop-types'

import { CHALLENGE_SCREENS } from '.'

const ChallengeContainerScreen = ({ navigation, route }) => {
  const examId = route.params.challenge.exam_id
  const ChallengeScreen = CHALLENGE_SCREENS[examId]

  if (!ChallengeScreen) {
    return (
      <TouchableOpacity
        onPress={() => navigation.pop()}
        style={{ flex: 1, padding: 30, paddingTop: 100 }}
      >
        <Text>Challenge {examId} unter screens/challenges/index.js hinzufügen</Text>
      </TouchableOpacity>
    )
  }

  return <ChallengeScreen navigation={navigation} exam={route.params.challenge} />
}

ChallengeContainerScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
}

export default ChallengeContainerScreen
