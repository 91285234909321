import { makeAutoObservable } from 'mobx'
import { Animated, Platform } from 'react-native'

class PauseStore {
  constructor() {
    this.pauseInMS = 0
    this.sum = 0
    this.active = false
    this.blurIntensity = new Animated.Value(0)
    makeAutoObservable(this)
  }

  start() {
    this.pauseInMS = new Date()
    this.active = true
    Animated.timing(this.blurIntensity, {
      duration: 500,
      toValue: 100,
      useNativeDriver: Platform.OS !== 'web'
    }).start()
  }

  stop() {
    Animated.timing(this.blurIntensity, {
      duration: 500,
      toValue: 0,
      useNativeDriver: Platform.OS !== 'web'
    }).start()
    this.sum += new Date() - this.pauseInMS
    this.active = false
  }

  reset() {
    Animated.timing(this.blurIntensity, {
      duration: 500,
      toValue: 0,
      useNativeDriver: Platform.OS !== 'web'
    }).start()
    this.pauseInMS = 0
    this.sum = 0
    this.active = false
  }

  clear() {
    this.pauseInMS = 0
    this.sum = 0
    this.active = false
    this.blurIntensity = new Animated.Value(0)
  }
}

export default PauseStore
