import React from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'

import metrics from '../../../../theme/metrics'
import { HEROS } from '../data/heros-journey'
import { Typo } from '../../../../components'
import colors from '../../../../theme/colors'

const s = StyleSheet.create({
  cardStyle: {
    flex: 1,
    width: metrics.widthPercentageToDP(78),
    backgroundColor: '#fff',
    borderRadius: 4,
    shadowColor: '#E3E4E6',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8,
    marginRight: 14,
    padding: 16
  }
})

const HeroCard = ({ onPress, hero, index, selected }) => (
  <TouchableOpacity key={hero.value} activeOpacity={0.9} onPress={onPress}>
    <View
      style={[
        s.cardStyle,
        {
          marginRight: index + 1 === HEROS.length ? 40 : 14,
          backgroundColor: selected ? colors.primaryBlue : '#fff'
        }
      ]}
    >
      <Typo.H2White style={{ color: selected ? '#fff' : colors.primaryBlue }} translate>
        {hero.name}
      </Typo.H2White>
      <View style={{ paddignRight: 16 }}>
        <Typo.T2White
          marginTop={16}
          style={{ color: selected ? '#fff' : colors.darkBlack }}
          translate
        >
          {hero?.desc}
        </Typo.T2White>
      </View>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Image
          source={hero.image}
          resizeMode='contain'
          style={{
            width: metrics.widthPercentageToDP(30),
            height: metrics.widthPercentageToDP(30)
          }}
        />
      </View>
    </View>
  </TouchableOpacity>
)

HeroCard.propTypes = {
  onPress: PropTypes.func.isRequired,
  hero: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired
}

export default HeroCard
