/* eslint-disable camelcase */
export const answerSpecifications = {
  DELEGATION: {
    time_needed_in_ms: (time_needed_in_ms) => time_needed_in_ms > 0,
    length: (answers) => answers.length === 40
  },
  NUMERACY: {
    length: (answers) => answers.length === 40
  },
  ANALOGICAL_REASONING: {
    // TODO: correctness = decision = item_correct
    // TODO: semantic_distance: 0.84, // ?
    correctness: (correctness) => [true, false].includes(correctness),
    decision: (decision) => [0, 1].includes(decision),
    item_correct: (item_correct) => [true, false].includes(item_correct),
    pause_in_ms: (pause_in_ms) => pause_in_ms >= 0,
    round: (round) => round >= 1 && round <= 30,
    stimulus_class: (stimulus_class) =>
      ['crossdomain', 'withindomain', 'wronganalogy'].includes(stimulus_class),
    time_needed_in_ms: (time_needed_in_ms) => time_needed_in_ms >= 0 && time_needed_in_ms <= 15000,
    length: (answers) => answers.length === 30
  },
  DIGIT_SPAN: {
    // TODO: numberSequence
    // TODO: shortTermMemoryCapacity
    correct: (correct) => [true, false].includes(correct),
    pause_in_ms: (pause_in_ms) => pause_in_ms >= 0,
    round: (round) => round >= 0 && round <= 11,
    time_needed_in_ms: (time_needed_in_ms) => time_needed_in_ms > 0,
    length: (answers) => answers.length === 12
  },
  NBACK: {
    // TODO: item_correct corresponds to decision
    decision: (decision) => [0, 1].includes(decision),
    item_correct: (item_correct) => [0, 1].includes(item_correct),
    round: (round) => round >= 1 && round <= 32,
    symbolName: (symbolName) =>
      [
        'ghost',
        'hammer',
        'cocktail',
        'lemon',
        'cube',
        'carrot',
        'fish',
        'candy',
        'hat',
        'dog'
      ].includes(symbolName),
    time_needed_in_ms: (time_needed_in_ms) => time_needed_in_ms > 0 && time_needed_in_ms <= 1500,
    length: (answers) => answers.length === 32
  },
  HEROS_JOURNEY: {
    decision: (decision) => decision >= 0 && decision <= 10,
    time_needed_in_ms: (time_needed_in_ms) => time_needed_in_ms > 0,
    type: (type) => ['coopcomp', 'orderchaos'].includes(type),
    length: (answers) => answers.length === 10
  },
  FLANKER: {
    congruent: (congruent) => [true, false].includes(congruent),
    correct_item: (correct_item) => [0, 1].includes(correct_item),
    correct_score: (correct_score) => [-1, 1].includes(correct_score),
    decision: (decision) => [0, 1].includes(decision),
    pause_in_ms: (pause_in_ms) => pause_in_ms >= 0,
    time_needed_in_ms: (time_needed_in_ms) => time_needed_in_ms > 0,
    length: (answers) => answers.length === 32
  },
  TOWER_OF_LONDON: {
    draws: (draws) => draws >= 0,
    finish: (finish) => [true, false].includes(finish),
    min_required_draws: (min_required_draws) => min_required_draws > 0,
    pause_in_ms: (pause_in_ms) => pause_in_ms >= 0,
    success: (success) => [true, false].includes(success),
    time_planning: (time_planning) => time_planning > 0,
    time_solving: (time_solving) => time_solving >= 0,
    length: (answers) => answers.length === 9
  },
  RIASEC_IMAGES: {
    // TODO: item_score
    // TODO: resp_latency
    category: (category) => ['R', 'I', 'A', 'S', 'E', 'C'].includes(category),
    decision: (decision) => [0, 1].includes(decision),
    pause_in_ms: (pause_in_ms) => pause_in_ms >= 0,
    time_needed_in_ms: (time_needed_in_ms) => time_needed_in_ms > 0,
    length: (answers) => answers.length === 36
  },
  PATH_FINDER: {
    // TODO: problem_solving
    // TODO: score
    draws: (draws) => draws >= 0,
    pause_in_ms: (pause_in_ms) => pause_in_ms >= 0,
    success: (success) => [true, false].includes(success),
    time_needed_in_ms: (time_planning) => time_planning > 0,
    time_planning: (time_solving) => time_solving >= 0,
    verify_answer: ({ time_needed_in_ms, time_planning, time_solving }) =>
      time_needed_in_ms === time_planning + time_solving,
    length: (answers) => answers.length === 5
  },
  BIG_FIVE: {
    // TODO: item_score
    // TODO: resp_latency
    // TODO: retries
    category: (category) => ['O', 'C', 'E', 'A', 'N'].includes(category),
    decision: (decision) => [1, -1].includes(decision),
    pause_in_ms: (pause_in_ms) => pause_in_ms >= 0,
    round: (round) => round >= 1 && round <= 60,
    time_needed_in_ms: (time_needed_in_ms) => time_needed_in_ms > 0,
    length: (answers) => answers.length === 60
  },
  EMOTIONS_GAME: {
    // TODO: item_deliberation
    decision: (decision) =>
      ['sadness', 'surprise', 'anger', 'disgust', 'fear', 'happiness'].includes(decision),
    emotion: (emotion) =>
      ['sadness', 'surprise', 'anger', 'disgust', 'fear', 'happiness'].includes(emotion),
    pause_in_ms: (pause_in_ms) => pause_in_ms >= 0,
    round: (round) => round >= 1 && round <= 24,
    success: (success) => [true, false].includes(success),
    time_needed_in_ms: (time_needed_in_ms) => time_needed_in_ms > 0,
    verify_answer: ({ decision, emotion, success }) => (decision === emotion) === success,
    length: (answers) => answers.length === 24
  }
}
