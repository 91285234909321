import React, { useRef, useState } from 'react'
import { Auth, I18n } from 'aws-amplify'
import { StyleSheet, ScrollView } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { AivyTextInput, Button } from '../../components'
import { notifyBugsnag } from '../../utils/bugsnag'
// import { AuthContext } from '../../context'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  }
})

const SignIn = ({ navigation }) => {
  // { navigation }
  // const { signIn } = useContext(AuthContext)
  const formRef = useRef()
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(I18n.get('global.save_strength_email.email_input_required'))
      .email(I18n.get('global.save_strength_email.email_input_check'))
  })

  const showAlert = (email) => {
    navigation.navigate('Alert', {
      title: I18n.get('global.save_strength_email.email_input_alert_title'),
      message: `${email}`,
      buttons: [
        {
          text: I18n.get('global.save_strength_email.email_input_alert_cancel_action'),
          onPress: () => {
            formRef.current.resetForm()
          }, // specifiy undefined to disable Alert when pressing this button
          style: 'cancel'
        },
        {
          text: I18n.get('global.save_strength_email.email_input_alert_success_action'),
          onPress: async () => {
            setIsLoading(true)
            Auth.configure({
              authenticationFlowType: 'CUSTOM_AUTH'
            })
            try {
              const cognitoUser = await Auth.signIn(email.toLowerCase())
              navigation.navigate('VerifyCode', { email, cognitoUser })
            } catch (err) {
              switch (err.code) {
                case 'UserNotFoundException':
                  formRef.current.setFieldError(
                    'email',
                    I18n.get('global.alert.error.mail_not_found')
                  )
                  break
                default:
                  formRef.current.setFieldError('email', err.message)
                  notifyBugsnag(err)
                  break
              }
            } finally {
              setIsLoading(false)
            }
          }
        }
      ]
    })
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values) => showAlert(values.email)}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {({ handleChange, handleBlur, values, errors, touched, isValid, handleSubmit }) => (
        <ScrollView
          keyboardDismissMode='on-drag'
          style={styles.container}
          contentContainerStyle={{ paddingHorizontal: 16, paddingTop: 32 }}
        >
          <AivyTextInput
            label={I18n.get('global.sign_in.email')}
            onChangeText={handleChange('email')}
            onBlur={() => handleBlur('email')}
            value={values.email}
            error={errors.email && touched.email}
            errorText={errors.email || undefined}
            isValid={isValid.valueOf('email')}
            keyboardType='email-address'
          />
          <Button
            title='global.sign_in.action'
            containerStyle={{ marginTop: 56 }}
            isLoading={isLoading}
            disabled={!isValid.valueOf('email') || values.email === ''}
            onPress={handleSubmit}
          />
        </ScrollView>
      )}
    </Formik>
  )
}

SignIn.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default SignIn
