import React, { useContext, useRef, useState } from 'react'
import { View, StyleSheet, SafeAreaView } from 'react-native'
import _ from 'lodash'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import ChallengeContainer from '../ChallengeContainer'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import Instruction from '../../../components/challenge/Instruction'
import Intro from '../../../components/challenge/Intro'
import CountDownTimer from '../../../components/challenge/CountDownTimer'
import Questionnaire from '../../../components/challenge/Questionnaire'
import TimeIcon from '../../../components/svgs/TimeIcon'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import { QUESTIONS, TEST_QUESTIONS } from './data'
import Question from './components/Question'
import Button from './components/Button'
import Alert from './components/Alert'
import colors from '../../../theme/colors'
import { AuthContext } from '../../../context'
import { trackEvent } from '../../../utils/tracking'

const s = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightPaleGrey
  },
  contentContainer: {
    flex: 1 // pushes the footer to the end of the screen
  },
  footer: {
    paddingBottom: 24,
    paddingTop: 24
  }
})

const AnalogicalReasoning = ({ testRound, submit }) => {
  const { rootStore } = useContext(AuthContext)

  const t0 = useRef(new Date()) // useRef prevent reinit
  const t1 = useRef(null) // useRef prevent reinit

  const [round, setRound] = useState(0)
  const [decision, setDecision] = useState(null)
  const [answers, setAnswers] = useState([])
  const questionnaire = useRef(null)
  const countdown = useRef(null)
  const [alertBottomPos, setAlertBottomPos] = useState(0)

  const renderLeftHeader = () => {
    if (testRound) return null

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TimeIcon containerStyle={{ marginRight: 4 }} color={colors.primaryBlue} />
        <CountDownTimer
          ref={countdown}
          timeToShow={['M', 'S']}
          labelM=''
          labelS=''
          digitTxtColor={colors.primaryBlue}
          until={15}
          size={14}
        />
      </View>
    )
  }

  const isAnswerCorrect = (correctness, choice) => {
    if (correctness) {
      return choice === 1
    }
    return choice === 0
  }

  const resetRound = () => {
    if (!testRound) {
      countdown.current.resetTimer(5)
    }

    rootStore.pauseStore.reset()
  }

  const choicePressed = (choice) => {
    setDecision(choice)

    const { id, semantic_distance, correctness, stimulus_class } = testRound
      ? TEST_QUESTIONS[round]
      : QUESTIONS[round]

    t1.current = new Date()
    const time_needed_in_ms = t1.current - t0.current
    const item_correct = isAnswerCorrect(correctness, choice)
    const pause_in_ms = rootStore.pauseStore.sum

    setAnswers(
      answers.concat([
        {
          round: round + 1,
          id,
          time_needed_in_ms: time_needed_in_ms > 15000 ? 15000 : time_needed_in_ms,
          pause_in_ms,
          decision: choice,
          item_correct,
          correctness,
          semantic_distance,
          stimulus_class
        }
      ])
    )
    resetRound()

    if (questionnaire) {
      setTimeout(() => {
        setDecision(null)
        t0.current = new Date()
        t1.current = null
        questionnaire.current?.pressFooterPrimaryButton()
      }, 750)
    }
  }

  const onSubmit = () => {
    if (!testRound) {
      submit(
        answers.reduce((result, answer, idx) => {
          result[`round_${idx + 1}`] = answer
          return result
        }, {})
      )
    } else {
      submit([])
    }
  }

  return (
    <SafeAreaView style={s.container}>
      <ChallengeHeader
        title={
          testRound ? I18n.get('global.challenge.testround') : `${round + 1}/${QUESTIONS.length}`
        }
        onPress={() => {
          trackEvent('ChallengePaused', { exam_id: 'ANALOGICAL_REASONING' })
          rootStore.pauseStore.start()
        }}
        leftHeader={renderLeftHeader()}
      />
      <View style={s.contentContainer}>
        <Questionnaire
          ref={questionnaire}
          questions={testRound ? TEST_QUESTIONS : QUESTIONS}
          onSubmit={onSubmit}
          hideProgress
          renderQuestion={(item) => (
            <Question key={item.id} item={item} choicePressed={choicePressed} />
          )}
          onPrevOrNext={(idx) => setRound(idx)}
          showFooter={false}
        />
      </View>
      <View style={s.footer}>
        <Alert
          answer={answers[round]}
          containerStyle={{ position: 'absolute', width: '100%', bottom: alertBottomPos + 24 + 8 }}
        />
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Button
            type='false'
            onPress={() => choicePressed(0)}
            active={decision === 0}
            disabled={!_.isNull(decision)}
            onLayout={(event) => setAlertBottomPos(event.nativeEvent.layout.height)}
          />
          <Button
            type='correct'
            onPress={() => choicePressed(1)}
            active={decision === 1}
            disabled={!_.isNull(decision)}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}

AnalogicalReasoning.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

IntroScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

InstructionScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

TestRoundCompleteScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  prevScreen: PropTypes.func.isRequired
}

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const AnalogicalReasoningChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={AnalogicalReasoning}
    EXAM={ALL_CHALLENGES.ANALOGICAL_REASONING}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: InstructionScreen, name: 'InstructionScreen' }
    ]}
    MIDDLE_SCREENS={[{ screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' }]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

AnalogicalReasoningChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

AnalogicalReasoningChallenge.defaultProps = { onChallengeComplete: () => {} }

export default AnalogicalReasoningChallenge
