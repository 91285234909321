import React, { useContext, useEffect, useRef, useState } from 'react'
import { I18n } from 'aws-amplify'
import {
  Animated,
  SafeAreaView,
  View,
  StyleSheet,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  TextInput,
  StatusBar
} from 'react-native'
import * as Device from 'expo-device'
import { Asset } from 'expo-asset'
import PropTypes from 'prop-types'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useKeyboard } from '@react-native-community/hooks'
import _ from 'lodash'
import { observer } from 'mobx-react'
import WebPlatform from 'platform'

import colors from '../../theme/colors'
import { Typo, Loader, Button, RatingListItem } from '../../components'
import metrics from '../../theme/metrics'
import { isChallengeDemo, sendSlackMessage } from '../../utils/helpers'
import { notifyBugsnag } from '../../utils/bugsnag'
import { AuthContext, NotificationContext } from '../../context'
import { CHALLENGE_RESULT_COMPONENTS } from '../../components/challenge/result'
import { trackEvent } from '../../utils/tracking'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightPaleGrey
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingTop: 16,
    paddingBottom: 8,
    backgroundColor: colors.lightPaleGrey
  },
  examTitleStyle: {
    fontSize: 20,
    color: colors.darkBlack
  },
  examCountStyle: {
    fontSize: 16,
    color: colors.darkBlue
  },
  examIconStyle: {
    height: 56,
    width: 56,
    borderRadius: 28,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: colors.lightGrey
  },
  examScoreStyle: {
    fontSize: 16,
    textTransform: 'uppercase'
  },
  examHighscoreStyle: {
    fontSize: 12,
    color: colors.darkBlack,
    textTransform: 'uppercase'
  },
  examHighScore: {
    fontSize: 26,
    color: colors.primary,
    lineHeight: 30
  },
  textinput: {
    borderWidth: 1,
    borderColor: colors.cadetBlue,
    borderRadius: 4,
    lineHeight: 22,
    fontSize: 16,
    paddingHorizontal: 8,
    paddingTop: 16,
    height: 96
  }
})

const ChallengeResultWrapper = ({ exam, onPress, answer, route }) => {
  const demo = isChallengeDemo()
  const innerExam = exam || route.params.exam
  const innerOnPress = onPress || route.params.onPress
  const innerAnswer = answer || route.params.answer

  const insets = useSafeAreaInsets()
  const { keyboardShown, keyboardHeight } = useKeyboard()

  const { rootStore } = useContext(AuthContext)
  const { success } = useContext(NotificationContext)

  const [isLoading, setIsLoading] = useState(true)
  const [isFrowning, setIsFrowning] = useState(false)
  const [isSmiling, setIsSmiling] = useState(false)
  const [resultRatingInfo, setResultRatingInfo] = useState('')
  const [zScoreVars, setZScoreVars] = useState(null)
  const [scrollToEnd, setScrollToEnd] = useState(false)
  const [feddbackSent, setFeedbackSent] = useState('INITIAL')

  const animatedValueFrown = useRef(new Animated.Value(1))
  const animatedValueSmile = useRef(new Animated.Value(1))

  const scrollView = useRef()
  const isFirstRun = useRef(true)
  const { language } = rootStore.userStore
  const { challengeConfigs } = rootStore.appSettingsStore

  useEffect(() => {
    let isMounted = true

    Promise.all([
      Asset.loadAsync([
        require('../../assets/images/score.png'),
        require('../../assets/images/medal.png')
      ])
    ])
      .then(() => isMounted && setIsLoading(false))
      .catch((err) => notifyBugsnag(err))

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (demo === true) return
    if (isLoading || _.isEmpty(challengeConfigs)) return
    const challengeConfigForId = challengeConfigs.find((config) => config.id === innerExam.exam_id)
    if (challengeConfigForId === undefined)
      throw new Error(
        `${innerExam.exam_id} is missing ChallengeConfig entry in table. Check current ChallengeConfig ID in System Table too.`
      )
    setZScoreVars(challengeConfigs.find((config) => config.id === innerExam.exam_id) ?? {})
  }, [isLoading, challengeConfigs, innerExam, demo])

  useEffect(() => {
    if (isFirstRun.current) return

    setScrollToEnd(true)
  }, [keyboardShown])

  useEffect(() => {
    if (scrollToEnd) {
      setScrollToEnd(false)
      setTimeout(() => {
        if (scrollView.current) scrollView.current.scrollToEnd({ animated: true })
      }, 1000 * 0.2)
    }
  }, [scrollToEnd])

  useEffect(() => {
    if (_.isEmpty(innerAnswer)) return

    if (innerAnswer.result_rating_info === 'positive') {
      setIsFrowning(false)
      setIsSmiling(true)
      setFeedbackSent('DONE')
      handlePressSmiling()

      return
    }

    if (innerAnswer.result_rating_info) {
      setIsFrowning(true)
      setIsSmiling(false)
      setFeedbackSent('DONE')
      handlePressFrowning()
    }
  }, [innerAnswer])

  useEffect(() => {
    isFirstRun.current = false
  }, [])

  function handlePressFrowning() {
    animatedValueSmile.current.setValue(1)
    Animated.spring(animatedValueFrown.current, {
      toValue: 1.5,
      friction: 3,
      tension: 40,
      useNativeDriver: Platform.OS !== 'web'
    }).start()
  }

  function handlePressSmiling() {
    animatedValueFrown.current.setValue(1)
    Animated.spring(animatedValueSmile.current, {
      toValue: 1.5,
      friction: 3,
      tension: 40,
      useNativeDriver: Platform.OS !== 'web'
    }).start()
  }

  const next = async () => {
    if (isSmiling && feddbackSent !== 'DONE') {
      try {
        await rootStore.answersStore.updateAnswer({
          data: {
            id: innerAnswer.id,
            result_rating_info: 'positive'
          },
          career_id: rootStore.spaceStore.isAivySelect,
          owner: rootStore.userStore.username
        })
        // sendSlackMessage('support', `Positives Feedback für Challenge: ${innerExam?.exam_id} 🙂`)

        trackEvent('ResultRated', { type: 'positive' })
        // success('global.alert.success.feedback')
      } catch (err) {
        notifyBugsnag(err)
      }
    }

    innerOnPress()
  }

  const submitFeedback = async () => {
    setFeedbackSent('IS_LOADING')

    try {
      await rootStore.answersStore.updateAnswer({
        data: {
          id: innerAnswer.id,
          result_rating_info: resultRatingInfo
        },
        career_id: rootStore.spaceStore.isAivySelect,
        owner: rootStore.userStore.username
      })

      const metaData = { owner: rootStore.userStore.username }
      if (Platform.OS === 'web') {
        metaData.description = WebPlatform.description
        metaData.layout = WebPlatform.layout
        metaData.manufacturer = WebPlatform.manufacturer
        metaData.name = WebPlatform.name
        metaData.os = WebPlatform.os
        metaData.product = WebPlatform.product
        metaData.version = WebPlatform.version
      } else {
        metaData.brand = Device.brand
        metaData.deviceName = Device.deviceName
      }

      sendSlackMessage(
        'support',
        `New feedback by user for challenge ${
          innerExam?.exam_id
        }: ${resultRatingInfo} |  system information: ${JSON.stringify(metaData)}`
      )

      trackEvent('ResultRated', { type: 'negative' })
      setResultRatingInfo('')
      setFeedbackSent('DONE')
      success('global.alert.success.feedback')
    } catch (err) {
      notifyBugsnag(err)
      setFeedbackSent('INITIAL')
    }
  }

  const ChallengeResultComponent = CHALLENGE_RESULT_COMPONENTS[innerExam.exam_id]

  const animatedStyleSmile = {
    transform: [{ scale: animatedValueSmile.current }]
  }

  const animatedStyleFrown = {
    transform: [{ scale: animatedValueFrown.current }]
  }

  if (demo === false && (isLoading || !zScoreVars)) {
    return <Loader style={{ backgroundColor: colors.lightPaleGrey }} />
  }

  return (
    <SafeAreaView style={[styles.container, { paddingTop: insets.top }]}>
      <StatusBar barStyle='dark-content' />
      <ScrollView
        ref={scrollView}
        contentInsetAdjustmentBehavior='automatic'
        showsVerticalScrollIndicator={Platform.OS === 'web'}
        contentContainerStyle={{
          paddingBottom: keyboardShown ? keyboardHeight : 48
        }}
        style={{ flex: 1, backgroundColor: colors.lightPaleGrey }}
      >
        <View>
          <View style={[styles.header]}>
            <View style={{ justifyContent: 'center' }}>
              <Typo.H2Black translate marginBottom={4} style={styles.examTitleStyle}>
                {innerExam.title[language]}
              </Typo.H2Black>
            </View>
            <Image
              resizeMode={Platform.OS === 'android' ? undefined : 'contain'}
              source={{ uri: innerExam.card.image }}
              style={[styles.examIconStyle, { backgroundColor: innerExam.backgroundColor }]}
            />
          </View>
        </View>
        {demo === false && (
          <View style={{ paddingHorizontal: 16 }}>
            <RatingListItem
              challenge={innerExam}
              answer={innerAnswer}
              containerStyles={{ marginTop: 8 }}
            />
          </View>
        )}
        <View style={{ paddingHorizontal: 16 }}>
          <ChallengeResultComponent
            zScoreVars={zScoreVars}
            answer={innerAnswer}
            exam={innerExam}
            demo={demo}
          />
        </View>

        {demo === false && (
          <View style={{ padding: 24, marginBottom: keyboardShown ? 0 : 64 }}>
            <Typo.T1Black translate center>
              challenge.result.feedback.title
            </Typo.T1Black>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 10
              }}
            >
              <TouchableOpacity
                activeOpacity={1}
                disabled={feddbackSent === 'DONE'}
                onPress={() => {
                  setIsFrowning(true)
                  setIsSmiling(false)
                  setScrollToEnd(true)
                }}
                onPressOut={() => handlePressFrowning()}
              >
                <Animated.View style={animatedStyleFrown}>
                  <Image
                    style={{
                      width: 32,
                      height: 32,
                      margin: 24
                    }}
                    source={require('../../assets/images/rating/slightly-frowning-face.png')}
                  />
                </Animated.View>
              </TouchableOpacity>

              <TouchableOpacity
                activeOpacity={1}
                disabled={feddbackSent === 'DONE'}
                onPress={() => {
                  setIsFrowning(false)
                  setIsSmiling(true)
                  setResultRatingInfo('')
                }}
                onPressOut={() => handlePressSmiling()}
              >
                <Animated.View style={animatedStyleSmile}>
                  <Image
                    style={{
                      width: 32,
                      height: 32,
                      margin: 24
                    }}
                    source={require('../../assets/images/rating/slightly-smiling-face.png')}
                  />
                </Animated.View>
              </TouchableOpacity>
            </View>
            {isFrowning && feddbackSent !== 'DONE' && (
              <View>
                <TextInput
                  style={[
                    styles.textinput,
                    { height: metrics.heightPercentageToDP(15) },
                    Platform.OS === 'web' ? { outlineColor: colors.primaryBlue } : {}
                  ]}
                  textAlignVertical='top'
                  multiline
                  underlineColorAndroid='transparent'
                  placeholderTextColor={colors.raven}
                  placeholder={I18n.get('global.textinput.placeholder.tellusmore')}
                  value={resultRatingInfo}
                  onChangeText={(input) => setResultRatingInfo(input)}
                />
                <View
                  style={{
                    width: '100%',
                    marginTop: 24,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typo.T1Black translate style={{ flex: 1 }}>
                    challenge.result.feedback.description
                  </Typo.T1Black>
                  <View style={{ flex: 1 }}>
                    <Button
                      disabled={resultRatingInfo === ''}
                      onPress={submitFeedback}
                      title='global.send'
                      isLoading={feddbackSent === 'IS_LOADING'}
                    />
                  </View>
                </View>
              </View>
            )}
          </View>
        )}
      </ScrollView>
      {demo === false && (
        <View style={{ position: 'absolute', width: '100%', bottom: 0 }}>
          <View style={{ padding: 24, paddingBottom: 0 }}>
            <Button
              onPress={next}
              containerStyle={{
                marginTop: 32,
                marginBottom: insets.bottom + 32
              }}
              title='global.challenge.result.backbutton'
            />
          </View>
        </View>
      )}
    </SafeAreaView>
  )
}

ChallengeResultWrapper.propTypes = {
  exam: PropTypes.object,
  onPress: PropTypes.func,
  answer: PropTypes.object,
  route: PropTypes.object
}

ChallengeResultWrapper.defaultProps = {
  exam: undefined,
  onPress: undefined,
  answer: undefined,
  route: undefined
}

export default observer(ChallengeResultWrapper)
