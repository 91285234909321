import colors from '../../../../theme/colors'

const APP_CONTENT_PREFIX = 'https://react-native-app-content.s3.eu-central-1.amazonaws.com'

export const buttonTypes = {
  arrow_left: {
    backgroundColor: colors.white,
    source: `${APP_CONTENT_PREFIX}/flanker_v2/button-icons/arrow-left.png`
  },
  arrow_right: {
    backgroundColor: colors.white,
    source: `${APP_CONTENT_PREFIX}/flanker_v2/button-icons/arrow-right.png`
  },
  correct: {
    backgroundColor: colors.buttonGreen,
    source: `${APP_CONTENT_PREFIX}/flanker_v2/button-icons/correct.png`
  },
  wrong: {
    backgroundColor: colors.buttonRed,
    source: `${APP_CONTENT_PREFIX}/flanker_v2/button-icons/wrong.png`
  }
}
