import React, { useReducer, useEffect, useMemo, useState, useRef } from 'react'
import { Auth } from 'aws-amplify'
import { LogBox, AppState } from 'react-native'
import 'moment/locale/de'
import PropTypes from 'prop-types'

import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { AuthContext, NotificationContext } from './context'
import Navigation from './navigation'
import { getNotificationHolder } from './utils/notification-holder'
import { notifyBugsnag } from './utils/bugsnag'
// import TextLoaderStatus from './components/TextLoaderStatus'
import InvitationUsed from './components/InvitationUsed'
import InvitationExpired from './components/InvitationExpired'
import InvitationDeleted from './components/InvitationDeleted'
import invitationStates from './constants/invitationStates'
import asyncStorage from './utils/async-storage'
import initializeApp from './utils/initializeApp'
import { ContentLoader } from './components'
import { trackEvent } from './utils/tracking'
import { useMount } from './hooks/use-mount'

LogBox.ignoreLogs(['Non-serializable values were found in the navigation state'])

const App = ({ invitationState, setInvitationState, setAppState, rootStore }) => {
  const [isAppReady, setIsAppReady] = useState(false)

  const currentState = useRef(AppState.currentState)

  useMount(() => {
    trackEvent('AivyStarted')

    const startApp = async () => {
      await checkLogin()
      await asyncStorage.storeData('started', true)
    }

    startApp()
  })

  useMount(() => {
    const changeEventListener = async (nextAppState) => {
      if (currentState.current.match(/inactive|background/) && nextAppState === 'active') {
        try {
          await initializeApp(rootStore.userStore.initialLink, setInvitationState, rootStore)
        } catch (err) {
          setAppState('ERROR')
          notifyBugsnag(
            new Error(`[reinitializeProfileData] ${err.message || JSON.stringify(err)}`)
          )
        }
      }

      currentState.current = nextAppState
    }

    const el = AppState.addEventListener('change', changeEventListener)

    return () => {
      el.remove()
    }
  })

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isSignup: false,
            isLoading: false
          }
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            isSignup: action.isSignup,
            isLoading: false
          }
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            isSignup: false,
            isLoading: false
          }
        default:
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            isSignup: false,
            isLoading: false
          }
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null
    }
  )

  async function checkLogin(isSignup) {
    let cognitoUser

    try {
      cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: false })
    } catch (err) {
      if (err === 'The user is not authenticated') {
        dispatch({ type: 'SIGN_OUT' })
        setIsAppReady(true)
      } else {
        setAppState('ERROR')
        notifyBugsnag(
          new Error(`[Auth.currentAuthenticatedUser] ${err.message || JSON.stringify(err)}`)
        )
      }

      return
    }

    rootStore.userStore.cognitoUser = cognitoUser
    rootStore.userStore.username = cognitoUser.signInUserSession?.accessToken?.payload?.username
    asyncStorage.storeData('user-id', cognitoUser.signInUserSession?.accessToken?.payload?.username)

    try {
      await initializeApp(rootStore.userStore.initialLink, setInvitationState, rootStore)
      dispatch({
        type: 'SIGN_IN',
        token: cognitoUser.signInUserSession?.accessToken?.payload?.username,
        isSignup
      })
      setIsAppReady(true)
    } catch (err) {
      setAppState('ERROR')
      notifyBugsnag(new Error(`[initializeApp] ${err.message || JSON.stringify(err)}`))
    }
  }

  useEffect(() => {
    if (isAppReady) {
      setAppState('DONE')
    }
  }, [isAppReady, setAppState])

  const authContext = useMemo(
    () => ({
      rootStore,
      checkLogin,
      setAppState,
      signIn: async () => {
        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        // In the example, we'll use a dummy token
        // Please only use dispatch here. Do SignIn in Login Page for example
        dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' })
      },
      signOut: () => dispatch({ type: 'SIGN_OUT' }),
      signUp: async () => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // In the example, we'll use a dummy token
        // Please only use dispatch here. Do SignOut in Logout Page for example
        dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' })
      }
    }),
    [] // eslint-disable-line
  )

  const notificationContext = useMemo(
    () => ({
      success: (message) => getNotificationHolder().alertWithType('success', message),
      notice: (message) => getNotificationHolder().alertWithType('notice', message),
      error: (message) => getNotificationHolder().alertWithType('error', message)
    }),
    []
  )

  if (state.isLoading) return <ContentLoader />

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <AuthContext.Provider value={authContext}>
        <NotificationContext.Provider value={notificationContext}>
          {invitationState === invitationStates.INITIAL && (
            <Navigation isLoggedIn={!!state.userToken} isSignup={!!state.isSignup} />
          )}
          {invitationState === invitationStates.EXPIRED && <InvitationExpired />}
          {invitationState === invitationStates.USED && (
            <InvitationUsed onPress={() => setInvitationState(invitationStates.INITIAL)} />
          )}
          {invitationState === invitationStates.DELETED && <InvitationDeleted />}
        </NotificationContext.Provider>
      </AuthContext.Provider>
    </GestureHandlerRootView>
  )
}

App.propTypes = {
  invitationState: PropTypes.string.isRequired,
  setInvitationState: PropTypes.func.isRequired,
  setAppState: PropTypes.func.isRequired,
  rootStore: PropTypes.object.isRequired
}

export default App
