import React, { useEffect, useRef } from 'react'
import { AppState } from 'react-native'
import * as Linking from 'expo-linking'
import PropTypes from 'prop-types'

import Offline from '../components/Offline'
import { useAmplify } from '../hooks/use-amplify'
// import { notifyBugsnag } from '../utils/bugsnag'
import asyncStorage from '../utils/async-storage'
import { ContentLoader } from '../components'
import { trackEvent } from '../utils/tracking'
import { useMount } from '../hooks/use-mount'

const ConnectionWrapper = ({ appState, setAppState, children }) => {
  const [amplifyReady, setAmplifyReady] = useAmplify('INITIAL')
  const currentState = useRef(AppState.currentState)

  useEffect(() => {
    const setAppStateError = async () => {
      const url = await Linking.getInitialURL()

      await asyncStorage.storeData('initUrlBeforeUpdate', url)
      setAppState('ERROR')
    }

    if (amplifyReady === 'READY_AGAIN') {
      setAppState('APP_STATE_ACTIVE')
    }

    if (amplifyReady === 'ERROR') {
      setAppStateError()
    }
  }, [amplifyReady, setAppState])

  useMount(() => {
    const changeEventListener = async (nextAppState) => {
      if (currentState.current.match(/inactive|background/) && nextAppState === 'active') {
        setAmplifyReady('APP_STATE_ACTIVE')
        trackEvent('AppState', { type: 'GoForeground' })
      } else {
        setAppState('APP_STATE_INACTIVE')
        trackEvent('AppState', { type: 'GoBackground' })
      }

      currentState.current = nextAppState
      console.log('AppState', currentState.current) // eslint-disable-line no-console
    }

    const el = AppState.addEventListener('change', changeEventListener)

    return () => {
      el.remove()
    }
  })

  if (amplifyReady === 'INITIAL') return <ContentLoader />

  if (appState === 'ERROR') {
    return <Offline />
  }

  return children
}

ConnectionWrapper.propTypes = {
  appState: PropTypes.string.isRequired,
  setAppState: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default ConnectionWrapper
