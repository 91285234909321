export const stoneBackgroundColors = [
  {
    normal: '#5786FF'
  },
  {
    normal: '#FFB628'
  },
  {
    normal: '#FE79A7'
  },
  {
    normal: '#FFB628'
  }
]

export const getSourceForStone = (stone) => {
  switch (stone) {
    case 0:
      return require('../../../../assets/challenges/tower-of-london/game-assets/blue-big-stone.png')
    case 2:
      return require('../../../../assets/challenges/tower-of-london/game-assets/pink-big-stone.png')
    case 3:
      return require('../../../../assets/challenges/tower-of-london/game-assets/yellow-big-stone.png')
    default:
      break
  }
}

export const ANIMATION_DURATION = 125

export const STONE_DIMENSIONS = {
  height: 40,
  width: 90
}

export const PEGS_WIDTH = 18

const b = 0
const r = 2
const y = 3

export const towerStates = {
  11: {
    0: [r, y, b], // long tower
    1: [], // middle tower
    2: [] // short tower
  },
  12: {
    0: [r, y],
    1: [],
    2: [b]
  },
  13: {
    0: [r, y],
    1: [b],
    2: []
  },
  15: {
    0: [r],
    1: [b],
    2: [y]
  },
  16: {
    0: [],
    1: [b, r],
    2: [y]
  },
  23: {
    0: [r, b],
    1: [y],
    2: []
  },
  24: {
    0: [r],
    1: [y, b],
    2: []
  },
  25: {
    0: [r],
    1: [y],
    2: [b]
  },
  32: {
    0: [b, r],
    1: [],
    2: [y]
  },
  33: {
    0: [b, r],
    1: [y],
    2: []
  },
  34: {
    0: [b],
    1: [y, r],
    2: []
  },
  36: {
    0: [],
    1: [y, b],
    2: [r]
  },
  41: {
    0: [b, y, r],
    1: [],
    2: []
  },
  42: {
    0: [b, y],
    1: [],
    2: [r]
  },
  43: {
    0: [b, y],
    1: [r],
    2: []
  },
  46: {
    0: [],
    1: [r, b],
    2: [y]
  },
  51: {
    0: [y, b, r],
    1: [],
    2: []
  },
  52: {
    0: [y, b],
    1: [r],
    2: []
  },
  53: {
    0: [y, b],
    1: [r],
    2: []
  },
  54: {
    0: [y],
    1: [r, b],
    2: []
  },
  55: {
    0: [y],
    1: [r],
    2: [b]
  },
  61: {
    0: [y, r, b],
    1: [],
    2: []
  },
  63: {
    0: [y, r],
    1: [b],
    2: []
  },
  64: {
    0: [y],
    1: [b, r],
    2: []
  },
  65: {
    0: [y],
    1: [b],
    2: [r]
  }
}

export const TIME_TO_COMPLETE = 60

export const TEST_LEVELS = [
  {
    name: 'UL01',
    min_required_draws: 2,
    outcome: towerStates['65'],
    towers: towerStates['51']
  },
  {
    name: 'UL02',
    min_required_draws: 3,
    outcome: towerStates['33'],
    towers: towerStates['25']
  }
]

export const LEVELS = [
  {
    name: 'L003',
    min_required_draws: 4,
    outcome: towerStates['32'],
    towers: towerStates['25']
  },
  {
    name: 'L012',
    min_required_draws: 5,
    outcome: towerStates['34'],
    towers: towerStates['54']
  },
  {
    name: 'L016',
    min_required_draws: 6,
    outcome: towerStates['32'],
    towers: towerStates['13']
  },
  {
    name: 'L001',
    min_required_draws: 4,
    outcome: towerStates['13'],
    towers: towerStates['34']
  },
  {
    name: 'L010',
    min_required_draws: 5,
    outcome: towerStates['11'],
    towers: towerStates['33']
  },
  {
    name: 'L000',
    min_required_draws: 4,
    outcome: towerStates['65'],
    towers: towerStates['12']
  },
  {
    id: 1,
    name: 'L008',
    min_required_draws: 5,
    outcome: towerStates['43'],
    towers: towerStates['23']
  },
  {
    id: 1,
    name: 'L017',
    min_required_draws: 6,
    outcome: towerStates['53'],
    towers: towerStates['34']
  },
  {
    id: 1,
    name: 'L018',
    min_required_draws: 6,
    outcome: towerStates['61'],
    towers: towerStates['23']
  }
]
