import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { View } from 'react-native'
import { Picker } from '@react-native-picker/picker'

import asyncStorage from '../utils/async-storage'
import { trackEvent } from '../utils/tracking'
import { replaceUrlParam } from '../utils/helpers'

const SwitchLang = () => {
  const [language, setLanguage] = useState()

  useEffect(() => {
    const getLanguage = async () => {
      setLanguage((await asyncStorage.getData('lang')) || 'de')
    }

    getLanguage()
  }, [])

  const updateLanguage = async (lang) => {
    Auth.configure({ clientMetadata: { language: lang } })
    await asyncStorage.storeData('lang', lang)
    trackEvent('LanguageChanged', { language: lang })

    const currentUrl = window?.location.href
    const replacedUrl = replaceUrlParam(currentUrl, 'language', lang)
    window && window.location.replace(replacedUrl)
  }

  if (!language) return null

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {/* <Image
        source={require('../assets/images/language-icon.png')}
        style={{ height: 18, width: 18, resizeMode: 'contain', marginRight: 6 }}
      />
      <Typo.T2Black>{`${I18n.get('global.switch_language')}: `}</Typo.T2Black> */}
      <Picker
        style={{ fontFamily: 'fira-sans-regular', fontSize: 14 }}
        selectedValue={language}
        onValueChange={(value) => updateLanguage(value)}
      >
        <Picker.Item fontFamily='fira-sans-regular' label='DE (Deutsch)' value='de' />
        <Picker.Item fontFamily='fira-sans-regular' label='EN (English)' value='en' />
      </Picker>
    </View>
  )
}

export default SwitchLang
