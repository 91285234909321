import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import PropTypes from 'prop-types'

import metrics from '../../../../theme/metrics'

const styles = StyleSheet.create({
  container: {
    width: metrics.widthPercentageToDP(74),
    height: metrics.widthPercentageToDP(41),
    backgroundColor: '#fff',
    borderRadius: 10,
    shadowColor: '#252D42',
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 24,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const SymbolContainer = ({ imageHeight, imageWidth, imageSource }) => (
  <View style={styles.container}>
    <Image
      source={imageSource}
      style={{
        height: imageHeight,
        width: imageWidth,
        resizeMode: 'contain'
      }}
    />
  </View>
)

SymbolContainer.propTypes = {
  imageHeight: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  imageSource: PropTypes.any.isRequired
}

export default SymbolContainer
