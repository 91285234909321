/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
  Platform
} from 'react-native'
import * as Clipboard from 'expo-clipboard'
import PropTypes from 'prop-types'
import * as Linking from 'expo-linking'

import { observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import PerspectiveListItem from '../../components/PerspectiveListItem'
import Typo from '../../components/Typo'
import Loader from '../../components/Loader'
import metrics from '../../theme/metrics'
import colors from '../../theme/colors'
import Partner from '../../components/main/Partner'
import { AuthContext, NotificationContext } from '../../context'
import { replaceTranslationKeys } from '../../utils/helpers'
import { notifyBugsnag } from '../../utils/bugsnag'
import { query } from '../../graphql'
import { rowCenterBetween } from '../../styles'
import { trackEvent } from '../../utils/tracking'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightPaleGrey
  },
  copyAivyId: {
    height: 48,
    width: '100%',
    backgroundColor: '#fff',
    marginTop: 56,
    borderRadius: 8,
    borderColor: colors.brightBlue,
    borderWidth: 1,
    paddingHorizontal: 16,
    ...rowCenterBetween
  }
})

const ListHeaderComponent = ({
  navigation,
  imageUri,
  partnerName,
  perspectivesLength,
  applyWithID,
  onPressCopyAivyId,
  withDescription
}) => (
  <View style={{ marginBottom: 62, paddingTop: 32 }}>
    <View style={{ alignItems: 'flex-end' }}>
      {/* <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image
          source={require('../../assets/images/info-icon-grey.png')}
          style={{ height: 20, width: 20 }}
        />
        <Typo.T1Black style={{ marginLeft: 8 }} translate>
          perspectives.how_it_works
        </Typo.T1Black>
      </TouchableOpacity> */}
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Image
          source={require('../../assets/images/x-icon-grey.png')}
          style={{ height: 32, width: 32 }}
        />
      </TouchableOpacity>
    </View>
    <View style={{ marginTop: 32, marginBottom: -32 }}>
      <Partner name={partnerName} logo={{ uri: imageUri }} />
      <Typo.H1Black style={{ marginTop: 12 }}>
        {replaceTranslationKeys(
          I18n.get('perspectives.title'),
          ['{{perspectiveLength}}', '{{partnerName}}'],
          [perspectivesLength === 0 ? '...' : perspectivesLength, partnerName]
        )}
      </Typo.H1Black>
      {withDescription && (
        <Typo.T1Black marginTop={8} translate>
          perspectivelist.continue_description
        </Typo.T1Black>
      )}
      <Image
        source={require('../../assets/images/illustrations/skyscraper-detail.png')}
        style={{
          ...metrics.calculateResponsiveDimensions({ height: 150, width: 200 }),
          position: 'absolute',
          zIndex: -1,
          top: 8,
          right: -32
        }}
      />
    </View>
    {applyWithID && (
      <TouchableOpacity style={styles.copyAivyId} onPress={onPressCopyAivyId}>
        <Typo.T1Black translate>global.youraivyid</Typo.T1Black>
        <Typo.ButtonBlue translate>global.copynow</Typo.ButtonBlue>
      </TouchableOpacity>
    )}
  </View>
)

ListHeaderComponent.propTypes = {
  navigation: PropTypes.object.isRequired,
  imageUri: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
  perspectivesLength: PropTypes.number.isRequired,
  applyWithID: PropTypes.bool.isRequired,
  onPressCopyAivyId: PropTypes.func.isRequired,
  withDescription: PropTypes.bool.isRequired
}

const Perspectives = ({ navigation }) => {
  const { rootStore } = useContext(AuthContext)
  const { success } = useContext(NotificationContext)
  const [data, setData] = useState([])
  console.log('data', data)

  const { isAnonym } = rootStore.userStore
  const { applyWithID } = rootStore.appSettingsStore.components
  const { progress } = rootStore.challengesStore

  useEffect(() => {
    const { usedAlgorithm, partner_id } = rootStore.appSettingsStore
    async function loadMatchMeIfYouCanAsync() {
      try {
        const answer = await query({
          query: 'matchMeIfYouCan',
          variables: {
            partner_id: rootStore.appSettingsStore.partner_id,
            user_id: rootStore.userStore.username
          }
        })

        console.log('answer matchMeIfYouCan', answer)

        const res = JSON.parse(answer.data.matchMeIfYouCan)
        console.log('res', res)

        let hasFieldAvailableAttract = false
        res.forEach((a) => {
          if (a.is_available_in_attract !== null || a.is_available_in_attract !== undefined) {
            hasFieldAvailableAttract = true
          }
        })

        if (hasFieldAvailableAttract === true) {
          setData(res.filter((a) => a.is_available_in_attract === true))
        } else {
          setData(res)
        }
      } catch (err) {
        notifyBugsnag(err)
      }
    }
    async function loadRiasecAsync() {
      const riasecUserInput =
        rootStore.answersStore.getAnswerForChallenge('RIASEC_IMAGES')?.user_input
      try {
        const answer = await query({
          query: 'getMatchingCareers',
          variables: {
            riasec: JSON.stringify(riasecUserInput || {}),
            partner_id,
            getUnseen: true,
            minMatchingScore: 10
          }
        })

        const res = JSON.parse(answer.data.getMatchingCareers)
        setData(res.map((o) => ({ ...o, title: o.name, matching_score: o.matchingScore })))
      } catch (err) {
        notifyBugsnag(err)
      }
    }
    // eslint-disable-next-line no-unused-expressions
    usedAlgorithm === 'riasec' ? loadRiasecAsync() : loadMatchMeIfYouCanAsync()
  }, [
    rootStore.appSettingsStore.partner_id,
    rootStore.userStore.username,
    rootStore.appSettingsStore,
    rootStore.answersStore
  ])

  const onPressPerspective = (url, careerName) => {
    trackEvent('PerspectivePressed', { url, careerName })
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS === 'web') {
          return window.open(url)
        }
        return Linking.openURL(url)
      }
      notifyBugsnag(new Error(`[PerspectivePressed] Don't know how to open URI: ${url}`))
    })
  }

  const onPressCopyAivyId = () => {
    if (isAnonym) {
      navigation.goBack()
      setTimeout(() => {
        navigation.navigate('SaveStrengthDNA')
      }, 200)
    } else {
      Clipboard.setString(rootStore.userStore.username)
      success(I18n.get('global.alert.success.aivyid'))
      navigation.goBack()
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        ListHeaderComponent={
          <ListHeaderComponent
            navigation={navigation}
            imageUri={rootStore.appSettingsStore.logo}
            partnerName={rootStore.appSettingsStore.partnerDisplayName}
            perspectivesLength={data.length}
            applyWithID={applyWithID}
            onPressCopyAivyId={onPressCopyAivyId}
            withDescription={progress < 100}
          />
        }
        ListEmptyComponent={<Loader />}
        contentContainerStyle={{ paddingHorizontal: 24 }}
        data={data.sort((a, b) => b.matching_score - a.matching_score)}
        renderItem={({ item }) => (
          <PerspectiveListItem
            containerStyle={{ marginBottom: 16 }}
            title={item.title}
            matchingScore={item.matching_score}
            externalLink={item.external_link}
            onPress={() => onPressPerspective(item.external_link, item.title)}
            photoUrl={item.photo_url}
          />
        )}
        keyExtractor={(item) => item.title}
      />
    </SafeAreaView>
  )
}

Perspectives.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default observer(Perspectives)
