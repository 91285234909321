import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
  useContext
} from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'

import { Typo } from '..'
import { AuthContext } from '../../context'

const styles = StyleSheet.create({
  timeCount: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  timeTxt: {
    color: 'white',
    marginVertical: 2,
    backgroundColor: 'transparent'
  },
  timeInnerCont: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  doubleDigitCont: {
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const CountDownTimer = forwardRef(
  (
    {
      style,
      until,
      onFinish,
      digitTxtColor,
      size,
      timeTxtColor,
      timeToShow,
      labelD,
      labelH,
      labelM,
      labelS,
      onPress
    },
    ref
  ) => {
    const { rootStore } = useContext(AuthContext)
    const [seconds, setSeconds] = useState(until)
    const [pauseIsActive, setPauseIsActive] = useState(rootStore.pauseStore.active)
    const timer = useRef()

    const startTimer = () => {
      timer.current = setInterval(() => setSeconds((previous) => previous - 1), 1000)
    }

    const stopTimer = () => {
      timer.current = clearInterval(timer.current) // timer.current = undefined
    }

    useEffect(() => {
      pauseIsActive && stopTimer()
    }, [pauseIsActive]) // eslint-disable-line

    useEffect(() => {
      !pauseIsActive && startTimer()
      return stopTimer
    }, [pauseIsActive]) // eslint-disable-line

    useEffect(() => {
      seconds <= 0 && stopTimer()
      seconds <= 0 && onFinish && onFinish()
    }, [seconds]) // eslint-disable-line

    useEffect(() => {
      setPauseIsActive(rootStore.pauseStore.active)
    }, [rootStore.pauseStore.active])

    useImperativeHandle(ref, () => ({
      pauseTimer: () => {
        setPauseIsActive(true)
      },

      resumeTimer: () => {
        setPauseIsActive(false)
      },

      resetTimer: (sec) => {
        stopTimer()
        setSeconds(sec)
        startTimer()
      },

      stopTimer: () => stopTimer()
    }))

    const getTimeLeft = () => ({
      sec: seconds <= 0 ? 0 : seconds % 60,
      minutes: parseInt(seconds / 60, 10) % 60,
      hours: parseInt(seconds / (60 * 60), 10) % 24,
      days: parseInt(seconds / (60 * 60 * 24), 10)
    })

    const renderDigit = (digits, needsDoublePoints) => (
      <Typo.ButtonBlue style={[styles.digitTxt, { fontSize: size }, { color: digitTxtColor }]}>
        {digits + (needsDoublePoints ? ':' : '')}
      </Typo.ButtonBlue>
    )

    const renderDoubleDigits = (label, digits, needsDoublePoints) => (
      <View style={styles.timeInnerCont}>
        <View style={styles.timeInnerCont}>{renderDigit(digits, needsDoublePoints)}</View>
        <Text style={[styles.timeTxt, { fontSize: size }, { color: timeTxtColor }]}>{label}</Text>
      </View>
    )

    const renderCountDown = () => {
      const { days, hours, minutes, sec } = getTimeLeft()
      const newTime = sprintf('%02d:%02d:%02d:%02d', days, hours, minutes, sec).split(':')
      const Component = onPress ? TouchableOpacity : View

      return (
        <Component style={styles.timeCount} onPress={onPress}>
          {_.includes(timeToShow, 'D') ? renderDoubleDigits(labelD, newTime[0], true) : null}
          {_.includes(timeToShow, 'H') ? renderDoubleDigits(labelH, newTime[1], true) : null}
          {_.includes(timeToShow, 'M') ? renderDoubleDigits(labelM, newTime[2], true) : null}
          {_.includes(timeToShow, 'S') ? renderDoubleDigits(labelS, newTime[3], false) : null}
        </Component>
      )
    }

    return <View style={style}>{renderCountDown()}</View>
  }
)

CountDownTimer.propTypes = {
  until: PropTypes.number.isRequired,
  digitTxtColor: PropTypes.string.isRequired,
  timeTxtColor: PropTypes.string,
  size: PropTypes.number,
  timeToShow: PropTypes.array.isRequired,
  onPress: PropTypes.func,
  labelD: PropTypes.string,
  labelH: PropTypes.string,
  labelM: PropTypes.string,
  labelS: PropTypes.string,
  style: PropTypes.object,
  onFinish: PropTypes.func
}

CountDownTimer.defaultProps = {
  timeTxtColor: undefined,
  size: 14,
  onPress: undefined,
  labelD: undefined,
  labelH: undefined,
  labelM: undefined,
  labelS: undefined,
  style: {},
  onFinish: undefined
}

export default CountDownTimer
