import _ from 'lodash'

const BIG_FIVE_DATA = [
  {
    id: 'PID023',
    medians: -3542.0,
    adjective: 'challenge.big_five.item_id_PID023',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID030',
    medians: -3484.5,
    adjective: 'challenge.big_five.item_id_PID030',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID032',
    medians: 3665.0,
    adjective: 'challenge.big_five.item_id_PID032',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID034',
    medians: -3488.0,
    adjective: 'challenge.big_five.item_id_PID034',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID036',
    medians: 3603.0,
    adjective: 'challenge.big_five.item_id_PID036',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID055',
    medians: -3249.0,
    adjective: 'challenge.big_five.item_id_PID055',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID058',
    medians: -3651.5,
    adjective: 'challenge.big_five.item_id_PID058',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID080',
    medians: 3774.0,
    adjective: 'challenge.big_five.item_id_PID080',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID087',
    medians: 3750.0,
    adjective: 'challenge.big_five.item_id_PID087',
    coding: 1,
    dimension: 'E'
  },
  {
    id: 'PID098',
    medians: 3815.0,
    adjective: 'challenge.big_five.item_id_PID098',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID110',
    medians: 3012.0,
    adjective: 'challenge.big_five.item_id_PID110',
    coding: 1,
    dimension: 'E'
  },
  {
    id: 'PID127',
    medians: -3155.0,
    adjective: 'challenge.big_five.item_id_PID127',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID148',
    medians: 3697.0,
    adjective: 'challenge.big_five.item_id_PID148',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID155',
    medians: 3896.0,
    adjective: 'challenge.big_five.item_id_PID155',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID163',
    medians: 3719.0,
    adjective: 'challenge.big_five.item_id_PID163',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID173',
    medians: 3841.0,
    adjective: 'challenge.big_five.item_id_PID173',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID177',
    medians: 3545.0,
    adjective: 'challenge.big_five.item_id_PID177',
    coding: 1,
    dimension: 'E'
  },
  {
    id: 'PID185',
    medians: 3554.5,
    adjective: 'challenge.big_five.item_id_PID185',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID196',
    medians: 3836.0,
    adjective: 'challenge.big_five.item_id_PID196',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID198',
    medians: 3696.5,
    adjective: 'challenge.big_five.item_id_PID198',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID201',
    medians: 3766.0,
    adjective: 'challenge.big_five.item_id_PID201',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID207',
    medians: -3349.0,
    adjective: 'challenge.big_five.item_id_PID207',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID226',
    medians: 2684.0,
    adjective: 'challenge.big_five.item_id_PID226',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID228',
    medians: 3427.0,
    adjective: 'challenge.big_five.item_id_PID228',
    coding: -1,
    dimension: 'E'
  },
  {
    id: 'PID229',
    medians: 3517.0,
    adjective: 'challenge.big_five.item_id_PID229',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID237',
    medians: 3611.0,
    adjective: 'challenge.big_five.item_id_PID237',
    coding: 1,
    dimension: 'E'
  },
  {
    id: 'PID243',
    medians: 3089.0,
    adjective: 'challenge.big_five.item_id_PID243',
    coding: 1,
    dimension: 'E'
  },
  {
    id: 'PID248',
    medians: 3718.0,
    adjective: 'challenge.big_five.item_id_PID248',
    coding: 1,
    dimension: 'E'
  },
  {
    id: 'PID266',
    medians: 3922.0,
    adjective: 'challenge.big_five.item_id_PID266',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID272',
    medians: -3545.0,
    adjective: 'challenge.big_five.item_id_PID272',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID274',
    medians: -2827.5,
    adjective: 'challenge.big_five.item_id_PID274',
    coding: -1,
    dimension: 'E'
  },
  {
    id: 'PID295',
    medians: 3483.0,
    adjective: 'challenge.big_five.item_id_PID295',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID297',
    medians: 3792.0,
    adjective: 'challenge.big_five.item_id_PID297',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID302',
    medians: 3784.0,
    adjective: 'challenge.big_five.item_id_PID302',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID303',
    medians: 3351.0,
    adjective: 'challenge.big_five.item_id_PID303',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID304',
    medians: 3795.0,
    adjective: 'challenge.big_five.item_id_PID304',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID310',
    medians: -2732.0,
    adjective: 'challenge.big_five.item_id_PID310',
    coding: -1,
    dimension: 'E'
  },
  {
    id: 'PID323',
    medians: 3842.0,
    adjective: 'challenge.big_five.item_id_PID323',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID331',
    medians: 3133.0,
    adjective: 'challenge.big_five.item_id_PID331',
    coding: -1,
    dimension: 'E'
  },
  {
    id: 'PID336',
    medians: 3371.5,
    adjective: 'challenge.big_five.item_id_PID336',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID342',
    medians: 3834.5,
    adjective: 'challenge.big_five.item_id_PID342',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID345',
    medians: -3444.0,
    adjective: 'challenge.big_five.item_id_PID345',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID356',
    medians: 2434.0,
    adjective: 'challenge.big_five.item_id_PID356',
    coding: -1,
    dimension: 'E'
  },
  {
    id: 'PID360',
    medians: 3746.5,
    adjective: 'challenge.big_five.item_id_PID360',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID386',
    medians: 3794.0,
    adjective: 'challenge.big_five.item_id_PID386',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID389',
    medians: 3548.5,
    adjective: 'challenge.big_five.item_id_PID389',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID402',
    medians: -3097.5,
    adjective: 'challenge.big_five.item_id_PID402',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID420',
    medians: 1068.0,
    adjective: 'challenge.big_five.item_id_PID420',
    coding: -1,
    dimension: 'E'
  },
  {
    id: 'PID427',
    medians: 3579.5,
    adjective: 'challenge.big_five.item_id_PID427',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID433',
    medians: 3560.0,
    adjective: 'challenge.big_five.item_id_PID433',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID435',
    medians: 3719.0,
    adjective: 'challenge.big_five.item_id_PID435',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID445',
    medians: 3743.0,
    adjective: 'challenge.big_five.item_id_PID445',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID451',
    medians: 3504.0,
    adjective: 'challenge.big_five.item_id_PID451',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID456',
    medians: -3226.5,
    adjective: 'challenge.big_five.item_id_PID456',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID458',
    medians: -3391.0,
    adjective: 'challenge.big_five.item_id_PID458',
    coding: 1,
    dimension: 'N'
  },
  {
    id: 'PID461',
    medians: 3448.0,
    adjective: 'challenge.big_five.item_id_PID461',
    coding: 1,
    dimension: 'O'
  },
  {
    id: 'PID466',
    medians: 3647.5,
    adjective: 'challenge.big_five.item_id_PID466',
    coding: 1,
    dimension: 'A'
  },
  {
    id: 'PID468',
    medians: 3712.0,
    adjective: 'challenge.big_five.item_id_PID468',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID470',
    medians: 3732.5,
    adjective: 'challenge.big_five.item_id_PID470',
    coding: 1,
    dimension: 'C'
  },
  {
    id: 'PID474',
    medians: 3592.0,
    adjective: 'challenge.big_five.item_id_PID474',
    coding: 1,
    dimension: 'C'
  }
]

export const ANSWER_OPTIONS = [
  { label: 'challenge.big_five.answer_options.less', value: -1 },
  { label: 'challenge.big_five.answer_options.more', value: 1 }
]

export const BLOCK_TIME_MS = 300

export const TIME_TO_ANSWER = 4000

const CATEGORIES = ['O', 'C', 'E', 'A', 'N']

const TEST_ITEMS = [
  { adjective: 'challenge.big_five.item_id_1', id: 1 },
  { adjective: 'challenge.big_five.item_id_2', id: 2 },
  { adjective: 'challenge.big_five.item_id_3', id: 3 },
  { adjective: 'challenge.big_five.item_id_4', id: 4 },
  { adjective: 'challenge.big_five.item_id_PID008', id: 5 }
]

export const TEST_ITEMS_COUNT = TEST_ITEMS.length

export const getRandomItems = () => {
  const testItems = TEST_ITEMS
  const mainItems = _.shuffle(
    _.flatten(
      CATEGORIES.map((category) =>
        _.shuffle(
          BIG_FIVE_DATA.filter(
            (item) => item.category === category && !testItems.map((o) => o.id).includes(item.id)
          )
        ).slice(0, 14)
      )
    )
  )

  return testItems.concat(mainItems)
}

export const getRandomTestQuestions = () => _.shuffle(TEST_ITEMS)

export const getRandomMainQuestions = () => {
  const O = _.filter(BIG_FIVE_DATA, { dimension: 'O' })
  const C = _.filter(BIG_FIVE_DATA, { dimension: 'C' })
  const E = _.filter(BIG_FIVE_DATA, { dimension: 'E' })
  const A = _.filter(BIG_FIVE_DATA, { dimension: 'A' })
  const N = _.filter(BIG_FIVE_DATA, { dimension: 'N' })

  const batch_one = _.concat(
    _.sampleSize(O, 4),
    _.sampleSize(C, 4),
    _.sampleSize(E, 4),
    _.sampleSize(A, 4),
    _.sampleSize(N, 4)
  )
  const batch_two = _.concat(
    _.sampleSize(_.difference(O, batch_one), 4),
    _.sampleSize(_.difference(C, batch_one), 4),
    _.sampleSize(_.difference(E, batch_one), 4),
    _.sampleSize(_.difference(A, batch_one), 4),
    _.sampleSize(_.difference(N, batch_one), 4)
  )
  const batch_three = _.concat(
    _.sampleSize(_.difference(O, _.concat(batch_one, batch_two)), 4),
    _.sampleSize(_.difference(C, _.concat(batch_one, batch_two)), 4),
    _.sampleSize(_.difference(E, _.concat(batch_one, batch_two)), 4),
    _.sampleSize(_.difference(A, _.concat(batch_one, batch_two)), 4),
    _.sampleSize(_.difference(N, _.concat(batch_one, batch_two)), 4)
  )

  return _.shuffle(_.concat(batch_one, batch_two, batch_three))
}

// TODO: EN TRANSLATION
export const INFO_TEXTS = {
  Offenheit: 'challenge.big_five.info_texts.openness',
  Gewissenhaftigkeit: 'challenge.big_five.info_texts.diligence',
  Extraversion: 'challenge.big_five.info_texts.extraversion',
  Verträglichkeit: 'challenge.big_five.info_texts.compatibility',
  Emotionalität: 'challenge.big_five.info_texts.emotionality',
  Emotionskontrolle: 'challenge.big_five.info_texts.emotionality',
  Risikobereitschaft: 'challenge.delegation.result.risktaking.description',
  Entscheidungsverhalten: 'challenge.delegation.result.responsibility.description'
}

/*

const createBatches = () => {
  let O
  let C
  let E
  let A
  let N
  O = _.filter(BIG_FIVE_DATA, { dimension: 'O' })
  C = _.filter(BIG_FIVE_DATA, { dimension: 'C' })
  E = _.filter(BIG_FIVE_DATA, { dimension: 'E' })
  A = _.filter(BIG_FIVE_DATA, { dimension: 'A' })
  N = _.filter(BIG_FIVE_DATA, { dimension: 'N' })

  const batch_one = _.concat(
    _.sampleSize(O, 4),
    _.sampleSize(C, 4),
    _.sampleSize(E, 4),
    _.sampleSize(A, 4),
    _.sampleSize(N, 4)
  )
  const batch_two = _.concat(
    _.sampleSize(_.difference(O, batch_one), 4),
    _.sampleSize(_.difference(C, batch_one), 4),
    _.sampleSize(_.difference(E, batch_one), 4),
    _.sampleSize(_.difference(A, batch_one), 4),
    _.sampleSize(_.difference(N, batch_one), 4)
  )
  const batch_three = _.concat(
    _.sampleSize(_.difference(O, _.concat(batch_one, batch_two)), 4),
    _.sampleSize(_.difference(C, _.concat(batch_one, batch_two)), 4),
    _.sampleSize(_.difference(E, _.concat(batch_one, batch_two)), 4),
    _.sampleSize(_.difference(A, _.concat(batch_one, batch_two)), 4),
    _.sampleSize(_.difference(N, _.concat(batch_one, batch_two)), 4)
  )
  return [batch_one, batch_two, batch_three]
}

const BIG_FIVE_DARK_TRIAD_ITEMS = [
  {
    id: 'PID024',
    medians: -3476.5,
    adjective: 'challenge.big_five. PID024',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID037',
    medians: -3301,
    adjective: 'challenge.big_five. PID037',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID159',
    medians: -3653,
    adjective: 'challenge.big_five. PID159',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID162',
    medians: -3148,
    adjective: 'challenge.big_five. PID162',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID211',
    medians: -3542,
    adjective: 'challenge.big_five. PID211',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID231',
    medians: -3535,
    adjective: 'challenge.big_five. PID231',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID261',
    medians: -3133,
    adjective: 'challenge.big_five. PID261',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID298',
    medians: -3410,
    adjective: 'challenge.big_five. PID298',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID318',
    medians: -3530,
    adjective: 'challenge.big_five. PID318',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID364',
    medians: -2520,
    adjective: 'challenge.big_five. PID364',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID408',
    medians: -3332,
    adjective: 'challenge.big_five. PID408',
    coding: 1,
    dimension: 'D3'
  },
  {
    id: 'PID232',
    medians: -3472,
    adjective: 'challenge.big_five. PID232',
    coding: 1,
    dimension: 'D3'
  }
]

*/
