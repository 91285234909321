import PropTypes from 'prop-types'
import React from 'react'
import { View, StyleSheet, StatusBar, Image } from 'react-native'
import metrics from '../theme/metrics'
import Typo from './Typo'

const s = StyleSheet.create({
  container: {
    flex: 1
    // backgroundColor: colors.brightBlue
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1 // pushes the footer to the end of the screen
  },
  footer: {
    paddingHorizontal: 24,
    paddingBottom: 24,
    paddingTop: 24
  },
  subHeaderTextStyle: {
    fontSize: 16,
    color: 'black',
    lineHeight: 22
  }
})

const QuestionModal = ({ buttons, title, text, additionalText, imageSource }) => (
  <View style={s.container}>
    <StatusBar barStyle='light-content' />
    <View style={s.contentContainer}>
      <Typo.H1Black marginBottom={8} marginTop={40} style={s.headerTextStyle} translate>
        {title}
      </Typo.H1Black>
      {imageSource && (
        <Image
          source={imageSource}
          resizeMode='contain'
          style={{
            width: metrics.widthPercentageToDP(45),
            height: metrics.widthPercentageToDP(45),
            alignSelf: 'center',
            margin: 40
          }}
        />
      )}
      <View style={{ paddingHorizontal: 24 }}>
        <Typo.T2Black center style={s.subHeaderTextStyle} translate>
          {text}
        </Typo.T2Black>
        {additionalText && (
          <Typo.T2LightBlack center translate style={{ marginTop: 16, lineHeight: 16 }}>
            {additionalText}
          </Typo.T2LightBlack>
        )}
      </View>
    </View>

    <View style={s.footer}>{buttons}</View>
  </View>
)

QuestionModal.propTypes = {
  buttons: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  additionalText: PropTypes.string,
  imageSource: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

QuestionModal.defaultProps = {
  imageSource: undefined,
  additionalText: undefined
}

export default QuestionModal
