import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import PropTypes from 'prop-types'

import { Button, Typo } from '../../components'
import colors from '../../theme/colors'
import { openUrl } from '../../utils/helpers'
import { useMount } from '../../hooks/use-mount'

const styles = StyleSheet.create({
  container: {
    flex: 1,

    backgroundColor: colors.lightPaleGrey,
    paddingVertical: 32,
    paddingHorizontal: 16
  }
})

const Redirect = ({ navigation, route }) => {
  const insets = useSafeAreaInsets()
  const { redirect_url } = route.params

  useMount(() => {
    setTimeout(() => {
      openUrl(redirect_url)
    }, 3000)
  })

  return (
    <View style={[styles.container, { paddingTop: 32 + insets.top }]}>
      <Typo.H1Black translate>redirect.title</Typo.H1Black>
      <Typo.T2Black marginTop={4} translate>
        redirect.subtitle
      </Typo.T2Black>
      <View style={{ paddingVertical: 32, flex: 1, justifyContent: 'center' }}>
        <Typo.NumberBlue center>. . .</Typo.NumberBlue>
      </View>
      <View style={{ paddingBottom: 32 + insets.bottom, paddingTop: 32 }}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={{ marginVertical: 16 }}>
          <Typo.ButtonBlue center translate>
            redirect.back_to_aivy.button.title
          </Typo.ButtonBlue>
        </TouchableOpacity>
        <Button
          containerStyle={{
            alignSelf: 'center'
          }}
          title='redirect.button.go'
          onPress={() => openUrl(redirect_url)}
        />
        <Typo.SmallTextBlack translate center marginTop={16}>
          redirect.hint
        </Typo.SmallTextBlack>
      </View>
    </View>
  )
}

Redirect.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
}

export default Redirect
