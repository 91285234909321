import React, { useEffect, useState, useRef, useContext } from 'react'
import { View, StyleSheet, ActivityIndicator, StatusBar } from 'react-native'
import { useAssets } from 'expo-asset'
import { isEmpty, sample } from 'lodash'
import PropTypes from 'prop-types'

import colors from '../../../theme/colors'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import Instruction from '../../../components/challenge/Instruction'
import Intro from '../../../components/challenge/Intro'
import ChallengeContainer from '../ChallengeContainer'
import { ALL_CHALLENGES } from '../../../constants/challenges'

import Question from './components/Question'
import {
  START_SEQUENCE_LENGTH,
  ROUND_LENGTH,
  getBackgroundColor,
  TEST_ROUND_LENGTH,
  LEVEL_DATA
} from './data'
import { AuthContext } from '../../../context'

const s = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey
  },
  contentContainer: {
    flex: 1 // pushes the footer to the end of the screen
  },
  footer: {
    paddingBottom: 24,
    paddingTop: 24
  },
  button: {
    backgroundColor: colors.darkGrey,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const DigitSpan = ({ testRound, submit }) => {
  const { rootStore } = useContext(AuthContext)

  const shortTermMemoryCapacity = useRef(START_SEQUENCE_LENGTH)
  const falseAgain = useRef(false)
  const isMounted = useRef(true)

  const [assets] = useAssets([
    require('../../../assets/challenges/digit-span/game-assets/tree.png'),
    require('../../../assets/challenges/digit-span/game-assets/apple.png'),
    require('../../../assets/challenges/digit-span/game-assets/bike.png'),
    require('../../../assets/challenges/digit-span/game-assets/tree.png'),
    require('../../../assets/challenges/digit-span/game-assets/cat.png'),
    require('../../../assets/challenges/digit-span/game-assets/snowflake.png'),
    require('../../../assets/challenges/digit-span/game-assets/lock-shape.png')
  ])
  const [round, setRound] = useState(0)
  const [numberSequence, setNumberSequence] = useState([])
  const [answers, setAnswers] = useState([])
  const [showQuestion, setShowQuestion] = useState(true)

  useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  const onSubmit = () => {
    submit(
      answers.reduce((result, answer, idx) => {
        result[`round_${idx + 1}`] = answer
        return result
      }, {})
    )
  }

  useEffect(() => {
    if ((testRound && answers.length === TEST_ROUND_LENGTH) || round === ROUND_LENGTH - 1) {
      // Test Round or Normal Round Completed
      shortTermMemoryCapacity.current = 3 // RESET STMC
      onSubmit()
    }
  }, [answers]) // eslint-disable-line

  useEffect(() => {
    const sequence = sample(LEVEL_DATA[shortTermMemoryCapacity.current])
    setNumberSequence(sequence)
  }, [round])

  const onQuestionComplete = (answer) => {
    const { decision, time_needed_in_ms } = answer
    const pause_in_ms = rootStore.pauseStore.sum

    setAnswers(
      answers.concat([
        {
          round,
          time_needed_in_ms,
          pause_in_ms,
          correct: decision,
          numberSequence: answer.numberSequence.toString(),
          shortTermMemoryCapacity: shortTermMemoryCapacity.current
        }
      ])
    )

    if (decision === false) {
      if (falseAgain.current === true) {
        if (shortTermMemoryCapacity.current === 3) {
          shortTermMemoryCapacity.current = 3 // minimum STMC of 3
        } else {
          shortTermMemoryCapacity.current -= 1 // reduce STMC
        }
        falseAgain.current = false // one mistake is no mistake
      } else {
        falseAgain.current = true // next time there is a penalty!
      }
    } else {
      shortTermMemoryCapacity.current += 1 // increase STMC
      falseAgain.current = false // one mistake is no mistake
    }

    rootStore.pauseStore.reset()

    if (round !== ROUND_LENGTH) {
      isMounted.current && setRound(round + 1)
      isMounted.current && setShowQuestion(false)
      isMounted.current && setShowQuestion(true)
    }
  }

  if (!assets) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator color={getBackgroundColor(numberSequence.length)} />
      </View>
    )
  }

  return (
    <View style={s.container}>
      <StatusBar barStyle='light-content' />
      {round < ROUND_LENGTH && showQuestion && !isEmpty(numberSequence) && (
        <Question
          backgroundColor={getBackgroundColor(numberSequence.length)}
          numberSequence={numberSequence}
          onQuestionComplete={onQuestionComplete}
          round={round}
          testRound={testRound}
        />
      )}
    </View>
  )
}

DigitSpan.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

IntroScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

InstructionScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

TestRoundCompleteScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const DigitSpanChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={DigitSpan}
    EXAM={ALL_CHALLENGES.DIGIT_SPAN}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: InstructionScreen, name: 'InstructionScreen' }
    ]}
    MIDDLE_SCREENS={[{ screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' }]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

DigitSpanChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

DigitSpanChallenge.defaultProps = { onChallengeComplete: () => {} }

export default DigitSpanChallenge
