import React, { useContext } from 'react'
import { View, StyleSheet, StatusBar, Image, TouchableOpacity, ScrollView } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import PropTypes from 'prop-types'

import { I18n } from 'aws-amplify'
import colors from '../../theme/colors'
import Typo from '../Typo'
import metrics from '../../theme/metrics'
import Button from '../Button'
import { AuthContext } from '../../context'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 20
  }
})

const Instruction = ({ onPress, exam }) => {
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const { instructionScreen } = exam

  const { rootStore } = useContext(AuthContext)
  const { language } = rootStore.userStore

  return (
    <>
      <StatusBar barStyle='dark-content' />
      <View style={[styles.container, { paddingTop: insets.top + 16 }]}>
        <View
          style={{
            marginTop: 8,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typo.H1Black translate>global.challenge.explanation.header</Typo.H1Black>
          <TouchableOpacity onPress={() => navigation.pop()}>
            <Image
              style={{ width: 24, height: 24, resizeMode: 'contain' }}
              source={require('../../assets/images/x-icon-black.png')}
            />
          </TouchableOpacity>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 32 }}
        >
          <View
            style={{
              marginTop: 32,
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Image
              style={{
                resizeMode: 'contain',
                ...metrics.calculateResponsiveDimensions(instructionScreen.imageDimension)
              }}
              source={{ uri: instructionScreen.image }}
            />
          </View>
          <View style={{ marginTop: 16 }}>
            <Typo.Markdown>{I18n.get(instructionScreen.instruction[language])}</Typo.Markdown>
          </View>
        </ScrollView>
      </View>
      <View style={{ paddingHorizontal: 20, paddingTop: 16, backgroundColor: colors.white }}>
        <Button
          title='global.got_it'
          onPress={onPress}
          containerStyle={{ marginTop: 'auto', marginBottom: insets.bottom + 32 }}
        />
      </View>
    </>
  )
}

Instruction.propTypes = {
  onPress: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

export default Instruction
