import React, { useState } from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'

import Typo from '../Typo'
import PersonalityModal from './PersonalityModal'
import UniversalModal from '../UniversalModal'

import colors from '../../theme/colors'

const styles = StyleSheet.create({
  progressTitleStyle: {
    fontSize: 16,
    color: colors.darkBlack
  },
  progressContainer: {
    height: 24,
    backgroundColor: colors.paleWhiteBlue,
    width: '50%',
    position: 'relative'
  },
  separator: {
    width: 1,
    height: 32,
    backgroundColor: colors.powderBlue
  }
})

const Progress = ({
  progressTitle,
  progressLeftTitle,
  progressRightTitle,
  value,
  max,
  containerStyle,
  hideMoreInfo,
  fillColor,
  progressTitleTextStyle
}) => {
  // eslint-disable-next-line no-unused-vars
  const [modalVisible, setModalVisible] = useState(false)

  const progressLeft = value < 0 ? (Math.abs(value) * 100) / max : 0
  const progressRight = value > 0 ? (value * 100) / max : 0

  const leftArrowSrc = require('../../assets/images/arrow-left-strength.png')
  const rightArrowSrc = require('../../assets/images/arrow-right-strength.png')

  return (
    <View style={containerStyle}>
      <TouchableOpacity onPress={() => setModalVisible(true)} disabled={hideMoreInfo}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Typo.T1Black style={[progressTitleTextStyle]}>{progressTitle}</Typo.T1Black>
          {!hideMoreInfo && (
            <Image
              source={require('../../assets/images/info.png')}
              style={{ marginLeft: 4, width: 18, height: 18 }}
            />
          )}
        </View>
      </TouchableOpacity>
      <View style={{ flexDirection: 'row', marginTop: 8, alignItems: 'center' }}>
        <View
          style={[
            styles.progressContainer,
            {
              borderTopLeftRadius: 2,
              borderBottomLeftRadius: 2,
              backgroundColor: progressLeft ? fillColor : colors.paleWhiteBlue
            }
          ]}
        >
          {progressLeft ? (
            <View
              style={{
                position: 'absolute',
                height: 24,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                overflow: 'hidden',
                opacity: 0.3
              }}
            >
              {Array.from(Array(10).keys()).map((item) => (
                <Image
                  key={item}
                  source={leftArrowSrc}
                  style={{ marginHorizontal: 8, height: 24, width: 15 }}
                />
              ))}
            </View>
          ) : null}
          <View
            style={{
              position: 'absolute',
              height: 24,
              width: progressLeft ? `${Math.abs(progressLeft - 100)}%` : 0,
              backgroundColor: colors.paleWhiteBlue
            }}
          />
        </View>
        <View style={styles.separator} />
        <View
          style={[
            styles.progressContainer,
            {
              borderTopRightRadius: 2,
              borderBottomRightRadius: 2
            }
          ]}
        >
          <View
            style={{
              position: 'absolute',
              height: 24,
              width: `${progressRight}%`,
              backgroundColor: fillColor
            }}
          >
            <View
              style={{
                position: 'absolute',
                height: 24,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                overflow: 'hidden',
                opacity: 0.3
              }}
            >
              {Array.from(Array(10).keys()).map((item) => (
                <Image
                  key={item}
                  source={rightArrowSrc}
                  style={{ marginHorizontal: 8, height: 24, width: 15 }}
                />
              ))}
            </View>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', marginTop: 4, justifyContent: 'space-between' }}>
        <Typo.SmallTextBlue>{progressLeftTitle}</Typo.SmallTextBlue>
        <Typo.SmallTextBlue>{progressRightTitle}</Typo.SmallTextBlue>
      </View>
      <UniversalModal animationType='slide' transparent visible={modalVisible}>
        <PersonalityModal trait={progressTitle} onPress={() => setModalVisible(false)} />
      </UniversalModal>
    </View>
  )
}

Progress.propTypes = {
  progressTitle: PropTypes.string,
  progressLeftTitle: PropTypes.string.isRequired,
  progressRightTitle: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  containerStyle: PropTypes.object,
  hideMoreInfo: PropTypes.bool,
  fillColor: PropTypes.string.isRequired,
  progressTitleTextStyle: PropTypes.object
}

Progress.defaultProps = {
  progressTitle: '',
  containerStyle: {},
  progressTitleTextStyle: {},
  hideMoreInfo: false
}

export default Progress
