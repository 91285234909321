import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { isUndefined } from 'lodash'
import { I18n } from 'aws-amplify'

import { AuthContext } from '../../context'
import Typo from '../Typo'
import Progress from './Progress'
import Loader from '../Loader'

import NormalDistributionCurve from '../challenge/result/components/NormalDistributionCurve'
import colors from '../../theme/colors'
import StrengthEmptySection from '../StrengthEmptySection'

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: '#B6C7F2',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    marginBottom: 16
  }
})

const customMDStyles = StyleSheet.create({
  text: {
    fontFamily: 'fira-sans-regular',
    fontSize: 14,
    lineHeight: 22,
    color: colors.darkBlack
  },
  strong: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'fira-sans-medium'
  }
})

const Personality = () => {
  const { rootStore } = useContext(AuthContext)
  const { challengeConfigs } = rootStore.appSettingsStore
  const [dataPersonality, setDataPersonality] = useState(null)

  const delegationAnswer = rootStore.answersStore.getAnswerForChallenge('DELEGATION')

  const DELEGATION_score_risk = delegationAnswer?.aivy_output?.translatedzScores?.score_risk

  const DELEGATION_score_responsibility =
    delegationAnswer?.aivy_output?.translatedzScores?.score_responsibility

  useEffect(() => {
    const answer = rootStore.answersStore.getAnswerForChallenge('BIG_FIVE')
    if (isUndefined(answer)) return

    const oScorePercent = answer.aivy_output.translatedzScores.o_score

    const cScorePercent = answer.aivy_output.translatedzScores.c_score

    const eScorePercent = answer.aivy_output.translatedzScores.e_score

    const aScorePercent = answer.aivy_output.translatedzScores.a_score

    const nScorePercent = answer.aivy_output.translatedzScores.n_score

    setDataPersonality([
      { max: 50, value: oScorePercent - 50, oScorePercent },
      { max: 50, value: cScorePercent - 50, cScorePercent },
      { max: 50, value: eScorePercent - 50, eScorePercent },
      { max: 50, value: aScorePercent - 50, aScorePercent },
      { max: 50, value: nScorePercent - 50, nScorePercent }
    ])
  }, [rootStore.answersStore, challengeConfigs])

  function generateResultData() {
    const bigFiveAnswer = rootStore.answersStore.getAnswerForChallenge('BIG_FIVE')
    if (!bigFiveAnswer) return {}
    const elevationProzent = bigFiveAnswer.aivy_output.translatedzScores.elevation

    return { elevationProzent }
  }
  const bigFiveAnswer = rootStore.answersStore.getAnswerForChallenge('BIG_FIVE')

  if (!bigFiveAnswer) {
    return <StrengthEmptySection />
  }

  if (!dataPersonality) {
    return <Loader />
  }

  return (
    <View style={styles.container}>
      <Typo.T1Black translate marginTop={24} marginBottom={24}>
        strength.personality.desc
      </Typo.T1Black>
      <View style={styles.card}>
        <Progress
          progressTitle={I18n.get('challenge.big_five.result.openness')}
          progressLeftTitle={I18n.get('challenge.big_five.result.openness.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.openness.right_adjective')}
          max={dataPersonality[0].max}
          value={dataPersonality[0].value}
          fillColor={colors.aquaMarine}
        />
        <View style={{ marginTop: 12 }}>
          {dataPersonality[0].oScorePercent <= 50 ? (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.o_score.desc')}
            </Typo.Markdown>
          ) : (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.o_score.desc.variant')}
            </Typo.Markdown>
          )}
        </View>
      </View>
      <View style={styles.card}>
        <Progress
          progressTitle={I18n.get('challenge.big_five.result.diligence')}
          progressLeftTitle={I18n.get('challenge.big_five.result.diligence.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.diligence.right_adjective')}
          containerStyle={{ marginTop: 16 }}
          max={dataPersonality[1].max}
          value={dataPersonality[1].value}
          fillColor={colors.mango}
        />
        <View style={{ marginTop: 12 }}>
          {dataPersonality[1].cScorePercent <= 50 ? (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.c_score.desc')}
            </Typo.Markdown>
          ) : (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.c_score.desc.variant')}
            </Typo.Markdown>
          )}
        </View>
      </View>
      <View style={styles.card}>
        <Progress
          progressTitle={I18n.get('challenge.big_five.result.extraversion')}
          progressLeftTitle={I18n.get('challenge.big_five.result.extraversion.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.extraversion.right_adjective')}
          containerStyle={{ marginTop: 16 }}
          max={dataPersonality[2].max}
          value={dataPersonality[2].value}
          fillColor={colors.carnationPink}
        />
        <View style={{ marginTop: 12 }}>
          {dataPersonality[2].eScorePercent <= 50 ? (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.e_score.desc')}
            </Typo.Markdown>
          ) : (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.e_score.desc.variant')}
            </Typo.Markdown>
          )}
        </View>
      </View>
      <View style={styles.card}>
        <Progress
          progressTitle={I18n.get('challenge.big_five.result.compatibility')}
          progressLeftTitle={I18n.get('challenge.big_five.result.compatibility.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.compatibility.right_adjective')}
          containerStyle={{ marginTop: 16 }}
          max={dataPersonality[3].max}
          value={dataPersonality[3].value}
          fillColor={colors.softBlue}
        />
        <View style={{ marginTop: 12 }}>
          {dataPersonality[3].aScorePercent <= 50 ? (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.a_score.desc')}
            </Typo.Markdown>
          ) : (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.a_score.desc.variant')}
            </Typo.Markdown>
          )}
        </View>
      </View>
      <View style={styles.card}>
        <Progress
          progressTitle={I18n.get('challenge.big_five.result.emotionality')}
          progressLeftTitle={I18n.get('challenge.big_five.result.emotionality.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.emotionality.right_adjective')}
          containerStyle={{ marginTop: 16 }}
          max={dataPersonality[4].max}
          value={dataPersonality[4].value}
          fillColor={colors.rockGrey}
        />
        <View style={{ marginTop: 12 }}>
          {dataPersonality[4].nScorePercent <= 50 ? (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.n_score.desc')}
            </Typo.Markdown>
          ) : (
            <Typo.Markdown style={customMDStyles}>
              {I18n.get('strength.n_score.desc.variant')}
            </Typo.Markdown>
          )}
        </View>
      </View>

      {DELEGATION_score_risk && (
        <View style={styles.card}>
          <Progress
            progressTitle={I18n.get(
              'challenge.delegation.result.risktaking.normal_distribution_curve.title'
            )}
            progressLeftTitle={I18n.get(
              'challenge.delegation.result.risktaking.normal_distribution_curve.leftskala'
            )}
            progressRightTitle={I18n.get(
              'challenge.delegation.result.risktaking.normal_distribution_curve.rightskala'
            )}
            containerStyle={{ marginTop: 16 }}
            max={50}
            value={DELEGATION_score_risk - 50}
            fillColor={colors.buttonRed}
          />
          <View style={{ marginTop: 12 }}>
            {DELEGATION_score_risk <= 50 ? (
              <Typo.Markdown style={customMDStyles}>
                {I18n.get('challenge.delegation.result.risktaking_mean_percent_1')}
              </Typo.Markdown>
            ) : (
              <Typo.Markdown style={customMDStyles}>
                {I18n.get('challenge.delegation.result.risktaking_mean_percent_3')}
              </Typo.Markdown>
            )}
          </View>
        </View>
      )}

      {DELEGATION_score_responsibility && (
        <View style={[styles.card, { marginBottom: 0 }]}>
          <Progress
            progressTitle={I18n.get(
              'challenge.delegation.result.responsibility.normal_distribution_curve.title'
            )}
            progressLeftTitle={I18n.get(
              'challenge.delegation.result.responsibility.normal_distribution_curve.leftskala'
            )}
            progressRightTitle={I18n.get(
              'challenge.delegation.result.responsibility.normal_distribution_curve.rightskala'
            )}
            containerStyle={{ marginTop: 16 }}
            max={50}
            value={DELEGATION_score_responsibility - 50}
            fillColor={colors.crusta}
          />
          <View style={{ marginTop: 12 }}>
            {DELEGATION_score_responsibility <= 50 ? (
              <Typo.Markdown style={customMDStyles}>
                {I18n.get('challenge.delegation.result.responsibility_mean_percent_1')}
              </Typo.Markdown>
            ) : (
              <Typo.Markdown style={customMDStyles}>
                {I18n.get('challenge.delegation.result.responsibility_mean_percent_3')}
              </Typo.Markdown>
            )}
          </View>
        </View>
      )}
      <NormalDistributionCurve
        containerStyle={{ marginTop: 16, marginBottom: 32 }}
        title={I18n.get('challenge.big_five.result.normal_distribution_curve.title')}
        percent={generateResultData().elevationProzent}
        description={`${
          generateResultData().elevationProzent <= 50
            ? I18n.get('challenge.big_five.result.elevation_prozent_1')
            : I18n.get('challenge.big_five.result.elevation_prozent_2')
        }`}
        labels={{
          left: I18n.get('challenge.big_five.result.scale_endpoint.left'),
          right: I18n.get('challenge.big_five.result.scale_endpoint.right')
        }}
      />
    </View>
  )
}

export default Personality
