import React, { useEffect, useRef, useState } from 'react'
import { Animated, Dimensions, Easing, Platform } from 'react-native'
import PropTypes from 'prop-types'

import metrics from '../../../../theme/metrics'
import LottieAnimation from '../../../../components/challenge/LottieAnimation' // eslint-disable-line

const Helicopter = ({ source, helicopterState, onAnimationFinished, index }) => {
  const animatedValue = useRef(new Animated.Value(0))
  const [translateX] = useState(
    animatedValue.current.interpolate({
      inputRange: [-1, 0, 1],
      outputRange: [-Dimensions.get('window').width, 0, Dimensions.get('window').width]
    })
  )

  useEffect(() => {
    if (helicopterState === 'correct') {
      Animated.timing(animatedValue.current, {
        toValue: source.direction === 0 ? -1 : 1,
        duration: 600,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== 'web'
      }).start(onAnimationFinished)
    }
  }, [helicopterState, onAnimationFinished, source])

  return (
    <Animated.View style={{ transform: [{ translateX }] }}>
      <LottieAnimation
        key={`${helicopterState}-${index}`}
        loop={helicopterState === 'default'}
        width={metrics.widthPercentageToDP(40) * 1.2}
        height={metrics.heightPercentageToDP(7) * 1.4}
        autoplay
        onAnimationFinish={helicopterState === 'wrong' ? onAnimationFinished : undefined}
        animationData={
          ['default', 'correct'].includes(helicopterState) ? source.default : source.wrong
        }
      />
    </Animated.View>
  )
}

Helicopter.propTypes = {
  source: PropTypes.object.isRequired,
  helicopterState: PropTypes.string.isRequired,
  onAnimationFinished: PropTypes.func,
  index: PropTypes.number.isRequired
}

Helicopter.defaultProps = {
  onAnimationFinished: undefined
}

export default Helicopter
