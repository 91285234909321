import React from 'react'
import { StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'
import { PEGS_WIDTH, STONE_DIMENSIONS } from '../data'
import metrics from '../../../../theme/metrics'

const pegHeight = metrics.calculateResponsiveDimensions({ height: 208, width: PEGS_WIDTH }).height

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center'
  },
  peg: {
    position: 'absolute',
    backgroundColor: colors.paleBlue
  }
})

const Pegs = ({ positions, pegTop }) => (
  <View style={styles.container}>
    <View
      style={{
        position: 'absolute',
        width: PEGS_WIDTH,
        backgroundColor: colors.paleBlue,
        height: STONE_DIMENSIONS.height * 3.5,
        left: positions[0] - PEGS_WIDTH / 2,
        top: pegTop + (pegHeight - STONE_DIMENSIONS.height * 3.5)
      }}
    />
    <View
      style={{
        position: 'absolute',
        width: PEGS_WIDTH,
        backgroundColor: colors.paleBlue,
        height: STONE_DIMENSIONS.height * 2.5,
        left: positions[1] - PEGS_WIDTH / 2,
        top: pegTop + (pegHeight - STONE_DIMENSIONS.height * 2.5)
      }}
    />
    <View
      style={{
        position: 'absolute',
        width: PEGS_WIDTH,
        backgroundColor: colors.paleBlue,
        height: STONE_DIMENSIONS.height * 1.5,
        left: positions[2] - PEGS_WIDTH / 2,
        top: pegTop + (pegHeight - STONE_DIMENSIONS.height * 1.5)
      }}
    />
  </View>
)

Pegs.propTypes = {
  positions: PropTypes.array.isRequired,
  pegTop: PropTypes.number.isRequired
}

export default Pegs
