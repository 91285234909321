import _ from 'lodash'
import { I18n } from 'aws-amplify'

export const HEROS = [
  {
    value: 'presenter',
    name: 'challenge.heros_journey.result.mission.presenter.name',
    image: require('../../../../assets/challenges/heros-journey/game-assets/icon-presenter.png'),
    imageLarge: require('../../../../assets/challenges/heros-journey/game-assets/presenter-large.png'),
    desc: 'challenge.heros_journey.result.mission.presenter'
  },
  {
    value: 'explorer',
    name: 'challenge.heros_journey.result.mission.explorer.name',
    image: require('../../../../assets/challenges/heros-journey/game-assets/icon-explorer.png'),
    imageLarge: require('../../../../assets/challenges/heros-journey/game-assets/explorer-large.png'),
    desc: 'challenge.heros_journey.result.mission.explorer'
  },
  {
    value: 'coordinator',
    name: 'challenge.heros_journey.result.mission.coordinator.name',
    image: require('../../../../assets/challenges/heros-journey/game-assets/icon-coordinator.png'),
    imageLarge: require('../../../../assets/challenges/heros-journey/game-assets/coordinator-large.png'),
    desc: 'challenge.heros_journey.result.mission.coordinator'
  },
  {
    value: 'enterpriser',
    name: 'challenge.heros_journey.result.mission.enterpriser.name',
    image: require('../../../../assets/challenges/heros-journey/game-assets/icon-enterpriser.png'),
    imageLarge: require('../../../../assets/challenges/heros-journey/game-assets/enterpriser-large.png'),
    desc: 'challenge.heros_journey.result.mission.enterpriser'
  }
]

export const getPositionForMatrix = (value) => {
  switch (value) {
    case 'presenter':
      return 1
    case 'explorer':
      return 0
    case 'coordinator':
      return 3
    case 'enterpriser':
      return 2
    default:
      return 0
  }
}

/**
 * Result Text for Matrix
 * @param {Computed Hero} value
 */
export const getResultText = (value) => {
  if (value === 'presenter') {
    return I18n.get('challenge.heros_journey.result.chart.presenter')
  }
  if (value === 'explorer') {
    return I18n.get('challenge.heros_journey.result.chart.explorer')
  }
  if (value === 'coordinator') {
    return I18n.get('challenge.heros_journey.result.chart.coordinator')
  }
  if (value === 'enterpriser') {
    return I18n.get('challenge.heros_journey.result.chart.enterpriser')
  }
}

export const valueToName = (value) => {
  let name = ''
  for (let i = 0; i < HEROS.length; i++) {
    if (HEROS[i].value === value) {
      name = HEROS[i].name // eslint-disable-line
      break
    }
  }
  return name
}

export const TEST_QUESTION = [
  {
    id: 'testquestion',
    title: 'challenge.heros_journey.testquestion',
    type: 'test',
    a: 'challenge.heros_journey.testquestion.a',
    b: 'challenge.heros_journey.testquestion.b'
  }
]

const QUESTIONS = [
  {
    id: 'coopcomp_01',
    title: 'challenge.heros_journey.coopcomp_01',
    type: 'coopcomp',
    a: 'challenge.heros_journey.coopcomp_01.a',
    b: 'challenge.heros_journey.coopcomp_01.b'
  },
  {
    id: 'coopcomp_02',
    title: 'challenge.heros_journey.coopcomp_02',
    type: 'coopcomp',
    a: 'challenge.heros_journey.coopcomp_02.a',
    b: 'challenge.heros_journey.coopcomp_02.b'
  },
  {
    id: 'coopcomp_03',
    title: 'challenge.heros_journey.coopcomp_03',
    type: 'coopcomp',
    a: 'challenge.heros_journey.coopcomp_03.a',
    b: 'challenge.heros_journey.coopcomp_03.b'
  },
  {
    id: 'coopcomp_04',
    title: 'challenge.heros_journey.coopcomp_04',
    type: 'coopcomp',
    a: 'challenge.heros_journey.coopcomp_04.a',
    b: 'challenge.heros_journey.coopcomp_04.b'
  },
  {
    id: 'coopcomp_05',
    title: 'challenge.heros_journey.coopcomp_05',
    type: 'coopcomp',
    a: 'challenge.heros_journey.coopcomp_05.a',
    b: 'challenge.heros_journey.coopcomp_05.b'
  },
  {
    id: 'orderchaos_01',
    title: 'challenge.heros_journey.orderchaos_06',
    type: 'orderchaos',
    a: 'challenge.heros_journey.coopcomp_06.a',
    b: 'challenge.heros_journey.coopcomp_06.b'
  },
  {
    id: 'orderchaos_02',
    title: 'challenge.heros_journey.orderchaos_07',
    type: 'orderchaos',
    a: 'challenge.heros_journey.coopcomp_07.a',
    b: 'challenge.heros_journey.coopcomp_07.b'
  },
  {
    id: 'orderchaos_03',
    title: 'challenge.heros_journey.orderchaos_08',
    type: 'orderchaos',
    a: 'challenge.heros_journey.coopcomp_08.a',
    b: 'challenge.heros_journey.coopcomp_08.b'
  },
  {
    id: 'orderchaos_04',
    title: 'challenge.heros_journey.orderchaos_09',
    type: 'orderchaos',
    a: 'challenge.heros_journey.coopcomp_09.a',
    b: 'challenge.heros_journey.coopcomp_09.b'
  },
  {
    id: 'orderchaos_05',
    title: 'challenge.heros_journey.orderchaos_10',
    type: 'orderchaos',
    a: 'challenge.heros_journey.coopcomp_10.a',
    b: 'challenge.heros_journey.coopcomp_10.b'
  }
]

export const RANDOM_QUESTIONS = () => _.shuffle(QUESTIONS)
