import { Auth } from 'aws-amplify'
import { makeAutoObservable, runInAction } from 'mobx'
import { isNumber } from 'lodash'

import { generateUUID } from '../utils/helpers'
import { notifyBugsnag } from '../utils/bugsnag'
import { mutation, query } from '../graphql'
import invitationStates from '../constants/invitationStates'
import asyncStorage from '../utils/async-storage'
import { trackEvent } from '../utils/tracking'

class UserStore {
  constructor() {
    this.cognitoUser = null
    this.userProfile = {}
    this.UUID = null
    this.validLinkParam = {}
    this.invitationState = invitationStates.INITIAL
    this.initialLink = 'https://webapp.aivy.app'
    this.pushEndpoint = null
    this.emailEndpoint = null
    this.language = 'de'
    this.username = null
    makeAutoObservable(this)
  }

  get userID() {
    return this.cognitoUser.signInUserSession?.accessToken?.payload?.username
  }

  get isAnonym() {
    return !!this.cognitoUser?.attributes?.email?.includes('anon.account') || null
  }

  get email() {
    return this.cognitoUser?.attributes?.email
  }

  get isEmailVerified() {
    return this.cognitoUser?.attributes?.email_verified
  }

  get isHardFactsDone() {
    return (
      !!this.userProfile.location &&
      !!this.userProfile.reach_settings &&
      isNumber(this.userProfile.remote) &&
      isNumber(this.userProfile.work_experience)
    )
  }

  initUserProfile(userProfile) {
    if (!userProfile) return
    this.userProfile = userProfile

    this.fetchPinpointEndpoints()
  }

  setInvitationState(state) {
    this.invitationState = state
  }

  setCognitoUser(cognitoUser) {
    this.cognitoUser = cognitoUser
  }

  setInitialLink(link) {
    this.initialLink = link
  }

  async refreshUser() {
    return Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((cognitoUser) => {
        runInAction(() => {
          this.cognitoUser = cognitoUser
        })
      })
      .catch((err) => notifyBugsnag(err))
  }

  async createProfile() {
    if (!this.userID) return
    const answer = await mutation('createUser', { id: this.userID, owner: this.userID })
    return answer
  }

  getUUID() {
    if (this.UUID) return this.UUID
    const newUUID = generateUUID()
    this.UUID = newUUID
    return newUUID
  }

  async authenticaeToSpaceAccount(spaceId) {
    const username = `${spaceId}@anon.account`
    const password = `${spaceId}%`
    const email = username

    const language = (await asyncStorage.getData('lang')) || 'de'

    let cognitoUser

    try {
      cognitoUser = await Auth.signIn({
        username,
        password
      })

      trackEvent('Login')

      runInAction(() => {
        this.cognitoUser = cognitoUser
      })
    } catch (err) {
      notifyBugsnag(err)
    }

    if (cognitoUser !== undefined) return // ABORT since login success

    const res = await Auth.signUp({
      username,
      password,
      attributes: { email },
      clientMetadata: { language, autoConfirmUser: 'TRUE', autoVerifyEmail: 'TRUE' }
    })

    runInAction(() => {
      this.cognitoUser = res
    })

    await this.createProfile()

    trackEvent('AccountCreated')

    cognitoUser = await Auth.signIn({
      username,
      password
    })

    trackEvent('Login')

    runInAction(() => {
      this.cognitoUser = cognitoUser
    })
  }

  async createCognitoAccount() {
    this.UUID = generateUUID()
    const username = `${this.UUID}@anon.account`
    const password = `${this.UUID}%`
    const email = username

    const language = (await asyncStorage.getData('lang')) || 'de'

    const res = await Auth.signUp({
      username,
      password,
      attributes: { email },
      clientMetadata: { language, autoConfirmUser: 'TRUE', autoVerifyEmail: 'TRUE' }
    })

    runInAction(() => {
      this.cognitoUser = res
    })

    trackEvent('AccountCreated')
    return res
  }

  async loginCognitoAccount() {
    const username = `${this.UUID}@anon.account`
    const password = `${this.UUID}%`

    const cognitoUser = await Auth.signIn({
      username,
      password
    })

    trackEvent('Login')

    runInAction(() => {
      this.cognitoUser = cognitoUser
    })
  }

  async updateUser(data) {
    if (!this.cognitoUser) return

    const userProfile = await mutation('updateUser', {
      id: this.username,
      ...data
    })

    if (this.cognitoUser) {
      runInAction(() => {
        this.userProfile = Object.assign(this.userProfile, userProfile.data.updateUser)
      })
    } else {
      runInAction(() => {
        this.userProfile = userProfile.data.updateUser
      })
    }

    return userProfile.data.updateUser
  }

  fetchPinpointEndpoints() {
    if (this.isAnonym || !this.userProfile) return

    const setEndpoints = (data) => {
      const endpoints = JSON.parse(
        data.pinpointControl
      ).body.getUserEndpoints.res.EndpointsResponse.Item.filter(
        ({ OptOut, EndpointStatus }) => OptOut === 'NONE' && EndpointStatus === 'ACTIVE'
      )

      runInAction(() => {
        this.pushEndpoint = endpoints.find(({ ChannelType }) =>
          ['APNS', 'GCM'].includes(ChannelType)
        )
        this.emailEndpoint = endpoints.find(({ ChannelType }) => ChannelType === 'EMAIL')
      })
    }

    query({
      query: 'pinpointControl',
      variables: {
        action: 'getUserEndpoints',
        user_id: this.userProfile.id
      }
    })
      .then(({ data }) => setEndpoints(data))
      .catch((err) => {
        if (err?.errors?.[0]?.message !== 'Resource not found') {
          notifyBugsnag(err)
        }
      })
  }

  async logout() {
    await Auth.signOut({ global: true })
    this.cognitoUser = null
    this.emailEndpoint = null
    this.pushEndpoint = null
  }

  clear() {
    this.cognitoUser = null
    this.username = null
    this.userProfile = {}
    this.UUID = null
    this.validLinkParam = {}
    this.invitationState = invitationStates.INITIAL
    this.emailEndpoint = null
    this.pushEndpoint = null
  }
}

export default UserStore
