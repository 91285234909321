import React, { useRef, useState } from 'react'
import * as Updates from 'expo-updates'
import { Platform, AppState } from 'react-native'
import PropTypes from 'prop-types'

import { notifyBugsnag } from '../utils/bugsnag'
import asyncStorage from '../utils/async-storage'
import { ContentLoader } from '../components'
import { useMount } from '../hooks/use-mount'

const UpdateWrapper = ({ url, children }) => {
  const [updateWrapperReady, setUpdateWrapperReady] = useState(false)

  const currentState = useRef(AppState.currentState)

  const checkForOtaUpdates = async () => {
    if (Platform.OS === 'web' || __DEV__) {
      setUpdateWrapperReady(true)
      return
    }

    let update = null

    try {
      update = await Updates.checkForUpdateAsync()

      if (update.isAvailable) {
        await asyncStorage.storeData('initUrlBeforeUpdate', url)
        await Updates.fetchUpdateAsync()
        await Updates.reloadAsync()
      }
    } catch (err) {
      notifyBugsnag(err)
    } finally {
      update !== null && !update.isAvailable && setUpdateWrapperReady(true)
    }
  }

  useMount(() => {
    checkForOtaUpdates()

    const changeEventListener = async (nextAppState) => {
      if (currentState.current.match(/inactive|background/) && nextAppState === 'active') {
        checkForOtaUpdates()
      }

      currentState.current = nextAppState
    }

    const el = AppState.addEventListener('change', changeEventListener)

    return () => {
      el.remove()
    }
  })

  if (!updateWrapperReady) {
    return <ContentLoader />
  }

  return children
}

UpdateWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default UpdateWrapper
