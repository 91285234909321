import { useEffect, useState } from 'react'
// import { Platform } from 'react-native'
import { query } from '../graphql'
// eslint-disable-next-line no-promise-executor-return
const wait = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds))

export const useAmplify = (state) => {
  const [amplifyReady, setAmplifyReady] = useState(state)

  useEffect(() => {
    if (amplifyReady !== 'INITIAL' && amplifyReady !== 'APP_STATE_ACTIVE') return

    const getSystemWithRetryAndTimeout = async (retries, milliseconds) => {
      if (retries < 0) {
        setAmplifyReady('ERROR')
        // notifyBugsnag(new Error('[OFFLINE] use amplify hook'))
        return
      }

      return query({
        query: 'getSystem',
        variables: { id: 'AMPLIFY_READY_CHECK' },
        authMode: 'AWS_IAM'
      })
        .then(() => {
          if (amplifyReady === 'APP_STATE_ACTIVE') {
            setAmplifyReady('READY_AGAIN')
          } else {
            setAmplifyReady('READY')
          }
        })
        .catch(() =>
          wait(milliseconds).then(() =>
            getSystemWithRetryAndTimeout(retries - 1, milliseconds + 500)
          )
        )
    }

    getSystemWithRetryAndTimeout(6, 0)
  }, [amplifyReady])

  return [amplifyReady, setAmplifyReady]
}
