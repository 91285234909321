import React, { useContext } from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'

import Typo from './Typo'
import colors from '../theme/colors'
import metrics from '../theme/metrics'
import { AuthContext } from '../context'

const styles = StyleSheet.create({
  container: {
    height: 152,
    width: '100%',
    maxWidth: 400,
    borderRadius: 18,
    paddingLeft: 20,
    paddingTop: 16,
    shadowColor: colors.mediumGrey,
    shadowOffset: {
      width: 0,
      height: 15
    },
    shadowOpacity: 0.2,
    shadowRadius: 28,
    elevation: 14
  }
})

const ChallengeCard = ({ containerStyle, onPress, challenge }) => {
  const { rootStore } = useContext(AuthContext)
  const { language } = rootStore.userStore

  const { backgroundColor, card, title, timeToComplete } = challenge

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, { backgroundColor }, containerStyle]}
    >
      <Typo.H1White>{title[language]}</Typo.H1White>
      <View
        style={{
          marginTop: 4,
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Image
          style={{ width: 16, height: 16, marginRight: 6 }}
          source={require('../assets/images/duration-icon.png')}
        />
        <Typo.H4White>{`${timeToComplete / 1000 / 60}min`}</Typo.H4White>
      </View>
      {card.imagePosition && card.imageDimension && (
        <Image
          style={{
            position: 'absolute',
            resizeMode: 'contain',
            bottom: card.imagePosition.bottom,
            right: card.imagePosition.right,
            ...metrics.calculateResponsiveDimensions(card.imageDimension)
          }}
          source={{ uri: card.image }}
        />
      )}
    </TouchableOpacity>
  )
}

ChallengeCard.propTypes = {
  onPress: PropTypes.func.isRequired,
  challenge: PropTypes.object.isRequired,
  containerStyle: PropTypes.object
}

ChallengeCard.defaultProps = {
  containerStyle: {}
}

export default ChallengeCard
