import React, { useState } from 'react'
import { View, StyleSheet, Image, TouchableOpacity, Platform } from 'react-native'
import PropTypes from 'prop-types'
import * as Updates from 'expo-updates'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Auth, I18n } from 'aws-amplify'
import { ModalHeader, Typo, Button, Loader } from '../../../../components'
import metrics from '../../../../theme/metrics'
import { query } from '../../../../graphql'
import { notifyBugsnag } from '../../../../utils/bugsnag'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: 24
  }
})

const DeleteAccount = ({ navigation }) => {
  const insets = useSafeAreaInsets()
  const [isLoading, setIsLoading] = useState(false)

  const signOutAndReload = async () => {
    try {
      await Auth.signOut()
      if (Platform.OS === 'web') {
        window && window.location.reload()
      } else {
        await Updates.reloadAsync()
      }
    } catch (err) {
      notifyBugsnag(err)
    }
  }

  const deleteAccount = async () => {
    setIsLoading(true)
    try {
      await query({ query: 'userControl', variables: { action: 'deleteUniversalAppUser' } })
      signOutAndReload()
    } catch (error) {
      notifyBugsnag(error)
      setIsLoading(false)
    }
  }

  const handleDeleteAccount = () => {
    navigation.navigate('Alert', {
      title: I18n.get('global.my_account.delete_account'),
      message: I18n.get('deleteaccount_are_you_sure'),
      buttons: [
        {
          text: I18n.get('myAccount.logout.alert.cancel'),
          onPress: () => {},
          style: 'cancel'
        },
        {
          text: I18n.get('global.my_account.delete_account'),
          style: 'destructive',
          onPress: deleteAccount
        }
      ]
    })
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <View style={styles.container}>
      <ModalHeader
        headerTitle='global.my_account.delete_account'
        onPress={() => navigation.goBack()}
        withBackButton
      />
      <View style={styles.contentContainer}>
        <Image
          source={require('../../../../assets/images/illustrations/delete-account-illustration.png')}
          style={{
            marginTop: 32,
            alignSelf: 'center',
            ...metrics.calculateResponsiveDimensions({ width: 103, height: 172 })
          }}
        />
        <Typo.H1Blue marginTop={50} center translate>
          deleteaccount_are_you_sure
        </Typo.H1Blue>
        <Typo.T1LightBlack center marginTop={15} translate>
          deleteaccount_description
        </Typo.T1LightBlack>
      </View>
      <View style={{ paddingBottom: 45 + insets.bottom, paddingHorizontal: 24 }}>
        <Button
          title={I18n.get('deleteaccount_notnow_button')}
          onPress={() => navigation.goBack()}
        />
        <TouchableOpacity
          style={{ alignSelf: 'center', marginTop: 24 }}
          onPress={handleDeleteAccount}
        >
          <Typo.ButtonBlue translate>deleteaccount_deleteall_button</Typo.ButtonBlue>
        </TouchableOpacity>
      </View>
    </View>
  )
}

DeleteAccount.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default DeleteAccount
