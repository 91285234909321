import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import metrics from '../theme/metrics'

import Typo from './Typo'
import colors from '../theme/colors'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0, 0.15)'
  }
})

const Alert = ({ navigation, route }) => (
  <View style={styles.container}>
    <View
      style={{ width: metrics.widthPercentageToDP(72), borderRadius: 12, backgroundColor: '#fff' }}
    >
      <View style={{ paddingVertical: 24, paddingHorizontal: 26 }}>
        <Typo.H2Black center>{route.params.title}</Typo.H2Black>
        <Typo.T2Black center marginTop={10}>
          {route.params.message}
        </Typo.T2Black>
      </View>
      <View
        style={{
          height: 1,
          width: '100%',
          backgroundColor: colors.lightGrey
        }}
      />
      <View style={{ flexDirection: 'row', height: 44, width: '100%' }}>
        {route.params.buttons.map(({ onPress, text, style }, index, arr) => (
          <View key={text} style={{ flex: 1, flexDirection: 'row' }}>
            <TouchableOpacity
              onPress={() => {
                if (onPress) {
                  navigation.goBack()
                  onPress()
                } else {
                  navigation.goBack()
                }
              }}
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typo.T1Blue
                style={[
                  {
                    color: style === 'destructive' ? colors.carnationPink : colors.primaryBlue
                  }
                ]}
              >
                {text}
              </Typo.T1Blue>
            </TouchableOpacity>
            {index + 1 !== arr.length && (
              <View
                style={{
                  height: 44,
                  width: 1,
                  backgroundColor: colors.lightGrey
                }}
              />
            )}
          </View>
        ))}
      </View>
    </View>
  </View>
)

Alert.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
}

Alert.defaultProps = {}

export default Alert
