/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Image, ActivityIndicator, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import colors from '../theme/colors'
import Typo from './Typo'

import { query } from '../graphql'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 400,
    marginTop: 12,
    backgroundColor: colors.white,
    borderRadius: 18,
    shadowColor: colors.mediumGrey,
    shadowOffset: {
      width: 0,
      height: 15
    },
    shadowOpacity: 0.2,
    shadowRadius: 28,
    elevation: 14
  },
  matchingIllustration: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
    marginRight: 24
  },
  header: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 20,
    alignItems: 'center'
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(24, 32, 51, 0.5)'
  }
})

// eslint-disable-next-line react/prop-types
const SpaceListItem = ({ onPress, partnerID, careerID, containerStyle }) => {
  const [partner, setPartner] = useState()
  const [career, setCareer] = useState()

  useEffect(() => {
    loadPartner()
    if (careerID) {
      loadCareer()
    }
  }, [])

  async function loadPartner() {
    const res = await query({
      query: 'getPartner',
      variables: { id: partnerID }
    })
    console.log('res', res) // eslint-disable-line no-console
    setPartner(res.data.getPartner)
  }

  async function loadCareer() {
    const res = await query({
      query: 'getCareer',
      variables: { id: careerID }
    })
    console.log('res', res) // eslint-disable-line no-console
    setCareer(res.data.getCareer)
  }

  if (!partner) {
    return <ActivityIndicator />
  }

  if (careerID && !career) {
    return <ActivityIndicator />
  }

  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, containerStyle]}>
      <View style={styles.header}>
        <Image style={styles.matchingIllustration} source={{ uri: partner.logo }} />
        <View>
          {career ? (
            <>
              <Typo.ButtonBlue>Job</Typo.ButtonBlue>
              <Typo.T6Black>{`${career.title}`}</Typo.T6Black>
            </>
          ) : (
            <>
              <Typo.ButtonBlue>Initiativ</Typo.ButtonBlue>
              <Typo.T6Black>{partner.display_name}</Typo.T6Black>
            </>
          )}
          <Typo.SmallTextBlack>{partner.name}</Typo.SmallTextBlack>
        </View>
      </View>
    </TouchableOpacity>
  )
}

SpaceListItem.propTypes = {
  containerStyle: PropTypes.object,
  onPress: PropTypes.func.isRequired
}

SpaceListItem.defaultProps = {
  containerStyle: {}
}

export default SpaceListItem
