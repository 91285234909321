import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '@expo/vector-icons'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Button, Typo } from '../../../../components'
import { JOB_TYPES } from '../data'
import colors from '../../../../theme/colors'

const RoundIntro = ({ onPress, type }) => {
  const insets = useSafeAreaInsets()

  return (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 20 + insets.top,
        backgroundColor: colors.brightBlue
      }}
    >
      <View style={{ flex: 1 }}>
        <Typo.H1White center translate>
          {type === JOB_TYPES.SMALL
            ? 'challenge.delegation.jobs.privatetitle'
            : 'challenge.delegation.jobs.familytitle'}
        </Typo.H1White>
        <Typo.T1White center marginTop={24} translate>
          {type === JOB_TYPES.SMALL
            ? 'challenge.delegation.jobs.privatedescription'
            : 'challenge.delegation.jobs.familydescription'}
        </Typo.T1White>

        <FontAwesome5
          style={{ alignSelf: 'center', paddingVertical: 32 }}
          name={type === JOB_TYPES.SMALL ? 'box' : 'boxes'}
          size={150}
          color='#fff'
        />
        <Typo.T1LightWhite translate>
          {type === JOB_TYPES.SMALL
            ? 'challenge.delegation.jobs.bottomDescription.private'
            : 'challenge.delegation.jobs.bottomDescription.family'}
        </Typo.T1LightWhite>
      </View>
      <View style={{ paddingBottom: 24 + insets.bottom }}>
        <Button onPress={onPress} title='challenge.delegation.jobs.button' />
      </View>
    </View>
  )
}

RoundIntro.propTypes = {
  onPress: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
}

RoundIntro.defaultProps = {}

export default RoundIntro
