import React from 'react'
import { Image, Platform } from 'react-native'

const AivyLogoFullscreen = () => (
  <Image
    style={{
      height: '100%',
      width: '100%',
      resizeMode: Platform.OS === 'android' ? undefined : 'contain'
    }}
    source={require('../assets/images/splash.png')}
  />
)

export default AivyLogoFullscreen
