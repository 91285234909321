// This will hold your device dimensions so you can export and use them for responsive layouts. It will manage all the fonts, margins, and images according to device size.
import { Dimensions, Platform, PixelRatio } from 'react-native'

// const { width, height } = Dimensions.get('window')
const appDimensions = Dimensions.get('window')

const webDimensions =
  Platform.OS === 'web' ? document.getElementById('aivy-app').getBoundingClientRect() : null

const universalWidth = webDimensions ? webDimensions.width : appDimensions.width
const universalHeight = webDimensions ? webDimensions.height : appDimensions.height

const DESIGN_WIDTH = 375

/**
 * Converts provided width percentage to independent pixel (dp).
 * @param  {string} widthPercent The percentage of screen's width that UI element should cover
 *                               along with the percentage symbol (%).
 * @return {number}              The calculated dp depending on current device's screen width.
 */
const widthPercentageToDP = (widthPercent) => {
  // Parse string percentage input and convert it to number.
  const elemWidth = typeof widthPercent === 'number' ? widthPercent : parseFloat(widthPercent)

  // Use PixelRatio.roundToNearestPixel method in order to round the layout
  // size (dp) to the nearest one that correspons to an integer number of pixels.
  return PixelRatio.roundToNearestPixel((universalWidth * elemWidth) / 100)
}

/**
 * Converts provided height percentage to independent pixel (dp).
 * @param  {string} heightPercent The percentage of screen's height that UI element should cover
 *                                along with the percentage symbol (%).
 * @return {number}               The calculated dp depending on current device's screen height.
 */
const heightPercentageToDP = (heightPercent) => {
  // Parse string percentage input and convert it to number.
  const elemHeight = typeof heightPercent === 'number' ? heightPercent : parseFloat(heightPercent)

  // Use PixelRatio.roundToNearestPixel method in order to round the layout
  // size (dp) to the nearest one that correspons to an integer number of pixels.
  return PixelRatio.roundToNearestPixel((universalHeight * elemHeight) / 100)
}

const calculateResponsiveDimensions = ({ width, height }) => {
  const widthPercent = (width / DESIGN_WIDTH) * 100

  return {
    width: widthPercentageToDP(widthPercent),
    height: widthPercentageToDP(widthPercent / (width / height)),
    maxWidth: width,
    maxHeight: height
  }
}

const metrics = {
  screenWidth: universalWidth < universalHeight ? universalWidth : universalHeight,
  screenHeight: universalWidth < universalHeight ? universalHeight : universalWidth,
  widthPercentageToDP,
  heightPercentageToDP,
  calculateResponsiveDimensions
}

export default metrics
