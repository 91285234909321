/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, StyleSheet, Animated } from 'react-native'
import { isUndefined, values, isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import { I18n } from 'aws-amplify'

import colors from '../../theme/colors'
import { AuthContext } from '../../context'
import Typo from '../Typo'
import Bar from './Bar'
import Progress from './Progress'
import NormalDistributionCurve from '../challenge/result/components/NormalDistributionCurve'
import PieChartTemp from './PieChartTemp'
import { dimensionScore, INTERESTS_CONTENT } from '../../utils/helpers'
import StrengthEmptySection from '../StrengthEmptySection'

const customMDStyles = StyleSheet.create({
  text: {
    fontFamily: 'fira-sans-regular',
    fontSize: 14,
    lineHeight: 22,
    color: colors.darkBlack
  },
  strong: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'fira-sans-medium'
  }
})

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  oval: {
    height: 12,
    width: 12,
    borderRadius: 6
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: '#B6C7F2',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    marginBottom: 16
  }
})

const Interests = () => {
  const animatedAngle = useRef(new Animated.Value(2)).current
  const { rootStore } = useContext(AuthContext)

  const [dataBarChart, setDataBarChart] = useState([])
  const [dataPieChart, setDataPieChart] = useState([])
  const [interestsText, setInterestsText] = useState([])

  useEffect(() => {
    let data = []
    let dimensionScores = []
    const riaDetails = []
    const answer = rootStore.answersStore.getAnswerForChallenge('RIASEC_IMAGES')
    if (isUndefined(answer)) return

    data = values(answer.user_input)
    dimensionScores = dimensionScore(data)
    INTERESTS_CONTENT.forEach((ria, idx) => {
      riaDetails[idx] = {
        value: dimensionScores[idx],
        ...ria
      }
    })
    const pieChartData = riaDetails.sort((a, b) => b.value - a.value)
    const BarChartData = pieChartData.slice(0, 3)

    const temp = BarChartData[0]
    BarChartData[0] = BarChartData[1]
    BarChartData[1] = temp
    const resDataBarChart = BarChartData.map((item, idx) => ({
      value: idx === 0 ? 70 : idx === 1 ? 100 : 45,
      svg: {
        fill: idx === 0 ? colors.primaryGreen : idx === 1 ? colors.brightBlue : colors.carnationPink
      },
      label: item.header
    }))
    const resDataPieChart = pieChartData.map((item, idx) => ({
      number: item.value,
      color:
        idx === 0
          ? colors.brightBlue
          : idx === 1
          ? colors.primaryGreen
          : idx === 2
          ? colors.carnationPink
          : colors.lightGrey,
      name: item.header
    }))
    setInterestsText(BarChartData)
    setDataBarChart(resDataBarChart)
    setDataPieChart(resDataPieChart)
  }, [rootStore.answersStore])

  function generateResultData() {
    const riasecAnswer = rootStore.answersStore.getAnswerForChallenge('RIASEC_IMAGES')
    if (!riasecAnswer) return {}

    const diffHcPercent = riasecAnswer.aivy_output.translatedzScores.diff_hc
    const elevationPercent = riasecAnswer.aivy_output.translatedzScores.elevation
    return { diffHcPercent, elevationPercent }
  }

  const riasecAnswer = rootStore.answersStore.getAnswerForChallenge('RIASEC_IMAGES')

  if (!riasecAnswer) return <StrengthEmptySection />

  return (
    <View style={styles.container}>
      <Typo.T1Black marginTop={24} translate>
        strength.interests.desc
      </Typo.T1Black>
      <Bar data={dataBarChart} containerStyle={{ marginBottom: 72 }} />
      <PieChartTemp data={dataPieChart} animatedAngle={animatedAngle} />
      <View style={{ marginTop: 56 }}>
        {!isEmpty(dataBarChart) &&
          interestsText
            .sort((a, b) => b.value - a.value)
            .map((interest, index) => (
              <View key={interest.header}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 4,
                    marginTop: index !== 0 ? 40 : 0
                  }}
                >
                  <View
                    style={[
                      styles.oval,
                      {
                        marginRight: 8,
                        backgroundColor:
                          index === 0
                            ? colors.brightBlue
                            : index === 1
                            ? colors.primaryGreen
                            : index === 2
                            ? colors.carnationPink
                            : colors.lightGrey
                      }
                    ]}
                  />
                  <Typo.T1Black>{I18n.get(interest.header)}</Typo.T1Black>
                </View>
                <Typo.T2Black>
                  {I18n.get(index === 0 ? interest.a : index === 1 ? interest.b : interest.c)}
                </Typo.T2Black>
              </View>
            ))}
      </View>
      <View style={[styles.card, { marginTop: 32, marginBottom: 16 }]}>
        <Typo.H4Black marginBottom={16}>{`${I18n.get(
          'global.challenge.result.specialist'
        )} ${I18n.get('global.or')} ${I18n.get(
          'global.challenge.result.generalist'
        )}?`}</Typo.H4Black>
        <Progress
          hideMoreInfo
          progressLeftTitle={I18n.get('global.challenge.result.specialist')}
          progressRightTitle={I18n.get('global.challenge.result.generalist')}
          max={50}
          value={generateResultData().diffHcPercent - 50}
          fillColor={colors.primaryGreen}
        />
        <Typo.Markdown style={customMDStyles}>
          {generateResultData().diffHcPercent <= 50
            ? I18n.get('challenge.riasec_images.diff_hc_prozent_2')
            : I18n.get('challenge.riasec_images.diff_hc_prozent_1')}
        </Typo.Markdown>
      </View>
      <NormalDistributionCurve
        containerStyle={{ marginBottom: 32 }}
        percent={Math.round(generateResultData().elevationPercent)}
        title={I18n.get('challenge.riasec_images.result.normal_distribution_curve.title')}
        description={`${
          generateResultData().elevationPercent <= 50
            ? I18n.get('challenge.riasec_images.elevation_prozent_1')
            : I18n.get('challenge.riasec_images.elevation_prozent_2')
        }`}
        labels={{
          left: I18n.get('challenge.riasec_images.result.scale_endpoint.left'),
          right: I18n.get('challenge.riasec_images.result.scale_endpoint.right')
        }}
      />
    </View>
  )
}

export default observer(Interests)
