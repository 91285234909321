import * as Linking from 'expo-linking'
import { isEmpty, isUndefined } from 'lodash'
import { Platform } from 'react-native'
import * as Clipboard from 'expo-clipboard'
import { isDevice } from 'expo-device'
import * as Notifications from 'expo-notifications'

import params, { FALLBACK_PARAM } from '../constants/params'
import colors from '../theme/colors'
import asyncStorage from './async-storage'
import { notifyBugsnag } from './bugsnag'

// storing reusable methods and logic like validations, progress bar, date pickers, and according to app requirements.

export const UNSPLASH_ACCESS_KEY = 'piZ7_GAJBvYo0mu-E7r8cmIgSqZg7FM_qTaKHnstzpI'
export const UNSPLASH_SECRET_KEY = 'gP5MUS6UbHua1zf040i3WK6LdsWkkuBiM7Ad-ihtfSE'

export const CAREER_IMAGE_URL =
  'https://react-native-app-content.s3.eu-central-1.amazonaws.com/career-images/'

export const isAivy = (partnerId) => partnerId === 'af2dabfc-f3ff-43a1-80cd-53ebf3da38d1'

export const AWS_AUTH_MODES = {
  AMAZON_COGNITO_USER_POOLS: 'AMAZON_COGNITO_USER_POOLS',
  AWS_IAM: 'AWS_IAM'
}

export const SPACE_CONTEXTS = { SELECT: 'SELECT' }

export const generateUUID = () => {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0 // eslint-disable-line
    const v = c === 'x' ? r : (r & 0x3) | 0x8 // eslint-disable-line
    return v.toString(16)
  })
  return uuid
}

export const replaceTranslationKey = (text, placeholder, value) => {
  if (isUndefined(text) || isUndefined(placeholder) || isUndefined(value)) return ''

  return text.replace(placeholder, value)
}

export const replaceTranslationKeys = (text = '', placeholders = [], values = []) => {
  let res = text
  values.forEach((value, index) => {
    res = res.replace(placeholders[index], value)
  })

  return res
}

export const isInvitationExpired = (expiresAt) => {
  if (!expiresAt) return false
  const now = new Date()
  const expiresAtDate = new Date(expiresAt)

  return expiresAtDate < now
}

function parseLink(nativeUrl) {
  if (!nativeUrl) return { queryParams: {} }
  let res
  try {
    // queryParams: QueryParams | null
    const parsed = Linking.parse(nativeUrl)

    res = parsed
  } catch (err) {
    notifyBugsnag(err)
  }

  if (!res?.queryParams) {
    return { queryParams: {} }
  }

  return res
}

async function getParamsFromClipboard() {
  const alreadyStarted = await asyncStorage.getData('started')

  let res
  if (!alreadyStarted) {
    try {
      res = JSON.parse(await Clipboard.getStringAsync())
    } catch (error) {
      res = { queryParams: {} }
    }
  } else {
    res = { queryParams: {} }
  }

  return res
}

export function openUrl(url) {
  if (Platform.OS === 'web') {
    window.open(url)
    return
  }

  Linking.canOpenURL(url).then((supported) => {
    if (supported) {
      return Linking.openURL(url)
    }
    notifyBugsnag(new Error(`Don't know how to open URI: ${url}`))
  })
}

/**
 * Checks QueryParams from URL for valid linkparam.
 * returns fallback if nothing valid
 * @returns valid link parameter
 */
export async function retrieveLinkParams(url) {
  let initUrl = { queryParams: {} }

  if (url) {
    initUrl = parseLink(url)
  } else if (Platform.OS !== 'web') {
    initUrl = await getParamsFromClipboard()
  }

  const queryParams = initUrl ? initUrl.queryParams || {} : {}

  await asyncStorage.storeData('link_params', initUrl)

  const foundValidLinkParam = {}
  // eslint-disable-next-line
  for (const [validParamsKey, validParamsValue] of Object.entries(params)) {
    // eslint-disable-next-line
    for (const [queryParamsKey, queryParamsValue] of Object.entries(queryParams || {})) {
      if (queryParamsKey === validParamsValue) {
        foundValidLinkParam[queryParamsKey] = queryParams[queryParamsKey]
      }
    }
  }

  if (isEmpty(foundValidLinkParam)) {
    return FALLBACK_PARAM

    // const activeSpaceSettings = await getActiveSpaceSettings()
    // if (!activeSpaceSettings) {
    //   return FALLBACK_PARAM
    // }
    // return activeSpaceSettings
  }

  return foundValidLinkParam
}

/**
 * Checks if the user has enabled notifications in device
 * @returns true if granted, otherwise false
 */
export async function checkNotificationsPermissions() {
  // if (simulator || emulator) return true
  if (!isDevice) return true

  const { status } = await Notifications.getPermissionsAsync()

  return status === 'granted'
}

/* eslint-disable */
export const dimensionScore = (answers) => {
  const R = answers.filter(({ category }) => category === 'R')
  const I = answers.filter(({ category }) => category === 'I')
  const A = answers.filter(({ category }) => category === 'A')
  const S = answers.filter(({ category }) => category === 'S')
  const E = answers.filter(({ category }) => category === 'E')
  const C = answers.filter(({ category }) => category === 'C')

  const r_score = R.reduce((accumulator, { item_score }) => accumulator + item_score, 0)
  const i_score = I.reduce((accumulator, { item_score }) => accumulator + item_score, 0)
  const a_score = A.reduce((accumulator, { item_score }) => accumulator + item_score, 0)
  const s_score = S.reduce((accumulator, { item_score }) => accumulator + item_score, 0)
  const e_score = E.reduce((accumulator, { item_score }) => accumulator + item_score, 0)
  const c_score = C.reduce((accumulator, { item_score }) => accumulator + item_score, 0)

  return [r_score, i_score, a_score, s_score, e_score, c_score]
}

export const isSelect = (space) => (space.partner_id && space_career_id ? true : false)

export const INTERESTS_CONTENT = [
  {
    id: 0,
    header: 'strength.interests.r.header',
    code: 'r',
    color: colors.pink,
    description: 'strength.r.description',
    prev: 'strength.r.prev',
    a: 'strength.r.a',
    b: 'strength.r.b',
    c: 'strength.r.c'
  },
  {
    id: 1,
    color: colors.secondary,
    header: 'strength.interests.i.header',
    code: 'i',
    description: 'strength.i.description',
    prev: 'strength.i.prev',
    a: 'strength.i.a',
    b: 'strength.i.b',
    c: 'strength.i.c'
  },
  {
    id: 2,
    color: colors.brightBlue,
    header: 'strength.interests.a.header',
    code: 'a',
    description: 'strength.a.description',
    prev: 'strength.a.prev',
    a: 'strength.a.a',
    b: 'strength.a.b',
    c: 'strength.a.c'
  },
  {
    id: 3,
    color: colors.majorelleBlue,
    header: 'strength.interests.s.header',
    code: 's',
    description: 'strength.s.description',
    prev: 'strength.s.prev.',
    a: 'strength.s.a',
    b: 'strength.s.b',
    c: 'strength.s.c'
  },
  {
    id: 4,
    color: colors.crusta,
    header: 'strength.interests.e.header',
    code: 'e',
    description: 'strength.e.description',
    prev: 'strength.e.prev',
    a: 'strength.e.a',
    b: 'strength.e.b',
    c: 'strength.e.c'
  },
  {
    id: 5,
    color: colors.saffron,
    header: 'strength.interests.c.header',
    code: 'c',
    description: 'strength.c.description',
    prev: 'strength.c.prev',
    a: 'strength.c.a',
    b: 'strength.c.b',
    c: 'strength.c.c'
  }
]

export const shouldShowComputerMouseHint = () => {
  if (Platform.OS !== 'web') return false
  const isWebAppContainer = window.location.hostname.includes('webapp')
  const isTouchEnabled =
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
  return isWebAppContainer && isTouchEnabled
}

export const TimeInApp = async (timeInMinutes, rootStore) => {
  const time_in_app = rootStore.userStore.userProfile?.time_in_app

  if (time_in_app === null) {
    rootStore.userStore.updateUser({ time_in_app: timeInMinutes })
  } else {
    rootStore.userStore.updateUser({
      time_in_app: time_in_app + timeInMinutes
    })
  }
}

/**
 * Add new channels by Incoming Webhooks in Aivy Slack App
 */
const channels = {
  support: 'https://hooks.slack.com/services/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'
}

function _sendSlackMessage(channel, message) {
  return fetch(channels[channel], {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: JSON.stringify({ text: message })
  })
}

export async function sendSlackMessage(channel, message, throwError) {
  // because of cors policy
  if (__DEV__) {
    return console.log('send-slack-message:', message) // eslint-disable-line
  }

  try {
    await _sendSlackMessage(channel, message)
  } catch (err) {
    notifyBugsnag(err)

    if (throwError) {
      throw err
    }
  }
}

export function replaceUrlParam(url, paramName, paramValue) {
  if (paramValue == null) {
    paramValue = ''
  }
  var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)')
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + paramValue + '$2')
  }
  url = url.replace(/[?#]$/, '')
  return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue
}

export function isChallengeDemo() {
  if (Platform.OS !== 'web') return false
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const challengeIdFromUrl = urlParams.get('challenge')
  console.log('challengeIdFromUrl', challengeIdFromUrl)
  return !!challengeIdFromUrl
}

export function moveObjectToFirst(array, object) {
  const index = array.indexOf(object)

  if (index !== -1) {
    array.splice(index, 1)
    array.unshift(object)
  }

  return array
}

export function isNullOrUndefined(param) {
  return param === null || param === undefined
}

export function isTablet() {
  if (Platform.OS !== 'web') {
    return false
  }
  const userAgent = navigator.userAgent.toLowerCase()
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    )

  return isTablet
}

export function shouldShowCopyAivyId(partner_id) {
  if (!partner_id) return false

  return (
    partner_id === 'c162ed7c-c1e6-4512-90a4-f85d8182781d' ||
    partner_id === '038b35cc-e0f9-44f0-9c3f-b80936fc681f'
  )
}

export function getSubDomain() {
  if (Platform.OS !== 'web') return ''

  // Aktuelle URL der Webseite
  const currentURL = window.location.href

  // URL-Objekt erstellen
  const url = new URL(currentURL)

  // Subdomain auslesen
  const subdomain = url.hostname.split('.')[0]
  return subdomain
}

export function removeDuplicates(arr, propertyName) {
  const uniqueItems = new Map()
  arr.forEach((item) => {
    const propertyValue = item[propertyName]
    if (!uniqueItems.has(propertyValue)) {
      uniqueItems.set(propertyValue, item)
    }
  })

  return Array.from(uniqueItems.values())
}
