import React from 'react'
import { View } from 'react-native'
import ContentLoader from 'react-content-loader'
import { Rect, Circle } from 'react-native-svg'

import metrics from '../theme/metrics'
/**
 * TODO Check Can't perform a React state update on an unmounted component.
 */
const Loader = () => {
  const isLoggedIn = false
  // const [isLoggedIn, setIsLoggedIn] = useState(null)
  // useEffect(() => {
  //   Auth.currentAuthenticatedUser({ bypassCache: true })
  //     .then(() => {
  //       setIsLoggedIn(true)
  //     })
  //     .catch(() => {
  //       setIsLoggedIn(false)
  //     })
  // }, [])

  // if (isLoggedIn === null) return null

  return (
    <View style={{ padding: 24 }}>
      {!isLoggedIn ? (
        <ContentLoader
          viewBox={`0 0 ${metrics.screenWidth} ${metrics.screenHeight}`}
          height={metrics.screenHeight}
          width={metrics.screenWidth}
        >
          <Circle cx='19' cy='25' r='16' />
          <Rect x='39' y='12' rx='5' ry='5' width='220' height='10' />
          <Rect x='40' y='29' rx='5' ry='5' width='220' height='10' />
          <Circle cx={metrics.screenWidth - 80} cy='71' r='16' />
          <Rect x={metrics.screenWidth - 260} y='76' rx='5' ry='5' width='150' height='10' />
          <Rect x={metrics.screenWidth - 260} y='58' rx='5' ry='5' width='150' height='10' />
          <Circle cx='21' cy='117' r='16' />
          <Rect x='45' y='104' rx='5' ry='5' width='220' height='10' />
          <Rect x='45' y='122' rx='5' ry='5' width='220' height='10' />
        </ContentLoader>
      ) : (
        <ContentLoader
          viewBox={`0 0 ${metrics.screenWidth} ${metrics.screenHeight}`}
          height={metrics.screenHeight}
          width={metrics.screenWidth}
        >
          <Circle cx='15' cy='30' r='13' />

          <Rect x='35' y='25' rx='4' ry='4' width='60' height='5' />
          <Rect x='35' y='40' rx='4' ry='4' width='60' height='5' />
          <Rect x='0' y='60' rx='5' ry='5' width={metrics.screenWidth - 50} height={120} />
          <Rect x='0' y='200' rx='5' ry='5' width={metrics.screenWidth - 50} height={120} />
          <Rect x='0' y='340' rx='5' ry='5' width={metrics.screenWidth - 50} height={120} />
        </ContentLoader>
      )}
    </View>
  )
}

Loader.propTypes = {}

Loader.defaultProps = {}

export default Loader
