import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import PropTypes from 'prop-types'

import Typo from './Typo'
import colors from '../theme/colors'
import metrics from '../theme/metrics'

const styles = StyleSheet.create({
  container: {
    minHeight: 40,
    borderRadius: 24,
    borderWidth: 1,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: metrics.widthPercentageToDP(Platform.OS === 'web' ? 100 : 55)
  }
})

const ChatBubble = ({ message, containerStyle, channel }) => (
  <View
    style={[
      styles.container,
      containerStyle,
      {
        backgroundColor: channel === 'aivy' ? '#fff' : colors.paleWhiteBlue,
        borderColor: channel === 'aivy' ? colors.lightGrey : colors.paleBlue,
        marginLeft: channel === 'aivy' ? 0 : 'auto'
      }
    ]}
  >
    <Typo.T6Black style={{ marginHorizontal: 16 }} translate>
      {message}
    </Typo.T6Black>
  </View>
)

ChatBubble.propTypes = {
  message: PropTypes.string.isRequired,
  containerStyle: PropTypes.object,
  channel: PropTypes.string
}

ChatBubble.defaultProps = {
  containerStyle: {},
  channel: 'aivy'
}

export default ChatBubble
