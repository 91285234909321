import React, { useEffect, useState } from 'react'
import { StyleSheet, StatusBar, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import PropTypes from 'prop-types'

import colors from '../../theme/colors'
import Typo from '../Typo'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 128
  }
})

const Countdown = ({ onPress, backgroundColor }) => {
  const insets = useSafeAreaInsets()
  const [counter, setCounter] = useState(3)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (counter > 1) {
        setCounter(counter - 1)
      } else {
        clearInterval(timer)
        onPress && onPress()
      }
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <>
      <StatusBar barStyle='light-content' />
      <View style={[styles.container, { paddingTop: insets.top, backgroundColor }]}>
        <Typo.H2White translate>challenge.global.get_ready</Typo.H2White>
        <Typo.NumberWhite marginTop={20}>{counter}</Typo.NumberWhite>
      </View>
    </>
  )
}

Countdown.propTypes = {
  onPress: PropTypes.func,
  backgroundColor: PropTypes.string
}

Countdown.defaultProps = {
  onPress: undefined,
  backgroundColor: colors.brightBlue
}

export default Countdown
