import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'
import { Legals, MarkdownScreen, MyAccount, Notifications, DeleteAccount } from '../screens'
import colors from '../theme/colors'
import { headerLeft } from './AuthStack'
import HardFacts from '../screens/app/settings/account/HardFacts'
import HardFactsOverview from '../screens/app/settings/account/HardFactOverview'
import Languages from '../screens/app/settings/account/Languages'

const SettingsStack = createNativeStackNavigator()

const SettingStackScreen = ({ navigation }) => (
  <SettingsStack.Navigator
    screenOptions={{
      headerShown: false,
      headerTitleStyle: {
        fontFamily: 'fira-sans-semi-bold',
        fontSize: 16,
        color: colors.darkBlack
      },
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerLeft: () => headerLeft(navigation)
    }}
  >
    <SettingsStack.Screen name='MyAccount' component={MyAccount} />
    <SettingsStack.Screen
      name='Legals'
      component={Legals}
      options={{ title: I18n.get('global.legal') }}
    />
    <SettingsStack.Screen name='MarkdownScreen' component={MarkdownScreen} />
    <SettingsStack.Screen name='Notifications' component={Notifications} />
    <SettingsStack.Screen name='Languages' component={Languages} />
    <SettingsStack.Screen name='HardFacts' component={HardFacts} />
    <SettingsStack.Screen name='HardFactsOverview' component={HardFactsOverview} />
    <SettingsStack.Screen name='DeleteAccount' component={DeleteAccount} />
  </SettingsStack.Navigator>
)

SettingStackScreen.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default SettingStackScreen
