import React, { useEffect, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import PropTypes from 'prop-types'
import * as Updates from 'expo-updates'
import { Auth } from 'aws-amplify'

import { ModalHeader, Typo } from '../../../../components'
import colors from '../../../../theme/colors'
import asyncStorage from '../../../../utils/async-storage'
import { trackEvent } from '../../../../utils/tracking'
import { replaceUrlParam } from '../../../../utils/helpers'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  listItem: {
    width: '100%',
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20
  }
})

const Languages = ({ navigation }) => {
  const [language, setLanguage] = useState()

  useEffect(() => {
    const getLanguage = async () => {
      setLanguage((await asyncStorage.getData('lang')) || 'de')
    }

    getLanguage()
  }, [])

  const updateLanguage = async (lang) => {
    Auth.configure({ clientMetadata: { language: lang } })
    await asyncStorage.storeData('lang', lang)
    trackEvent('LanguageChanged', { language: lang })

    if (Platform.OS === 'web') {
      const currentUrl = window?.location.href
      const replacedUrl = replaceUrlParam(currentUrl, 'language', lang)
      window && window.location.replace(replacedUrl)
    } else {
      await Updates.reloadAsync()
    }
  }

  return (
    <View style={styles.container}>
      <ModalHeader
        headerTitle='settings.languages.header'
        onPress={() => navigation.goBack()}
        withBackButton
      />
      <View style={{ marginTop: 16 }}>
        <TouchableOpacity style={styles.listItem} onPress={() => updateLanguage('de')}>
          <Typo.T1Black>DE (Deutsch)</Typo.T1Black>
          {language === 'de' && <Ionicons name='checkmark' size={24} color={colors.primaryBlue} />}
        </TouchableOpacity>
        <TouchableOpacity style={styles.listItem} onPress={() => updateLanguage('en')}>
          <Typo.T1Black>EN (English)</Typo.T1Black>
          {language === 'en' && <Ionicons name='checkmark' size={24} color={colors.primaryBlue} />}
        </TouchableOpacity>
      </View>
    </View>
  )
}

Languages.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default Languages
