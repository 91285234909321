import React from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'
import Typo from './Typo'
import colors from '../theme/colors'
import metrics from '../theme/metrics'

const styles = StyleSheet.create({
  container: {
    height: 280,
    width: '100%',
    maxWidth: 400,
    backgroundColor: colors.oceanDarkBlue,
    borderRadius: 18,
    paddingLeft: 16,
    paddingTop: 16,
    justifyContent: 'space-between',
    paddingBottom: 38,
    shadowColor: colors.mediumGrey,
    shadowOffset: {
      width: 0,
      height: 15
    },
    shadowOpacity: 0.2,
    shadowRadius: 28,

    elevation: 14
  },
  labelStyle: {
    marginBottom: 4,
    height: 32,
    backgroundColor: 'rgba(255,255,255,0.15)',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    paddingHorizontal: 12,
    borderRadius: 8
  },
  imageStyle: {
    ...metrics.calculateResponsiveDimensions({ width: 124, height: 154 }),
    resizeMode: 'contain',
    position: 'absolute',
    right: 0,
    bottom: 0
  }
})

const NotificationListItem = ({ context, header, description, button, image, containerStyle }) => (
  <TouchableOpacity onPress={button.action} style={[styles.container, containerStyle]}>
    <Image style={styles.imageStyle} source={image} />
    <View>
      <View style={styles.labelStyle}>
        <Typo.T2White translate>{context}</Typo.T2White>
      </View>
      <Typo.H1White translate marginBottom={4}>
        {header}
      </Typo.H1White>
      <Typo.H3White translate style={{ width: '68%' }}>
        {description}
      </Typo.H3White>
    </View>
    <Typo.ButtonWhite translate style={{ color: colors.brightGreen, width: '64%' }}>
      {button.text}
    </Typo.ButtonWhite>
  </TouchableOpacity>
)

NotificationListItem.propTypes = {
  context: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  containerStyle: PropTypes.object
}

NotificationListItem.defaultProps = {
  containerStyle: {}
}

export default NotificationListItem
