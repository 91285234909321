import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'

import metrics from '../../../../theme/metrics'
import { Typo } from '../../../../components'
import colors from '../../../../theme/colors'
import Stone from './stone'
import { replaceTranslationKey } from '../../../../utils/helpers'

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    backgroundColor: '#fff',
    borderRadius: 4,
    paddingHorizontal: 18,
    paddingBottom: 24,
    paddingTop: 16,
    ...metrics.calculateResponsiveDimensions({ width: 307, height: 208 }),
    shadowColor: '#B6C7F2',
    shadowOpacity: 0.3,
    shadowRadius: 12,
    shadowOffset: { width: 0, height: 4 }
  }
})

const GoalState = ({ containerStyle, currentLevelOutcome, minRequiredDraws, won, lost }) => (
  <View
    style={[
      styles.container,
      containerStyle,
      // eslint-disable-next-line no-nested-ternary
      { backgroundColor: won ? colors.brightGreen : lost ? colors.buttonRed : '#fff' }
    ]}
  >
    <Typo.ButtonBlue style={{ color: won || lost ? '#fff' : colors.primaryBlue }}>
      {replaceTranslationKey(
        I18n.get('challenge.tower_of_london.notice'),
        '{{{min_required_draws}}}',
        minRequiredDraws
      )}
    </Typo.ButtonBlue>
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <View
        style={{
          width: metrics.calculateResponsiveDimensions({ height: 125, width: 194 }).width,
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'baseline'
        }}
      >
        <View
          style={{
            height: 3 * 24 + 20,
            width: 12,
            backgroundColor: colors.paleBlue,
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <View style={{ position: 'absolute' }}>
            {currentLevelOutcome[0].map((stone, index) => (
              <Stone
                tower={0}
                disabled={index !== 0}
                height={24}
                width={54}
                borderRadius={4.8}
                stone={stone}
                index={index}
                key={stone.toString()}
              />
            ))}
          </View>
        </View>
        <View
          style={{
            height: 2 * 24 + 20,
            width: 12,
            backgroundColor: colors.paleBlue,
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <View style={{ position: 'absolute' }}>
            {currentLevelOutcome[1].map((stone, index) => (
              <Stone
                tower={0}
                disabled={index !== 0}
                height={24}
                width={54}
                borderRadius={4.8}
                stone={stone}
                index={index}
                key={stone.toString()}
              />
            ))}
          </View>
        </View>
        <View
          style={{
            height: 1 * 24 + 20,
            width: 12,
            backgroundColor: colors.paleBlue,
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <View style={{ position: 'absolute' }}>
            {currentLevelOutcome[2].map((stone, index) => (
              <Stone
                tower={0}
                disabled={index !== 0}
                height={24}
                width={54}
                borderRadius={4.8}
                stone={stone}
                index={index}
                key={stone.toString()}
              />
            ))}
          </View>
        </View>
      </View>
    </View>
  </View>
)

GoalState.defaultProps = {
  containerStyle: {}
}

GoalState.propTypes = {
  containerStyle: PropTypes.object,
  currentLevelOutcome: PropTypes.array.isRequired,
  won: PropTypes.any.isRequired,
  minRequiredDraws: PropTypes.number.isRequired,
  lost: PropTypes.bool.isRequired
}

export default GoalState
