import React, { useRef, useState } from 'react'
import _ from 'lodash'
import { View, SafeAreaView, StyleSheet } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import PropTypes from 'prop-types'

import metrics from '../../../../theme/metrics'
import colors from '../../../../theme/colors'
import { Typo, Button } from '../../../../components'
import Dot from './Dot'
import HeroCard from './HeroCard'

const s = StyleSheet.create({
  pagination: {
    flexDirection: 'row',
    marginVertical: 30,
    justifyContent: 'center'
  },
  cardStyle: {
    flex: 1,
    width: metrics.widthPercentageToDP(78),
    backgroundColor: '#fff',
    borderRadius: 4,
    shadowColor: '#E3E4E6',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8,
    marginRight: 14,
    padding: 16
  }
})

const HerosJourneySelection = ({ route, navigation }) => {
  const { heros, pressedHero, onPress } = route.params

  const herosList = _.concat(
    _.filter(heros, (hero) => hero.value === pressedHero),
    _.filter(heros, (hero) => hero.value !== pressedHero)
  )

  const scroll = useRef(null)
  const [selected, setSelected] = useState(pressedHero)
  const [currentIndex, setCurrentIndex] = useState(0)

  function onPressHeroCard(hero, idx) {
    if (scroll.current) {
      switch (idx) {
        case 0:
          scroll.current.scrollTo({ x: 0, y: 0, animated: true })
          break
        case 1:
          scroll.current.scrollTo({ x: metrics.widthPercentageToDP(78) + 14, y: 0, animated: true })
          break
        case 2:
          scroll.current.scrollTo({
            x: metrics.widthPercentageToDP(78 * 2) + 28,
            y: 0,
            animated: true
          })
          break
        case 3:
          scroll.current.scrollTo({
            x: metrics.widthPercentageToDP(78 * 3) + 42,
            y: 0,
            animated: true
          })
          break
        default:
          scroll.current.scrollTo({ x: 0, y: 0, animated: true })
          break
      }
    }

    setSelected(hero.value)
  }

  const handleScroll = (e) => {
    const length = Math.round(metrics.widthPercentageToDP(78) + 14) // pagination window
    const currentPos = Math.round(e.nativeEvent.contentOffset.x)
    const variance = 20
    const firstSlide = currentPos === 0 && currentPos <= variance
    const secondSlide = currentPos >= length - variance && currentPos <= length + variance
    const thirdSlide = currentPos >= length * 2 - variance && currentPos <= length * 2 + variance
    const lastSlide = currentPos >= length * 3 - variance && currentPos <= length * 3 + variance

    if (firstSlide) {
      setCurrentIndex(0)
    } else if (secondSlide) {
      setCurrentIndex(1)
    } else if (thirdSlide) {
      setCurrentIndex(2)
    } else if (lastSlide) {
      setCurrentIndex(3)
    }
  }

  return (
    <SafeAreaView
      forceInset={{ top: 'always', bottom: 'always' }}
      style={{ flex: 1, backgroundColor: colors.whisper }}
    >
      <Typo.H1Blue center marginTop={40} translate>
        challenge.heros_journey.result.your_mission
      </Typo.H1Blue>
      <View style={s.pagination}>
        {heros.map(
          (
            _, // eslint-disable-line
            index
          ) => (
            <Dot
              key={index}
              index={index}
              currentIndex={currentIndex}
              // {...{ index }}
              onPress={() => onPressHeroCard(_, index)}
            />
          )
        )}
      </View>
      <ScrollView
        ref={scroll}
        horizontal
        scrollEventThrottle={1}
        bounces={false}
        snapToInterval={metrics.widthPercentageToDP(78) + 14}
        decelerationRate='fast'
        snapToAlignment='start'
        contentContainerStyle={{ marginLeft: 40 }}
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
      >
        {herosList.map((hero, index) => (
          <HeroCard
            key={index}
            hero={hero}
            index={index}
            selected={selected === hero.value}
            onPress={() => onPressHeroCard(hero, index)}
          />
        ))}
      </ScrollView>
      <View
        style={{
          paddingHorizontal: 24,
          paddingBottom: 24,
          paddingTop: 30
        }}
      >
        <Button
          onPress={() => {
            onPress(selected)
            navigation.goBack()
          }}
          title='global.choose'
        />
      </View>
    </SafeAreaView>
  )
}

HerosJourneySelection.propTypes = {
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired
}

export default HerosJourneySelection
