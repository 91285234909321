import React, { useContext, useState } from 'react'
import { Platform } from 'react-native'
import { isDevice } from 'expo-device'
import * as Notifications from 'expo-notifications'
import PropTypes from 'prop-types'

import { Button, QuestionModal } from '../../components'
import { AuthContext, NotificationContext } from '../../context'
import { notifyBugsnag } from '../../utils/bugsnag'
import { updateEndpoint } from '../../utils/analytics'
import { trackEvent } from '../../utils/tracking'

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false
  })
})

const RegisterForPushNotifications = ({ navigation }) => {
  const { rootStore } = useContext(AuthContext)
  const { success, error } = useContext(NotificationContext)

  const [isLoading, setIsLoading] = useState(false)

  async function registerForPushNotificationsAsync() {
    setIsLoading(true)

    if (__DEV__) {
      await rootStore.userStore.updateUser({
        push_token: [rootStore.userStore.username]
      })

      await updateEndpoint({ userStore: rootStore.userStore })

      setIsLoading(false)

      rootStore.userStore.fetchPinpointEndpoints()
      navigation.goBack()
      return
    }

    let token
    if (isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync()

      let finalStatus = existingStatus

      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }

      if (finalStatus !== 'granted') {
        error()
        return
      }

      token = (await Notifications.getDevicePushTokenAsync()).data
      trackEvent('PushNotificationGranted')
    } else {
      error()
      return
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C'
      })
    }

    try {
      await rootStore.userStore.updateUser({
        push_token: [token]
      })

      await updateEndpoint({ userStore: rootStore.userStore })

      success('global.push_notification_success_action')
      setIsLoading(false)

      rootStore.userStore.fetchPinpointEndpoints()
      navigation.goBack()
    } catch (err) {
      error()
      notifyBugsnag(err)
    }

    console.log(`user: ${rootStore.userStore.username} pushToken:`, token) // eslint-disable-line no-console
    return token
  }

  return (
    <QuestionModal
      buttons={[
        <Button
          key={0}
          title='global.push_notification_yes_action'
          containerStyle={{ marginVertical: 4 }}
          onPress={() => registerForPushNotificationsAsync()}
          isLoading={isLoading}
        />,
        <Button
          key={1}
          title='global.push_notification_no_action'
          containerStyle={{ marginVertical: 4, marginBottom: 32 }}
          type='outline'
          onPress={() => {
            trackEvent('PushNotificationCanceled')
            navigation.goBack()
          }}
          centerText
        />
      ]}
      title='global.push_notification_title'
      text='global.push_notification_description'
      additionalText='global.push_notification_info'
      imageSource={require('../../assets/images/illustrations/notification.png')}
    />
  )
}

RegisterForPushNotifications.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default RegisterForPushNotifications
