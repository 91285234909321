import React from 'react'
import { View } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import NormalDistributionCurve from './components/NormalDistributionCurve'
import ScienceText from './components/ScienceText'

import JobMatch from './components/JobMatch'

const DelegationResult = ({ answer }) => {
  const { score_risk } = answer.aivy_output.translatedzScores

  const { score_responsibility } = answer.aivy_output.translatedzScores

  return (
    <View>
      <NormalDistributionCurve
        containerStyle={{ marginTop: 32 }}
        title={I18n.get('challenge.delegation.result.risktaking.normal_distribution_curve.title')}
        percent={Math.round(score_risk)}
        description={`${
          score_risk <= 30 // eslint-disable-line
            ? I18n.get('challenge.delegation.result.risktaking_mean_percent_1')
            : score_risk > 30 && score_risk < 70
            ? I18n.get('challenge.delegation.result.risktaking_mean_percent_2')
            : I18n.get('challenge.delegation.result.risktaking_mean_percent_3')
        }`}
        labels={{
          left: I18n.get(
            'challenge.delegation.result.risktaking.normal_distribution_curve.leftskala'
          ),
          right: I18n.get(
            'challenge.delegation.result.risktaking.normal_distribution_curve.rightskala'
          )
        }}
      />
      <NormalDistributionCurve
        containerStyle={{ marginTop: 16 }}
        title={I18n.get(
          'challenge.delegation.result.responsibility.normal_distribution_curve.title'
        )}
        percent={Math.round(score_responsibility)}
        description={`${
          score_responsibility <= 30 // eslint-disable-line
            ? I18n.get('challenge.delegation.result.responsibility_mean_percent_1')
            : score_responsibility > 30 && score_responsibility < 70
            ? I18n.get('challenge.delegation.result.responsibility_mean_percent_2')
            : I18n.get('challenge.delegation.result.responsibility_mean_percent_3')
        }`}
        labels={{
          left: I18n.get(
            'challenge.delegation.result.responsibility.normal_distribution_curve.leftskala'
          ),
          right: I18n.get(
            'challenge.delegation.result.responsibility.normal_distribution_curve.rightskala'
          )
        }}
      />
      <JobMatch
        exam_id='DELEGATION'
        score={Math.round(score_responsibility)}
        containerStyle={{ marginTop: 16 }}
      />

      <ScienceText containerStyle={{ marginTop: 16 }} exam_id='DELEGATION' />
    </View>
  )
}

DelegationResult.propTypes = {
  answer: PropTypes.object.isRequired
}

export default DelegationResult
