export const FALLBACK_PARAM = { partner: 'af2dabfc-f3ff-43a1-80cd-53ebf3da38d1', NO_PARAMS: true }

// DEMO PARAMS FOR ATTRACT & SELECT. Comment Out/In if needed and restart app
// export const FALLBACK_PARAM = { partner: 'bei32464-06d4-4caa-900f-2812223db310', NO_PARAMS: false } // BEIERSDORF AIVY ATTRACT
// export const FALLBACK_PARAM = { partner: 'fre3abfc-f3ff-43a1-80cd-53ebf3da38d1', NO_PARAMS: false } // Fresenius AIVY ATTRACT

// export const FALLBACK_PARAM = {
//  invitation: '278f9bfa-c216-4a65-84d1-e8be630bb506',
//  NO_PARAMS: true
// }

/**
 * VALID URL LINK PARAMS FOR AIVY ASSESSMENT
 */
export default {
  CAREER: 'career',
  INVITATION: 'invitation',
  PARTNER: 'partner',
  USER: 'user'
}
