import React, { useContext, useEffect, useRef, useState } from 'react'
import { SafeAreaView, StatusBar, ActivityIndicator, View } from 'react-native'
import { Asset } from 'expo-asset'
import PropTypes from 'prop-types'

import Intro from '../../../components/challenge/Intro'
import Instruction from '../../../components/challenge/Instruction'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import Countdown from '../../../components/challenge/Countdown'
import ChallengeContainer from '../ChallengeContainer'
import Level from './Level'
import { getTestLvlForRnd, getLvlForRnd } from './data/path_finder'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import colors from '../../../theme/colors'
import { AuthContext } from '../../../context'
import { notifyBugsnag } from '../../../utils/bugsnag'

const PathFinder = ({ testRound, submit }) => {
  const { rootStore } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(true)
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0)

  const levels = useRef(testRound ? getTestLvlForRnd() : getLvlForRnd())

  const answers = useRef([])

  useEffect(() => {
    // const [{ localUri }] = await Asset.loadAsync(require('./assets/snack-icon.png'))
    let isMounted = true

    Asset.loadAsync([
      require('../../../assets/challenges/path-finder/game-assets/pathfinder1.png'),
      require('../../../assets/challenges/path-finder/game-assets/pathfinder2.png'),
      require('../../../assets/challenges/path-finder/game-assets/pathfinder3.png'),
      require('../../../assets/challenges/path-finder/game-assets/pathfinder1success.png'),
      require('../../../assets/challenges/path-finder/game-assets/pathfinder2success.png'),
      require('../../../assets/challenges/path-finder/game-assets/pathfinder3success.png'),
      require('../../../assets/challenges/path-finder/game-assets/pathfinder1blocked.png'),
      require('../../../assets/challenges/path-finder/game-assets/pathfinder2blocked.png'),
      require('../../../assets/challenges/path-finder/game-assets/pathfinder3blocked.png'),
      require('../../../assets/challenges/path-finder/game-assets/timer.png'),
      require('../../../assets/challenges/path-finder/game-assets/instruction.png')
    ])
      .then(() => isMounted && setIsLoading(false))
      .catch((err) => notifyBugsnag(err))

    return () => {
      isMounted = false
    }
  }, [])

  const submitAnswers = () => {
    submit(
      answers.current.reduce((result, answer, idx) => {
        result[`level_${idx + 1}`] = answer

        return result
      }, {})
    )
  }

  const onLevelComplete = (level, answer) => {
    if (!testRound) {
      answers.current = answers.current.concat([
        {
          id: level.id,
          pause_in_ms: rootStore.pauseStore.sum,
          ...answer
        }
      ])
    }

    if (currentLevelIndex === levels.current.length - 1) {
      submitAnswers()
    } else {
      setCurrentLevelIndex(currentLevelIndex + 1)
    }

    rootStore.pauseStore.reset()
  }

  if (isLoading) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: '#fff',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row'
        }}
      >
        <ActivityIndicator style={{ marginRight: 4 }} />
      </View>
    )
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.darkBlack }}>
      <StatusBar barStyle='light-content' animated />
      <Level
        key={levels.current[currentLevelIndex].id}
        currentLevelIndex={currentLevelIndex}
        level={levels.current[currentLevelIndex]}
        onLevelComplete={(data) => onLevelComplete(levels.current[currentLevelIndex], data)}
        testRound={testRound}
      />
    </SafeAreaView>
  )
}

PathFinder.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

IntroScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

TestRoundCompleteScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  prevScreen: PropTypes.func.isRequired
}

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

InstructionScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const CountdownScreen = ({ nextScreen }) => <Countdown onPress={nextScreen} />

CountdownScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired
}

const PathFinderChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={PathFinder}
    EXAM={ALL_CHALLENGES.PATH_FINDER}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: InstructionScreen, name: 'InstructionScreen' },
      { screen: CountdownScreen, name: 'CountdownScreen' }
    ]}
    MIDDLE_SCREENS={[
      { screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' },
      { screen: CountdownScreen, name: 'CountdownScreen' }
    ]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

PathFinderChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

PathFinderChallenge.defaultProps = { onChallengeComplete: () => {} }

export default PathFinderChallenge
