import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { View, StyleSheet, Image, ScrollView, TouchableOpacity } from 'react-native'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Slider } from '@miblanchard/react-native-slider'
import { useKeyboard } from '@react-native-community/hooks'

import { AivyTextInput, Button, ChatBubble, ModalHeader, Typo } from '../../../../components'
import colors from '../../../../theme/colors'
import { filterCities } from '../../../../utils/cities'
import { AuthContext } from '../../../../context'
import hardFacts from '../../../../constants/hard-facts'
import { notifyBugsnag } from '../../../../utils/bugsnag'
import { updateEndpoint } from '../../../../utils/analytics'
import { trackEvent } from '../../../../utils/tracking'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingHorizontal: 16,
    paddingBottom: 40
  },
  modalSheet: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    shadowColor: colors.darkBlue,
    shadowOffset: {
      width: 0, // x
      height: -12 // y
    },
    shadowOpacity: 0.1, // alpha
    shadowRadius: 33, // blur
    elevation: 8,
    paddingHorizontal: 24
  },
  thumbStyle: {
    backgroundColor: colors.white,
    width: 36,
    height: 36,
    borderRadius: 18,
    shadowColor: colors.softGrey,
    shadowOffset: {
      width: 0, // x
      height: 2 // y
    },
    shadowOpacity: 1, // alpha
    shadowRadius: 4, // blur
    elevation: 8
  },
  imageWrapperStyle: {
    height: 60,
    width: 60,
    borderRadius: 60 / 2,
    marginTop: 12,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: colors.lightGrey
  },
  imageStyle: {
    height: 38,
    width: 38,
    resizeMode: 'contain'
  }
})

const AivyImageBubble = () => (
  <View style={styles.imageWrapperStyle}>
    <Image style={styles.imageStyle} source={require('../../../../assets/images/aivy-logo.png')} />
  </View>
)

const start = [
  {
    message: 'hard_facts.message.1',
    channel: 'user'
  },
  {
    message: 'hard_facts.message.2',
    channel: 'aivy'
  },
  {
    message: 'hard_facts.message.3',
    channel: 'aivy'
  },
  {
    aivyImage: true
  }
]

const HardFacts = ({ navigation, route }) => {
  const keyboard = useKeyboard()
  const scrollView = useRef()
  const aivyTextInput = useRef()
  const { rootStore } = useContext(AuthContext)

  const [state, setState] = useState('LOCATION')
  const [updatingHardFacts, setUpdatingHardFacts] = useState('INITIAL')
  const [chat, setChat] = useState(start)
  const [cityInput, setCityInput] = useState('')
  const [selectedCity, setSelectedCity] = useState(null)
  const [citySuggestions, setCitySuggestions] = useState([])
  const [distance, setDistance] = useState(50)
  const [isCityListVisible, setIsCityListVisible] = useState(false)
  const [workExperience, setWorkExperience] = useState(null)
  const [remote, setRemote] = useState(null)

  const workExperienceTypes = useMemo(() => hardFacts.workExperienceTypes, [])
  const remoteTypes = useMemo(() => hardFacts.remoteTypes, [])

  const confirmLocation = () => {
    setChat((c) =>
      c.concat([
        {
          message: 'hard_facts.message.4',
          channel: 'user'
        },
        {
          message: `${selectedCity.name} +${distance}km`,
          channel: 'user'
        },
        {
          message: 'hard_facts.message.5',
          channel: 'aivy'
        },
        {
          aivyImage: true
        }
      ])
    )
    setState('REMOTE')
  }

  const confirmRemote = ({ key, value }) => {
    setChat((c) =>
      c.concat([
        {
          message: value,
          channel: 'user'
        },
        {
          message: 'hard_facts.message.6',
          channel: 'aivy'
        },
        {
          aivyImage: true
        }
      ])
    )
    setRemote(key)
    setState('WORK_EXPERIENCE')
  }

  const confirmWorkExperience = ({ key, value }) => {
    setChat((c) =>
      c.concat([
        {
          message: value,
          channel: 'user'
        },
        {
          message: 'hard_facts.message.7',
          channel: 'aivy'
        },
        {
          aivyImage: true
        }
      ])
    )
    setWorkExperience(key)
    setState('DONE')
  }

  const postHardFacts = async () => {
    setUpdatingHardFacts('UPDATING')

    try {
      await rootStore.userStore.updateUser({
        id: rootStore.userStore.username,
        location: {
          lat: parseFloat(selectedCity.latitude),
          lon: parseFloat(selectedCity.longitude),
          city: selectedCity.displayName
        },
        reach_settings: {
          searching_distance: distance
        },
        work_experience: workExperience,
        remote
      })

      updateEndpoint({
        userStore: rootStore.userStore,
        userAttributes: {
          searching_distance: [`${distance}`],
          work_experience: [`${workExperience}`],
          remote: [`${remote}`],
          country: [`${selectedCity.countryCode}`],
          city: [`${selectedCity.asciiname}`],
          latitude: [`${parseFloat(selectedCity.latitude)}`],
          longitude: [`${parseFloat(selectedCity.longitude)}`]
        },
        location: {
          country: selectedCity.countryCode,
          city: selectedCity.asciiname,
          latitude: parseFloat(selectedCity.latitude),
          longitude: parseFloat(selectedCity.longitude)
        }
      })

      setUpdatingHardFacts('DONE')
      trackEvent('HardFactsStored')
    } catch (err) {
      notifyBugsnag(err)
      setUpdatingHardFacts('INITIAL')
    }
  }

  useEffect(() => {
    if (updatingHardFacts === 'DONE') {
      setTimeout(() => {
        route.params && route.params.goToMain && navigation.navigate('Main')
        !route.params && navigation.goBack()
      }, 1000 * 1.5)
    }
  }, [updatingHardFacts, navigation, route])

  useEffect(() => {
    setCitySuggestions(filterCities(cityInput))
    !cityInput.length && setSelectedCity(null)
  }, [cityInput])

  return (
    <View style={styles.container}>
      <ModalHeader
        headerTitle='global.my_account.hard_facts'
        onPress={() => navigation.goBack()}
        withBackButton
      />
      <ScrollView
        ref={scrollView}
        onContentSizeChange={() => scrollView.current.scrollToEnd({ animated: true })}
        snapToEnd
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View style={styles.contentContainer}>
          {chat.map(({ message, channel, aivyImage }, index) => (
            <View key={index} style={{ alignItems: 'flex-start' }}>
              {aivyImage && <AivyImageBubble />}
              {message && (
                <ChatBubble
                  key={index}
                  message={message}
                  channel={channel}
                  containerStyle={{ marginTop: 12 }}
                />
              )}
            </View>
          ))}

          {state === 'REMOTE' && (
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                paddingLeft: 8
              }}
            >
              {remoteTypes.map(({ key, value }) => (
                <Button
                  key={key}
                  title={value}
                  type='outline'
                  backgroundColor='#fff'
                  marginHorizontal={16}
                  containerStyle={{ marginRight: 8, marginTop: 8 }}
                  onPress={() => confirmRemote({ key, value })}
                />
              ))}
            </View>
          )}

          {state === 'WORK_EXPERIENCE' && (
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                paddingLeft: 8
              }}
            >
              {workExperienceTypes.map(({ key, value }) => (
                <Button
                  key={key}
                  title={value}
                  type='outline'
                  backgroundColor='#fff'
                  marginHorizontal={16}
                  containerStyle={{ marginLeft: 8, marginTop: 8 }}
                  onPress={() => confirmWorkExperience({ key, value })}
                  centerText
                />
              ))}
            </View>
          )}

          {state === 'DONE' && (
            <View style={{ paddingHorizontal: 8, paddingTop: 24, width: '100%' }}>
              <Button
                title={updatingHardFacts === 'DONE' ? 'hard_facts.done_success' : 'hard_facts.done'}
                onPress={postHardFacts}
                isLoading={updatingHardFacts === 'UPDATING'}
                backgroundColor={updatingHardFacts === 'DONE' ? colors.buttonGreen : undefined}
              />
            </View>
          )}
        </View>
      </ScrollView>

      {state === 'LOCATION' && (
        <View
          style={[
            styles.modalSheet,
            { paddingBottom: keyboard.keyboardShown ? keyboard.keyboardHeight : 40 }
          ]}
        >
          <Typo.H2Black style={{ marginTop: 24 }} center translate>
            hard_facts.location_modal_title
          </Typo.H2Black>
          <AivyTextInput
            ref={aivyTextInput}
            containerStyle={{ marginTop: 16 }}
            label='hard_facts.location_input_label'
            onChangeText={(value) => setCityInput(value)}
            value={cityInput}
            icon={require('../../../../assets/images/location-icon.png')}
            autoComplete='off'
            autoFocus={false}
            onFocus={() => setIsCityListVisible(true)}
          />
          {isCityListVisible && (
            <View style={{ marginTop: 16, minHeight: 54 * 5 }}>
              {citySuggestions.map((city, index) => (
                <TouchableOpacity
                  key={index}
                  style={{ justifyContent: 'center', height: 54 }}
                  onPress={() => {
                    aivyTextInput.current && aivyTextInput.current.blur()
                    setIsCityListVisible(false)
                    setCityInput(city.displayName)
                    setSelectedCity(city)
                  }}
                >
                  <Typo.T1Black>{city.displayName}</Typo.T1Black>
                </TouchableOpacity>
              ))}
            </View>
          )}
          {!isCityListVisible && (
            <>
              <View style={{ marginTop: 52 }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typo.T1Black translate>hard_facts.search_distance_label</Typo.T1Black>
                  <Typo.H4Black>{`+ ${distance}km`}</Typo.H4Black>
                </View>
                <Slider
                  containerStyle={{ marginTop: 16 }}
                  minimumValue={10}
                  maximumValue={100}
                  step={5}
                  value={distance}
                  minimumTrackTintColor={colors.brightBlue}
                  maximumTrackTintColor={colors.lightGrey}
                  thumbStyle={styles.thumbStyle}
                  trackStyle={{ height: 2, borderRadius: 2 }}
                  onValueChange={(value) => setDistance(value[0])}
                  animateTransitions
                />
              </View>
              <Button
                title='hard_facts.location_confirm'
                containerStyle={{ marginTop: 42 }}
                onPress={confirmLocation}
                disabled={!selectedCity}
              />
            </>
          )}
        </View>
      )}
    </View>
  )
}

HardFacts.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
}

export default observer(HardFacts)

/* 
<BlurView
  style={{
    zIndex: 1,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 48 + insets.top,
    display: 'flex',
    justifyContent: 'flex-end',
    borderBottomWidth: 0.5,
    borderBottomColor: colors.mediumGrey
  }}
  intensity={32}
  tint='default'
>
*/
