import React, { useContext } from 'react'
import { View, StyleSheet, Image, SafeAreaView } from 'react-native'
import { I18n } from 'aws-amplify'

import { observer } from 'mobx-react'
import colors from '../theme/colors'
import Typo from './Typo'
import metrics from '../theme/metrics'
import { AuthContext } from '../context'
import { replaceTranslationKey } from '../utils/helpers'

const regexCompanyName = '{{{companyName}}}'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightPaleGrey,
    paddingHorizontal: 24
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const InvitationExpired = () => {
  const { rootStore } = useContext(AuthContext)

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.contentContainer}>
        <Typo.H2Blue>{I18n.get('invitation_expired')}</Typo.H2Blue>
        <Image
          style={{
            width: metrics.widthPercentageToDP(41),
            height: metrics.widthPercentageToDP(33),
            resizeMode: 'contain',
            marginTop: 35,
            marginBottom: 35
          }}
          source={require('../assets/images/illustrations/error-people.png')}
        />
        <View style={{ width: '80%' }}>
          <Typo.T1LightBlack center>
            {replaceTranslationKey(
              I18n.get('invitation_expired_desc'),
              regexCompanyName,
              rootStore.appSettingsStore.partnerDisplayName
            )}
          </Typo.T1LightBlack>
        </View>
      </View>
    </SafeAreaView>
  )
}

export default observer(InvitationExpired)
