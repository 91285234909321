import React, { useState } from 'react'
import { StyleSheet, SafeAreaView, ScrollView, View, Image } from 'react-native'
import { I18n } from 'aws-amplify'
import metrics from '../../theme/metrics'
import { Typo } from '../../components'
import colors from '../../theme/colors'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  slide: {
    paddingHorizontal: 24
  },
  footer: {
    width: '100%',
    height: 130,
    position: 'absolute',
    bottom: 94,
    alignSelf: 'center'
  },
  paginationWrapper: {
    position: 'absolute',
    bottom: 200,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  paginationDots: {
    height: 2,
    width: 16,
    backgroundColor: colors.primaryBlue,
    marginLeft: 8
  }
})

const OnboardingSlider = () => {
  const { screenHeight: height, screenWidth: width } = metrics
  const [sliderState, setSliderState] = useState({ currentPage: 0 })
  const SLIDES = [
    {
      title: I18n.get('onboarding.first.title'),
      subTitle: I18n.get('onboarding.first.subTitle'),
      imageSource: require('../../assets/images/onboarding/slide-01.png'),
      imageStyle: {
        ...metrics.calculateResponsiveDimensions({ width, height: 388 }),
        position: 'absolute',
        top: 0
      }
    },
    {
      title: I18n.get('onboarding.second.title'),
      subTitle: I18n.get('onboarding.second.subTitle'),
      imageSource: require('../../assets/images/onboarding/slide-02.png'),
      imageStyle: {
        ...metrics.calculateResponsiveDimensions({ width: 239, height: 268 }),
        alignSelf: 'center',
        position: 'absolute',
        top: metrics.heightPercentageToDP(15)
      }
    },
    {
      title: I18n.get('onboarding.third.title'),
      subTitle: I18n.get('onboarding.third.subTitle'),
      imageSource: require('../../assets/images/onboarding/slide-03.png'),
      imageStyle: {
        ...metrics.calculateResponsiveDimensions({ width: 209, height: 232 }),
        position: 'absolute',
        alignSelf: 'center',
        top: metrics.heightPercentageToDP(15)
      }
    }
  ]

  const setSliderPage = (event) => {
    const { currentPage } = sliderState
    const { x } = event.nativeEvent.contentOffset
    const indexOfNextScreen = Math.floor(x / width)
    if (indexOfNextScreen !== currentPage) {
      setSliderState({
        ...sliderState,
        currentPage: indexOfNextScreen
      })
    }
  }

  const { currentPage: pageIndex } = sliderState

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        style={{ flex: 1 }}
        horizontal
        scrollEventThrottle={16}
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onScroll={(e) => {
          setSliderPage(e)
        }}
      >
        <View style={[styles.slide, { width, height }]}>
          <Image source={SLIDES[0].imageSource} style={SLIDES[0].imageStyle} />
          <View style={styles.footer}>
            <Typo.H2Blue center>{SLIDES[0].title}</Typo.H2Blue>
            <Typo.T1LightBlack center marginTop={15}>
              {SLIDES[0].subTitle}
            </Typo.T1LightBlack>
          </View>
        </View>
        <View style={[styles.slide, { width, height }]}>
          <Image source={SLIDES[1].imageSource} style={SLIDES[1].imageStyle} />
          <View style={styles.footer}>
            <Typo.H2Blue center>{SLIDES[1].title}</Typo.H2Blue>
            <Typo.T1LightBlack marginTop={15} center>
              {SLIDES[1].subTitle}
            </Typo.T1LightBlack>
          </View>
        </View>
        <View style={[styles.slide, { width, height }]}>
          <Image source={SLIDES[2].imageSource} style={SLIDES[2].imageStyle} />
          <View style={styles.footer}>
            <Typo.H2Blue center>{SLIDES[2].title}</Typo.H2Blue>
            <Typo.T1LightBlack marginTop={15} center>
              {SLIDES[2].subTitle}
            </Typo.T1LightBlack>
          </View>
        </View>
      </ScrollView>
      <View style={styles.paginationWrapper}>
        {SLIDES.map((key, index) => (
          <View
            style={[styles.paginationDots, { opacity: pageIndex === index ? 1 : 0.3 }]}
            key={index}
          />
        ))}
      </View>
    </SafeAreaView>
  )
}

// OnboardingSlider.propTypes = {
//   navigation: PropTypes.object.isRequired
// }

export default OnboardingSlider
