import _ from 'lodash'

export const correctText = {
  title: 'immediate_feedback.correct',
  subTitle: ['immediate_feedback.very_good']
}

export const falseText = {
  title: 'immediate_feedback.false',
  subTitle: ['immediate_feedback.keep_up']
}

/**
 * Itempool
 */
const ANALOGICAL_REASONING_ITEMS = [
  {
    id: 1,
    pair1_stimA: 'challenge.analogical_reasonning.1.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.1.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.1.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.1.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.82,
    rated_difficulty: 3.53
  },
  {
    id: 2,
    pair1_stimA: 'challenge.analogical_reasonning.2.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.2.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.2.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.2.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.81,
    rated_difficulty: 3.82
  },
  {
    id: 3,
    pair1_stimA: 'challenge.analogical_reasonning.3.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.3.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.3.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.3.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.89,
    rated_difficulty: 4.55
  },
  {
    id: 4,
    pair1_stimA: 'challenge.analogical_reasonning.4.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.4.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.4.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.4.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.92,
    rated_difficulty: 3.14
  },
  {
    id: 5,
    pair1_stimA: 'challenge.analogical_reasonning.5.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.5.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.5.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.5.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.81,
    rated_difficulty: 4.43
  },
  {
    id: 6,
    pair1_stimA: 'challenge.analogical_reasonning.6.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.6.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.6.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.6.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.94,
    rated_difficulty: 2.8
  },
  {
    id: 7,
    pair1_stimA: 'challenge.analogical_reasonning.7.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.7.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.7.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.7.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.88,
    rated_difficulty: 3.97
  },
  {
    id: 8,
    pair1_stimA: 'challenge.analogical_reasonning.8.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.8.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.8.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.8.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.99,
    rated_difficulty: 3.87
  },
  {
    id: 9,
    pair1_stimA: 'challenge.analogical_reasonning.9.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.9.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.9.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.9.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.88,
    rated_difficulty: 4.23
  },
  {
    id: 10,
    pair1_stimA: 'challenge.analogical_reasonning.10.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.10.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.10.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.10.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.92,
    rated_difficulty: 3.47
  },
  {
    id: 11,
    pair1_stimA: 'challenge.analogical_reasonning.11.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.11.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.11.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.11.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.83,
    rated_difficulty: 4.16
  },
  {
    id: 12,
    pair1_stimA: 'challenge.analogical_reasonning.12.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.12.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.12.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.12.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.91,
    rated_difficulty: 2.54
  },
  {
    id: 13,
    pair1_stimA: 'challenge.analogical_reasonning.13.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.13.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.13.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.13.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.91,
    rated_difficulty: 3.39
  },
  {
    id: 14,
    pair1_stimA: 'challenge.analogical_reasonning.14.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.14.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.14.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.14.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.96,
    rated_difficulty: 3.73
  },
  {
    id: 15,
    pair1_stimA: 'challenge.analogical_reasonning.15.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.15.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.15.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.15.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.98,
    rated_difficulty: 4.54
  },
  {
    id: 16,
    pair1_stimA: 'challenge.analogical_reasonning.16.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.16.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.16.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.16.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.97,
    rated_difficulty: 5.78
  },
  {
    id: 17,
    pair1_stimA: 'challenge.analogical_reasonning.17.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.17.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.17.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.17.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.91,
    rated_difficulty: 3.77
  },
  {
    id: 18,
    pair1_stimA: 'challenge.analogical_reasonning.18.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.18.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.18.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.18.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.91,
    rated_difficulty: 6.04
  },
  {
    id: 19,
    pair1_stimA: 'challenge.analogical_reasonning.19.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.19.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.19.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.19.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.98,
    rated_difficulty: 5.5
  },
  {
    id: 20,
    pair1_stimA: 'challenge.analogical_reasonning.20.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.20.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.20.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.20.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.72,
    rated_difficulty: 3.72
  },
  {
    id: 21,
    pair1_stimA: 'challenge.analogical_reasonning.21.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.21.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.21.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.21.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.98,
    rated_difficulty: 4.52
  },
  {
    id: 22,
    pair1_stimA: 'challenge.analogical_reasonning.22.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.22.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.22.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.22.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.93,
    rated_difficulty: 3.82
  },
  {
    id: 23,
    pair1_stimA: 'challenge.analogical_reasonning.23.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.23.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.23.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.23.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.9,
    rated_difficulty: 2.07
  },
  {
    id: 24,
    pair1_stimA: 'challenge.analogical_reasonning.24.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.24.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.24.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.24.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.95,
    rated_difficulty: 4.28
  },
  {
    id: 25,
    pair1_stimA: 'challenge.analogical_reasonning.25.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.25.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.25.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.25.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.83,
    rated_difficulty: 3.68
  },
  {
    id: 26,
    pair1_stimA: 'challenge.analogical_reasonning.26.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.26.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.26.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.26.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.83,
    rated_difficulty: 3.98
  },
  {
    id: 27,
    pair1_stimA: 'challenge.analogical_reasonning.27.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.27.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.27.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.27.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.95,
    rated_difficulty: 6.74
  },
  {
    id: 28,
    pair1_stimA: 'challenge.analogical_reasonning.28.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.28.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.28.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.28.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.89,
    rated_difficulty: 5.44
  },
  {
    id: 29,
    pair1_stimA: 'challenge.analogical_reasonning.29.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.29.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.29.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.29.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.9,
    rated_difficulty: 6.94
  },
  {
    id: 30,
    pair1_stimA: 'challenge.analogical_reasonning.30.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.30.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.30.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.30.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.93,
    rated_difficulty: 4.92
  },
  {
    id: 31,
    pair1_stimA: 'challenge.analogical_reasonning.31.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.31.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.31.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.31.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.9,
    rated_difficulty: 6.78
  },
  {
    id: 32,
    pair1_stimA: 'challenge.analogical_reasonning.32.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.32.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.32.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.32.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.97,
    rated_difficulty: 3.85
  },
  {
    id: 33,
    pair1_stimA: 'challenge.analogical_reasonning.33.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.33.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.33.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.33.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.89,
    rated_difficulty: 5.06
  },
  {
    id: 34,
    pair1_stimA: 'challenge.analogical_reasonning.34.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.34.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.34.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.34.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.97,
    rated_difficulty: 3.73
  },
  {
    id: 35,
    pair1_stimA: 'challenge.analogical_reasonning.35.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.35.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.35.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.35.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.98,
    rated_difficulty: 3.4
  },
  {
    id: 36,
    pair1_stimA: 'challenge.analogical_reasonning.36.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.36.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.36.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.36.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.85,
    rated_difficulty: 3.36
  },
  {
    id: 37,
    pair1_stimA: 'challenge.analogical_reasonning.37.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.37.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.37.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.37.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.86,
    rated_difficulty: 6.23
  },
  {
    id: 38,
    pair1_stimA: 'challenge.analogical_reasonning.38.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.38.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.38.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.38.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.99,
    rated_difficulty: 3.73
  },
  {
    id: 39,
    pair1_stimA: 'challenge.analogical_reasonning.39.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.39.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.39.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.39.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.86,
    rated_difficulty: 4.6
  },
  {
    id: 40,
    pair1_stimA: 'challenge.analogical_reasonning.40.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.40.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.40.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.40.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.99,
    rated_difficulty: 4.21
  },
  {
    id: 41,
    pair1_stimA: 'challenge.analogical_reasonning.41.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.41.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.41.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.41.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.55,
    rated_difficulty: 3.55
  },
  {
    id: 42,
    pair1_stimA: 'challenge.analogical_reasonning.42.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.42.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.42.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.42.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.34,
    rated_difficulty: 2.42
  },
  {
    id: 43,
    pair1_stimA: 'challenge.analogical_reasonning.43.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.43.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.43.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.43.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.91,
    rated_difficulty: 2.22
  },
  {
    id: 44,
    pair1_stimA: 'challenge.analogical_reasonning.44.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.44.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.44.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.44.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.54,
    rated_difficulty: 1.69
  },
  {
    id: 45,
    pair1_stimA: 'challenge.analogical_reasonning.45.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.45.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.45.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.45.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.57,
    rated_difficulty: 2.27
  },
  {
    id: 46,
    pair1_stimA: 'challenge.analogical_reasonning.46.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.46.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.46.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.46.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.6,
    rated_difficulty: 2.83
  },
  {
    id: 47,
    pair1_stimA: 'challenge.analogical_reasonning.47.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.47.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.47.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.47.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.57,
    rated_difficulty: 3.8
  },
  {
    id: 48,
    pair1_stimA: 'challenge.analogical_reasonning.48.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.48.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.48.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.48.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.78,
    rated_difficulty: 3.68
  },
  {
    id: 49,
    pair1_stimA: 'challenge.analogical_reasonning.49.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.49.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.49.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.49.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.52,
    rated_difficulty: 2.83
  },
  {
    id: 50,
    pair1_stimA: 'challenge.analogical_reasonning.50.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.50.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.50.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.50.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.59,
    rated_difficulty: 3.56
  },
  {
    id: 51,
    pair1_stimA: 'challenge.analogical_reasonning.51.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.51.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.51.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.51.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.85,
    rated_difficulty: 2.49
  },
  {
    id: 52,
    pair1_stimA: 'challenge.analogical_reasonning.52.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.52.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.52.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.52.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.61,
    rated_difficulty: 2.47
  },
  {
    id: 53,
    pair1_stimA: 'challenge.analogical_reasonning.53.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.53.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.53.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.53.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.41,
    rated_difficulty: 1.49
  },
  {
    id: 54,
    pair1_stimA: 'challenge.analogical_reasonning.54.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.54.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.54.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.54.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.56,
    rated_difficulty: 2.1
  },
  {
    id: 55,
    pair1_stimA: 'challenge.analogical_reasonning.55.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.55.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.55.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.55.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.43,
    rated_difficulty: 2.64
  },
  {
    id: 56,
    pair1_stimA: 'challenge.analogical_reasonning.56.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.56.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.56.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.56.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.47,
    rated_difficulty: 4.78
  },
  {
    id: 57,
    pair1_stimA: 'challenge.analogical_reasonning.57.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.57.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.57.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.57.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.75,
    rated_difficulty: 2.01
  },
  {
    id: 58,
    pair1_stimA: 'challenge.analogical_reasonning.58.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.58.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.58.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.58.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.81,
    rated_difficulty: 3.12
  },
  {
    id: 59,
    pair1_stimA: 'challenge.analogical_reasonning.59.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.59.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.59.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.59.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.11,
    rated_difficulty: 2.3
  },
  {
    id: 60,
    pair1_stimA: 'challenge.analogical_reasonning.60.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.60.pair2_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.60.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.60.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.45,
    rated_difficulty: 2.32
  },
  {
    id: 61,
    pair1_stimA: 'challenge.analogical_reasonning.61.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.61.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.61.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.61.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.57,
    rated_difficulty: 2.75
  },
  {
    id: 62,
    pair1_stimA: 'challenge.analogical_reasonning.62.pair1_stimA',
    pair1_stimB: 'hallenge.analogical_reasonning.62.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.62.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.62.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.61,
    rated_difficulty: 1.45
  },
  {
    id: 63,
    pair1_stimA: 'challenge.analogical_reasonning.63.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.63.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.63.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.63.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.26,
    rated_difficulty: 2.95
  },
  {
    id: 64,
    pair1_stimA: 'challenge.analogical_reasonning.64.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.64.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.64.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.64.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.35,
    rated_difficulty: 2.7
  },
  {
    id: 65,
    pair1_stimA: 'hallenge.analogical_reasonning.65.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.65.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.65.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.65.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.44,
    rated_difficulty: 3.41
  },
  {
    id: 66,
    pair1_stimA: 'challenge.analogical_reasonning.66.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.66.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.66.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.66.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.35,
    rated_difficulty: 3.05
  },
  {
    id: 67,
    pair1_stimA: 'challenge.analogical_reasonning.67.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.67.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.67.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.67.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.43,
    rated_difficulty: 2.04
  },
  {
    id: 68,
    pair1_stimA: 'challenge.analogical_reasonning.68.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.68.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.68.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.68.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.44,
    rated_difficulty: 2.46
  },
  {
    id: 69,
    pair1_stimA: 'challenge.analogical_reasonning.69.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.69.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.69.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.69.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.68,
    rated_difficulty: 2.57
  },
  {
    id: 70,
    pair1_stimA: 'challenge.analogical_reasonning.70.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.70.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.70.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.70.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.41,
    rated_difficulty: 2.47
  },
  {
    id: 71,
    pair1_stimA: 'challenge.analogical_reasonning.71.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.71.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.71.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.71.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.48,
    rated_difficulty: 2.7
  },
  {
    id: 72,
    pair1_stimA: 'challenge.analogical_reasonning.72.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.72.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.72.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.72.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.81,
    rated_difficulty: 2.84
  },
  {
    id: 73,
    pair1_stimA: 'challenge.analogical_reasonning.73.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.73.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.73.pair1_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.73.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.62,
    rated_difficulty: 2.81
  },
  {
    id: 74,
    pair1_stimA: 'challenge.analogical_reasonning.74.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.74.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.74.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.74.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.56,
    rated_difficulty: 2.16
  },
  {
    id: 75,
    pair1_stimA: 'challenge.analogical_reasonning.75.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.75.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.75.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.75.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.83,
    rated_difficulty: 2.76
  },
  {
    id: 76,
    pair1_stimA: 'challenge.analogical_reasonning.76.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.76.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.76.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.76.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.18,
    rated_difficulty: 2.88
  },
  {
    id: 77,
    pair1_stimA: 'challenge.analogical_reasonning.77.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.77.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.77.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.77.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.54,
    rated_difficulty: 2.13
  },
  {
    id: 78,
    pair1_stimA: 'challenge.analogical_reasonning.78.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.78.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.78.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.78.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.69,
    rated_difficulty: 4.51
  },
  {
    id: 79,
    pair1_stimA: 'challenge.analogical_reasonning.79.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.79.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.79.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.79.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.43,
    rated_difficulty: 2.96
  },
  {
    id: 80,
    pair1_stimA: 'challenge.analogical_reasonning.80.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.80.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.80.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.80.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.75,
    rated_difficulty: 3.7
  },
  {
    id: 81,
    pair1_stimA: 'challenge.analogical_reasonning.81.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.81.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.81.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.81.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.84,
    rated_difficulty: 3.34
  },
  {
    id: 82,
    pair1_stimA: 'challenge.analogical_reasonning.82.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.82.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.82.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.82.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.81,
    rated_difficulty: 2.41
  },
  {
    id: 83,
    pair1_stimA: 'challenge.analogical_reasonning.83.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.83.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.83.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.83.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.85,
    rated_difficulty: 3.61
  },
  {
    id: 84,
    pair1_stimA: 'challenge.analogical_reasonning.84.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.84.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.84.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.84.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.5,
    rated_difficulty: 3.13
  },
  {
    id: 85,
    pair1_stimA: 'challenge.analogical_reasonning.85.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.85.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.85.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.85.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.71,
    rated_difficulty: 2.02
  },
  {
    id: 86,
    pair1_stimA: 'challenge.analogical_reasonning.86.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.86.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.86.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.86.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.85,
    rated_difficulty: 3.78
  },
  {
    id: 87,
    pair1_stimA: 'challenge.analogical_reasonning.87.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.87.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.87.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.87.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.79,
    rated_difficulty: 3.59
  },
  {
    id: 88,
    pair1_stimA: 'challenge.analogical_reasonning.88.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.88.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.88.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.88.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.66,
    rated_difficulty: 4.37
  },
  {
    id: 89,
    pair1_stimA: 'challenge.analogical_reasonning.89.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.89.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.89.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.89.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.85,
    rated_difficulty: 3.92
  },
  {
    id: 90,
    pair1_stimA: 'challenge.analogical_reasonning.90.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.90.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.90.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.90.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.81,
    rated_difficulty: 3.87
  },
  {
    id: 91,
    pair1_stimA: 'challenge.analogical_reasonning.91.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.91.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.91.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.91.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.63,
    rated_difficulty: 2.53
  },
  {
    id: 92,
    pair1_stimA: 'challenge.analogical_reasonning.92.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.92.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.92.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.92.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.33,
    rated_difficulty: 3.1
  },
  {
    id: 93,
    pair1_stimA: 'challenge.analogical_reasonning.93.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.93.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.93.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.93.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.59,
    rated_difficulty: 2.09
  },
  {
    id: 94,
    pair1_stimA: 'challenge.analogical_reasonning.94.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.94.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.94.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.94.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.71,
    rated_difficulty: 2.38
  },
  {
    id: 95,
    pair1_stimA: 'challenge.analogical_reasonning.95.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.95.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.95.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.95.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.96,
    rated_difficulty: 4.48
  },
  {
    id: 96,
    pair1_stimA: 'challenge.analogical_reasonning.96.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.96.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.96.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.96.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.84,
    rated_difficulty: 3.65
  },
  {
    id: 97,
    pair1_stimA: 'challenge.analogical_reasonning.97.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.97.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.97.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.97.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.97,
    rated_difficulty: 2.39
  },
  {
    id: 98,
    pair1_stimA: 'challenge.analogical_reasonning.98.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.98.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.98.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.98.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.98,
    rated_difficulty: 2.34
  },
  {
    id: 99,
    pair1_stimA: 'challenge.analogical_reasonning.99.pair1_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.99.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.99.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.99.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.98,
    rated_difficulty: 3.47
  },
  {
    id: 100,
    pair1_stimA: 'challenge.analogical_reasonning.100.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.100.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.100.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.100.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.92,
    rated_difficulty: 2.63
  },
  {
    id: 101,
    pair1_stimA: 'challenge.analogical_reasonning.101.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.101.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.101.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.101.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.9,
    rated_difficulty: 2.65
  },
  {
    id: 102,
    pair1_stimA: 'challenge.analogical_reasonning.102.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.102.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.102.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.102.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.94,
    rated_difficulty: 3.88
  },
  {
    id: 103,
    pair1_stimA: 'challenge.analogical_reasonning.103.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.103.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.103.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.103.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.8,
    rated_difficulty: 2.85
  },
  {
    id: 104,
    pair1_stimA: 'challenge.analogical_reasonning.104.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.104.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.104.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.104.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.38,
    rated_difficulty: 3.53
  },
  {
    id: 105,
    pair1_stimA: 'challenge.analogical_reasonning.105.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.105.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.105.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.105.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.69,
    rated_difficulty: 2.37
  },
  {
    id: 106,
    pair1_stimA: 'challenge.analogical_reasonning.106.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.106.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.106.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.106.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.76,
    rated_difficulty: 1.46
  },
  {
    id: 107,
    pair1_stimA: 'challenge.analogical_reasonning.107.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.107.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.107.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.107.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.93,
    rated_difficulty: 3.37
  },
  {
    id: 108,
    pair1_stimA: 'challenge.analogical_reasonning.100.pair2_stimB',
    pair1_stimB: 'challenge.analogical_reasonning.108.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.108.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.108.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.95,
    rated_difficulty: 5.79
  },
  {
    id: 109,
    pair1_stimA: 'challenge.analogical_reasonning.109.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.109.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.109.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.109.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.97,
    rated_difficulty: 2.5
  },
  {
    id: 110,
    pair1_stimA: 'challenge.analogical_reasonning.110.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.110.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.110.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.110.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.75,
    rated_difficulty: 2.62
  },
  {
    id: 111,
    pair1_stimA: 'challenge.analogical_reasonning.111.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.111.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.111.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.111.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.88,
    rated_difficulty: 4
  },
  {
    id: 112,
    pair1_stimA: 'challenge.analogical_reasonning.112.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.108.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.112.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.112.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.94,
    rated_difficulty: 5.96
  },
  {
    id: 113,
    pair1_stimA: 'challenge.analogical_reasonning.113.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.113.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.113.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.113.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.73,
    rated_difficulty: 2.5
  },
  {
    id: 114,
    pair1_stimA: 'challenge.analogical_reasonning.114.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.114.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.114.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.114.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.57,
    rated_difficulty: 3.45
  },
  {
    id: 115,
    pair1_stimA: 'challenge.analogical_reasonning.115.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.115.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.115.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.115.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.98,
    rated_difficulty: 2.16
  },
  {
    id: 116,
    pair1_stimA: 'challenge.analogical_reasonning.116.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.116.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.116.pair2_stimA',
    pair2_stimB: 'Kopichallenge.analogical_reasonning.116.pair2_stimBerer',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.99,
    rated_difficulty: 2.67
  },
  {
    id: 117,
    pair1_stimA: 'challenge.analogical_reasonning.117.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.117.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.117.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.117.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.92,
    rated_difficulty: 3.85
  },
  {
    id: 118,
    pair1_stimA: 'challenge.analogical_reasonning.118.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.118.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.118.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.118.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.96,
    rated_difficulty: 3.2
  },
  {
    id: 119,
    pair1_stimA: 'challenge.analogical_reasonning.119.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.119.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.119.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.119.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.69,
    rated_difficulty: 2.23
  },
  {
    id: 120,
    pair1_stimA: 'challenge.analogical_reasonning.120.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.120.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.120.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.120.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.78,
    rated_difficulty: 2.05
  }
]

export const QUESTIONS = _.shuffle([
  {
    id: 2,
    pair1_stimA: 'challenge.analogical_reasonning.2.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.2.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.2.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.2.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.81,
    rated_difficulty: 3.82
  },
  {
    id: 3,
    pair1_stimA: 'challenge.analogical_reasonning.3.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.3.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.3.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.3.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.89,
    rated_difficulty: 4.55
  },
  {
    id: 8,
    pair1_stimA: 'challenge.analogical_reasonning.8.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.8.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.8.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.8.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.99,
    rated_difficulty: 3.87
  },
  {
    id: 21,
    pair1_stimA: 'challenge.analogical_reasonning.21.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.21.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.21.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.21.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.98,
    rated_difficulty: 4.52
  },
  {
    id: 32,
    pair1_stimA: 'challenge.analogical_reasonning.32.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.32.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.32.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.32.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.97,
    rated_difficulty: 3.85
  },
  {
    id: 38,
    pair1_stimA: 'challenge.analogical_reasonning.38.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.38.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.38.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.38.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.99,
    rated_difficulty: 3.73
  },
  {
    id: 44,
    pair1_stimA: 'challenge.analogical_reasonning.44.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.44.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.44.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.44.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.54,
    rated_difficulty: 1.69
  },
  {
    id: 47,
    pair1_stimA: 'challenge.analogical_reasonning.47.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.47.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.47.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.47.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.57,
    rated_difficulty: 3.8
  },
  {
    id: 50,
    pair1_stimA: 'challenge.analogical_reasonning.50.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.50.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.50.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.50.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.59,
    rated_difficulty: 3.56
  },
  {
    id: 51,
    pair1_stimA: 'challenge.analogical_reasonning.51.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.51.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.51.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.51.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.85,
    rated_difficulty: 2.49
  },
  {
    id: 60,
    pair1_stimA: 'challenge.analogical_reasonning.60.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.60.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.60.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.60.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.45,
    rated_difficulty: 2.32
  },
  {
    id: 63,
    pair1_stimA: 'challenge.analogical_reasonning.63.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.63.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.63.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.63.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.26,
    rated_difficulty: 2.95
  },
  {
    id: 64,
    pair1_stimA: 'challenge.analogical_reasonning.64.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.64.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.64.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.64.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.35,
    rated_difficulty: 2.7
  },
  {
    id: 71,
    pair1_stimA: 'challenge.analogical_reasonning.71.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.71.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.71.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.71.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.48,
    rated_difficulty: 2.7
  },
  {
    id: 76,
    pair1_stimA: 'challenge.analogical_reasonning.76.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.76.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.76.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.76.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.18,
    rated_difficulty: 2.88
  },
  {
    id: 79,
    pair1_stimA: 'challenge.analogical_reasonning.79.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.79.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.79.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.79.pair2_stimB',
    correctness: true,
    stimulus_class: 'withindomain',
    semantic_distance: 0.43,
    rated_difficulty: 2.96
  },
  {
    id: 81,
    pair1_stimA: 'challenge.analogical_reasonning.81.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.81.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.81.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.81.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.84,
    rated_difficulty: 3.34
  },
  {
    id: 83,
    pair1_stimA: 'challenge.analogical_reasonning.83.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.83.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.83.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.83.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.85,
    rated_difficulty: 3.61
  },
  {
    id: 87,
    pair1_stimA: 'challenge.analogical_reasonning.87.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.87.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.87.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.87.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.79,
    rated_difficulty: 3.59
  },
  {
    id: 89,
    pair1_stimA: 'challenge.analogical_reasonning.89.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.89.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.89.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.89.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.85,
    rated_difficulty: 3.92
  },
  {
    id: 96,
    pair1_stimA: 'challenge.analogical_reasonning.96.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.96.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.96.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.96.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.84,
    rated_difficulty: 3.65
  },
  {
    id: 97,
    pair1_stimA: 'challenge.analogical_reasonning.97.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.97.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.97.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.97.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.97,
    rated_difficulty: 2.39
  },
  {
    id: 105,
    pair1_stimA: 'challenge.analogical_reasonning.105.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.105.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.105.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.105.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.69,
    rated_difficulty: 2.37
  },
  {
    id: 109,
    pair1_stimA: 'challenge.analogical_reasonning.109.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.109.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.109.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.109.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.97,
    rated_difficulty: 2.5
  },
  {
    id: 112,
    pair1_stimA: 'challenge.analogical_reasonning.112.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.108.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.112.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.112.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.94,
    rated_difficulty: 5.96
  },
  {
    id: 114,
    pair1_stimA: 'challenge.analogical_reasonning.114.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.114.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.114.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.114.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.57,
    rated_difficulty: 3.45
  },
  {
    id: 115,
    pair1_stimA: 'challenge.analogical_reasonning.115.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.115.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.115.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.115.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.98,
    rated_difficulty: 2.16
  },
  {
    id: 117,
    pair1_stimA: 'challenge.analogical_reasonning.117.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.117.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.117.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.117.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.92,
    rated_difficulty: 3.85
  },
  {
    id: 118,
    pair1_stimA: 'challenge.analogical_reasonning.118.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.118.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.118.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.118.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.96,
    rated_difficulty: 3.2
  },
  {
    id: 119,
    pair1_stimA: 'challenge.analogical_reasonning.119.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.119.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.119.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.119.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.69,
    rated_difficulty: 2.23
  }
])

export const TEST_QUESTIONS = [
  {
    id: 1,
    pair1_stimA: 'challenge.analogical_reasonning.1.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.1.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.1.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.1.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.82,
    rated_difficulty: 3.53
  },
  {
    id: 86,
    pair1_stimA: 'challenge.analogical_reasonning.86.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.86.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.86.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.86.pair2_stimB',
    correctness: false,
    stimulus_class: 'wronganalogy',
    semantic_distance: 0.85,
    rated_difficulty: 3.78
  },
  {
    id: 4,
    pair1_stimA: 'challenge.analogical_reasonning.4.pair1_stimA',
    pair1_stimB: 'challenge.analogical_reasonning.4.pair1_stimB',
    pair2_stimA: 'challenge.analogical_reasonning.4.pair2_stimA',
    pair2_stimB: 'challenge.analogical_reasonning.4.pair2_stimB',
    correctness: true,
    stimulus_class: 'crossdomain',
    semantic_distance: 0.92,
    rated_difficulty: 3.14
  }
]

export const NOT_USED_DATA = { ANALOGICAL_REASONING_ITEMS }
