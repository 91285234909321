import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import PropTypes from 'prop-types'

import AuthStack from './AuthStack'
import AppStack from './AppStack'

const RootStack = createNativeStackNavigator()

// { navigation }
const RootStackScreen = ({ isLoggedIn, isSignup }) => (
  <RootStack.Navigator
    screenOptions={{
      headerShown: false,
      animation: 'none'
    }}
  >
    {isLoggedIn ? (
      <RootStack.Screen name='App' component={AppStack} initialParams={{ isSignup }} />
    ) : (
      <RootStack.Screen name='Auth' component={AuthStack} />
    )}
  </RootStack.Navigator>
)

RootStackScreen.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isSignup: PropTypes.bool.isRequired
}

export default RootStackScreen
