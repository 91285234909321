import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    flex: 1
  }
})

const ContentWithFooter = ({
  content,
  footer,
  contentContainerStyle,
  footerContainerStyle,
  containerStyle
}) => (
  <View style={[styles.container, containerStyle]}>
    <View style={[styles.container, contentContainerStyle]}>{content}</View>
    <View style={footerContainerStyle}>{footer}</View>
  </View>
)

ContentWithFooter.propTypes = {
  content: PropTypes.element.isRequired,
  footer: PropTypes.element.isRequired,
  contentContainerStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  footerContainerStyle: PropTypes.object
}

ContentWithFooter.defaultProps = {
  containerStyle: {},
  contentContainerStyle: {},
  footerContainerStyle: {}
}

export default ContentWithFooter
