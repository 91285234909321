import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'
import Typo from '../Typo'
import colors from '../../theme/colors'
import { replaceTranslationKey, replaceTranslationKeys } from '../../utils/helpers'

const styles = StyleSheet.create({
  progressbar: {
    position: 'relative',
    marginTop: 8,
    height: 4,
    width: '100%',
    backgroundColor: colors.lightGrey,
    borderRadius: 2
  },
  completed: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: colors.primaryGreen,
    height: '100%',
    borderRadius: 2,
    shadowColor: colors.primaryGreen,
    shadowOffset: {
      width: 0, // x
      height: 0 // y
    },
    shadowOpacity: 0.42, // alpha
    shadowRadius: 10, // blur
    elevation: 8
  }
})

const Progressbar = ({ containerStyles, value, max }) => {
  const successMessage = () => {
    if (value === max) {
      return I18n.get('global.progressbar.all_data_submitted')
    }

    if (value === 1) {
      return I18n.get('global.progressbar.one_task_completed')
    }

    return replaceTranslationKey(I18n.get('global.progressbar.tasks_completed'), '{{tasks}}', value)
  }

  return (
    <View style={[containerStyles]}>
      <Typo.H4Black>
        {replaceTranslationKeys(
          I18n.get('global.progressbar.x_of_y_tasks_done'),
          ['{{value}}', '{{max}}'],
          [value, max]
        )}
      </Typo.H4Black>
      <View style={styles.progressbar}>
        <View style={[styles.completed, { width: `${(100 * value) / max}%` }]} />
      </View>
      {value > 0 && <Typo.T2Black style={{ marginTop: 4 }}>{successMessage()}</Typo.T2Black>}
    </View>
  )
}

Progressbar.propTypes = {
  containerStyles: PropTypes.object,
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
}

Progressbar.defaultProps = {
  containerStyles: {}
}

export default Progressbar
