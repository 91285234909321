import React from 'react'
import { View, StyleSheet, TouchableOpacity, Image, Platform } from 'react-native'
import PropTypes from 'prop-types'
import Typo from './Typo'
import colors from '../theme/colors'
import metrics from '../theme/metrics'

const styles = StyleSheet.create({
  container: {
    height: 280,
    width: '100%',
    maxWidth: 400,
    backgroundColor: colors.white,
    borderRadius: 18,
    paddingHorizontal: 16,
    paddingTop: 16,
    shadowColor: colors.mediumGrey,
    shadowOffset: {
      width: 0,
      height: 15
    },
    shadowOpacity: 0.2,
    shadowRadius: 28,
    elevation: 14
  },
  label: {
    alignSelf: 'flex-start',
    justifyContent: 'center',
    height: 32,
    paddingHorizontal: 12,
    backgroundColor: colors.paleGrey,
    borderRadius: 8
  },
  newTag: {
    alignSelf: 'flex-start',
    justifyContent: 'center',
    height: 32,
    paddingHorizontal: 12,
    backgroundColor: colors.carnationPink,
    borderRadius: 8
  },
  imageWrapperStyle: {
    position: 'absolute',
    left: 20,
    bottom: 20,
    height: 56,
    width: 56,
    borderRadius: 56 / 2,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: colors.lightGrey
  },
  partnerLogo: {
    width: 42,
    height: 42,
    resizeMode: Platform.OS === 'android' ? undefined : 'contain'
  },
  stackContainer: {
    position: 'absolute',
    zIndex: 1,
    bottom: 20,
    width: 40,
    height: 40,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: colors.white,
    backgroundColor: colors.white,
    shadowColor: colors.mediumGrey,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.15,
    shadowRadius: 15,
    elevation: 4
  },
  stack: {
    resizeMode: 'contain',
    width: 36,
    height: 36
  },
  illustration: {
    resizeMode: 'contain',
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: -1
  }
})

const WhiteListItem = ({
  context,
  newTag,
  newTagTitle,
  betaTag,
  header,
  event,
  partnerLogo,
  stack,
  illustration,
  containerStyle,
  onPress
}) => (
  <TouchableOpacity onPress={onPress} style={[styles.container, containerStyle]}>
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <View style={styles.label}>
        <Typo.T2Black>{context}</Typo.T2Black>
      </View>
      {newTag && (
        <View style={styles.newTag}>
          <Typo.H4White translate>{newTagTitle}</Typo.H4White>
        </View>
      )}
      {betaTag && (
        <View style={styles.newTag}>
          <Typo.H4White>Beta</Typo.H4White>
        </View>
      )}
    </View>

    <View style={{ marginTop: 8, paddingHorizontal: 4 }}>
      <Typo.H1Black>{header}</Typo.H1Black>
      {event && <Typo.T1Black style={{ marginTop: 2 }}>{event.time}</Typo.T1Black>}
      {event && <Typo.T1LightBlack>{event.street}</Typo.T1LightBlack>}
      {event && <Typo.T1LightBlack>{event.city}</Typo.T1LightBlack>}
    </View>

    {partnerLogo && (
      <View style={styles.imageWrapperStyle}>
        <Image style={styles.partnerLogo} source={partnerLogo} />
      </View>
    )}

    {stack &&
      stack.map((element, index) => {
        if (index < 3) {
          return (
            <View key={index} style={[styles.stackContainer, { left: 16 + index * 32 }]}>
              <Image style={styles.stack} source={element.image} />
              {element.initials && (
                <Typo.H4White center style={{ position: 'absolute', width: 40, top: 7 }}>
                  {element.initials}
                </Typo.H4White>
              )}
            </View>
          )
        }

        if (index === 3) {
          return (
            <View
              key={index}
              style={[
                styles.stackContainer,
                { left: 16 + index * 32, backgroundColor: colors.darkBlue },
                { justifyContent: 'center', alignItems: 'center', borderWidth: 0 }
              ]}
            >
              <Typo.H4White>{`+${stack.length - 3}`}</Typo.H4White>
            </View>
          )
        }

        return null
      })}

    <Image
      style={[
        styles.illustration,
        { ...metrics.calculateResponsiveDimensions(illustration), margin: illustration.margin }
      ]}
      source={illustration.src}
    />
  </TouchableOpacity>
)

WhiteListItem.propTypes = {
  context: PropTypes.string.isRequired,
  newTag: PropTypes.bool,
  newTagTitle: PropTypes.string,
  betaTag: PropTypes.bool,
  header: PropTypes.string.isRequired,
  event: PropTypes.object,
  partnerLogo: PropTypes.object,
  stack: PropTypes.array,
  illustration: PropTypes.object.isRequired,
  containerStyle: PropTypes.object,
  onPress: PropTypes.func.isRequired
}

WhiteListItem.defaultProps = {
  newTag: false,
  betaTag: false,
  event: undefined,
  partnerLogo: undefined,
  stack: undefined,
  containerStyle: {},
  newTagTitle: ''
}

export default WhiteListItem
