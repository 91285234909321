import { CAMPAIGN } from './campaign'
import { CAREER } from './career'
import { PARTNER } from './partner'

export const REACH_REQUEST = `{
  id
  seen
  clicked
  context
  career_url
  career_id
  career ${CAREER}
  partner_id
  partner ${PARTNER}
  campaign_id
  campaign ${CAMPAIGN}
  createdAt
  updatedAt
}`
