import _ from 'lodash'
import { I18n } from 'aws-amplify'

export const IMAGE_URL =
  'https://s3.eu-central-1.amazonaws.com/react-native-app-content/riasec-images/'
const CATEGORIES = ['R', 'I', 'A', 'S', 'E', 'C']
const ITEMS_PER_CATEGORY = 4

export const block_time_ms = 300

/**
 * Updated Questions for the new One Round Update based on briefing
 * https://docs.google.com/document/d/14XQm1MMXePovis5lqWo1yWwzrqFiJBvUkdOC10Q1arw/edit
 */
const RIASEC_QUESTIONS_ONE_ROUND = [
  {
    id: 4,
    photo: 'BID0104',
    title: I18n.get('challenge.riasec_images.header_question.BID0104'),
    value: 'R',
    median: -1089.0
  },
  {
    id: 73,
    photo: 'BID0702',
    title: I18n.get('challenge.riasec_images.header_question.BID0702'),
    value: 'R',
    median: -1923.0
  },
  {
    id: 15,
    photo: 'BID1304',
    title: I18n.get('challenge.riasec_images.header_question.BID1304'),
    value: 'R',
    median: 2649.0
  },
  {
    id: 35,
    photo: 'BID1902',
    title: I18n.get('challenge.riasec_images.header_question.BID1901'),
    value: 'R',
    median: -1793.0
  },
  {
    id: 49,
    photo: 'BID2501',
    title: I18n.get('challenge.riasec_images.header_question.BID2501'),
    value: 'R',
    median: -2828.0
  },
  {
    id: 56,
    photo: 'BID3104',
    title: I18n.get('challenge.riasec_images.header_question.BID3104'),
    value: 'R',
    median: -775.5
  },
  {
    id: 10,
    photo: 'BID0802',
    title: I18n.get('challenge.riasec_images.header_question.BID0802'),
    value: 'I',
    median: 2566.0
  },
  {
    id: 19,
    photo: 'BID1404',
    title: I18n.get('challenge.riasec_images.header_question.BID1404'),
    value: 'I',
    median: 3238.0
  },
  {
    id: 38,
    photo: 'BID2004',
    title: I18n.get('challenge.riasec_images.header_question.BID2002'),
    value: 'I',
    median: 3662.0
  },
  {
    id: 74,
    photo: 'BID2604',
    title: I18n.get('challenge.riasec_images.header_question.BID2604'),
    value: 'I',
    median: 3453.5
  },
  {
    id: 58,
    photo: 'BID3202',
    title: I18n.get('challenge.riasec_images.header_question.BID3202'),
    value: 'I',
    median: 3304.5
  },
  {
    id: 16,
    photo: 'BID1401',
    title: I18n.get('challenge.riasec_images.header_question.BID1401'),
    value: 'I',
    median: 3511.5
  },
  {
    id: 75,
    photo: 'BID0902',
    title: I18n.get('challenge.riasec_images.header_question.BID0902'),
    value: 'A',
    median: -3547.0
  },
  {
    id: 22,
    photo: 'BID1503',
    title: I18n.get('challenge.riasec_images.header_question.BID1503'),
    value: 'A',
    median: 2344.0
  },
  {
    id: 41,
    photo: 'BID2103',
    title: I18n.get('challenge.riasec_images.header_question.BID2103'),
    value: 'A',
    median: -1245.0
  },
  {
    id: 51,
    photo: 'BID2702',
    title: I18n.get('challenge.riasec_images.header_question.BID2702'),
    value: 'A',
    median: 2141.0
  },
  {
    id: 60,
    photo: 'BID3302',
    title: I18n.get('challenge.riasec_images.header_question.BID3302'),
    value: 'A',
    median: -2667.0
  },
  {
    id: 39,
    photo: 'BID2101',
    title: I18n.get('challenge.riasec_images.header_question.BID2102'),
    value: 'A',
    median: 1429.0
  },
  {
    id: 76,
    photo: 'BID3404',
    title: I18n.get('challenge.riasec_images.header_question.BID3404'),
    value: 'S',
    median: 2921.0
  },
  {
    id: 25,
    photo: 'BID1603',
    title: I18n.get('challenge.riasec_images.header_question.BID1603'),
    value: 'S',
    median: -3217.0
  },
  {
    id: 43,
    photo: 'BID2201',
    title: I18n.get('challenge.riasec_images.header_question.BID2201'),
    value: 'S',
    median: -2931.0
  },
  {
    id: 77,
    photo: 'BID2202',
    title: I18n.get('challenge.riasec_images.header_question.BID2202'),
    value: 'S',
    median: 1569.0
  },
  {
    id: 64,
    photo: 'BID3402',
    title: I18n.get('challenge.riasec_images.header_question.BID3402'),
    value: 'S',
    median: -2936.0
  },
  {
    id: 46,
    photo: 'BID2204',
    title: I18n.get('challenge.riasec_images.header_question.BID2204'),
    value: 'S',
    median: -2735.0
  },
  {
    id: 78,
    photo: 'BID1102',
    title: I18n.get('challenge.riasec_images.header_question.BID1102'),
    value: 'E',
    median: -1449.0
  },
  {
    id: 27,
    photo: 'BID1701',
    title: I18n.get('challenge.riasec_images.header_question.BID1701'),
    value: 'E',
    median: -3468.0
  },
  {
    id: 79,
    photo: 'BID2301',
    title: I18n.get('challenge.riasec_images.header_question.BID2301'),
    value: 'E',
    median: -3429.0
  },
  {
    id: 80,
    photo: 'BID3501',
    title: I18n.get('challenge.riasec_images.header_question.BID3501'),
    value: 'E',
    median: 2221.0
  },
  {
    id: 66,
    photo: 'BID3502',
    title: I18n.get('challenge.riasec_images.header_question.BID3502'),
    value: 'E',
    median: 1836.0
  },
  {
    id: 13,
    photo: 'BID1104',
    title: I18n.get('challenge.riasec_images.header_question.BID1104'),
    value: 'E',
    median: -1761.5
  },
  {
    id: 6,
    photo: 'BID0602',
    title: I18n.get('challenge.riasec_images.header_question.BID0602'),
    value: 'C',
    median: -2046.0
  },
  {
    id: 31,
    photo: 'BID1802',
    title: I18n.get('challenge.riasec_images.header_question.BID1802'),
    value: 'C',
    median: -3343.0
  },
  {
    id: 47,
    photo: 'BID2401',
    title: I18n.get('challenge.riasec_images.header_question.BID2401'),
    value: 'C',
    median: 1811.0
  },
  {
    id: 54,
    photo: 'BID3002',
    title: I18n.get('challenge.riasec_images.header_question.BID3002'),
    value: 'C',
    median: -3011.0
  },
  {
    id: 72,
    photo: 'BID3603',
    title: I18n.get('challenge.riasec_images.header_question.BID3603'),
    value: 'C',
    median: -1283.5
  },
  {
    id: 33,
    photo: 'BID1804',
    title: I18n.get('challenge.riasec_images.header_question.BID1804'),
    value: 'C',
    median: -3324.5
  }
]

export const getRandomMainQuestions = () => _.shuffle(RIASEC_QUESTIONS_ONE_ROUND)
// let R, I, A, S, E, C;
// R = _.filter(RIASEC_QUESTIONS, { value: "R" });
// I = _.filter(RIASEC_QUESTIONS, { value: "I" });
// A = _.filter(RIASEC_QUESTIONS, { value: "A" });
// S = _.filter(RIASEC_QUESTIONS, { value: "S" });
// E = _.filter(RIASEC_QUESTIONS, { value: "E" });
// C = _.filter(RIASEC_QUESTIONS, { value: "C" });

// return _.shuffle(
//     _.concat(
//         _.sampleSize(R, 4),
//         _.sampleSize(I, 4),
//         _.sampleSize(A, 4),
//         _.sampleSize(S, 4),
//         _.sampleSize(E, 4),
//         _.sampleSize(C, 4),
//     )
// )

export const RIASEC_TEST_QUESTIONS = [
  {
    id: 97,
    photo: 'BID1303',
    title: I18n.get('challenge.riasec_images.header_question.BID1303'),
    value: 'R',
    median: 0
  },
  {
    id: 98,
    photo: 'BID2904',
    title: I18n.get('challenge.riasec_images.header_question.BID2904'),
    value: 'E',
    median: 0
  },
  {
    id: 99,
    photo: 'BID3304',
    title: I18n.get('challenge.riasec_images.header_question.BID3304'),
    value: 'A',
    median: 0
  }
]

/**
 * All Riasec Questions
 */
const RIASEC_QUESTIONS = [
  {
    id: 1,
    photo: 'BID0101',
    title: I18n.get('challenge.riasec_images.header_question.BID0101'),
    value: 'R',
    median: -725.5
  },
  {
    id: 2,
    photo: 'BID0102',
    title: I18n.get('challenge.riasec_images.header_question.BID0102'),
    value: 'R',
    median: 368.0
  },
  {
    id: 3,
    photo: 'BID0103',
    title: I18n.get('challenge.riasec_images.header_question.BID0103'),
    value: 'R',
    median: -1928.0
  },
  {
    id: 4,
    photo: I18n.get('challenge.riasec_images.header_question.BID0104'),
    title: 'mit Werkzeugen arbeiten',
    value: 'R',
    median: -1089.0
  },
  {
    id: 5,
    photo: 'BID0404',
    title: I18n.get('challenge.riasec_images.header_question.BID0404'),
    value: 'S',
    median: 2631.0
  },
  {
    id: 6,
    photo: 'BID0602',
    title: I18n.get('challenge.riasec_images.header_question.BID0602'),
    value: 'C',
    median: -2046.0
  },
  {
    id: 7,
    photo: 'BID0603',
    title: I18n.get('challenge.riasec_images.header_question.BID0603'),
    value: 'C',
    median: 2681.5
  },
  {
    id: 8,
    photo: 'BID0801',
    title: I18n.get('challenge.riasec_images.header_question.BID0801'),
    value: 'I',
    median: 3113.0
  },
  {
    id: 9,
    photo: 'BID0802',
    title: I18n.get('challenge.riasec_images.header_question.BID0802'),
    value: 'I',
    median: 2800.0
  },
  {
    id: 10,
    photo: 'BID0804',
    title: I18n.get('challenge.riasec_images.header_question.BID0804'),
    value: 'I',
    median: 2566.0
  },
  {
    id: 11,
    photo: 'BID1101',
    title: I18n.get('challenge.riasec_images.header_question.BID1101'),
    value: 'E',
    median: -2442.0
  },
  {
    id: 12,
    photo: 'BID1103',
    title: I18n.get('challenge.riasec_images.header_question.BID1103'),
    value: 'E',
    median: -1449.0
  },
  {
    id: 13,
    photo: 'BID1104',
    title: I18n.get('challenge.riasec_images.header_question.BID1104'),
    value: 'E',
    median: -1761.5
  },
  {
    id: 14,
    photo: 'BID1303',
    title: I18n.get('challenge.riasec_images.header_question.BID1303'),
    value: 'R',
    median: 2155.0
  },
  {
    id: 15,
    photo: 'BID1304',
    title: I18n.get('challenge.riasec_images.header_question.BID1304'),
    value: 'R',
    median: 2649.0
  },
  {
    id: 16,
    photo: 'BID1401',
    title: I18n.get('challenge.riasec_images.header_question.BID1401'),
    value: 'I',
    median: 3511.5
  },
  {
    id: 17,
    photo: 'BID1402',
    title: I18n.get('challenge.riasec_images.header_question.BID1402'),
    value: 'I',
    median: 3149.0
  },
  {
    id: 18,
    photo: I18n.get('challenge.riasec_images.header_question.BID1403'),
    title: 'Experimente durchführen',
    value: 'I',
    median: 3302.0
  },
  {
    id: 19,
    photo: 'BID1404',
    title: I18n.get('challenge.riasec_images.header_question.BID1404'),
    value: 'I',
    median: 3238.0
  },
  {
    id: 20,
    photo: 'BID1501',
    title: I18n.get('challenge.riasec_images.header_question.BID1501'),
    value: 'A',
    median: 2058.0
  },
  {
    id: 21,
    photo: 'BID1502',
    title: I18n.get('challenge.riasec_images.header_question.BID1502'),
    value: 'A',
    median: 2257.5
  },
  {
    id: 22,
    photo: 'BID1503',
    title: I18n.get('challenge.riasec_images.header_question.BID1503'),
    value: 'A',
    median: 2344.0
  },
  {
    id: 23,
    photo: 'BID1601',
    title: I18n.get('challenge.riasec_images.header_question.BID1601'),
    value: 'S',
    median: -3234.5
  },
  {
    id: 24,
    photo: 'BID1602',
    title: I18n.get('challenge.riasec_images.header_question.BID1602'),
    value: 'S',
    median: -3312.0
  },
  {
    id: 25,
    photo: 'BID1603',
    title: I18n.get('challenge.riasec_images.header_question.BID1603'),
    value: 'S',
    median: -3217.0
  },
  {
    id: 26,
    photo: 'BID1604',
    title: I18n.get('challenge.riasec_images.header_question.BID1604'),
    value: 'S',
    median: -3382.0
  },
  {
    id: 27,
    photo: 'BID1701',
    title: I18n.get('challenge.riasec_images.header_question.BID1701'),
    value: 'E',
    median: -3468.0
  },
  {
    id: 28,
    photo: 'BID1702',
    title: I18n.get('challenge.riasec_images.header_question.BID1702'),
    value: 'E',
    median: -3348.0
  },
  {
    id: 29,
    photo: 'BID1703',
    title: I18n.get('challenge.riasec_images.header_question.BID1703'),
    value: 'E',
    median: -3308.0
  },
  {
    id: 30,
    photo: 'BID1801',
    title: I18n.get('challenge.riasec_images.header_question.BID1801'),
    value: 'C',
    median: -3276.0
  },
  {
    id: 31,
    photo: 'BID1802',
    title: I18n.get('challenge.riasec_images.header_question.BID1802'),
    value: 'C',
    median: -3343.0
  },
  {
    id: 32,
    photo: 'BID1803',
    title: I18n.get('challenge.riasec_images.header_question.BID1803'),
    value: 'C',
    median: -3461.0
  },
  {
    id: 33,
    photo: 'BID1804',
    title: I18n.get('challenge.riasec_images.header_question.BID1804'),
    value: 'C',
    median: -3324.5
  },
  {
    id: 34,
    photo: 'BID1901',
    title: I18n.get('challenge.riasec_images.header_question.BID1901'),
    value: 'R',
    median: 2230.0
  },
  {
    id: 35,
    photo: 'BID1902',
    title: I18n.get('challenge.riasec_images.header_question.BID1902'),
    value: 'R',
    median: -1793.0
  },
  {
    id: 36,
    photo: 'BID2002',
    title: I18n.get('challenge.riasec_images.header_question.BID2002'),
    value: 'I',
    median: 3730.5
  },
  {
    id: 37,
    photo: 'BID2003',
    title: I18n.get('challenge.riasec_images.header_question.BID2003'),
    value: 'I',
    median: 3660.5
  },
  {
    id: 38,
    photo: 'BID2004',
    title: I18n.get('challenge.riasec_images.header_question.BID2004'),
    value: 'I',
    median: 3662.0
  },
  {
    id: 39,
    photo: 'BID2101',
    title: I18n.get('challenge.riasec_images.header_question.BID2101'),
    value: 'A',
    median: 1429.0
  },
  {
    id: 40,
    photo: 'BID2102',
    title: I18n.get('challenge.riasec_images.header_question.BID2102'),
    value: 'A',
    median: 1560.5
  },
  {
    id: 41,
    photo: 'BID2103',
    title: I18n.get('challenge.riasec_images.header_question.BID2103'),
    value: 'A',
    median: -1245.0
  },
  {
    id: 42,
    photo: 'BID2104',
    title: I18n.get('challenge.riasec_images.header_question.BID2104'),
    value: 'A',
    median: 1767.0
  },
  {
    id: 43,
    photo: 'BID2201',
    title: I18n.get('challenge.riasec_images.header_question.BID2201'),
    value: 'S',
    median: -2931.0
  },
  {
    id: 44,
    photo: 'BID2202',
    title: I18n.get('challenge.riasec_images.header_question.BID2202'),
    value: 'S',
    median: -3157.5
  },
  {
    id: 45,
    photo: 'BID2203',
    title: I18n.get('challenge.riasec_images.header_question.BID2203'),
    value: 'S',
    median: -2993.0
  },
  {
    id: 46,
    photo: 'BID2204',
    title: I18n.get('challenge.riasec_images.header_question.BID2204'),
    value: 'S',
    median: -2735.0
  },
  {
    id: 47,
    photo: 'BID2401',
    title: I18n.get('challenge.riasec_images.header_question.BID2401'),
    value: 'C',
    median: 1811.0
  },
  {
    id: 48,
    photo: 'BID2404',
    title: I18n.get('challenge.riasec_images.header_question.BID2404'),
    value: 'C',
    median: 1731.0
  },
  {
    id: 49,
    photo: 'BID2501',
    title: I18n.get('challenge.riasec_images.header_question.BID2501'),
    value: 'R',
    median: -2828.0
  },
  {
    id: 50,
    photo: 'BID2502',
    title: I18n.get('challenge.riasec_images.header_question.BID2502'),
    value: 'R',
    median: -2686.5
  },
  {
    id: 51,
    photo: 'BID2702',
    title: I18n.get('challenge.riasec_images.header_question.BID2702'),
    value: 'A',
    median: 2141.0
  },
  {
    id: 52,
    photo: 'BID2901',
    title: I18n.get('challenge.riasec_images.header_question.BID2901'),
    value: 'E',
    median: 2388.0
  },
  {
    id: 53,
    photo: 'BID2904',
    title: I18n.get('challenge.riasec_images.header_question.BID2904'),
    value: 'E',
    median: 2640.0
  },
  {
    id: 54,
    photo: 'BID3002',
    title: I18n.get('challenge.riasec_images.header_question.BID3002'),
    value: 'C',
    median: -3011.0
  },
  {
    id: 55,
    photo: 'BID3103',
    title: I18n.get('challenge.riasec_images.header_question.BID3103'),
    value: 'R',
    median: -1967.0
  },
  {
    id: 56,
    photo: 'BID3104',
    title: I18n.get('challenge.riasec_images.header_question.BID3104'),
    value: 'R',
    median: -775.5
  },
  {
    id: 57,
    photo: 'BID3201',
    title: I18n.get('challenge.riasec_images.header_question.BID3201'),
    value: 'I',
    median: 3246.0
  },
  {
    id: 58,
    photo: 'BID3202',
    title: I18n.get('challenge.riasec_images.header_question.BID3202'),
    value: 'I',
    median: 3304.5
  },
  {
    id: 59,
    photo: 'BID3301',
    title: I18n.get('challenge.riasec_images.header_question.BID3301'),
    value: 'A',
    median: -1556.0
  },
  {
    id: 60,
    photo: 'BID3302',
    title: I18n.get('challenge.riasec_images.header_question.BID3302'),
    value: 'A',
    median: -2667.0
  },
  {
    id: 61,
    photo: 'BID3303',
    title: I18n.get('challenge.riasec_images.header_question.BID3303'),
    value: 'A',
    median: 2527.0
  },
  {
    id: 62,
    photo: 'BID3304',
    title: I18n.get('challenge.riasec_images.header_question.BID3304'),
    value: 'A',
    median: -2351.5
  },
  {
    id: 63,
    photo: 'BID3401',
    title: I18n.get('challenge.riasec_images.header_question.BID3401'),
    value: 'S',
    median: -2808.0
  },
  {
    id: 64,
    photo: 'BID3402',
    title: I18n.get('challenge.riasec_images.header_question.BID3402'),
    value: 'S',
    median: -2936.0
  },
  {
    id: 65,
    photo: 'BID3403',
    title: I18n.get('challenge.riasec_images.header_question.BID3403'),
    value: 'S',
    median: -2909.0
  },
  {
    id: 66,
    photo: 'BID3501',
    title: I18n.get('challenge.riasec_images.header_question.BID3501'),
    value: 'E',
    median: 1836.0
  },
  {
    id: 67,
    photo: 'BID3502',
    title: I18n.get('challenge.riasec_images.header_question.BID3502'),
    value: 'E',
    median: -1792.0
  },
  {
    id: 68,
    photo: 'BID3503',
    title: I18n.get('challenge.riasec_images.header_question.BID3503'),
    value: 'E',
    median: -1996.0
  },
  {
    id: 69,
    photo: 'BID3504',
    title: I18n.get('challenge.riasec_images.header_question.BID3504'),
    value: 'E',
    median: 596.5
  },
  {
    id: 70,
    photo: 'BID3601',
    title: I18n.get('challenge.riasec_images.header_question.BID3601'),
    value: 'C',
    median: -2588.0
  },
  {
    id: 71,
    photo: 'BID3602',
    title: I18n.get('challenge.riasec_images.header_question.BID3602'),
    value: 'C',
    median: -1543.5
  },
  {
    id: 72,
    photo: 'BID3603',
    title: I18n.get('challenge.riasec_images.header_question.BID3603'),
    value: 'C',
    median: -1283.5
  }
]

export const NOT_USED_DATA = { RIASEC_QUESTIONS, CATEGORIES, ITEMS_PER_CATEGORY }
