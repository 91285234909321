import colors from '../../../../theme/colors'
import metrics from '../../../../theme/metrics'

export const START_SEQUENCE_LENGTH = 3
export const ROUND_LENGTH = 12
export const TEST_ROUND_LENGTH = 1 // SHOULD BE 1

/**
 * Define starting set of numbers
 * @param {Number} shortTermMemoryCapacity
 */

export const getAllowedDuplicates = (shortTermMemoryCapacity) => {
  if (shortTermMemoryCapacity <= 6) {
    return 1
  }
  if (shortTermMemoryCapacity < 12) {
    return 2
  }
  return 3
}

export const countDuplicates = (random, sequence) => {
  const count = sequence.reduce((n, val) => n + (val === random.toString()), 0)
  return count
}

export const TIME_TO_OBSERVE = 800

export const SYMBOLS = [
  {
    id: '1',
    imageSource: require('../../../../assets/challenges/digit-span/game-assets/eye.png'),
    imageHeight: metrics.widthPercentageToDP(6.9),
    imageWidth: metrics.widthPercentageToDP(10.4)
  },
  {
    id: '2',
    imageSource: require('../../../../assets/challenges/digit-span/game-assets/apple.png'),
    imageHeight: metrics.widthPercentageToDP(9.6),
    imageWidth: metrics.widthPercentageToDP(8.2)
  },
  {
    id: '3',
    imageSource: require('../../../../assets/challenges/digit-span/game-assets/bike.png'),
    imageHeight: metrics.widthPercentageToDP(7.47),
    imageWidth: metrics.widthPercentageToDP(12.27)
  },
  {
    id: '4',
    imageSource: require('../../../../assets/challenges/digit-span/game-assets/tree.png'),
    imageHeight: metrics.widthPercentageToDP(9.5),
    imageWidth: metrics.widthPercentageToDP(6.8)
  },
  {
    id: '5',
    imageSource: require('../../../../assets/challenges/digit-span/game-assets/cat.png'),
    imageHeight: metrics.widthPercentageToDP(9.1),
    imageWidth: metrics.widthPercentageToDP(9.6)
  },
  {
    id: '6',
    imageSource: require('../../../../assets/challenges/digit-span/game-assets/snowflake.png'),
    imageHeight: metrics.widthPercentageToDP(9.6),
    imageWidth: metrics.widthPercentageToDP(8.5)
  }
]

export const checkPartialSolution = (answer, numberSequence) => {
  if (answer.length === 0) return true
  let res = false
  answer.forEach((currentValue, index) => {
    res = currentValue === numberSequence[index]
  })
  return res
}

export const getBackgroundColor = (numberSequenceLength) => {
  switch (numberSequenceLength) {
    case 3:
      return colors.primaryBlue
    case 4:
      return colors.gigas
    case 5:
      return colors.hopbush
    case 6:
      return colors.cloudBurst
    case 7:
      return colors.brightSun
    default:
      return colors.brightSun
  }
}

/**
 * Define starting set of numbers
 * @param {Number} x
 */
export const checkDifference = (x) => x <= 2

export const isAnswerCorrect = (numberSequence, answer) => {
  let res = []
  answer.forEach((item, index) => {
    if (numberSequence[index] === item) {
      // Backward Span (BS)
      res.push(true)
    } else {
      res.push(false)
    }
  })
  // array of true and false for each digit
  // console.log('entwurf:' + res);
  // check if array includes false (returns true)
  // console.log('includes? ' + res.includes(false));
  // if returned true, then res = false, because
  // array included a wrong digit typed by user
  res = res.includes(false) !== true

  // return res;
  return res
}

export const LEVEL_DATA = {
  3: [
    ['1', '2', '5'],
    ['2', '1', '5'],
    ['1', '4', '2'],
    ['6', '5', '1'],
    ['1', '5', '3'],
    ['2', '4', '1'],
    ['3', '5', '4'],
    ['6', '5', '4'],
    ['3', '2', '1'],
    ['2', '5', '4'],
    ['1', '6', '2'],
    ['1', '6', '4'],
    ['6', '4', '2'],
    ['2', '5', '1'],
    ['5', '1', '4'],
    ['1', '2', '6'],
    ['4', '3', '1'],
    ['3', '1', '5'],
    ['2', '3', '4'],
    ['4', '2', '5'],
    ['2', '3', '1'],
    ['2', '4', '6'],
    ['3', '5', '6'],
    ['2', '1', '3'],
    ['3', '2', '5'],
    ['6', '1', '3'],
    ['3', '6', '4'],
    ['6', '1', '5'],
    ['2', '6', '4'],
    ['3', '1', '4'],
    ['1', '3', '5'],
    ['5', '3', '4'],
    ['6', '3', '5'],
    ['1', '3', '6'],
    ['5', '2', '3'],
    ['1', '4', '5'],
    ['2', '1', '6'],
    ['5', '4', '6'],
    ['1', '3', '4'],
    ['6', '4', '1'],
    ['6', '4', '5'],
    ['6', '3', '4'],
    ['2', '6', '1'],
    ['3', '6', '2'],
    ['6', '1', '2'],
    ['4', '3', '5'],
    ['6', '3', '2'],
    ['5', '2', '6'],
    ['6', '3', '1'],
    ['4', '3', '6'],
    ['1', '4', '3'],
    ['4', '5', '6'],
    ['4', '6', '3'],
    ['1', '3', '2'],
    ['2', '3', '6'],
    ['6', '2', '1'],
    ['3', '2', '6'],
    ['2', '6', '3'],
    ['4', '6', '1'],
    ['3', '5', '2'],
    ['5', '3', '1'],
    ['5', '2', '1'],
    ['1', '5', '4'],
    ['6', '2', '5'],
    ['5', '3', '6'],
    ['5', '4', '2'],
    ['2', '3', '5'],
    ['2', '4', '5'],
    ['6', '2', '4'],
    ['6', '5', '3'],
    ['4', '2', '3'],
    ['2', '4', '3'],
    ['3', '2', '4'],
    ['4', '6', '2'],
    ['4', '6', '5'],
    ['5', '4', '3'],
    ['1', '2', '3'],
    ['1', '5', '2'],
    ['1', '6', '3'],
    ['4', '1', '2'],
    ['2', '6', '5'],
    ['3', '1', '2'],
    ['1', '6', '5'],
    ['4', '5', '2'],
    ['1', '2', '4'],
    ['5', '4', '1'],
    ['4', '5', '3'],
    ['3', '6', '1'],
    ['5', '1', '6'],
    ['5', '2', '4'],
    ['3', '4', '2'],
    ['6', '5', '2'],
    ['4', '1', '3'],
    ['3', '6', '5'],
    ['5', '6', '2'],
    ['5', '1', '2'],
    ['4', '1', '5'],
    ['2', '5', '6'],
    ['2', '5', '3'],
    ['3', '4', '5']
  ],
  4: [
    ['2', '1', '3', '4'],
    ['6', '5', '4', '1'],
    ['5', '6', '3', '4'],
    ['4', '1', '3', '5'],
    ['4', '1', '5', '6'],
    ['5', '2', '4', '6'],
    ['3', '1', '5', '2'],
    ['2', '4', '3', '6'],
    ['5', '1', '3', '6'],
    ['2', '3', '1', '6'],
    ['2', '6', '5', '1'],
    ['6', '1', '2', '4'],
    ['6', '3', '4', '2'],
    ['1', '2', '4', '3'],
    ['3', '4', '2', '5'],
    ['3', '5', '6', '4'],
    ['2', '3', '6', '5'],
    ['2', '1', '4', '3'],
    ['5', '6', '2', '4'],
    ['5', '1', '6', '2'],
    ['5', '6', '4', '2'],
    ['4', '5', '6', '1'],
    ['1', '2', '5', '6'],
    ['2', '5', '1', '6'],
    ['5', '4', '1', '6'],
    ['5', '2', '6', '1'],
    ['2', '3', '4', '1'],
    ['2', '4', '6', '1'],
    ['2', '3', '4', '5'],
    ['3', '6', '1', '4'],
    ['2', '6', '4', '1'],
    ['4', '2', '3', '1'],
    ['3', '5', '2', '4'],
    ['4', '5', '6', '2'],
    ['4', '1', '3', '6'],
    ['3', '5', '6', '1'],
    ['6', '5', '4', '2'],
    ['5', '2', '3', '4'],
    ['4', '1', '5', '3'],
    ['1', '5', '4', '2'],
    ['5', '1', '4', '6'],
    ['2', '6', '5', '4'],
    ['1', '6', '4', '2'],
    ['6', '1', '5', '3'],
    ['1', '4', '5', '6'],
    ['6', '2', '3', '4'],
    ['3', '6', '5', '2'],
    ['6', '4', '5', '1'],
    ['5', '2', '4', '1'],
    ['3', '1', '4', '5'],
    ['5', '3', '4', '6'],
    ['3', '6', '1', '5'],
    ['4', '1', '3', '2'],
    ['3', '4', '2', '6'],
    ['6', '3', '4', '1'],
    ['5', '3', '1', '4'],
    ['4', '5', '1', '3'],
    ['6', '3', '5', '1'],
    ['6', '1', '2', '5'],
    ['2', '4', '3', '5'],
    ['5', '2', '1', '4'],
    ['1', '3', '6', '5'],
    ['1', '5', '3', '2'],
    ['1', '4', '6', '3'],
    ['5', '3', '2', '1'],
    ['6', '3', '2', '1'],
    ['2', '1', '4', '6'],
    ['5', '1', '6', '4'],
    ['2', '4', '5', '6'],
    ['5', '6', '2', '3'],
    ['3', '5', '1', '6'],
    ['3', '6', '5', '4'],
    ['3', '2', '5', '6'],
    ['5', '4', '6', '1'],
    ['1', '6', '5', '4'],
    ['1', '6', '2', '5'],
    ['3', '2', '4', '1'],
    ['1', '5', '3', '4'],
    ['6', '1', '4', '3'],
    ['2', '1', '3', '6'],
    ['2', '5', '3', '4'],
    ['5', '4', '2', '6'],
    ['1', '2', '6', '4'],
    ['4', '3', '5', '1'],
    ['5', '3', '4', '2'],
    ['2', '6', '1', '3'],
    ['5', '3', '6', '1'],
    ['6', '5', '2', '4'],
    ['6', '5', '3', '1'],
    ['5', '6', '1', '2'],
    ['4', '5', '3', '2'],
    ['6', '1', '3', '2'],
    ['1', '4', '5', '2'],
    ['1', '3', '2', '5'],
    ['6', '3', '2', '4'],
    ['2', '5', '6', '4'],
    ['2', '4', '1', '3'],
    ['5', '2', '6', '4'],
    ['5', '1', '4', '2'],
    ['5', '6', '2', '1']
  ],
  5: [
    ['5', '6', '1', '3', '2'],
    ['6', '4', '2', '1', '3'],
    ['5', '1', '2', '3', '6'],
    ['1', '6', '5', '3', '4'],
    ['1', '3', '5', '2', '4'],
    ['3', '2', '5', '4', '6'],
    ['6', '5', '3', '4', '2'],
    ['2', '4', '5', '1', '3'],
    ['5', '1', '2', '6', '3'],
    ['3', '5', '6', '4', '1'],
    ['3', '4', '6', '5', '2'],
    ['6', '4', '3', '2', '1'],
    ['5', '6', '1', '2', '4'],
    ['3', '6', '5', '4', '2'],
    ['1', '3', '6', '2', '5'],
    ['2', '4', '3', '5', '6'],
    ['2', '3', '5', '4', '1'],
    ['2', '5', '1', '6', '4'],
    ['2', '6', '1', '5', '4'],
    ['3', '2', '6', '1', '5'],
    ['6', '2', '3', '4', '5'],
    ['3', '4', '1', '6', '5'],
    ['6', '1', '5', '4', '2'],
    ['4', '6', '3', '1', '2'],
    ['2', '3', '6', '1', '4'],
    ['2', '3', '1', '5', '4'],
    ['5', '3', '2', '4', '6'],
    ['4', '3', '6', '1', '5'],
    ['4', '3', '5', '6', '1'],
    ['2', '4', '1', '6', '5'],
    ['2', '6', '5', '1', '4'],
    ['4', '6', '3', '5', '1'],
    ['5', '6', '1', '2', '3'],
    ['3', '6', '1', '5', '4'],
    ['4', '5', '1', '2', '3'],
    ['1', '6', '2', '5', '4'],
    ['3', '2', '5', '6', '1'],
    ['1', '4', '6', '2', '3'],
    ['1', '3', '2', '5', '6'],
    ['5', '6', '1', '4', '3'],
    ['6', '1', '5', '2', '3'],
    ['2', '6', '4', '3', '5'],
    ['6', '4', '5', '1', '3'],
    ['3', '6', '1', '5', '2'],
    ['5', '6', '1', '4', '2'],
    ['5', '2', '1', '3', '6'],
    ['5', '3', '1', '6', '4'],
    ['2', '6', '4', '5', '3'],
    ['4', '2', '6', '5', '3'],
    ['5', '3', '1', '4', '6'],
    ['3', '2', '1', '6', '5'],
    ['5', '6', '4', '2', '1'],
    ['1', '3', '6', '4', '2'],
    ['2', '4', '6', '3', '1'],
    ['6', '1', '5', '4', '3'],
    ['6', '2', '4', '5', '3'],
    ['3', '1', '4', '6', '2'],
    ['4', '5', '2', '1', '3'],
    ['6', '1', '3', '5', '4'],
    ['1', '4', '3', '2', '6'],
    ['3', '1', '5', '6', '4'],
    ['4', '1', '3', '2', '5'],
    ['6', '4', '2', '5', '1'],
    ['4', '1', '6', '5', '3'],
    ['4', '2', '1', '6', '5'],
    ['2', '3', '4', '6', '1'],
    ['3', '2', '6', '4', '1'],
    ['4', '6', '1', '5', '2'],
    ['2', '4', '6', '1', '5'],
    ['6', '3', '4', '1', '2'],
    ['3', '5', '4', '6', '2'],
    ['5', '1', '6', '2', '4'],
    ['1', '2', '3', '4', '5'],
    ['3', '1', '6', '4', '2'],
    ['6', '2', '5', '3', '1'],
    ['4', '2', '5', '1', '6'],
    ['6', '3', '1', '2', '5'],
    ['4', '5', '6', '3', '1'],
    ['1', '6', '5', '3', '2'],
    ['1', '5', '6', '2', '4'],
    ['4', '2', '5', '3', '1'],
    ['1', '6', '4', '3', '2'],
    ['4', '5', '6', '1', '2'],
    ['3', '5', '6', '1', '4'],
    ['2', '1', '4', '5', '6'],
    ['1', '6', '2', '3', '5'],
    ['2', '3', '5', '4', '6'],
    ['2', '6', '3', '1', '4'],
    ['3', '2', '1', '5', '6'],
    ['2', '1', '5', '3', '4'],
    ['4', '6', '2', '5', '1'],
    ['3', '2', '4', '1', '6'],
    ['6', '5', '3', '1', '4'],
    ['6', '3', '1', '5', '4'],
    ['2', '1', '6', '4', '5'],
    ['1', '6', '3', '4', '5'],
    ['5', '3', '1', '4', '2'],
    ['3', '1', '6', '2', '5'],
    ['2', '5', '1', '3', '4'],
    ['6', '4', '2', '3', '1']
  ],
  6: [
    ['3', '1', '4', '5', '6', '2'],
    ['6', '2', '4', '5', '1', '3'],
    ['1', '6', '3', '2', '5', '4'],
    ['2', '5', '1', '3', '4', '6'],
    ['6', '5', '1', '2', '4', '3'],
    ['6', '5', '1', '3', '4', '2'],
    ['1', '3', '5', '2', '6', '4'],
    ['3', '4', '1', '5', '2', '6'],
    ['3', '1', '6', '4', '2', '5'],
    ['2', '6', '5', '3', '1', '4'],
    ['2', '3', '5', '1', '4', '6'],
    ['2', '5', '6', '3', '1', '4'],
    ['6', '2', '5', '4', '3', '1'],
    ['5', '2', '4', '1', '3', '6'],
    ['2', '1', '5', '4', '6', '3'],
    ['1', '4', '5', '3', '2', '6'],
    ['2', '6', '3', '1', '4', '5'],
    ['3', '1', '6', '2', '4', '5'],
    ['5', '2', '1', '6', '3', '4'],
    ['3', '1', '6', '2', '5', '4'],
    ['2', '6', '4', '1', '5', '3'],
    ['2', '1', '3', '5', '6', '4'],
    ['6', '5', '1', '2', '3', '4'],
    ['6', '3', '2', '5', '4', '1'],
    ['2', '4', '6', '3', '5', '1'],
    ['3', '2', '4', '6', '1', '5'],
    ['4', '6', '3', '1', '5', '2'],
    ['6', '5', '2', '3', '4', '1'],
    ['1', '2', '4', '6', '3', '5'],
    ['4', '6', '2', '1', '3', '5'],
    ['3', '5', '2', '1', '6', '4'],
    ['4', '5', '6', '2', '1', '3'],
    ['3', '5', '2', '4', '6', '1'],
    ['3', '2', '5', '4', '6', '1'],
    ['2', '3', '6', '1', '5', '4'],
    ['5', '6', '1', '2', '3', '4'],
    ['2', '4', '6', '5', '3', '1'],
    ['3', '5', '2', '1', '4', '6'],
    ['3', '4', '5', '6', '1', '2'],
    ['1', '6', '2', '4', '3', '5'],
    ['2', '5', '6', '3', '4', '1'],
    ['6', '3', '2', '1', '5', '4'],
    ['4', '3', '5', '1', '6', '2'],
    ['1', '3', '4', '2', '6', '5'],
    ['5', '4', '6', '1', '2', '3'],
    ['2', '5', '4', '6', '3', '1'],
    ['3', '5', '1', '6', '4', '2'],
    ['3', '6', '5', '2', '4', '1'],
    ['3', '4', '6', '1', '5', '2'],
    ['5', '3', '6', '2', '4', '1'],
    ['3', '6', '5', '2', '1', '4'],
    ['2', '5', '3', '1', '6', '4'],
    ['6', '1', '2', '4', '3', '5'],
    ['1', '4', '6', '5', '3', '2'],
    ['3', '5', '4', '6', '1', '2'],
    ['6', '3', '1', '2', '4', '5'],
    ['4', '6', '2', '5', '3', '1'],
    ['2', '4', '6', '5', '1', '3'],
    ['6', '3', '5', '4', '2', '1'],
    ['4', '1', '5', '2', '6', '3'],
    ['6', '1', '4', '5', '3', '2'],
    ['6', '4', '3', '2', '1', '5'],
    ['3', '2', '4', '6', '5', '1'],
    ['6', '5', '1', '3', '2', '4'],
    ['1', '2', '6', '3', '5', '4'],
    ['1', '5', '3', '6', '2', '4'],
    ['2', '3', '1', '6', '5', '4'],
    ['2', '5', '6', '1', '3', '4'],
    ['2', '1', '6', '5', '4', '3'],
    ['5', '2', '3', '1', '4', '6'],
    ['1', '6', '2', '5', '4', '3'],
    ['6', '1', '5', '2', '3', '4'],
    ['2', '5', '6', '4', '1', '3'],
    ['2', '1', '6', '4', '3', '5'],
    ['6', '2', '4', '1', '3', '5'],
    ['1', '6', '4', '3', '5', '2'],
    ['4', '6', '3', '2', '1', '5'],
    ['4', '1', '3', '5', '2', '6'],
    ['6', '4', '1', '5', '3', '2'],
    ['5', '6', '2', '4', '1', '3'],
    ['5', '6', '2', '4', '3', '1'],
    ['1', '4', '3', '2', '6', '5'],
    ['1', '6', '5', '4', '2', '3'],
    ['3', '1', '5', '6', '2', '4'],
    ['4', '2', '3', '6', '1', '5'],
    ['4', '1', '6', '3', '2', '5'],
    ['1', '6', '2', '3', '4', '5'],
    ['1', '5', '6', '2', '3', '4'],
    ['3', '4', '5', '1', '6', '2'],
    ['3', '5', '1', '2', '4', '6'],
    ['2', '4', '1', '3', '5', '6'],
    ['5', '1', '4', '3', '6', '2'],
    ['5', '2', '6', '4', '1', '3'],
    ['1', '4', '5', '6', '2', '3'],
    ['4', '2', '6', '5', '3', '1'],
    ['3', '6', '1', '4', '5', '2'],
    ['3', '4', '6', '2', '5', '1'],
    ['5', '4', '6', '2', '3', '1'],
    ['1', '2', '6', '5', '4', '3'],
    ['2', '6', '3', '5', '1', '4']
  ],
  7: [
    ['2', '4', '1', '6', '5', '3', '5'],
    ['5', '2', '5', '4', '3', '6', '4'],
    ['2', '1', '5', '3', '2', '4', '6'],
    ['2', '4', '2', '5', '6', '1', '4'],
    ['1', '6', '4', '3', '1', '2', '5'],
    ['2', '4', '5', '3', '2', '5', '1'],
    ['5', '3', '2', '6', '4', '5', '2'],
    ['2', '5', '4', '1', '3', '2', '1'],
    ['3', '2', '4', '1', '5', '6', '4'],
    ['4', '6', '2', '6', '5', '1', '4'],
    ['2', '1', '6', '5', '1', '2', '4'],
    ['6', '4', '3', '2', '5', '4', '3'],
    ['4', '2', '6', '5', '4', '3', '1'],
    ['2', '6', '3', '1', '6', '5', '3'],
    ['1', '6', '2', '6', '4', '5', '1'],
    ['1', '4', '2', '3', '6', '3', '6'],
    ['1', '4', '5', '4', '6', '2', '3'],
    ['1', '4', '1', '3', '2', '5', '3'],
    ['6', '3', '6', '1', '3', '2', '1'],
    ['5', '2', '4', '6', '1', '2', '5'],
    ['2', '5', '2', '5', '6', '4', '3'],
    ['3', '1', '6', '2', '4', '2', '3'],
    ['1', '5', '3', '1', '4', '2', '3'],
    ['5', '4', '2', '4', '1', '2', '3'],
    ['1', '2', '3', '2', '4', '6', '1'],
    ['6', '3', '4', '5', '2', '3', '5'],
    ['6', '5', '1', '4', '5', '3', '2'],
    ['3', '4', '5', '1', '4', '1', '6'],
    ['2', '6', '1', '2', '5', '4', '5'],
    ['1', '6', '3', '6', '3', '4', '2'],
    ['1', '3', '1', '4', '2', '3', '5'],
    ['6', '4', '3', '5', '6', '3', '5'],
    ['3', '2', '4', '5', '4', '1', '5'],
    ['1', '6', '3', '6', '3', '2', '5'],
    ['6', '5', '1', '2', '3', '2', '1'],
    ['4', '6', '3', '2', '5', '4', '1'],
    ['4', '1', '6', '5', '3', '1', '6'],
    ['4', '5', '1', '5', '4', '1', '2'],
    ['4', '5', '3', '6', '5', '1', '4'],
    ['3', '4', '3', '1', '4', '6', '5'],
    ['4', '6', '5', '1', '4', '3', '2'],
    ['2', '4', '1', '3', '6', '3', '5'],
    ['3', '2', '1', '6', '4', '3', '4'],
    ['5', '1', '2', '1', '2', '5', '6'],
    ['5', '4', '6', '1', '4', '1', '6'],
    ['1', '2', '4', '6', '5', '6', '2'],
    ['6', '4', '2', '1', '2', '6', '3'],
    ['2', '6', '1', '6', '5', '3', '5'],
    ['2', '6', '1', '5', '4', '3', '4'],
    ['4', '6', '4', '5', '1', '6', '5'],
    ['4', '2', '4', '1', '5', '3', '6'],
    ['1', '4', '6', '2', '4', '5', '2'],
    ['2', '3', '6', '1', '4', '3', '2'],
    ['3', '6', '2', '4', '5', '6', '3'],
    ['6', '3', '6', '5', '3', '2', '5'],
    ['6', '2', '3', '2', '4', '1', '6'],
    ['4', '3', '2', '3', '1', '6', '2'],
    ['3', '4', '5', '6', '5', '1', '4'],
    ['6', '1', '6', '4', '5', '4', '3'],
    ['3', '2', '3', '2', '6', '1', '6'],
    ['6', '4', '2', '4', '1', '3', '2'],
    ['4', '6', '1', '6', '1', '3', '5'],
    ['3', '6', '3', '1', '5', '4', '1'],
    ['4', '2', '1', '4', '6', '5', '2'],
    ['1', '6', '4', '3', '1', '6', '4'],
    ['6', '3', '1', '3', '2', '5', '1'],
    ['1', '3', '5', '1', '2', '6', '4'],
    ['3', '4', '1', '2', '6', '3', '2'],
    ['1', '3', '4', '5', '2', '5', '3'],
    ['2', '5', '4', '1', '5', '2', '3'],
    ['3', '4', '2', '3', '6', '2', '5'],
    ['4', '2', '1', '3', '2', '6', '3'],
    ['3', '5', '3', '6', '4', '1', '6'],
    ['6', '1', '3', '6', '4', '3', '5'],
    ['3', '6', '4', '5', '6', '5', '3'],
    ['2', '5', '3', '2', '4', '6', '1'],
    ['1', '4', '3', '6', '4', '2', '5'],
    ['3', '6', '3', '2', '6', '1', '4'],
    ['2', '6', '4', '6', '5', '4', '3'],
    ['6', '4', '1', '3', '1', '5', '2'],
    ['6', '1', '3', '5', '1', '2', '3'],
    ['6', '3', '6', '4', '5', '3', '5'],
    ['1', '3', '4', '2', '5', '3', '4'],
    ['4', '2', '5', '1', '2', '1', '5'],
    ['6', '4', '6', '5', '4', '5', '2'],
    ['3', '4', '1', '4', '3', '6', '5'],
    ['6', '4', '2', '5', '6', '4', '5'],
    ['3', '5', '2', '4', '5', '1', '3'],
    ['3', '5', '6', '4', '6', '4', '5'],
    ['1', '3', '5', '2', '4', '2', '3'],
    ['4', '6', '5', '1', '2', '1', '6'],
    ['6', '2', '3', '1', '5', '3', '1'],
    ['3', '5', '4', '1', '2', '6', '4'],
    ['5', '6', '2', '1', '2', '5', '3'],
    ['3', '6', '5', '4', '5', '3', '2'],
    ['4', '2', '4', '1', '3', '5', '3'],
    ['5', '1', '3', '4', '3', '1', '4'],
    ['1', '5', '2', '3', '5', '3', '1'],
    ['2', '4', '5', '4', '1', '5', '6'],
    ['5', '4', '5', '4', '6', '2', '3']
  ],
  8: [
    ['3', '1', '6', '5', '6', '2', '5', '3'],
    ['4', '2', '4', '2', '3', '1', '6', '5'],
    ['6', '3', '6', '1', '4', '2', '5', '2'],
    ['5', '4', '5', '4', '1', '2', '6', '3'],
    ['4', '6', '4', '5', '6', '1', '3', '5'],
    ['4', '1', '3', '5', '2', '6', '4', '6'],
    ['5', '3', '4', '3', '6', '1', '6', '2'],
    ['2', '4', '3', '2', '5', '1', '5', '1'],
    ['1', '5', '2', '3', '6', '4', '5', '1'],
    ['2', '6', '2', '6', '5', '1', '4', '1'],
    ['3', '1', '5', '2', '3', '6', '5', '1'],
    ['6', '2', '6', '1', '4', '3', '4', '1'],
    ['4', '1', '6', '2', '1', '6', '4', '5'],
    ['2', '3', '2', '5', '4', '3', '6', '5'],
    ['6', '2', '3', '2', '1', '6', '4', '1'],
    ['4', '2', '6', '2', '3', '5', '1', '3'],
    ['1', '5', '1', '5', '4', '6', '4', '3'],
    ['1', '3', '5', '2', '3', '4', '6', '4'],
    ['4', '1', '3', '1', '5', '2', '3', '4'],
    ['4', '3', '2', '5', '6', '1', '4', '3'],
    ['2', '3', '5', '3', '5', '4', '6', '1'],
    ['4', '3', '5', '1', '2', '3', '6', '5'],
    ['5', '2', '4', '3', '4', '5', '3', '1'],
    ['4', '6', '5', '3', '1', '3', '4', '2'],
    ['3', '1', '4', '6', '3', '2', '1', '2'],
    ['1', '4', '6', '2', '5', '3', '5', '3'],
    ['6', '3', '1', '2', '3', '5', '4', '2'],
    ['1', '3', '2', '5', '6', '4', '1', '2'],
    ['6', '3', '5', '6', '3', '5', '2', '4'],
    ['1', '3', '4', '3', '5', '1', '4', '6'],
    ['5', '1', '2', '1', '3', '5', '3', '2'],
    ['3', '5', '2', '1', '6', '1', '2', '4'],
    ['3', '6', '1', '6', '5', '2', '4', '3'],
    ['2', '1', '6', '1', '3', '6', '3', '2'],
    ['6', '1', '6', '3', '2', '3', '1', '5'],
    ['3', '5', '1', '6', '5', '3', '4', '1'],
    ['5', '1', '6', '3', '5', '4', '3', '2'],
    ['2', '3', '4', '3', '4', '1', '5', '6'],
    ['5', '3', '5', '4', '1', '6', '4', '1'],
    ['6', '2', '5', '3', '4', '1', '6', '4'],
    ['2', '1', '5', '2', '4', '6', '5', '4'],
    ['3', '1', '6', '3', '1', '6', '4', '2'],
    ['6', '5', '1', '6', '3', '5', '4', '1'],
    ['2', '4', '2', '6', '5', '3', '5', '1'],
    ['6', '1', '4', '5', '6', '5', '4', '3'],
    ['5', '4', '6', '5', '4', '2', '1', '2'],
    ['2', '4', '1', '5', '3', '5', '4', '2'],
    ['5', '1', '5', '1', '2', '6', '2', '6'],
    ['3', '4', '6', '1', '3', '4', '1', '2'],
    ['4', '6', '2', '4', '3', '2', '5', '3'],
    ['3', '2', '1', '4', '2', '6', '3', '1'],
    ['2', '6', '1', '5', '1', '3', '4', '5'],
    ['5', '1', '2', '3', '1', '4', '5', '4'],
    ['3', '4', '6', '5', '1', '6', '1', '4'],
    ['6', '5', '6', '5', '1', '2', '4', '3'],
    ['2', '5', '3', '1', '4', '2', '3', '6'],
    ['4', '6', '5', '3', '5', '3', '6', '4'],
    ['1', '2', '5', '2', '3', '5', '1', '6'],
    ['4', '5', '6', '1', '4', '2', '1', '6'],
    ['4', '6', '4', '2', '3', '1', '6', '1'],
    ['5', '3', '1', '3', '6', '5', '1', '2'],
    ['6', '1', '5', '1', '4', '2', '4', '3'],
    ['1', '6', '5', '6', '2', '4', '3', '5'],
    ['4', '2', '4', '3', '5', '3', '5', '1'],
    ['3', '2', '1', '6', '3', '1', '2', '5'],
    ['2', '3', '5', '6', '2', '6', '1', '5'],
    ['5', '1', '3', '1', '3', '2', '5', '4'],
    ['5', '6', '4', '1', '3', '1', '4', '3'],
    ['3', '4', '2', '3', '1', '6', '4', '1'],
    ['4', '6', '3', '2', '5', '3', '2', '5'],
    ['6', '1', '2', '5', '3', '2', '1', '5'],
    ['1', '5', '4', '5', '6', '3', '1', '2'],
    ['3', '4', '3', '5', '2', '5', '1', '6'],
    ['6', '2', '5', '6', '5', '1', '2', '3'],
    ['2', '5', '2', '3', '6', '5', '1', '3'],
    ['4', '1', '3', '5', '1', '3', '2', '6'],
    ['4', '6', '4', '6', '1', '3', '5', '1'],
    ['2', '1', '4', '2', '6', '4', '3', '6'],
    ['5', '2', '4', '1', '5', '3', '4', '6'],
    ['3', '1', '2', '1', '4', '5', '6', '4'],
    ['4', '6', '5', '2', '3', '2', '4', '6'],
    ['3', '2', '5', '6', '2', '6', '5', '1'],
    ['1', '6', '3', '5', '3', '2', '5', '4'],
    ['4', '1', '3', '1', '6', '4', '6', '3'],
    ['5', '3', '1', '4', '5', '3', '1', '4'],
    ['4', '5', '1', '5', '3', '6', '1', '2'],
    ['6', '1', '5', '2', '6', '3', '1', '3'],
    ['1', '3', '4', '2', '6', '5', '2', '4'],
    ['5', '3', '5', '4', '2', '3', '4', '1'],
    ['6', '3', '6', '1', '5', '4', '3', '1'],
    ['1', '3', '5', '3', '6', '5', '2', '4'],
    ['3', '4', '1', '5', '1', '4', '2', '5'],
    ['6', '2', '6', '5', '3', '4', '1', '3'],
    ['1', '6', '1', '4', '5', '4', '6', '5'],
    ['6', '3', '6', '3', '2', '5', '1', '2'],
    ['6', '5', '6', '5', '4', '1', '2', '1'],
    ['4', '2', '3', '4', '2', '6', '3', '6'],
    ['1', '6', '2', '5', '1', '5', '2', '6'],
    ['3', '6', '4', '6', '4', '5', '2', '5'],
    ['2', '1', '5', '1', '4', '3', '6', '2']
  ],
  9: [
    ['4', '6', '1', '4', '6', '3', '1', '3', '5'],
    ['4', '6', '4', '2', '6', '1', '3', '1', '2'],
    ['5', '2', '5', '2', '6', '4', '6', '4', '1'],
    ['2', '5', '3', '4', '3', '5', '4', '6', '1'],
    ['6', '4', '5', '6', '4', '1', '5', '2', '1'],
    ['2', '3', '2', '4', '1', '4', '5', '3', '5'],
    ['2', '4', '1', '2', '6', '1', '3', '6', '5'],
    ['1', '4', '2', '1', '3', '5', '4', '3', '2'],
    ['4', '5', '6', '3', '5', '1', '4', '2', '1'],
    ['3', '4', '6', '1', '5', '4', '3', '6', '2'],
    ['3', '6', '1', '4', '3', '6', '2', '1', '5'],
    ['4', '2', '4', '3', '1', '5', '3', '2', '5'],
    ['3', '4', '2', '4', '6', '3', '2', '5', '1'],
    ['4', '5', '6', '4', '1', '2', '3', '2', '1'],
    ['6', '3', '2', '5', '1', '6', '5', '3', '4'],
    ['5', '4', '6', '4', '1', '5', '6', '2', '3'],
    ['6', '1', '4', '2', '1', '2', '4', '3', '5'],
    ['1', '2', '5', '1', '3', '6', '2', '5', '6'],
    ['3', '2', '6', '2', '6', '5', '1', '4', '3'],
    ['3', '1', '6', '4', '5', '6', '5', '1', '3'],
    ['3', '6', '3', '4', '2', '1', '4', '2', '6'],
    ['5', '1', '6', '3', '2', '6', '1', '5', '4'],
    ['3', '2', '1', '5', '2', '3', '5', '6', '4'],
    ['4', '5', '2', '6', '4', '2', '5', '1', '3'],
    ['4', '2', '3', '2', '6', '5', '3', '1', '5'],
    ['1', '6', '5', '4', '3', '2', '6', '1', '4'],
    ['3', '2', '4', '1', '6', '2', '5', '3', '6'],
    ['2', '6', '3', '2', '4', '1', '3', '5', '4'],
    ['1', '4', '3', '4', '1', '6', '2', '3', '2'],
    ['4', '3', '5', '1', '3', '5', '2', '4', '6'],
    ['2', '6', '2', '5', '1', '4', '5', '3', '1'],
    ['3', '4', '6', '2', '5', '1', '6', '5', '3'],
    ['6', '2', '1', '6', '4', '1', '5', '2', '5'],
    ['6', '2', '5', '1', '6', '1', '4', '3', '5'],
    ['1', '2', '4', '6', '2', '4', '1', '3', '6'],
    ['2', '3', '2', '6', '5', '6', '1', '5', '3'],
    ['3', '5', '1', '2', '1', '5', '2', '6', '3'],
    ['2', '3', '5', '6', '3', '2', '1', '4', '6'],
    ['4', '6', '1', '3', '6', '4', '2', '1', '2'],
    ['6', '2', '5', '4', '3', '1', '3', '5', '2'],
    ['5', '3', '2', '1', '2', '1', '4', '3', '5'],
    ['6', '5', '3', '1', '4', '1', '5', '2', '3'],
    ['6', '4', '5', '1', '4', '3', '2', '6', '5'],
    ['6', '1', '2', '3', '2', '5', '6', '4', '1'],
    ['2', '4', '6', '1', '3', '1', '6', '3', '4'],
    ['1', '5', '6', '2', '6', '2', '1', '4', '5'],
    ['4', '5', '3', '1', '5', '3', '4', '1', '6'],
    ['2', '6', '5', '6', '3', '1', '2', '3', '1'],
    ['3', '6', '5', '4', '5', '2', '6', '1', '4'],
    ['3', '5', '1', '5', '1', '3', '6', '4', '2'],
    ['6', '1', '6', '5', '1', '3', '2', '3', '4'],
    ['2', '1', '2', '6', '1', '5', '6', '4', '3'],
    ['5', '1', '5', '6', '1', '4', '2', '3', '6'],
    ['4', '3', '1', '2', '6', '3', '2', '5', '6'],
    ['3', '2', '5', '2', '3', '1', '6', '4', '5'],
    ['5', '2', '4', '6', '1', '3', '4', '2', '1'],
    ['4', '6', '1', '2', '6', '3', '5', '3', '2'],
    ['1', '6', '3', '5', '3', '2', '5', '2', '1'],
    ['3', '5', '4', '6', '3', '6', '2', '5', '1'],
    ['2', '3', '5', '3', '1', '6', '2', '4', '6'],
    ['1', '3', '2', '4', '3', '5', '6', '1', '6'],
    ['4', '6', '5', '3', '5', '3', '6', '2', '1'],
    ['6', '3', '5', '1', '3', '4', '2', '1', '2'],
    ['3', '4', '5', '6', '3', '6', '4', '5', '2'],
    ['1', '4', '5', '1', '3', '5', '4', '2', '3'],
    ['5', '3', '1', '3', '4', '6', '5', '4', '6'],
    ['5', '6', '5', '3', '6', '2', '1', '4', '2'],
    ['4', '6', '1', '5', '6', '4', '5', '2', '1'],
    ['5', '1', '4', '1', '6', '3', '4', '6', '5'],
    ['6', '5', '4', '1', '5', '6', '3', '4', '3'],
    ['4', '1', '2', '3', '2', '5', '3', '5', '6'],
    ['2', '6', '2', '6', '5', '1', '5', '4', '3'],
    ['5', '6', '4', '3', '1', '5', '1', '4', '3'],
    ['1', '4', '6', '2', '6', '5', '2', '4', '3'],
    ['5', '6', '1', '3', '4', '5', '6', '1', '3'],
    ['1', '2', '1', '4', '3', '6', '4', '2', '6'],
    ['4', '1', '6', '2', '6', '3', '2', '5', '3'],
    ['4', '2', '5', '3', '6', '5', '2', '4', '1'],
    ['5', '6', '1', '2', '4', '5', '2', '3', '6'],
    ['6', '2', '5', '2', '1', '5', '4', '1', '3'],
    ['2', '5', '1', '3', '5', '2', '3', '6', '4'],
    ['2', '5', '1', '6', '1', '4', '3', '5', '2'],
    ['1', '4', '2', '1', '4', '2', '5', '3', '6'],
    ['4', '5', '3', '4', '6', '3', '2', '6', '5'],
    ['1', '6', '3', '5', '4', '2', '3', '2', '4'],
    ['5', '3', '6', '5', '1', '4', '3', '1', '2'],
    ['3', '1', '3', '6', '1', '5', '2', '4', '5'],
    ['3', '5', '3', '4', '1', '5', '1', '2', '6'],
    ['4', '6', '2', '3', '1', '4', '2', '5', '3'],
    ['2', '3', '6', '2', '3', '5', '6', '4', '1'],
    ['4', '5', '3', '1', '2', '1', '2', '3', '4'],
    ['3', '6', '1', '4', '6', '4', '1', '5', '2'],
    ['6', '2', '5', '4', '1', '4', '2', '1', '6'],
    ['5', '1', '3', '1', '3', '5', '6', '2', '4'],
    ['2', '3', '2', '6', '4', '3', '1', '6', '1'],
    ['2', '3', '1', '3', '2', '6', '4', '5', '1'],
    ['2', '5', '3', '2', '6', '3', '6', '5', '4'],
    ['4', '2', '5', '1', '4', '6', '2', '3', '5'],
    ['5', '6', '4', '3', '4', '6', '2', '5', '3'],
    ['2', '3', '6', '1', '2', '1', '5', '3', '4']
  ],
  10: [
    ['1', '5', '4', '2', '4', '1', '5', '2', '6', '3'],
    ['1', '5', '2', '4', '1', '2', '6', '3', '6', '5'],
    ['2', '5', '2', '1', '5', '4', '6', '1', '3', '4'],
    ['4', '3', '6', '5', '6', '5', '2', '4', '2', '1'],
    ['4', '5', '1', '2', '3', '5', '3', '1', '2', '4'],
    ['6', '4', '3', '6', '3', '1', '2', '1', '5', '4'],
    ['1', '4', '3', '4', '6', '5', '3', '2', '6', '1'],
    ['4', '2', '3', '5', '2', '3', '1', '6', '5', '1'],
    ['5', '3', '5', '6', '3', '2', '4', '6', '2', '4'],
    ['6', '4', '3', '6', '5', '4', '2', '3', '1', '5'],
    ['1', '4', '5', '6', '2', '5', '3', '6', '3', '4'],
    ['2', '1', '6', '1', '3', '4', '2', '3', '5', '6'],
    ['4', '2', '1', '3', '2', '6', '1', '3', '6', '5'],
    ['6', '3', '1', '4', '3', '1', '5', '6', '4', '2'],
    ['2', '5', '6', '5', '3', '6', '3', '2', '1', '4'],
    ['6', '5', '2', '1', '4', '1', '2', '3', '4', '6'],
    ['5', '3', '5', '6', '1', '3', '2', '6', '4', '2'],
    ['4', '6', '1', '6', '1', '4', '5', '2', '3', '5'],
    ['1', '5', '3', '4', '3', '1', '4', '2', '5', '6'],
    ['2', '6', '3', '1', '4', '5', '2', '1', '5', '4'],
    ['4', '5', '6', '2', '3', '2', '4', '3', '5', '6'],
    ['4', '1', '4', '3', '5', '6', '2', '1', '6', '5'],
    ['2', '6', '1', '4', '6', '1', '2', '4', '5', '3'],
    ['5', '6', '5', '6', '2', '3', '2', '3', '1', '4'],
    ['6', '1', '2', '3', '1', '2', '4', '6', '4', '5'],
    ['4', '2', '1', '6', '3', '5', '2', '3', '5', '6'],
    ['1', '5', '1', '4', '3', '2', '5', '6', '2', '6'],
    ['4', '6', '2', '4', '6', '5', '3', '2', '1', '5'],
    ['5', '3', '4', '5', '6', '4', '2', '3', '2', '1'],
    ['2', '3', '5', '4', '6', '2', '5', '1', '4', '6'],
    ['4', '1', '3', '6', '3', '1', '5', '4', '2', '5'],
    ['4', '3', '4', '3', '5', '1', '2', '5', '6', '1'],
    ['4', '1', '3', '5', '4', '1', '6', '2', '6', '3'],
    ['5', '6', '4', '1', '5', '4', '1', '2', '3', '2'],
    ['6', '2', '6', '1', '2', '5', '3', '4', '1', '3'],
    ['4', '3', '6', '3', '2', '5', '4', '5', '6', '2'],
    ['6', '3', '6', '4', '1', '2', '4', '5', '2', '5'],
    ['1', '3', '4', '6', '3', '1', '6', '4', '2', '5'],
    ['5', '6', '4', '2', '3', '1', '3', '6', '5', '1'],
    ['2', '6', '3', '2', '3', '5', '4', '5', '1', '4'],
    ['3', '6', '5', '6', '4', '1', '2', '1', '5', '2'],
    ['2', '3', '5', '2', '6', '5', '3', '6', '1', '4'],
    ['5', '3', '6', '1', '5', '1', '6', '4', '2', '4'],
    ['5', '1', '5', '4', '2', '4', '1', '3', '6', '2'],
    ['6', '4', '3', '2', '5', '1', '6', '2', '4', '3'],
    ['5', '4', '5', '4', '3', '2', '6', '1', '6', '1'],
    ['1', '4', '2', '3', '6', '1', '6', '2', '5', '4'],
    ['3', '2', '6', '5', '1', '3', '4', '6', '4', '1'],
    ['6', '1', '2', '3', '6', '1', '4', '3', '5', '2'],
    ['4', '2', '4', '3', '5', '3', '2', '5', '6', '1'],
    ['6', '1', '2', '6', '2', '1', '3', '4', '5', '4'],
    ['3', '4', '3', '2', '6', '1', '6', '2', '5', '1'],
    ['6', '2', '6', '5', '4', '1', '4', '2', '1', '5'],
    ['1', '4', '3', '5', '3', '1', '5', '2', '6', '2'],
    ['1', '5', '6', '3', '5', '3', '1', '2', '6', '2'],
    ['6', '3', '1', '2', '5', '1', '3', '5', '2', '6'],
    ['3', '2', '6', '3', '1', '2', '6', '4', '1', '4'],
    ['6', '3', '1', '5', '3', '6', '4', '1', '2', '5'],
    ['5', '1', '3', '2', '3', '6', '1', '6', '2', '5'],
    ['5', '4', '6', '2', '6', '1', '5', '2', '1', '4'],
    ['4', '5', '4', '3', '1', '3', '2', '6', '5', '1'],
    ['1', '3', '6', '5', '4', '2', '1', '5', '2', '4'],
    ['6', '1', '3', '1', '6', '5', '3', '5', '2', '4'],
    ['6', '5', '1', '5', '3', '2', '6', '4', '2', '4'],
    ['5', '3', '2', '5', '2', '1', '3', '6', '1', '4'],
    ['5', '4', '1', '4', '2', '6', '5', '6', '1', '3'],
    ['6', '1', '5', '6', '5', '3', '2', '4', '3', '2'],
    ['4', '2', '1', '2', '6', '1', '6', '3', '4', '5'],
    ['5', '6', '5', '3', '1', '3', '4', '2', '4', '1'],
    ['3', '2', '4', '5', '4', '1', '2', '5', '1', '3'],
    ['1', '6', '1', '5', '6', '3', '5', '4', '3', '2'],
    ['1', '5', '6', '3', '2', '1', '2', '6', '3', '4'],
    ['2', '6', '4', '1', '6', '2', '4', '5', '1', '3'],
    ['4', '5', '1', '3', '1', '6', '5', '2', '4', '3'],
    ['5', '2', '1', '2', '3', '5', '1', '6', '3', '6'],
    ['2', '5', '1', '2', '5', '1', '4', '3', '6', '3'],
    ['2', '1', '3', '5', '4', '2', '5', '3', '4', '1'],
    ['5', '6', '2', '4', '6', '3', '1', '3', '2', '5'],
    ['6', '2', '6', '3', '2', '1', '3', '1', '5', '4'],
    ['1', '5', '6', '4', '3', '5', '2', '4', '6', '1'],
    ['5', '6', '2', '4', '5', '2', '4', '1', '6', '3'],
    ['2', '6', '1', '2', '6', '4', '3', '1', '3', '5'],
    ['6', '1', '5', '2', '6', '1', '2', '5', '3', '4'],
    ['6', '4', '3', '2', '1', '3', '4', '2', '6', '5'],
    ['4', '1', '6', '5', '2', '5', '3', '1', '6', '3'],
    ['2', '1', '2', '6', '1', '6', '3', '5', '4', '3'],
    ['2', '1', '3', '1', '4', '2', '6', '3', '5', '4'],
    ['5', '2', '1', '5', '3', '6', '1', '4', '6', '2'],
    ['2', '1', '4', '6', '5', '6', '4', '1', '5', '2'],
    ['6', '3', '2', '3', '2', '1', '6', '4', '5', '4'],
    ['4', '2', '1', '4', '2', '6', '3', '5', '3', '6'],
    ['2', '6', '3', '5', '2', '4', '3', '6', '1', '4'],
    ['4', '6', '4', '6', '3', '5', '1', '2', '3', '5'],
    ['5', '3', '1', '4', '1', '5', '3', '4', '2', '6'],
    ['3', '5', '4', '2', '3', '2', '4', '5', '6', '1'],
    ['2', '3', '2', '4', '1', '3', '1', '6', '5', '4'],
    ['3', '6', '3', '6', '4', '2', '4', '1', '2', '5'],
    ['6', '1', '3', '6', '5', '3', '5', '4', '1', '2'],
    ['1', '6', '4', '6', '2', '3', '2', '4', '5', '1'],
    ['2', '6', '2', '3', '5', '1', '3', '4', '1', '5']
  ],
  11: [
    ['4', '2', '3', '6', '4', '3', '6', '1', '5', '2', '1'],
    ['2', '3', '1', '4', '6', '1', '4', '2', '5', '3', '5'],
    ['3', '5', '4', '1', '4', '6', '5', '2', '1', '3', '6'],
    ['6', '5', '1', '6', '4', '3', '2', '1', '2', '3', '5'],
    ['1', '6', '2', '4', '1', '6', '5', '3', '4', '2', '5'],
    ['3', '1', '2', '5', '4', '5', '1', '4', '6', '3', '2'],
    ['5', '6', '3', '1', '3', '5', '1', '2', '4', '6', '4'],
    ['5', '2', '6', '5', '1', '2', '1', '3', '6', '4', '3'],
    ['4', '3', '5', '1', '2', '3', '1', '2', '5', '4', '6'],
    ['4', '1', '3', '1', '4', '6', '3', '6', '5', '2', '5'],
    ['4', '2', '1', '3', '5', '3', '5', '6', '4', '6', '1'],
    ['6', '5', '6', '1', '5', '4', '2', '4', '1', '3', '2'],
    ['2', '5', '1', '4', '6', '5', '1', '2', '6', '4', '3'],
    ['1', '2', '5', '6', '1', '6', '2', '4', '5', '3', '4'],
    ['1', '2', '4', '3', '4', '6', '3', '1', '6', '5', '2'],
    ['1', '4', '5', '4', '5', '3', '1', '3', '6', '2', '6'],
    ['5', '1', '6', '5', '4', '6', '1', '4', '2', '3', '2'],
    ['2', '6', '2', '3', '1', '3', '6', '5', '4', '5', '1'],
    ['1', '5', '4', '3', '5', '2', '1', '4', '6', '2', '3'],
    ['1', '4', '5', '6', '5', '1', '2', '3', '6', '4', '3'],
    ['5', '1', '2', '3', '4', '5', '6', '3', '4', '2', '6'],
    ['3', '1', '6', '5', '6', '3', '4', '1', '4', '5', '2'],
    ['6', '2', '6', '3', '1', '2', '1', '3', '4', '5', '4'],
    ['6', '2', '1', '5', '6', '5', '2', '3', '4', '1', '3'],
    ['5', '3', '5', '3', '4', '6', '4', '6', '1', '2', '1'],
    ['6', '2', '6', '3', '1', '3', '1', '2', '4', '5', '4'],
    ['1', '6', '2', '4', '5', '3', '4', '3', '2', '5', '1'],
    ['6', '2', '6', '1', '3', '5', '2', '3', '5', '4', '1'],
    ['1', '4', '5', '4', '3', '5', '6', '2', '1', '3', '6'],
    ['6', '3', '2', '5', '2', '1', '5', '3', '6', '4', '1'],
    ['5', '4', '6', '5', '2', '6', '4', '1', '3', '1', '3'],
    ['3', '1', '2', '6', '5', '6', '2', '4', '3', '4', '5'],
    ['5', '6', '4', '5', '2', '4', '2', '3', '1', '6', '1'],
    ['4', '6', '4', '2', '6', '5', '1', '2', '3', '5', '1'],
    ['3', '4', '1', '2', '4', '5', '2', '3', '6', '1', '6'],
    ['5', '4', '1', '3', '4', '6', '5', '3', '2', '6', '2'],
    ['3', '6', '1', '5', '1', '2', '6', '2', '4', '3', '5'],
    ['2', '5', '3', '5', '1', '6', '3', '6', '1', '2', '4'],
    ['4', '6', '1', '3', '2', '6', '5', '3', '4', '5', '1'],
    ['4', '5', '6', '3', '4', '1', '2', '5', '6', '3', '2'],
    ['3', '1', '6', '5', '1', '2', '4', '3', '2', '4', '6'],
    ['5', '3', '2', '4', '5', '3', '6', '4', '6', '1', '2'],
    ['2', '3', '6', '2', '3', '1', '5', '1', '4', '6', '5'],
    ['2', '5', '2', '3', '4', '5', '4', '6', '3', '6', '1'],
    ['2', '1', '5', '6', '3', '1', '3', '2', '6', '5', '4'],
    ['5', '3', '5', '3', '6', '1', '2', '6', '2', '4', '1'],
    ['4', '5', '4', '1', '6', '3', '6', '3', '2', '1', '2'],
    ['3', '4', '6', '5', '4', '1', '5', '6', '3', '1', '2'],
    ['6', '3', '2', '4', '2', '1', '6', '5', '1', '4', '3'],
    ['6', '2', '4', '6', '5', '3', '4', '2', '1', '5', '1'],
    ['6', '1', '4', '5', '1', '4', '3', '6', '3', '2', '5'],
    ['5', '3', '1', '2', '6', '4', '6', '3', '1', '4', '2'],
    ['2', '3', '1', '2', '3', '5', '6', '4', '1', '6', '4'],
    ['1', '3', '6', '2', '1', '6', '5', '4', '5', '4', '3'],
    ['6', '3', '4', '5', '6', '1', '4', '2', '1', '2', '3'],
    ['5', '2', '5', '1', '4', '2', '3', '6', '3', '6', '1'],
    ['6', '1', '5', '6', '2', '3', '5', '1', '2', '4', '3'],
    ['6', '3', '6', '5', '4', '2', '5', '1', '3', '1', '2'],
    ['3', '2', '4', '6', '1', '3', '5', '6', '5', '2', '1'],
    ['5', '3', '1', '2', '1', '3', '4', '6', '4', '6', '2'],
    ['4', '6', '4', '5', '1', '2', '5', '3', '2', '6', '1'],
    ['6', '5', '3', '2', '4', '1', '5', '1', '4', '3', '2'],
    ['4', '2', '3', '1', '4', '1', '3', '5', '2', '6', '5'],
    ['2', '3', '5', '2', '5', '3', '6', '4', '1', '4', '6'],
    ['4', '2', '5', '1', '3', '5', '2', '3', '6', '1', '6'],
    ['5', '3', '5', '2', '1', '2', '1', '3', '6', '4', '6'],
    ['6', '4', '2', '3', '5', '1', '3', '5', '1', '2', '6'],
    ['5', '6', '4', '1', '4', '6', '3', '2', '5', '1', '3'],
    ['5', '2', '5', '1', '3', '2', '6', '4', '6', '3', '4'],
    ['4', '6', '3', '5', '1', '4', '5', '3', '2', '1', '6'],
    ['1', '4', '2', '3', '6', '3', '6', '1', '5', '4', '2'],
    ['1', '5', '6', '3', '4', '2', '3', '1', '2', '4', '5'],
    ['4', '1', '6', '4', '2', '6', '1', '3', '5', '2', '3'],
    ['6', '2', '5', '3', '1', '2', '5', '4', '1', '3', '6'],
    ['3', '1', '2', '5', '2', '3', '4', '5', '6', '4', '6'],
    ['3', '6', '5', '6', '3', '2', '5', '4', '2', '1', '4'],
    ['4', '1', '6', '4', '2', '6', '1', '2', '5', '3', '5'],
    ['1', '6', '5', '3', '5', '6', '4', '2', '1', '3', '4'],
    ['3', '4', '6', '3', '1', '5', '6', '4', '1', '5', '2'],
    ['1', '4', '6', '3', '2', '1', '5', '6', '4', '2', '3'],
    ['3', '5', '6', '4', '6', '3', '1', '4', '1', '2', '5'],
    ['4', '3', '6', '2', '5', '4', '6', '1', '2', '1', '5'],
    ['3', '1', '4', '2', '5', '6', '4', '2', '1', '5', '3'],
    ['4', '2', '1', '5', '1', '5', '6', '2', '3', '4', '6'],
    ['4', '2', '5', '2', '4', '3', '6', '5', '6', '1', '3'],
    ['4', '3', '6', '1', '2', '3', '1', '6', '4', '2', '5'],
    ['5', '4', '6', '5', '3', '4', '6', '2', '3', '2', '1'],
    ['5', '4', '3', '1', '5', '4', '6', '2', '1', '6', '3'],
    ['2', '6', '4', '6', '2', '5', '4', '1', '3', '1', '3'],
    ['4', '2', '3', '4', '3', '5', '2', '5', '1', '6', '1'],
    ['2', '4', '3', '2', '3', '6', '4', '6', '1', '5', '1'],
    ['4', '5', '6', '5', '6', '3', '2', '4', '2', '3', '1'],
    ['1', '5', '4', '1', '2', '6', '2', '3', '6', '5', '4'],
    ['2', '1', '5', '3', '2', '6', '5', '4', '3', '4', '6'],
    ['6', '5', '1', '5', '1', '6', '4', '3', '2', '3', '2'],
    ['3', '1', '4', '1', '4', '5', '3', '6', '5', '2', '6'],
    ['3', '1', '6', '4', '1', '2', '6', '3', '5', '2', '5'],
    ['2', '4', '3', '4', '6', '3', '1', '2', '1', '5', '6'],
    ['1', '6', '4', '5', '4', '3', '6', '3', '2', '5', '2'],
    ['1', '3', '1', '5', '2', '3', '6', '2', '6', '4', '5']
  ],
  12: [
    ['3', '4', '2', '3', '5', '6', '4', '5', '2', '1', '5', '1'],
    ['2', '4', '5', '1', '2', '1', '5', '4', '1', '6', '4', '5'],
    ['6', '5', '4', '3', '1', '6', '5', '4', '2', '6', '5', '3'],
    ['4', '6', '1', '2', '3', '4', '3', '4', '6', '3', '6', '1'],
    ['2', '4', '6', '5', '6', '1', '4', '6', '5', '4', '3', '5'],
    ['5', '4', '5', '1', '2', '6', '1', '6', '4', '2', '1', '2'],
    ['5', '1', '4', '6', '2', '6', '5', '4', '3', '6', '5', '1'],
    ['6', '3', '1', '5', '2', '3', '1', '3', '2', '1', '6', '4'],
    ['2', '1', '6', '2', '4', '3', '6', '4', '6', '4', '1', '2'],
    ['3', '2', '1', '4', '1', '6', '1', '4', '6', '3', '5', '3'],
    ['5', '3', '4', '3', '2', '5', '3', '1', '2', '4', '5', '4'],
    ['5', '3', '2', '1', '2', '4', '2', '6', '5', '4', '1', '6'],
    ['2', '5', '1', '5', '1', '3', '5', '3', '2', '6', '1', '4'],
    ['6', '1', '3', '2', '1', '5', '4', '5', '1', '6', '2', '6'],
    ['4', '2', '4', '6', '4', '2', '3', '6', '1', '2', '5', '1'],
    ['1', '2', '3', '5', '3', '4', '3', '6', '5', '6', '1', '2'],
    ['5', '4', '6', '2', '1', '3', '2', '5', '1', '2', '1', '6'],
    ['2', '4', '1', '6', '3', '1', '5', '4', '5', '2', '5', '2'],
    ['1', '4', '2', '6', '4', '5', '3', '5', '1', '4', '6', '3'],
    ['5', '3', '1', '6', '1', '3', '1', '3', '5', '2', '5', '2'],
    ['2', '6', '4', '3', '4', '6', '1', '6', '2', '1', '4', '5'],
    ['4', '2', '1', '4', '3', '1', '2', '1', '4', '3', '6', '2'],
    ['4', '1', '3', '4', '5', '6', '3', '2', '5', '1', '2', '1'],
    ['5', '4', '3', '4', '1', '3', '5', '4', '5', '6', '1', '3'],
    ['4', '5', '2', '1', '4', '2', '6', '1', '6', '1', '5', '6'],
    ['5', '2', '5', '4', '5', '4', '1', '6', '2', '3', '6', '1'],
    ['2', '5', '1', '3', '1', '5', '1', '4', '2', '6', '5', '4'],
    ['4', '5', '4', '2', '4', '6', '2', '3', '1', '3', '5', '2'],
    ['3', '5', '2', '1', '4', '3', '6', '5', '1', '4', '5', '1'],
    ['2', '1', '4', '3', '5', '1', '6', '1', '4', '5', '3', '4'],
    ['1', '6', '4', '1', '6', '3', '1', '2', '6', '3', '4', '3'],
    ['2', '5', '3', '6', '2', '1', '3', '5', '4', '2', '1', '6'],
    ['6', '1', '3', '4', '3', '2', '1', '5', '6', '5', '1', '3'],
    ['4', '3', '5', '2', '4', '3', '1', '6', '1', '3', '2', '1'],
    ['2', '1', '3', '2', '4', '1', '5', '2', '6', '4', '5', '6'],
    ['2', '1', '3', '2', '1', '3', '5', '2', '6', '3', '4', '6'],
    ['6', '5', '4', '3', '5', '3', '1', '3', '2', '4', '1', '2'],
    ['3', '1', '3', '4', '1', '3', '2', '6', '4', '6', '5', '1'],
    ['6', '2', '6', '4', '5', '4', '1', '5', '3', '6', '4', '2'],
    ['4', '2', '3', '5', '2', '5', '1', '3', '5', '2', '6', '4'],
    ['3', '4', '6', '5', '6', '3', '2', '4', '1', '6', '3', '2'],
    ['6', '3', '5', '3', '1', '3', '1', '6', '2', '5', '1', '6'],
    ['4', '3', '6', '4', '1', '3', '5', '2', '4', '2', '5', '2'],
    ['1', '3', '6', '2', '1', '5', '1', '3', '2', '5', '3', '6'],
    ['3', '2', '4', '3', '2', '5', '2', '3', '1', '6', '1', '5'],
    ['4', '3', '6', '3', '1', '3', '4', '5', '6', '5', '4', '2'],
    ['5', '3', '6', '4', '3', '1', '5', '2', '4', '1', '5', '4'],
    ['3', '5', '2', '3', '6', '4', '2', '4', '3', '1', '4', '1'],
    ['4', '3', '5', '6', '4', '2', '5', '1', '3', '4', '6', '5'],
    ['2', '3', '5', '1', '5', '4', '3', '6', '2', '5', '1', '4'],
    ['4', '2', '5', '2', '5', '1', '5', '3', '2', '4', '1', '4'],
    ['4', '1', '4', '6', '1', '3', '4', '3', '5', '6', '1', '6'],
    ['2', '1', '4', '3', '6', '4', '1', '2', '6', '1', '5', '6'],
    ['2', '1', '5', '4', '3', '6', '4', '3', '4', '1', '6', '2'],
    ['3', '4', '6', '4', '1', '5', '3', '4', '6', '3', '5', '2'],
    ['3', '2', '6', '2', '5', '2', '6', '4', '5', '4', '1', '5'],
    ['3', '4', '3', '1', '2', '4', '2', '5', '2', '4', '5', '1'],
    ['3', '2', '3', '4', '6', '4', '2', '4', '5', '6', '5', '2'],
    ['3', '6', '5', '6', '4', '3', '6', '2', '3', '2', '1', '4'],
    ['1', '3', '4', '2', '3', '2', '6', '5', '1', '5', '2', '6'],
    ['2', '6', '1', '3', '4', '6', '4', '3', '5', '4', '2', '3'],
    ['5', '4', '3', '1', '3', '1', '6', '5', '2', '5', '1', '3'],
    ['1', '4', '5', '3', '6', '2', '5', '2', '6', '4', '1', '5'],
    ['3', '6', '1', '4', '6', '2', '5', '4', '1', '3', '1', '2'],
    ['5', '6', '2', '1', '6', '2', '1', '6', '5', '3', '5', '4'],
    ['1', '4', '5', '1', '5', '3', '1', '6', '3', '5', '2', '3'],
    ['1', '4', '6', '3', '6', '1', '6', '3', '1', '3', '5', '2'],
    ['6', '3', '1', '3', '6', '3', '5', '6', '5', '4', '2', '5'],
    ['4', '1', '3', '2', '3', '4', '3', '6', '2', '1', '5', '1'],
    ['5', '4', '6', '1', '4', '6', '2', '3', '1', '4', '6', '1'],
    ['6', '1', '6', '1', '3', '5', '2', '6', '1', '4', '2', '4'],
    ['6', '5', '3', '6', '5', '2', '4', '3', '5', '6', '4', '3'],
    ['5', '1', '5', '2', '5', '3', '2', '1', '2', '1', '6', '4'],
    ['6', '2', '4', '1', '2', '6', '3', '6', '5', '4', '2', '3'],
    ['4', '6', '1', '4', '5', '1', '6', '1', '2', '3', '5', '3'],
    ['5', '1', '6', '4', '5', '3', '6', '4', '6', '1', '4', '3'],
    ['1', '2', '4', '3', '2', '1', '6', '3', '4', '1', '5', '2'],
    ['2', '6', '5', '1', '5', '6', '1', '3', '4', '2', '6', '5'],
    ['1', '2', '1', '6', '2', '3', '5', '3', '2', '3', '6', '4'],
    ['4', '3', '5', '6', '2', '4', '6', '3', '2', '1', '5', '2'],
    ['5', '4', '2', '5', '6', '1', '3', '6', '3', '1', '5', '1'],
    ['6', '1', '2', '5', '2', '5', '6', '3', '6', '5', '3', '1'],
    ['1', '6', '2', '3', '5', '2', '5', '6', '1', '5', '3', '6'],
    ['4', '3', '4', '5', '6', '2', '3', '6', '2', '1', '5', '2'],
    ['5', '1', '5', '1', '5', '3', '1', '2', '4', '6', '4', '6'],
    ['4', '1', '3', '6', '4', '1', '4', '5', '2', '3', '1', '3'],
    ['1', '4', '5', '4', '3', '1', '6', '1', '4', '2', '5', '2'],
    ['5', '4', '1', '6', '5', '1', '2', '1', '4', '5', '2', '4'],
    ['6', '4', '2', '4', '1', '6', '5', '1', '4', '2', '1', '5'],
    ['3', '1', '2', '5', '2', '4', '6', '3', '5', '2', '6', '1'],
    ['5', '4', '3', '5', '4', '2', '6', '5', '4', '6', '1', '2'],
    ['4', '6', '5', '4', '5', '4', '6', '3', '5', '2', '3', '6'],
    ['4', '5', '6', '2', '6', '5', '4', '6', '2', '5', '1', '3'],
    ['1', '6', '3', '2', '3', '2', '5', '3', '1', '6', '4', '2'],
    ['3', '6', '5', '6', '4', '2', '3', '6', '4', '3', '5', '2'],
    ['6', '1', '3', '1', '4', '2', '1', '5', '6', '5', '4', '3'],
    ['3', '2', '4', '1', '5', '3', '5', '4', '5', '4', '3', '6'],
    ['6', '5', '3', '4', '6', '5', '1', '4', '6', '1', '3', '5'],
    ['3', '6', '2', '1', '3', '5', '2', '4', '6', '1', '6', '1'],
    ['3', '5', '6', '4', '6', '1', '6', '4', '5', '3', '2', '3']
  ],
  13: [
    ['4', '5', '6', '2', '6', '3', '6', '5', '3', '1', '5', '1', '4'],
    ['4', '3', '6', '1', '4', '2', '1', '6', '5', '2', '3', '6', '1'],
    ['6', '2', '3', '2', '5', '4', '5', '1', '3', '5', '6', '2', '3'],
    ['3', '4', '2', '5', '1', '2', '1', '3', '4', '3', '1', '4', '5'],
    ['3', '6', '1', '3', '4', '1', '5', '3', '5', '6', '4', '5', '4'],
    ['1', '3', '6', '4', '1', '4', '1', '5', '6', '4', '6', '5', '2'],
    ['6', '4', '5', '3', '4', '3', '2', '3', '2', '6', '4', '5', '1'],
    ['2', '1', '2', '4', '1', '5', '1', '5', '6', '3', '5', '6', '2'],
    ['3', '4', '3', '1', '4', '6', '4', '1', '3', '1', '2', '5', '6'],
    ['3', '1', '3', '5', '3', '1', '6', '5', '6', '4', '1', '6', '4'],
    ['1', '6', '2', '4', '3', '6', '1', '4', '3', '1', '5', '4', '6'],
    ['2', '1', '5', '3', '5', '6', '4', '1', '5', '4', '2', '4', '3'],
    ['3', '2', '3', '4', '3', '1', '4', '5', '1', '4', '5', '2', '5'],
    ['2', '4', '5', '6', '2', '6', '3', '4', '2', '5', '1', '3', '4'],
    ['1', '6', '1', '6', '3', '5', '2', '4', '1', '3', '6', '4', '3'],
    ['2', '1', '2', '1', '5', '4', '5', '3', '4', '1', '3', '5', '6'],
    ['2', '3', '4', '3', '4', '6', '1', '6', '4', '3', '6', '2', '1'],
    ['1', '4', '2', '1', '6', '3', '6', '5', '1', '4', '5', '2', '5'],
    ['6', '2', '5', '4', '6', '4', '3', '4', '6', '1', '3', '5', '1'],
    ['6', '4', '6', '4', '6', '1', '5', '1', '4', '3', '5', '3', '1'],
    ['2', '4', '5', '3', '4', '2', '3', '5', '4', '3', '6', '2', '5'],
    ['3', '2', '4', '3', '1', '4', '5', '1', '2', '6', '4', '6', '2'],
    ['5', '4', '6', '4', '5', '6', '2', '4', '6', '1', '2', '3', '5'],
    ['3', '2', '5', '2', '4', '1', '5', '6', '1', '5', '3', '2', '1'],
    ['4', '1', '3', '4', '2', '5', '4', '2', '5', '1', '5', '6', '3'],
    ['2', '3', '1', '6', '3', '2', '6', '3', '4', '5', '4', '2', '4'],
    ['2', '5', '2', '5', '2', '1', '4', '1', '5', '3', '1', '6', '3'],
    ['4', '6', '3', '2', '6', '5', '3', '1', '2', '4', '5', '1', '4'],
    ['2', '5', '4', '1', '2', '3', '2', '4', '5', '6', '3', '5', '6'],
    ['5', '1', '4', '2', '1', '5', '2', '6', '5', '2', '4', '6', '1'],
    ['4', '5', '3', '4', '3', '2', '6', '4', '6', '2', '3', '2', '1'],
    ['3', '4', '6', '2', '4', '6', '3', '4', '3', '1', '5', '2', '5'],
    ['5', '4', '3', '1', '5', '2', '6', '3', '5', '6', '4', '3', '6'],
    ['1', '2', '4', '5', '1', '4', '1', '4', '5', '6', '2', '5', '3'],
    ['6', '3', '2', '6', '3', '1', '3', '5', '2', '5', '1', '4', '5'],
    ['1', '2', '1', '6', '4', '1', '6', '4', '6', '4', '2', '5', '3'],
    ['4', '1', '5', '3', '4', '5', '6', '4', '2', '6', '2', '1', '5'],
    ['2', '1', '6', '5', '6', '5', '2', '6', '2', '3', '1', '3', '1'],
    ['5', '1', '2', '6', '4', '5', '3', '1', '4', '3', '2', '3', '6'],
    ['5', '4', '3', '1', '4', '5', '2', '5', '1', '2', '4', '6', '1'],
    ['2', '6', '5', '6', '5', '2', '3', '4', '5', '1', '3', '4', '6'],
    ['4', '3', '4', '2', '6', '3', '2', '1', '3', '2', '5', '1', '6'],
    ['4', '3', '2', '3', '1', '2', '1', '3', '1', '4', '6', '4', '6'],
    ['6', '5', '2', '5', '3', '2', '6', '2', '1', '6', '5', '3', '1'],
    ['3', '1', '4', '6', '4', '3', '2', '4', '3', '6', '1', '2', '5'],
    ['2', '1', '5', '6', '5', '2', '3', '6', '2', '4', '6', '4', '1'],
    ['3', '2', '3', '6', '5', '2', '3', '4', '5', '6', '1', '2', '5'],
    ['2', '3', '6', '1', '3', '1', '6', '1', '6', '2', '5', '2', '3'],
    ['4', '5', '4', '1', '5', '3', '1', '6', '5', '1', '2', '4', '3'],
    ['5', '6', '2', '4', '5', '2', '6', '4', '6', '1', '4', '1', '5'],
    ['6', '2', '5', '6', '3', '5', '2', '6', '2', '3', '4', '3', '5'],
    ['5', '2', '6', '2', '6', '5', '1', '4', '2', '1', '5', '1', '4'],
    ['2', '4', '1', '3', '4', '2', '6', '3', '2', '5', '3', '5', '1'],
    ['1', '3', '2', '3', '2', '3', '1', '5', '1', '2', '4', '5', '6'],
    ['1', '3', '5', '3', '2', '6', '2', '6', '4', '5', '4', '2', '6'],
    ['2', '6', '4', '3', '5', '2', '1', '3', '5', '6', '2', '6', '1'],
    ['5', '1', '3', '5', '2', '1', '4', '5', '3', '2', '4', '6', '4'],
    ['5', '2', '3', '5', '6', '3', '2', '4', '1', '4', '6', '3', '6'],
    ['6', '1', '6', '3', '6', '3', '5', '1', '5', '1', '4', '2', '3'],
    ['4', '3', '4', '5', '3', '5', '1', '4', '5', '1', '3', '2', '6'],
    ['4', '3', '2', '5', '3', '6', '2', '6', '1', '3', '6', '5', '4'],
    ['6', '5', '1', '2', '6', '2', '6', '2', '4', '3', '5', '3', '5'],
    ['6', '4', '1', '6', '1', '5', '1', '6', '3', '4', '5', '3', '4'],
    ['6', '1', '6', '1', '3', '1', '3', '6', '2', '5', '4', '2', '4'],
    ['2', '1', '3', '2', '4', '2', '4', '1', '3', '1', '4', '3', '5'],
    ['1', '2', '6', '5', '4', '2', '4', '3', '6', '5', '1', '6', '1'],
    ['2', '6', '1', '4', '1', '5', '6', '3', '4', '2', '3', '6', '1'],
    ['6', '3', '6', '3', '4', '3', '1', '6', '4', '2', '4', '2', '1'],
    ['5', '3', '5', '3', '2', '1', '4', '5', '4', '1', '6', '4', '6'],
    ['4', '1', '3', '1', '4', '1', '5', '6', '3', '2', '3', '4', '6'],
    ['2', '6', '5', '3', '2', '1', '6', '5', '1', '5', '4', '2', '1'],
    ['3', '1', '5', '1', '2', '1', '2', '5', '2', '6', '4', '6', '4'],
    ['6', '1', '5', '2', '4', '6', '2', '3', '2', '6', '1', '4', '3'],
    ['2', '4', '6', '5', '3', '1', '4', '1', '4', '3', '2', '6', '5'],
    ['2', '1', '3', '2', '4', '3', '2', '6', '1', '6', '3', '1', '4'],
    ['5', '6', '1', '3', '5', '6', '3', '2', '5', '3', '6', '2', '1'],
    ['5', '3', '2', '5', '6', '1', '2', '3', '5', '2', '1', '4', '1'],
    ['5', '6', '2', '5', '6', '3', '1', '4', '6', '1', '2', '4', '5'],
    ['4', '3', '6', '2', '6', '3', '6', '3', '5', '2', '5', '4', '1'],
    ['3', '2', '5', '4', '1', '4', '3', '4', '6', '5', '6', '1', '2'],
    ['1', '2', '3', '5', '3', '5', '2', '1', '4', '3', '4', '6', '5'],
    ['3', '6', '4', '2', '5', '1', '5', '3', '2', '3', '2', '6', '4'],
    ['6', '2', '5', '1', '4', '5', '6', '3', '4', '6', '1', '3', '5'],
    ['5', '1', '5', '3', '4', '6', '2', '6', '4', '2', '1', '4', '1'],
    ['1', '3', '2', '6', '5', '6', '3', '1', '2', '6', '1', '3', '5'],
    ['6', '4', '3', '5', '6', '1', '3', '1', '3', '4', '2', '4', '2'],
    ['1', '5', '2', '5', '1', '6', '5', '1', '3', '2', '3', '4', '6'],
    ['3', '4', '5', '2', '6', '5', '2', '4', '2', '4', '3', '6', '5'],
    ['3', '2', '5', '6', '2', '1', '5', '3', '1', '5', '3', '2', '1'],
    ['5', '6', '5', '6', '4', '6', '1', '2', '5', '3', '4', '1', '3'],
    ['4', '5', '6', '4', '6', '2', '6', '2', '4', '3', '1', '5', '3'],
    ['1', '3', '5', '2', '1', '4', '5', '1', '4', '5', '4', '3', '6'],
    ['4', '6', '2', '4', '6', '1', '3', '1', '3', '2', '6', '2', '4'],
    ['2', '4', '6', '2', '4', '5', '6', '1', '2', '4', '3', '6', '5'],
    ['3', '5', '3', '5', '4', '3', '4', '1', '6', '5', '6', '1', '2'],
    ['6', '1', '3', '1', '3', '1', '4', '3', '6', '4', '6', '5', '2'],
    ['3', '1', '3', '1', '2', '1', '6', '2', '6', '5', '4', '6', '4'],
    ['4', '5', '1', '4', '1', '4', '3', '6', '2', '5', '3', '6', '2'],
    ['6', '2', '1', '3', '1', '4', '2', '4', '1', '5', '4', '6', '5'],
    ['4', '3', '1', '5', '6', '2', '1', '3', '4', '5', '2', '4', '6']
  ],
  14: [
    ['5', '4', '5', '4', '5', '2', '3', '2', '4', '3', '1', '6', '2', '3'],
    ['4', '5', '1', '4', '2', '6', '3', '5', '4', '5', '2', '1', '3', '2'],
    ['3', '5', '6', '4', '6', '5', '6', '1', '4', '3', '4', '1', '3', '5'],
    ['4', '6', '3', '5', '4', '1', '5', '1', '5', '4', '2', '6', '1', '6'],
    ['6', '4', '3', '1', '4', '2', '3', '4', '5', '3', '5', '2', '6', '5'],
    ['3', '6', '3', '6', '1', '2', '4', '1', '2', '1', '2', '3', '5', '4'],
    ['3', '5', '3', '4', '6', '3', '4', '6', '5', '2', '4', '5', '1', '6'],
    ['4', '3', '2', '5', '6', '3', '2', '3', '1', '6', '2', '1', '5', '1'],
    ['6', '2', '4', '3', '5', '4', '2', '3', '1', '2', '6', '5', '4', '6'],
    ['2', '1', '4', '6', '2', '1', '2', '5', '6', '5', '1', '4', '6', '5'],
    ['1', '3', '1', '4', '5', '2', '6', '3', '2', '4', '6', '3', '5', '2'],
    ['3', '5', '4', '5', '1', '6', '5', '4', '3', '2', '3', '6', '2', '6'],
    ['5', '4', '1', '2', '6', '4', '3', '6', '4', '3', '2', '6', '5', '2'],
    ['6', '5', '6', '4', '5', '1', '6', '3', '1', '5', '4', '3', '4', '1'],
    ['2', '5', '1', '3', '6', '5', '6', '1', '4', '5', '1', '2', '6', '2'],
    ['6', '4', '1', '2', '6', '3', '2', '6', '1', '2', '1', '5', '3', '4'],
    ['3', '6', '1', '4', '1', '3', '2', '5', '1', '5', '4', '6', '4', '3'],
    ['6', '1', '2', '3', '5', '1', '5', '2', '6', '4', '1', '6', '3', '2'],
    ['6', '5', '3', '2', '3', '4', '5', '1', '6', '1', '4', '3', '4', '5'],
    ['1', '2', '3', '2', '3', '5', '2', '5', '6', '3', '4', '1', '5', '1'],
    ['3', '2', '3', '4', '5', '1', '5', '3', '5', '4', '2', '1', '6', '4'],
    ['3', '1', '3', '2', '3', '5', '4', '2', '6', '2', '1', '4', '5', '6'],
    ['5', '4', '6', '1', '6', '2', '3', '4', '6', '4', '2', '5', '3', '2'],
    ['5', '1', '6', '5', '1', '3', '2', '6', '3', '1', '6', '5', '4', '2'],
    ['1', '6', '5', '4', '6', '5', '4', '6', '3', '4', '3', '2', '3', '2'],
    ['5', '2', '5', '2', '4', '2', '4', '6', '1', '5', '3', '6', '4', '6'],
    ['6', '4', '5', '1', '3', '2', '3', '1', '2', '6', '4', '3', '6', '4'],
    ['4', '6', '2', '5', '1', '4', '2', '3', '5', '6', '1', '2', '6', '5'],
    ['3', '1', '4', '2', '4', '2', '6', '1', '2', '6', '3', '4', '6', '5'],
    ['3', '2', '4', '2', '3', '2', '5', '6', '1', '5', '1', '6', '1', '4'],
    ['5', '2', '3', '5', '1', '4', '1', '5', '3', '6', '3', '2', '4', '1'],
    ['1', '4', '2', '1', '4', '1', '6', '4', '3', '2', '5', '2', '5', '3'],
    ['6', '4', '2', '6', '4', '1', '5', '3', '2', '3', '1', '2', '6', '1'],
    ['6', '1', '3', '2', '6', '3', '4', '2', '6', '2', '1', '4', '1', '5'],
    ['2', '5', '2', '5', '2', '3', '6', '4', '6', '3', '5', '3', '4', '1'],
    ['3', '4', '1', '6', '4', '3', '1', '3', '2', '5', '6', '4', '2', '5'],
    ['2', '5', '1', '4', '2', '4', '3', '1', '4', '2', '3', '5', '6', '1'],
    ['6', '3', '5', '2', '6', '3', '4', '2', '5', '4', '1', '6', '2', '1'],
    ['3', '2', '5', '6', '3', '5', '6', '5', '2', '1', '4', '6', '3', '4'],
    ['4', '1', '5', '1', '6', '4', '5', '3', '5', '2', '4', '1', '2', '6'],
    ['1', '2', '4', '5', '1', '2', '3', '1', '5', '6', '4', '6', '5', '4'],
    ['3', '2', '6', '2', '1', '2', '6', '4', '5', '1', '5', '4', '6', '4'],
    ['4', '5', '1', '4', '5', '6', '4', '5', '3', '1', '6', '3', '6', '3'],
    ['6', '1', '3', '4', '2', '6', '2', '6', '3', '4', '3', '1', '2', '1'],
    ['5', '1', '4', '3', '5', '2', '4', '6', '5', '6', '3', '4', '2', '3'],
    ['2', '1', '4', '5', '2', '5', '6', '4', '1', '6', '3', '5', '2', '6'],
    ['5', '2', '1', '3', '5', '4', '2', '1', '2', '4', '3', '5', '6', '1'],
    ['5', '1', '4', '5', '6', '1', '2', '5', '2', '4', '2', '6', '4', '6'],
    ['3', '5', '3', '1', '3', '4', '1', '5', '2', '5', '6', '4', '1', '6'],
    ['1', '6', '3', '4', '5', '4', '3', '1', '5', '1', '4', '3', '5', '6'],
    ['4', '5', '1', '3', '2', '4', '1', '4', '5', '6', '3', '2', '3', '5'],
    ['5', '1', '2', '4', '2', '5', '6', '4', '1', '3', '5', '3', '6', '4'],
    ['5', '4', '3', '6', '3', '6', '2', '5', '1', '6', '2', '3', '5', '2'],
    ['1', '3', '4', '5', '6', '1', '3', '2', '3', '4', '2', '1', '6', '2'],
    ['6', '3', '5', '3', '6', '5', '1', '5', '2', '1', '4', '2', '4', '3'],
    ['4', '3', '2', '6', '2', '5', '4', '3', '1', '3', '5', '2', '4', '6'],
    ['4', '3', '5', '4', '2', '1', '2', '3', '4', '5', '3', '2', '1', '5'],
    ['2', '1', '3', '1', '2', '6', '3', '1', '2', '4', '3', '4', '6', '5'],
    ['6', '2', '6', '3', '5', '6', '3', '2', '5', '2', '4', '5', '4', '3'],
    ['4', '6', '4', '1', '2', '4', '5', '2', '5', '2', '1', '6', '3', '1'],
    ['4', '2', '6', '3', '5', '2', '5', '3', '4', '2', '4', '6', '1', '3'],
    ['4', '6', '4', '5', '6', '5', '1', '5', '1', '4', '2', '1', '6', '2'],
    ['5', '2', '1', '6', '3', '1', '4', '1', '6', '3', '4', '3', '4', '2'],
    ['2', '1', '3', '2', '6', '3', '4', '2', '4', '3', '4', '1', '5', '6'],
    ['1', '4', '1', '5', '1', '6', '4', '5', '6', '4', '6', '3', '2', '3'],
    ['4', '1', '5', '1', '5', '1', '5', '2', '6', '2', '6', '4', '6', '3'],
    ['3', '5', '2', '1', '6', '2', '4', '2', '1', '3', '6', '5', '1', '4'],
    ['1', '6', '3', '1', '4', '1', '4', '5', '4', '2', '6', '3', '6', '3'],
    ['6', '1', '6', '5', '3', '5', '1', '3', '4', '3', '2', '4', '6', '2'],
    ['3', '2', '5', '4', '1', '5', '1', '6', '4', '2', '6', '2', '5', '1'],
    ['4', '3', '2', '3', '2', '3', '4', '6', '4', '5', '1', '5', '2', '6'],
    ['4', '2', '6', '3', '5', '4', '1', '6', '4', '6', '1', '3', '2', '1'],
    ['1', '2', '4', '1', '2', '1', '6', '4', '2', '3', '4', '5', '6', '3'],
    ['3', '5', '2', '3', '5', '3', '4', '1', '6', '5', '4', '2', '1', '2'],
    ['3', '2', '4', '6', '5', '6', '1', '4', '3', '4', '1', '5', '6', '3'],
    ['1', '2', '5', '6', '4', '3', '2', '4', '1', '4', '6', '5', '3', '6'],
    ['3', '5', '6', '2', '3', '5', '4', '3', '6', '2', '1', '6', '5', '1'],
    ['3', '1', '3', '4', '3', '1', '5', '2', '6', '4', '5', '1', '4', '6'],
    ['4', '3', '1', '4', '1', '2', '3', '1', '6', '5', '2', '4', '3', '2'],
    ['1', '6', '4', '5', '2', '1', '4', '5', '6', '3', '5', '3', '2', '4'],
    ['5', '4', '1', '4', '6', '2', '5', '4', '6', '3', '2', '5', '3', '1'],
    ['2', '3', '4', '2', '4', '3', '2', '3', '1', '4', '6', '5', '1', '6'],
    ['3', '4', '2', '4', '1', '2', '3', '5', '1', '6', '2', '5', '6', '3'],
    ['3', '4', '3', '5', '3', '4', '5', '4', '6', '1', '5', '6', '2', '1'],
    ['4', '2', '3', '2', '5', '4', '2', '6', '4', '1', '3', '6', '3', '6'],
    ['1', '2', '6', '2', '6', '5', '2', '6', '5', '1', '3', '5', '1', '3'],
    ['2', '6', '4', '5', '2', '1', '3', '5', '2', '1', '3', '5', '4', '6'],
    ['2', '3', '5', '2', '4', '3', '5', '6', '5', '2', '4', '3', '1', '6'],
    ['1', '5', '3', '6', '1', '4', '6', '2', '1', '4', '6', '5', '3', '5'],
    ['1', '2', '5', '4', '5', '6', '4', '6', '4', '1', '3', '5', '3', '2'],
    ['2', '6', '4', '2', '4', '5', '3', '2', '6', '4', '5', '3', '5', '3'],
    ['1', '3', '1', '5', '2', '6', '4', '1', '4', '2', '3', '6', '4', '2'],
    ['2', '4', '3', '4', '3', '5', '6', '4', '1', '3', '1', '2', '6', '5'],
    ['1', '3', '5', '2', '3', '1', '4', '5', '6', '5', '1', '4', '2', '4'],
    ['2', '6', '3', '2', '3', '4', '6', '1', '4', '5', '2', '4', '5', '6'],
    ['6', '3', '4', '2', '3', '5', '6', '2', '5', '3', '6', '1', '5', '1'],
    ['5', '1', '6', '4', '2', '4', '5', '2', '1', '2', '6', '4', '1', '3'],
    ['2', '4', '6', '4', '6', '4', '1', '2', '6', '5', '2', '3', '1', '5'],
    ['6', '1', '4', '5', '1', '2', '3', '5', '2', '4', '3', '5', '2', '4'],
    ['3', '4', '5', '3', '2', '3', '4', '1', '5', '4', '1', '6', '1', '2']
  ]
}
