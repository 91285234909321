export const PARTNER = `{
  id
  name
  display_name
  career_site_url
  app_settings {
    components
    challenges
    custom_apply_mail_template
    usedAlgorithm
    custom_data_policy
  }
  logo
  logo_ratio
  logo_alt
  createdAt
  updatedAt
}`
