import React, { useContext } from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import * as Clipboard from 'expo-clipboard'

import Progressbar from './main/Progressbar'
import Header from './main/Header'
import Switch from './Switch'
import Typo from './Typo'
import metrics from '../theme/metrics'
import { replaceTranslationKey, shouldShowCopyAivyId } from '../utils/helpers'
import { AuthContext, NotificationContext } from '../context'
import colors from '../theme/colors'
import SpacesProfileHeader from './SpacesProfileHeader'

const styles = StyleSheet.create({
  image: {
    position: 'absolute',
    resizeMode: 'contain',
    right: -16,
    bottom: 0,
    ...metrics.calculateResponsiveDimensions({ width: 123, height: 163 })
  },
  copyAivyIdBtn: {
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primaryBlue,
    borderRadius: 24
  }
})

const ListHeaderComponent = ({ currentSwitchTab, setCurrentSwitchTab, navigation }) => {
  const { rootStore } = useContext(AuthContext)
  const { cognitoUser } = rootStore.userStore
  const { challenges: appSettingsStoreChallenges, partner_id } = rootStore.appSettingsStore
  const {
    allChallengesCount,
    completedChallenges,
    progress,
    challenges: activeChallenges
  } = rootStore.challengesStore
  const { isAivySelect } = rootStore.spaceStore
  const { success } = useContext(NotificationContext)

  const copyAivyId = async () => {
    await Clipboard.setStringAsync(cognitoUser?.attributes?.sub)
    success(I18n.get('global.alert.success.aivyid'))
  }

  const getCTAText = () => {
    if (progress === 100) {
      return [I18n.get('successfunnel.title'), I18n.get('successfunnel.subtitle')]
    }

    if (progress > 0) {
      return isAivySelect
        ? [I18n.get('dashboard.next_header'), I18n.get('dashboard.next_text')]
        : [
            replaceTranslationKey(
              I18n.get('global.main.header_title'),
              '{{number}}',
              activeChallenges.length
            ),
            I18n.get('global.main.header_description')
          ]
    }
    return isAivySelect
      ? [I18n.get('global.ready'), I18n.get('dashboard.note')]
      : [
          replaceTranslationKey(
            I18n.get('global.main.header_title'),
            '{{number}}',
            activeChallenges.length
          ),
          I18n.get('global.main.header_description')
        ]
  }

  if (isAivySelect) {
    return (
      <View style={{ marginBottom: 26 }}>
        <SpacesProfileHeader navigation={navigation} />

        <View style={{ marginTop: 16 }} />

        <Header title={getCTAText()[0]} description={getCTAText()[1]} />
        {shouldShowCopyAivyId(partner_id) && isAivySelect && (
          <TouchableOpacity onPress={copyAivyId} style={[styles.copyAivyIdBtn, { marginTop: 12 }]}>
            <Typo.ButtonWhite translate>global.youraivyid</Typo.ButtonWhite>
          </TouchableOpacity>
        )}

        <Progressbar
          containerStyles={{ marginTop: 24 }}
          value={Math.min(
            completedChallenges.filter(
              (c) => !!appSettingsStoreChallenges.find((x) => x.exam_id === c.exam_id)
            ).length,
            allChallengesCount
          )} // CHECK HERE
          max={allChallengesCount}
        />
      </View>
    )
  }

  return (
    <View style={{ marginBottom: 26 }}>
      <SpacesProfileHeader navigation={navigation} />

      <View style={{ marginTop: 16 }} />
      <Header title={getCTAText()[0]} description={getCTAText()[1]} width='68%' />

      <View style={{ marginTop: 24 }}>
        <Image
          style={styles.image}
          source={require('../assets/images/illustrations/paint-artist.png')}
        />
        <Switch
          left={{
            label: 'global.main.switch_label_left',
            active: currentSwitchTab === 'TODO',
            onPress: () => {
              setCurrentSwitchTab('TODO')
            }
          }}
          right={{
            label: 'global.main.switch_label_right',
            active: currentSwitchTab === 'DONE',
            onPress: () => {
              setCurrentSwitchTab('DONE')
            }
          }}
        />
      </View>
    </View>
  )
}

ListHeaderComponent.propTypes = {
  currentSwitchTab: PropTypes.string.isRequired,
  setCurrentSwitchTab: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired
}

export default observer(ListHeaderComponent)
