import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import Typo from './Typo'
import colors from '../theme/colors'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'flex-end',
    backgroundColor: colors.oceanDarkBlue,
    paddingHorizontal: 20,
    paddingVertical: 16,
    shadowColor: colors.primaryBlue,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.3,
    shadowRadius: 8,
    elevation: 12
  }
})

const SignUp = ({ onPress }) => (
  <TouchableOpacity onPress={onPress} style={styles.container}>
    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typo.T2White style={{ width: '64%' }} translate>
        global.sign_up.text
      </Typo.T2White>
      <Typo.H4Green translate>global.sign_up.action</Typo.H4Green>
    </View>
  </TouchableOpacity>
)

SignUp.propTypes = {
  onPress: PropTypes.func.isRequired
}

export default SignUp
