import React, { useContext } from 'react'
import { View, StyleSheet, SafeAreaView, Platform, Image } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import colors from '../theme/colors'
import Typo from './Typo'
import Button from './Button'
import { AuthContext } from '../context'
import { replaceTranslationKey } from '../utils/helpers'

const regexCompanyName = '{{{companyName}}}'

const MARGIN_BOTTOM = Platform.OS === 'android' ? 24 : 0

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightPaleGrey,
    paddingHorizontal: 24
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  footer: {
    padding: 24
  }
})

const InvitationUsed = ({ onPress }) => {
  const { rootStore, signOut } = useContext(AuthContext)

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.contentContainer}>
        <Typo.H2Blue>{I18n.get('invitation_used')}</Typo.H2Blue>
        <Image
          source={require('../assets/images/illustrations/testround.png')}
          resizeMode='contain'
          style={{ width: 230, height: 230, marginTop: 35, marginBottom: 35 }}
        />
        <View style={{ width: '90%' }}>
          <Typo.T1LightBlack center>
            {replaceTranslationKey(
              I18n.get('invitation_used_desc'),
              regexCompanyName,
              rootStore.appSettingsStore.partnerDisplayName ||
                rootStore.appSettingsStore.partnerName
            )}
          </Typo.T1LightBlack>
        </View>
      </View>
      <View style={styles.footer}>
        <Button
          containerStyle={{ marginBottom: MARGIN_BOTTOM }}
          title='global.got_it'
          onPress={async () => {
            await rootStore.userStore.logout()
            signOut()
            onPress()
          }}
        />
      </View>
    </SafeAreaView>
  )
}

InvitationUsed.propTypes = {
  onPress: PropTypes.func.isRequired
}

export default observer(InvitationUsed)
