import React from 'react'
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'

import { Button } from '.'
import colors from '../theme/colors'

const styles = StyleSheet.create({
  footer: {
    paddingVertical: 24,
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center'
  },
  prevButton: {
    height: 48,
    width: 48,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.paleBlue,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.primaryBlue,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 5.46,
    elevation: 9,
    backgroundColor: colors.white
  }
})

const Footer = ({
  prevButtonProps,
  showBackButton,
  submitButtonColored,
  primaryButtonProps,
  footerColor
}) => (
  <View style={[styles.footer, { backgroundColor: footerColor || '#fff' }]}>
    {showBackButton && (
      <TouchableOpacity
        hitSlop={{ top: 0, left: 50, bottom: 50, right: 50 }}
        {...prevButtonProps} // eslint-disable-line
      >
        <View style={[styles.prevButton, { opacity: prevButtonProps.disabled ? 0.35 : 1 }]}>
          <Image
            style={{ height: 24, width: 24 }}
            source={require('../assets/images/arrow_back_nav.png')}
          />
        </View>
      </TouchableOpacity>
    )}
    <View style={{ flex: 1, marginLeft: showBackButton ? 15 : 0 }}>
      <Button
        {...primaryButtonProps} // eslint-disable-line
        style={[
          primaryButtonProps.submitReady && submitButtonColored
            ? { backgroundColor: colors.secondary }
            : {}
        ]}
        title={
          primaryButtonProps.submitReady
            ? 'global.challenge.footer.submit'
            : 'global.challenge.footer.further'
        }
        uppercase
      />
    </View>
  </View>
)

Footer.propTypes = {
  prevButtonProps: PropTypes.object,
  showBackButton: PropTypes.bool,
  submitButtonColored: PropTypes.bool,
  primaryButtonProps: PropTypes.object,
  title: PropTypes.string.isRequired,
  footerColor: PropTypes.string
}

Footer.defaultProps = {
  prevButtonProps: {},
  showBackButton: true,
  submitButtonColored: true,
  primaryButtonProps: {},
  footerColor: undefined
}

export default Footer

// const PrevButtonIcon = ({ color }) => (
//  <Svg width={16} height={16} viewBox='0 0 16 16'>
//    <Defs>
//      <Path
//        d='M7.8 11l5.25-5.25a.5.5 0 00-.003-.703l-.694-.694a.495.495 0 00-.707.001l-7.292 7.292a.496.496 0 000 .708l7.292 7.292a.502.502 0 00.707 0l.694-.693a.502.502 0 00.003-.703L7.8 13h11.707a.492.492 0 00.493-.505v-.99a.507.507 0 00-.493-.505H7.8z'
//        id='prefix__a'
//      />
//    </Defs>
//    <G transform='translate(-4 -4)' fill='none' fillRule='evenodd'>
//      <Mask id='prefix__b' fill='#fff'>
//        <Use xlinkHref='#prefix__a' />
//      </Mask>
//      <Use fill={color || colors.brightBlue} xlinkHref='#prefix__a' />
//      <G mask='url(#prefix__b)' fill={color || colors.brightBlue}>
//        <Path d='M0 0h24v24H0z' />
//      </G>
//    </G>
//  </Svg>
// )
