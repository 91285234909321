/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { View, StyleSheet, Text, Image, ScrollView } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import PropTypes from 'prop-types'
// import { Button as ButtonRNPaper } from 'react-native-paper'
import { I18n } from 'aws-amplify'

import { Button, Typo } from '../../../../components'
import colors from '../../../../theme/colors'
import { JOB_TYPES } from '../data'
import metrics from '../../../../theme/metrics'
import { replaceTranslationKeys } from '../../../../utils/helpers'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.brightBlue,
    paddingHorizontal: 24
  },
  contentContainer: {
    flex: 1
  },
  personContainer: {
    alignItems: 'center',
    width: '45%',
    paddingVertical: 16,
    borderWidth: 1,
    borderColor: '#fff'
  },
  footer: {
    paddingVertical: 24
  },
  moodContainer: {
    width: 20,
    height: 140,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#fff',
    marginTop: 12,
    justifyContent: 'flex-end',
    alignItems: 'center',
    overflow: 'hidden'
  },
  reactionFillBarContainer: {
    width: '100%',
    height: 24,
    borderRadius: 8,
    backgroundColor: colors.darkBlue,
    justifyContent: 'center',
    paddingHorizontal: 4
  },
  outcomeBadgeContainer: {
    height: 24,
    width: 44,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    backgroundColor: colors.primaryGreen,
    marginLeft: 8
  }
})

const Feedback = ({
  balances,
  onPress,
  familyMoodChange,
  success,
  familyFeedback,
  familySmiley,
  familyReaction,
  doShip,
  outcome,
  familyMood,
  type,
  moodText,
  potentialOutcome
}) => {
  const insets = useSafeAreaInsets()
  const customMDStyles = StyleSheet.create({
    text: {
      fontFamily: 'fira-sans-regular',
      fontSize: 14,
      lineHeight: 20,
      color: potentialOutcome !== null ? colors.safetyOrange : '#fff'
    },
    strong: {
      fontFamily: 'fira-sans-semi-bold',
      fontSize: 14,
      lineHeight: 20,
      color: potentialOutcome !== null ? colors.safetyOrange : '#fff'
    }
  })

  const [showMoodText, setShowMoodText] = useState(false)
  // const [showTutorial, setShowTutorial] = useState(true)
  let headline = { text: '', color: '#fff' }

  if (doShip === 0) {
    headline = { text: I18n.get('challenge.delegation.feedback.title.stayport'), color: '#fff' }
  } else if (doShip === 1 && success) {
    headline = {
      text: I18n.get('challenge.delegation.feedback.title.win'),
      color: colors.brightGreen
    }
  } else {
    headline = {
      text: I18n.get('challenge.delegation.feedback.title.loss'),
      color: colors.dislikeRed
    }
  }

  function getImageForReaction(reaction) {
    switch (reaction) {
      case 1:
        return (
          <Image
            style={{
              ...metrics.calculateResponsiveDimensions({ width: 208, height: 124 })
            }}
            source={require('../../../../assets/challenges/delegation/family-mood-happy.png')}
          />
        )

      case -1:
        return (
          <Image
            style={{
              ...metrics.calculateResponsiveDimensions({ width: 212, height: 109 })
            }}
            source={require('../../../../assets/challenges/delegation/family-mood-mad.png')}
          />
        )
      case 0:
        return (
          <Image
            style={{
              ...metrics.calculateResponsiveDimensions({ width: 177, height: 109 })
            }}
            source={require('../../../../assets/challenges/delegation/family-mood-normal.png')}
          />
        )

      default:
        break
    }
  }

  // function getEmojiBySympathy(sympathy) {
  //   if (sympathy === 100) {
  //     return '😍'
  //   }
  //   if (sympathy === 0) {
  //     return '😡'
  //   }
  //   if (sympathy >= 68) {
  //     return '🙂'
  //   }
  //   if (sympathy <= 67 && sympathy >= 34) {
  //     return '😐'
  //   }
  //   return '🙁'
  // }

  if (showMoodText) {
    return (
      <View
        style={[
          styles.container,
          { paddingTop: 20 + insets.top, paddingBottom: 20 + insets.bottom }
        ]}
      >
        <View style={styles.contentContainer}>
          <Typo.H1White marginBottom={16} center translate>
            challenge.delegation.feedback.mood.title
          </Typo.H1White>
          <Typo.T1White>{moodText}</Typo.T1White>
        </View>
        <View style={styles.footer}>
          <Button
            onPress={onPress}
            marginHorizontal={4}
            backgroundColor={colors.primaryBlue}
            title='challenge.delegation.feedback.button.nextjob'
          />
        </View>
      </View>
    )
  }

  return (
    <ScrollView
      style={{
        flex: 1,
        paddingHorizontal: 16,
        backgroundColor: colors.brightBlue
      }}
      contentContainerStyle={{ paddingTop: 20 + insets.top, paddingBottom: 20 + insets.bottom }}
    >
      <Typo.H1White marginBottom={0}>{headline.text}</Typo.H1White>
      <Typo.Markdown style={customMDStyles}>{familyFeedback}</Typo.Markdown>
      {outcome !== 0 && (
        <Text
          style={{
            fontSize: 20,
            fontFamily: 'fira-sans-semi-bold',
            color: '#fff',
            marginTop: 16
          }}
          marginTop={16}
        >
          {replaceTranslationKeys(
            I18n.get('challenge.delegation.feedback.outcome.desc'),
            ['{{amount}}', '{{familyOrPrivateDesc}}', '{{winOrLose}}'],
            [
              Math.abs(JOB_TYPES.BIG === type ? 4 * outcome : outcome),
              JOB_TYPES.BIG === type
                ? I18n.get('challenge.delegation.feedback.ofFamily')
                : I18n.get('challenge.delegation.feedback.ofPrivate'),
              outcome > 0
                ? I18n.get('challenge.delegation.feedback.won')
                : I18n.get('challenge.delegation.feedback.loss')
            ]
          )}
        </Text>
      )}

      <View style={{ marginTop: 16 }}>
        <Text style={{ fontSize: 20, fontFamily: 'fira-sans-light', color: '#fff' }}>
          {`${I18n.get('challenge.delegation.feedback.reaction.title')} ${familySmiley}`}
        </Text>
        <View style={{ marginTop: 24, marginLeft: 32 }}>{getImageForReaction(familyReaction)}</View>
        <View style={{ opacity: type === JOB_TYPES.SMALL ? 0.3 : 1 }}>
          <View style={styles.reactionFillBarContainer}>
            <View
              style={{
                height: 16,
                borderRadius: 6,
                width: `${familyMood}%`,
                backgroundColor:
                  familyMood >= 68
                    ? colors.primaryGreen
                    : familyMood < 68 && familyMood >= 34
                    ? colors.mango
                    : colors.buttonRed
              }}
            />
          </View>
          <View
            style={{
              marginTop: 4,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ fontSize: 20, fontFamily: 'fira-sans-light', color: '#fff' }}>
                {I18n.get('challenge.delegation.feedback.sympathy')}
              </Text>
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: 'fira-sans-regular',
                  color: '#fff',
                  marginLeft: 4
                }}
              >
                {`(${familyMoodChange > 0 || familyMood === 100 ? '+' : ''}${
                  familyMood === 100 ? '0' : familyMoodChange
                })`}
              </Text>
            </View>
            <Text style={{ fontSize: 20, color: '#fff', fontFamily: 'fira-sans-bold' }}>
              {familyMood}
            </Text>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 32 }}>
        <View
          style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typo.T1White translate>challenge.delegation.feedback.privatecapital</Typo.T1White>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontFamily: 'fira-sans-bold', fontSize: 16, color: '#fff' }}>
              {`${balances.me} Taler`}
            </Text>
            {outcome !== 0 && (
              <View
                style={[
                  styles.outcomeBadgeContainer,
                  { backgroundColor: outcome < 0 ? colors.buttonRed : colors.primaryGreen }
                ]}
              >
                <Typo.T2White>{` ${outcome > 0 ? '+' : ''}${outcome}`}</Typo.T2White>
              </View>
            )}
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10
          }}
        >
          <Typo.T1White translate>challenge.delegation.feedback.familycapital</Typo.T1White>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontFamily: 'fira-sans-bold', fontSize: 16, color: '#fff' }}>
              {`${balances.m1 + balances.m2 + balances.m3} Taler`}
            </Text>
            {outcome !== 0 && JOB_TYPES.BIG === type && (
              <View
                style={[
                  styles.outcomeBadgeContainer,
                  { backgroundColor: outcome < 0 ? colors.buttonRed : colors.primaryGreen }
                ]}
              >
                <Typo.T2White>{` ${outcome > 0 ? '+' : ''}${
                  JOB_TYPES.BIG === type ? 3 * outcome : outcome
                }`}</Typo.T2White>
              </View>
            )}
          </View>
        </View>
        <View style={{ height: 2, width: '100%', backgroundColor: '#fff', marginVertical: 16 }} />
        <View
          style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typo.T1White translate>challenge.delegation.feedback.totalcapital</Typo.T1White>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontFamily: 'fira-sans-bold', fontSize: 16, color: '#fff' }}>
              {`${balances.me + balances.m1 + balances.m2 + balances.m3} Taler`}
            </Text>
            {outcome !== 0 && (
              <View
                style={[
                  styles.outcomeBadgeContainer,
                  { backgroundColor: outcome < 0 ? colors.buttonRed : colors.primaryGreen }
                ]}
              >
                <Typo.T2White>{` ${outcome > 0 ? '+' : ''}${
                  JOB_TYPES.BIG === type ? 4 * outcome : outcome
                }`}</Typo.T2White>
              </View>
            )}
          </View>
        </View>
      </View>

      <View style={{ marginTop: 24 }}>
        <Button
          onPress={moodText ? () => setShowMoodText(true) : onPress}
          backgroundColor={moodText ? colors.carnationPink : colors.primaryBlue}
          marginHorizontal={4}
          title={
            moodText
              ? 'challenge.delegation.feedback.button.react'
              : 'challenge.delegation.feedback.button.nextjob'
          }
        />
      </View>
    </ScrollView>
  )

  // return (
  //   <>
  //     <View
  //       style={[
  //         styles.container,
  //         testRound && type === JOB_TYPES.BIG && showTutorial && { opacity: 0.4 }
  //       ]}
  //     >
  //       <View style={styles.contentContainer}>
  //         <Typo.H1White style={{ color: headline.color }} marginBottom={4} center>
  //           {headline.text}
  //         </Typo.H1White>
  //         <Typo.Markdown style={customMDStyles}>{familyFeedback}</Typo.Markdown>
  //         <View style={{ marginVertical: 16 }}>
  //           {outcome !== 0 && (
  //             <Typo.H2White marginBottom={8}>{`Du hast ${Math.abs(
  //               JOB_TYPES.BIG === type ? 4 * outcome : outcome
  //             )}€ ${
  //               JOB_TYPES.BIG === type
  //                 ? 'von dem Familienunternehmen'
  //                 : 'von deinem privatem Vermögen'
  //             } ${outcome > 0 ? 'verdient' : 'verloren'}`}</Typo.H2White>
  //           )}
  //           <View style={rowCenterBetween}>
  //             <Typo.H2LightWhite style={{ fontSize: 18 }}>
  //               Reaktion der Geschwister:
  //             </Typo.H2LightWhite>
  //             <Text style={{ color: '#fff', fontSize: 32, marginRight: 12 }}>{familySmiley}</Text>
  //           </View>
  //           <View
  //             style={[
  //               rowCenterBetween,
  //               { marginTop: 4, opacity: type === JOB_TYPES.SMALL ? 0.3 : 1 }
  //             ]}
  //           >
  //             <Typo.H2LightWhite style={{ fontSize: 18 }}>
  //               Sympathie:{' '}
  //               {type === JOB_TYPES.BIG && (
  //                 <Typo.H2White>
  //                   {`${familyMood} `}
  //                   <Typo.H2White
  //                     style={{
  //                       color:
  //                         familyMoodChange > 0 || familyMood === 100
  //                           ? 'green'
  //                           : familyMoodChange < 0
  //                           ? 'red'
  //                           : 'orange'
  //                     }}
  //                   >{`(${familyMoodChange > 0 || familyMood === 100 ? '+' : ''}${
  //                     familyMood === 100 ? '0' : familyMoodChange
  //                   })`}</Typo.H2White>
  //                 </Typo.H2White>
  //               )}
  //             </Typo.H2LightWhite>
  //             <Text style={{ color: '#fff', fontSize: 32, marginRight: 12 }}>
  //               {getEmojiBySympathy(familyMood)}
  //             </Text>
  //           </View>
  //           <View style={[rowCenterBetween, { marginTop: 8 }]}>
  //             <Typo.T1White>0</Typo.T1White>
  //             <View
  //               style={{
  //                 height: 24,
  //                 width: '80%',
  //                 borderRadius: 8,
  //                 borderWidth: 1,
  //                 justifyContent: 'center'
  //               }}
  //             >
  //               <View
  //                 style={{
  //                   width: `${familyMood}%`,
  //                   height: 24,
  //                   backgroundColor:
  //                     familyMood >= 68
  //                       ? 'green'
  //                       : familyMood < 68 && familyMood >= 34
  //                       ? 'orange'
  //                       : 'red',
  //                   borderRadius: 8
  //                 }}
  //               />
  //             </View>
  //             <Typo.T1White>100</Typo.T1White>
  //           </View>
  //         </View>
  //         <View style={[styles.contentContainer, { justifyContent: 'flex-end' }]}>
  //           <View>
  //             <View style={[rowCenterBetween, { marginBottom: 4 }]}>
  //               <Typo.H2LightWhite style={{ fontSize: 18 }}>Alises Vermögen:</Typo.H2LightWhite>
  //               <Typo.H2White style={{ fontSize: 18 }}>
  //                 {`${balances.m1} EUR`}{' '}
  //                 <Typo.H2LightWhite style={{ color: headline.color }}>
  //                   {type === JOB_TYPES.BIG && outcome !== 0
  //                     ? `(${outcome > 0 ? '+' : ''}${outcome})`
  //                     : ''}
  //                 </Typo.H2LightWhite>
  //               </Typo.H2White>
  //             </View>
  //             <View style={[rowCenterBetween, { marginBottom: 4 }]}>
  //               <Typo.H2LightWhite style={{ fontSize: 18 }}>James Vermögen:</Typo.H2LightWhite>
  //               <Typo.H2White style={{ fontSize: 18 }}>
  //                 {`${balances.m2} EUR`}{' '}
  //                 <Typo.H2LightWhite style={{ color: headline.color }}>
  //                   {type === JOB_TYPES.BIG && outcome !== 0
  //                     ? `(${outcome > 0 ? '+' : ''}${outcome})`
  //                     : ''}
  //                 </Typo.H2LightWhite>
  //               </Typo.H2White>
  //             </View>
  //             <View style={[rowCenterBetween, { marginBottom: 4 }]}>
  //               <Typo.H2LightWhite style={{ fontSize: 18 }}>Claires Vermögen:</Typo.H2LightWhite>
  //               <Typo.H2White style={{ fontSize: 18 }}>
  //                 {`${balances.m3} EUR`}{' '}
  //                 <Typo.H2LightWhite style={{ color: headline.color }}>
  //                   {type === JOB_TYPES.BIG && outcome !== 0
  //                     ? `(${outcome > 0 ? '+' : ''}${outcome})`
  //                     : ''}
  //                 </Typo.H2LightWhite>
  //               </Typo.H2White>
  //             </View>
  //             <View style={[rowCenterBetween, { marginBottom: 4, width: '100%', flex: 1 }]}>
  //               <Typo.H2LightWhite style={{ fontSize: 18 }}>Eigenes Vermögen:</Typo.H2LightWhite>
  //               <Typo.H2White style={{ fontSize: 18 }}>
  //                 {`${balances.me} EUR`}
  //                 <Typo.H2LightWhite style={{ color: headline.color }}>
  //                   {outcome !== 0 ? ` (${outcome > 0 ? '+' : ''}${outcome})` : ''}
  //                 </Typo.H2LightWhite>
  //               </Typo.H2White>
  //             </View>
  //             <View style={{ width: '100%', height: 1, backgroundColor: '#fff' }} />
  //             <View style={[rowCenterBetween, { marginTop: 4 }]}>
  //               <Typo.H2LightWhite style={{ fontSize: 18 }}>Familienvermögen:</Typo.H2LightWhite>
  //               <Typo.H2White style={{ fontSize: 18 }}>{`${
  //                 balances.me + balances.m1 + balances.m2 + balances.m3
  //               } EUR`}</Typo.H2White>
  //             </View>
  //           </View>
  //         </View>
  //       </View>
  //       <View style={styles.footer}>
  //         <Button
  //           onPress={moodText ? () => setShowMoodText(true) : onPress}
  //           backgroundColor={moodText ? colors.carnationPink : colors.primaryBlue}
  //           marginHorizontal={4}
  //           title={moodText ? 'Deine Geschwister reagieren!' : 'Zum nächsten Auftrag ->'}
  //         />
  //       </View>
  //     </View>
  //     {testRound && type === JOB_TYPES.BIG && showTutorial && (
  //       <View style={{ position: 'absolute', paddingHorizontal: 24, bottom: 24 }}>
  //         <View
  //           style={{
  //             backgroundColor: '#fff',
  //             marginBottom: 8,
  //             padding: 12,
  //             borderRadius: 8,
  //             borderWidth: 1,
  //             borderColor: '#000'
  //           }}
  //         >
  //           <Typo.T1Black>
  //             Deine Entscheidungen beeinflussen auch dein Verhältnis zu deinen Geschwistern. Wenn es
  //             um ihr eigenes Geld geht, verstehen sie keinen Spaß! Also halte immer stets einen
  //             Blick auf die Sympathie deiner Geschwister.
  //           </Typo.T1Black>
  //         </View>
  //         <ButtonRNPaper
  //           onPress={() => setShowTutorial(null)}
  //           color={colors.primaryBlue}
  //           mode='contained'
  //         >
  //           Verstanden
  //         </ButtonRNPaper>
  //       </View>
  //     )}
  //   </>
  // )
}

Feedback.propTypes = {
  balances: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  familyFeedback: PropTypes.string.isRequired,
  familySmiley: PropTypes.string.isRequired,
  doShip: PropTypes.number.isRequired,
  outcome: PropTypes.number.isRequired,
  familyMoodChange: PropTypes.number.isRequired,
  familyMood: PropTypes.number.isRequired,
  moodText: PropTypes.any.isRequired,
  potentialOutcome: PropTypes.any.isRequired,
  // testRound: PropTypes.bool.isRequired,
  familyReaction: PropTypes.number.isRequired
}

Feedback.defaultProps = {}

export default Feedback
