import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'

import Typo from '../../../Typo'
import Button from '../../../Button'

export const examScienceText = {
  RIASEC_IMAGES: {
    previewText: 'challenge.riasec_images.science_text_short',
    fullText: 'challenge.riasec_images.science_text_long'
  },
  BIG_FIVE: {
    previewText: 'challenge.big_five.result.science_text_short',
    fullText: 'challenge.big_five.result.science_text_long'
  },
  PATH_FINDER: {
    previewText: 'challenge.path_finder.result.science_text_short',
    fullText: 'challenge.path_finder.result.science_text_long'
  },
  EMOTIONS_GAME: {
    previewText: 'challenge.emotions_game.result.science_text_short',
    fullText: 'challenge.emotions_game.result.science_text_long'
  },
  TOWER_OF_LONDON: {
    previewText: 'challenge.tower_of_london.result.science_text_short',
    fullText: 'challenge.tower_of_london.result.science_text_long'
  },
  ANALOGICAL_REASONING: {
    previewText: 'challenge.analogical_reasonning.result.science_text_short',
    fullText: 'challenge.analogical_reasonning.result.science_text_long'
  },
  DIGIT_SPAN: {
    previewText: 'challenge.digit_span.result.science_text_short',
    fullText: 'challenge.digit_span.result.science_text_long'
  },
  FLANKER: {
    previewText: 'challenge.flanker.result.science_text_short',
    fullText: 'challenge.flanker.result.science_text_long'
  },
  NBACK: {
    previewText: 'challenge.nback.result.science_text_short',
    fullText: 'challenge.nback.result.science_text_long'
  },
  DELEGATION: {
    previewText: 'challenge.delegation.result.science_text_short',
    fullText: 'challenge.delegation.result.science_text_long'
  },
  NUMERACY: {
    previewText: 'challenge.numeracy.result.science_text_short',
    fullText: 'challenge.numeracy.result.science_text_long'
  }
}

const s = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: '#B6C7F2',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5
  }
})

const ScienceText = ({ exam_id, containerStyle }) => {
  const [visibility, setVisibility] = useState(false)

  const onPressShow = () => {
    setVisibility(true)
  }

  return (
    <View style={[s.container, containerStyle]}>
      <Typo.H4Black translate>global.challenge.result.the_science_behind</Typo.H4Black>
      <Typo.T2Black marginTop={24} marginBottom={visibility ? 0 : 24} translate>
        {visibility ? examScienceText[exam_id].fullText : examScienceText[exam_id].previewText}
      </Typo.T2Black>
      {visibility ? null : (
        <Button
          onPress={onPressShow}
          style={{ width: '100%' }}
          title='global.challenge.result.show_complete_explaination'
          theme='outline'
        />
      )}
    </View>
  )
}

ScienceText.propTypes = {
  exam_id: PropTypes.string.isRequired,
  containerStyle: PropTypes.object
}

ScienceText.defaultProps = {
  containerStyle: {}
}

export default ScienceText
