import React from 'react'
import { Platform } from 'react-native'
import { Text } from 'react-native-svg'
import _ from 'lodash'
import { PieChart } from 'react-native-svg-charts'
import PropTypes from 'prop-types'

const transformData = (data) => {
  let containNegative = false
  let smallestNumber = 0
  data.forEach((item) => {
    if (item.number < 0) {
      containNegative = true
    }
  })

  if (containNegative) {
    const arr = data.map((item) => item.number)
    const min = arr.reduce((a, b) => Math.min(a, b))
    smallestNumber = min
    const transformedData = data.map((item) => ({
      name: item.name,
      color: item.color,
      number: item.number + Math.abs(smallestNumber)
    }))

    return transformedData
  }
  return data
}

const Title = () => (
  <Text
    fill='#182033'
    fillRule='evenodd'
    fontSize={14}
    textAnchor='middle'
    alignmentBaseline='middle'
    fontWeight='600'
    fontFamily={Platform.OS === 'web' ? 'fira-sans-regular' : null}
  >
    INTERESSEN
  </Text>
)

const PieChartTemp = ({ data }) => {
  const newData = transformData(data)
  const baseValue = _.sumBy(newData, (item) => item.number)

  const pieData = newData
    .filter((item) => item.number > 0)
    .map((item, index) => ({
      value: item.number,
      svg: {
        fill: item.color,
        onPress: () => console.log('press', index) // eslint-disable-line no-console
      },
      key: `pie-${index}`
    }))

  const Labels = ({ slices }) =>
    slices.map((slice, index) => {
      const { pieCentroid, data: dataSlice } = slice
      const labelText = Math.round((dataSlice.value / baseValue) * 100)

      return (
        <Text
          key={index}
          x={pieCentroid[0]}
          y={pieCentroid[1]}
          fill='white'
          textAnchor='middle'
          alignmentBaseline='middle'
          fontFamily={Platform.OS === 'web' ? 'fira-sans-regular' : null}
          fillRule='evenodd'
          fontSize={16}
        >
          {labelText > 0 && index <= 2 ? `${labelText}%` : ''}
        </Text>
      )
    })

  return (
    <PieChart
      style={{ height: 230 }}
      data={pieData} // pieData
      innerRadius='55%'
      padAngle={0}
      // endAngle={Math.PI * animatedAngle}
    >
      <Labels />
      <Title />
    </PieChart>
  )
}

PieChartTemp.propTypes = {
  data: PropTypes.array.isRequired
}

export default PieChartTemp
