import React, { useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import colors from '../../../../theme/colors'
import { Typo } from '../../../../components'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'center'
  },
  button: {
    width: '30%',
    margin: 5,
    paddingHorizontal: 10,
    paddingVertical: 6,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8
  }
})

const Numpad = ({ onInput, submitButtonDisabled, onSubmit }) => {
  const handlePress = (value) => {
    onInput(value)
  }

  return (
    <View style={styles.container}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num) => (
        <TouchableOpacity
          key={num}
          style={styles.button}
          onPress={() => handlePress(num.toString())}
        >
          <Typo.H4Black>{num}</Typo.H4Black>
        </TouchableOpacity>
      ))}
      <TouchableOpacity
        disabled={submitButtonDisabled}
        onPress={onSubmit}
        style={[
          styles.button,
          {
            width: '63%',
            backgroundColor: colors.primaryGreen,
            opacity: submitButtonDisabled ? 0.6 : 1
          }
        ]}
      >
        <Typo.T1White translate>challenge.numeracy.enter</Typo.T1White>
      </TouchableOpacity>
    </View>
  )
}

export default Numpad
