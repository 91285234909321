import React from 'react'
import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import PropTypes from 'prop-types'

const TimeIcon = ({ color, containerStyle }) => (
  <View style={containerStyle}>
    <Svg width={16} height={16}>
      <Path
        d='M8 14.222A6.222 6.222 0 1 0 8 1.778a6.222 6.222 0 0 0 0 12.444zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm.578-8H12.1v1.778H6.8V3.556h1.778V8z'
        fill={color}
        fillRule='nonzero'
      />
    </Svg>
  </View>
)

TimeIcon.propTypes = {
  color: PropTypes.string.isRequired,
  containerStyle: PropTypes.object.isRequired
}

export default TimeIcon
