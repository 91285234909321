/* eslint-disable no-restricted-syntax */
const SCORES = [
  'score',
  'elevation',
  'o_score',
  'a_score',
  'n_score',
  'e_score',
  'c_score',
  'score_coopcomp',
  'score_orderchaos',
  'i_score',
  'r_score',
  'a_score',
  's_score',
  'e_score',
  'c_score',
  'score_risk',
  'score_responsibility'
]

function zScoreToPercentage(z) {
  if (z < -6.5) {
    return 0.0
  }
  if (z > 6.5) {
    return 1.0
  }
  let factK = 1
  let sum = 0
  let term = 1
  let k = 0
  const loopStop = Math.exp(-23)
  while (Math.abs(term) > loopStop) {
    term = (((0.3989422804 * (-1) ** k * z ** k) / (2 * k + 1) / 2 ** k) * z ** (k + 1)) / factK
    sum += term
    k++
    factK *= k
  }
  sum += 0.5
  return sum
}

function translateZScoreToPercentage(field, aivyOutput, zScoreVars) {
  if (!zScoreVars || !aivyOutput) {
    return 1
  }

  const zScoreMean = JSON.parse(zScoreVars.zscore_mean)
  const zScoreDeviation = JSON.parse(zScoreVars.zscore_deviation)

  const value = aivyOutput[field]
  const mean = zScoreMean[field]
  const deviation = zScoreDeviation[field]
  const zScoreScore = (value - mean) / (deviation === 0 ? 1 : deviation)
  let percent = zScoreToPercentage(zScoreScore) * 100
  if (field === 'n_score') {
    percent = 100 - percent
  }
  return percent
}

/**
 * Checks if percentiles are stored in the user object.
 * If not it will return them, otherwise nothing happens.
 * @param {Object} user
 * @param {Object} answers
 * @returns Object with percentiles
 */
export const checkPercentiles = (user, answers, challengeConfigs) => {
  if (!user || !answers || !challengeConfigs) return null // || user?.percentiles

  const percentileJson = { percentiles: {} }
  for (const answer of answers) {
    const { exam_id: examId } = answer
    const aivyOutput = answer?.aivy_output ? answer.aivy_output : null

    const config = challengeConfigs.find((challengeConfig) => challengeConfig.id === examId)

    if (aivyOutput) {
      for (const [key] of Object.entries(aivyOutput)) {
        if (SCORES.includes(key)) {
          const percent = translateZScoreToPercentage(key, aivyOutput, config)
          percentileJson.percentiles[`${examId}_${key}`] = parseFloat(percent.toFixed(4))
        }
      }
    }
  }
  return percentileJson
}
