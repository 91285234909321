import React, { useEffect, useState } from 'react'
import { View, Image, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { I18n } from 'aws-amplify'
import { Button, Typo } from '../../../../components'
import metrics from '../../../../theme/metrics'
import colors from '../../../../theme/colors'
import asyncStorage from '../../../../utils/async-storage'

const ThirdIntro = ({ onPress, onPressBack }) => {
  const [language, setLanguage] = useState('de')
  const insets = useSafeAreaInsets()

  useEffect(() => {
    const getLanguage = async () => {
      setLanguage((await asyncStorage.getData('lang')) || 'de')
    }

    getLanguage()
  }, [])

  return (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 20 + insets.top,
        backgroundColor: colors.softBlue
      }}
    >
      <View style={{ flex: 1 }}>
        <Typo.H1White translate>challenge.delegation.thirdintro.title</Typo.H1White>
        <View style={{ marginTop: 16 }}>
          <Image
            source={
              language === 'de'
                ? require('../../../../assets/challenges/delegation/explanation-de.png')
                : require('../../../../assets/challenges/delegation/explanation-en.png')
            }
            style={{
              alignSelf: 'center',
              resizeMode: 'contain',
              ...metrics.calculateResponsiveDimensions({
                height: 300,
                width: metrics.widthPercentageToDP('90')
              })
            }}
          />
          <Typo.T1White marginTop={8} translate>
            challenge.delegation.thirdintro.description
          </Typo.T1White>
        </View>
      </View>
      <View style={{ paddingBottom: 24 + insets.bottom }}>
        <Button
          marginHorizontal={8}
          type='white'
          onPress={onPress}
          title='challenge.delegation.thirdintro.button'
        />
        <TouchableOpacity style={{ alignItems: 'center', marginTop: 24 }} onPress={onPressBack}>
          <Typo.ButtonWhite>{I18n.get('global.goback')}</Typo.ButtonWhite>
        </TouchableOpacity>
      </View>
    </View>
  )
}

ThirdIntro.propTypes = {
  onPress: PropTypes.func.isRequired,
  onPressBack: PropTypes.func.isRequired
}

ThirdIntro.defaultProps = {}

export default ThirdIntro
