import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'
import { Platform } from 'react-native'
import { isError } from 'lodash'

import awsconfig from '../aws-exports'

const isNetworkError = ({ message }) => {
  const messagetolowercase = message.toLowerCase()

  if (
    messagetolowercase.includes('network request failed') ||
    messagetolowercase.includes('failed to fetch') ||
    messagetolowercase.includes('network error') ||
    messagetolowercase.includes('networkerror') ||
    messagetolowercase.includes('keine internetverbindung')
  ) {
    return true
  }

  return false
}

export const notifyBugsnagUniversal = (Bugsnag, error) => {
  if (error === 'The user is not authenticated') {
    console.log(error) // eslint-disable-line no-console
    return
  }

  console.error(error) // eslint-disable-line no-console
  if (__DEV__) return

  Bugsnag.notify(isError(error) ? error : new Error(JSON.stringify(error)))
}

/**
 * If you’d like to add diagnostic data to reports, or adjust
 * event data conditionally, you can use an onError callback,
 * which will be run immediately after an error is captured or reported.
 *
 * @param {*} event
 *
 * If you want to prevent an event from being sent to BugSnag,
 * you can return false within an onError.
 *
 */
export const onErrorUniversal = async (event) => {
  if (__DEV__) return false

  const device = Platform.OS
  const version = `${Constants.expoConfig.version} | ${Constants.expoConfig.extra?.versionName}`

  const meta = {
    user_id: await AsyncStorage.getItem('user-id'),
    partner_id: await AsyncStorage.getItem('partner-id'),
    partner_name: await AsyncStorage.getItem('partner-name'),
    career_id: await AsyncStorage.getItem('career-id'),
    space_id: await AsyncStorage.getItem('space-id')
  }

  Object.keys(meta).forEach((key) => {
    if (meta[key] != null) meta[key] = JSON.parse(meta[key])
  })

  const { aws_user_pools_id, aws_cognito_region } = awsconfig
  const cognito_url = meta.user_id
    ? 'https://eu-central-1.console.aws.amazon.com/cognito/v2/idp/user-pools/' +
      `${aws_user_pools_id}/users/details/${meta.user_id}?region=${aws_cognito_region}`
    : null

  event.context = `version ${version}`
  event.addMetadata('meta', {
    device,
    ...meta,
    cognito_url,
    version
  })

  let networkError = false
  event.errors.forEach((error) => {
    networkError = isNetworkError({ message: error.errorMessage })
  })

  if (networkError) return false

  event.errors.forEach((error) => {
    error.errorMessage = `[${device.toUpperCase()}] ${error.errorMessage}`
  })
}
