import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  StyleSheet,
  StatusBar,
  Platform,
  View,
  SafeAreaView,
  Animated,
  AppState
} from 'react-native'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { API, graphqlOperation, I18n } from 'aws-amplify'
import { useIsFocused, useFocusEffect } from '@react-navigation/native'

import Button from '../../components/Button'
import MainSmallHeader from '../../components/MainSmallHeader'
import NotificationListItem from '../../components/NotificationListItem'
import StrengthModalize from '../../components/strengths/StrengthModalize'
import { AuthContext } from '../../context'
import colors from '../../theme/colors'
import ChallengeCard from '../../components/ChallengeCard'
import SignUp from '../../components/SignUp'
import ListHeaderComponent from '../../components/ListHeaderComponent'
import TodoListTypes from '../../constants/TodoListTypes'
import WhiteListItem from '../../components/WhiteListItem'
import { isAivy, replaceTranslationKey, TimeInApp } from '../../utils/helpers'
import SAMPLE_RESULT_DATA from '../../utils/sample-result-data.json'
import VerifyEmail from '../../components/VerifyEmail'
import { initializeProfileData } from '../../utils/initializeProfileData'
import { checkPercentiles } from '../../utils/check-percentiles'

import { onCreateAnswerByOwner } from '../../graphql/subscriptions'
import EmptyState from '../../components/EmptyState'
import { trackEvent } from '../../utils/tracking'
import { useHandleOnChallengeAnswer } from '../../hooks/use-handle-on-challenge-answer'
import { ExpiresAtHint } from '../../components'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    backgroundColor: colors.paleGrey
  }
})

let userIDBackup

const Main = ({ navigation }) => {
  const { rootStore } = useContext(AuthContext)

  const [data, setData] = useState([])
  const [currentSwitchTab, setCurrentSwitchTab] = useState('TODO')

  const appState = useRef(AppState.currentState)
  const timeInApp = useRef(Date.now())
  const didShowNPS = useRef(false)
  const firstUpdate = useRef(true)

  const flatlistRef = useRef()
  const scrolling = useRef(new Animated.Value(0)).current

  const isFocused = useIsFocused()

  const opacity = scrolling.interpolate({
    inputRange: [70, 80],
    outputRange: [0, 1],
    extrapolate: 'clamp'
  })

  const zIndex = scrolling.interpolate({
    inputRange: [70, 80],
    outputRange: [-1, 10],
    extrapolate: 'clamp'
  })

  useHandleOnChallengeAnswer()

  const { pushEndpoint } = rootStore.userStore
  const { isAnonym, isEmailVerified, email, userProfile, isHardFactsDone } = rootStore.userStore
  const { answers } = rootStore.answersStore
  const { challenges, completedChallenges, progress } = rootStore.challengesStore
  const { active_space: activeSpace, isAivySelect, spaceContext } = rootStore.spaceStore

  const { challengeConfigs, usedAlgorithm } = rootStore.appSettingsStore
  const { successPerspective, newsletter } = rootStore.appSettingsStore.components

  const beforeProgress = useRef(activeSpace?.progress)

  const [isExpiresAtVisible, setIsExpiresAtVisible] = useState(!!activeSpace.expiresAt)

  useEffect(() => {
    /**
     * Handler for showing net promoter score
     */
    const hasNpsAlready = userProfile.net_promoter_scores
      ?.map((nps) => nps.product)
      .includes('AIVY_UNIVERSAL_APP')

    if (hasNpsAlready || didShowNPS.current === true || answers.length < 4 || isAivySelect) return

    setTimeout(() => {
      didShowNPS.current = true
      // navigation.navigate('NetPromoterScore')
    }, 2000)
  }, [navigation, answers.length, userProfile.net_promoter_scores, isAivySelect])

  useEffect(() => {
    /**
     * Handler to set last_seen and time_in_app for type user
     */
    const handleAppStateChange = (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        timeInApp.current = Date.now()
      }
      appState.current = nextAppState
      const timeInMs = Date.now() - timeInApp.current
      if (timeInMs >= 60000) {
        const timeInMinutes = timeInMs / 60000
        TimeInApp(Math.round(timeInMinutes), rootStore)
      }
      rootStore.userStore.updateUser({ last_seen: new Date() })
    }
    rootStore.userStore.updateUser({ last_seen: new Date() })
    const subscription = AppState.addEventListener('change', handleAppStateChange)
    return () => {
      subscription?.remove()
    }
  }, [rootStore.userStore, rootStore])

  useEffect(() => {
    const percentiles = checkPercentiles(userProfile, answers, challengeConfigs)
    if (percentiles === null) return
    rootStore.userStore.updateUser(percentiles)
  }, [userProfile, answers, rootStore.userStore, challengeConfigs])

  useEffect(() => {
    if (
      rootStore.userStore.username &&
      (rootStore.userStore.username !== userIDBackup || !userIDBackup)
    ) {
      userIDBackup = rootStore.userStore.username
      API.graphql(
        graphqlOperation(onCreateAnswerByOwner, { owner: rootStore.userStore.username })
      ).subscribe({
        next: () => {
          setTimeout(async () => {
            initializeProfileData(rootStore.userStore.username, rootStore)
          }, 800)
        },
        // eslint-disable-next-line no-console
        error: () => {
          setTimeout(() => {
            initializeProfileData(rootStore.userStore.username, rootStore)
          }, 800)
        }
      })
    }
  }, [rootStore.userStore.username, rootStore, rootStore.answersStore.answers])

  useEffect(() => {
    // Build FlatList Data
    const buildFlatList = async () => {
      const todoList = []
      const playedRiasecImages = rootStore.answersStore.getAnswerForChallenge('RIASEC_IMAGES')

      if (currentSwitchTab === 'TODO') {
        // Perspectives
        if (successPerspective && playedRiasecImages && spaceContext === 'ATTRACT') {
          todoList.push({ type: TodoListTypes.PERSPECTIVES })
        }

        // Reverse Recruiting
        if (isAivy(activeSpace?.partner_id) && spaceContext === 'ATTRACT' && playedRiasecImages) {
          todoList.push({ type: TodoListTypes.REVERSE_RECRUITING })
        }

        // Challenges
        todoList.push(...challenges.map((c) => ({ ...c, type: TodoListTypes.CHALLENGE })))

        // Notification
        if (!pushEndpoint && Platform.OS !== 'web') {
          todoList.push({ type: TodoListTypes.NOTIFICATIONS })
        }

        // SignUp
        if (isAnonym) {
          todoList.push({ type: TodoListTypes.SIGNUP })
        }

        // all challenges completed and nothing to show inside todo tab
        if (todoList.length === 0) {
          todoList.push({ type: TodoListTypes.EMPTY_TODO_TAB })
        }

        // Newsletter
        // if (newsletter && isEmailVerified) {
        if (newsletter && isEmailVerified && !activeSpace.newsletter) {
          todoList.push({ type: TodoListTypes.NEWSLETTER })
        }

        // Hard Facts
        if (
          isEmailVerified &&
          !isAnonym &&
          !isAivySelect &&
          isAivy(activeSpace?.partner_id) &&
          !isHardFactsDone
        ) {
          todoList.push({ type: TodoListTypes.HARD_FACTS })
        }

        setData(todoList.map((item, index) => ({ id: index, ...item })))
      } else if (currentSwitchTab === 'DONE') {
        const doneList = completedChallenges.map((challenge, index) => ({
          id: index,
          type: TodoListTypes.CHALLENGE_DONE,
          ...challenge
        }))

        if (doneList.length === 0) {
          doneList.push({ id: 123, type: TodoListTypes.EMPTY_DONE_TAB })
        }

        setData(doneList)
      }
    }

    buildFlatList()
  }, [
    currentSwitchTab,
    isAnonym,
    challenges,
    activeSpace,
    isAivySelect,
    successPerspective,
    pushEndpoint,
    completedChallenges,
    progress,
    isEmailVerified,
    isHardFactsDone,
    newsletter,
    usedAlgorithm,
    rootStore.answersStore,
    spaceContext
  ])

  useEffect(() => {
    const progressShadow = activeSpace?.progress
    const redirect_url = rootStore.appSettingsStore.careerRedirectUrl

    if (!redirect_url) return

    if (beforeProgress.current === 100) {
      setTimeout(() => {
        navigation.navigate('Redirect', { redirect_url })
      }, 1500)
      return
    }

    if (progressShadow === 100) {
      // COMPLETE FIRST TIME
      trackEvent('FunnelCompleted')

      if (redirect_url) {
        setTimeout(() => {
          navigation.navigate('Redirect', { redirect_url })
        }, 1500)
      }
    }
    beforeProgress.current = progressShadow
  }, [activeSpace?.progress, navigation, rootStore.appSettingsStore.careerRedirectUrl])

  useFocusEffect(
    useCallback(() => {
      if (firstUpdate.current === true) {
        firstUpdate.current = false
        return
      }
      async function fetchData() {
        await initializeProfileData(rootStore.userStore.username, rootStore)
      }
      fetchData()

      return () => {}
    }, [rootStore])
  )

  // useEffect(() => {
  //   if (Platform.OS === 'web') return
  //   if (route.params && !route.params.isSignup) return

  //   // setTimeout(() => {
  //   //   navigation.navigate('RegisterForPushNotifications')
  //   // }, 1000 * 1.2)
  // }, [navigation, route])

  async function cheatChallenge(item) {
    if (item.createdAt) {
      await rootStore.answersStore.deleteAnswer(item.id)
      return
    }

    // eslint-disable-next-line no-shadow
    const answer = SAMPLE_RESULT_DATA.filter((answer) => answer.exam_id === item.exam_id)

    if (!answer[0]) {
      // eslint-disable-next-line no-console
      console.log('Keine Demo Daten hinterlegt')
      return
    }
    const demoAnswer = answer[0]

    const cheatData = {
      owner: rootStore.userStore.username,
      exam_id: item.exam_id,
      version: 0,
      cycle: 1,
      aivy_output: JSON.stringify(demoAnswer.aivy_output),
      rating: null,
      os: Platform.OS,
      score: demoAnswer.aivy_output?.score,
      user_input: JSON.stringify(demoAnswer.user_input)
    }

    await rootStore.answersStore.postAnswer(cheatData)
  }

  const navigateFirstChallenge = () => {
    navigation.navigate('ChallengeContainerScreen', {
      challenge: rootStore.challengesStore.challenges[0]
    })
  }

  const renderItem = ({ item }) => {
    const { partnerDisplayName, logo } = rootStore.appSettingsStore

    if (item.type === TodoListTypes.CHALLENGE || item.type === TodoListTypes.CHALLENGE_DONE) {
      const onPress =
        item.type === TodoListTypes.CHALLENGE
          ? () =>
              navigation.navigate('ChallengeContainerScreen', {
                challenge: item
              })
          : () =>
              navigation.navigate('ChallengeResult', {
                exam: item,
                onPress: () => navigation.goBack(),
                answer: rootStore.answersStore.answers.find(
                  (answer) => answer.exam_id === item.exam_id
                )
              })

      return (
        <View>
          {__DEV__ && (
            <Button
              onPress={() => cheatChallenge(item)}
              title={item.createdAt ? 'Delete Me (ohne Reload)' : 'Cheat Me'}
            />
          )}
          <ChallengeCard containerStyle={{ marginBottom: 16 }} challenge={item} onPress={onPress} />
        </View>
      )
    }

    if (item.type === TodoListTypes.HARD_FACTS) {
      return (
        <WhiteListItem
          context={I18n.get('global.main.hardfacts.context')}
          header={I18n.get('global.main.hardfacts.title')}
          illustration={{
            src: require('../../assets/images/illustrations/skyscraper.png'),
            width: 192,
            height: 148
          }}
          containerStyle={{ marginBottom: 16 }}
          onPress={() => navigation.navigate('HardFacts')}
        />
      )
    }

    if (item.type === TodoListTypes.NEWSLETTER) {
      return (
        <WhiteListItem
          context='Angebote'
          header={replaceTranslationKey(
            I18n.get('global.main.newsletter.title'),
            '{{partnerName}}',
            partnerDisplayName
          )}
          illustration={{
            src: require('../../assets/images/illustrations/skyscraper.png'),
            width: 192,
            height: 148
          }}
          partnerLogo={{ uri: logo }}
          containerStyle={{ marginBottom: 16 }}
          onPress={() => navigation.navigate('Newsletter')}
        />
      )
    }

    // if (item.type === TodoListTypes.NOTIFICATIONS) {
    //   return (
    //     <NotificationListItem
    //       context='global.main.settings_notification_context'
    //       header='global.main.settings_notification_header'
    //       description='global.main.settings_notification_description'
    //       button={{
    //         action: () => navigation.navigate('RegisterForPushNotifications'),
    //         text: 'global.main.settings_notification_button_action'
    //       }}
    //       image={require('../../assets/images/illustrations/notification.png')}
    //       containerStyle={{ marginBottom: 16 }}
    //     />
    //   )
    // }

    if (item.type === TodoListTypes.SIGNUP) {
      return (
        <NotificationListItem
          context='global.main.sign_up_notification_context'
          header='global.main.sign_up_notification_header'
          description='global.main.sign_up_notification_description'
          button={{
            action: () => navigation.navigate('SaveStrengthDNA'),
            text: 'global.main.complete_profile_button'
          }}
          image={require('../../assets/images/illustrations/sign-up.png')}
          containerStyle={{ marginBottom: 16 }}
        />
      )
    }

    if (item.type === TodoListTypes.PERSPECTIVES) {
      return (
        <WhiteListItem
          context={I18n.get('perspectives')}
          newTag={progress !== 100}
          newTagTitle={completedChallenges.length === 1 ? 'global.new' : 'global.updated'}
          header={replaceTranslationKey(
            I18n.get('global.main.perspectives.title'),
            '{{partnerName}}',
            partnerDisplayName
          )} // 'Discover 3-4 suitable perspectives at Bierdorf.'
          partnerLogo={{ uri: logo }}
          illustration={{
            src: require('../../assets/images/illustrations/skyscraper.png'),
            width: 192,
            height: 148
          }}
          containerStyle={{ marginBottom: 16 }}
          onPress={() => navigation.navigate('Perspectives')}
        />
      )
    }

    if (item.type === TodoListTypes.REVERSE_RECRUITING) {
      return (
        <WhiteListItem
          context={I18n.get('global.main.reverseRecruiting.context.inspiration')}
          // newTag
          // newTagTitle='global.new'
          header={I18n.get('global.main.reverseRecruiting.title')}
          event={item.event}
          betaTag
          partnerLogo={item.partnerLogo}
          // stack={[
          //   require('../../assets/images/adaptive-icon.png'),
          //   require('../../assets/images/adaptive-icon.png'),
          //   require('../../assets/images/adaptive-icon.png'),
          //   require('../../assets/images/adaptive-icon.png'),
          //   require('../../assets/images/adaptive-icon.png')
          // ].map((image) => ({ image }))}
          illustration={{
            src: require('../../assets/images/illustrations/skyscraper.png'),
            width: 192,
            height: 148
          }}
          containerStyle={{ marginBottom: 16 }}
          onPress={() => navigation.navigate('ReverseRecruiting')}
        />
      )
    }

    if (item.type === TodoListTypes.EMPTY_TODO_TAB) {
      return (
        <EmptyState
          description={
            isAivySelect
              ? `${I18n.get('global.empty_state.todo_tab')}\n\n${replaceTranslationKey(
                  I18n.get('global.empty_state.todo_tab_partner'),
                  '{{partner}}',
                  partnerDisplayName
                )}`
              : I18n.get('global.empty_state.todo_tab')
          }
          image={{
            width: isAivySelect ? 128 : 192,
            height: isAivySelect ? 128 : 192,
            src: isAivySelect
              ? require('../../assets/images/illustrations/notification.png')
              : require('../../assets/images/illustrations/empty-todo-tab.png')
          }}
        />
      )
    }

    if (item.type === TodoListTypes.EMPTY_DONE_TAB) {
      return (
        <EmptyState
          description={I18n.get('global.empty_state.done_tab')}
          image={{
            width: 192,
            height: 192,
            src: require('../../assets/images/illustrations/illustration-men-heart.png')
          }}
        />
      )
    }
  }

  const displaySignup = isAnonym && isAivy(activeSpace?.partner_id)

  return (
    <>
      <MainSmallHeader
        onPress={() => navigation.navigate('Settings')}
        isAnonym={!!isAnonym}
        isEmailVerified={!!isEmailVerified}
        containerStyle={{ opacity, zIndex }}
      />
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: displaySignup ? colors.oceanDarkBlue : colors.paleGrey
        }}
      >
        <View>
          {displaySignup && <SignUp onPress={() => navigation.navigate('SaveStrengthDNA')} />}
        </View>
        <StatusBar barStyle={displaySignup ? 'light-content' : 'dark-content'} />
        <View>
          {!isAnonym && !isEmailVerified && (
            <VerifyEmail
              onPress={() =>
                navigation.navigate('SaveStrengthDNA', {
                  screen: 'SaveStrengthCodeVerify',
                  params: { verifyEmail: true, email }
                })
              }
            />
          )}
          {isExpiresAtVisible && activeSpace.expiresAt && (
            <ExpiresAtHint
              onPress={() => setIsExpiresAtVisible(false)}
              expiresAt={activeSpace.expiresAt}
            />
          )}
        </View>
        <Animated.FlatList
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {
                    y: scrolling
                  }
                }
              }
            ],
            { useNativeDriver: Platform.OS !== 'web' }
          )}
          ref={flatlistRef}
          data={data}
          renderItem={renderItem}
          ListHeaderComponent={
            <ListHeaderComponent
              currentSwitchTab={currentSwitchTab}
              setCurrentSwitchTab={setCurrentSwitchTab}
              navigation={navigation}
            />
          }
          contentContainerStyle={{
            paddingBottom: 90,
            paddingTop: 14
          }}
          style={styles.container}
          keyExtractor={(item) => item.id}
        />

        <StrengthModalize
          open={isFocused}
          navigateFirstChallenge={navigateFirstChallenge}
          navigation={navigation}
        />
      </SafeAreaView>
    </>
  )
}

Main.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default observer(Main)
