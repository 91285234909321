import { ANSWER } from './schema/answer'
import { PERSON } from './schema/person'
import { USER } from './schema/user'

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) ${USER}
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) ${USER}
  }
`
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer($input: CreateAnswerInput!, $condition: ModelAnswerConditionInput) {
    createAnswer(input: $input, condition: $condition) ${ANSWER}
  }
`
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer($input: UpdateAnswerInput!, $condition: ModelAnswerConditionInput) {
    updateAnswer(input: $input, condition: $condition) ${ANSWER}
  }
`
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer($input: DeleteAnswerInput!, $condition: ModelAnswerConditionInput) {
    deleteAnswer(input: $input, condition: $condition) ${ANSWER}
  }
`

export const createPerson = /* GraphQL */ `
  mutation CreatePerson($input: CreatePersonInput!, $condition: ModelPersonConditionInput) {
    createPerson(input: $input, condition: $condition) ${PERSON}
  }
`
