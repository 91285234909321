import AnswersStore from './AnswersStore'
import AppSettingsStore from './AppSettingsStore'
import ChallengesStore from './ChallengesStore'
import HardFactsStore from './HardFactsStore'
import PartnerStore from './PartnerStore'
import PauseStore from './PauseStore'
import SpaceStore from './SpaceStore'
import UserStore from './UserStore'

class RootStore {
  constructor() {
    this.answersStore = new AnswersStore(this)
    this.appSettingsStore = new AppSettingsStore(this)
    this.challengesStore = new ChallengesStore(this)
    this.hardFactsStore = new HardFactsStore(this)
    this.partnerStore = new PartnerStore(this)
    this.pauseStore = new PauseStore(this)
    this.spaceStore = new SpaceStore(this)
    this.userStore = new UserStore(this)
    // add more stores here
  }

  getMainScreenProps() {
    return {
      progressBar: {
        value: this.answersStore.answers.length,
        max: this.challengesStore.challenges.length
      }
    }
  }

  getProgressBarProps() {
    return {
      value: this.answersStore.answers.length,
      max: this.challengesStore.challenges.length
    }
  }

  clearAllStores() {
    this.answersStore.clear()
    this.appSettingsStore.clear()
    this.challengesStore.clear()
    this.hardFactsStore.clear()
    this.partnerStore.clear()
    this.pauseStore.clear()
    this.userStore.clear()
    this.spaceStore.clear()
  }
}

export default RootStore
