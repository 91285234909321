import React, { useContext } from 'react'
import { View, StyleSheet, StatusBar, Image, TouchableOpacity, ScrollView } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import PropTypes from 'prop-types'

import colors from '../../theme/colors'
import Typo from '../Typo'
import metrics from '../../theme/metrics'
import Button from '../Button'
import { AuthContext } from '../../context'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.brightBlue,
    paddingHorizontal: 20
  }
})

const Intro = ({ onPress, exam }) => {
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()

  const { title, timeToComplete, introScreen, backgroundColor } = exam

  const { rootStore } = useContext(AuthContext)
  const { language } = rootStore.userStore

  return (
    <>
      <StatusBar barStyle='light-content' />
      <View style={[styles.container, { paddingTop: insets.top + 16, backgroundColor }]}>
        <View
          style={{
            marginTop: 8,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typo.H1White translate>{title[language]}</Typo.H1White>
          <TouchableOpacity onPress={() => navigation.pop()}>
            <Image
              style={{ width: 24, height: 24, resizeMode: 'contain' }}
              source={require('../../assets/images/x-icon-white.png')}
            />
          </TouchableOpacity>
        </View>
        <View
          style={{
            marginTop: 8,
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Image
            style={{ width: 16, height: 16, marginRight: 6 }}
            source={require('../../assets/images/duration-icon.png')}
          />
          <Typo.H4White>{`${timeToComplete / 1000 / 60}min`}</Typo.H4White>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 32 }}
        >
          <View
            style={{
              marginTop: 32,
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Image
              style={{
                resizeMode: 'contain',
                ...metrics.calculateResponsiveDimensions(introScreen.imageDimension)
              }}
              source={{ uri: introScreen.image }}
            />
          </View>
          <Typo.T1White translate style={{ marginTop: 32 }}>
            {introScreen.intro[language]}
          </Typo.T1White>
        </ScrollView>
      </View>
      <View style={{ paddingHorizontal: 20, paddingTop: 16, backgroundColor }}>
        <Button
          title='global.challenge.start_test_challenge_button'
          type='white'
          onPress={onPress}
          containerStyle={{ marginTop: 'auto', marginBottom: insets.bottom + 32 }}
        />
      </View>
    </>
  )
}

Intro.propTypes = {
  onPress: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

export default Intro
