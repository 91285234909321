import React, { useState, useEffect, useContext } from 'react'
import { View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native'
import PropTypes from 'prop-types'
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell
} from 'react-native-confirmation-code-field'
import { Auth, I18n } from 'aws-amplify'
import * as Updates from 'expo-updates'

import { Loader, ModalHeader, Typo } from '../../components'
import { rowCenter } from '../../styles'
import { replaceTranslationKey } from '../../utils/helpers'
import { notifyBugsnag } from '../../utils/bugsnag'
import colors from '../../theme/colors'
import { AuthContext } from '../../context'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  codeFieldRoot: { marginTop: 20 },
  cell: {
    width: 40,
    height: 40,
    fontSize: 24,
    fontFamily: 'fira-sans-light',
    lineHeight: 32,

    textAlign: 'center'
  },
  borderStyle: {
    borderBottomWidth: 2,
    borderBottomColor: colors.softGrey
  },
  focusBorderStyle: {
    borderBottomColor: colors.primaryBlue
  },
  errorBorderStyle: {
    borderBottomColor: colors.error
  }
})

const CELL_COUNT = 5

let cognitoUserForResend = null

const VerifyCode = ({ route }) => {
  const { checkLogin } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [errorCodeVerify, setErrorCodeVerify] = useState(null)

  const [value, setValue] = useState('')
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT })
  // eslint-disable-next-line no-unused-vars
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue
  })
  const [seconds, setSeconds] = useState(30)
  const { email, cognitoUser, reload } = route.params

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds <= 0) return
      setSeconds((sec) => sec - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [seconds])

  useEffect(() => {
    const answerCustomChallenge = async () => {
      setIsLoading(true)
      let codeVerified = false

      try {
        await Auth.sendCustomChallengeAnswer(cognitoUserForResend || cognitoUser, value)
        await Auth.currentSession()
        codeVerified = true
      } catch (err) {
        setErrorCodeVerify(I18n.get('global.alert.error.bad_code'))

        if (err !== 'No current user') {
          notifyBugsnag(err)
        }
      }

      if (!codeVerified) {
        setIsLoading(false)
        return
      }

      if (reload) {
        try {
          if (Platform.OS === 'web') {
            window && window.location.reload()
          } else {
            await Updates.reloadAsync()
          }
        } catch (err) {
          notifyBugsnag(err)
        }

        return
      }

      await checkLogin()
    }

    if (value.length < CELL_COUNT) return

    answerCustomChallenge()
  }, [value, cognitoUser, checkLogin, reload])

  useEffect(() => {
    setErrorCodeVerify(null)
  }, [value])

  const resendCode = async () => {
    setIsLoading(true)
    try {
      Auth.configure({
        authenticationFlowType: 'CUSTOM_AUTH'
      })
      cognitoUserForResend = await Auth.signIn(email.toLowerCase())

      setSeconds(30)
      setErrorCodeVerify(null)
      setValue('')
    } catch (error) {
      notifyBugsnag(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <View style={styles.container}>
      {reload && <View style={{ height: 64 }} />}
      <ModalHeader
        headerTitle='signin.title'
        onPress={() => Updates.reloadAsync()}
        withBackButton
        showBackNavigation={!reload}
      />
      <View style={{ marginTop: 16, paddingHorizontal: 24 }}>
        <Typo.H2Blue translate center marginBottom={4}>
          global.save_strength_code_verify.code_send_information_5
        </Typo.H2Blue>
        <Typo.T1Black translate center marginTop={8} marginBottom={12}>
          {email}
        </Typo.T1Black>
        <Typo.T1LightBlack translate marginBottom={42} center>
          global.save_strength_code_verify.enter_text
        </Typo.T1LightBlack>
        <CodeField
          ref={ref}
          value={value}
          caretHidden={false}
          onChangeText={setValue}
          cellCount={CELL_COUNT}
          rootStyle={styles.codeFieldRoot}
          keyboardType='number-pad'
          textContentType='oneTimeCode'
          renderCell={({ index, symbol, isFocused }) => (
            <View
              key={index}
              style={[
                styles.borderStyle,
                isFocused ? styles.focusBorderStyle : {},
                errorCodeVerify ? styles.errorBorderStyle : {}
              ]}
            >
              <Text key={index} style={styles.cell} onLayout={getCellOnLayoutHandler(index)}>
                {symbol || (isFocused ? <Cursor /> : null)}
              </Text>
            </View>
          )}
        />
        {errorCodeVerify && (
          <View style={{ marginTop: 18 }}>
            <Typo.T2Black center>{errorCodeVerify}</Typo.T2Black>
            <TouchableOpacity style={{ marginTop: 8 }} onPress={resendCode}>
              <Typo.ButtonBlue center translate>
                global.save_strength_code_verify.resend_action
              </Typo.ButtonBlue>
            </TouchableOpacity>
            <TouchableOpacity style={{ marginTop: 8 }} onPress={() => setValue('')}>
              <Typo.ButtonBlue center translate>
                global.save_strength_code_verify.clear_action
              </Typo.ButtonBlue>
            </TouchableOpacity>
          </View>
        )}
        {!isLoading && !errorCodeVerify && (
          <View style={{ marginTop: 18 }}>
            {seconds > 0 ? (
              <Typo.T2Black center>
                {replaceTranslationKey(
                  I18n.get('global.save_strength_code_verify.resend_seconds'),
                  '{{seconds}}',
                  seconds
                )}
              </Typo.T2Black>
            ) : (
              <View style={[rowCenter, { alignSelf: 'center' }]}>
                <Typo.T2Black translate center>
                  global.save_strength_code_verify.resend_question
                </Typo.T2Black>
                <TouchableOpacity style={{ marginLeft: 8 }} onPress={resendCode}>
                  <Typo.ButtonBlue translate>
                    global.save_strength_code_verify.resend_action
                  </Typo.ButtonBlue>
                </TouchableOpacity>
              </View>
            )}
          </View>
        )}
        {isLoading && (
          <View style={{ padding: 18 }}>
            <Loader />
          </View>
        )}
      </View>
    </View>
  )
}

VerifyCode.propTypes = {
  route: PropTypes.object.isRequired
}

export default VerifyCode
