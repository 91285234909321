import React, { useContext } from 'react'
import { View, StyleSheet, Image, ImageBackground, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Button, ModalHeader, Typo } from '../../../components'
import colors from '../../../theme/colors'
import metrics from '../../../theme/metrics'
import { rowCenter } from '../../../styles'
import { AuthContext } from '../../../context'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  imageStyle: {
    resizeMode: Platform.OS === 'android' ? undefined : 'contain',
    position: 'absolute',
    bottom: -3,
    alignSelf: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    ...metrics.calculateResponsiveDimensions({ width: 181, height: 195 })
  },
  iconStyle: { height: 32, width: 32, marginRight: 24 },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0,0,0,0.25)'
  },
  companyLogo: {
    height: 40,
    width: 40,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#fff',
    resizeMode: Platform.OS === 'android' ? undefined : 'contain',
    backgroundColor: colors.lightGrey
  },
  manyMore: {
    height: 40,
    width: 40,
    borderWidth: 2,
    borderColor: '#fff',
    borderRadius: 10,
    backgroundColor: colors.darkBlue,
    left: -32,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const SaveStrengthIntro = ({ navigation }) => {
  const insets = useSafeAreaInsets()
  const { rootStore } = useContext(AuthContext)
  const { isAivySelect } = rootStore.spaceStore

  return (
    <View style={styles.container}>
      {/* <View style={{ backgroundColor: colors.darkBlue, height: metrics.heightPercentageToDP(36) }}> */}

      <ImageBackground
        style={{
          width: '100%',
          height: metrics.heightPercentageToDP(36),
          minHeight: 272
        }}
        imageStyle={{ minHeight: 272 }}
        source={require('../../../assets/images/background-with-shape.png')}
      >
        <View style={styles.overlay} />
        <ModalHeader
          accentColor='#fff'
          onPress={() => navigation.goBack()}
          headerTitle='global.save_strength_intro.modal_header_title'
        />
        <Image
          source={require('../../../assets/images/illustrations/strength-dna-illustration.png')}
          style={styles.imageStyle}
        />
      </ImageBackground>
      {/* <ImageBackground
          imageStyle={styles.imageStyle}
          style={styles.imageBackgroundStyle}
          source={require('../../../assets/images/illustrations/strength-dna-illustration.png')}
        >
          <Image
            style={{
              ...metrics.calculateResponsiveDimensions({ width: 70, height: 70 }),
              position: 'absolute',
              right: -42,
              top: -42
            }}
            source={require('../../../assets/images/illustrations/free-badge.png')}
          />
        </ImageBackground> */}
      {/* </View> */}
      <View style={{ flex: 1, padding: 20 }}>
        <Typo.H2Black translate marginBottom={24}>
          global.save_strength_intro.apply_question
        </Typo.H2Black>
        {isAivySelect === null && (
          <View style={[rowCenter, { marginBottom: 20 }]}>
            <Image
              style={styles.iconStyle}
              source={require('../../../assets/images/company-icon.png')}
            />
            {/* <View style={{ flexDirection: 'row' }}>
            <Image
              style={styles.companyLogo}
              source={require('../../../assets/images/logos/companisto.png')}
            />
            <Image
              style={[styles.companyLogo, { left: -8 }]}
              source={require('../../../assets/images/logos/aivy.png')}
            />
            <Image
              style={[styles.companyLogo, { left: -16 }]}
              source={require('../../../assets/images/logos/roche.png')}
            />
            <View style={styles.manyMore}>
              <Typo.H4White>+10</Typo.H4White>
            </View>
          </View> */}
            <Typo.T1Black style={{ flexWrap: 'wrap', flex: 1 }} translate>
              global.save_strength_intro.apply
            </Typo.T1Black>
          </View>
        )}
        <View style={[rowCenter, { marginBottom: 20 }]}>
          <Image
            style={styles.iconStyle}
            source={require('../../../assets/images/mail-icon.png')}
          />
          <Typo.T1Black style={{ flexWrap: 'wrap', flex: 1 }} translate>
            {isAivySelect === null
              ? 'global.save_strength_intro.information_1'
              : 'global.save_strength_intro.information_1_select'}
          </Typo.T1Black>
        </View>
        <View style={rowCenter}>
          <Image style={styles.iconStyle} source={require('../../../assets/images/24-icon.png')} />
          <Typo.T1Black style={{ flexWrap: 'wrap', flex: 1 }} translate>
            {isAivySelect === null
              ? 'global.save_strength_intro.information_2'
              : 'global.save_strength_intro.information_2_select'}
          </Typo.T1Black>
        </View>
      </View>
      <View style={{ paddingHorizontal: 24, paddingBottom: insets.bottom + 20 }}>
        <Button
          title='global.save_strength_intro.get_started_action'
          onPress={() => navigation.navigate('SaveStrengthEmail')}
          backgroundColor={colors.primaryGreen}
        />
      </View>
    </View>
  )
}

SaveStrengthIntro.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default SaveStrengthIntro
