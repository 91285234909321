// You can add universal styles here like flexDirection: row, centerAll, itemsEnd and container-like equally spacing from all directions and many more. Here we place the explore feature’s components related styles.
export const rowCenter = { flexDirection: 'row', alignItems: 'center' }
export const rowCenterBetween = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}
export const rowCenterEvenly = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly'
}
