import React, { useContext, useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
  Platform,
  ActivityIndicator
} from 'react-native'
import PropTypes from 'prop-types'
import * as Linking from 'expo-linking'
import { observer } from 'mobx-react'
// import { I18n } from 'aws-amplify'
import { shuffle } from 'lodash'

import AsyncStorage from '../../utils/async-storage'
import { ReverseRecruitingListItem, Typo } from '../../components'
import metrics from '../../theme/metrics'
import colors from '../../theme/colors'
import { notifyBugsnag } from '../../utils/bugsnag'
import { query } from '../../graphql'
import { AuthContext } from '../../context'
import { trackEvent } from '../../utils/tracking'

// import LottieAnimation from '../../components/challenge/LottieAnimation'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightPaleGrey
  }
})

// const ListEmptyComponent = () => (
//   <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
//     <LottieAnimation
//       loop
//       autoplay
//       width={200}
//       height={200}
//       animationData={require('../../assets/animations/searching.json')}
//     />
//     <Typo.T1Black center>{I18n.get('reverserecruiting.empty')}</Typo.T1Black>
//   </View>
// )

const ListHeaderComponent = () => (
  <View style={{ marginBottom: 62, paddingTop: 46 }}>
    <Typo.H3Black marginBottom={4} translate>
      global.main.reverseRecruiting.context.inspiration
    </Typo.H3Black>
    <Typo.H1Black marginBottom={8} translate>
      reverserecruiting.title
    </Typo.H1Black>
    <View>
      <Image
        source={require('../../assets/images/illustrations/skyscraper-detail.png')}
        style={{
          ...metrics.calculateResponsiveDimensions({ height: 150, width: 200 }),
          position: 'absolute',
          right: -24
        }}
      />
      {/* <View style={{ width: metrics.widthPercentageToDP(60) }}>
        <Typo.T2Black translate>reverserecruiting.subTitle</Typo.T2Black>
      </View> */}
    </View>
  </View>
)

const ReverseRecruiting = ({ navigation }) => {
  const { rootStore } = useContext(AuthContext)
  const { username } = rootStore.userStore
  const [reachItems, setReachItems] = useState(null)

  useEffect(() => {
    fetch(
      `https://react-native-app-content.s3.eu-central-1.amazonaws.com/reach/reach-customers.json?random_number=${new Date().getTime()}`
    )
      .then((response) => response.json())
      .then((json) => {
        AsyncStorage.getData('deletedReach').then((res) => {
          const result = [...json]
          query({ query: 'searchNewMatchingCareersFromBasicCampaigns' }).then(async () => {
            try {
              const reachRequests = await query({
                query: 'listReachRequestsByOwnerByDate',
                variables: { owner: username }
              })
              const resultArray = reachRequests.data.listReachRequestsByOwnerByDate.items

              if (resultArray.length > 0) {
                resultArray.forEach((r) => {
                  result.push({
                    city: '',
                    id: r.id,
                    level: '',
                    logo: r.partner.logo,
                    url: `https://pbc.aivy.app/reach?campaign=${r.campaign.id}`, // https://develop.pbc.aivy.app/reach?campaign=${r.campaign.id}
                    text: `**${r.partner.display_name}** mit der Stelle **${r.career.title}**. Bist du interessiert?`
                  })
                })
              }
              if (!res) {
                setReachItems(shuffle(result))
              }
              const deletedReach = JSON.parse(res)
              const filteredReach = []
              result.forEach((reachItem) => {
                if (deletedReach?.includes(reachItem.id)) return
                filteredReach.push(reachItem)
              })

              setReachItems(shuffle(filteredReach))
            } catch (error) {
              notifyBugsnag(error)
            }
          })
        })
      })
      .catch((error) => {
        notifyBugsnag(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPressReach = (url, career) => {
    trackEvent('ReachRequestPressed', { url, career })
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS === 'web') {
          return window.open(url)
        }
        return Linking.openURL(url)
      }
      notifyBugsnag(new Error(`[ReachRequestPressed] Don't know how to open URI: ${url}`))
    })
  }

  const onPressDeletedItem = async (id, career) => {
    let deletedItems = await AsyncStorage.getData('deletedReach')
    if (!deletedItems) {
      deletedItems = []
    } else {
      deletedItems = JSON.parse(deletedItems)
    }
    deletedItems.push(id)
    await AsyncStorage.storeData('deletedReach', JSON.stringify(deletedItems))
    setReachItems(reachItems.filter((item) => item.id !== id))
    trackEvent('ReachRequestNotInteressted', { career })
  }

  const renderItem = ({ item }) => (
    <ReverseRecruitingListItem
      onPress={() => onPressReach(item.url, item.career)}
      onPressDeletedItem={() => onPressDeletedItem(item.id, item.career)}
      level={item.level}
      careerTitle={item.career}
      partnerTitle={item.displayName}
      partnerLogo={item.logo}
      containerStyle={{ marginBottom: 16 }}
      markdownText={item.text}
      city={item.city}
    />
  )
  // const { rootStore } = useContext(AuthContext)
  // const reachRequests = rootStore.userStore.userProfile?.reach_requests?.items

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        ListHeaderComponent={<ListHeaderComponent />}
        ListEmptyComponent={<ActivityIndicator />}
        contentContainerStyle={{ paddingHorizontal: 24 }}
        data={reachItems}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />
      <TouchableOpacity
        style={{ position: 'absolute', top: 14, right: 18 }}
        onPress={() => navigation.goBack()}
      >
        <Image
          source={require('../../assets/images/x-icon-grey.png')}
          style={{ height: 24, width: 24 }}
        />
      </TouchableOpacity>
    </SafeAreaView>
  )
}

ReverseRecruiting.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default observer(ReverseRecruiting)
