import * as React from 'react'
import Svg, { Defs, Path, Use } from 'react-native-svg'
import { View } from 'react-native'
import PropTypes from 'prop-types'

const ArrowDownIcon = ({ containerStyle, color }) => (
  <View style={containerStyle}>
    <Svg
      width={12}
      height={8}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={containerStyle}
    >
      <Defs>
        <Path id='a' d='m2.295 8.295 4.58-4.59-4.58-4.59 1.41-1.41 6 6-6 6z' />
      </Defs>
      <Use fill={color} fillRule='nonzero' transform='rotate(90 6 3.705)' xlinkHref='#a' />
    </Svg>
  </View>
)

ArrowDownIcon.propTypes = {
  containerStyle: PropTypes.object,
  color: PropTypes.string
}

ArrowDownIcon.defaultProps = {
  containerStyle: {},
  color: '#2B2B2B'
}

export default ArrowDownIcon
