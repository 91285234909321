import { makeAutoObservable } from 'mobx'

import { defaultTemplate } from '../assets/mail-template/apply'
import { query } from '../graphql'
import { ALL_CHALLENGES } from '../constants/challenges'
import { isNullOrUndefined } from '../utils/helpers'

class AppSettingsStore {
  constructor() {
    this.partner_id = null
    this.isPreAssessment = null
    this.logo = null
    this.logo_alt = ''
    this.logo_ratio = {
      height: 0,
      width: 0
    }
    this.career_site_url = ''
    this.challenges = []
    this.usedAlgorithm = 'matchMeIfYouCan'
    this.challengeConfigs = {}
    this.custom_data_policy = false
    this.custom_apply_mail_template = defaultTemplate
    this.partnerName = ''
    this.partnerDisplayName = ''
    this.careerName = ''
    this.careerRedirectUrl = null
    this.components = {
      fbpixel: null,
      successPerspective: false,
      applyWithID: false,
      newsletter: false,
      randomChallenges: true,
      introSlider: true,
      completedScreen: true
    }

    makeAutoObservable(this)
  }

  setAppSettings(assessmentConfig) {
    if (!assessmentConfig) {
      return
    }

    if (assessmentConfig.app_settings === null || assessmentConfig.app_settings === undefined) {
      // set default app settings
      assessmentConfig.app_settings = {}
    }

    const {
      components,
      usedAlgorithm,
      challenges,
      custom_data_policy,
      custom_apply_mail_template
    } = assessmentConfig.app_settings

    this.isPreAssessment = !!assessmentConfig.partner_id
    this.partner_id = assessmentConfig.partner_id || assessmentConfig.id
    this.careerName = this.isPreAssessment ? assessmentConfig.title : ''
    this.careerRedirectUrl = this.isPreAssessment ? assessmentConfig.redirect_url : null
    this.partnerName = this.isPreAssessment ? assessmentConfig.partnerName : assessmentConfig.name
    this.partnerDisplayName = assessmentConfig.display_name || assessmentConfig.name
    this.career_site_url = assessmentConfig.career_site_url
    this.logo = assessmentConfig.logo
    this.logo_alt = assessmentConfig.logo_alt

    this.challenges = !isNullOrUndefined(challenges)
      ? JSON.parse(challenges)
      : Object.values(ALL_CHALLENGES).map(({ exam_id }) => ({ exam_id }))
    this.challengeConfigs = assessmentConfig.challengeConfigs
    this.custom_data_policy = custom_data_policy

    if (components) {
      this.components = Object.assign(this.components, JSON.parse(components))
    }
    if (usedAlgorithm) {
      this.usedAlgorithm = usedAlgorithm
    }
    if (custom_apply_mail_template) {
      this.custom_apply_mail_template = custom_apply_mail_template
    }
    if (assessmentConfig.logo_ratio) {
      this.logo_ratio = JSON.parse(assessmentConfig.logo_ratio)
    }
  }

  static async getZscores(id) {
    if (!id) return null

    const answer = await query({
      query: 'getChallengeConfig',
      variables: { id },
      authMode: 'AWS_IAM'
    })

    return answer
  }

  static async getSettingsForPartner(partnerObj) {
    if (!partnerObj) return null

    const systemAnswer = await query({
      query: 'getSystem',
      variables: { id: 'CURRENT_CHALLENGE_CONFIG_ID' },
      authMode: 'AWS_IAM'
    })

    const zScores = await this.getZscores(JSON.parse(systemAnswer.data.getSystem.data).id)

    const zScoresObject = zScores.data.getChallengeConfig.configs
    const mergedObj = { ...partnerObj, challengeConfigs: zScoresObject }

    return mergedObj
  }

  static async getSettingsForCareer(careerObj, partnerObj) {
    const partner = await this.getSettingsForPartner(partnerObj)
    const zScores = await this.getZscores(careerObj.challenge_config_id)

    const zScoresObject = zScores.data.getChallengeConfig.configs
    const mergedObj = { ...partner, ...careerObj, challengeConfigs: zScoresObject }
    const appSettings = careerObj.app_settings ? careerObj.app_settings : partner.app_settings
    mergedObj.partnerName = partner.name

    mergedObj.app_settings = appSettings
    mergedObj.logo = careerObj.logo || partner.logo

    return mergedObj
  }

  clear() {
    this.partner_id = null
    this.isPreAssessment = null
    this.logo = null
    this.logo_alt = ''
    this.logo_ratio = {
      height: 0,
      width: 0
    }
    this.career_site_url = ''
    this.challenges = []
    this.usedAlgorithm = 'matchMeIfYouCan'
    this.custom_data_policy = false
    this.custom_apply_mail_template = defaultTemplate
    this.partnerName = ''
    this.partnerDisplayName = ''
    this.careerName = ''
    this.components = {
      successPerspective: false,
      applyWithID: false,
      newsletter: false,
      randomChallenges: true,
      introSlider: true,
      completedScreen: true
    }
  }
}

export default AppSettingsStore
