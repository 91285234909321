import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import Typo from '../../../Typo'
import TradeOffBalken from './TradeOffBalken'
import colors from '../../../../theme/colors'

const customMDStyles = StyleSheet.create({
  text: {
    fontFamily: 'fira-sans-regular',
    fontSize: 14,
    lineHeight: 22
  },
  strong: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'fira-sans-medium'
  }
})

const s = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: colors.lightBlue,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5
  }
})

const TradeOff = ({
  containerStyle,
  title,
  tradeoff,
  firstDescription,
  // secondDescription,
  leftSkala,
  rightSkala
}) => (
  <View style={[s.container, containerStyle]}>
    <Typo.H4Black>{title}</Typo.H4Black>
    <TradeOffBalken containerStyle={{ marginTop: 24, marginBottom: 0 }} value={tradeoff} />
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 24
      }}
    >
      <Typo.SmallTextBlue>{leftSkala}</Typo.SmallTextBlue>
      <Typo.SmallTextBlue>{rightSkala}</Typo.SmallTextBlue>
    </View>
    <Typo.Markdown style={customMDStyles}>{firstDescription}</Typo.Markdown>
    {/* {secondDescription && (
      <Typo.Markdown marginTop={16} style={customMDStyles}>
        {secondDescription}
      </Typo.Markdown>
    )} */}
  </View>
)

TradeOff.propTypes = {
  containerStyle: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  tradeoff: PropTypes.number.isRequired,
  firstDescription: PropTypes.string.isRequired,
  // secondDescription: PropTypes.string.isRequired,
  leftSkala: PropTypes.string.isRequired,
  rightSkala: PropTypes.string.isRequired
}

export default TradeOff
