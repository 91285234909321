import React, { useMemo, useState } from 'react'
import { StyleSheet, TouchableOpacity, Image } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import Typo from '../../../../components/Typo'
import colors from '../../../../theme/colors'
import metrics from '../../../../theme/metrics'

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    width: metrics.widthPercentageToDP(42),
    height: metrics.widthPercentageToDP(42),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: colors.primaryBlue,
    borderWidth: 1
  }
})

const Button = ({ type, onPress, active, disabled, onLayout }) => {
  const activeStyle = { backgroundColor: colors.primaryBlue }
  const [isLoading] = useState(false)

  const imageSource = useMemo(() => {
    if (type === 'false') {
      return active
        ? require('../../../../assets/challenges/analogical-reasoning/game-assets/false.png')
        : require('../../../../assets/challenges/analogical-reasoning/game-assets/false-blue.png')
    }

    return active
      ? require('../../../../assets/challenges/analogical-reasoning/game-assets/correct.png')
      : require('../../../../assets/challenges/analogical-reasoning/game-assets/correct-blue.png')
  }, [type, active])

  const buttonTitle = useMemo(
    () => (type === 'false' ? I18n.get('global.false') : I18n.get('global.correct')),
    [type]
  )

  // useEffect(() => {
  //  async function loadAsync() {
  //    await Promise.all([
  //      Asset.loadAsync([
  //        require('../../../../assets/challenges/analogical-reasoning/game-assets/false.png'),
  //        require('../../../../assets/challenges/analogical-reasoning/game-assets/false-blue.png'),
  //        require('../../../../assets/challenges/analogical-reasoning/game-assets/correct.png'),
  //        require('../../../../assets/challenges/analogical-reasoning/game-assets/correct-blue.png')
  //      ])
  //    ])
  //  }

  //  loadAsync()
  //  setIsLoading(false)
  // }, [])

  if (isLoading) {
    return null
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      style={[styles.container, active ? activeStyle : {}]}
      onPress={onPress}
      onLayout={onLayout}
    >
      <Image
        style={{ height: 42, width: 44, marginBottom: 14, resizeMode: 'contain' }}
        source={imageSource}
      />
      <Typo.H2LightBlue style={{ color: active ? '#fff' : colors.primaryBlue }} translate>
        {buttonTitle}
      </Typo.H2LightBlue>
    </TouchableOpacity>
  )
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onLayout: PropTypes.func
}

Button.defaultProps = {
  onLayout: () => {}
}

export default Button
