import _ from 'lodash'

/**
 * Prevent User from tapping fast trough the challenge
 */
export const BLOCK_TIME_MS = 300

/**
 * Updated Questions for the new One Round Update based on briefing
 * https://docs.google.com/document/d/17VHu4Ukk_f0V-Wn0sQNzqWGKOw85Y8jquPuZxoF8W_0/edit
 */
const EMOTION_QUESTIONS_ONE_ROUND = [
  {
    id: 1,
    category: 'E1',
    photo_url: '01_anger_E1.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_anger_E1.jpg'),
    emotion: 'anger'
  },
  {
    id: 2,
    category: 'E17',
    photo_url: '01_disgust_E17.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_disgust_E17.jpg'),
    emotion: 'disgust'
  },
  {
    id: 3,
    category: 'E25',
    photo_url: '01_fear_E25.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_fear_E25.jpg'),
    emotion: 'fear'
  },
  {
    id: 4,
    category: 'E33',
    photo_url: '01_happiness_E33.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_happiness_E33.jpg'),
    emotion: 'happiness'
  },
  {
    id: 5,
    category: 'E41',
    photo_url: '01_sadness_E41.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_sadness_E41.jpg'),
    emotion: 'sadness'
  },
  {
    id: 6,
    category: 'E49',
    photo_url: '01_surprise_E49.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_surprise_E49.jpg'),
    emotion: 'surprise'
  },
  {
    id: 7,
    category: 'E2',
    photo_url: '02_anger_E2.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_anger_E2.jpg'),
    emotion: 'anger'
  },
  {
    id: 8,
    category: 'E18',
    photo_url: '02_disgust_E18.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_disgust_E18.jpg'),
    emotion: 'disgust'
  },
  {
    id: 9,
    category: 'E26',
    photo_url: '02_fear_E26.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_fear_E26.jpg'),
    emotion: 'fear'
  },
  {
    id: 10,
    category: 'E34',
    photo_url: '02_happiness_E34.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_happiness_E34.jpg'),
    emotion: 'happiness'
  },
  {
    id: 11,
    category: 'E42',
    photo_url: '02_sadness_E42.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_sadness_E42.jpg'),
    emotion: 'sadness'
  },
  {
    id: 12,
    category: 'E50',
    photo_url: '02_surprise_E50.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_surprise_E50.jpg'),
    emotion: 'surprise'
  },
  {
    id: 13,
    category: 'E3',
    photo_url: '03_anger_E3.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_anger_E3.jpg'),
    emotion: 'anger'
  },
  {
    id: 14,
    category: 'E19',
    photo_url: '03_disgust_E19.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_disgust_E19.jpg'),
    emotion: 'disgust'
  },
  {
    id: 15,
    category: 'E27',
    photo_url: '03_fear_E27.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_fear_E27.jpg'),
    emotion: 'fear'
  },
  {
    id: 16,
    category: 'E35',
    photo_url: '03_happiness_E35.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_happiness_E35.jpg'),
    emotion: 'happiness'
  },
  {
    id: 17,
    category: 'E43',
    photo_url: '03_sadness_E43.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_sadness_E43.jpg'),
    emotion: 'sadness'
  },
  {
    id: 18,
    category: 'E51',
    photo_url: '03_surprise_E51.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_surprise_E51.jpg'),
    emotion: 'surprise'
  },
  {
    id: 19,
    category: 'E4',
    photo_url: '04_anger_E4.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_anger_E4.jpg'),
    emotion: 'anger'
  },
  {
    id: 20,
    category: 'E20',
    photo_url: '04_disgust_E20.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_disgust_E20.jpg'),
    emotion: 'disgust'
  },
  {
    id: 21,
    category: 'E28',
    photo_url: '04_fear_E28.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_fear_E28.jpg'),
    emotion: 'fear'
  },
  {
    id: 22,
    category: 'E36',
    photo_url: '04_happiness_E36.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_happiness_E36.jpg'),
    emotion: 'happiness'
  },
  {
    id: 23,
    category: 'E44',
    photo_url: '04_sadness_E44.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_sadness_E44.jpg'),
    emotion: 'sadness'
  },
  {
    id: 24,
    category: 'E52',
    photo_url: '04_surprise_E52.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_surprise_E52.jpg'),
    emotion: 'surprise'
  }
]

/**
 * All the questions for the Emotions Challenge
 */
const EMOTION_QUESTIONS = [
  {
    id: 1,
    category: 'E1',
    photo_url: '01_anger_E1.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_anger_E1.jpg'),
    emotion: 'anger'
  },
  {
    id: 2,
    category: 'E17',
    photo_url: '01_disgust_E17.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_disgust_E17.jpg'),
    emotion: 'disgust'
  },
  {
    id: 3,
    category: 'E25',
    photo_url: '01_fear_E25.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_fear_E25.jpg'),
    emotion: 'fear'
  },
  {
    id: 4,
    category: 'E33',
    photo_url: '01_happiness_E33.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_happiness_E33.jpg'),
    emotion: 'happiness'
  },
  {
    id: 5,
    category: 'E41',
    photo_url: '01_sadness_E41.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_sadness_E41.jpg'),
    emotion: 'sadness'
  },
  {
    id: 6,
    category: 'E49',
    photo_url: '01_surprise_E49.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/01_surprise_E49.jpg'),
    emotion: 'surprise'
  },
  {
    id: 7,
    category: 'E2',
    photo_url: '02_anger_E2.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_anger_E2.jpg'),
    emotion: 'anger'
  },
  {
    id: 8,
    category: 'E18',
    photo_url: '02_disgust_E18.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_disgust_E18.jpg'),
    emotion: 'disgust'
  },
  {
    id: 9,
    category: 'E26',
    photo_url: '02_fear_E26.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_fear_E26.jpg'),
    emotion: 'fear'
  },
  {
    id: 10,
    category: 'E34',
    photo_url: '02_happiness_E34.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_happiness_E34.jpg'),
    emotion: 'happiness'
  },
  {
    id: 11,
    category: 'E42',
    photo_url: '02_sadness_E42.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_sadness_E42.jpg'),
    emotion: 'sadness'
  },
  {
    id: 12,
    category: 'E50',
    photo_url: '02_surprise_E50.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/02_surprise_E50.jpg'),
    emotion: 'surprise'
  },
  {
    id: 13,
    category: 'E3',
    photo_url: '03_anger_E3.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_anger_E3.jpg'),
    emotion: 'anger'
  },
  {
    id: 14,
    category: 'E19',
    photo_url: '03_disgust_E19.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_disgust_E19.jpg'),
    emotion: 'disgust'
  },
  {
    id: 15,
    category: 'E27',
    photo_url: '03_fear_E27.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_fear_E27.jpg'),
    emotion: 'fear'
  },
  {
    id: 16,
    category: 'E35',
    photo_url: '03_happiness_E35.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_happiness_E35.jpg'),
    emotion: 'happiness'
  },
  {
    id: 17,
    category: 'E43',
    photo_url: '03_sadness_E43.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_sadness_E43.jpg'),
    emotion: 'sadness'
  },
  {
    id: 18,
    category: 'E51',
    photo_url: '03_surprise_E51.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/03_surprise_E51.jpg'),
    emotion: 'surprise'
  },
  {
    id: 19,
    category: 'E4',
    photo_url: '04_anger_E4.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_anger_E4.jpg'),
    emotion: 'anger'
  },
  {
    id: 20,
    category: 'E20',
    photo_url: '04_disgust_E20.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_disgust_E20.jpg'),
    emotion: 'disgust'
  },
  {
    id: 21,
    category: 'E28',
    photo_url: '04_fear_E28.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_fear_E28.jpg'),
    emotion: 'fear'
  },
  {
    id: 22,
    category: 'E36',
    photo_url: '04_happiness_E36.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_happiness_E36.jpg'),
    emotion: 'happiness'
  },
  {
    id: 23,
    category: 'E44',
    photo_url: '04_sadness_E44.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_sadness_E44.jpg'),
    emotion: 'sadness'
  },
  {
    id: 24,
    category: 'E52',
    photo_url: '04_surprise_E52.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/04_surprise_E52.jpg'),
    emotion: 'surprise'
  },
  {
    id: 25,
    category: 'E5',
    photo_url: '05_anger_E5.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/05_anger_E5.jpg'),
    emotion: 'anger'
  },
  {
    id: 26,
    category: 'E22',
    photo_url: '05_disgust_E22.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/05_disgust_E22.jpg'),
    emotion: 'disgust'
  },
  {
    id: 27,
    category: 'E30',
    photo_url: '05_fear_E30.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/05_fear_E30.jpg'),
    emotion: 'fear'
  },
  {
    id: 28,
    category: 'E37',
    photo_url: '05_happiness_E37.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/05_happiness_E37.jpg'),
    emotion: 'happiness'
  },
  {
    id: 29,
    category: 'E45',
    photo_url: '05_sadness_E45.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/05_sadness_E45.jpg'),
    emotion: 'sadness'
  },
  {
    id: 30,
    category: 'E54',
    photo_url: '05_surprise_E54.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/05_surprise_E54.jpg'),
    emotion: 'surprise'
  },
  {
    id: 31,
    category: 'E7',
    photo_url: '06_anger_E7.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/06_anger_E7.jpg'),
    emotion: 'anger'
  },
  {
    id: 32,
    category: 'E23',
    photo_url: '06_disgust_E23.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/06_disgust_E23.jpg'),
    emotion: 'disgust'
  },
  {
    id: 33,
    category: 'E32',
    photo_url: '06_fear_E32.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/06_fear_E32.jpg'),
    emotion: 'fear'
  },
  {
    id: 34,
    category: 'E40',
    photo_url: '06_happiness_E40.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/06_happiness_E40.jpg'),
    emotion: 'happiness'
  },
  {
    id: 35,
    category: 'E48',
    photo_url: '06_sadness_E48.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/06_sadness_E48.jpg'),
    emotion: 'sadness'
  },
  {
    id: 36,
    category: 'E55',
    photo_url: '06_surprise_E55.jpg',
    imageSource: require('../../../../assets/challenges/emotions-game/game-assets/06_surprise_E55.jpg'),
    emotion: 'surprise'
  }
]

/**
 * Adjust challenge configuration here
 */
export const EMOTIONS_URL =
  'https://react-native-app-content.s3.eu-central-1.amazonaws.com/emotions/'
export const TIME_TO_OBSERVE = 200
export const TIME_TO_OBSERVE_IN_TEST_ROUNDS = [800, 500, 400, 300, 250, 200]
export const FOCUS_TIME = 1200
export const NOISE_TIME = 100
export const SECONDS_TO_ANSWER = 6
export const ANSWER_OPTIONS = [
  { label: 'challenge.emotions_game.anger', value: 'anger' },
  { label: 'challenge.emotions_game.fear', value: 'fear' },
  { label: 'challenge.emotions_game.disgust', value: 'disgust' },
  { label: 'challenge.emotions_game.happiness', value: 'happiness' },
  { label: 'challenge.emotions_game.sadness', value: 'sadness' },
  { label: 'challenge.emotions_game.surprise', value: 'surprise' }
]

export const EMOTIONS_IMAGES = [
  require('../../../../assets/challenges/emotions-game/game-assets/01_anger_E1.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/01_disgust_E17.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/01_fear_E25.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/01_happiness_E33.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/01_sadness_E41.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/01_surprise_E49.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/02_anger_E2.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/02_disgust_E18.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/02_fear_E26.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/02_happiness_E34.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/02_sadness_E42.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/02_surprise_E50.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/03_anger_E3.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/03_disgust_E19.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/03_fear_E27.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/03_happiness_E35.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/03_sadness_E43.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/03_surprise_E51.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/04_anger_E4.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/04_disgust_E20.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/04_fear_E28.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/04_happiness_E36.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/04_sadness_E44.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/04_surprise_E52.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/05_anger_E5.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/05_disgust_E22.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/05_fear_E30.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/05_happiness_E37.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/05_sadness_E45.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/05_surprise_E54.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/06_anger_E7.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/06_disgust_E23.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/06_fear_E32.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/06_happiness_E40.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/06_sadness_E48.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/06_surprise_E55.jpg'),
  require('../../../../assets/challenges/emotions-game/game-assets/focus-icon.png')
]

/**
 * Creates (returns) Random Questions for the main emotions round.
 */
export const getRandomMainQuestions = () => _.shuffle(EMOTION_QUESTIONS_ONE_ROUND)

// let anger = EMOTION_QUESTIONS.filter(({ emotion }) => emotion === "anger");
// let fear = EMOTION_QUESTIONS.filter(({ emotion }) => emotion === "fear");

// let disgust = EMOTION_QUESTIONS.filter(
//     ({ emotion }) => emotion === "disgust"
// );
// let happiness = EMOTION_QUESTIONS.filter(
//     ({ emotion }) => emotion === "happiness"
// );
// let sadness = EMOTION_QUESTIONS.filter(
//     ({ emotion }) => emotion === "sadness"
// );
// let surprise = EMOTION_QUESTIONS.filter(
//     ({ emotion }) => emotion === "surprise"
// );

// const mainQuestions = _.shuffle(
//     _.concat(
//         _.sampleSize(anger, 2),
//         _.sampleSize(fear, 2),
//         _.sampleSize(disgust, 2),
//         _.sampleSize(happiness, 2),
//         _.sampleSize(sadness, 2),
//         _.sampleSize(surprise, 2)
//     )
// );

// return mainQuestions;

/**
 * Creates (returns) random questions for the test emotions round.
 * @param {*} mainQuestions you have to pass the mainQuestions to avoid duplicate questions in round
 */
export const getRandomTestQuestions = (mainQuestions) => {
  const questions = _.shuffle(EMOTION_QUESTIONS).filter(
    (o) => !mainQuestions.map((u) => u.photo_url).includes(o.photo_url)
  )

  const anger_testQuestion = questions.filter(({ emotion }) => emotion === 'anger')
  const fear_testQuestion = questions.filter(({ emotion }) => emotion === 'fear')
  const disgust_testQuestion = questions.filter(({ emotion }) => emotion === 'disgust')
  const happiness_testQuestion = questions.filter(({ emotion }) => emotion === 'happiness')
  const sadness_testQuestion = questions.filter(({ emotion }) => emotion === 'sadness')
  const surprise_testQuestion = questions.filter(({ emotion }) => emotion === 'surprise')

  const testQuestions = _.shuffle([
    _.sample(anger_testQuestion),
    _.sample(fear_testQuestion),
    _.sample(disgust_testQuestion),
    _.sample(happiness_testQuestion),
    _.sample(sadness_testQuestion),
    _.sample(surprise_testQuestion)
  ])
  return testQuestions
}
