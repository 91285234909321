import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
  SaveStrengthIntro,
  SaveStrengthEmail,
  SaveStrengthCodeVerify,
  SaveStrengthSuccess
} from '../screens'
import HardFacts from '../screens/app/settings/account/HardFacts'

const SaveStrengthStack = createNativeStackNavigator()

const SaveStrengthStackScreen = () => (
  <SaveStrengthStack.Navigator screenOptions={{ headerShown: false }}>
    <SaveStrengthStack.Screen name='SaveStrengthIntro' component={SaveStrengthIntro} />
    <SaveStrengthStack.Screen name='SaveStrengthEmail' component={SaveStrengthEmail} />
    <SaveStrengthStack.Screen name='SaveStrengthCodeVerify' component={SaveStrengthCodeVerify} />
    <SaveStrengthStack.Screen name='SaveStrengthSuccess' component={SaveStrengthSuccess} />
    <SaveStrengthStack.Screen name='HardFacts' component={HardFacts} />
  </SaveStrengthStack.Navigator>
)

export default SaveStrengthStackScreen
