import RIASEC_IMAGES from './RiasecImagesResult'
import EMOTIONS_GAME from './EmotionsGameResult'
import HEROS_JOURNEY from './HerosJounreyResult'
import TOWER_OF_LONDON from './TowerOfLondonResult'
import PATH_FINDER from './PathFinderResult'
import BIG_FIVE from './BigFiveResult'
import ANALOGICAL_REASONING from './AnalogicalReasoningResult'
import DIGIT_SPAN from './DigitSpanResult'
import FLANKER from './FlankerResult'
import NBACK from './NbackResult'
import DELEGATION from './DelegationResult'
import NUMERACY from './NumeracyResult'

export const CHALLENGE_RESULT_COMPONENTS = {
  HEROS_JOURNEY,
  RIASEC_IMAGES,
  EMOTIONS_GAME,
  TOWER_OF_LONDON,
  PATH_FINDER,
  BIG_FIVE,
  ANALOGICAL_REASONING,
  DIGIT_SPAN,
  FLANKER,
  NBACK,
  DELEGATION,
  NUMERACY
}
