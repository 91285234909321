import React, { useContext, useEffect } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StackActions } from '@react-navigation/native'
import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import { Button, Typo } from '../../../components'
import metrics from '../../../theme/metrics'
import { AuthContext } from '../../../context'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  footer: {
    paddingHorizontal: 24
  }
})

const SaveStrengthSuccess = ({ navigation }) => {
  const insets = useSafeAreaInsets()
  const { rootStore } = useContext(AuthContext)

  useEffect(() => {
    if (rootStore.userStore.isHardFactsDone) {
      setTimeout(() => {
        navigation.dispatch(StackActions.popToTop())
        navigation.goBack()
      }, 2000)
    }
  }, [rootStore.userStore.isHardFactsDone, navigation])

  return (
    <View style={styles.container}>
      <View
        style={{ flex: 1, paddingHorizontal: 24, alignItems: 'center', justifyContent: 'center' }}
      >
        <Typo.H2Blue center marginBottom={4} translate>
          global.save_strength_success.title
        </Typo.H2Blue>
        {!rootStore.userStore.isHardFactsDone && (
          <Typo.T1LightBlack center translate>
            global.save_strength_success.hard_facts_information
          </Typo.T1LightBlack>
        )}
        <Image
          source={require('../../../assets/images/illustrations/strength-success.png')}
          style={[
            metrics.calculateResponsiveDimensions({ height: 180, width: 151 }),
            { marginTop: 64, marginBottom: 32 }
          ]}
        />
        <Typo.H3Blue translate>global.save_strength_success.success_message</Typo.H3Blue>
      </View>
      {!rootStore.userStore.isHardFactsDone && (
        <View style={[styles.footer, { paddingBottom: 24 + insets.bottom }]}>
          <Button
            title='global.save_strength_success.start_hard_facts_action'
            containerStyle={{ marginBottom: 16 }}
            onPress={() => navigation.navigate('HardFacts', { goToMain: true })}
          />
          <Button
            title='global.save_strength_success.do_it_later_action'
            type='outline'
            onPress={() => {
              navigation.dispatch(StackActions.popToTop())
              navigation.goBack()
            }}
          />
        </View>
      )}
    </View>
  )
}

SaveStrengthSuccess.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default observer(SaveStrengthSuccess)
