/* eslint-disable camelcase */
import React, { useContext } from 'react'
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import { Typo, ModalHeader } from '../../../../components'
import colors from '../../../../theme/colors'
import { AuthContext } from '../../../../context'
import { rowCenter, rowCenterBetween } from '../../../../styles'
import hardFacts from '../../../../constants/hard-facts'

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  headerContainer: {
    marginHorizontal: 16,
    marginTop: 20,
    alignItems: 'center',
    height: 44,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  backBtn: {
    position: 'absolute',
    left: 0,
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  whiteContainer: {
    width: '100%',
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 16,
    backgroundColor: '#fff'
  },
  listItemContainer: {
    ...rowCenterBetween,
    paddingHorizontal: 16,
    height: 64
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: colors.lightGrey
  }
})

const HardFactsOverview = observer(({ navigation }) => {
  const { rootStore } = useContext(AuthContext)
  const { location, reach_settings, work_experience, remote } = rootStore.userStore.userProfile

  const remoteState = hardFacts.remoteTypes.find(({ key }) => key === remote)
  const workExperience = hardFacts.workExperienceOverviewTypes.find(
    ({ key }) => key === work_experience
  )

  return (
    <View style={styles.container}>
      <ModalHeader
        onPress={() => navigation.goBack()}
        headerTitle='global.my_account.hard_facts'
        backgroundColor='#fff'
        withBackButton
      />
      <View style={{ width: '100%', height: 16, backgroundColor: '#fff' }} />
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingHorizontal: 16, paddingTop: 40, paddingBottom: 48 }}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8 }}>
          <Typo.T18Black translate style={{ marginLeft: 8 }}>
            hard_facts_overview.your_hard_facts
          </Typo.T18Black>
          <TouchableOpacity
            style={{ marginRight: 8 }}
            onPress={() => navigation.navigate('HardFacts')}
          >
            <Typo.T2Blue style={{ paddingTop: 2 }} translate>
              hard_facts_overview.edit_action
            </Typo.T2Blue>
          </TouchableOpacity>
        </View>

        <View style={[styles.whiteContainer, { marginBottom: 32 }]}>
          <TouchableOpacity disabled style={styles.listItemContainer}>
            <View style={rowCenter}>
              {/* <Image
                style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                source={require('../../../../assets/images/language-icon.png')}
              /> */}
              <View>
                <Typo.T1Black translate>hard_facts_overview.location_input_label</Typo.T1Black>
              </View>
            </View>
            <Typo.T1Black style={{ opacity: 0.6 }}>{location.city}</Typo.T1Black>
          </TouchableOpacity>
          <View style={styles.separator} />
          <TouchableOpacity disabled style={styles.listItemContainer}>
            <View style={rowCenter}>
              {/* <Image
                style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                source={require('../../../../assets/images/language-icon.png')}
              /> */}
              <View>
                <Typo.T1Black translate>hard_facts_overview.search_distance_label</Typo.T1Black>
              </View>
            </View>
            <Typo.T1Black style={{ opacity: 0.6 }}>
              {`${reach_settings.searching_distance} km`}
            </Typo.T1Black>
          </TouchableOpacity>
          <View style={styles.separator} />
          <TouchableOpacity disabled style={styles.listItemContainer}>
            <View style={rowCenter}>
              {/* <Image
                style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                source={require('../../../../assets/images/language-icon.png')}
              /> */}
              <View>
                <Typo.T1Black translate>hard_facts_overview.remote_label</Typo.T1Black>
              </View>
            </View>
            <Typo.T1Black style={{ opacity: 0.6 }} translate>
              {remoteState.value}
            </Typo.T1Black>
          </TouchableOpacity>
          <View style={styles.separator} />
          <TouchableOpacity disabled style={styles.listItemContainer}>
            <View style={rowCenter}>
              {/* <Image
                style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                source={require('../../../../assets/images/language-icon.png')}
              /> */}
              <View>
                <Typo.T1Black translate>hard_facts_overview.work_experience_label</Typo.T1Black>
              </View>
            </View>
            <Typo.T1Black style={{ opacity: 0.6 }} translate>
              {workExperience.value}
            </Typo.T1Black>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  )
})

HardFactsOverview.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default HardFactsOverview
