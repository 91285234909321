import React from 'react'
import { View, StyleSheet, StatusBar } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import PropTypes from 'prop-types'

import Typo from '../../components/Typo'
import Button from '../../components/Button'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(68, 97, 212, 0.95)'
  },
  footer: {
    paddingHorizontal: 24
  },
  btn: {
    borderWidth: 0,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0
  }
})

const BlueTitleDescriptionScreen = ({ route }) => {
  const { title, description, onPress } = route.params
  const insets = useSafeAreaInsets()
  return (
    <View
      style={[styles.container, { paddingBottom: 24 + insets.bottom, paddingTop: 24 + insets.top }]}
    >
      <StatusBar barStyle='light-content' animated />
      <View style={{ flex: 1 }}>
        <View style={{ paddingHorizontal: 20 }}>
          <Typo.H1White>{title}</Typo.H1White>

          <Typo.T1White marginTop={40} translate>
            {description}
          </Typo.T1White>
        </View>
      </View>
      <View style={styles.footer}>
        <Button onPress={onPress} title='global.got_it' type='white' containerStyle={styles.btn} />
      </View>
    </View>
  )
}

BlueTitleDescriptionScreen.propTypes = {
  route: PropTypes.object.isRequired
}

export default BlueTitleDescriptionScreen
