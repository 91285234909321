import React from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'

import ArrowDownIcon from '../assets/svgs/arrow-down-icon'
import Typo from './Typo'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: 44,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  backBtn: {
    position: 'absolute',
    left: 0,
    justifyContent: 'center',
    alignItems: 'flex-start'
  }
})

const ModalHeader = ({
  headerTitle,
  onPress,
  accentColor,
  withBackButton,
  backgroundColor,
  showBackNavigation
}) => (
  <View style={[{ paddingHorizontal: 16, paddingTop: 20 }, { backgroundColor }]}>
    <View style={[styles.container]}>
      {showBackNavigation && (
        <TouchableOpacity
          hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
          style={styles.backBtn}
          onPress={onPress}
        >
          {withBackButton ? (
            <Image
              source={require('../assets/images/arrow_back_black_nav.png')}
              style={{ height: 16, width: 16 }}
            />
          ) : (
            <ArrowDownIcon color={accentColor} />
          )}
        </TouchableOpacity>
      )}
      <Typo.H4Black style={{ color: accentColor }} translate>
        {headerTitle}
      </Typo.H4Black>
    </View>
  </View>
)

ModalHeader.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  accentColor: PropTypes.string,
  withBackButton: PropTypes.bool,
  showBackNavigation: PropTypes.bool,
  backgroundColor: PropTypes.string
}

ModalHeader.defaultProps = {
  accentColor: '#2B2B2B',
  withBackButton: false,
  backgroundColor: 'transparent',
  showBackNavigation: true
}

export default ModalHeader
