import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'

import colors from '../../theme/colors'
import Typo from '../Typo'
import DimensionData from '../../constants/DimensionData'
import Skill from './Skill'
import { AuthContext } from '../../context'
import StrengthEmptySection from '../StrengthEmptySection'

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingBottom: 22,
    elevation: 8,
    shadowColor: '#B6C7F2',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5
  },
  ratingPercentContainer: {
    height: 28,
    paddingHorizontal: 8,
    backgroundColor: colors.primaryGreen,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const Skills = () => {
  const DimensionsSkills = Object.values(DimensionData).filter((d) => d.dimension === 'Fähigkeiten')
  const { rootStore } = useContext(AuthContext)

  function checkIfSkillsEmpty() {
    let count = 0
    DimensionsSkills.forEach((skill) => {
      const { challengeID } = skill.scoreData[0]

      const answer = rootStore.answersStore.getAnswerForChallenge(challengeID)
      if (answer) {
        count++
      }
    })
    return count
  }

  if (checkIfSkillsEmpty() === 0) {
    return <StrengthEmptySection />
  }

  return (
    <View style={styles.container}>
      <Typo.T1Black translate marginTop={24} marginBottom={32}>
        strength.ability.desc
      </Typo.T1Black>
      {/* <Image
        style={{
          ...metrics.calculateResponsiveDimensions({ width: 311, height: 102 }),
          alignSelf: 'center',
          marginTop: 50,
          marginBottom: 32
        }}
        source={require('../../assets/images/normaldistribution.png')}
      /> */}
      {DimensionsSkills.map((skill) => (
        <Skill key={skill.property} skill={skill} />
      ))}
    </View>
  )
}

export default observer(Skills)
