import React, { useContext, useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  ImageBackground,
  Image,
  Platform,
  Alert
} from 'react-native'
import PropTypes from 'prop-types'
import * as Updates from 'expo-updates'
import { observer } from 'mobx-react'
import Constants from 'expo-constants'
import initials from 'initials'
import * as Linking from 'expo-linking'
import { Auth, I18n } from 'aws-amplify'
import { Typo, ModalHeader, CreateAccountPrompt } from '../../../components'
import colors from '../../../theme/colors'
import { AuthContext } from '../../../context'
import { rowCenter, rowCenterBetween } from '../../../styles'
import { notifyBugsnag } from '../../../utils/bugsnag'
import { replaceTranslationKey } from '../../../utils/helpers'
import asyncStorage from '../../../utils/async-storage'
import { getLanguages } from '../../../utils/languages'
import AivyLogoFullscreen from '../../../components/AivyLogoFullscreen'

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  headerContainer: {
    marginHorizontal: 16,
    marginTop: 20,
    alignItems: 'center',
    height: 44,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  backBtn: {
    position: 'absolute',
    left: 0,
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  whiteContainer: {
    width: '100%',
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 16,
    backgroundColor: '#fff'
  },
  listItemContainer: {
    ...rowCenterBetween,
    paddingHorizontal: 16,
    height: 80
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: colors.lightGrey
  }
})

const MyAccount = observer(({ navigation }) => {
  const { rootStore } = useContext(AuthContext)
  const { isAnonym, isHardFactsDone } = rootStore.userStore

  const [language, setLanguage] = useState(null)
  const [logout, setLogout] = useState(false)

  useEffect(() => {
    const getLanguage = async () => {
      setLanguage(getLanguages()[(await asyncStorage.getData('lang')) || 'de'])
    }

    getLanguage()
  }, [])

  const signOutAndReload = async () => {
    setLogout(true)
    try {
      // eslint-disable-next-line no-unused-expressions
      isAnonym ? await Auth.deleteUser() : await Auth.signOut()
      if (Platform.OS === 'web') {
        // eslint-disable-next-line no-unused-expressions
        window && window.location.reload()
      } else {
        await Updates.reloadAsync()
      }
    } catch (err) {
      notifyBugsnag(err)
    }
  }

  const handleLogout = async () => {
    if (isAnonym) {
      navigation.navigate('Alert', {
        title: I18n.get('myAccount.logout.alert.title'),
        message: I18n.get('myAccount.logout.alert.desc'),
        buttons: [
          {
            text: I18n.get('myAccount.logout.alert.cancel'),
            onPress: () => {},
            style: 'cancel'
          },
          {
            text: I18n.get('myAccount.logout.alert.deleteAccount'),
            style: 'destructive',
            onPress: signOutAndReload
          }
        ]
      })
    } else {
      signOutAndReload()
    }
  }

  if (logout) return <AivyLogoFullscreen />

  return (
    <View style={styles.container}>
      <ModalHeader
        onPress={() => navigation.goBack()}
        headerTitle='global.my_account.modal_header_title'
        backgroundColor='#fff'
      />
      {isAnonym ? (
        <View style={{ paddingHorizontal: 16, backgroundColor: '#fff', paddingVertical: 24 }}>
          <CreateAccountPrompt
            onPress={() => {
              navigation.goBack()
              setTimeout(() => {
                navigation.navigate('SaveStrengthDNA')
              }, 200)
            }}
          />
        </View>
      ) : (
        <View
          style={[
            rowCenter,
            { paddingHorizontal: 16, backgroundColor: '#fff', paddingVertical: 24 }
          ]}
        >
          <ImageBackground
            style={{
              height: 56,
              width: 56,
              marginRight: 16,
              alignItems: 'center',
              justifyContent: 'center'
            }}
            source={require('../../../assets/images/profile-placeholders/profile-1.png')}
            imageStyle={{ height: 56, width: 56 }}
          >
            <Typo.H4White>
              {initials(rootStore.userStore.cognitoUser.attributes.email)}
            </Typo.H4White>
          </ImageBackground>
          {/* <Image
          style={{ height: 56, width: 56, marginRight: 16 }}
         
        /> */}
          <Typo.T1Black>{rootStore.userStore.cognitoUser.attributes.email}</Typo.T1Black>
        </View>
      )}
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingHorizontal: 16, paddingTop: 30, paddingBottom: 48 }}
      >
        <Typo.T18Black translate marginBottom={8} style={{ marginLeft: 4 }}>
          global.my_account.manage
        </Typo.T18Black>
        <View style={[styles.whiteContainer, { marginBottom: 32 }]}>
          <TouchableOpacity
            onPress={() => navigation.navigate('Languages')}
            style={styles.listItemContainer}
          >
            <View style={rowCenter}>
              <Image
                style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                source={require('../../../assets/images/language-icon.png')}
              />
              <View>
                <Typo.T1Black translate>global.language</Typo.T1Black>
              </View>
            </View>
            <View style={rowCenter}>
              {language && <Typo.T1Black>{language}</Typo.T1Black>}
              <Image
                style={{ height: 12, width: 8, marginLeft: 12 }}
                source={require('../../../assets/images/arrow-right-grey.png')}
              />
            </View>
          </TouchableOpacity>
          {Platform.OS !== 'web' && <View style={styles.separator} />}
          {(Platform.OS !== 'web' || (Platform.OS === 'web' && !isAnonym)) && (
            <TouchableOpacity
              style={styles.listItemContainer}
              onPress={() => navigation.navigate('Notifications')}
            >
              <View style={rowCenter}>
                <Image
                  style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                  source={require('../../../assets/images/notification-icon.png')}
                />
                <View>
                  <Typo.T1Black translate>global.notifications</Typo.T1Black>
                </View>
              </View>
              <Image
                style={{ height: 12, width: 8 }}
                source={require('../../../assets/images/arrow-right-grey.png')}
              />
            </TouchableOpacity>
          )}
          <View style={styles.separator} />
          <TouchableOpacity
            disabled={isAnonym}
            style={[styles.listItemContainer]}
            onPress={
              isHardFactsDone
                ? () => navigation.navigate('HardFactsOverview')
                : () => navigation.navigate('HardFacts')
            }
          >
            <View style={[rowCenter, { opacity: isAnonym ? 0.3 : 1 }]}>
              <Image
                style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                source={require('../../../assets/images/hardfacts-icon.png')}
              />
              <View>
                <Typo.T1Black translate>global.my_account.hard_facts</Typo.T1Black>
                {isHardFactsDone && (
                  <Typo.T2Black translate style={{ opacity: 0.6 }}>
                    global.my_account.hard_facts_done
                  </Typo.T2Black>
                )}
              </View>
            </View>
            <Image
              style={[{ height: 12, width: 8, opacity: isAnonym ? 0.3 : 1 }]}
              source={require('../../../assets/images/arrow-right-grey.png')}
            />
          </TouchableOpacity>
        </View>
        <Typo.T18Black translate marginBottom={8} style={{ marginLeft: 4 }}>
          global.my_account.others
        </Typo.T18Black>
        <View style={styles.whiteContainer}>
          <TouchableOpacity
            style={styles.listItemContainer}
            onPress={() => {
              try {
                Linking.openURL('mailto:hi@aivy.app')
              } catch (err) {
                Alert.alert('hi@aivy.app')
              }
            }}
          >
            <View style={rowCenter}>
              <Image
                style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                source={require('../../../assets/images/support-icon.png')}
              />
              <View>
                <Typo.T1Black translate>global.my_account.support</Typo.T1Black>
                <Typo.T2Black translate style={{ opacity: 0.6 }}>
                  global.my_account.feedback
                </Typo.T2Black>
              </View>
            </View>
          </TouchableOpacity>
          <View style={styles.separator} />
          <TouchableOpacity
            onPress={() => navigation.navigate('Legals')}
            style={styles.listItemContainer}
          >
            <View style={rowCenter}>
              <Image
                style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                source={require('../../../assets/images/legals-icon.png')}
              />
              <View>
                <Typo.T1Black translate>global.my_account.legals</Typo.T1Black>
                <Typo.T2Black translate style={{ opacity: 0.6 }}>
                  global.my_account.imprint
                </Typo.T2Black>
              </View>
            </View>
          </TouchableOpacity>
          {!isAnonym && (
            <>
              <View style={styles.separator} />
              <TouchableOpacity
                style={[styles.listItemContainer]}
                onPress={() => navigation.navigate('DeleteAccount')}
              >
                <View style={[rowCenter]}>
                  <Image
                    style={{ height: 32, width: 32, resizeMode: 'contain', marginRight: 12 }}
                    source={require('../../../assets/images/delete-account-icon.png')}
                  />
                  <View>
                    <Typo.T1Black translate>global.my_account.delete_account</Typo.T1Black>
                  </View>
                </View>
                <Image
                  style={[{ height: 12, width: 8 }]}
                  source={require('../../../assets/images/arrow-right-grey.png')}
                />
              </TouchableOpacity>
            </>
          )}
          <View style={{ width: '100%', height: 1, backgroundColor: colors.lightGrey }} />
          <TouchableOpacity onPress={handleLogout} style={styles.listItemContainer}>
            <View style={rowCenter}>
              <Image
                style={{ marginRight: 12, height: 32, width: 32, resizeMode: 'contain' }}
                source={require('../../../assets/images/logout-icon.png')}
              />
              <Typo.T1Blue translate>global.my_account.log_out</Typo.T1Blue>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ paddingVertical: 24 }}>
          <Typo.SmallTextBlack
            center
          >{`Version ${Constants.expoConfig.version} | ${Constants.expoConfig.extra?.versionName}`}</Typo.SmallTextBlack>
          <Typo.T2LightBlack center selectable>
            {replaceTranslationKey(
              I18n.get('global.my_account.identifier'),
              '{{identifier}}',
              rootStore.userStore.username
            )}
          </Typo.T2LightBlack>
        </View>
      </ScrollView>
    </View>
  )
})

MyAccount.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default MyAccount
