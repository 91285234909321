import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'

import Footer from '../Footer'
import CloseButton from '../CloseButton'
import colors from '../../theme/colors'

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    height: 44,
    zIndex: 10
  },
  questionWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  footer: {
    backgroundColor: '#f5f5f5',
    paddingVertical: 20,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center'
  }
})

const Questionnaire = forwardRef(
  (
    {
      startingIndex,
      numberOfTestRounds,
      onPrevOrNext,
      onPrev,
      onNext,
      setCountdownVisible,
      questions,
      onSubmit,
      title,
      showBackButton,
      renderQuestion,
      primaryButtonProps,
      finalPrimaryButtonTitle,
      submitButtonColored,
      showFooter,
      hideProgress,
      hideCloseButton,
      startingTitle,
      footerColor,
      delayTransition
    },
    ref
  ) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(startingIndex)
    const [currentScreenIndex, setCurrentScreenIndex] = useState(startingIndex)
    const [items, setItems] = useState([])
    const [lastQuestionReached, setLastQuestionReached] = useState(false)

    function nextPressed() {
      if (setCountdownVisible) {
        setCountdownVisible(true)
      }
      let nextQuestionIndex = currentScreenIndex + 1
      if (numberOfTestRounds && currentScreenIndex >= numberOfTestRounds) {
        nextQuestionIndex--
      }
      onPrevOrNext && onPrevOrNext(nextQuestionIndex)
      onNext && onNext()
      setCurrentQuestionIndex(nextQuestionIndex)
      setCurrentScreenIndex(currentScreenIndex + 1)
    }

    useImperativeHandle(ref, () => ({
      pressFooterPrimaryButton() {
        setTimeout(() => {
          lastQuestionReached ? onSubmit() : nextPressed()
        }, delayTransition)
      }
    }))

    useEffect(() => {
      if (numberOfTestRounds) {
        const q = questions.splice(numberOfTestRounds, 0, {
          testRoundCompletedScreen: true
        })
        const questionsOnScreen = q.map((question, idx) => ({
          ...question,
          screenIndex: idx
        }))

        setItems(questionsOnScreen)
      } else {
        const questionsOnScreen = questions.map((question, idx) => ({
          ...question,
          screenIndex: idx
        }))
        setItems(questionsOnScreen)
      }
    }, [questions, currentScreenIndex, numberOfTestRounds])

    useEffect(() => {
      if (questions.length === currentScreenIndex + 1) {
        setLastQuestionReached(true)
      }
    }, [currentScreenIndex, questions.length])

    function prevPressed() {
      let prevQuestionIndex = currentScreenIndex - 1
      if (numberOfTestRounds && currentScreenIndex === numberOfTestRounds - 1) {
        prevQuestionIndex++
      }
      onPrevOrNext && onPrevOrNext(prevQuestionIndex)
      onPrev && onPrev()
      setCurrentQuestionIndex(prevQuestionIndex)
      setCurrentScreenIndex(currentScreenIndex - 1)
    }

    function pressFooterPrimaryButton() {
      lastQuestionReached ? onSubmit() : nextPressed()
    }

    const question = items[currentScreenIndex]

    if (!question) return null

    return (
      <View style={styles.container}>
        {hideProgress ? null : (
          <View style={styles.header}>
            {/* <ProgressBar anim={progressAnim} length={questions.length} /> */}
            {!hideCloseButton && (
              <CloseButton
                style={{ position: 'relative', left: 0, marginLeft: 8 }}
                color={colors.darkBlack}
              />
            )}
          </View>
        )}
        <View style={styles.container}>
          <View key={question.screenIndex} style={[styles.questionWrapper]}>
            {renderQuestion(question)}
          </View>
        </View>
        {showFooter && (
          <Footer
            footerColor={footerColor}
            title={
              (lastQuestionReached && finalPrimaryButtonTitle.toUpperCase()) ||
              (currentScreenIndex === 0 && startingTitle) ||
              title.toUpperCase()
            }
            submitButtonColored={submitButtonColored}
            showBackButton={currentQuestionIndex === 0 ? false : showBackButton}
            primaryButtonProps={{
              ...primaryButtonProps,
              submitReady: lastQuestionReached,
              onPress: () => pressFooterPrimaryButton()
            }}
            prevButtonProps={{
              disabled: currentScreenIndex === 0,
              onPress: () => prevPressed()
            }}
          />
        )}
      </View>
    )
  }
)

Questionnaire.propTypes = {
  startingIndex: PropTypes.number,
  numberOfTestRounds: PropTypes.number,
  onPrevOrNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  setCountdownVisible: PropTypes.func,
  questions: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  showBackButton: PropTypes.bool,
  renderQuestion: PropTypes.func.isRequired,
  primaryButtonProps: PropTypes.object,
  finalPrimaryButtonTitle: PropTypes.string,
  submitButtonColored: PropTypes.bool,
  showFooter: PropTypes.bool.isRequired,
  hideProgress: PropTypes.bool.isRequired,
  hideCloseButton: PropTypes.bool,
  startingTitle: PropTypes.string,
  footerColor: PropTypes.string,
  delayTransition: PropTypes.number
}

Questionnaire.defaultProps = {
  startingIndex: 0,
  numberOfTestRounds: undefined,
  onPrev: undefined,
  onNext: undefined,
  setCountdownVisible: () => {},
  title: 'Weiter'.toUpperCase(),
  showBackButton: undefined,
  primaryButtonProps: {},
  finalPrimaryButtonTitle: 'Abschließen'.toUpperCase(),
  submitButtonColored: true,
  hideCloseButton: undefined,
  startingTitle: undefined,
  footerColor: undefined,
  delayTransition: 0
}

export default Questionnaire
