export const areaChartData = [
  31575, 37522, 43923, 52400, 61273, 72048, 84836, 98034, 113597, 131146, 151174, 173567, 198038,
  226276, 257193, 289457, 327140, 368180, 412073, 457754, 510704, 566270, 622947, 686160, 754014,
  821559, 894344, 969321, 1048864, 1128351, 1210787, 1296840, 1379637, 1464553, 1550450, 1635966,
  1720188, 1799029, 1874107, 1952649, 2017594, 2087541, 2148984, 2199198, 2246765, 2286828, 2319176,
  2347025, 2358856, 2370113, 2368518, 2361824, 2344803, 2320617, 2285394, 2245297, 2198522, 2146465,
  2084443, 2021336, 1950174, 1875552, 1801534, 1716185, 1633350, 1552709, 1465220, 1379724, 1296176,
  1210476, 1127244, 1048588, 970235, 893876, 822446, 753448, 686868, 623003, 566244, 510916, 458912,
  411797, 367470, 326725, 290890, 256396, 225856, 198101, 173208, 151654, 131148, 113780, 97635,
  84411, 71502, 61268, 52236, 44158, 37298, 31732
]
