import React, { useContext, useState } from 'react'
import { View, StyleSheet, Pressable, Image, Text, Platform, LayoutAnimation } from 'react-native'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import * as Updates from 'expo-updates'

import { ProfileButton } from '.'
import { AuthContext } from '../context'
import { mutation, query } from '../graphql'
import ArrowDownIcon from '../assets/svgs/arrow-down-icon'
import ArrowUpIcon from '../assets/svgs/arrow-up-icon'
import { useMount } from '../hooks/use-mount'
import { useSetState } from '../hooks/use-set-state'
import colors from '../theme/colors'
import CheckCircleIcon from './heroicons/solid/CheckCircleIcon'
import metrics from '../theme/metrics'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  spacesContainer: {
    backgroundColor: colors.lightGrey,
    borderRadius: 24
  },
  itemStyle: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  imageContainer: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    height: 24,
    width: 24,
    resizeMode: Platform.OS === 'android' ? undefined : 'contain'
  },
  iconContainer: {
    marginLeft: 'auto',
    marginRight: 8,
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    // marginTop: 1
  }
})

const SpacesProfileHeader = observer(({ navigation }) => {
  const { rootStore } = useContext(AuthContext)

  const {
    appSettingsStore: { partnerDisplayName, logo, careerName }
  } = rootStore

  const { active_space } = rootStore.spaceStore
  const active_space_id = active_space?.id

  const [state, setState] = useSetState({ spaces: [], careers: {}, partners: {}, isLoading: true })
  const [open, setOpen] = useState(false)

  useMount(() => {
    const doAsync = async () => {
      const spaces = await query({
        query: 'spacesByOwner',
        variables: { owner: rootStore.userStore.username }
      })

      setState({ spaces: spaces.data.spacesByOwner.items })

      const careers = await Promise.all(
        spaces.data.spacesByOwner.items
          .filter(({ career_id }) => career_id)
          .map(({ career_id }) => query({ query: 'getCareer', variables: { id: career_id } }))
      )

      setState({
        careers: careers
          .map(({ data }) => data.getCareer)
          .reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {})
      })

      const partners = await Promise.all(
        spaces.data.spacesByOwner.items.map(({ partner_id }) =>
          query({ query: 'getPartner', variables: { id: partner_id } })
        )
      )

      setState({
        isLoading: false,
        partners: partners
          .map(({ data }) => data.getPartner)
          .reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {})
      })
    }

    doAsync()
  })

  async function goToSpace({ space_id }) {
    await mutation('updateUser', {
      id: rootStore.userStore.username,
      active_space_id: space_id
    })

    if (Platform.OS === 'web') {
      return window.location.reload()
    }

    await Updates.reloadAsync()
    return null
  }

  const { spaces, careers, partners, isLoading } = state

  const spaceSwitchAvalaible = spaces.length > 1

  return (
    <View style={[styles.container, open ? { flexDirection: 'column' } : {}]}>
      <View style={[styles.spacesContainer, open && { width: '100%' }]}>
        <Pressable
          onPress={() => {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
            setOpen(!open)
          }}
          disabled={!spaceSwitchAvalaible}
          style={styles.itemStyle}
        >
          <View style={styles.imageContainer}>
            <Image style={styles.image} source={{ uri: logo }} />
          </View>
          <View
            style={{
              marginLeft: 8,
              marginRight: 16,
              maxWidth: open ? metrics.widthPercentageToDP('60') : metrics.widthPercentageToDP('50')
            }}
          >
            <Text numberOfLines={1} style={{ fontFamily: 'fira-sans-medium', fontSize: 14 }}>
              {partnerDisplayName}
            </Text>
            {careerName !== '' && (
              <Text numberOfLines={1} style={{ fontFamily: 'fira-sans-regular', fontSize: 12 }}>
                {careerName}
              </Text>
            )}
          </View>
          {spaceSwitchAvalaible && (
            <View style={styles.iconContainer}>
              {open ? (
                <ArrowUpIcon containerStyle={{ marginBottom: -1 }} />
              ) : (
                <ArrowDownIcon containerStyle={{ marginTop: 1 }} />
              )}
            </View>
          )}
        </Pressable>
      </View>
      {open && !isLoading && (
        <View style={{ width: '100%', marginTop: 8 }}>
          {spaces.map(({ id: space_id, career_id, partner_id }, index) => {
            const { logo: partner_logo, display_name: partner_display_name } = partners[partner_id]
            const { logo: career_logo, title: career_title } = careers[career_id] || {}
            const imageUri = career_logo || partner_logo

            const active = space_id === active_space_id

            return (
              <Pressable
                key={index}
                style={[
                  styles.itemStyle,
                  { marginBottom: 4, backgroundColor: colors.lightGrey },
                  { paddingHorizontal: 12, paddingVertical: 8 }
                ]}
                onPress={active ? undefined : () => goToSpace({ space_id })}
              >
                <View style={[styles.imageContainer, { width: 40, height: 40, borderRadius: 20 }]}>
                  <Image style={styles.image} source={{ uri: imageUri }} />
                </View>

                <View style={{ marginLeft: 8, marginRight: 16 }}>
                  <Text numberOfLines={1} style={{ fontFamily: 'fira-sans-medium', fontSize: 14 }}>
                    {partner_display_name}
                  </Text>
                  {career_title !== '' && career_title !== undefined && (
                    <Text
                      numberOfLines={1}
                      style={{ fontFamily: 'fira-sans-regular', fontSize: 12 }}
                    >
                      {career_title}
                    </Text>
                  )}
                </View>

                {active ? (
                  <View
                    style={{
                      marginLeft: 'auto',
                      marginRight: 8,
                      backgroundColor: 'white',
                      width: 24,
                      height: 24,
                      borderRadius: 12
                    }}
                  >
                    <CheckCircleIcon color={colors.primaryBlue} />
                  </View>
                ) : null}
              </Pressable>
            )
          })}
        </View>
      )}
      {!open && <ProfileButton onPress={() => navigation.navigate('Settings')} />}
    </View>
  )
})

SpacesProfileHeader.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default SpacesProfileHeader
