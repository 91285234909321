import React, { useState } from 'react'
import { View, Image, StyleSheet } from 'react-native'
import * as d3 from 'd3'
import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'

const thumbWidth = 26

const s = StyleSheet.create({
  container: {
    height: 40,
    width: '100%',
    justifyContent: 'center'
  },
  line: {
    height: 2,
    backgroundColor: colors.primaryGreen,
    width: '100%'
  }
})

const TradeOffBalken = ({ containerStyle, value }) => {
  const [width, setWidth] = useState(0)
  const scale = d3.scaleLinear().domain([-100, 100]).range([0, width])

  const onLayout = (event) => {
    setWidth(event.nativeEvent.layout.width)
  }

  return (
    <View style={[s.container, containerStyle]}>
      <Image
        style={{ height: 40, width: 2, position: 'absolute', alignSelf: 'center' }}
        source={require('../../../../assets/images/line.png')}
      />
      <View style={s.line} onLayout={onLayout} />
      <Image
        style={{
          height: 30,
          width: thumbWidth,
          position: 'absolute',
          transform: [{ translateX: scale(value) - thumbWidth / 2 }, { translateY: 2 }]
        }}
        source={require('../../../../assets/images/tradeofficon.png')}
      />
    </View>
  )
}

TradeOffBalken.propTypes = {
  containerStyle: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired
}

export default TradeOffBalken
