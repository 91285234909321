import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { notifyBugsnagUniversal, onErrorUniversal } from './bugsnag-universal'
import { getSubDomain } from './helpers'

export const notifyBugsnag = (error) => {
  notifyBugsnagUniversal(Bugsnag, error)
}

export const options = {
  apiKey: '715cc5c59d50d844ef08334bbf19d77f',
  plugins: [new BugsnagPluginReact()],
  onError: onErrorUniversal,
  releaseStage: getSubDomain()
}
