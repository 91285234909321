import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import PropTypes from 'prop-types'

import { SYMBOL_HEIGHT, SYMBOL_WIDTH } from '../data'

const s = StyleSheet.create({
  symbolContainer: {
    width: SYMBOL_WIDTH,
    height: SYMBOL_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center'
  },
  symbol: {
    height: SYMBOL_HEIGHT,
    width: 95.6
  }
})

const Symbol = ({ symbolSource }) => (
  <View style={[s.symbolContainer]}>
    <Image style={[s.symbol]} source={symbolSource} resizeMode='contain' />
  </View>
)

Symbol.propTypes = {
  symbolSource: PropTypes.object.isRequired
}

export default Symbol
