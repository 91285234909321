import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import { View, StyleSheet, Animated, Platform, Image, Easing } from 'react-native'
import PropTypes from 'prop-types'

// import { Typo } from '../../../../components'
import Typo from '../../../../components/Typo'
import Symbol from './Symbol'
import {
  SYMBOLS,
  SYMBOL_HEIGHT,
  SYMBOL_WIDTH,
  DURATION_PER_SYMBOL,
  START_ANIM_AFTER_MS
} from '../data'

const s = StyleSheet.create({
  container: {
    overflow: 'hidden',
    position: 'relative',
    top: 33,
    left: 1,
    width: SYMBOL_WIDTH,
    height: SYMBOL_HEIGHT
  },
  currencyIconStyle: {
    height: 20,
    width: 20,
    marginRight: 4
  },
  reel: {
    width: SYMBOL_WIDTH
  }
})

const Reel = forwardRef(({ question, onAnimationEnd, balance }, ref) => {
  const t0 = useRef(null)

  const [isGameOver, setIsGameOver] = useState(false)
  const [currentAnimatedValue, setCurrentAnimatedValue] = useState(0)
  const currentScrollPos = useRef(new Animated.Value(0)).current

  /**
   * Function will be called from Parent Component
   */
  useImperativeHandle(ref, () => ({
    getCurrentScrollPos() {
      return { currentAnimatedValue, timings: { t0: t0.current, t1: new Date() } }
    },
    getCurrentSymbol() {
      const posAbs = Math.abs(Math.round(currentAnimatedValue) + SYMBOL_HEIGHT / 2)
      const passedItems = Math.floor(Math.abs(posAbs / SYMBOL_HEIGHT))
      const currentItem = passedItems + 1
      return currentItem
    },
    getDuration() {
      const posAbs = Math.abs(Math.round(currentAnimatedValue) + SYMBOL_HEIGHT / 2)
      const pixels = posAbs % SYMBOL_HEIGHT
      const duration = (DURATION_PER_SYMBOL / SYMBOL_HEIGHT) * pixels
      return duration
    }
  }))

  const startReelAnimation = () => {
    t0.current = new Date()
    Animated.timing(currentScrollPos, {
      toValue: question.length * SYMBOL_HEIGHT * -1 + SYMBOL_HEIGHT,
      duration: DURATION_PER_SYMBOL * question.length,
      useNativeDriver: Platform.OS !== 'web',
      easing: Easing.linear
    }).start(({ finished }) => {
      if (finished) {
        setTimeout(() => {
          setIsGameOver(true)
          setTimeout(() => {
            onAnimationEnd()
          }, 1500)
        }, DURATION_PER_SYMBOL / 2)
      }
    })
  }

  useEffect(() => {
    setTimeout(() => {
      startReelAnimation()
    }, START_ANIM_AFTER_MS)
    currentScrollPos.addListener(({ value }) => {
      setCurrentAnimatedValue(value)
    })
  }, []) // eslint-disable-line

  if (isGameOver) {
    return (
      <View style={[s.container, { alignItems: 'center', justifyContent: 'center' }]}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image
            style={{ height: 24, width: 24, marginRight: 8 }}
            source={require('../../../../assets/challenges/nback/game-assets/currency.png')}
          />
          <Typo.NumberBlue>{balance}</Typo.NumberBlue>
        </View>
        <Typo.T2Blue center>Münzen gesammelt</Typo.T2Blue>
      </View>
    )
  }

  return (
    <View style={s.container}>
      <Animated.View
        style={[
          s.reel,
          {
            transform: [{ translateY: currentScrollPos }],
            height: SYMBOL_HEIGHT * question.length
          }
        ]}
      >
        {question.map((item, idx) => (
          <Symbol
            key={`symbol-${idx}`}
            symbolSource={SYMBOLS.find((x) => x.id === item.id).source}
          />
        ))}
      </Animated.View>
    </View>
  )
})

Reel.propTypes = {
  question: PropTypes.array.isRequired,
  onAnimationEnd: PropTypes.func.isRequired,
  balance: PropTypes.number.isRequired
}

export default Reel
