import React from 'react'
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'
import metrics from '../../../../theme/metrics'

const s = StyleSheet.create({
  container: {
    width: metrics.widthPercentageToDP(72),
    height: metrics.widthPercentageToDP(47),
    maxWidth: 273,
    maxHeight: 176,
    backgroundColor: '#fff',
    borderRadius: 20,
    shadowColor: '#252D42',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.06,
    shadowRadius: 44,
    elevation: 6,
    alignItems: 'center',
    justifyContent: 'center'
  },
  stopButton: {
    maxHeight: 128,
    maxWidth: 225,
    height: metrics.widthPercentageToDP(34),
    width: metrics.widthPercentageToDP(60)
  },
  stopButtonShadow: {
    shadowColor: '#92B0FF',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.49,
    shadowRadius: 11
  },
  dot: {
    height: 6,
    width: 6,
    borderRadius: 3,
    backgroundColor: colors.softGrey,
    position: 'absolute'
  }
})

const StopButton = ({ onPress, disabled }) => (
  <View style={[s.container, { opacity: disabled ? 0.5 : 1 }]}>
    <View style={[s.dot, { top: 12, left: 12 }]} />
    <View style={[s.dot, { bottom: 12, left: 12 }]} />
    <View style={[s.dot, { top: 12, right: 12 }]} />
    <View style={[s.dot, { bottom: 12, right: 12 }]} />
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <View style={s.stopButtonShadow}>
        <Image
          style={s.stopButton}
          source={require('../../../../assets/challenges/nback/game-assets/stop-button.png')}
        />
      </View>
    </TouchableOpacity>
  </View>
)

StopButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default StopButton
