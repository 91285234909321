import AsyncStorage from '@react-native-async-storage/async-storage'
import { notifyBugsnag } from './bugsnag'

const notify = (err) => {
  if (err.name === 'QuotaExceededError') {
    return
  }

  notifyBugsnag(err)
}

const storeData = async (key, value) => {
  if (value === null || value === undefined) return

  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem(key, jsonValue)
  } catch (err) {
    notify(err)
  }
}

const removeData = async (key) => {
  try {
    await AsyncStorage.removeItem(key)
  } catch (err) {
    notify(err)
  }
}

const getData = async (key) => {
  try {
    let jsonValue = await AsyncStorage.getItem(key)

    if (jsonValue === 'de' || jsonValue === 'en') {
      await storeData(key, jsonValue)
      jsonValue = await AsyncStorage.getItem(key)
    }

    return jsonValue != null ? JSON.parse(jsonValue) : null
  } catch (err) {
    notify(err)
  }
}

const asyncStorage = {
  storeData,
  getData,
  removeData
}

export default asyncStorage
