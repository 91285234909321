import React from 'react'
import { Image, Platform, TouchableOpacity } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import PropTypes from 'prop-types'

import { I18n } from 'aws-amplify'
import { MarkdownScreen, SignIn, VerifyCode, Welcome } from '../screens'
import colors from '../theme/colors'
import { Alert } from '../components'

export const headerLeft = (navigation) => (
  <TouchableOpacity
    onPress={() => navigation.goBack()}
    style={{ marginLeft: Platform.OS === 'web' ? 24 : 0 }}
    hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
  >
    <Image
      style={{ height: 16, width: 16 }}
      source={require('../assets/images/arrow_back_black_nav.png')}
    />
  </TouchableOpacity>
)

const AuthStack = createNativeStackNavigator()

const AuthStackScreen = ({ navigation }) => (
  <AuthStack.Navigator screenOptions={{ headerShown: false }}>
    <AuthStack.Screen name='Welcome' component={Welcome} />
    <AuthStack.Screen
      name='SignIn'
      component={SignIn}
      options={{
        title: I18n.get('global.signin'),
        headerShown: true,
        headerTitleStyle: {
          fontFamily: 'fira-sans-semi-bold',
          fontSize: 16,
          color: colors.darkBlack
        },
        headerShadowVisible: false,
        headerBackTitleVisible: false,
        headerLeft: () => headerLeft(navigation)
      }}
    />
    <AuthStack.Group
      screenOptions={{
        presentation: 'modal',
        animation: 'slide_from_bottom'
      }}
    >
      <AuthStack.Screen name='VerifyCode' component={VerifyCode} />
      <AuthStack.Screen name='MarkdownScreen' component={MarkdownScreen} />
      <AuthStack.Screen
        name='Alert'
        component={Alert}
        options={{
          presentation: 'transparentModal',
          animation: 'fade'
        }}
      />
    </AuthStack.Group>
  </AuthStack.Navigator>
)

AuthStackScreen.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default AuthStackScreen
