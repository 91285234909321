import React, { useState, useRef, useContext, useEffect } from 'react'
import { View, StyleSheet, SafeAreaView } from 'react-native'
import { I18n } from 'aws-amplify'
import Svg, { Path } from 'react-native-svg'
import PropTypes from 'prop-types'

import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import Instruction from '../../../components/challenge/Instruction'
import Intro from '../../../components/challenge/Intro'
import CountDownTimer from '../../../components/challenge/CountDownTimer'
import Questionnaire from '../../../components/challenge/Questionnaire'
import Countdown from '../../../components/challenge/Countdown'
import Question from './components/Question'
import { getRandomTestQuestions, getRandomMainQuestions } from './data'
import ChallengeContainer from '../ChallengeContainer'
import colors from '../../../theme/colors'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import { AuthContext } from '../../../context'
import { trackEvent } from '../../../utils/tracking'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white'
  },
  rowCenter: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})

const BigFive = ({ testRound, submit }) => {
  const { rootStore } = useContext(AuthContext)

  const t0 = useRef(new Date()) // useRef prevent reinit
  const t1 = useRef(null) // useRef prevent reinit

  const [buttonsDisabled] = useState(false)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [answers, setAnswers] = useState([])
  const [questions] = useState(testRound ? getRandomTestQuestions() : getRandomMainQuestions())
  const [countdownColor, setCountdownColor] = useState(colors.primaryBlue)
  const countDown = useRef()
  const questionnaireRef = useRef(null)
  const prevPauseIsActive = useRef(rootStore.pauseStore.active)

  useEffect(() => {
    if (rootStore.pauseStore.active === true && prevPauseIsActive.current === false) {
      countDown.current && countDown.current.pauseTimer()
    }
    if (rootStore.pauseStore.active === false && prevPauseIsActive.current === true) {
      countDown.current && countDown.current.resumeTimer()
    }
    prevPauseIsActive.current = rootStore.pauseStore.active
  }, [rootStore.pauseStore.active]) // eslint-disable-line

  function choicePressed(item, { decision, retries }) {
    const { coding, dimension, medians, id } = item

    t1.current = new Date()
    const time_needed_in_ms = t1.current - t0.current
    const calc_time_needed_in_ms =
      time_needed_in_ms < 300 || time_needed_in_ms > 5000 ? null : time_needed_in_ms // Vorbereitungsschritte
    const pause_in_ms = rootStore.pauseStore.sum

    let calc_resp_latency = calc_time_needed_in_ms // Antwortlatenz
      ? 5000 - calc_time_needed_in_ms
      : null
    calc_resp_latency = calc_resp_latency >= 0 ? calc_resp_latency : null

    calc_resp_latency = calc_resp_latency || medians // Null-Werte korrigieren

    let item_score
    // item ist median wenn time < 300 > 5000
    if (calc_time_needed_in_ms === null) {
      item_score = medians
    } else {
      item_score = decision * coding * calc_resp_latency
    }

    if (!testRound) {
      setAnswers(
        answers.concat([
          {
            round: currentQuestionIndex + 1,
            id,
            category: dimension,
            time_needed_in_ms,
            retries,
            decision,
            pause_in_ms,
            item_score,
            resp_latency: calc_resp_latency
          }
        ])
      )

      setCountdownColor(colors.primaryBlue)
      countDown.current.resetTimer(4)
    }

    setTimeout(() => {
      questionnaireRef && questionnaireRef.current?.pressFooterPrimaryButton()
      t0.current = new Date()
      t1.current = null
    }, 150)
    rootStore.pauseStore.reset()
  }

  const submitChallenge = () => {
    if (testRound) submit([])
    const submitAnswer = answers.reduce((result, answer, idx) => {
      result[`round_${idx + 1}`] = answer
      return result
    }, {})
    submit(submitAnswer)
  }

  const renderLeftHeader = () => {
    if (testRound) return null

    return (
      <View style={styles.rowCenter}>
        <View style={{ marginRight: 4 }}>
          <Svg width={16} height={16}>
            <Path
              d='M8 14.222A6.222 6.222 0 1 0 8 1.778a6.222 6.222 0 0 0 0 12.444zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm.578-8H12.1v1.778H6.8V3.556h1.778V8z'
              fill={countdownColor}
              fillRule='nonzero'
            />
          </Svg>
        </View>
        <CountDownTimer
          ref={countDown}
          timeToShow={['M', 'S']}
          labelM=''
          labelS=''
          digitTxtColor={countdownColor}
          until={4}
          onFinish={() => setCountdownColor(colors.carnationPink)}
          size={14}
        />
      </View>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <ChallengeHeader
        title={
          testRound
            ? I18n.get('global.challenge.testround')
            : `${currentQuestionIndex + 1}/${questions.length}`
        }
        onPress={() => {
          trackEvent('ChallengePaused', { exam_id: 'BIG_FIVE' })
          rootStore.pauseStore.start()
        }}
        leftHeader={renderLeftHeader()}
      />
      <Questionnaire
        ref={questionnaireRef}
        questions={questions}
        onSubmit={submitChallenge}
        hideProgress
        renderQuestion={(item) => (
          <Question
            key={item.adjective}
            item={item}
            answer={answers[item.questionIdx]}
            choicePressed={(value) => choicePressed(item, value)}
            buttonsDisabled={buttonsDisabled}
          />
        )}
        onPrevOrNext={(idx) => setCurrentQuestionIndex(idx)}
        showFooter={false}
      />
    </SafeAreaView>
  )
}

BigFive.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

TestRoundCompleteScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  prevScreen: PropTypes.func.isRequired
}

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

InstructionScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

IntroScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const CountdownScreen = ({ nextScreen }) => <Countdown onPress={nextScreen} />

CountdownScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired
}

const BigFiveChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={BigFive}
    EXAM={ALL_CHALLENGES.BIG_FIVE}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: InstructionScreen, name: 'InstructionScreen' },
      { screen: CountdownScreen, name: 'CountdownScreen' }
    ]}
    MIDDLE_SCREENS={[
      { screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' },
      { screen: CountdownScreen, name: 'CountdownScreen' }
    ]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

BigFiveChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

BigFiveChallenge.defaultProps = { onChallengeComplete: () => {} }

export default BigFiveChallenge
