import React, { useImperativeHandle, useRef, forwardRef } from 'react'
import LottieView from 'lottie-react-native'
import PropTypes from 'prop-types'

const LottieAnimation = forwardRef(
  ({ loop, autoplay, animationData, width, height, onAnimationFinish, style }, ref) => {
    const lottieRef = useRef()

    useImperativeHandle(ref, () => ({
      stop() {
        lottieRef.current.stop()
      },
      pause() {
        lottieRef.current.pause()
      },
      play() {
        lottieRef.current.play()
      },
      goToAndPlay() {
        lottieRef.current.reset()
        lottieRef.current.play()
      },
      setSpeed(s) {
        lottieRef.current.setSpeed(s)
      },
      setDirection(d) {
        lottieRef.current.setDirection(d)
      },
      playSegments(s, f) {
        lottieRef.current.play(s, f)
      }
    }))

    return (
      <LottieView
        ref={lottieRef}
        resizeMode='contain'
        style={[
          style,
          {
            width,
            height
          }
        ]}
        source={animationData}
        loop={loop}
        autoPlay={autoplay}
        onAnimationFinish={onAnimationFinish}
      />
    )
  }
)

LottieAnimation.propTypes = {
  loop: PropTypes.bool.isRequired,
  autoplay: PropTypes.bool.isRequired,
  animationData: PropTypes.object.isRequired,
  width: PropTypes.any.isRequired,
  height: PropTypes.number.isRequired,
  onAnimationFinish: PropTypes.func,
  style: PropTypes.object
}

LottieAnimation.defaultProps = {
  onAnimationFinish: undefined,
  style: {}
}

export default LottieAnimation
