import React from 'react'
import { Image } from 'react-native'
import PropTypes from 'prop-types'

import { getSourceForStone } from '../data'

const Stone = ({ stone, height, width }) => (
  <Image style={{ height, width }} source={getSourceForStone(stone)} />
)

Stone.propTypes = {
  stone: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}

export default Stone
