import React, { useState, useRef, useEffect, useContext } from 'react'
import { View, StyleSheet, Image, SafeAreaView, ActivityIndicator, StatusBar } from 'react-native'
import { I18n } from 'aws-amplify'
import { Asset } from 'expo-asset'
import PropTypes from 'prop-types'

import Intro from '../../../components/challenge/Intro'
import Instruction from '../../../components/challenge/Instruction'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import Countdown from '../../../components/challenge/Countdown'
import ChallengeContainer from '../ChallengeContainer'
import Questionnaire from '../../../components/challenge/Questionnaire'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import ImageBackground from '../../../components/ImageBackground'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import metrics from '../../../theme/metrics'
import Question from './components/Question'
import { TEST_QUESTIONS, durationFocusInMs, getRoundItems, isAnswerCorrect } from './data'
import Button from './components/Button'
import { buttonTypes } from './data/button-types'
import { assets } from './data/assets'
import { notifyBugsnag } from '../../../utils/bugsnag'
import { AuthContext } from '../../../context'
import { trackEvent } from '../../../utils/tracking'

const s = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 24
    // backgroundColor: colors.lightPaleGrey
  },
  contentContainer: {
    flex: 1 // pushes the footer to the end of the screen
  }
})

const Flanker = ({ testRound, submit }) => {
  const { rootStore } = useContext(AuthContext)

  const t0 = useRef(new Date()) // useRef prevent reinit
  const t1 = useRef(null) // useRef prevent reinit
  const [questions] = useState(getRoundItems())
  const [round, setRound] = useState(0)
  const [answers, setAnswers] = useState([])
  const questionnaire = useRef(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  // one of [ 'arrow_left', 'arrow_right', 'correct', 'wrong' ]
  const [buttonLeftState, setButtonLeftState] = useState('arrow_left')
  const [buttonRightState, setButtonRightState] = useState('arrow_right')

  // one of [ 'default', 'correct', 'wrong' ]
  const [helicopterState, setHelicopterState] = useState('default')

  function cacheImages(images) {
    return images.map((image) => {
      if (typeof image === 'string') {
        return Image.prefetch(image)
      }
      return Asset.fromModule(image).downloadAsync()
    })
  }

  const choicePressed = (choice) => {
    t1.current = new Date()
    setIsButtonDisabled(true)

    const time_needed_in_ms = t1.current - t0.current
    const { id, congruent, correct_right } = testRound ? TEST_QUESTIONS[round] : questions[round]

    const correct = isAnswerCorrect(choice, correct_right)
    const decision = choice
    const pause_in_ms = rootStore.pauseStore.sum

    if (correct === 1) {
      setHelicopterState('correct')
      choice === 1 && setButtonRightState('correct')
      choice === 0 && setButtonLeftState('correct')
    }

    if (correct === -1) {
      setHelicopterState('wrong')
      choice === 1 && setButtonRightState('wrong')
      choice === 0 && setButtonLeftState('wrong')
    }

    setAnswers(
      answers.concat([
        {
          id,
          time_needed_in_ms,
          pause_in_ms,
          congruent,
          correct_score: correct, // 1 or -1
          correct_item: correct === -1 ? 0 : 1, // 1 or 0
          decision
        }
      ])
    )
    rootStore.pauseStore.reset()
  }

  useEffect(() => {
    let isMounted = true
    const imageAssets = cacheImages(assets)
    Promise.all([...imageAssets])
      .then(() => isMounted && setIsLoading(false))
      .catch((err) => notifyBugsnag(err))

    return function cleanup() {
      isMounted = false
    }
  }, []) // eslint-disable line

  useEffect(() => {
    setTimeout(() => {
      setIsButtonDisabled(false)
    }, durationFocusInMs)
  }, [round])

  const startTimeTracking = () => {
    t0.current = new Date()
    t1.current = null
  }

  const submitAnswer = () => {
    rootStore.pauseStore.reset()

    submit(
      answers.reduce((result, answer, idx) => {
        result[`round_${idx + 1}`] = answer
        return result
      }, {})
    )
  }

  const onAnimationFinished = () => {
    questionnaire && questionnaire?.current?.pressFooterPrimaryButton()

    setButtonLeftState('arrow_left')
    setButtonRightState('arrow_right')
    setHelicopterState('default')
  }

  if (isLoading) {
    return (
      <View style={[s.container, { alignItems: 'center', justifyContent: 'center' }]}>
        <ActivityIndicator />
      </View>
    )
  }

  return (
    <SafeAreaView style={s.container}>
      <StatusBar barStyle='dark-content' />
      <ImageBackground
        source={{
          uri: 'https://react-native-app-content.s3.eu-central-1.amazonaws.com/flanker_v2/background-clouds.png'
        }}
        resizeMode='cover'
        style={{
          position: 'absolute',
          width: metrics.widthPercentageToDP(100),
          height: metrics.heightPercentageToDP(100),
          left: 0,
          top: 0
        }}
      />
      <ChallengeHeader
        title={
          testRound ? I18n.get('global.challenge.testround') : `${round + 1}/${questions.length}`
        }
        onPress={() => {
          trackEvent('ChallengePaused', { exam_id: 'FLANKER' })
          rootStore.pauseStore.start()
        }}
      />
      <View style={s.contentContainer}>
        <Questionnaire
          ref={questionnaire}
          questions={testRound ? TEST_QUESTIONS : questions}
          hideProgress
          renderQuestion={(item) => (
            <Question
              key={item.id}
              item={item}
              helicopterState={helicopterState}
              onAnimationFinished={() => onAnimationFinished()}
              startTimeTracking={startTimeTracking}
            />
          )}
          onPrevOrNext={(idx) => setRound(idx)}
          showFooter={false}
          animationType='scale'
          onSubmit={submitAnswer}
        />
      </View>
      <View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', paddingBottom: 24 }}>
          <Button
            onPress={() => choicePressed(0)}
            disabled={isButtonDisabled}
            source={buttonTypes[buttonLeftState].source}
            backgroundColor={buttonTypes[buttonLeftState].backgroundColor}
          />
          <Button
            onPress={() => choicePressed(1)}
            disabled={isButtonDisabled}
            source={buttonTypes[buttonRightState].source}
            backgroundColor={buttonTypes[buttonRightState].backgroundColor}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}

Flanker.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

IntroScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

InstructionScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

TestRoundCompleteScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const CountdownScreen = ({ nextScreen }) => <Countdown onPress={() => nextScreen()} />

CountdownScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired
}

const FlankerChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={Flanker}
    EXAM={ALL_CHALLENGES.FLANKER}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: InstructionScreen, name: 'InstructionScreen' },
      { screen: CountdownScreen, name: 'CountdownScreen' }
    ]}
    MIDDLE_SCREENS={[
      { screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' },
      { screen: CountdownScreen, name: 'CountdownScreen' }
    ]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

FlankerChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

FlankerChallenge.defaultProps = { onChallengeComplete: () => {} }

export default FlankerChallenge
