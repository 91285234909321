import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    height: 72,
    paddingHorizontal: 12,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center'
  },
  inputContainer: {
    width: 12,
    height: 40,
    opacity: 0.15,
    backgroundColor: colors.mediumGrey,
    borderRadius: 2
  },
  lockShape: {
    height: 31,
    width: 67,
    alignSelf: 'center'
  }
})

const Lock = ({ length, answer, success, error }) => (
  <View>
    <Image
      source={require('../../../../assets/challenges/digit-span/game-assets/lock-shape.png')}
      style={styles.lockShape}
    />
    <View
      style={{
        width: 20,
        height: 40,
        backgroundColor: '#fff',
        borderRadius: 9.5,
        position: 'absolute',
        overflow: 'hidden',
        top: -15,
        alignSelf: 'center'
      }}
    >
      <View style={{ flex: 1, backgroundColor: '#fff' }} />
      <View style={{ flex: 1, backgroundColor: colors.glitter }} />
    </View>
    <View style={styles.container}>
      {Array.from(Array(length).keys()).map((i, index) => {
        const isLastElement = index < length - 1
        const isFilledElement = index < answer.length
        return (
          <View
            key={i}
            style={[
              styles.inputContainer,
              { marginRight: isLastElement ? 8 : 0, opacity: isFilledElement ? 1 : 0.15 },
              error ? { backgroundColor: colors.carnationError, opacity: 1 } : {},
              success ? { backgroundColor: colors.primaryGreen, opacity: 1 } : {}
            ]}
          />
        )
      })}
    </View>
  </View>
)

Lock.defaultProps = {
  success: false,
  error: false
}

Lock.propTypes = {
  length: PropTypes.number.isRequired,
  answer: PropTypes.array.isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool
}

export default Lock
