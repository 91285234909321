const sources = {
  3: [
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder1success.png'),
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder1.png')
  ],
  4: [
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder2success.png'),
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder2.png')
  ],
  5: [
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder3success.png'),
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder3.png')
  ],
  6: [
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder1success.png'),
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder1blocked.png')
  ],
  7: [
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder2success.png'),
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder2blocked.png')
  ],
  8: [
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder3success.png'),
    require('../../../../assets/challenges/path-finder/game-assets/pathfinder3blocked.png')
  ]
}

export const getSource = (index, condition) =>
  condition ? sources[String(index)][0] : sources[String(index)][1]
