import React from 'react'
import { View } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import ScienceText from './components/ScienceText'
import NormalDistributionCurve from './components/NormalDistributionCurve'
import JobMatch from './components/JobMatch'

const DigitSpanResult = ({ answer }) => {
  const score_percent = answer.aivy_output.translatedzScores.score
  return (
    <View>
      <NormalDistributionCurve
        containerStyle={{ marginTop: 32 }}
        title={I18n.get('challenge.digit_span.result.normal_distribution_curve.title')}
        percent={Math.round(score_percent)}
        description={`${
          score_percent <= 30 // eslint-disable-line
            ? I18n.get('challenge.digit_span.result.score_percent_1')
            : score_percent > 30 && score_percent < 70
            ? I18n.get('challenge.digit_span.result.score_percent_2')
            : I18n.get('challenge.digit_span.result.score_percent_3')
        }`}
        labels={{
          left: I18n.get('challenge.digit_span.result.scale_endpoint.left'),
          right: I18n.get('challenge.digit_span.result.scale_endpoint.right')
        }}
      />
      <JobMatch exam_id='DIGIT_SPAN' score={score_percent} containerStyle={{ marginTop: 16 }} />
      <ScienceText containerStyle={{ marginTop: 16 }} exam_id='DIGIT_SPAN' />
    </View>
  )
}

DigitSpanResult.propTypes = {
  answer: PropTypes.object.isRequired
}

export default DigitSpanResult
