import React, { useEffect, useState } from 'react'

import invitationStates from '../constants/invitationStates'
import UpdateWrapper from './UpdateWrapper'
import AppSettingsParamsWrapper from './AppSettingsParamsWrapper'
import RootStore from '../stores/RootStore'
import ConnectionWrapper from './ConnectionWrapper'
import App from '../App'
import { setLanguage } from '../utils/languages'
import { useFonts } from '../hooks/use-fonts'
import { ContentLoader, DeviceNotSupportedHint } from '../components'
import { isTablet } from '../utils/helpers'
import { useBetterURL } from '../hooks/use-better-url'

const rootStore = new RootStore()

const RootWrapper = () => {
  const fontsLoaded = useFonts()
  const [isLoading, setIsLoading] = useState(true)
  const [invitationState, setInvitationState] = useState(invitationStates.INITIAL)
  const [appState, setAppState] = useState('INITIAL')

  const url = useBetterURL()

  useEffect(() => {
    const setup = async () => {
      const language = await setLanguage()
      rootStore.userStore.language = language
      setIsLoading(false)
    }

    setup()
  }, [])

  if (isLoading || !fontsLoaded || !url) return <ContentLoader />

  if (isTablet()) {
    return <DeviceNotSupportedHint />
  }

  return (
    <ConnectionWrapper appState={appState} setAppState={setAppState}>
      <UpdateWrapper url={url}>
        <AppSettingsParamsWrapper
          // when the url changes,
          // the component get's rerendered
          key={url}
          rootStore={rootStore}
          setInvitationState={setInvitationState}
          url={url}
        >
          <App
            rootStore={rootStore}
            invitationState={invitationState}
            setInvitationState={setInvitationState}
            setAppState={setAppState}
          />
        </AppSettingsParamsWrapper>
      </UpdateWrapper>
    </ConnectionWrapper>
  )
}

export default RootWrapper
