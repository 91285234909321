import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import PropTypes from 'prop-types'

import metrics from '../../../../theme/metrics'
import { durationFocusInMs } from '../data'
import Helicopter from './Helicopter'

const s = StyleSheet.create({
  container: {
    height: metrics.widthPercentageToDP(77) * 0.88,
    justifyContent: 'center',
    marginTop: 128
  },
  twoAirPlaneStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  oneAirPlaneStyle: {
    alignItems: 'center'
  },
  focus: {
    height: metrics.widthPercentageToDP(39),
    width: metrics.widthPercentageToDP(39),
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

const Question = ({ item, helicopterState, onAnimationFinished, startTimeTracking }) => {
  const [isShowingFocus, setIsShowingFocus] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsShowingFocus(false)
      startTimeTracking()
    }, durationFocusInMs)
  }, [startTimeTracking])

  return (
    <View style={s.container}>
      {isShowingFocus ? (
        <Image
          style={s.focus}
          source={{
            uri: 'https://react-native-app-content.s3.eu-central-1.amazonaws.com/flanker_v2/focus.png'
          }}
        />
      ) : (
        <>
          <View style={s.twoAirPlaneStyle}>
            <Helicopter
              source={item.level[0]}
              helicopterState={helicopterState}
              onAnimationFinished={onAnimationFinished}
              index={0}
            />
            <Helicopter source={item.level[1]} helicopterState={helicopterState} index={1} />
          </View>
          <View style={s.oneAirPlaneStyle}>
            <Helicopter source={item.level[2]} helicopterState={helicopterState} index={2} />
          </View>
          <View style={s.twoAirPlaneStyle}>
            <Helicopter source={item.level[3]} helicopterState={helicopterState} index={3} />
            <Helicopter source={item.level[4]} helicopterState={helicopterState} index={4} />
          </View>
        </>
      )}
    </View>
  )
}

Question.propTypes = {
  item: PropTypes.object.isRequired,
  helicopterState: PropTypes.string.isRequired,
  onAnimationFinished: PropTypes.func.isRequired,
  startTimeTracking: PropTypes.func.isRequired
}

export default Question
