import React, { useState, useEffect, useRef, useContext } from 'react'
import { View, StyleSheet, SafeAreaView, Image, ActivityIndicator } from 'react-native'
import { Asset } from 'expo-asset'
import PropTypes from 'prop-types'

import colors from '../../../theme/colors'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import Instruction from '../../../components/challenge/Instruction'
import Intro from '../../../components/challenge/Intro'
import Countdown from '../../../components/challenge/Countdown'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import Typo from '../../../components/Typo'
import ChallengeContainer from '../ChallengeContainer'
import LottieAnimation from '../../../components/challenge/LottieAnimation'
import { StopButton, Reel } from './components' // NBACK Components
import {
  STARTING_BALANCE,
  SPIN_COST,
  COIN_WIN,
  getRandomQuestion,
  SYMBOLS,
  DURATION_PER_SYMBOL
} from './data'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import { AuthContext } from '../../../context'
import { trackEvent } from '../../../utils/tracking'

const s = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey
  },
  currencyIconStyle: {
    height: 20,
    width: 20,
    marginRight: 4
  },
  leftHeaderContainer: {
    flexDirection: 'row'
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  footer: {
    paddingTop: 24,
    paddingBottom: 24,
    alignItems: 'center'
  },
  lottieView: {
    width: 320,
    position: 'absolute',
    backgroundColor: 'yellow'
  }
})

const NBack = ({ submit }) => {
  const { rootStore } = useContext(AuthContext)

  const questionRef = useRef(getRandomQuestion())
  const [isLoading, setIsLoading] = useState(true)
  const [isSpinButtonDisabled, setIsSpinButtonDisabled] = useState(false)
  const [balance, setBalance] = useState(STARTING_BALANCE)
  const [showCorrect, setShowCorrect] = useState(true)

  const answers = useRef(
    questionRef.current.map((a) => ({
      time_needed_in_ms: DURATION_PER_SYMBOL,
      decision: 0,
      ...a
    }))
  )
  const lottieAnim = useRef(null)
  const lottieFalseAnim = useRef(null)
  const reelRef = useRef()

  const cacheImages = (images) =>
    images.map((image) => {
      if (typeof image === 'string') {
        return Image.prefetch(image)
      }
      return Asset.fromModule(image).downloadAsync()
    })

  const loadAssetsAsync = async () => {
    const imageAssets = cacheImages(SYMBOLS.map((symbol) => symbol.source.uri))

    await Promise.all([...imageAssets])
    setIsLoading(false)
  }

  useEffect(() => {
    loadAssetsAsync()
  }, []) // eslint-disable-line

  // useEffect(() => {
  //   let interval = null;
  //   if (reelRef.current) {
  //     interval = setInterval(() => {
  //       const { currentAnimatedValue, timings } = reelRef?.current?.getCurrentScrollPos();
  //       const symbol = getSymbolByPos(currentAnimatedValue, questionRef.current);
  //       setCurrentSymbol(symbol.symbolName);
  //     }, 10);
  //   }
  //   return function cleanup() {
  //     if (interval) clearInterval(interval);
  //   };
  // });

  /**
   * Updates the decision to 1 in object with passed round
   * @param {int} round
   */
  const updateAnswersArray = (round, time_needed_in_ms) => {
    if (!round || !time_needed_in_ms) return
    const correctTimeNeeded =
      time_needed_in_ms > DURATION_PER_SYMBOL ? DURATION_PER_SYMBOL : time_needed_in_ms

    let objIndex = null
    const newArr = [...answers.current]
    objIndex = newArr.findIndex((obj) => obj.round === round)
    newArr[objIndex].decision = 1
    newArr[objIndex].time_needed_in_ms = Math.round(correctTimeNeeded)

    answers.current = newArr
  }

  const onPressStopButton = () => {
    setIsSpinButtonDisabled(true)
    setTimeout(() => {
      setIsSpinButtonDisabled(false)
    }, 1000)

    // const { currentAnimatedValue, timings } = reelRef.current.getCurrentScrollPos();
    const currentSymbol = questionRef.current[reelRef.current.getCurrentSymbol()]
    const { item_correct, round } = currentSymbol

    const time_needed_in_ms = reelRef.current.getDuration()

    if (item_correct === 1) {
      setShowCorrect(true)
      lottieAnim.current.goToAndPlay()
      setBalance(balance - SPIN_COST + COIN_WIN)
      updateAnswersArray(round, time_needed_in_ms)
    } else {
      setShowCorrect(false)
      updateAnswersArray(round, time_needed_in_ms)
      lottieFalseAnim.current.goToAndPlay()
      setBalance(balance - SPIN_COST)
    }
  }

  const submitChallenge = () => {
    rootStore.pauseStore.reset()
    submit(
      answers.current.reduce((result, answer, idx) => {
        result[`round_${idx + 1}`] = answer
        return result
      }, {})
    )
  }

  /**
   * Called when Reel Animation ends.
   */
  const onAnimationEnd = () => {
    submitChallenge()
  }

  const renderLeftHeader = () => (
    <View style={s.leftHeaderContainer}>
      <Image
        style={s.currencyIconStyle}
        source={require('../../../assets/challenges/nback/game-assets/currency.png')}
      />
      <Typo.ButtonBlue
        style={{ color: balance < SPIN_COST ? colors.carnationPink : colors.primaryBlue }}
      >
        {balance > 0 ? balance : 0}
      </Typo.ButtonBlue>
    </View>
  )

  if (isLoading) {
    return (
      <View style={[s.container, { alignItems: 'center', justifyContent: 'center' }]}>
        <ActivityIndicator style={{ marginRight: 4 }} />
      </View>
    )
  }

  return (
    <SafeAreaView style={s.container}>
      <ChallengeHeader
        onPress={() => {
          trackEvent('ChallengePaused', { exam_id: 'NBACK' })
          rootStore.pauseStore.start()
        }}
        leftHeader={renderLeftHeader()}
      />
      <View style={s.contentContainer}>
        <LottieAnimation
          ref={lottieAnim}
          loop={false}
          style={{ position: 'absolute', opacity: showCorrect ? 1 : 0 }}
          width={300}
          height={300}
          autoplay={false}
          onAnimationFinish={() => {
            setIsSpinButtonDisabled(false)
          }}
          animationData={require('../../../assets/challenges/nback/game-assets/n-back-correct.json')}
        />

        <LottieAnimation
          ref={lottieFalseAnim}
          loop={false}
          style={{ position: 'absolute', opacity: showCorrect ? 0 : 1 }}
          width={300}
          height={300}
          autoplay={false}
          onAnimationFinish={() => {
            setIsSpinButtonDisabled(false)
          }}
          animationData={require('../../../assets/challenges/nback/game-assets/n-back-incorrect.json')}
        />

        <Reel
          question={questionRef.current}
          ref={reelRef}
          onAnimationEnd={onAnimationEnd}
          balance={balance}
        />
      </View>
      <View style={s.footer}>
        {/* <View style={{ padding: 20 }}>
          <Typo.H4Black>{`CURRENT SYMBOL: ${currentSymbol}`}</Typo.H4Black>
        </View> */}
        <StopButton onPress={onPressStopButton} disabled={isSpinButtonDisabled} />
      </View>
    </SafeAreaView>
  )
}

NBack.propTypes = {
  submit: PropTypes.func.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

IntroScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

InstructionScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

TestRoundCompleteScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const CountdownScreen = ({ nextScreen }) => <Countdown onPress={() => nextScreen()} />

CountdownScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired
}

const NBackChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={NBack}
    EXAM={ALL_CHALLENGES.NBACK}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: InstructionScreen, name: 'InstructionScreen' },
      { screen: CountdownScreen, name: 'CountdownScreen' }
    ]}
    MIDDLE_SCREENS={[
      { screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' },
      { screen: CountdownScreen, name: 'CountdownScreen' }
    ]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

NBackChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

NBackChallenge.defaultProps = { onChallengeComplete: () => {} }

export default NBackChallenge
