import React, { useState } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import PropTypes from 'prop-types'

import { I18n } from 'aws-amplify'
import Typo from '../Typo'

const s = StyleSheet.create({
  container: {
    height: 340,
    justifyContent: 'flex-end'
  },
  bar: {
    flex: 1,
    paddingHorizontal: 8,
    paddingBottom: 8,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
})

const Bar = ({ containerStyle, data }) => {
  const [barWidth, setBarWidth] = useState(null)

  const onLayout = (e) => {
    const { width } = e.nativeEvent.layout
    setBarWidth(width)
  }

  const getNumber = (index) => {
    switch (index) {
      case 0:
        return 2
      case 1:
        return 1
      case 2:
        return 3
      default:
        return 0
    }
  }

  return (
    <View style={[s.container, containerStyle]}>
      <View style={{ height: 198, flexDirection: 'row', alignItems: 'flex-end' }}>
        {data.map((bar, index) => (
          <View
            onLayout={onLayout}
            key={bar.label}
            style={[{ height: `${bar.value}%`, backgroundColor: bar.svg.fill }, s.bar]}
          >
            <Typo.NumberWhite>{getNumber(index)}</Typo.NumberWhite>
            <Typo.T2White>{I18n.get(bar.label)}</Typo.T2White>
          </View>
        ))}
      </View>
      {barWidth && (
        <Image
          style={{
            width: 90,
            height: 167,
            position: 'absolute',
            bottom: 80,
            left: barWidth / 2 - 16
          }}
          source={require('../../assets/images/strengths-screen/person.png')}
        />
      )}
      {barWidth && (
        <Image
          style={{
            width: 99,
            height: 215,
            position: 'absolute',
            bottom: 120,
            left: barWidth * 2 - barWidth / 2 - 24
          }}
          source={require('../../assets/images/strengths-screen/person2.png')}
        />
      )}
    </View>
  )
}

Bar.propTypes = {
  containerStyle: PropTypes.object,
  data: PropTypes.array.isRequired
}

Bar.defaultProps = {
  containerStyle: {}
}

export default Bar
