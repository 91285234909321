import React, { useContext, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import { observer } from 'mobx-react'

import { rowCenter, rowCenterBetween } from '../../styles'
import Typo from '../Typo'
import colors from '../../theme/colors'
import { AuthContext } from '../../context'

import BlueTitleDescriptionScreen from '../../screens/app/BlueTitleDescriptionScreen'
import UniversalModal from '../UniversalModal'

const styles = StyleSheet.create({
  ratingPercentContainer: {
    height: 28,
    paddingHorizontal: 8,
    backgroundColor: colors.primaryGreen,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const RatingPercent = ({ percent }) => (
  <View style={[styles.ratingPercentContainer]}>
    <Typo.H4White>{`${percent}%`}</Typo.H4White>
  </View>
)

const Bar = ({ percent, endpointLeft, endpointRight }) => {
  const [width, setWidth] = useState(0)
  const scale = d3.scaleLinear().domain([0, 100]).range([0, width])

  const onLayout = (event) => {
    setWidth(event.nativeEvent.layout.width)
  }
  return (
    <>
      <View
        onLayout={onLayout}
        style={{
          height: 24,
          width: '100%',
          backgroundColor: colors.paleWhiteBlue,
          justifyContent: 'center',
          overflow: 'hidden'
        }}
      >
        <View
          style={{
            position: 'absolute',
            height: 24,
            width: 60,
            backgroundColor: colors.primaryGreen,
            opacity: 0.2,
            transform: [{ translateX: scale(percent) - 30 }]
          }}
        />

        <Image
          source={require('../../assets/images/indicator.png')}
          style={{
            height: 32,
            width: 32,
            position: 'absolute',
            transform: [{ translateX: scale(percent) - 16 }]
          }}
        />
      </View>
      <View style={[rowCenterBetween, { marginTop: 4 }]}>
        <Typo.T2Black>{endpointLeft}</Typo.T2Black>
        <Typo.T2Black>{endpointRight}</Typo.T2Black>
      </View>
    </>
  )
}

const Skill = ({ skill }) => {
  const { rootStore } = useContext(AuthContext)
  const [modalVisible, setModalVisible] = useState(false)
  const { challengeID, score } = skill.scoreData[0]
  const answer = rootStore.answersStore.getAnswerForChallenge(challengeID)

  if (answer === undefined || answer === null) {
    return null
  }

  const percentile = answer.aivy_output.translatedzScores[score]

  return (
    <View style={{ marginBottom: 36 }}>
      <View style={[rowCenterBetween, { marginBottom: 8 }]}>
        <TouchableOpacity onPress={() => setModalVisible(true)}>
          <View style={rowCenter}>
            <Typo.T1Black translate>{skill.property}</Typo.T1Black>
            <Image
              resizeMode='contain'
              style={{ height: 18, width: 18, marginLeft: 6 }}
              source={require('../../assets/images/info.png')}
            />
          </View>
        </TouchableOpacity>
        {/* <RatingPercent percent={Math.round(percentile)} /> */}
      </View>
      <Bar
        percent={Math.round(percentile)}
        endpointLeft={skill.endpointLeft}
        endpointRight={skill.endpointRight}
      />
      <UniversalModal animationType='slide' transparent visible={modalVisible}>
        <BlueTitleDescriptionScreen
          route={{
            params: {
              title: skill.property,
              description: skill.intro.de,
              onPress: () => setModalVisible(false)
            }
          }}
        />
      </UniversalModal>
    </View>
  )
}

RatingPercent.propTypes = {
  percent: PropTypes.number.isRequired
}

Bar.propTypes = {
  percent: PropTypes.number.isRequired,
  endpointLeft: PropTypes.string.isRequired,
  endpointRight: PropTypes.string.isRequired
}

Skill.propTypes = {
  skill: PropTypes.object.isRequired
}

export default observer(Skill)
