import { Auth, I18n } from 'aws-amplify'
import * as Linking from 'expo-linking'
import moment from 'moment'
import * as Localization from 'expo-localization'

import de from '../i18n/de.json'
import en from '../i18n/en.json'
import asyncStorage from './async-storage'

const languages = { de, en }
const languagesString = JSON.stringify(languages).replace(/(?:\\r\\n)+/g, '')
const vocabularies = JSON.parse(languagesString)

const updateModules = async (language) => {
  moment.locale(language)
  I18n.setLanguage(language)
  I18n.putVocabularies(vocabularies)
  Auth.configure({ clientMetadata: { language } })
  await asyncStorage.storeData('lang', language)
}

/**
 * set language gets called before the update wrapper,
 * so we do not have to make a difference her between
 * web and app, because if the link contains a
 * language param, the param get's stored initial
 * and we get it on the second init after a possible
 * update from the local storage
 *
 * importance of possible languages:
 * language || localLanguage || systemLanguage || 'de'
 */
export const setLanguage = async () => {
  const initUrl = await Linking.parseInitialURLAsync()

  const { language: languageParam } = initUrl.queryParams || {}
  const localLanguage = await asyncStorage.getData('lang')
  const systemLanguage = Localization.locale.split('-').shift()

  const language = languageParam || localLanguage || systemLanguage

  await updateModules(Object.keys(languages).includes(language) ? language : 'de')
  return language || 'de'
}

export const getLanguages = () => ({
  de: 'Deutsch',
  en: 'English'
})
