import React from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../theme/colors'
import Typo from './Typo'
import metrics from '../theme/metrics'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: colors.darkBlue,
    paddingVertical: 32,
    paddingHorizontal: 20,
    borderRadius: 18,
    justifyContent: 'center'
  }
})

const CreateAccountPrompt = ({ onPress }) => (
  <View style={styles.container}>
    <Typo.T2White translate style={{ width: '60%' }} marginBottom={14}>
      settings.saveAccount.text
    </Typo.T2White>
    <TouchableOpacity onPress={onPress}>
      <Typo.ButtonBlack style={{ color: colors.brightGreen }} translate>
        settings.saveAccount.button
      </Typo.ButtonBlack>
    </TouchableOpacity>
    <Image
      source={require('../assets/images/illustrations/illustration-men-heart.png')}
      style={{
        position: 'absolute',
        right: 8,
        ...metrics.calculateResponsiveDimensions({ height: 109, width: 130 })
      }}
    />
  </View>
)

CreateAccountPrompt.propTypes = {
  onPress: PropTypes.func.isRequired
}

CreateAccountPrompt.defaultProps = {}

export default CreateAccountPrompt
