import React, { useEffect } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import * as MailComposer from 'expo-mail-composer'

import { I18n } from 'aws-amplify'
import metrics from '../../theme/metrics'
import colors from '../../theme/colors'
import Typo from '../Typo'
import Button from '../Button'
import { trackEvent } from '../../utils/tracking'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    paddingHorizontal: 20
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const DeviceNotSupportedHint = () => {
  useEffect(() => {
    trackEvent('DeviceNotSupportedHint')
  }, [])

  const onPressContactSupport = () => {
    MailComposer.isAvailableAsync().then((value) => {
      // eslint-disable-next-line no-alert
      if (value === false) alert('Kontaktiere uns über folgende E-Mail-Adresse: tech@aivy.app')
      MailComposer.composeAsync({
        subject: '[Device Not Supported] Support @ Aivy',
        recipients: ['tech@aivy.app']
      })
    })
  }

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <Image
          style={{
            ...metrics.calculateResponsiveDimensions({ height: 150, width: 150 }),
            alignSelf: 'center',
            marginBottom: 32,
            resizeMode: 'contain'
          }}
          source={require('../../assets/images/device-not-supported-hint.png')}
        />
        <Typo.H1Blue translate>device_not_supported_hint.title</Typo.H1Blue>

        <Typo.T1LightBlack translate style={{ width: '70%' }} marginTop={14} center>
          device_not_supported_hint.description
        </Typo.T1LightBlack>
        <Button
          containerStyle={{
            marginTop: 48,
            maxWidth: 329,
            alignSelf: 'center'
          }}
          title={I18n.get('device_not_supported_hint.button.title')}
          onPress={onPressContactSupport}
        />
      </View>
    </View>
  )
}

export default DeviceNotSupportedHint
