import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, View, Animated } from 'react-native'
import { BarChart } from 'react-native-svg-charts'

import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'
import metrics from '../../../../theme/metrics'
import Typo from '../../../../components/Typo'
import { Slider } from './Slider' // eslint-disable-line

const styles = StyleSheet.create({
  container: {
    width: '100%'
  },
  thumb: {
    width: metrics.widthPercentageToDP(10),
    height: metrics.widthPercentageToDP(10),
    borderRadius: 4,
    backgroundColor: colors.brightBlue,
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    fontFamily: 'fira-sans-regular',
    color: '#8B8F99',
    lineHeight: 18,
    width: 140,
    fontSize: 12,
    textTransform: 'uppercase'
  }
})

const initDataBarChart = () => {
  const t = []
  for (let i = 100; i >= 0; i -= 10) {
    t.push({
      value: i,
      svg: { fill: colors.paleBlue }
    })
  }
  for (let i = 0; i <= 100; i += 10) {
    t.push({
      value: i,
      svg: { fill: colors.paleBlue }
    })
  }

  return t
}

const LifeGoalsSlider = ({ onComplete, showAnswerTitle, leftLabel, rightLabel }) => {
  const [value, setValue] = useState(5)
  // const [multiSliderValue, setMultiSliderValue] = useState([5, 5])
  // eslint-disable-next-line no-unused-vars
  const [dataBarChart, setDataBarChart] = useState(initDataBarChart())
  const [answer, setAnswer] = useState('einigermaßen wichtig')

  const animatedValue = useRef(new Animated.Value(5))

  const [interpolateColorLabelLeft] = useState(
    animatedValue.current.interpolate({
      inputRange: [0, 5, 10],
      outputRange: [colors.brightBlue, '#8B8F99', '#8B8F99']
    })
  )

  const [interpolateColorLabelRight] = useState(
    animatedValue.current.interpolate({
      inputRange: [0, 5, 9],
      outputRange: ['#8B8F99', '#8B8F99', colors.brightBlue]
    })
  )

  useEffect(() => {
    Animated.timing(animatedValue.current, {
      toValue: value,
      duration: 100,
      useNativeDriver: false // style property 'color' is not supported by native animated module
    }).start()
  }, [value])

  /**
   * Compute Color for BarChart (above slider)
   * paleBlue inaktiv
   * brightBlue aktiv
   */
  const getColor = (v, i, side) => {
    const activeColor = colors.brightBlue

    if (v === 5) {
      return colors.paleBlue
    }
    if (v > 5) {
      if (side === 'left') {
        return colors.paleBlue
      }
      if (v === 10 && i <= 100) {
        return activeColor
      }
      if (v === 9 && i <= 80) {
        return activeColor
      }
      if (v === 8 && i <= 60) {
        return activeColor
      }
      if (v === 7 && i <= 40) {
        return activeColor
      }
      if (v === 6 && i <= 20) {
        return activeColor
      }
      return colors.paleBlue
    }
    if (v < 5) {
      if (side === 'right') {
        return colors.paleBlue
      }
      if (v === 0 && i <= 100) {
        return activeColor
      }
      if (v === 1 && i <= 80) {
        return activeColor
      }
      if (v === 2 && i <= 60) {
        return activeColor
      }
      if (v === 3 && i <= 40) {
        return activeColor
      }
      if (v === 4 && i <= 20) {
        return activeColor
      }
      return colors.paleBlue
    }
  }

  const sliderComplete = (v) => {
    onComplete(v)
  }

  const sliderValuesChange = (v) => {
    setValue(v)

    // if (v > 5) {
    //  setMultiSliderValue([5, v])
    // } else {
    //  setMultiSliderValue([v, 5])
    // }

    if (v >= 0 && v < 2) {
      setAnswer('überhaupt nicht wichtig')
    } else if (v >= 2 && v < 4) {
      setAnswer('nicht sehr wichtig')
    } else if (v >= 4 && v < 7) {
      setAnswer('einigermaßen wichtig')
    } else if (v >= 7 && v < 9) {
      setAnswer('sehr wichtig')
    } else {
      setAnswer('äußerst wichtig')
    }

    const t = []
    // left side
    for (let i = 100; i >= 0; i -= 10) {
      t.push({
        value: i,
        svg: { fill: getColor(v, i, 'left') }
      })
    }
    // right side
    for (let i = 0; i <= 100; i += 10) {
      t.push({
        value: i,
        svg: { fill: getColor(v, i, 'right') }
      })
    }

    setDataBarChart(t)
  }

  return (
    <View style={styles.container}>
      {showAnswerTitle ? (
        <View>
          <Typo.H2Black
            marginBottom={4}
            style={{
              color: colors.primaryBlue,
              fontSize: 16,
              textTransform: 'uppercase'
            }}
          >
            Meine Antwort lautet
          </Typo.H2Black>
          <Typo.H2Black
            marginBottom={16}
            style={{
              fontSize: 26,
              color: colors.sapphire,
              lineHeight: 32
            }}
          >
            {answer}
          </Typo.H2Black>
        </View>
      ) : null}

      <BarChart
        style={{ height: 48, position: 'relative', top: 80 }}
        data={dataBarChart}
        yAccessor={({ item }) => item.value}
        svg={{
          fill: colors.paleBlue
        }}
        leftLabel={leftLabel}
        rightLabel={rightLabel}
        spacingInner={0.4}
      />

      <Slider
        value={value}
        minimumValue={0}
        maximumValue={10}
        step={1}
        onValueChange={sliderValuesChange}
        onSlidingComplete={sliderComplete}
        trackStyle={{ backgroundColor: 'transparent' }}
        maximumTrackTintColor='transparent'
        minimumTrackTintColor='transparent'
        thumbImage={require('../../../../assets/challenges/heros-journey/game-assets/thumb.png')}
        thumbStyle={styles.thumb}
        thumbTintColor='#0c6692'
        thumbTouchSize={{ width: 60, height: 60 }}
        thumbSize={{ width: 20, height: 12 }}
        style={{ position: 'relative', zIndex: 120, top: metrics.widthPercentageToDP(21) }}
      />

      <View
        style={{
          top: metrics.widthPercentageToDP(15.6),
          width: '100%',
          height: 4,
          flexDirection: 'row',
          justifyContent: 'center',
          borderRadius: 4
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '50%',
            backgroundColor: colors.paleBlue
          }}
        >
          <View
            style={{
              width: `${100 - value * 20}%`,
              backgroundColor: colors.brightBlue
            }}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '50%',
            backgroundColor: colors.paleBlue
          }}
        >
          <View
            style={{
              width: `${(value - 5) * 20}%`,
              backgroundColor: colors.brightBlue
            }}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: metrics.widthPercentageToDP(22)
        }}
      >
        <Animated.Text style={[styles.label, { color: interpolateColorLabelLeft }]}>
          {leftLabel || 'Überhaupt\nnicht wichtig'}
        </Animated.Text>
        <Animated.Text
          style={[styles.label, { color: interpolateColorLabelRight }, { textAlign: 'right' }]}
        >
          {rightLabel || 'Äußerst\nwichtig'}
        </Animated.Text>
      </View>
    </View>
  )
}

LifeGoalsSlider.propTypes = {
  onComplete: PropTypes.func.isRequired,
  showAnswerTitle: PropTypes.bool,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string
}

LifeGoalsSlider.defaultProps = {
  showAnswerTitle: false,
  leftLabel: undefined,
  rightLabel: undefined
}

export default LifeGoalsSlider
