import React, { useState, useRef, useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Auth, I18n } from 'aws-amplify'
import { StackActions } from '@react-navigation/native'

import { AivyTextInput, ModalHeader, Typo, Button } from '../../../components'

import { notifyBugsnag } from '../../../utils/bugsnag'
import { AuthContext } from '../../../context'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  }
})

const SaveStrengthEmail = ({ navigation }) => {
  const { rootStore } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef()

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(I18n.get('global.save_strength_email.email_input_required'))
      .email(I18n.get('global.save_strength_email.email_input_check'))
  })

  const showAlertAliasExistsException = (email) => {
    navigation.navigate('Alert', {
      title: I18n.get('global.save_strength_email.alias_exists_exception.alert_title'),
      message: I18n.get('global.save_strength_email.alias_exists_exception.alert_description'),
      buttons: [
        {
          text: I18n.get('global.save_strength_email.alias_exists_exception.alert_cancel_action'),
          onPress: () => {}, // specifiy undefined to disable Alert when pressing this button
          style: 'cancel'
        },
        {
          text: I18n.get('global.save_strength_email.alias_exists_exception.alert_success_action'),
          onPress: async () => {
            setIsLoading(true)
            try {
              rootStore.clearAllStores()
              await Auth.signOut()
              const cognitoUser = await Auth.signIn(email.toLowerCase())

              navigation.dispatch(StackActions.popToTop())
              navigation.goBack()

              navigation.navigate('VerifyCode', { email, cognitoUser, reload: true })
            } catch (err) {
              notifyBugsnag(err)
            }
          }
        }
      ]
    })
  }

  const showAlert = (email) => {
    navigation.navigate('Alert', {
      title: I18n.get('global.save_strength_email.email_input_alert_title'),
      message: `${email}`,
      buttons: [
        {
          text: I18n.get('global.save_strength_email.email_input_alert_cancel_action'),
          onPress: () => {}, // specifiy undefined to disable Alert when pressing this button
          style: 'cancel'
        },
        {
          text: I18n.get('global.save_strength_email.email_input_alert_success_action'),
          onPress: () => {
            setIsLoading(true)
            Auth.currentAuthenticatedUser()
              .then((user) => {
                Auth.updateUserAttributes(user, { email: email.toLowerCase() })
                  .then((res) => {
                    if (res === 'SUCCESS') {
                      navigation.navigate('SaveStrengthCodeVerify', { email })
                    }
                  })
                  .catch((err) => {
                    switch (err.code) {
                      case 'AliasExistsException':
                        formRef.current.setFieldError(
                          'email',
                          I18n.get('global.alert.error.mail_already_reg')
                        )
                        showAlertAliasExistsException(email)
                        break
                      default:
                        formRef.current.setFieldError('email', err.message)
                        break
                    }
                    // formRef.current.resetForm()
                    err.code !== 'AliasExistsException' && notifyBugsnag(err)
                  })
                  .finally(() => setIsLoading(false))
              })
              .catch((err) => notifyBugsnag(err))
          }
        }
      ]
    })
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values) => showAlert(values.email)}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {({ handleChange, handleBlur, values, errors, touched, isValid, handleSubmit }) => (
        <View style={styles.container}>
          <ModalHeader
            headerTitle='global.save_strength_email.modal_header_title'
            onPress={() => navigation.goBack()}
            withBackButton
          />
          <View style={{ marginTop: 16, paddingHorizontal: 24 }}>
            <Typo.H2Blue translate center marginBottom={4}>
              global.save_strength_email.email_input_question
            </Typo.H2Blue>
            <Typo.T1LightBlack translate center>
              global.save_strength_email.email_input_information
            </Typo.T1LightBlack>
            <AivyTextInput
              containerStyle={{ marginTop: 48 }}
              label='global.save_strength_email.email_input_label'
              onChangeText={handleChange('email')}
              onBlur={() => handleBlur('email')}
              value={values.email}
              error={errors.email && touched.email}
              errorText={errors.email || undefined}
              isValid={isValid.valueOf('email')}
              keyboardType='email-address'
            />
            <Button
              containerStyle={{ marginTop: 52 }}
              title='global.save_strength_email.further_action'
              disabled={!isValid.valueOf('email') || values.email === ''}
              onPress={handleSubmit}
              isLoading={isLoading}
            />
          </View>
        </View>
      )}
    </Formik>
  )
}

SaveStrengthEmail.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default SaveStrengthEmail
