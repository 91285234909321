import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import PropTypes from 'prop-types'

import {
  Main,
  ChallengeContainerScreen,
  ReverseRecruiting,
  RegisterForPushNotifications,
  VerifyCode,
  Newsletter,
  NetPromoterScore,
  BlueTitleDescriptionScreen
} from '../screens'
import SettingsStack from './SettingsStack'
import SaveStrengthStack from './SaveStrengthStack'
import { Alert } from '../components'
import HerosJourneySelection from '../screens/challenges/heros_journey/components/HerosJourneySelection'
import Pause from '../components/challenge/Pause'
import ChallengeResultWrapper from '../screens/challenges/ChallengeResultWrapper'
import Perspectives from '../screens/app/Perspectives'
import Notification from '../components/Notification'
import { setNotificationHolder } from '../utils/notification-holder'
import HardFacts from '../screens/app/settings/account/HardFacts'
import Redirect from '../screens/app/Redirect'

const AppStack = createNativeStackNavigator()

const appStackScreens = [
  { name: 'Settings', Component: SettingsStack },
  { name: 'SaveStrengthDNA', Component: SaveStrengthStack },
  { name: 'RegisterForPushNotifications', Component: RegisterForPushNotifications },
  { name: 'ReverseRecruiting', Component: ReverseRecruiting },
  { name: 'Perspectives', Component: Perspectives },
  { name: 'Newsletter', Component: Newsletter },
  { name: 'HardFacts', Component: HardFacts },
  {
    name: 'Redirect',
    Component: Redirect
  },
  {
    name: 'ChallengeContainerScreen',
    Component: ChallengeContainerScreen,
    options: { presentation: 'fullScreenModal' }
  },
  { name: 'ChallengeResult', Component: ChallengeResultWrapper },
  {
    name: 'Alert',
    Component: Alert,
    options: { presentation: 'transparentModal', animation: 'fade' }
  },
  { name: 'HerosJourneySelection', Component: HerosJourneySelection },
  {
    name: 'Pause',
    Component: Pause,
    options: { presentation: 'transparentModal', animation: 'fade' }
  },
  { name: 'VerifyCode', Component: VerifyCode, options: { presentation: 'fullScreenModal' } },
  {
    name: 'NetPromoterScore',
    Component: NetPromoterScore,
    options: { presentation: 'transparentModal', animation: 'fade' }
  },
  {
    name: 'BlueTitleDescriptionScreen',
    Component: BlueTitleDescriptionScreen,
    options: { presentation: 'fullScreenModal' }
  }
]

const AppStackScreen = ({ route: mainInitialParams }) => (
  <AppStack.Navigator screenOptions={{ headerShown: false }}>
    <AppStack.Screen
      name='Main'
      initialParams={mainInitialParams.params}
      // eslint-disable-next-line react/no-children-prop
      children={({ navigation, route }) => (
        <>
          <Main navigation={navigation} route={route} />
          <Notification ref={(ref) => setNotificationHolder(ref)} />
        </>
      )}
    />
    {/* Common modal screens */}
    <AppStack.Group
      screenOptions={{
        presentation: 'modal',
        animation: 'slide_from_bottom'
      }}
    >
      {appStackScreens.map(({ name, Component, options }, index) => (
        <AppStack.Screen
          key={index}
          name={name}
          options={options}
          // eslint-disable-next-line react/no-children-prop
          children={({ navigation, route }) => (
            <>
              <Component navigation={navigation} route={route} />
              <Notification ref={(ref) => setNotificationHolder(ref)} />
            </>
          )}
        />
      ))}
    </AppStack.Group>
  </AppStack.Navigator>
)

AppStackScreen.propTypes = {
  route: PropTypes.object.isRequired
}

export default AppStackScreen
