import React, { useContext, useState } from 'react'
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { Picker } from '@react-native-picker/picker'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'
import { observer } from 'mobx-react'

import { Typo, Button } from '../../components'
import metrics from '../../theme/metrics'
import { AuthContext, NotificationContext } from '../../context'
import { replaceTranslationKey } from '../../utils/helpers'
import { trackEvent } from '../../utils/tracking'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0, 0.5)'
  }
})

const NetPromoterScore = ({ navigation }) => {
  const [selectedNps, setSelectedNps] = useState(5)
  const { rootStore } = useContext(AuthContext)
  const { success } = useContext(NotificationContext)

  function getLabelForItem(value) {
    if (value === 0) {
      return replaceTranslationKey(
        I18n.get('net_promoter_score.left_skala_title'),
        '{{value}}',
        value
      )
    }
    if (value === 10) {
      return replaceTranslationKey(
        I18n.get('net_promoter_score.right_skala_title'),
        '{{value}}',
        value
      )
    }

    return value.toString()
  }

  const onPressSubmit = async () => {
    try {
      const net_promoter_scores =
        rootStore.userStore.userProfile.net_promoter_scores?.length > 1
          ? [
              ...rootStore.userStore.userProfile.net_promoter_scores,
              {
                score: selectedNps,
                description: '',
                product: 'AIVY_UNIVERSAL_APP',
                createdAt: new Date().toISOString()
              }
            ]
          : [
              {
                score: selectedNps,
                description: '',
                product: 'AIVY_UNIVERSAL_APP',
                createdAt: new Date().toISOString()
              }
            ]

      await rootStore.userStore.updateUser({
        id: rootStore.userStore.username,
        net_promoter_scores
      })
      success(I18n.get('net_promoter_score.success_msg'))
      trackEvent('SUBMIT_NET_PROMOTER_SCORE')
      navigation.goBack()
    } catch (error) {
      throw new Error(error)
    }
  }

  return (
    <View style={styles.container}>
      <View
        style={{
          width: metrics.widthPercentageToDP(94),
          borderRadius: 12,
          backgroundColor: '#fff',
          paddingVertical: 24,
          paddingHorizontal: 26
        }}
      >
        <Image
          style={{
            height: 64,
            width: 64,
            resizeMode: 'contain',
            marginVertical: 16,
            alignSelf: 'center'
          }}
          source={require('../../assets/images/aivy-logo.png')}
        />
        <Typo.H2Black center translate>
          net_promoter_score.title
        </Typo.H2Black>
        <Picker
          selectedValue={selectedNps}
          onValueChange={(itemValue) => setSelectedNps(itemValue)}
          style={{ marginTop: 24 }}
        >
          {[...Array(11).keys()].map((value) => (
            <Picker.Item
              key={value}
              // eslint-disable-next-line no-nested-ternary
              label={getLabelForItem(value)}
              value={value}
            />
          ))}
        </Picker>
        <View style={{ marginTop: 20 }}>
          <Button title='net_promoter_score.button_submit' onPress={onPressSubmit} />
          <TouchableOpacity
            style={{ alignItems: 'center', marginTop: 8 }}
            onPress={() => navigation.goBack()}
          >
            <Typo.ButtonBlue>Jetzt nicht</Typo.ButtonBlue>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

NetPromoterScore.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default observer(NetPromoterScore)
