import { makeAutoObservable } from 'mobx'

import { query } from '../graphql'
import { notifyBugsnag } from '../utils/bugsnag'

class SpaceStore {
  constructor() {
    this.spaces = []
    this.active_space = null
    makeAutoObservable(this)
  }

  get isAivySelect() {
    return this.active_space?.career_id
  }

  get spaceContext() {
    return this.active_space?.context
  }

  setSpaces(spaces) {
    this.spaces = spaces
  }

  initSpace(space) {
    if (!space) return
    this.active_space = space
  }

  async appendSpace(space) {
    if (this.spaces.find((element) => element.id === space.id)) {
      return
    }
    this.spaces.push(space)
    this.active_space = space
  }

  static async getSpaceByID(spaceId) {
    if (!spaceId) return null

    const answer = await query({
      query: 'customGetSpace',
      variables: { id: spaceId },
      authMode: 'AWS_IAM'
    })

    return answer.data.getSpace
  }

  clear() {
    this.spaces = []
    this.active_space = null
  }

  async updateSpace(data, spaceId) {
    if (this.active_space === null) return

    let answer
    try {
      answer = await query({
        query: 'customUpdateSpace',
        variables: { input: { id: spaceId || this.active_space.id, ...data } }
      })
    } catch (err) {
      notifyBugsnag(err)
    }

    return answer
  }
}

export default SpaceStore
