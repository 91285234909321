import React, { useContext } from 'react'
import { View } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import ScienceText from './components/ScienceText'
import TradeOff from './components/TradeOff'
import NormalDistributionCurve from './components/NormalDistributionCurve'
import JobMatch from './components/JobMatch'
import { AuthContext } from '../../../context'

const EmotionsGameResult = ({ answer = {}, demo }) => {
  const { rootStore } = useContext(AuthContext)

  const { challengeConfigs } = rootStore.appSettingsStore
  const filteredChallengeConfigsById = challengeConfigs.filter((c) => c.id === 'EMOTIONS_GAME')

  const heighest_score_answer_translated =
    filteredChallengeConfigsById[0]?.heighest_score_answer_translated

  const score_percent = answer.aivy_output.translatedzScores.score

  const avg_speed_percent_of_heighestScoreAnswer =
    heighest_score_answer_translated?.avg_speed_emotions_game || 0

  const avg_accuracy_percent_of_heighestScoreAnswer =
    heighest_score_answer_translated?.avg_accuracy_emotions_game || 0

  const avg_speed_percent = answer.aivy_output.translatedzScores.avg_speed_emotions_game

  const avg_accuracy_percent = answer.aivy_output.translatedzScores.avg_accuracy_emotions_game

  const tradeoff = demo === true ? 20 : 100 - avg_speed_percent - avg_accuracy_percent
  const tradeoffHeighestScoreAnswer =
    100 - avg_speed_percent_of_heighestScoreAnswer - avg_accuracy_percent_of_heighestScoreAnswer

  return (
    <View>
      <NormalDistributionCurve
        containerStyle={{ marginTop: 32 }}
        title={I18n.get('challenge.emotions_game.result.normal_distribution_curve.title')}
        percent={Math.round(score_percent)}
        description={`${
          score_percent <= 30 // eslint-disable-line
            ? I18n.get('challenge.emotions_game.score_percent_1')
            : score_percent > 30 && score_percent < 70
            ? I18n.get('challenge.emotions_game.score_percent_2')
            : I18n.get('challenge.emotions_game.score_percent_3')
        }`}
        labels={{
          left: I18n.get('challenge.emotions_game.result.scale_endpoint.left'),
          right: I18n.get('challenge.emotions_game.result.scale_endpoint.right')
        }}
      />
      <TradeOff
        containerStyle={{ marginTop: 16 }}
        title={I18n.get('global.accuracy_speed')}
        leftSkala={I18n.get('global.accuracy')}
        rightSkala={I18n.get('global.speed')}
        tradeoff={tradeoff}
        firstDescription={`${I18n.get('challenge.result.tradeoff_pretext')} ${
          tradeoff >= 0
            ? I18n.get('challenge.emotions_game.tradeoff_1')
            : I18n.get('challenge.emotions_game.tradeoff_2')
        }.`}
        secondDescription={`${I18n.get('challenge.result.tradeoff.pretext_second')} ${
          tradeoffHeighestScoreAnswer >= 0 ? I18n.get('global.speed') : I18n.get('global.accuracy')
        }**.`}
      />
      <JobMatch exam_id='EMOTIONS_GAME' score={score_percent} containerStyle={{ marginTop: 16 }} />
      <ScienceText containerStyle={{ marginTop: 16 }} exam_id='EMOTIONS_GAME' />
    </View>
  )
}

EmotionsGameResult.propTypes = {
  answer: PropTypes.object.isRequired,
  demo: PropTypes.bool
}

EmotionsGameResult.defaultProps = {
  demo: false
}

export default EmotionsGameResult
