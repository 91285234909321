import React from 'react'
import { View, StyleSheet, TouchableOpacity, Text, ActivityIndicator } from 'react-native'
import PropTypes from 'prop-types'

import { I18n } from 'aws-amplify'
import colors from '../theme/colors'

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.primaryBlue,
    padding: 13,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 24
  },
  text: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'fira-sans-regular',
    marginHorizontal: 42
  }
})

const Button = ({
  onPress,
  title,
  disabled,
  type,
  containerStyle,
  textStyle,
  isLoading,
  backgroundColor,
  id,
  activityIndicatorColor,
  uppercase,
  marginHorizontal,
  centerText
}) => {
  const buttonStyles = [styles.button]
  const textStyles = [styles.text, textStyle]

  if (disabled) {
    buttonStyles.push({ opacity: 0.3 })
  }

  if (type === 'outline') {
    buttonStyles.push({
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: colors.primaryBlue
    })
    textStyles.push({ color: colors.primaryBlue })
  }

  if (type === 'white') {
    buttonStyles.push({
      backgroundColor: 'white'
    })
    textStyles.push({ color: colors.darkBlack })
  }

  if (marginHorizontal) {
    textStyles.push({ marginHorizontal })
  }

  if (centerText) {
    textStyles.push({ textAlign: 'center' })
  }

  return (
    <TouchableOpacity
      id={id}
      disabled={disabled || isLoading}
      onPress={onPress}
      style={containerStyle}
    >
      <View style={[buttonStyles, backgroundColor ? { backgroundColor } : {}]}>
        {isLoading ? (
          <ActivityIndicator color={activityIndicatorColor} style={{ marginHorizontal: 42 }} />
        ) : (
          <Text style={textStyles}>
            {uppercase ? I18n.get(title).toUpperCase() : I18n.get(title)}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

Button.propTypes = {
  onPress: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'outline', 'white']),
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  textStyle: PropTypes.object,
  isLoading: PropTypes.bool,
  backgroundColor: PropTypes.string,
  activityIndicatorColor: PropTypes.string,
  uppercase: PropTypes.bool,
  marginHorizontal: PropTypes.number,
  centerText: PropTypes.bool
}

Button.defaultProps = {
  disabled: false,
  type: 'primary',
  id: 'button',
  containerStyle: {},
  textStyle: {},
  isLoading: false,
  backgroundColor: undefined,
  activityIndicatorColor: '#fff',
  uppercase: false,
  marginHorizontal: undefined,
  centerText: false
}

export default Button
