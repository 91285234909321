import React, { useState } from 'react'
import { ImageBackground as ImageBackgroundRN, ActivityIndicator, View } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../theme/colors'

const ImageBackground = ({ source, resizeMode, style, imageStyle, children }) => {
  const [isLoading, setIsLoading] = useState(true)

  // useEffect(() => setTimeout(() => setIsLoading(false), 1800))

  return (
    <ImageBackgroundRN
      onLoad={() => setIsLoading(false)}
      source={source}
      style={[style, { backgroundColor: colors.mainColor }]}
      imageStyle={imageStyle}
      resizeMode={resizeMode}
    >
      {isLoading && (
        <View
          style={{
            flex: 1,
            backgroundColor: colors.lightGrey,
            justifyContent: 'center'
          }}
        >
          <ActivityIndicator color={colors.mainColor} />
        </View>
      )}
      {!isLoading && children}
    </ImageBackgroundRN>
  )
}

ImageBackground.propTypes = {
  source: PropTypes.object.isRequired,
  resizeMode: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  imageStyle: PropTypes.object,
  children: PropTypes.node
}

ImageBackground.defaultProps = {
  imageStyle: {},
  children: null
}

export default ImageBackground
