import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import PropTypes from 'prop-types'

import Typo from './Typo'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
    marginBottom: 64
  },
  imageStyle: {
    resizeMode: 'contain'
  }
})

const EmptyState = ({ image, description }) => {
  const { width, height, src } = image

  return (
    <View style={styles.container}>
      <Image style={[styles.imageStyle, { width, height }]} source={src} />
      <Typo.T1LightBlack center style={{ marginTop: 32 }}>
        {description}
      </Typo.T1LightBlack>
    </View>
  )
}

EmptyState.propTypes = {
  image: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired
}

export default EmptyState
