import React from 'react'
import { View, StyleSheet, StatusBar, SafeAreaView, ScrollView } from 'react-native'
import PropTypes from 'prop-types'

import Typo from '../Typo'
import Button from '../Button'
import { INFO_TEXTS } from '../../screens/challenges/big_five/data'

const s = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 24,
    backgroundColor: 'rgba(68, 97, 212, 0.95)'
  },
  contentContainer: {
    flex: 1 // pushes the footer to the end of the screen
  },
  footer: {
    paddingHorizontal: 24,
    paddingBottom: 24,
    paddingTop: 24
  },
  btn: {
    borderWidth: 0,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0
  }
})

const PersonalityModal = ({ onPress, trait }) => (
  <SafeAreaView style={s.container}>
    <StatusBar barStyle='light-content' animated />
    <View style={s.contentContainer}>
      <View marginTop={40} style={{ paddingHorizontal: 20 }}>
        <Typo.H1White>{trait}</Typo.H1White>
        <ScrollView style={{ marginTop: 40, flex: 1 }}>
          <Typo.T1White translate>{INFO_TEXTS[trait]}</Typo.T1White>
        </ScrollView>
      </View>
    </View>
    <View style={s.footer}>
      <Button onPress={onPress} title='global.got_it' type='white' containerStyle={s.btn} />
    </View>
  </SafeAreaView>
)

PersonalityModal.propTypes = {
  onPress: PropTypes.func.isRequired,
  trait: PropTypes.string.isRequired
}

export default PersonalityModal
