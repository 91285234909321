import cities from '../constants/cities'

export const filterCities = (value) => {
  if (!value || value.length < 3) return []

  const result = cities.filter(({ filterBy }) => filterBy.startsWith(value.toLowerCase()))

  result.length = 5

  return result
}
