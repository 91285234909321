import { REACH_REQUEST } from './reach-request'

export const USER = `{
  id
  time_in_app
  type
  net_promoter_scores {
    score
    description
    product
    createdAt
  }
  opens_app
  opens_app_daily
  avg_challenge_rating
  gender
  content_categories
  active_space_id
  delete_text
  privacy
  mail_stored
  phone_stored
  last_seen
  createdAt
  updatedAt
  suggested_hero
  hero
  location {
    lat
    lon
    city
  }
  percentiles {
    BIG_FIVE_o_score
    BIG_FIVE_c_score
    BIG_FIVE_e_score
    BIG_FIVE_a_score
    BIG_FIVE_n_score
    BIG_FIVE_elevation
    RIASEC_IMAGES_elevation
    RIASEC_IMAGES_r_score
    RIASEC_IMAGES_i_score
    RIASEC_IMAGES_a_score
    RIASEC_IMAGES_s_score
    RIASEC_IMAGES_e_score
    RIASEC_IMAGES_c_score
    HEROS_JOURNEY_score_coopcomp
    HEROS_JOURNEY_score_orderchaos
    PATH_FINDER_score
    FLANKER_score
    EMOTIONS_GAME_score
    ANALOGICAL_REASONING_score
    TOWER_OF_LONDON_score
    DIGIT_SPAN_score
    NBACK_score
    FLANKER_avg_speed
    DELEGATION_score_risk
    DELEGATION_score_responsibility
  }
  reach_requests {
    items ${REACH_REQUEST}
    nextToken
  }
  reach_settings {
    searching_distance
  }
  work_experience
  remote
  email_unsubscribed_topics
  gcm_unsubscribed_topics
  apns_unsubscribed_topics
}`
