import _ from 'lodash'
// GELBER PFEIL GIBT KORREKTE ANSWER

export const durationFocusInMs = 1000

// 0 === left && 1 === right
const leftBlue = {
  default: require('../../../../assets/challenges/flanker/game-assets/blue-left.json'),
  wrong: require('../../../../assets/challenges/flanker/game-assets/wrong-blue-left.json'),
  direction: 0
}

const rightBlue = {
  default: require('../../../../assets/challenges/flanker/game-assets/blue-right.json'),
  wrong: require('../../../../assets/challenges/flanker/game-assets/wrong-blue-right.json'),
  direction: 1
}

const leftPink = {
  default: require('../../../../assets/challenges/flanker/game-assets/red-left.json'),
  wrong: require('../../../../assets/challenges/flanker/game-assets/wrong-red-left.json'),
  direction: 0
}

const rightPink = {
  default: require('../../../../assets/challenges/flanker/game-assets/red-right.json'),
  wrong: require('../../../../assets/challenges/flanker/game-assets/wrong-red-right.json'),
  direction: 1
}

const ITEM_POOL = [
  {
    id: 1,
    level: [rightBlue, rightBlue, rightBlue, rightBlue, rightBlue],
    congruent: true,
    correct_right: true
  },
  {
    id: 2,
    level: [leftBlue, leftBlue, rightBlue, leftBlue, leftBlue],
    congruent: false,
    correct_right: true
  },
  {
    id: 3,
    level: [rightBlue, rightBlue, leftBlue, rightBlue, rightBlue],
    congruent: false,
    correct_right: false
  },
  {
    id: 4,
    level: [leftBlue, leftBlue, leftBlue, leftBlue, leftBlue],
    congruent: true,
    correct_right: false
  },
  {
    id: 5,
    level: [rightPink, rightPink, rightBlue, rightPink, rightPink],
    congruent: true,
    correct_right: true
  },
  {
    id: 6,
    level: [leftPink, leftPink, rightBlue, leftPink, leftPink],
    congruent: false,
    correct_right: false
  },
  {
    id: 7,
    level: [rightPink, rightPink, leftBlue, rightPink, rightPink],
    congruent: false,
    correct_right: true
  },
  {
    id: 8,
    level: [leftPink, leftPink, leftBlue, leftPink, leftPink],
    congruent: true,
    correct_right: false
  },
  {
    id: 9,
    level: [rightBlue, rightBlue, rightPink, rightBlue, rightBlue],
    congruent: true,
    correct_right: true
  },
  {
    id: 10,
    level: [leftBlue, leftBlue, rightPink, leftBlue, leftBlue],
    congruent: false,
    correct_right: true
  },
  {
    id: 11,
    level: [rightBlue, rightBlue, leftPink, rightBlue, rightBlue],
    congruent: false,
    correct_right: false
  },
  {
    id: 12,
    level: [leftBlue, leftBlue, leftPink, leftBlue, leftBlue],
    congruent: true,
    correct_right: false
  },
  {
    id: 13,
    level: [rightPink, rightPink, rightPink, rightPink, rightPink],
    congruent: true,
    correct_right: true
  },
  {
    id: 14,
    level: [leftPink, leftPink, rightPink, leftPink, leftPink],
    congruent: false,
    correct_right: false
  },
  {
    id: 15,
    level: [rightPink, rightPink, leftPink, rightPink, rightPink],
    congruent: false,
    correct_right: true
  },
  {
    id: 16,
    level: [leftPink, leftPink, leftPink, leftPink, leftPink],
    congruent: true,
    correct_right: false
  }
]

const CONGRUENT_ITEMS = [
  {
    id: 1,
    level: [rightBlue, rightBlue, rightBlue, rightBlue, rightBlue],
    congruent: true,
    correct_right: true
  },
  {
    id: 4,
    level: [leftBlue, leftBlue, leftBlue, leftBlue, leftBlue],
    congruent: true,
    correct_right: false
  },
  {
    id: 5,
    level: [rightPink, rightPink, rightBlue, rightPink, rightPink],
    congruent: true,
    correct_right: true
  },
  {
    id: 8,
    level: [leftPink, leftPink, leftBlue, leftPink, leftPink],
    congruent: true,
    correct_right: false
  },
  {
    id: 9,
    level: [rightBlue, rightBlue, rightPink, rightBlue, rightBlue],
    congruent: true,
    correct_right: true
  },
  {
    id: 12,
    level: [leftBlue, leftBlue, leftPink, leftBlue, leftBlue],
    congruent: true,
    correct_right: false
  },
  {
    id: 13,
    level: [rightPink, rightPink, rightPink, rightPink, rightPink],
    congruent: true,
    correct_right: true
  },
  {
    id: 16,
    level: [leftPink, leftPink, leftPink, leftPink, leftPink],
    congruent: true,
    correct_right: false
  }
]

export const TEST_QUESTIONS = [
  {
    id: 2,
    level: [leftBlue, leftBlue, rightBlue, leftBlue, leftBlue],
    congruent: false,
    correct_right: true
  },
  {
    id: 6,
    level: [leftPink, leftPink, rightBlue, leftPink, leftPink],
    congruent: false,
    correct_right: false
  },
  {
    id: 15,
    level: [rightPink, rightPink, leftPink, rightPink, rightPink],
    congruent: false,
    correct_right: true
  }
]

const NOT_CONGRUENT_ITEMS = _.filter(ITEM_POOL, (item) => item.congruent === false)

export const getRoundItems = () => {
  const firstItemSet = _.shuffle(_.concat(CONGRUENT_ITEMS, NOT_CONGRUENT_ITEMS))

  const secondItemSet = _.shuffle(_.concat(NOT_CONGRUENT_ITEMS, NOT_CONGRUENT_ITEMS))
  return _.concat(firstItemSet, secondItemSet)
}

/**
 * Returns 1 for correct answer and -1 for uncorrect answer
 * @param {*} choice
 * @param {*} correct_right
 */
export const isAnswerCorrect = (choice, correct_right) => {
  if (choice === 1) {
    if (correct_right === true) {
      return 1
    }
  }

  if (correct_right === false && choice === 0) {
    return 1
  }
  return -1
}

// ITEMS BESTEHEN AUS 32 ITEMS: DIE ERSTEN 16 ITEMS alle ITems aus dem pool random. Die restlichen alle au0er 1,4,5, 8 etc
