import { makeAutoObservable } from 'mobx'
import _ from 'lodash'
import { defaultTemplate } from '../assets/mail-template/apply'

class PartnerStore {
  constructor() {
    this.partner = {
      id: 'af2dabfc-f3ff-43a1-80cd-53ebf3da38d1',
      name: 'Aivy UG'
    }
    this.refUserId = undefined
    this.logo = ''
    this.logo_alt = ''
    this.career_site_url = ''
    this.logo_ratio = {
      height: 0,
      width: 0
    }
    this.usedAlgorithm = 'matchMeIfYouCan'
    this.custom_data_policy = false
    this.challengeConfigs = {}
    this.custom_apply_mail_template = defaultTemplate
    this.components = {
      challengeTinder: true,
      challengeLevelUp: true,
      successStrength: true,
      successPerspective: true,
      drawerCareer: true,
      applyWithID: true,
      newsletter: true,
      perspectiveTinder: true,
      randomChallenges: true,
      buyPDF: true,
      introSlider: true,
      completedScreen: true
    }
    this.name = ''
    this.display_name = ''
    this.partnerID = ''
    this.challenges = []

    makeAutoObservable(this)
  }

  setAppSettings(partnerSettings) {
    if (!partnerSettings) return
    const {
      id,
      name,
      components,
      usedAlgorithm,
      minMatchingScore,
      logo,
      logo_alt,
      career_site_url,
      display_name,
      logo_ratio,
      challenges,
      custom_data_policy,
      custom_apply_mail_template
    } = partnerSettings.data.getPartner

    this.challenges = JSON.parse(challenges)
    this.name = name
    this.challengeConfigs = partnerSettings.challengeConfigs
    this.display_name = display_name
    this.partner = { id, name, minMatchingScore }
    this.logo = logo
    this.career_site_url = career_site_url
    this.logo_alt = logo_alt
    this.custom_data_policy = custom_data_policy
    this.partnerID = id

    if (_.isNull(components) === false) {
      this.components = Object.assign(this.components, JSON.parse(components))
    }
    if (_.isNull(logo_ratio) === false) {
      this.logo_ratio = JSON.parse(logo_ratio)
    }
    if (_.isNull(usedAlgorithm) === false) {
      this.usedAlgorithm = usedAlgorithm
    }
    if (_.isNull(custom_apply_mail_template) === false) {
      this.custom_apply_mail_template = custom_apply_mail_template
    }
  }

  clear() {
    this.partner = {
      id: 'af2dabfc-f3ff-43a1-80cd-53ebf3da38d1',
      name: 'Aivy UG'
    }
    this.refUserId = undefined
    this.logo = ''
    this.logo_alt = ''
    this.career_site_url = ''
    this.logo_ratio = {
      height: 0,
      width: 0
    }
    this.usedAlgorithm = 'matchMeIfYouCan'
    this.custom_data_policy = false
    this.custom_apply_mail_template = defaultTemplate
    this.components = {
      challengeTinder: true,
      challengeLevelUp: true,
      successStrength: true,
      successPerspective: true,
      drawerCareer: true,
      applyWithID: true,
      newsletter: true,
      perspectiveTinder: true,
      randomChallenges: true,
      buyPDF: true,
      introSlider: true,
      completedScreen: true
    }
    this.name = ''
    this.display_name = ''
    this.partnerID = ''
    this.challenges = []
  }
}

export default PartnerStore
