import React from 'react'
import { StyleSheet, Pressable, Image } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'

const styles = StyleSheet.create({
  button: {
    width: 72,
    height: 72,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 36,
    shadowColor: colors.rockGrey,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.1,
    shadowRadius: 28,
    elevation: 8
  },
  buttonIcon: {
    height: 32,
    width: 32,
    resizeMode: 'contain'
  }
})

const Button = ({ onPress, disabled, source, backgroundColor }) => (
  <Pressable
    style={[styles.button, { backgroundColor }]}
    onPress={() => onPress()}
    disabled={disabled}
  >
    <Image style={styles.buttonIcon} source={{ uri: source }} />
  </Pressable>
)

Button.propTypes = {
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  source: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired
}

export default Button
