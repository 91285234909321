import React, { useRef, useState } from 'react'
import { StyleSheet, Animated, TouchableOpacity, View, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'

import Typo from '../../../components/Typo'
import colors from '../../../theme/colors'
import { getSource } from './data/assets'

const styles = StyleSheet.create({
  cellButton: {
    flex: 1,
    borderRadius: 100
  }
})

const CellElement = ({ index, initialState, levelCompleted, belongsToCorrectPath, onRotate }) => {
  const [rotations, setRotations] = useState(initialState || 0)
  const anim = useRef(new Animated.Value(rotations)).current

  if (index === 0) return null

  if ([1, 2].includes(index)) {
    return (
      <View
        underlayColor='#e9e9e9'
        style={[
          styles.cellButton,
          {
            borderRadius: 100,
            justifyContent: 'center',
            alignItems: 'center'
          },
          index === 1 ? { backgroundColor: colors.safetyOrange } : {},
          index === 2 ? { backgroundColor: colors.brightBlue } : {}
        ]}
      >
        <Typo.ButtonWhite>
          {(index === 1 && I18n.get('global.challenge.start')) ||
            (index === 2 && I18n.get('global.challenge.end'))}
        </Typo.ButtonWhite>
      </View>
    )
  }

  return (
    <TouchableOpacity
      disabled={levelCompleted || [6, 7, 8].includes(index)}
      activeOpacity={0.8}
      onPress={() => {
        const rotate = rotations + 1

        Animated.spring(anim, {
          toValue: rotate,
          useNativeDriver: Platform.OS !== 'web'
        }).start()

        onRotate(index === 3 ? rotate % 2 : rotate % 4)
        setRotations(rotate)
      }}
      style={[
        styles.cellButton,
        belongsToCorrectPath && levelCompleted ? { backgroundColor: 'pink' } : {}
      ]}
    >
      <Animated.Image
        source={getSource(index, belongsToCorrectPath && levelCompleted)}
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          transform: [
            {
              rotate: anim.interpolate({
                inputRange: [0, 1],
                outputRange: ['0deg', '90deg']
              })
            }
          ]
        }}
      />
    </TouchableOpacity>
  )
}

CellElement.propTypes = {
  index: PropTypes.number.isRequired,
  initialState: PropTypes.number.isRequired,
  levelCompleted: PropTypes.bool.isRequired,
  belongsToCorrectPath: PropTypes.bool.isRequired,
  onRotate: PropTypes.func.isRequired
}

export default CellElement
