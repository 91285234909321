import React, { useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  Text,
  TouchableOpacity,
  Platform,
  UIManager,
  LayoutAnimation
} from 'react-native'
import Svg, { Path } from 'react-native-svg'
import { rowCenter } from '../styles'
import colors from '../theme/colors'

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true)
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightGreyBg,
    paddingHorizontal: 7,
    paddingVertical: 6,
    borderRadius: 23,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start'
  },
  imageContainerWrapper: {
    height: 32,
    width: 32,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff'
  },
  image: {
    height: 30,
    width: 30,
    borderRadius: 16,
    resizeMode: 'contain'
  },
  companyText: {
    fontFamily: 'fira-sans-semi-bold',
    letterSpacing: 0.9,
    fontSize: 16
  }
})

const SpaceSwitchHeader = () => {
  const [expanded, setExpanded] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
      setExpanded(!expanded)
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (open) {
    return (
      <View
        style={{
          backgroundColor: colors.lightGreyBg,
          paddingTop: 10,
          paddingLeft: 12,
          paddingBottom: 12,
          borderRadius: 24,
          width: '100%'
        }}
      >
        <TouchableOpacity
          onPress={() => {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
            setOpen(!open)
          }}
          style={rowCenter}
        >
          <Image style={{ height: 48, width: 48, backgroundColor: '#fff', borderRadius: 24 }} />
          <View style={{ marginLeft: 12 }}>
            <Text
              style={{
                fontFamily: 'fira-sans-regular',
                fontSize: 12,
                color: '#000',
                opacity: 0.5,
                lineHeight: 26
              }}
            >
              Aktueller Space
            </Text>
            <Text style={styles.companyText}>Beiersdorf</Text>
          </View>
          <View style={{ marginLeft: 18 }}>
            <Svg width={15} height={9} xmlns='http://www.w3.org/2000/svg'>
              <Path
                d='M.299 6.439a.957.957 0 0 0-.228.33C.024 6.895 0 7.029 0 7.17c0 .2.047.38.142.542a1.046 1.046 0 0 0 .912.519c.303 0 .56-.107.77-.322l5.605-5.747h-.715l5.59 5.747c.21.215.466.322.77.322.194 0 .37-.047.527-.141.162-.095.29-.22.385-.378.095-.162.142-.343.142-.542a.965.965 0 0 0-.307-.731L7.917.385a1.338 1.338 0 0 0-.401-.283A.988.988 0 0 0 7.068 0c-.32 0-.603.128-.85.385L.299 6.44Z'
                fill='#000'
                fillRule='nonzero'
              />
            </Svg>
          </View>
        </TouchableOpacity>
        <View style={{ marginTop: 16, marginLeft: 6, flexDirection: 'row' }}>
          <View style={{ alignItems: 'center' }}>
            <Image
              style={{
                height: 98,
                width: 98,
                backgroundColor: '#fff',
                borderRadius: 98 / 2,
                borderWidth: 3,
                borderColor: colors.brightBlue,
                marginBottom: 12
              }}
            />
            <View style={rowCenter}>
              <Svg width={11} height={11} xmlns='http://www.w3.org/2000/svg'>
                <Path
                  d='M4.341 10.454c.48 0 .842-.184 1.087-.552l5.307-7.999c.088-.13.154-.258.196-.385.042-.13.063-.255.063-.374 0-.33-.115-.602-.345-.816A1.161 1.161 0 0 0 9.809 0c-.23 0-.425.046-.586.138-.157.088-.307.242-.448.46L4.318 7.596l-2.23-2.674a1.15 1.15 0 0 0-.91-.42c-.34 0-.622.11-.844.328A1.105 1.105 0 0 0 0 5.652c0 .15.023.292.069.426.05.13.14.266.27.408l2.962 3.49c.272.318.619.478 1.04.478Z'
                  fill='#4D7BF3'
                  fillRule='nonzero'
                />
              </Svg>
              <Text
                style={{
                  marginLeft: 4,
                  fontFamily: 'fira-sans-bold',
                  color: colors.brightBlue,
                  lineHeight: 26,
                  fontSize: 15
                }}
              >
                Beiersdorf
              </Text>
            </View>
          </View>
          <View style={{ marginLeft: 24, alignItems: 'center' }}>
            <Image
              style={{
                height: 98,
                width: 98,
                backgroundColor: '#fff',
                borderRadius: 98 / 2,
                marginBottom: 12
              }}
            />
            <Text style={{ fontFamily: 'fira-sans-regular', fontSize: 15, lineHeight: 26 }}>
              Lufthansa
            </Text>
          </View>
        </View>
      </View>
    )
  }

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        setOpen(!open)
      }}
    >
      <View style={styles.imageContainerWrapper}>
        <Image
          style={styles.image}
          source={{
            uri: 'https://aivy-user-content-production.s3.eu-central-1.amazonaws.com/public/partner-logos/af2dabfc-f3ff-43a1-80cd-53ebf3da38d1.png?random_number=1663834658560'
          }}
        />
      </View>
      {expanded && (
        <View style={rowCenter}>
          <View style={{ marginLeft: 12, marginRight: 6 }}>
            <Text style={styles.companyText}>Aivy</Text>
          </View>
          <Svg width={15} height={9} xmlns='http://www.w3.org/2000/svg'>
            <Path
              d='M7.068 8.231c.162-.005.312-.036.448-.094.141-.063.275-.157.4-.283l5.905-6.062a.982.982 0 0 0 .307-.73 1.046 1.046 0 0 0-.527-.92A1.004 1.004 0 0 0 13.074 0c-.293 0-.55.107-.77.322l-5.59 5.755h.715L1.824.322A1.069 1.069 0 0 0 1.054 0C.86 0 .68.047.518.142a1.064 1.064 0 0 0-.519.92c0 .146.024.28.07.4.053.12.13.234.229.338l5.92 6.054c.257.252.54.377.849.377Z'
              fill='#000'
              fillRule='nonzero'
            />
          </Svg>
        </View>
      )}
    </TouchableOpacity>
  )
}

SpaceSwitchHeader.propTypes = {}

SpaceSwitchHeader.defaultProps = {}

export default SpaceSwitchHeader
