import { makeAutoObservable, runInAction } from 'mobx'
import { mutation, query } from '../graphql'
import { notifyBugsnag } from '../utils/bugsnag'

class AnswersStore {
  constructor() {
    this.answers = []
    makeAutoObservable(this)
  }

  setAnswers(answers) {
    if (!answers) return
    this.answers = answers
  }

  getAnswerForChallenge(id) {
    return this.answers.filter((a) => a.exam_id === id)[0]
  }

  async postAnswer(data, career_id = undefined) {
    const answer = await mutation('createAnswer', data)
    const resFromHelperControl = await query({
      query: 'helperControl',
      variables: {
        action: 'listAnswersWithAivyOutput',
        user_id: data.owner,
        career_id,
        challenge_id: data.exam_id
      }
    })

    const parsedRes = JSON.parse(resFromHelperControl.data.helperControl)

    const aivy_output = parsedRes?.body?.listAnswersWithAivyOutput?.res[0]?.aivy_output

    if (aivy_output === null || aivy_output === undefined) {
      throw new Error('Error while sending the challenge')
    }

    let a = answer.data?.createAnswer
    a = {
      ...a,
      aivy_output,
      user_input: JSON.parse(a.user_input)
    }

    runInAction(() => {
      this.answers.push(a)
    })

    return a
  }

  async deleteAnswer(id) {
    await mutation('deleteAnswer', { id })
    runInAction(() => {
      this.answers.find((e, index) => {
        if (e.id === id) {
          this.answers.splice(index, 1)
        }
        return e.id === id
      })
    })
  }

  async updateAnswer({ data, career_id, owner }) {
    try {
      const result = await mutation('updateAnswer', data)
      let r = result.data.updateAnswer

      const resFromHelperControl = await query({
        query: 'helperControl',
        variables: {
          action: 'listAnswersWithAivyOutput',
          user_id: owner,
          career_id: career_id || undefined,
          challenge_id: r.exam_id
        }
      })

      const parsedRes = JSON.parse(resFromHelperControl.data.helperControl)

      const aivy_output = parsedRes?.body?.listAnswersWithAivyOutput?.res[0]?.aivy_output

      r = {
        ...r,
        aivy_output,
        user_input: JSON.parse(r.user_input)
      }

      runInAction(() => {
        this.answers = this.answers.filter((answer) => answer.id !== r.id)
        this.answers.push(r)
      })

      return r
    } catch (error) {
      notifyBugsnag(error)
    }
  }

  clear() {
    this.answers = []
  }
}

export default AnswersStore
