import Constants from 'expo-constants'
import { manufacturer, modelName } from 'expo-device'
import * as Localization from 'expo-localization'
import { Platform } from 'react-native'

import { query } from '../graphql'
import { notifyBugsnag } from './bugsnag'

// TODO:
// handle device change

export const updateEndpoint = async ({ userStore, userAttributes, location }) => {
  if (!userStore) {
    notifyBugsnag(new Error('update endpoint - !userStore'))
    return
  }

  const { userID, email, isAnonym } = userStore
  // const { push_token } = userStore.userProfile

  const user_attributes = JSON.stringify({
    ...(userAttributes || {}),
    frontend: ['aivy-universal-app']
  })

  const demographic = {
    app_version: `${Constants.expoConfig.version}`,
    locale: Localization.locale,
    make: manufacturer,
    model: modelName,
    platform: Platform.OS
  }

  try {
    const variables = {
      action: 'updateEndpoint',
      user_id: userID,
      user_attributes,
      demographic,
      location
    }

    if (!isAnonym) {
      await query({
        query: 'pinpointControl',
        variables: {
          ...variables,
          address: email,
          channel_type: 'EMAIL'
        }
      })
    }

    // if (push_token && push_token[0]) {
    //   await query({
    //     query: 'pinpointControl',
    //     variables: {
    //       ...variables,
    //       address: push_token[0],
    //       channel_type: Platform.OS === 'ios' ? 'APNS' : 'GCM'
    //     }
    //   })
    // }

    await userStore.fetchPinpointEndpoints()
  } catch (err) {
    notifyBugsnag(err)
  }
}
