import React from 'react'
import { View, StyleSheet, Image } from 'react-native'

import Typo from './Typo'

import metrics from '../theme/metrics'

const styles = StyleSheet.create({})

const StrengthEmptySection = () => (
  <View style={[styles.container, { paddingVertical: 24 }]}>
    <Image
      source={require('../assets/images/illustrations/discover.png')}
      style={{
        ...metrics.calculateResponsiveDimensions({ height: 160, width: 177 }),
        alignSelf: 'center'
      }}
    />
    <Typo.T1LightBlack center marginTop={40}>
      Absolviere weitere Challenges, um diesen Stärkenbereich freizuschalten.
    </Typo.T1LightBlack>
  </View>
)

export default StrengthEmptySection
