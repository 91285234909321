import React from 'react'
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { BlurView } from 'expo-blur'

import Typo from './Typo'
import colors from '../theme/colors'

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    height: 42,
    width: '100%',
    maxWidth: 400,
    borderRadius: 64,
    flexDirection: 'row'
  },
  blurBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  pill: {
    width: '50%',
    height: 42,
    justifyContent: 'center',
    alignItems: 'center'
  },
  activePill: {
    backgroundColor: 'white',
    borderRadius: 64,
    shadowColor: colors.brightBlue,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.16,
    shadowRadius: 53,
    elevation: 2
  }
})

const Switch = ({ left, right }) => (
  // const { success, error, notice } = useContext(NotificationContext)

  // useEffect(() => {
  //  const random = Math.floor(Math.random() * 3)
  //  const notifications = [success, error, notice]
  //  const messages = [
  //    'Benachrichtung wird angezeigt.',
  //    'Leider ist ein Fehler aufgetreten.',
  //    'Strength ID copied!'
  //  ]

  //  setTimeout(() => notifications[random](messages[random]), 2 * 1000)
  // }, [success, error, notice])

  <View style={[styles.container, Platform.OS !== 'android' ? { overflow: 'hidden' } : {}]}>
    {Platform.OS !== 'android' && (
      <BlurView style={styles.blurBackground} intensity={30} tint='default' />
    )}
    {Platform.OS === 'android' && (
      <View
        style={[
          styles.blurBackground,
          { backgroundColor: colors.lightGrey, opacity: 0.9, borderRadius: 64 }
        ]}
      />
    )}
    <TouchableOpacity
      onPress={left.onPress}
      style={[styles.pill, left.active ? styles.activePill : {}]}
    >
      <Typo.H4Black translate>{left.label}</Typo.H4Black>
    </TouchableOpacity>
    <TouchableOpacity
      onPress={right.onPress}
      style={[styles.pill, right.active ? styles.activePill : {}]}
    >
      <Typo.H4Black translate>{right.label}</Typo.H4Black>
    </TouchableOpacity>
  </View>
)

Switch.propTypes = {
  left: PropTypes.object.isRequired,
  right: PropTypes.object.isRequired
}

export default Switch
