import React, { useContext, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'
import { observer } from 'mobx-react'

import { Button, Typo } from '../../components'
import { replaceTranslationKey } from '../../utils/helpers'
import { AuthContext, NotificationContext } from '../../context'
import colors from '../../theme/colors'
import metrics from '../../theme/metrics'
import { trackEvent } from '../../utils/tracking'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  contentContainerStyle: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 24
  },
  footer: {
    padding: 24,
    paddingBottom: 48
  }
})

const customMDStyles = StyleSheet.create({
  text: {
    fontFamily: 'fira-sans-light',
    fontSize: 16,
    lineHeight: 22,
    color: colors.darkBlack,
    textAlign: 'center'
  },
  strong: {
    fontSize: 16,
    lineHeight: 22,
    fontFamily: 'fira-sans-regular',
    textAlign: 'center'
  }
})

const Newsletter = ({ navigation }) => {
  const { rootStore } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const { success } = useContext(NotificationContext)

  const subscribeToNewsletter = async () => {
    setIsLoading(true)
    await rootStore.spaceStore.updateSpace({ newsletter: true })
    setIsLoading(false)
    trackEvent('NewsletterSubscribed')
    success(I18n.get('global.newsletter.success'))
    setTimeout(() => {
      navigation.goBack()
    }, 1500)
  }

  return (
    <View style={styles.container}>
      <View style={styles.contentContainerStyle}>
        <Image
          source={require('../../assets/images/newsletter-image.png')}
          style={{
            ...metrics.calculateResponsiveDimensions({ height: 80, width: 75 }),
            marginTop: 48,
            marginBottom: 24,
            alignSelf: 'center'
          }}
        />
        <Typo.H2Blue center>
          {' '}
          {replaceTranslationKey(
            I18n.get('newsletter.body_1'),
            '{{{companyName}}}',
            rootStore.appSettingsStore.partnerDisplayName
          )}
        </Typo.H2Blue>
        <Typo.Markdown style={customMDStyles} marginTop={8}>
          {replaceTranslationKey(
            I18n.get('newsletter.body_2'),
            '{{{companyName}}}',
            rootStore.appSettingsStore.partnerDisplayName
          )}
        </Typo.Markdown>
      </View>
      <View style={styles.footer}>
        <Button
          isLoading={isLoading}
          title='newsletter.button.yes'
          onPress={subscribeToNewsletter}
        />
        <TouchableOpacity onPress={() => navigation.goBack()} style={{ marginTop: 24 }}>
          <Typo.ButtonBlue center>{I18n.get('newsletter.button.no')}</Typo.ButtonBlue>
        </TouchableOpacity>
      </View>
    </View>
  )
}

Newsletter.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default observer(Newsletter)
