import React from 'react'
import { ActivityIndicator, View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const Loader = ({ containerStyle }) => (
  <View style={[styles.container, containerStyle]}>
    <ActivityIndicator />
  </View>
)

Loader.propTypes = {
  containerStyle: PropTypes.object
}

Loader.defaultProps = {
  containerStyle: {}
}

export default Loader
