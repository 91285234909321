import React, { useEffect, useState, useRef, useContext } from 'react'
import { SafeAreaView, ActivityIndicator, View } from 'react-native'
import { useAssets } from 'expo-asset'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import Intro from '../../../components/challenge/Intro'
import Instruction from '../../../components/challenge/Instruction'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import Questionnaire from '../../../components/challenge/Questionnaire'
import Countdown from '../../../components/challenge/Countdown'
import ScreenContainer from '../../../components/challenge/ScreenContainer'
import Question from './components/Question'
import ChallengeContainer from '../ChallengeContainer'
import {
  getRandomMainQuestions,
  getRandomTestQuestions,
  EMOTIONS_IMAGES,
  TIME_TO_OBSERVE
} from './data'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import { AuthContext } from '../../../context'
import { trackEvent } from '../../../utils/tracking'

const EmotionsGame = ({ testRound, submit }) => {
  const { rootStore } = useContext(AuthContext)
  const MAIN_QUESTIONS = getRandomMainQuestions()
  const TEST_QUESTIONS = getRandomTestQuestions(MAIN_QUESTIONS)

  const [showCountdown, setShowCountdown] = useState(true)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [answers, setAnswers] = useState([])
  const [questions] = useState(testRound ? TEST_QUESTIONS : MAIN_QUESTIONS)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const [assets] = useAssets(EMOTIONS_IMAGES)

  const questionnaire = useRef(null)

  useEffect(() => {
    if (!buttonsDisabled) {
      return
    }
    setTimeout(() => {
      setButtonsDisabled(false)
    })
  }, [buttonsDisabled])

  function choicePressed(item, { value, elapsedTime }) {
    if (answers[currentQuestionIndex]) return

    const time_needed_in_ms_adjusted =
      // eslint-disable-next-line no-nested-ternary
      elapsedTime > 5000 ? 5000 : elapsedTime < 300 ? 300 : elapsedTime

    setAnswers(
      Object.assign([...answers], {
        [currentQuestionIndex]: {
          decision: value,
          success: item.emotion === value,
          round: currentQuestionIndex + 1,
          id: item.id,
          emotion: item.emotion,
          time_needed_in_ms: elapsedTime,
          item_deliberation: 5000 - time_needed_in_ms_adjusted,
          pause_in_ms: rootStore.pauseStore.sum
        }
      })
    )

    setTimeout(() => {
      questionnaire.current && questionnaire.current?.pressFooterPrimaryButton()
      setButtonsDisabled(true)
    }, 150)
    rootStore.pauseStore.reset()
  }

  const submitAnswer = async () => {
    const tempAnswer = answers.reduce((result, answer, idx) => {
      result[`round_${idx + 1}`] = answer
      return result
    }, {})

    submit(tempAnswer)
  }

  if (!assets) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: '#fff',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row'
        }}
      >
        <ActivityIndicator style={{ marginRight: 4 }} />
      </View>
    )
  }

  if (showCountdown) {
    return (
      <Countdown
        onPress={() => {
          setShowCountdown(false)
        }}
      />
    )
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
      <ScreenContainer>
        <ChallengeHeader
          title={
            testRound
              ? I18n.get('global.challenge.testround')
              : `${currentQuestionIndex + 1}/${questions.length}`
          }
          onPress={() => {
            trackEvent('ChallengePaused', {
              exam_id: 'EMOTIONS_GAME'
            })
            rootStore.pauseStore.start()
          }}
        />
        <Questionnaire
          ref={questionnaire}
          questions={questions}
          delayTransition={300}
          renderQuestion={(question) => (
            <Question
              pause={rootStore.pauseStore.active}
              currentQuestionId={questions[currentQuestionIndex].id}
              question={question}
              onPress={(choice) => choicePressed(question, choice)}
              timeToObserve={TIME_TO_OBSERVE}
            />
          )}
          onPrevOrNext={(idx) => setCurrentQuestionIndex(idx)}
          onSubmit={submitAnswer}
          showFooter={false}
          hideProgress
        />
      </ScreenContainer>
    </SafeAreaView>
  )
}

EmotionsGame.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

IntroScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

InstructionScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

TestRoundCompleteScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const EmotionsGameChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={EmotionsGame}
    EXAM={ALL_CHALLENGES.EMOTIONS_GAME}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: InstructionScreen, name: 'InstructionScreen' }
    ]}
    MIDDLE_SCREENS={[{ screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' }]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

EmotionsGameChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

EmotionsGameChallenge.defaultProps = { onChallengeComplete: () => {} }

export default EmotionsGameChallenge
