import React from 'react'
import { View, StyleSheet, Image, ImageBackground } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../theme/colors'
import Typo from './Typo'
import Button from './Button'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: colors.white,
    borderRadius: 18,
    shadowColor: colors.mediumGrey,
    shadowOffset: {
      width: 0,
      height: 15
    },
    shadowOpacity: 0.2,
    shadowRadius: 28,
    elevation: 14
  },
  matchingIllustration: {
    height: 80,
    width: 80,
    resizeMode: 'contain',
    marginRight: 24
  },
  header: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 20,
    alignItems: 'center'
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(24, 32, 51, 0.5)'
  }
})

const PerspectiveListItem = ({ containerStyle, title, matchingScore, onPress, photoUrl }) => {
  const getMatchingScoreAssets = () => {
    if (matchingScore <= 40) {
      return {
        text: 'global.perspective_list_item.fits_less',
        image: require('../assets/images/illustrations/matching-2.png')
      }
    }

    if (matchingScore <= 60) {
      return {
        text: 'global.perspective_list_item.fits_medium',
        image: require('../assets/images/illustrations/matching-3.png')
      }
    }

    if (matchingScore <= 80) {
      return {
        text: 'global.perspective_list_item.fits_good',
        image: require('../assets/images/illustrations/matching-4.png')
      }
    }

    if (matchingScore <= 100) {
      return {
        text: 'global.perspective_list_item.fits_very_good',
        image: require('../assets/images/illustrations/matching-5.png')
      }
    }
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.header}>
        <Image
          style={styles.matchingIllustration}
          source={getMatchingScoreAssets(matchingScore).image}
        />
        <View>
          <Typo.T2Black translate>global.perspective_list_item.header</Typo.T2Black>
          <Typo.H4Black translate>{getMatchingScoreAssets(matchingScore).text}</Typo.H4Black>
        </View>
      </View>
      <ImageBackground
        source={{ uri: photoUrl ? `${photoUrl}` : '' }}
        style={{
          backgroundColor: colors.softGrey,
          borderBottomLeftRadius: 18,
          borderBottomRightRadius: 18,
          flex: 1,
          width: '100%',
          overflow: 'hidden'
        }}
        imageStyle={{
          borderBottomLeftRadius: 18,
          borderBottomRightRadius: 18,
          overflow: 'hidden',
          height: '100%',
          width: '100%'
        }}
      >
        <View style={styles.overlay} />
        <View style={{ flex: 1, padding: 20, justifyContent: 'space-between' }}>
          <Typo.H2White style={{ width: '80%' }}>{title}</Typo.H2White>
          <View
            style={{
              alignItems: 'flex-end',
              marginTop: 20
            }}
          >
            {/* <TouchableOpacity onPress={() => alert('TODO')}>
              <Typo.ButtonWhite translate>global.perspective_list_item.no_action</Typo.ButtonWhite>
            </TouchableOpacity> */}
            <Button onPress={onPress} title='global.perspective_list_item.more_action' />
          </View>
        </View>
      </ImageBackground>
    </View>
  )
}

PerspectiveListItem.propTypes = {
  containerStyle: PropTypes.object,
  title: PropTypes.string.isRequired,
  matchingScore: PropTypes.number.isRequired,
  onPress: PropTypes.func.isRequired,
  photoUrl: PropTypes.string
}

PerspectiveListItem.defaultProps = {
  containerStyle: {},
  photoUrl: null
}

export default PerspectiveListItem
