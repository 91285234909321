import { makeAutoObservable } from 'mobx'

class HardFactsStore {
  constructor() {
    this.x = [] // sample
    makeAutoObservable(this)
  }

  clear() {
    this.x = [] // sample
    // TODO: CLEAR STORE
  }
}

export default HardFactsStore
