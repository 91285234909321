import React from 'react'
import { StyleSheet, View } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import NormalDistributionCurve from './components/NormalDistributionCurve'
import ScienceText from './components/ScienceText'
import Typo from '../../Typo'
import Progress from '../../strengths/Progress'
import colors from '../../../theme/colors'

const customMDStyles = StyleSheet.create({
  text: {
    fontFamily: 'fira-sans-regular',
    fontSize: 14,
    lineHeight: 22,
    color: colors.darkBlack
  },
  strong: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'fira-sans-medium'
  }
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'red'
  },
  oval: {
    height: 12,
    width: 12,
    borderRadius: 6
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: '#B6C7F2',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5
  },
  examScoreStyle: {
    fontSize: 16,

    textTransform: 'uppercase',
    color: colors.darkBlack
  },
  insightTextStyle: {
    fontSize: 14,
    color: colors.darkBlack
  },
  insightTextStyleBold: {
    fontSize: 14,
    color: colors.darkBlack
  },
  scienceTextStyle: {
    fontSize: 14,
    color: colors.darkBlack
  }
})

const RiasecImagesResult = ({ answer }) => {
  const diff_hc_prozent = answer.aivy_output.translatedzScores.diff_hc
  const elevation_prozent = answer.aivy_output.translatedzScores.elevation

  return (
    <View>
      <View style={[styles.card, { marginTop: 32, marginBottom: 16 }]}>
        <Typo.H4Black marginBottom={16}>
          {`${I18n.get('global.challenge.result.specialist')} ${I18n.get('global.or')} ${I18n.get(
            'global.challenge.result.generalist'
          )}?`}
        </Typo.H4Black>
        <Progress
          hideMoreInfo
          progressLeftTitle={I18n.get('global.challenge.result.specialist')}
          progressRightTitle={I18n.get('global.challenge.result.generalist')}
          max={50}
          value={diff_hc_prozent - 50}
          fillColor={colors.primaryGreen}
        />
        <Typo.Markdown style={customMDStyles}>
          {diff_hc_prozent <= 50
            ? I18n.get('challenge.riasec_images.diff_hc_prozent_2')
            : I18n.get('challenge.riasec_images.diff_hc_prozent_1')}
        </Typo.Markdown>
      </View>
      <NormalDistributionCurve
        percent={Math.round(elevation_prozent)}
        title={I18n.get('challenge.riasec_images.result.normal_distribution_curve.title')}
        description={`${
          elevation_prozent <= 50
            ? I18n.get('challenge.riasec_images.elevation_prozent_1')
            : I18n.get('challenge.riasec_images.elevation_prozent_2')
        }`}
        labels={{
          left: I18n.get('challenge.riasec_images.result.scale_endpoint.left'),
          right: I18n.get('challenge.riasec_images.result.scale_endpoint.right')
        }}
      />
      <ScienceText containerStyle={{ marginTop: 16 }} exam_id='RIASEC_IMAGES' />
    </View>
  )
}

RiasecImagesResult.propTypes = {
  answer: PropTypes.object.isRequired
}

export default RiasecImagesResult
