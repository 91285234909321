import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import PropTypes from 'prop-types'

const CloseButton = ({ onPress, style, color, size }) => (
  <TouchableOpacity
    hitSlop={{ top: 30, left: 100, bottom: 30, right: 100 }}
    onPress={onPress}
    style={style}
  >
    <Ionicons name='md-close' size={size || 30} color={color || 'white'} style={style} />
  </TouchableOpacity>
)

CloseButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
}

export default CloseButton
