import React from 'react'
import { StyleSheet, View } from 'react-native'
import { I18n } from 'aws-amplify'
import PropTypes from 'prop-types'

import ScienceText from './components/ScienceText'
import NormalDistributionCurve from './components/NormalDistributionCurve'
import colors from '../../../theme/colors'
import Typo from '../../Typo'
import Progress from '../../strengths/Progress'

const styles = StyleSheet.create({
  card: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: '#B6C7F2',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5
  }
})

const BigFiveResult = ({ answer }) => {
  const elevation_prozent = answer.aivy_output.translatedzScores.elevation

  const o_score_prozent = answer.aivy_output.translatedzScores.o_score

  const c_score_prozent = answer.aivy_output.translatedzScores.c_score

  const e_score_prozent = answer.aivy_output.translatedzScores.e_score

  const a_score_prozent = answer.aivy_output.translatedzScores.a_score

  const n_score_prozent = answer.aivy_output.translatedzScores.n_score

  return (
    <View>
      <View style={[styles.card, { marginTop: 32 }]}>
        <Typo.H4Black translate marginBottom={16}>
          strength.personality
        </Typo.H4Black>
        <Progress
          progressTitle={I18n.get('challenge.big_five.result.openness')}
          hideMoreInfo
          progressLeftTitle={I18n.get('challenge.big_five.result.openness.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.openness.right_adjective')}
          max={50}
          value={o_score_prozent - 50}
          fillColor={colors.aquaMarine}
        />
        <Progress
          containerStyle={{ marginTop: 32 }}
          progressTitle={I18n.get('challenge.big_five.result.diligence')}
          hideMoreInfo
          progressLeftTitle={I18n.get('challenge.big_five.result.diligence.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.diligence.right_adjective')}
          max={50}
          value={c_score_prozent - 50}
          fillColor={colors.mango}
        />
        <Progress
          containerStyle={{ marginTop: 32 }}
          progressTitle={I18n.get('challenge.big_five.result.extraversion')}
          hideMoreInfo
          progressLeftTitle={I18n.get('challenge.big_five.result.extraversion.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.extraversion.right_adjective')}
          max={50}
          value={e_score_prozent - 50}
          fillColor={colors.carnationPink}
        />
        <Progress
          containerStyle={{ marginTop: 32 }}
          progressTitle={I18n.get('challenge.big_five.result.compatibility')}
          hideMoreInfo
          progressLeftTitle={I18n.get('challenge.big_five.result.compatibility.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.compatibility.right_adjective')}
          max={50}
          value={a_score_prozent - 50}
          fillColor={colors.softBlue}
        />
        <Progress
          containerStyle={{ marginTop: 32 }}
          progressTitle={I18n.get('challenge.big_five.result.emotionality')}
          hideMoreInfo
          progressLeftTitle={I18n.get('challenge.big_five.result.emotionality.left_adjective')}
          progressRightTitle={I18n.get('challenge.big_five.result.emotionality.right_adjective')}
          max={50}
          value={n_score_prozent - 50}
          fillColor={colors.rockGrey}
        />
      </View>
      <NormalDistributionCurve
        containerStyle={{ marginTop: 16 }}
        title={I18n.get('challenge.big_five.result.normal_distribution_curve.title')}
        percent={Math.round(elevation_prozent)}
        description={`${
          elevation_prozent <= 50
            ? I18n.get('challenge.big_five.result.elevation_prozent_1')
            : I18n.get('challenge.big_five.result.elevation_prozent_2')
        }`}
        labels={{
          left: I18n.get('challenge.big_five.result.scale_endpoint.left'),
          right: I18n.get('challenge.big_five.result.scale_endpoint.right')
        }}
      />
      <ScienceText containerStyle={{ marginTop: 16 }} exam_id='BIG_FIVE' />
    </View>
  )
}

BigFiveResult.propTypes = {
  answer: PropTypes.object.isRequired
}

export default BigFiveResult
