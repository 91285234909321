const APP_CONTENT_PREFIX = 'https://react-native-app-content.s3.eu-central-1.amazonaws.com'

export const assets = [
  `${APP_CONTENT_PREFIX}/flanker_v2/background-clouds.png`,
  `${APP_CONTENT_PREFIX}/flanker_v2/focus.png`,
  `${APP_CONTENT_PREFIX}/flanker_v2/button-icons/arrow-left.png`,
  `${APP_CONTENT_PREFIX}/flanker_v2/button-icons/arrow-right.png`,
  `${APP_CONTENT_PREFIX}/flanker_v2/button-icons/correct.png`,
  `${APP_CONTENT_PREFIX}/flanker_v2/button-icons/wrong.png`
]
