/* eslint-disable */
import Bugsnag from '@bugsnag/js'
import { Amplify } from 'aws-amplify'
import 'setimmediate'
import awsconfig from './src/aws-exports'
Amplify.configure({ ...awsconfig, Analytics: { autoSessionRecord: false } })
import * as ErrorRecovery from 'expo-error-recovery'
import React from 'react'
import { AppRegistry } from 'react-native'
// import AppPlatformSwitch from './src/AppPlatformSwitch'

import { ErrorBoundaryFallBackComponent } from './src/components'
import { options } from './src/utils/bugsnag'
import RootWrapper from './src/wrappers/RootWrapper'

// Amplify.Logger.LOG_LEVEL = 'DEBUG'

/**
 * https://github.com/expo/expo/issues/7996#issuecomment-619229359
 */
if (!global.setImmediate) {
  global.setImmediate = setTimeout
}

Bugsnag.start(options)

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const addBasisRootStyling = (rootTag) => {
  if (!rootTag) return
  rootTag.style.flexShrink = 0
  rootTag.style.flexBasis = 'auto'
  rootTag.style.overflow = 'hidden'
  rootTag.style.display = 'flex'
  rootTag.style.padding = '0px'
  rootTag.style.backgroundColor = 'white'
}

const withExpoRoot = (AppRootComponent) =>
  class ExpoRootComponent extends React.Component {
    render() {
      const props = {
        ...this.props,
        exp: { ...this.props.exp, errorRecovery: ErrorRecovery.recoveredProps }
      }
      return React.createElement(AppRootComponent, { ...props })
    }
  }

const Root = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallBackComponent}>
      <RootWrapper />
    </ErrorBoundary>
  )
}

const AppWithExpoRoot = withExpoRoot(Root)

const RootComponent = (props) => React.createElement(AppWithExpoRoot, { ...props })

AppRegistry.registerComponent('main', () => RootComponent)
const rootTag = document.getElementById('aivy-app') ?? document.getElementById('root')

addBasisRootStyling(rootTag)

AppRegistry.runApplication('main', { rootTag })
