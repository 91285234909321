import React from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'

const ProfileButton = ({ containerStyles, onPress }) => (
  <View style={containerStyles}>
    <TouchableOpacity onPress={onPress}>
      <Image
        style={{ resizeMode: 'contain', width: 24, height: 24 }}
        source={require('../assets/images/profile-icon.png')}
      />
    </TouchableOpacity>
  </View>
)

ProfileButton.propTypes = {
  containerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onPress: PropTypes.func.isRequired
}

ProfileButton.defaultProps = {
  containerStyles: {}
}

export default ProfileButton
