import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { Switch } from 'react-native-paper'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

import { rowCenterBetween } from '../../../../styles'
import { ModalHeader, Typo } from '../../../../components'
import { AuthContext } from '../../../../context'
import { query } from '../../../../graphql'
import { notifyBugsnag } from '../../../../utils/bugsnag'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  title: {
    marginTop: 32,
    marginBottom: 4,
    paddingHorizontal: 20
  },
  notificationContainer: {
    paddingHorizontal: 20,
    height: 40,
    ...rowCenterBetween
  }
})

const Notifications = ({ navigation }) => {
  const { rootStore } = useContext(AuthContext)
  const { id: user_id } = rootStore.userStore.userProfile
  const { pushEndpoint, emailEndpoint } = rootStore.userStore

  const [state, setState] = useState('INITIAL') // || 'ERROR'

  const [pushChallenge, setPushChallenge] = useState(false)
  const [pushReach, setPushReach] = useState(false)
  const [emailChallenge, setEmailChallenge] = useState(false)
  const [emailReach, setEmailReach] = useState(false)

  useEffect(() => {
    if (!pushEndpoint) return

    const { unsubscribed_topics } = pushEndpoint.Attributes || {}
    setPushChallenge(!(unsubscribed_topics || []).includes('CHALLENGE'))
    setPushReach(!(unsubscribed_topics || []).includes('REACH'))
  }, [pushEndpoint])

  useEffect(() => {
    if (!emailEndpoint) return

    const { unsubscribed_topics } = emailEndpoint.Attributes || {}
    setEmailChallenge(!(unsubscribed_topics || []).includes('CHALLENGE'))
    setEmailReach(!(unsubscribed_topics || []).includes('REACH'))
  }, [emailEndpoint])

  const onToggle = async ({ topic, value }) => {
    if (!pushEndpoint && topic.includes('PUSH')) {
      navigation.navigate('RegisterForPushNotifications')

      return
    }

    const input = { user_id }
    input.action = value ? 'reSubscribeEndpointToTopic' : 'unsubscribeEndpointFromTopic'

    if (topic === 'PUSH_CHALLENGE') {
      input.topic = 'CHALLENGE'
      input.channel_type = Platform.OS === 'ios' ? 'APNS' : 'GCM'
      setPushChallenge(value)
    }

    if (topic === 'PUSH_REACH') {
      input.topic = 'REACH'
      input.channel_type = Platform.OS === 'ios' ? 'APNS' : 'GCM'
      setPushReach(value)
    }

    if (topic === 'EMAIL_CHALLENGE') {
      input.topic = 'CHALLENGE'
      input.channel_type = 'EMAIL'
      setEmailChallenge(value)
    }

    if (topic === 'EMAIL_REACH') {
      input.topic = 'REACH'
      input.channel_type = 'EMAIL'
      setEmailReach(value)
    }

    query({
      query: 'pinpointControl',
      variables: input
    })
      .then(() => rootStore.userStore.fetchPinpointEndpoints())
      .catch((err) => notifyBugsnag(err) || setState('ERROR'))
  }

  return (
    <View style={styles.container}>
      <ModalHeader
        headerTitle='settings.notifications_header'
        onPress={() => navigation.goBack()}
        withBackButton
      />

      {state === 'ERROR' && (
        <View style={{ marginTop: 128, alignItems: 'center', justifyContent: 'center' }}>
          <Typo.H2Black center translate>
            global.error_boundary.message_1
          </Typo.H2Black>
          <Typo.T1Black center translate marginTop={8}>
            global.error_boundary.message_2
          </Typo.T1Black>
        </View>
      )}

      {state === 'INITIAL' && (
        <>
          {Platform.OS !== 'web' && (
            <>
              <Typo.H2Black style={styles.title}>Push-Benachrichtigungen</Typo.H2Black>
              <View style={styles.notificationContainer}>
                <Typo.T1Black translate>global.notifications.new_challenges</Typo.T1Black>
                <Switch
                  value={pushChallenge}
                  onValueChange={(value) => onToggle({ topic: 'PUSH_CHALLENGE', value })}
                />
              </View>
              <View style={styles.notificationContainer}>
                <Typo.T1Black translate>global.notifications.new_careers</Typo.T1Black>
                <Switch
                  value={pushReach}
                  onValueChange={(value) => onToggle({ topic: 'PUSH_REACH', value })}
                />
              </View>
            </>
          )}

          {!rootStore.userStore.isAnonym && (
            <>
              <Typo.H2Black style={styles.title}>E-Mail-Benachrichtigungen</Typo.H2Black>
              <View style={styles.notificationContainer}>
                <Typo.T1Black translate>global.notifications.new_challenges</Typo.T1Black>
                <Switch
                  value={emailChallenge}
                  onValueChange={(value) => onToggle({ topic: 'EMAIL_CHALLENGE', value })}
                />
              </View>
              <View style={styles.notificationContainer}>
                <Typo.T1Black translate>global.notifications.new_careers</Typo.T1Black>
                <Switch
                  value={emailReach}
                  onValueChange={(value) => onToggle({ topic: 'EMAIL_REACH', value })}
                />
              </View>
            </>
          )}
        </>
      )}
    </View>
  )
}

Notifications.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default observer(Notifications)
