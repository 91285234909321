/* eslint-disable camelcase */
import { answerSpecifications } from '../constants/answer-specifications'
import { notifyBugsnag } from './bugsnag'

export const verifyAnswers = ({ exam_id, user_input }) => {
  const answers = Object.keys(user_input).map((key) => user_input[key])
  const keysToVerify = Object.keys(answerSpecifications[exam_id]).filter((key) => key !== 'length')
  const notVerifiedKeys = []

  answers.forEach((answer) => {
    keysToVerify.forEach((key) => {
      if (!answerSpecifications[exam_id][key](key === 'verify_answer' ? answer : answer[key])) {
        !notVerifiedKeys.includes(key) && notVerifiedKeys.push(key)
      }
    })
  })

  if (notVerifiedKeys.length) {
    notifyBugsnag(
      new Error(`${exam_id}: incorrect answer property for keys ${JSON.stringify(notVerifiedKeys)}`)
    )
  }

  if (!answerSpecifications[exam_id].length(answers)) {
    notifyBugsnag(new Error(`${exam_id}: incorrect user_input length!`))
  }
}
