const remoteTypes = [
  { key: 1, value: 'hard_facts.remote.yes' },
  { key: 0, value: 'hard_facts.remote.no' }
]

const workExperienceTypes = [
  { key: 0, value: 'hard_facts.work_experience.graduate' },
  { key: 1, value: 'hard_facts.work_experience.junior' },
  { key: 2, value: 'hard_facts.work_experience.senior' }
]

const workExperienceOverviewTypes = [
  { key: 0, value: 'hard_facts_overview.work_experience.graduate' },
  { key: 1, value: 'hard_facts_overview.work_experience.junior' },
  { key: 2, value: 'hard_facts_overview.work_experience.senior' }
]

const hardFacts = { remoteTypes, workExperienceTypes, workExperienceOverviewTypes }

export default hardFacts

// const searchingTypes = [
//  { key: 0, value: 'hard_facts.searching_type.currently_searching' },
//  { key: 1, value: 'hard_facts.searching_type.open_for_the_offer' },
//  { key: 2, value: 'hard_facts.searching_type.not_interested' }
// ]

// const educationLevels = [
//  { key: 0, value: 'hard_facts.education_level.employed' },
//  { key: 1, value: 'hard_facts.education_level.in_school' },
//  { key: 2, value: 'hard_facts.education_level.in_training' },
//  { key: 3, value: 'hard_facts.education_level.studying' }
// ]

// de
// "hard_facts.searching_type.currently_searching": "derzeit auf der Suche",
// "hard_facts.searching_type.open_for_the_offer": "offen für ein Angebot",
// "hard_facts.searching_type.not_interested": "nicht interessiert",
// "hard_facts.education_level.employed": "angestellt",
// "hard_facts.education_level.in_school": "in der Schule",
// "hard_facts.education_level.in_training": "in der Ausbildung",
// "hard_facts.education_level.studying": "Student",

// en
// "hard_facts.searching_type.currently_searching": "Currently searching",
// "hard_facts.searching_type.open_for_the_offer": "Open for the offer",
// "hard_facts.searching_type.not_interested": "Not interested",
// "hard_facts.education_level.employed": "Employed",
// "hard_facts.education_level.in_school": "In school",
// "hard_facts.education_level.in_training": "In training",
// "hard_facts.education_level.studying": "Studying",
