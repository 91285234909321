export default {
  BIG_FIVE_o_score: {
    dimension: 'Persönlichkeit',
    property: 'Offenheit für Neues',
    endpointLeft: 'traditionell',
    endpointRight: 'experimentell',
    text1: 'deutlich traditioneller',
    text2: 'etwas traditioneller',
    text3: 'etwas experimenteller',
    text4: 'deutlich experimenteller',
    pol1: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher traditionell und sucht klare Strukturen und Regeln. Damit ist sie die beste Wahl für bodenständige Tätigkeiten, in denen die zu erfüllenden Aufgaben klar definiert sind. Vor allem mit Bekanntem und Routinen fühlt sie sich wohl und genießt die Vorhersehbarkeit der nächsten Aufgaben sehr, da sie lieber konkret als kreativ denkt. Damit ist die Person prädestiniert für verwaltende, strukturierte und sich wiederholende Arbeitstätigkeiten.',
    pol2: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher experimentell und sucht neue Erfahrungen und Erlebnisse. Damit ist sie die beste Wahl für kreative Tätigkeiten oder ein Umfeld mit hoher Komplexität und Vielfalt. Ihre erste Wahl sind oftmals dynamische Umgebungen, die von Wachstum und Innovation geprägt sind. Als eher experimentelle Person verlangt sie beispielsweise meist eher sich ständig wandelnde Arbeitstätigkeiten, die Flexibilität und Kreativität erfordern.',
    scoreData: [{ challengeID: 'BIG_FIVE', score: 'o_score' }]
  },
  BIG_FIVE_c_score: {
    dimension: 'Persönlichkeit',
    property: 'Gewissenhaftigkeit',
    endpointLeft: 'pragmatisch',
    endpointRight: 'systematisch',
    text1: 'deutlich pragmatischer',
    text2: 'etwas pragmatischer',
    text3: 'etwas systematischer',
    text4: 'deutlich systematischer',
    pol1: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher pragmatisch, lässt Dinge lieber auf sich zukommen und denkt handlungsorientiert anstatt viel zu planen. Damit ist sie die beste Wahl für Tätigkeiten, bei denen nicht alles im Detail perfekt und ausgearbeitet sein muss, sondern der Fokus auf dem großen Ganzen liegt.',
    pol2: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher systematisch und geht sehr strukturiert und organisiert an Aufgaben heran. Damit ist sie die beste Wahl für Tätigkeiten, bei denen systematisches und detailliertes Arbeiten gefordert sind. Vor allem bei verwaltenden, juristischen oder auch technischen Tätigkeiten, bei denen die Liebe zum Detail im Vordergrund steht, kann sie sich am besten einbringen.',
    scoreData: [{ challengeID: 'BIG_FIVE', score: 'c_score' }]
  },
  BIG_FIVE_e_score: {
    dimension: 'Persönlichkeit',
    property: 'Extraversion',
    endpointLeft: 'zurückhaltend',
    endpointRight: 'energiegeladen',
    text1: 'deutlich zurückhaltender',
    text2: 'etwas zurückhaltender',
    text3: 'etwas energiegeladener',
    text4: 'deutlich energiegeladener',
    pol1: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher zurückhaltend und sucht nicht ständig die Gesellschaft anderer. Damit ist sie die beste Wahl für Tätigkeiten, bei denen wenige, tiefgehende soziale Interaktionen gefordert sind. Besonders in Tätigkeiten, in denen man eher allein in konzentrierter Umgebung arbeiten kann und nicht jeden Tag von immer neuen Menschen umgeben ist, beispielsweise in der Wissenschaft oder Entwicklung, kann sie sich am besten einbringen.',
    pol2: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher energiegeladen und sucht meistens Gesellschaft und Austausch mit Anderen. Damit ist sie die beste Wahl für Tätigkeiten mit vielfältigen sozialen Interaktionen. In diesen Situationen ist sie oft sehr aktiv und auch durchsetzungsfähig, wodurch sie für Tätigkeiten mit vielfältigem und wechselndem Mitarbeitenden- oder Kundenkontakt gut geeignet ist.',
    scoreData: [{ challengeID: 'BIG_FIVE', score: 'e_score' }]
  },
  BIG_FIVE_a_score: {
    dimension: 'Persönlichkeit',
    property: 'Verträglichkeit',
    endpointLeft: 'sachorientiert',
    endpointRight: 'beziehungsorientiert',
    text1: 'deutlich sachorientierter',
    text2: 'etwas sachorientierter',
    text3: 'etwas beziehungsorientierter',
    text4: 'deutlich beziehungsorientierter',
    pol1: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher sachorientiert und strebt vor allem nach individuellen Ergebnissen. Sie lässt sich als wettbewerbsorientiert und ehrgeizig bezeichnen. Damit ist sie die beste Wahl für Tätigkeiten,die entweder auf individuelle Ergebnisse abzielen oder ein gewisses Maß an Dominanz benötigen. Liegt der Fokus der Stelle also eher auf der Leistung des Einzelnen als auf Teamwork, eignen sich oftmals sachorientierte Personen, beispielsweise bei der Polizei oder in der Sachbearbeitung.',
    pol2: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher beziehungsorientiert und strebt vor allem nach harmonischen Beziehungen. Damit ist sie der geborene Team-Player. Damit ist sie die beste Wahl für Tätigkeiten, die ein hohes Maß an Zusammenarbeit und sozialen Fertigkeiten benötigen. Eher beziehungsorientierte Personen sind stets um soziale Harmonie bemüht und eignen sich deshalb oftmals sehr gut für soziale Tätigkeiten und Umgebungen mit vielfältigen Kontakten, beispielsweise in Lehr- oder Pflegeberufen.',
    scoreData: [{ challengeID: 'BIG_FIVE', score: 'a_score' }]
  },
  BIG_FIVE_n_score: {
    dimension: 'Persönlichkeit',
    intro: {
      de: 'Fokus auf Gefühlsausdrücke beschreibt die Tendenz einer Person, eher jeder noch so kleinen Gefühlsregung Aufmerksamkeit zu schenken, anstatt eher Sachinformationen und nur die deutlichsten Gefühlsregungen wahrzunehmen und zu gewichten.',
      en: "The perception of emotions describes a person's tendency to rather pay attention to the details of emotional expressions than perceiving more general factual information."
    },
    property: 'Emotionskontrolle',
    endpointLeft: 'emotionsgeleitet',
    endpointRight: 'emotionskontrolliert',
    text1: 'deutlich emotionsgeleiteter',
    text2: 'etwas emotionsgeleiteter',
    text3: 'etwas emotionskontrollierter',
    text4: 'deutlich emotionskontrollierter',
    pol1: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher emotionsgeleitet und lässt ihren Emotionen öfters mal freien Lauf. Sie ist eher sensibel gegenüber Angst und Stress und hat dadurch ein hohes Bedürfnis, Stress und Unsicherheit zu vermeiden. Damit ist sie die beste Wahl für Tätigkeiten, bei denen alle zukünftigen Unvorhersehbarkeiten und Notfälle im Voraus in Betracht gezogen werden müssen, wie zum Beispiel bei administrativen Jobs.',
    pol2: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher emotionskontrolliert und behält ihre Emotionen sehr stark im Griff. Damit ist sie die beste Wahl für Tätigkeiten, bei denen eine hohe Stresstoleranz nötig ist. Eher emotionskontrollierte Personen sind nicht aus der Ruhe zu bringen, aber auch nicht einfach zu durchschauen und wirken manchmal distanziert. Sie sind somit die beste Wahl für Tätigkeiten, bei denen man häufig bei Unvorhersehbarkeiten und Notfällen einen kühlen Kopf bewahren muss, wie zum Beispiel im medizinischen oder juristischen Bereich.',
    scoreData: [{ challengeID: 'BIG_FIVE', score: 'n_score' }]
  },
  BIG_FIVE_elevation: {
    dimension: 'Persönlichkeit',
    property: 'Verhaltenskonformität',
    endpointLeft: 'unkonventionell',
    endpointRight: 'gesellschaftskonform',
    text1: 'deutlich unkonventioneller',
    text2: 'etwas unkonventioneller',
    text3: 'etwas gesellschaftskonformer',
    text4: 'deutlich gesellschaftskonformer',
    pol1: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher unkonventionell und weicht in ihrem Verhalten auch schonmal von dem gesellschaftlich definierten Normbild ab. Sie fordert Konventionen auch einmal heraus und bringt somit viele neue Perspektiven ein. Damit ist sie die beste Wahl für Tätigkeiten, in denen viele verschiedene und auch mal unliebsame Sichtweisen förderlich sind und man somit Probleme wie Gruppendenken umgehen kann, beispielsweise in der Wissenschaft.',
    pol2: 'Im Vergleich zur Grundgesamtheit verhält sich die Person eher geselllschaftskonform und dem gesellschaftlichen Idealbild entsprechend. Sie vereint alle als positiv angesehene Persönlichkeitseigenschaften. Damit ist sie die beste Wahl für Tätigkeiten mit Kundenkontakt oder der Repräsentation des Unternehmens nach außen, da sie stets einen guten Eindruck vermittelt und nur selten anecken wird.',
    scoreData: [{ challengeID: 'BIG_FIVE', score: 'elevation' }]
  },
  RIASEC_IMAGES_elevation: {
    dimension: 'Berufliche Interessen',
    property: 'Differenziertheit der beruflichen Interessen',
    endpointLeft: 'spezialisiert',
    endpointRight: 'generalistisch',
    text1: 'deutlich spezialisierter in ihren Interessen',
    text2: 'etwas spezialisierter in ihren Interessen',
    text3: 'etwas generalistischer in ihren Interessen',
    text4: 'deutlich generalistischer in ihren Interessen',
    pol1: 'Im Vergleich zur Grundgesamtheit zeigt sich die Person eher spezialisiert und interessiert sich eher für wenige und eng gefasste berufliche Aufgaben- und Tätigkeitsbereiche. Damit ist sie die beste Wahl für Tätigkeiten, die klar definierte und sehr ähnliche Aufgaben an die Mitarbeitenden stellen und eine sehr hohe Expertise in einem abgegrenzten Tätigkeitsbereich benötigen.',
    pol2: 'Im Vergleich zur Grundgesamtheit zeigt sich die Person eher generalistisch und interessiert sich eher für viele und breit gefächerte berufliche Aufgaben- und Tätigkeitsbereiche. Damit ist sie die beste Wahl für Tätigkeiten, die interdisziplinäre oder abwechslungsreiche Aufgaben an die Mitarbeitenden stellen. Oder solche Jobs, die einen Überblick über verschiedene Tätigkeitsbereiche und die Fähigkeit zum Vernetzen dieser Bereiche benötigen, zum Beispiel im Projektmanagement.',
    scoreData: [{ challengeID: 'RIASEC_IMAGES', score: 'elevation' }]
  },
  RIASEC_IMAGES_r_score: {
    dimension: 'Berufliche Interessen',
    property: 'Handwerklich-technisches Interesse',
    endpointLeft: 'wenig',
    endpointRight: 'viel',
    text1: 'deutlich weniger an handwerklich-technischen Aufgaben interessiert',
    text2: 'etwas weniger an handwerklich-technischen Aufgaben interessiert',
    text3: 'etwas mehr an handwerklich-technischen Aufgaben interessiert',
    text4: 'deutlich mehr an handwerklich-technischen Aufgaben interessiert',
    pol1: 'Im Vergleich zur Grundgesamtheit hat die Person eher niedriges handwerklich-technisches Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher selten handwerklich-technische Aufgaben erledigt werden müssen. Handwerklich-technische Aufgaben umfassen vor allem praktische Tätigkeiten an Maschinen, mit technischen Geräten oder Werkzeugen, Aktivitäten, die körperliche Ausdauer fordern und konkrete sichtbare Ergebnisse haben. Diese findet man besonders im mechanischen, technischen oder landwirtschaftlichen Bereich und weniger im Büro hinter einem Schreibtisch.',
    pol2: 'Im Vergleich zur Grundgesamtheit hat die Person eher hohes handwerklich-technisches Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher häufig handwerklich-technische Aufgaben erledigt werden müssen. Handwerklich-technische Aufgaben umfassen vor allem praktische Tätigkeiten an Maschinen, mit technischen Geräten oder Werkzeugen, Aktivitäten, die körperliche Ausdauer fordern und konkrete sichtbare Ergebnisse haben. Diese findet man besonders im mechanischen, technischen oder landwirtschaftlichen Bereich und weniger im Büro hinter einem Schreibtisch.',
    scoreData: [{ challengeID: 'RIASEC_IMAGES', score: 'r_score' }]
  },
  RIASEC_IMAGES_i_score: {
    dimension: 'Berufliche Interessen',
    property: 'Untersuchend-forschendes Interesse',
    endpointLeft: 'wenig',
    endpointRight: 'viel',
    text1: 'deutlich weniger an untersuchend-forschenden Aufgaben interessiert',
    text2: 'etwas weniger an untersuchend-forschenden Aufgaben interessiert',
    text3: 'etwas mehr an untersuchend-forschenden Aufgaben interessiert',
    text4: 'deutlich mehr an untersuchend-forschenden Aufgaben interessiert',
    pol1: 'Im Vergleich zur Grundgesamtheit hat die Person eher niedriges untersuchend-forschendes Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher selten untersuchend-forschende Aufgaben erledigt werden müssen. Untersuchend-forschende Aufgaben umfassen vor allem Tätigkeiten, die komplex sind, analytisches Denken und Kombinieren erfordern und geistige und naturwissenschaftliche Phänomene beschreiben. Diese findet man besonders in Berufsfeldern, in denen Problemlösen und Wissenserwerb im Vordergrund stehen und es gefordert ist, die Dinge genau zu analysieren und auch einmal ein wenig zu knobeln.',
    pol2: 'Im Vergleich zur Grundgesamtheit hat die Person eher hohes untersuchend-forschendes Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher häufig untersuchend-forschende Aufgaben erledigt werden müssen. Untersuchend-forschende Aufgaben umfassen vor allem Tätigkeiten, die komplex sind, analytisches Denken und Kombinieren erfordern und geistige und naturwissenschaftliche Phänomene beschreiben. Diese findet man besonders in Berufsfeldern, in denen Problemlösen und Wissenserwerb im Vordergrund stehen und es gefordert ist, die Dinge genau zu analysieren und auch einmal ein wenig zu knobeln.',
    scoreData: [{ challengeID: 'RIASEC_IMAGES', score: 'i_score' }]
  },
  RIASEC_IMAGES_a_score: {
    dimension: 'Berufliche Interessen',
    property: 'Künstlerisch-kreatives Interesse',
    endpointLeft: 'wenig',
    endpointRight: 'viel',
    text1: 'deutlich weniger an künstlerisch-kreativen Aufgaben interessiert',
    text2: 'etwas weniger an künstlerisch-kreativen Aufgaben interessiert',
    text3: 'etwas mehr an künstlerisch-kreativen Aufgaben interessiert',
    text4: 'deutlich mehr an künstlerisch-kreativen Aufgaben interessiert',
    pol1: 'Im Vergleich zur Grundgesamtheit hat die Person eher niedriges künstlerisch-kreatives Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher selten künstlerisch-kreative Aufgaben erledigt werden müssen. Künstlerisch-kreative Aufgaben umfassen vor allem Tätigkeiten, die eher offen und unstrukturiert sind, vor allem in den literarischen, musischen oder künstlerischen Bereich fallen, und den kreativen Ausdruck von Ideen oder Emotionen ermöglichen. Diese findet man besonders in den Bereichen Sprache, Literatur, Kunst, Musik und Schauspiel und Berufsfeldern, die Kreativität und ästhetische Gestaltung erfordern, wie beispielsweise Grafikdesign.',
    pol2: 'Im Vergleich zur Grundgesamtheit hat die Person eher hohes künstlerisch-kreatives Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher häufig künstlerisch-kreative Aufgaben erledigt werden müssen. Künstlerisch-kreative Aufgaben umfassen vor allem Tätigkeiten, die eher offen und unstrukturiert sind, vor allem in den literarischen, musischen oder künstlerischen Bereich fallen, und den kreativen Ausdruck von Ideen oder Emotionen ermöglichen. Diese findet man besonders in den Bereichen Sprache, Literatur, Kunst, Musik und Schauspiel und Berufsfeldern, die Kreativität und ästhetische Gestaltung erfordern, wie beispielsweise Grafikdesign.',
    scoreData: [{ challengeID: 'RIASEC_IMAGES', score: 'a_score' }]
  },
  RIASEC_IMAGES_s_score: {
    dimension: 'Berufliche Interessen',
    property: 'Erziehend-pflegendes Interesse',
    endpointLeft: 'wenig',
    endpointRight: 'viel',
    text1: 'deutlich weniger an erziehend-pflegenden Aufgaben interessiert',
    text2: 'etwas weniger an erziehend-pflegenden Aufgaben interessiert',
    text3: 'etwas mehr an erziehend-pflegenden Aufgaben interessiert',
    text4: 'deutlich mehr an erziehend-pflegenden Aufgaben interessiert',
    pol1: 'Im Vergleich zur Grundgesamtheit hat die Person eher niedriges erziehend-pflegendes Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher selten erziehend-pflegende Aufgaben erledigt werden müssen. Erziehend-pflegende Aufgaben umfassen  vor allem Tätigkeiten, welche die Unterstützung oder Beratung anderer beispielsweise in Form von Unterricht, Lehren, Ausbilden, Versorgen oder Pflegen beinhalten und Aktivitäten in Bereichen, in denen Empathie und Humanitarismus gefragt sind. Diese findet man besonders in Arbeitsumgebungen, in denen soziale Interaktionen im Vordergrund stehen und Geselligkeit und Hilfsbereitschaft gefragt sind.',
    pol2: 'Im Vergleich zur Grundgesamtheit hat die Person eher hohes erziehend-pflegendes Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher häufig erziehend-pflegende Aufgaben erledigt werden müssen. Erziehend-pflegende Aufgaben umfassen  vor allem Tätigkeiten, welche die Unterstützung oder Beratung anderer beispielsweise in Form von Unterricht, Lehren, Ausbilden, Versorgen oder Pflegen beinhalten und Aktivitäten in Bereichen, in denen Empathie und Humanitarismus gefragt sind. Diese findet man besonders in Arbeitsumgebungen, in denen soziale Interaktionen im Vordergrund stehen und Geselligkeit und Hilfsbereitschaft gefragt sind.',
    scoreData: [{ challengeID: 'RIASEC_IMAGES', score: 's_score' }]
  },
  RIASEC_IMAGES_e_score: {
    dimension: 'Berufliche Interessen',
    property: 'Führend-verkaufendes Interesse',
    endpointLeft: 'wenig',
    endpointRight: 'viel',
    text1: 'deutlich weniger an führend-verkaufenden Aufgaben interessiert',
    text2: 'etwas weniger an führend-verkaufenden Aufgaben interessiert',
    text3: 'etwas mehr an führend-verkaufenden Aufgaben interessiert',
    text4: 'deutlich mehr an führend-verkaufenden Aufgaben interessiert',
    pol1: 'Im Vergleich zur Grundgesamtheit hat die Person eher niedriges führend-verkaufendes Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher selten führend-verkaufende Aufgaben erledigt werden müssen. Führend-verkaufende Aufgaben umfassen vor allem Tätigkeiten, welche die Überzeugung anderer von den eigenen Ideen oder die Führung eines Teams oder Unternehmens beinhalten und Aktivitäten mit einem Fokus auf unternehmerische Aspekte. Beispiele hierfür sind die Vielzahl von Aufgaben im Marketing und Vertrieb, bei denen es darum geht, andere Personen mitzureißen und von einem Produkt oder einer Dienstleistung zu überzeugen.',
    pol2: 'Im Vergleich zur Grundgesamtheit hat die Person eher hohes führend-verkaufendes Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher häufig führend-verkaufende Aufgaben erledigt werden müssen. Führend-verkaufende Aufgaben umfassen vor allem Tätigkeiten, welche die Überzeugung anderer von den eigenen Ideen oder die Führung eines Teams oder Unternehmens beinhalten und Aktivitäten mit einem Fokus auf unternehmerische Aspekte. Beispiele hierfür sind die Vielzahl von Aufgaben im Marketing und Vertrieb, bei denen es darum geht, andere Personen mitzureißen und von einem Produkt oder einer Dienstleistung zu überzeugen.',
    scoreData: [{ challengeID: 'RIASEC_IMAGES', score: 'e_score' }]
  },
  RIASEC_IMAGES_c_score: {
    dimension: 'Berufliche Interessen',
    property: 'Ordnend-verwaltendes Interesse',
    endpointLeft: 'wenig',
    endpointRight: 'viel',
    text1: 'deutlich weniger an ordnend-verwaltenden Aufgaben interessiert',
    text2: 'etwas weniger an ordnend-verwaltenden Aufgaben interessiert',
    text3: 'etwas mehr an ordnend-verwaltenden Aufgaben interessiert',
    text4: 'deutlich mehr an ordnend-verwaltenden Aufgaben interessiert',
    pol1: 'Die Person hat im Vergleich zur Grundgesamtheit eher niedriges ordnend-verwaltendes Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher selten ordnend-verwaltende Aufgaben erledigt werden müssen. Ordnend-verwaltende Aufgaben umfassen vor allem Tätigkeiten, welche die Herstellung und Aufrechterhaltung klarer Routinen, den strukturierten regelhaften Umgang mit Daten und Maschinen und das Einhalten klarer Leistungsstandards beinhalten. Hierunter fallen vor allem die Herstellung und Bewahrung von Ordnung, eine gewissenhafte Dokumentation und Verwaltung von Prozessen und komplexen Systemen sowie die Auswertung von Unternehmenszahlen.',
    pol2: 'Die Person hat im Vergleich zur Grundgesamtheit eher hohes ordnend-verwaltendes Interesse. Damit ist sie die beste Wahl für Jobs, in denen eher häufig ordnend-verwaltende Aufgaben erledigt werden müssen. Ordnend-verwaltende Aufgaben umfassen vor allem Tätigkeiten, welche die Herstellung und Aufrechterhaltung klarer Routinen, den strukturierten regelhaften Umgang mit Daten und Maschinen und das Einhalten klarer Leistungsstandards beinhalten. Hierunter fallen vor allem die Herstellung und Bewahrung von Ordnung, eine gewissenhafte Dokumentation und Verwaltung von Prozessen und komplexen Systemen sowie die Auswertung von Unternehmenszahlen.',
    scoreData: [{ challengeID: 'RIASEC_IMAGES', score: 'c_score' }]
  },
  HEROS_JOURNEY_score_coopcomp: {
    dimension: 'Persönlichkeit',
    intro: {
      de: 'Teamarbeits- oder Unabhängigkeits-Orientierung beschreibt die Tendenz einer Person, eher nach individuellen Ergebnissen anstatt nach Zusammenarbeit zu streben.',
      en: "Focus on teamwork or independence describes a person's tendency to either strive for collaboration or individual results."
    },
    property: 'Teamarbeits- oder Unabhängigkeits-Orientierung',
    endpointLeft: 'Teamarbeit',
    endpointRight: 'Unabhängigkeit',
    text1: 'deutlich mehr auf Teamarbeit fokussiert',
    text2: 'etwas mehr auf Teamarbeit fokussiert',
    text3: 'etwas mehr auf Unabhängigkeit fokussiert',
    text4: 'deutlich mehr auf Unabhängigkeit fokussiert',
    pol1: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf Teamarbeit als auf Unabhängigkeit und bevorzugt persönlichen Austausch, Gemeinschaft und Kompromissbereitschaft. Damit ist sie die beste Wahl für ein Arbeitsumfeld, in dem es um persönlichen Austausch und Gemeinschaft geht. Sie wünscht sich Führung durch Unterstützung, Weiterbildung und Coaching und bringt sich am besten in Teams ein, die jeden miteinbeziehen und Wert auf Teamarbeit und Kompromissbereitschaft legen.',
    pol2: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf Unabhängigkeit als auf Teamarbeit und bevorzugt Ergebnisorientierung, Höchstleistung und Wettbewerbsfähigkeit. Damit ist sie die beste Wahl für ein Arbeitsumfeld, in dem es um Wettbewerb, Ergebnisse und Zielerreichung geht. Sie wünscht sich Führung durch Anspruch an hohe Leistungen, Zielsetzung und Wettbewerbsförderung und bringen sich am besten in Teams ein, in denen Ergebnisse, Höchstleistung und Wettbewerbsfähigkeit im Vordergrund stehen.',
    scoreData: [{ challengeID: 'HEROS_JOURNEY', score: 'score_coopcomp' }]
  },
  HEROS_JOURNEY_score_orderchaos: {
    dimension: 'Persönlichkeit',
    intro: {
      de: 'Stabilitäts- oder Flexibilitäts-Orientierung beschreibt die Tendenz einer Person, eher ein wandelbares und innovatives Umfeld, anstatt ein stabiles und strukturiertes Umfeld zu suchen.',
      en: "Focus on stability or flexibility describes a person's tendency to either seek a stable and structured environment or a changeable and innovative environment."
    },
    property: 'Stabilitäts- oder Flexibilitäts-Orientierung',
    endpointLeft: 'Stabilität',
    endpointRight: 'Flexibilität',
    text1: 'deutlich mehr auf Stabilität fokussiert',
    text2: 'etwas mehr auf Stabilität fokussiert',
    text3: 'etwas mehr auf Flexibilität fokussiert',
    text4: 'deutlich mehr auf Flexibilität fokussiert',
    pol1: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf Stabilität als auf Flexibilität und bevorzugt Kontrolle und Sicherheit, Verlässlichkeit, Effizienz und Nachhaltigkeit. Damit ist sie die beste Wahl für ein Arbeitsumfeld, in dem kontrolliertes Vorgehen und Sicherheit im Vordergrund stehen. Sie wünscht sich Führung durch Koordination, klare Prozesse und detaillierte Regeln und bringt sich am besten in Teams ein, in denen Verlässlichkeit, Sicherheit und enger Zusammenhalt im Vordergrund stehen.',
    pol2: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf Flexibilität als auf Stabilität und bevorzugt kreative Freiheit, Risikobereitschaft, Neues zu lernen und Innovation. Damit ist sie die beste Wahl für ein Arbeitsumfeld, in dem kreative Lösungen und Risikobereitschaft im Vordergrund stehen. Sie wünscht sich Führung durch Eigenverantwortung, flache Hierarchien und offenen Austausch und bringt sich am besten in Teams ein, in denen Einzigartigkeit, Risikobereitschaft und kreative Freiheit im Vordergrund stehen.',
    scoreData: [{ challengeID: 'HEROS_JOURNEY', score: 'score_orderchaos' }]
  },
  TRUST_score: {
    dimension: 'Persönlichkeit',
    property: 'Selbstlosigkeit',
    endpointLeft: 'eigener Vorteil',
    endpointRight: 'fremder Vorteil',
    text1: 'deutlich mehr im Sinne des eigenen Vorteils handelnd',
    text2: 'etwas mehr im Sinne des eigenen Vorteils handelnd',
    text3: 'etwas mehr im Sinne eines fremden Vorteils handelnd',
    text4: 'deutlich mehr im Sinne eines fremden Vorteils handelnd',
    pol1: 'Im Vergleich zur Grundgesamtheit handelt die Person eher im Sinne des eigenen Vorteil ihrer Organisation als zum Vorteil ihres Gegenübers und priorisiert vor allem die eigene Zielerreichung. Damit ist sie die beste Wahl für Tätigkeiten, bei denen der Vorteil der eigenen Organisation maximiert werden muss, man wenig Kompromisse eingehen kann und das Gegenüber auch einmal zurückstecken muss.',
    pol2: 'Im Vergleich zur Grundgesamtheit handelt die Person eher im Sinne des fremden Vorteils als zum eigenen Vorteil ihrer Organisation und priorisiert vor allem das Wohlergehen ihres Gegenübers. Damit ist sie die beste Wahl für Tätigkeiten, bei denen das beste Ergebnis für alle Beteiligten, beispielsweise Geschäftspartner:innen, Kund:innen und Kolleg:innen, im Vordergrund steht.',
    scoreData: [{ challengeID: 'TRUST', score: 'score' }]
  },
  TROLLEY_score: {
    dimension: 'Persönlichkeit',
    property: 'Zweck- oder Prinzipien-Orientierung',
    endpointLeft: 'Fokus auf Ziel',
    endpointRight: 'Fokus auf Weg',
    text1: 'deutlich mehr auf das Ziel einer Handlung fokussiert',
    text2: 'etwas mehr auf das Ziel einer Handlung fokussiert',
    text3: 'etwas mehr auf den Weg zur Zielerreichung fokussiert',
    text4: 'deutlich mehr auf den Weg zur Zielerreichung fokussiert',
    pol1: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf das Ziel einer Handlung als auf den Weg dahin, wobei der Zweck auch schon einmal die Mittel heiligen kann. Damit ist sie die beste Wahl für ein Arbeitsumfeld, in dem Zielerreichung im Vordergrund steht. Hier werden Handlungen und Entscheidungen anhand des entstehenden Ergebnisses bewertet und gute Entscheidungen schaffen den größtmöglichen Vorteil für die maximal mögliche Anzahl an Personen.',
    pol2: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf den Weg zur Erreichung eines Ziels und den damit verbundenen Prinzipien als auf das Ziel einer Handlung. Für sie gilt: Gute Entscheidungen benachteiligen niemanden, selbst wenn dabei übergeordnete Gemeinschaftsziele nicht vollständig erreicht werden. Damit ist sie die beste Wahl für ein Arbeitsumfeld, in dem werteorientiertes Handeln im Vordergrund steht. Hier werden Handlungen und Entscheidungen danach bewertet, ob sie mit Normen und Gesetzen in Einklang stehen und die Rechte eines jeden Einzelnen würdigen.',
    scoreData: [{ challengeID: 'TROLLEY', score: 'score' }]
  },
  // SPEEDACC_score: {
  //   dimension: 'Persönlichkeit',
  //   property: 'Geschwindigkeits- oder Genauigkeits-Orientierung',
  //   endpointLeft: 'Geschwindigkeit',
  //   endpointRight: 'Genauigkeit',
  //   text1: 'deutlich mehr auf Geschwindigkeit fokussiert',
  //   text2: 'etwas mehr auf Geschwindigkeit fokussiert',
  //   text3: 'etwas mehr auf Genauigkeit fokussiert',
  //   pol1: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf Geschwindigkeit als auf Genauigkeit und nimmt für die schnelle Bearbeitung einer Aufgabe auch einmal Flüchtigkeitsfehler in Kauf. Damit ist sie die beste Wahl für Tätigkeiten, in denen nicht genügend Zeit vorhanden ist, um alles noch einmal genau gegenzuprüfen. Ein zu hoher Fokus auf Genauigkeit und die perfekte Erledigung von Aufgaben würden den Ablauf hier eher aufhalten.',
  //   pol2: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf Genauigkeit als auf Geschwindigkeit und nimmt sich auch einmal mehr Zeit als erwartet für eine genaue Bearbeitung einer Aufgabe. Damit ist sie die beste Wahl für Tätigkeiten, in denen aufgrund der hohen Priorität von sorgfältiger Ausführung eher viel Zeit zur Verfügung steht. Hier wäre kein Raum für Flüchtigkeitsfehler, die durch eine Zeitersparnis entstehen.',
  //   text4: 'deutlich mehr auf Genauigkeit fokussiert'
  // },
  PATH_FINDER_score: {
    dimension: 'Fähigkeiten',
    intro: {
      de: 'Problemlösung unter Zeitdruck beschreibt die Tendenz einer Person, gute Lösungen eher auch unter Zeitdruck zu finden, anstatt Probleme lieber mit genügend Zeit anzugehen.',
      en: "Problem solving under time pressure describes a person's tendency to find good solutions even under time pressure rather than preferring to solve problems calmly without time pressure."
    },
    property: 'Problemlösung unter Zeitdruck',
    endpointLeft: 'ohne Zeitdruck',
    endpointRight: 'unter Zeitdruck',
    text1: 'deutlich besser in Problemlösung ohne Zeitdruck',
    text2: 'etwas besser in Problemlösung ohne Zeitdruck',
    text3: 'etwas besser in Problemlösung unter Zeitdruck',
    text4: 'deutlich besser in Problemlösung unter Zeitdruck',
    pol1: 'Im Vergleich zur Grundgesamtheit findet die Person gute Lösungen für Probleme eher ohne Zeitdruck und lässt sich zumeist auch einmal mehr Zeit, um eine gute Lösung zu finden. Damit ist sie die beste Wahl für Arbeitsumgebungen, in der sie Aufgaben zumeist in Ruhe und mit Genauigkeit bearbeiten kann. Sie findet ihr ideales Umfeld insbesondere in Jobs, in denen eher Routineaufgaben mit weniger Problemen oder ausreichend Zeit zur Lösung dieser Probleme gegeben sind.',
    pol2: 'Im Vergleich zur Grundgesamtheit findet die Person gute Lösungen für Probleme eher unter Zeitdruck. Damit ist sie die beste Wahl für dynamische und sich ständig ändernde Arbeitsumgebungen, in der sie gefordert ist, Aufgaben zumeist schnell und gut zu lösen. Sie findet ihr ideales Umfeld insbesondere in Jobs, in denen sie mit vielfältigen und abwechslungsreichen Problemen innerhalb kürzester Zeit konfrontiert ist.',
    scoreData: [{ challengeID: 'PATH_FINDER', score: 'score' }]
  },
  FLANKER_score: {
    dimension: 'Fähigkeiten',
    intro: {
      de: 'Aufmerksamkeitsfokus beschreibt die Tendenz einer Person, eher mit breitem Suchfokus anstatt eher mit engem Suchfokus die Lösung für ein Problem zu finden.',
      en: "Attentional focus describes a person's tendency to rather pay attention to a broad range of variables than a narrow range of variables in order to find the solution to a problem."
    },
    property: 'Aufmerksamkeitsfokus',
    endpointLeft: 'enger Fokus',
    endpointRight: 'breiter Fokus',
    text1: 'bei Problemlösungen deutlich mehr zu einem engen Suchfokus neigend',
    text2: 'bei Problemlösungen etwas mehr zu einem engen Suchfokus neigend',
    text3: 'bei Problemlösungen etwas mehr zu einem breiten Suchfokus neigend',
    text4: 'bei Problemlösungen deutlich mehr zu einem breiten Suchfokus neigend',
    pol1: 'Im Vergleich zur Grundgesamtheit findet die Person gute Lösungen für Probleme eher mit einem engen Suchfokus, auch wenn sie dabei eventuell wichtige Informationen aus dem Blick verliert. Damit ist sie die beste Wahl für Tätigkeiten, bei denen sie eher mit klar definierten und sich wiederholenden Aufgaben und Informationen konfrontiert wird. Sie ist damit besonders geeignet für Jobs, in denen Probleme mit einem engem Suchradius gelöst werden müssen, wie beispielsweise im Kundendienst oder anderen klar eingegrenzten Tätigkeitsbereichen.',
    pol2: 'Im Vergleich zur Grundgesamtheit findet die Person gute Lösungen für Probleme eher mit einem breiten Suchfokus, auch wenn sie dann durch viele gleichzeitige Informationen eine Reizüberflutung riskiert. Damit ist sie die beste Wahl für Tätigkeiten, bei denen sie eher mit unvorhersehbaren und vielfältigen Aufgaben und Informationen konfrontiert wird. Sie ist damit besonders geeignet für Jobs, in denen Probleme mit einem breiten Suchradius und oftmals auch unter Reizüberflutung gelöst werden müssen, wie beispielsweise in der Notfallmedizin oder im Krisenmanagement.',
    scoreData: [{ challengeID: 'FLANKER', score: 'score' }]
  },
  EMOTIONS_GAME_score: {
    dimension: 'Fähigkeiten',
    intro: {
      de: 'Fokus auf Gefühlsausdrücke beschreibt die Tendenz einer Person, eher jeder noch so kleinen Gefühlsregung Aufmerksamkeit zu schenken, anstatt eher Sachinformationen und nur die deutlichsten Gefühlsregungen wahrzunehmen und zu gewichten.',
      en: "The perception of emotions describes a person's tendency to rather pay attention to the details of emotional expressions than perceiving more general factual information."
    },
    property: 'Fokus auf Gefühlsausdrücke',
    endpointLeft: 'Fokus auf Sachinfos',
    endpointRight: 'Fokus auf Gefühlsinfos',
    text1: 'deutlich mehr auf Sachinformationen fokussiert',
    text2: 'etwas mehr auf Sachinformationen fokussiert',
    text3: 'etwas mehr auf Gefühlsinformationen fokussiert',
    text4: 'deutlich mehr auf Gefühlsinformationen fokussiert',
    pol1: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf Sachinformationen als auf Gefühlsinformationen, um den bestmöglichen Informationsgewinn aus einer sozialen Interaktion zu erzielen. Damit ist sie die beste Wahl für Tätigkeiten, in denen es weniger soziale Interaktionen gibt und Zahlen und Fakten ungeachtet der Gefühlsregungen einzelner Personen wichtig sind. Sie sind damit besonders geeignet für Jobs, in denen eher Themen behandelt werden, die unabhängig von spontanen Gefühlen einzelner Personen sind, wie beispielsweise in der Softwareentwicklung.',
    pol2: 'Im Vergleich zur Grundgesamtheit legt die Person mehr Wert auf Gefühlsinformationen als auf Sachinformationen, um den bestmöglichen Informationsgewinn aus einer sozialen Interaktion zu erzielen. Damit ist sie die beste Wahl für Tätigkeiten in denen vielfältige soziale Interaktionen ein täglicher Aspekt und Erfolgsfaktor der Arbeit sind. Sie sind damit besonders geeignet für Jobs, die besonders darauf ausgerichtet sind, dass sich Kunden oder andere Personen verstanden und wohlfühlen, wie beispielsweise in Pflegeberufen oder im Vertrieb.',
    scoreData: [{ challengeID: 'EMOTIONS_GAME', score: 'score' }]
  },
  ANALOGICAL_REASONING_score: {
    dimension: 'Fähigkeiten',
    intro: {
      de: 'Vernetztes Denken beschreibt die Tendenz einer Person, eher unerwartete und schwerer nachvollziehbare Lösungen, anstatt eher naheliegende und einfacher nachvollziehbare Lösungen zu finden.',
      en: 'Analogical reasoning describes a person’s tendency to rather find unexpected and innovative solutions than obvious and pragmatic solutions.'
    },
    property: 'Vernetztes Denken',
    endpointLeft: 'pragmatische Vergleiche',
    endpointRight: 'übergreifende Vergleiche',
    text1: 'bei Problemlösungen deutlich mehr zu pragmatischen Vergleichen neigend',
    text2: 'bei Problemlösungen etwas mehr zu pragmatischen Vergleichen neigend',
    text3: 'bei Problemlösungen etwas mehr zu einem übergreifenden Vergleichen neigend',
    text4: 'bei Problemlösungen deutlich mehr zu einem übergreifenden Vergleichen neigend',
    pol1: 'Im Vergleich zur Grundgesamtheit findet die Person gute Lösungen eher mit pragmatischen Vergleichen des Problems mit Problemen aus dem gleichen oder sehr ähnlichen Themenbereich und findet damit zumeist naheliegende Lösungen. Damit ist sie die beste Wahl für Tätigkeiten, in der sie zumeist mit überschaubaren oder wiederkehrenden Problemen konfrontiert wird. Das trifft auf Jobs zu, die naheliegende und pragmatische Lösungen suchen, wie beispielsweise solche Jobs mit hohem administrativen Anteil oder in der Industriefertigung.',
    pol2: 'Im Vergleich zur Grundgesamtheit findet die Person gute Lösungen eher mit übergreifenden Vergleichen des Problems mit Problemen aus einem völlig anderen Themenbereich und findet damit zumeist unerwartete Lösungen. Damit ist sie die beste Wahl für Tätigkeiten, in der sie zumeist mit sehr komplexen und vielschichtigen Problemen konfrontiert wird, für die es selten offensichtliche Lösung gibt. Das trifft auf Jobs zu, die unerwartete und kreative Lösungen suchen, wie beispielsweise in der Wissenschaft oder Unternehmensberatung.',
    scoreData: [{ challengeID: 'ANALOGICAL_REASONING', score: 'score' }]
  },
  TOWER_OF_LONDON_score: {
    dimension: 'Fähigkeiten',
    intro: {
      de: 'Geplante Problemlösung beschreibt die Tendenz einer Person, gute Lösungen eher durch sehr genaues Planen auf Anhieb anstatt eher durch mehrfaches Ausprobieren zu finden.',
      en: "The planning ability describes a person's tendency to find good solutions through very precise planning rather than through trial and error."
    },
    property: 'Geplante Problemlösung',
    endpointLeft: 'ausprobieren',
    endpointRight: 'planen',
    text1: 'bei Problemlösungen deutlich mehr zum Ausprobieren neigend',
    text2: 'bei Problemlösungen etwas mehr zum Ausprobieren neigend',
    text3: 'bei Problemlösungen etwas mehr zum Planen neigend',
    text4: 'bei Problemlösungen deutlich mehr zum Planen neigend',
    pol1: 'Die Person findet im Vergleich zur Grundgesamtheit gute Lösungen für Probleme eher durch Ausprobieren von verschiedenen Lösungsansätzen, auch wenn der erste Ansatz nicht gleich klappt, anstatt ihre nächsten Schritte bis ins Detail zu planen. Damit ist sie die beste Wahl für Arbeitsumgebungen, in denen eher eine offene Fehlerkultur gelebt wird und Fehler als Möglichkeit zum Lernen verstanden werden. Gerade in Tätigkeiten mit weniger komplexen Aufgaben kann durch Ausprobieren oftmals schneller eine Lösung erreicht werden als durch genaues Planen.',
    pol2: 'Die Person findet im Vergleich zur Grundgesamtheit gute Lösungen für Probleme eher durch Planen eines genauen Lösungswegs, der beim ersten Ansatz mit hoher Wahrscheinlichkeit direkt klappt, anstatt einfach drauf los zu probieren. Damit ist sie die beste Wahl für Arbeitsumgebungen, in denen eher mehrere Schritte vorausgedacht werden müssen, um komplexe Aufgaben systematisch zu bewältigen. Gerade in Tätigkeiten mit komplexen, sicherheitsrelevanten oder kostenintensiven Aufgaben ist genaues Planen wichtig, weil Fehler ein hohes Sicherheits- oder Kostenrisiko mitbringen, wie beispielsweise im Umgang mit Maschinen oder der Behandlung von Menschen.',
    scoreData: [{ challengeID: 'TOWER_OF_LONDON', score: 'score' }]
  },
  DIGIT_SPAN_score: {
    dimension: 'Fähigkeiten',
    intro: {
      de: 'Kurzfristiges Merken beschreibt die Tendenz einer Person, Informationen eher zu merken und aus dem Kopf wiederzugeben, anstatt sie eher zu notieren und nachzuschlagen.',
      en: "Short-term memorization describes a person's tendency to rather cognitively memorise and reproduce information than to write down information and look them up. "
    },
    property: 'Kurzfristiges Merken',
    endpointLeft: 'Informationen notieren',
    endpointRight: 'Informationen merken',
    text1: 'deutlich mehr zum Notieren von Informationen neigend',
    text2: 'etwas mehr zum Notieren von Informationen neigend',
    text3: 'etwas mehr zum Merken von Informationen neigend',
    text4: 'deutlich mehr zum Merken von Informationen neigend',
    pol1: 'Die Person neigt im Vergleich zur Grundgesamtheit beim Umgang mit neuen Informationen eher dazu, sich diese zu notieren und nachzuschlagen. Damit ist sie die beste Wahl für Tätigkeiten, bei denen sie eher mit klar abgegrenzten Aufgaben und wichtigen aber nicht zeitkritischen Informationen konfrontiert ist. Sie findet ihr ideales Umfeld insbesondere in Jobs, in denen sie genügend Zeit hat, um wichtige Informationen bei Bedarf nachzuschlagen, wie beispielsweise in administrativen Tätigkeiten.',
    pol2: 'Die Person neigt im Vergleich zur Grundgesamtheit beim Umgang mit neuen Informationen eher dazu, sich diese zu merken und aus dem Kopf wiedergeben. Damit ist sie die beste Wahl für Tätigkeiten, bei denen sie eher mit einer Vielzahl von zeitkritischen Informationen konfrontiert ist. Sie findet ihr ideales Umfeld vor allem in Jobs, in denen sie vielfältige und abwechslungsreiche Informationen kurzfristig kombinieren muss, wie zum Beispiel im investigativen Journalismus oder als Investment-Analyst:in.',
    scoreData: [{ challengeID: 'DIGIT_SPAN', score: 'score' }]
  },
  NBACK_score: {
    dimension: 'Fähigkeiten',
    intro: {
      de: 'Kurzfristiges Wiedererkennen beschreibt die Tendenz einer Person, Informationen und Personen eher auch in einem dynamischen Umfeld zumeist gut wiederzuerkennen, anstatt sie eher in einem stabilen Umfeld zumeist gut wiederzuerkennen.',
      en: "Short-term recognition describes a person's tendency to even recognise information and people in a dynamic environment instead of recognising them exclusively in a stable environment."
    },
    property: 'Kurzfristiges Wiedererkennen',
    endpointLeft: 'stabiles Umfeld',
    endpointRight: 'dynamisches Umfeld',
    text1: 'deutlich besser im Wiedererkennen in einem stabilen Umfeld',
    text2: 'etwas besser im Wiedererkennen in einem stabilen Umfeld',
    text3: 'etwas besser im Wiedererkennen in einem dynamischen Umfeld',
    text4: 'deutlich besser im Wiedererkennen in einem dynamischen Umfeld',
    pol1: 'Die Person neigt im Vergleich zur Grundgesamtheit eher dazu, Informationen und Personen besser in einem stabilen Umfeld wiedererkennen. Damit ist sie die beste Wahl für Tätigkeiten, bei denen sie eher mit einem klar definierten Team oder Kund:innenstamm und abgegrenzten Aufgaben konfrontiert ist. Sie findet ihr ideales Umfeld insbesondere in Jobs mit einem hohem Anteil an Routineaufgaben, wo somit bekannte Themen und Gesichter eingeordnet werden müssen. Dazu gehören beispielsweise Tätigkeiten in der industriellen Fertigung, Logistik oder der Sachbearbeitung.',
    pol2: 'Die Person neigt im Vergleich zur Grundgesamtheit eher dazu, Informationen und Personen besser in einem dynamischen Umfeld wiedererkennen. Damit ist sie die beste Wahl für Tätigkeiten, bei denen sie eher mit vielfältigen Kontakten und Informationen konfrontiert ist. Sie findet ihr ideales Umfeld vor allem in Jobs, in denen sie täglich neue Themen und Gesichter kennenlernen und einordnen muss. Dazu gehören beispielsweise Tätigkeiten im Vertrieb oder bei Nachrichten- und Informationsdiensten.',
    scoreData: [{ challengeID: 'NBACK', score: 'score' }]
  },
  DELEGATION_score_risk: {
    dimension: 'Persönlichkeit',
    property: 'Risikobereitschaft',
    endpointLeft: 'vorsichtig',
    endpointRight: 'risikobereit',
    text1: 'deutlich vorsichtiger',
    text2: 'etwas vorsichtiger',
    text3: 'etwas risikobereiter',
    text4: 'deutlich risikobereiter',
    pol1: 'Die Person neigt im Vergleich zur Grundgesamtheit eher dazu, vorsichtig zu handeln. Damit ist sie die beste Wahl für Tätigkeiten, in denen sie die Kontrolle über eventuelle Verluste hat und Risiken vermeiden kann. Sie findet ihr ideales Umfeld insbesondere in Jobs, in denen sie sehr gewissenhaft prüfen kann, ob eine Entscheidung zu viel Risiko beinhaltet, wodurch mögliche Verluste hinreichend abgewogen werden. Der vorsichtige Umgang mit Risiken kann zum Beispiel für Tätigkeiten in der Wirtschaftsprüfung oder im Rechtswesen hilfreich sein.',
    pol2: 'Die Person neigt im Vergleich zur Grundgesamtheit eher dazu, risikobereit zu handeln. Damit ist sie die beste Wahl für Tätigkeiten, in denen sie trotz einiger Ungewissheit und möglicher Verluste die Chance auf einen hohen Gewinn hat. Sie ist damit besonders geeignet für Jobs, in denen sie sich bei risikoreichen Entscheidungen eher auf die Chancen als die potentiellen Verluste konzentrieren kann. Das kann zum Beispiel im Vertrieb der Fall sein, wenn viel Zeit in die Gewinnung eines potenzieller Großkunden investiert wird, wo der Vertragsabschluss aber ungewiss ist.',
    scoreData: [{ challengeID: 'DELEGATION', score: 'score_risk' }]
  },

  DELEGATION_score_responsibility: {
    dimension: 'Persönlichkeit',
    property: 'Verantwortungsbereitschaft',
    endpointLeft: 'selbstständig',
    endpointRight: 'folgsam',
    text1: 'deutlich selbstständiger',
    text2: 'etwas selbstständiger',
    text3: 'etwas folgsamer',
    text4: 'deutlich folgsamer',
    pol1: 'Die Person neigt im Vergleich zur Grundgesamtheit eher dazu, selbstständig zu handeln. Damit ist sie die beste Wahl für Tätigkeiten, in denen sie Entscheidungen selbständig treffen und verantworten kann. Sie findet ihr ideales Umfeld insbesondere in Jobs, die eine selbstständige Entscheidungsfindung und somit viel Autonomie zulassen. Vor allem in Führungspositionen ist es oft erforderlich, schwierige Entscheidungen allein zu treffen und auch Verantwortung für die resultierenden Konsequenzen zu übernehmen.',
    pol2: 'Die Person neigt im Vergleich zur Grundgesamtheit eher dazu, folgsam zu handeln. Damit ist sie die beste Wahl für Tätigkeiten, in denen sie Entscheidungen nicht alleine treffen und verantworten muss. Sie ist damit besonders geeignet für Jobs, bei denen Entscheidungen nicht über die Köpfe von Anderen hinweg getroffen werden sollten. Vor allem bei Aufgabenfeldern, in denen eher Teamarbeit gefordert ist und Autonomie an zweiter Stelle steht, zahlt es sich aus, alle mit einzubeziehen.',
    scoreData: [{ challengeID: 'DELEGATION', score: 'score_responsibility' }]
  }
}
