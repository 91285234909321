export default {
  primaryBlue: '#4461D4',
  brightBlue: '#4D7BF3',
  darkBlue: '#2C46B2',
  oceanDarkBlue: '#263C99',
  lightBlue: '#B6C7F2',
  paleBlue: '#DFE4FC',
  paleWhiteBlue: '#EBEFFD',
  primaryBlueAlpha: 'rgba(68, 97, 212, 0.3)',
  primaryGreen: '#29CCC7',
  dislikeRed: '#ff7163',
  brightGreen: '#4FF0D0',
  // aquaMarine: '#50d9c7', // duplicate key
  darkBlack: '#182033',
  darkGrey: '#283440',
  rockGrey: '#45586B',
  mediumGrey: '#849BB3',
  softGrey: '#D1DBE6',
  lightGrey: '#EBF0F5',
  paleGrey: '#F5F7FA',
  lightPaleGrey: '#FAFBFC',
  error: '#FF7163',
  white: '#fff',
  backgroundGrey: '#FAFBFE',
  crusta: '#FF843B',
  aquaMarine: '#42dbbd',
  mango: '#ffb628',
  brightSun: '#FFC148',
  carnationPink: '#fe79a7',
  softBlue: '#5786ff',
  powderBlue: '#B6C7F2',
  whisper: '#F7F8FB',
  midGray: 'rgba(24, 32, 51, 0.7)',
  geyser: '#D4DBE3',
  linkWater: 'rgba(132, 155, 179, 0.5)',
  cadetBlue: '#A7B5C4',
  salmon: '#FF7567',
  carnationError: '#ed5958',
  raven: 'rgba(24, 32, 51, 0.6)',
  osloGrey: 'rgba(24, 32, 51, 0.5)',
  safetyOrange: '#FF9A28',
  portage: 'rgba(44, 70, 178, 0.5)',
  paleSky: '#707584',
  glitter: '#e8ecfe',
  gigas: '#3E3894',
  hopbush: '#d66192',
  cloudBurst: '#2B3845',
  buttonRed: '#F25050',
  buttonGreen: '#3BD4C7',
  lightGreyBg: '#DEE6F7',
  skyGrey: '#e7f4fe'
}
