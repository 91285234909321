import React, { useRef } from 'react'
import { Platform } from 'react-native'
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import PropTypes from 'prop-types'
import { CHALLENGE_SCREENS } from '../screens/challenges'

import RootStack from './RootStack'
import { trackEvent } from '../utils/tracking'

const Stack = createNativeStackNavigator()

// window.aivyChallengeId = 'TOWER_OF_LONDON'

const Navigation = ({ isLoggedIn, isSignup }) => {
  const navigationRef = useNavigationContainerRef()
  const routeNameRef = useRef()

  function getRootStack() {
    const limeChallengeID = Platform.OS === 'web' ? window.aivyChallengeId : undefined
    const queryString = Platform.OS === 'web' ? window.location.search : undefined

    if (limeChallengeID || (queryString && queryString.includes('challenge'))) {
      const challengeID = limeChallengeID || new URLSearchParams(queryString).get('challenge')

      return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen
            name='ChallengesScreen'
            component={CHALLENGE_SCREENS[challengeID]}
            options={{ presentation: 'fullScreenModal' }}
          />
        </Stack.Navigator>
      )
    }

    return <RootStack isLoggedIn={isLoggedIn} isSignup={isSignup} />
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      documentTitle={{
        formatter: () => `Aivy® - Your Skills. Your job.`
      }}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute().name
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current
        const currentRouteName = navigationRef.getCurrentRoute().name

        if (previousRouteName !== currentRouteName) {
          trackEvent('screen_view', { screen_name: currentRouteName })
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName
      }}
    >
      {getRootStack()}
    </NavigationContainer>
  )
}

Navigation.propTypes = {
  isLoggedIn: PropTypes.bool,
  isSignup: PropTypes.bool
}

Navigation.defaultProps = {
  isLoggedIn: false,
  isSignup: false
}

export default Navigation
