import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import Typo from '../Typo'

const Header = ({ title, description, width }) => (
  <View style={{ width }}>
    <Typo.H1BlackBold>{title}</Typo.H1BlackBold>
    <Typo.T2Black marginTop={4}>{description}</Typo.T2Black>
  </View>
)

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  width: PropTypes.string
}

Header.defaultProps = {
  width: '100%'
}

export default Header
