const ITEM_LIST = [
  {
    id: 'Z21',
    type: 'NUMBER_SERIES',
    text: '5, 9, 13, 17, ...',
    right_answer: 21,
    strategy: '(+4)',
    category: '5-stufig, 1 ZS'
  },
  {
    id: 'Z11',
    type: 'NUMBER_SERIES',
    text: '51, 48, 45, 42, ...',
    right_answer: 39,
    strategy: '(-3)',
    category: '5-stufig, 1 ZS'
  },
  {
    id: 'Z1',
    type: 'NUMBER_SERIES',
    text: '3, 6, 12, 24, ...',
    right_answer: 48,
    strategy: '(x2)',
    category: '5-stufig, 1 ZS'
  },
  {
    id: 'Z23',
    type: 'NUMBER_SERIES',
    text: '15, 21, 27, 33, ...',
    right_answer: 39,
    strategy: '(+6)',
    category: '5-stufig, 1 ZS'
  },
  {
    id: 'Z5',
    type: 'NUMBER_SERIES',
    text: '128, 64, 32, 16, ...',
    right_answer: 8,
    strategy: '(÷2)',
    category: '5-stufig, 1 ZS'
  },
  {
    id: 'Z15',
    type: 'NUMBER_SERIES',
    text: '68, 61, 54, 47, ...',
    right_answer: 40,
    strategy: '(-7)',
    category: '5-stufig, 1 ZS'
  },
  {
    id: 'Z6',
    type: 'NUMBER_SERIES',
    text: '162, 54, 18, 6, ...',
    right_answer: 2,
    strategy: '(÷3)',
    category: '5-stufig, 1 ZS'
  },
  {
    id: 'Z35',
    type: 'NUMBER_SERIES',
    text: '21, 22, 19, 20, 17, ...',
    right_answer: 18,
    strategy: '(+1, -3)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z44',
    type: 'NUMBER_SERIES',
    text: '4, 2, 6, 4, 8, ...',
    right_answer: 6,
    strategy: '(-2, +4)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z37',
    type: 'NUMBER_SERIES',
    text: '4, 7, 5, 8, 6, ...',
    right_answer: 9,
    strategy: '(+3, -2)',
    category: '6-stufig, 2 ZS'
  },

  {
    id: 'Z31',
    type: 'NUMBER_SERIES',
    text: '20, 40, 10, 20, 5, ...',
    right_answer: 10,
    strategy: '(x2, ÷4)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z27',
    type: 'NUMBER_SERIES',
    text: '8, 4, 12, 6, 18, ...',
    right_answer: 9,
    strategy: '(÷2, x3)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z45',
    type: 'NUMBER_SERIES',
    text: '15, 12, 17, 14, 19, ...',
    right_answer: 16,
    strategy: '(-3, +5)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z32',
    type: 'NUMBER_SERIES',
    text: '4, 12, 6, 18, 9, ...',
    right_answer: 27,
    strategy: '(x3, ÷2)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z40',
    type: 'NUMBER_SERIES',
    text: '7, 13, 9, 15, 11, ...',
    right_answer: 17,
    strategy: '(+6, -4)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z48',
    type: 'NUMBER_SERIES',
    text: '22, 16, 18, 12, 14, ...',
    right_answer: 8,
    strategy: '(-6, +2)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z29',
    type: 'NUMBER_SERIES',
    text: '48, 12, 24, 6, 12, ...',
    right_answer: 3,
    strategy: '(÷4, x2)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z41',
    type: 'NUMBER_SERIES',
    text: '8, 15, 13, 20, 18, ...',
    right_answer: 25,
    strategy: '(+7, -2)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z50',
    type: 'NUMBER_SERIES',
    text: '32, 24, 27, 19, 22, ...',
    right_answer: 14,
    strategy: '(-8, +3)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z34',
    type: 'NUMBER_SERIES',
    text: '1, 6, 2, 12, 4, ...',
    right_answer: 24,
    strategy: '(x6, ÷3)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z54',
    type: 'NUMBER_SERIES',
    text: '2, 3, 5, 8, 12, 17, ...',
    right_answer: 23,
    strategy: '(+1, +2, +3, +4, +5, +6)',
    category: 'Comple...'
  },
  {
    id: 'Z67',
    type: 'NUMBER_SERIES',
    text: '5, 7, 8, 9, 11, 12, ...',
    right_answer: 13,
    strategy: '(+2, +1, +1, +2, +1, +1)',
    category: ''
  },
  {
    id: 'Z58',
    type: 'NUMBER_SERIES',
    text: '31, 33, 30, 34, 29, 35, ...',
    right_answer: 28,
    strategy: '(+2, -3, +4, -5, +6, -7)',
    category: ''
  },
  {
    id: 'Z55',
    type: 'NUMBER_SERIES',
    text: '37, 31, 26, 22, 19, 17, ...',
    right_answer: 16,
    strategy: '(-6, -5, -4, -3, -2, -1)',
    category: 'Comple...'
  },
  {
    id: 28,
    type: 'NUMBER_SERIES',
    text: '27, 9, 18, 6, 12, ...',
    right_answer: 4,
    strategy: '(÷3, x2)',
    category: '6-stufig, 2 ZS'
  },
  {
    id: 'Z62',
    type: 'NUMBER_SERIES',
    text: '2, 5, 8, 12, 16, 21, ...',
    right_answer: 26,
    strategy: '(+3, +3, +4, +4, +5, +5)',
    category: ''
  },
  {
    id: 'Z52',
    type: 'NUMBER_SERIES',
    text: '2, 4, 3, 6, 5, 9, ...',
    right_answer: 8,
    strategy: '(+2, -1, +3, -1, +4, -1)',
    category: 'Comple...'
  },
  {
    id: 'Z61',
    type: 'NUMBER_SERIES',
    text: '3, 6, 9, 18, 22, 44, ...',
    right_answer: 49,
    strategy: '(x2, +3, x2, +4, x2, +5)',
    category: ''
  },
  {
    id: 'Z69',
    type: 'NUMBER_SERIES',
    text: '12, 15, 17, 16, 19, 21, ...',
    right_answer: 20,
    strategy: '(+3, +2, -1, +3, +2, -1)',
    category: ''
  },
  {
    id: 'Z60',
    type: 'NUMBER_SERIES',
    text: '40, 20, 26, 13, 20, 10, ...',
    right_answer: 18,
    strategy: '(÷2, +6, ÷2, +7, ÷2, +8)',
    category: ''
  },
  {
    id: 'Z66',
    type: 'NUMBER_SERIES',
    text: '6, 5, 8, 9, 8, 11, ...',
    right_answer: 12,
    strategy: '(-1, +3, +1, -1, +3, +1)',
    category: ''
  },
  {
    id: 'G23',
    type: 'OPERATIONS',
    text: '12 + X = 18',
    right_answer: 6,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G17',
    type: 'OPERATIONS',
    text: '15 – X = 9',
    right_answer: 6,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G9',
    type: 'OPERATIONS',
    text: '2 * X = 28',
    right_answer: 14,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G1',
    type: 'OPERATIONS',
    text: 'X ÷ 2 = 14',
    right_answer: 28,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G18',
    type: 'OPERATIONS',
    text: '22 – X = 8',
    right_answer: 14,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G2',
    type: 'OPERATIONS',
    text: 'X ÷ 3 = 7',
    right_answer: 21,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G24',
    type: 'OPERATIONS',
    text: '13 + X = 17',
    right_answer: 4,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G14',
    type: 'OPERATIONS',
    text: 'X * 4 = 48',
    right_answer: 12,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G5',
    type: 'OPERATIONS',
    text: '22 ÷ X = 2',
    right_answer: 11,
    strategy: '',
    category: '1 RS'
  },

  {
    id: 'G19',
    type: 'OPERATIONS',
    text: 'X – 3 = 6',
    right_answer: 9,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G10',
    type: 'OPERATIONS',
    text: '3 * X = 21',
    right_answer: 7,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G7',
    type: 'OPERATIONS',
    text: '45 ÷ X = 3',
    right_answer: 15,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G11',
    type: 'OPERATIONS',
    text: '4 * X = 32',
    right_answer: 8,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G20',
    type: 'OPERATIONS',
    text: 'X – 9 = 12',
    right_answer: 21,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G8',
    type: 'OPERATIONS',
    text: '72 ÷ X = 9',
    right_answer: 8,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G12',
    type: 'OPERATIONS',
    text: '5 * X = 35',
    right_answer: 7,
    strategy: '',
    category: '1 RS'
  },
  {
    id: 'G25',
    type: 'OPERATIONS',
    text: '30 ÷ 6 ÷ X = 1',
    right_answer: 5,
    strategy: '',
    category: '2 RS'
  },
  {
    id: 'G26',
    type: 'OPERATIONS',
    text: '42 ÷ X ÷ 7 = 2',
    right_answer: 3,
    strategy: '',
    category: '2 RS'
  },
  {
    id: 'G28',
    type: 'OPERATIONS',
    text: '4 * X * 2 = 64',
    right_answer: 8,
    strategy: '',
    category: '2 RS'
  },
  {
    id: 'G30',
    type: 'OPERATIONS',
    text: '7 + 8 + X = 28',
    right_answer: 13,
    strategy: '',
    category: '2 RS'
  },
  {
    id: 'G32',
    type: 'OPERATIONS',
    text: '14 – X – 3 = 9',
    right_answer: 2,
    strategy: '',
    category: '2 RS'
  },
  {
    id: 'G33',
    type: 'OPERATIONS',
    text: 'X + 6 + 5 = 14',
    right_answer: 3,
    strategy: '',
    category: '2 RS'
  },
  {
    id: 'G36',
    type: 'OPERATIONS',
    text: 'X – 3 – 2 = 4',
    right_answer: 9,
    strategy: '',
    category: '2 RS'
  },
  {
    id: 'G38',
    type: 'OPERATIONS',
    text: 'X + 5 * 4 = 48',
    right_answer: 28,
    strategy: '',
    category: '2 RS mit Regel'
  },
  {
    id: 'G40',
    type: 'OPERATIONS',
    text: 'X + 9 ÷ 3 = 5',
    right_answer: 2,
    strategy: '',
    category: '2 RS mit Regel'
  },
  {
    id: 'G43',
    type: 'OPERATIONS',
    text: '3 * 6 + X = 30',
    right_answer: 12,
    strategy: '',
    category: '2 RS mit Regel'
  },
  {
    id: 'G44',
    type: 'OPERATIONS',
    text: '20 ÷ 2 – X = 2',
    right_answer: 8,
    strategy: '',
    category: '2 RS mit Regel'
  },
  {
    id: 'G47',
    type: 'OPERATIONS',
    text: 'X + 4 * 5 = 26',
    right_answer: 6,
    strategy: '',
    category: '2 RS mit Regel'
  },
  {
    id: 'G48',
    type: 'OPERATIONS',
    text: 'X – 2 * 2 = 6',
    right_answer: 10,
    strategy: '',
    category: '2 RS mit Regel'
  },
  {
    id: 'G49',
    type: 'OPERATIONS',
    text: 'X – 4 ÷ 2 = 14',
    right_answer: 16,
    strategy: '',
    category: '2 RS mit Regel'
  },
  {
    id: 'T8',
    type: 'TEXT',
    text: 'Im Büro gibt es 7 Sessel und 12 Drehstühle. Wie viele Sitzgelegenheiten gibt es insgesamt?',
    right_answer: 19,
    strategy: '(+)',
    category: '1 RS'
  },
  {
    id: 'T10',
    type: 'TEXT',
    text: 'Von den 82 Mitarbeitenden eines Unternehmens haben 8 gekündigt. Wie viele Mitarbeitende sind noch im Unternehmen?',
    right_answer: 74,
    strategy: '(-)',
    category: '1 RS'
  },
  {
    id: 'T1',
    type: 'TEXT',
    text: 'Anna hat 4-mal so viele Äpfel wie Max. Max hat 5 Äpfel. Wie viele Äpfel hat Anna?',
    right_answer: 20,
    strategy: '(x)',
    category: '1 RS'
  },
  {
    id: 'T5',
    type: 'TEXT',
    text: 'Jeden Tag werden Blätter für 3 Euro in einem Copy Shop gedruckt. Das Unternehmen möchte daher einen eigenen Drucker für 150 Euro kaufen. Nach wie vielen Tagen hat sich der Kauf gelohnt?',
    right_answer: 50,
    strategy: '(÷)',
    category: '1 RS'
  },
  {
    id: 'T9',
    type: 'TEXT',
    text: 'Ein Bäcker hat 8 Kekse gebacken und seine Kollegin hat 13 weitere Kekse gebacken. Wie viele Kekse haben sie zusammen?',
    right_answer: 21,
    strategy: '(+)',
    category: '1 RS'
  },
  {
    id: 'T12',
    type: 'TEXT',
    text: 'Ein Restaurant hat 22 Pizzen gebacken, von denen 18 verkauft wurden. Wie viele Pizzen sind noch übrig?',
    right_answer: 4,
    strategy: '(-)',
    category: '1 RS'
  },
  {
    id: 'T2',
    type: 'TEXT',
    text: 'In einem Spielzeugladen sind 60 Spielzeugautos. Jedes Auto kostet 3 Euro. Wie viel kosten alle Autos zusammen?',
    right_answer: 180,
    strategy: '(x)',
    category: '1 RS'
  },
  {
    id: 'T4',
    type: 'TEXT',
    text_de:
      'Ein Kuchenrezept benötigt für 5 Kuchen 10 Tassen Zucker. Wie viel Zucker benötigt man für einen Kuchen?',
    text_en:
      'A recipe requires 10 cups of sugar for 5 cakes. How much sugar do you need for one cake?',
    right_answer: 2,
    strategy: '(÷)',
    category: '1 RS'
  },
  {
    id: 'T3',
    type: 'TEXT',
    text: 'Ein Unternehmen hat im vergangenen Jahr 120 Euro für Bleistifte ausgegeben. In diesem Jahr haben sie 3-mal so viel ausgegeben. Wie viel Geld hat das Unternehmen in diesem Jahr für Bleistifte ausgegeben?',
    right_answer: 360,
    strategy: '(x)',
    category: '1 RS'
  },
  {
    id: 'T6',
    type: 'TEXT',
    text_de:
      'Ein Gärtner hat 36 Blumensamen und möchte sie in Reihen pflanzen, wobei in jeder Reihe 4 Samen sein sollen. Wie viele Reihen kann der Gärtner pflanzen?',
    text_en:
      'A gardener wants to plant 36 flower seeds in rows, with 4 seeds in each row. How many rows can the gardener plant?',
    right_answer: 9,
    strategy: '(÷)',
    category: '1 RS'
  },
  {
    id: 'T11',
    type: 'TEXT',
    text: 'Max hat 35 Euro gespart. Wenn er 15 Euro ausgibt, wie viel Geld hat er dann noch übrig?',
    right_answer: 20,
    strategy: '(-)',
    category: '1 RS'
  },
  {
    id: 'T16',
    type: 'TEXT',
    text: 'Eine Studentin lernt für eine Prüfung. Zuerst lernt sie 120 Minuten, dann lernt sie weitere 45 Minuten. Wie viele Minuten hat die Studentin insgesamt gelernt?',
    right_answer: 165,
    strategy: '(+,+)',
    category: '2 RS'
  },
  {
    id: 'T13',
    type: 'TEXT',
    text: 'Ein Unternehmen hat ein Budget von 260 Euro. Zuerst werden 140 Euro in Büroartikel und dann 18 Euro in eine Fußmatte investiert. Wie viel vom Budget ist danach noch übrig?',
    right_answer: 102,
    strategy: '(-,-)',
    category: '2 RS'
  },
  {
    id: 'T20',
    type: 'TEXT',
    text: 'Ein Paket enthält 5 Bücher. Eine Bibliothekarin bestellt 2 Pakete, dann merkt sie, dass sie eigentlich 4-mal so viele Bücher bräuchte. Wie viele Bücher bräuchte die Bibliothekarin?',
    right_answer: 40,
    strategy: '(x,x)',
    category: '2 RS'
  },
  {
    id: 'T22',
    type: 'TEXT',
    text: 'Ein Lager enthält 100 Kisten. Zuerst werden die Kisten auf 10 Regale gleichmäßig aufgeteilt. Pro Regal wird die Hälfte der Kisten blau angemalt. Wie viele blaue Kisten sind in jedem Regal am Ende?',
    right_answer: 5,
    strategy: '(÷, ÷)',
    category: '2 RS'
  },
  {
    id: 'T15',
    type: 'TEXT',
    text: 'Ein Kuchen wird in 16 Stücke geschnitten. Zuerst nimmt Anna 5 Stücke, dann nimmt Max 3 Stücke. Wie viele Stücke sind danach noch übrig?',
    right_answer: 8,
    strategy: '(-,-)',
    category: '2 RS'
  },
  {
    id: 'T17',
    type: 'TEXT',
    text: 'Ein Unternehmen verkauft Computer. Zuerst verkauft es 12 Computer, dann verkauft es weitere 9 Computer. Wie viele Computer hat das Unternehmen insgesamt verkauft?',
    right_answer: 21,
    strategy: '(+,+)',
    category: '2 RS'
  },
  {
    id: 'T21',
    type: 'TEXT',
    text_de:
      'Eine Firma produziert pro Monat 20 T-Shirts. Die Konkurrenz produziert 3-mal so viele T-Shirts pro Monat. Wie viele T-Shirts produziert die Konkurrenz in drei Monaten?',
    text_en:
      'A company produces 20 T-shirts per month. A competitor produces 3 times as many T-shirts per month. How many T-shirts does the competitor produce in three months?',
    right_answer: 180,
    strategy: '(x,x)',
    category: '2 RS'
  },
  {
    id: 'T23',
    type: 'TEXT',
    text_de:
      'Ein Bus hat 40 Sitzplätze. Die Hälfte der Sitzplätze befindet sich im oberen Bereich. Dort gibt es 5 Reihen. Wie viele Pätze gibt es pro Reihe im oberen Bereich des Busses?',
    text_en:
      'A double-decker bus has 40 seats. Half of the seats are in the upper section. There are 5 rows of seats there. How many seats does one row in the upper section of the bus have?',
    right_answer: 4,
    strategy: '(÷, ÷)',
    category: '2 RS'
  },
  {
    id: 'T25',
    type: 'TEXT',
    text: 'In einem Aquarium befinden sich 30 Fische. Zuerst werden 15 neue Fische hinzugefügt, dann werden 5 Fische verkauft. Wie viele Fische sind danach noch im Aquarium?',
    right_answer: 40,
    strategy: '(+, -)',
    category: '2 RS'
  },
  {
    id: 'T27',
    type: 'TEXT',
    text: 'In einem Regal stehen 55 Bücher. Zuerst werden 15 Bücher entnommen, dann werden 8 Bücher zurückgestellt. Wie viele Bücher sind danach noch im Regal?',
    right_answer: 48,
    strategy: '(-, +)',
    category: '2 RS'
  },
  {
    id: 'T29',
    type: 'TEXT',
    text_de:
      'Ein Fußballteam hat im 1. Spiel 3 Tore erzielt. In den nächsten Spielen wurden 4-mal so viele Tore wie im ersten Spiel erzielt. Wie viele Tore hat das Team insgesamt?',
    text_en:
      'A soccer team scored 3 goals in their first game. In the following games, they scored 4 times as many goals as in the first game. How many goals did the team score in total?',
    right_answer: 15,
    strategy: '(x, +)',
    category: '2 RS'
  },
  {
    id: 'T32',
    type: 'TEXT',
    text_de:
      'Ein Unternehmen hat 120 Mitarbeitende. Diese werden auf 10 Teams aufgeteilt. In einem der Teams sind 4 Mitarbeitende krank. Wie viele Mitarbeitende sind dann noch in diesem Team?',
    text_en:
      'A company has 120 employees. These are divided into 10 teams. In one of the teams, 4 employees are sick. How many employees are left in this team?',
    right_answer: 8,
    strategy: '(÷, -)',
    category: '2 RS'
  },
  {
    id: 'T26',
    type: 'TEXT',
    text_de:
      'Anna hat 320 Euro auf dem Konto. Zuerst zahlt sie weitere 200 Euro ein, dann hebt sie 120 Euro ab. Wie viel Geld befindet sich danach auf dem Konto?',
    text_en:
      'Anna has 320 euros in her account. First she deposits 200 euros, then she withdraws 120 euros. How much money is in the account afterwards?',
    right_answer: 400,
    strategy: '(+, -)',
    category: '2 RS'
  },
  {
    id: 'T28',
    type: 'TEXT',
    text_de:
      'Ein Geschäft hat 50 Hemden im Lager. Zuerst werden 20 Hemden verkauft, dann werden 10 Hemden nachbestellt. Wie viele Hemden sind danach noch im Lager?',
    text_en:
      'A store has 50 shirts in stock. First 20 shirts are sold, then 10 shirts are reordered. How many shirts are still in stock afterwards?',
    right_answer: 40,
    strategy: '(-, +)',
    category: '2 RS'
  },
  {
    id: 'T30',
    type: 'TEXT',
    text: 'Ein Laden verkauft Äpfel zu einem Preis von 2 Euro pro Stück. Eine Kundin kauft zuerst 5 Äpfel. Dann kauft sie für 5 Euro eine Avocado. Wie viel hat die Kundin insgesamt bezahlt?',
    right_answer: 15,
    strategy: '(x, +)',
    category: '2 RS'
  },
  {
    id: 'T34',
    type: 'TEXT',
    text: 'Eine Gärtnerin hat 60 Blumensamen, die sie auf 5 Beete verteilt. In einem Beet wurden 5 Samen wieder ausgegraben. Wie viele Blumensamen sind danach noch in diesem Beet vorhanden?',
    right_answer: 7,
    strategy: '(÷, -)',
    category: '2 RS'
  },
  {
    id: 'T35',
    type: 'TEXT',
    text_de:
      'Ein Unternehmen hat letztes Jahr 42€ für Tastaturen ausgegeben. Dieses Jahr hat sich der Betrag halbiert, allerdings wurde zusätzlich eine Tastaur für 7€ gekauft. Wieviel Geld wurde dieses Jahr für Tastaturen ausgegeben?',
    text_en:
      'A company spent 42 euros on coffee cups last year. This year, the amount was halved, but an additional coffee cup was purchased for 7 euros. How much money was spent on coffee cups this year?',
    right_answer: 28,
    strategy: '(÷, +)',
    category: '2 RS'
  },
  {
    id: 'T37',
    type: 'TEXT',
    text_de:
      'Ein Unternehmen hat 150 Mitarbeitende. Ein Drittel dieser nimmt an einer Schulung teil. Es nehmen noch weitere 14 Personen teil. Wie viele Personen besuchen die Schulung?',
    text_en:
      'A company has 150 employees. A third of the employees sign up for a training course. Then 14 more people sign up for the training. How many people attend the training in total?',
    right_answer: 64,
    strategy: '(÷, +)',
    category: '2 RS'
  },
  {
    id: 'T31',
    type: 'TEXT',
    text_de:
      'Ein Unternehmen hat 5 Abteilungen. In jeder Abteilung arbeiten 7 Mitarbeitende. Es werden 7 neue eingestellt. Wie viele Mitarbeitende gibt es insgesamt?',
    text_en:
      'A company has 5 departments. There are 7 employees in each department. 7 new employees are hired. How many employees are working at the company in total?',
    right_answer: 42,
    strategy: '(x, +)',
    category: '2 RS'
  }
]

const getId = (id) => ITEM_LIST.filter((i) => i.id === id)[0]

export const ITEMS = [
  [
    'G14',
    'Z45',
    'G25',
    'T28',
    'G30',
    'T31',
    'G36',
    'T4',
    'T6',
    'G7',
    'T26',
    'G32',
    'Z31',
    'T32',
    'Z41',
    'T37',
    'G28',
    'Z40',
    'T23',
    'Z54',
    'T35',
    'Z67',
    'G38',
    'Z62',
    'Z48',
    'G48',
    'Z50',
    'Z55',
    'G40',
    'Z27',
    'Z32',
    'G49',
    'Z58',
    'T21',
    'Z52',
    'G26',
    'T29',
    'Z60',
    'Z69',
    'Z66',
    'Z61'
  ].map((id) => getId(id))
]

export const TEST_ITEMS = [
  [
    {
      id: 19,
      type: 'NUMBER_SERIES',
      text: '9, 11, 13, 15, ...',
      right_answer: 17,
      strategy: '(+2)',
      category: '5-stufig, 1 ZS'
    }
  ],
  [
    {
      id: 79,
      type: 'OPERATIONS',
      text: '12 + X = 18',
      right_answer: 6,
      strategy: '',
      category: '1 RS'
    }
  ],
  [
    {
      id: 118,
      type: 'TEXT',
      text_de:
        'Im Büro gibt es 7 Sessel und 12 Drehstühle. Wie viele Sitzgelegenheiten gibt es insgesamt?',
      text_en:
        'There are 7 armchairs and 12 swivel chairs in the office. How many seats are there in total?',
      right_answer: 19,
      strategy: '(+)',
      category: '1 RS'
    }
  ]
]
