import { API, graphqlOperation } from 'aws-amplify'
import NetInfo from '@react-native-community/netinfo'
import { isError } from 'lodash'

import * as mutations from './mutations'
import * as queries from './queries'
// import * as subscriptions from '../graphql/subscriptions'

export const convertGraphqlError = ({ errors }, { query }) => {
  if (
    // graphql error
    Array.isArray(errors)
  ) {
    const { message, path } = errors[0]
    const error_path = path && path[0] !== query && path[0]

    return new Error(
      [query, message, error_path, '(graphql error)'].filter((item) => item).join(' / ')
    )
  }

  return new Error('convertGraphqlError: unknown')
}

const LEGALS_URL = 'https://react-native-app-content.s3.eu-central-1.amazonaws.com/legals/'

export const query = (options) => {
  const queryString = options.query

  options.query = options.queryParam
    ? queries[options.query](options.queryParam)
    : queries[options.query]

  const promise = new Promise((resolve, reject) => {
    const request = () => {
      API.graphql(options)
        .then((response) => {
          const { errors } = response

          if (errors) {
            reject(convertGraphqlError({ errors }, { query: queryString }))
            return
          }

          resolve(response)
        })
        .catch((err) => {
          if (isError(err)) reject(err)
          else reject(convertGraphqlError(err, { query: queryString }))
        })
    }

    NetInfo.fetch().then((state) => {
      if (!state.isConnected) reject(new Error('Keine Internetverbindung'))
      else request()
    })
  })

  return promise
}

export const mutation = async (mutationString, params) => {
  const state = await NetInfo.fetch()

  if (!state.isConnected) {
    throw new Error('Keine Internetverbindung')
  }

  return API.graphql(graphqlOperation(mutations[mutationString], { input: params }))
}

// export const subscription = () => {}

export const getMarkdown = async (endpoint) => {
  const response = await fetch(LEGALS_URL + endpoint)
  return response.text()
}
