import React from 'react'
import { View, StyleSheet, Image, Animated } from 'react-native'
import PropTypes from 'prop-types'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { rowCenter, rowCenterBetween } from '../styles'
import ProfileButton from './ProfileButton'
import Typo from './Typo'

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    paddingLeft: 16,
    paddingRight: 24,
    paddingVertical: 18,
    ...rowCenterBetween,
    position: 'absolute',
    width: '100%'
  }
})

const MainSmallHeader = ({ onPress, containerStyle, isAnonym, isEmailVerified }) => {
  const insets = useSafeAreaInsets()

  if (isAnonym || !isEmailVerified) return null

  return (
    <Animated.View style={[styles.container, containerStyle, { paddingTop: insets.top + 18 }]}>
      <View style={{ ...rowCenter }}>
        <Image
          source={require('../assets/images/aivy-icon.png')}
          style={{ height: 18, width: 20, marginRight: 14 }}
        />
        <Typo.H2Black>Aivy</Typo.H2Black>
      </View>
      <ProfileButton onPress={onPress} />
    </Animated.View>
  )
}

MainSmallHeader.propTypes = {
  onPress: PropTypes.func.isRequired,
  containerStyle: PropTypes.object,
  isAnonym: PropTypes.bool.isRequired,
  isEmailVerified: PropTypes.bool.isRequired
}

MainSmallHeader.defaultProps = {
  containerStyle: {}
}

export default MainSmallHeader
