import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  Text,
  SafeAreaView,
  Platform,
  ActivityIndicator
} from 'react-native'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'

import { StatusBar } from 'expo-status-bar'
import { Button, ChatBubble, ErrorBoundaryFallBackComponent, Typo } from '../../components'
import colors from '../../theme/colors'
import { AuthContext } from '../../context'
import invitationStates from '../../constants/invitationStates'
import LottieAnimation from '../../components/challenge/LottieAnimation'
import SwitchLang from '../../components/SwitchLang'
import { notifyBugsnag } from '../../utils/bugsnag'
import asyncStorage from '../../utils/async-storage'
import { useMount } from '../../hooks/use-mount'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingHorizontal: 24,
    paddingTop: 24
  },
  imageWrapperStyle: {
    height: 45,
    width: 45,
    borderRadius: 60 / 2,
    marginTop: 14,
    marginBottom: 8,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: colors.lightGrey
  },
  imageStyle: {
    height: 30,
    width: 30,
    resizeMode: 'contain'
  }
})

const CHAT_MESSAGES = [
  { message: 'global.welcome.greeting_1' },
  { message: 'global.welcome.greeting_2' },
  { message: 'global.welcome.greeting_3' },
  { message: 'global.welcome.greeting_4' }
]

const Welcome = ({ navigation }) => {
  const { rootStore, checkLogin } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState(null)
  const [isLastMessage, setIsLastMessage] = useState(null)
  const [language, setLanguage] = useState('de')

  const [error, setError] = useState(null)

  const isMounted = useRef(true)

  const { invitationState } = rootStore.userStore

  useEffect(() => {
    const getLanguage = async () => {
      const storageLanguage = await asyncStorage.getData('lang')
      setLanguage(storageLanguage || 'de')
    }

    getLanguage()

    return () => {
      isMounted.current = false
    }
  }, [])

  const onPressLetsGo = async () => {
    setIsLoading(true)
    const decodedURL = decodeURIComponent(rootStore.userStore.initialLink)
    let invitation = null

    if (Platform.OS === 'web') {
      const url = new URL(decodedURL)
      const query_params = new URLSearchParams(url.search)
      invitation = query_params.get('invitation')
    }

    try {
      if (invitation) {
        await rootStore.userStore.authenticaeToSpaceAccount(invitation)
      } else {
        await rootStore.userStore.createCognitoAccount()
        await rootStore.userStore.loginCognitoAccount()
        await rootStore.userStore.createProfile()
      }

      await checkLogin(true)
      isMounted.current && setIsLoading(false)
    } catch (err) {
      setError(err)
      notifyBugsnag(new Error(`[onPressLetsGo] ${err.message || JSON.stringify(err)}`))
    }
  }

  useMount(() => {
    if (invitationState === 'VALID_OWNER') {
      onPressLetsGo()
    }
  })

  useEffect(() => {
    setMessages([])
    setIsLastMessage(false)

    const timeout = __DEV__ ? [500, 1000, 1500, 2000] : [2500, 5000, 7500, 8500]
    setTimeout(() => setMessages((m) => [...m, { message: CHAT_MESSAGES[0].message }]), timeout[0])
    setTimeout(() => setMessages((m) => [...m, { message: CHAT_MESSAGES[1].message }]), timeout[1])
    setTimeout(() => setMessages((m) => [...m, { message: CHAT_MESSAGES[2].message }]), timeout[2])
    setTimeout(() => setMessages((m) => [...m, { message: CHAT_MESSAGES[3].message }]), timeout[3])
    setTimeout(() => setIsLastMessage(true), timeout[3])
  }, [])

  useEffect(() => () => {
    isMounted.current = false
  })

  if (error) return <ErrorBoundaryFallBackComponent />

  if (invitationState === 'VALID_OWNER') {
    return (
      <SafeAreaView style={[styles.container, { alignItems: 'center', justifyContent: 'center' }]}>
        <View>
          <ActivityIndicator style={{ marginBottom: 16 }} />
          <Typo.T1Black translate>global.welcome.assessment_is_loading</Typo.T1Black>
        </View>
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar animated style='dark' />
      {Platform.OS === 'web' && ( // && deviceType === 3 was before
        <View
          style={{
            padding: 24,
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1
          }}
        >
          <SwitchLang />
        </View>
      )}
      <View style={styles.contentContainer}>
        {messages &&
          messages.map((m, i) => (
            <ChatBubble
              key={m.message}
              message={m.message}
              containerStyle={{ marginTop: i === 0 ? 0 : 8 }}
            />
          ))}
        {isLastMessage && (
          <View>
            <View style={styles.imageWrapperStyle}>
              <Image style={styles.imageStyle} source={{ uri: rootStore.appSettingsStore.logo }} />
            </View>
            <Typo.SmallTextBlack style={{ opacity: 0.5 }} translate>
              global.welcome.powered_by_aivy
            </Typo.SmallTextBlack>
          </View>
        )}
        {!isLastMessage && (
          <LottieAnimation
            loop
            autoplay
            width={50}
            height={32}
            animationData={require('../../assets/animations/typing.json')}
          />
        )}
        <View />
      </View>
      {isLastMessage && (
        <View style={{ paddingRight: 20, alignItems: 'flex-end', paddingBottom: 20 }}>
          <Button
            id='lets_go'
            title='global.welcome.lets_go'
            containerStyle={{ marginVertical: 8 }}
            onPress={onPressLetsGo}
            isLoading={isLoading}
            disabled={
              rootStore.userStore.invitationState === invitationStates.EXPIRED ||
              rootStore.userStore.invitationState === invitationStates.USED
            }
          />
          <Button
            title='global.welcome.account_existing'
            type='outline'
            onPress={() => navigation.navigate('SignIn')}
            marginHorizontal={20}
          />
          <Typo.SmallTextBlack style={{ textAlign: 'right', marginTop: 16 }}>
            {I18n.get('global.welcome.terms_of_use_beginning')}
            <Text
              onPress={() =>
                navigation.navigate('MarkdownScreen', {
                  title: 'global.privacy_policy',
                  endpoint: `${language}_data_protection.md`
                })
              }
              style={{ textDecorationLine: 'underline' }}
            >
              {I18n.get('global.welcome.data_protection')}
            </Text>
            <Text>{' & '}</Text>
            <Text
              onPress={() =>
                navigation.navigate('MarkdownScreen', {
                  title: 'global.agb',
                  endpoint: `${language}_agb.md`
                })
              }
              style={{ textDecorationLine: 'underline' }}
            >
              {I18n.get('global.welcome.terms_of_use')}
            </Text>
            <Text>.</Text>
          </Typo.SmallTextBlack>
        </View>
      )}
    </SafeAreaView>
  )
}

Welcome.propTypes = {
  navigation: PropTypes.object.isRequired
}

export default observer(Welcome)
