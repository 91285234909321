import * as React from 'react'
import Svg, { Defs, Rect, G, Use, Path } from 'react-native-svg'
import { View } from 'react-native'
import PropTypes from 'prop-types'

const SvgComponent = ({ width, containerStyle }) => (
  <View style={[containerStyle, { width }]}>
    <Svg width={width} height={2}>
      <Defs>
        <Rect id='prefix__b' x={0} y={0} width={343} height={219} rx={4} />
      </Defs>
      <G fill='none' fillRule='evenodd'>
        <G transform='translate(-18 -60)'>
          <Use fill='#000' filter='url(#prefix__a)' xlinkHref='#prefix__b' />
          <Use fill='#FFF' xlinkHref='#prefix__b' />
        </G>
        <Path stroke='#849BB3' strokeDasharray='1,4' d='M0 1h307' />
      </G>
    </Svg>
  </View>
)

SvgComponent.propTypes = {
  width: PropTypes.number.isRequired,
  containerStyle: PropTypes.object.isRequired
}

export default SvgComponent
