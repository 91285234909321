import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, StatusBar, SafeAreaView, StyleSheet } from 'react-native'
import _ from 'lodash'
import { I18n } from 'aws-amplify'
import { Asset } from 'expo-asset'
import PropTypes from 'prop-types'

import metrics from '../../../theme/metrics'
import colors from '../../../theme/colors'
import Questionnaire from '../../../components/challenge/Questionnaire'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import Instruction from '../../../components/challenge/Instruction'
import Intro from '../../../components/challenge/Intro'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import Typo from '../../../components/Typo'
import LifeGoalsSlider from './components/LifeGoalsSlider'
import HerosJourneyMission from './components/HerosJourneyMission'
import { HEROS, RANDOM_QUESTIONS, TEST_QUESTION } from './data/heros-journey'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import ChallengeContainer from '../ChallengeContainer'
import { notifyBugsnag } from '../../../utils/bugsnag'
import { AuthContext } from '../../../context'
import { trackEvent } from '../../../utils/tracking'

const customMDStyles = StyleSheet.create({
  heading: {
    fontFamily: 'fira-sans-regular'
  },
  text: {
    fontSize: 26,
    lineHeight: 32,
    fontFamily: 'fira-sans-light',
    color: '#fff',
    textAlign: 'center'
  },
  strong: {
    fontFamily: 'fira-sans-medium',
    color: '#fff',
    fontSize: 26
  }
})

const HerosJourney = ({ testRound, submit }) => {
  const { rootStore } = useContext(AuthContext)

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [heros, setHeros] = useState(HEROS)
  const [questions] = useState(testRound ? TEST_QUESTION : RANDOM_QUESTIONS())
  const [isLoading, setIsLoading] = useState(false)
  const [role_suggestion, setRole_suggestion] = useState('')
  // const [heroSelected, setHeroSelected] = useState('')
  const [chooseHeroLoading, setChooseHeroLoading] = useState(false)
  const [showHerosJourneyMission, setShowHerosJourneyMission] = useState(false)
  const [disableContinue, setDisableContinue] = useState(true)

  const answers = useRef([])
  const questionnaire = useRef(null)
  const startTime = useRef(null)
  const endTime = useRef(null)

  useEffect(() => {
    Promise.all([
      Asset.loadAsync([
        require('../../../assets/challenges/heros-journey/game-assets/thumb.png'),
        require('../../../assets/challenges/heros-journey/game-assets/icon-presenter.png'),
        require('../../../assets/challenges/heros-journey/game-assets/icon-explorer.png'),
        require('../../../assets/challenges/heros-journey/game-assets/icon-coordinator.png'),
        require('../../../assets/challenges/heros-journey/game-assets/icon-enterpriser.png'),
        require('../../../assets/challenges/heros-journey/game-assets/rounds.png')
      ])
    ])
      .then(() => {
        setIsLoading(false)
        startTime.current = new Date() // track ms when ready
      })
      .catch((err) => notifyBugsnag(err))
  }, []) // eslint-disable-line

  const computeHero = () => {
    setIsLoading(true)

    const score_orderchaos =
      _.sumBy(
        _.filter(answers.current, (a) => a.type === 'orderchaos'),
        (a) => a.decision
      ) / 5
    const score_coopcomp =
      _.sumBy(
        _.filter(answers.current, (a) => a.type === 'coopcomp'),
        (a) => a.decision
      ) / 5

    const updateHeros = (role) => {
      const updatedHeros = _.concat(
        _.filter(heros, (hero) => hero.value === role),
        _.filter(heros, (hero) => hero.value !== role)
      )
      setHeros(updatedHeros)
    }

    if (score_coopcomp <= 5 && score_orderchaos <= 5) {
      setRole_suggestion('presenter')
      // setHeroSelected('presenter')
      updateHeros('presenter')
    } else if (score_coopcomp <= 5 && score_orderchaos > 5) {
      setRole_suggestion('explorer')
      // setHeroSelected('explorer')
      updateHeros('explorer')
    } else if (score_coopcomp > 5 && score_orderchaos <= 5) {
      setRole_suggestion('coordinator')
      // setHeroSelected('coordinator')
      updateHeros('coordinator')
    } else if (score_coopcomp > 5 && score_orderchaos > 5) {
      setRole_suggestion('enterpriser')
      // setHeroSelected('enterpriser')
      updateHeros('enterpriser')
    }

    setTimeout(() => {
      // LayoutAnimation.easeInEaseOut()
      setShowHerosJourneyMission(true)
      setIsLoading(false)
    }, 450)
  }

  const checkButtonDisabled = (currentQuestionAnswer) => {
    if (currentQuestionAnswer === undefined) {
      return true
    }
    if (currentQuestionAnswer.decision !== 5) {
      return false
    }
    return true
  }

  const chooseHero = async (hero, role) => {
    setChooseHeroLoading(true)
    setRole_suggestion(role)
    // setHeroSelected(hero)

    try {
      await rootStore.userStore.updateUser({
        hero,
        suggested_hero: role
      })
    } catch (err) {
      notifyBugsnag(err)
    }

    submit(
      answers.current.reduce((answerObject, labelIdx, idx) => {
        answerObject[`round_${idx + 1}`] = labelIdx
        return answerObject
      }, {})
    )
  }

  const getHeaderTitle = () =>
    testRound
      ? I18n.get('global.challenge.testround')
      : `${currentQuestionIndex + 1}/${questions.length}`

  const choicePressed = (idx) => {
    endTime.current = new Date()

    const currentQuestion = questions[currentQuestionIndex]
    const time_needed_in_ms = endTime.current - startTime.current

    answers.current = Object.assign([...answers.current], {
      [currentQuestionIndex]: {
        id: currentQuestion.id,
        decision: idx,
        type: currentQuestion.type,
        time_needed_in_ms
      }
    })

    setDisableContinue(checkButtonDisabled(answers.current[currentQuestionIndex]))
  }

  if (showHerosJourneyMission) {
    return (
      <HerosJourneyMission
        heros={heros}
        role_suggestion={role_suggestion}
        onPress={chooseHero}
        loading={chooseHeroLoading}
      />
    )
  }

  return (
    <>
      <SafeAreaView style={{ backgroundColor: colors.primaryBlue }} />
      <SafeAreaView
        forceInset={{ bottom: 'always', top: 'never' }}
        style={{ flex: 1, backgroundColor: '#fff' }}
      >
        <StatusBar barStyle='light-content' backgroundColor={colors.primaryBlue} />
        <Questionnaire
          ref={questionnaire}
          questions={questions}
          primaryButtonProps={{
            disabled: disableContinue,
            isLoading
          }}
          showFooter
          showBackButton={false}
          finalPrimaryButtonTitle={
            testRound
              ? I18n.get('global.complete_testround')
              : I18n.get('challenge.heros_journey.buttonlabel.your_mission')
          }
          onSubmit={testRound ? () => submit([]) : computeHero}
          hideProgress
          onPrevOrNext={(idx) => {
            setCurrentQuestionIndex(idx)
            setDisableContinue(true)
            startTime.current = new Date()
          }}
          renderQuestion={(question) => (
            <View style={{ flex: 1 }}>
              <View
                style={{
                  width: '100%',
                  height: metrics.heightPercentageToDP(33),
                  backgroundColor: colors.primaryBlue,
                  alignItems: 'center',
                  justifyContent: 'space-around'
                }}
              >
                <View style={{ width: '100%' }}>
                  <ChallengeHeader
                    title={getHeaderTitle()}
                    onPress={() => {
                      trackEvent('ChallengePaused', { exam_id: 'HEROS_JOURNEY' })
                      rootStore.pauseStore.start()
                    }}
                    titleStyle={{ color: '#fff' }}
                  />
                </View>
                <View style={{ alignItems: 'center' }}>
                  <Typo.Markdown center style={customMDStyles} marginTop={0}>
                    {I18n.get(question.title)}
                  </Typo.Markdown>
                  {testRound && (
                    <Typo.T2LightWhite center translate>
                      challenge.heros_journey.sliderhint
                    </Typo.T2LightWhite>
                  )}
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  paddingHorizontal: 22
                }}
              >
                <LifeGoalsSlider
                  onComplete={(idx) => choicePressed(idx)}
                  leftLabel={I18n.get(question.a)}
                  rightLabel={I18n.get(question.b)}
                />
              </View>
            </View>
          )}
        />
      </SafeAreaView>
    </>
  )
}

HerosJourney.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

TestRoundCompleteScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

InstructionScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

IntroScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const HerosJourneyChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={HerosJourney}
    EXAM={ALL_CHALLENGES.HEROS_JOURNEY}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: InstructionScreen, name: 'InstructionScreen' }
    ]}
    MIDDLE_SCREENS={[{ screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' }]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

HerosJourneyChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

HerosJourneyChallenge.defaultProps = { onChallengeComplete: () => {} }

export default HerosJourneyChallenge
