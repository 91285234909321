import React, { useState, useEffect, useContext } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell
} from 'react-native-confirmation-code-field'
import { Auth, I18n } from 'aws-amplify'

import { observer } from 'mobx-react'
import { ModalHeader, Typo, Button } from '../../../components'
import colors from '../../../theme/colors'
import { rowCenter } from '../../../styles'
import { AuthContext } from '../../../context'
import { replaceTranslationKey } from '../../../utils/helpers'
import { notifyBugsnag } from '../../../utils/bugsnag'
import { updateEndpoint } from '../../../utils/analytics'
import { trackEvent } from '../../../utils/tracking'
import { mutation } from '../../../graphql'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  codeFieldRoot: { marginTop: 20 },
  cell: {
    width: 40,
    height: 40,
    fontSize: 24,
    fontFamily: 'fira-sans-light',
    lineHeight: 32,

    textAlign: 'center'
  },
  borderStyle: {
    borderBottomWidth: 2,
    borderBottomColor: colors.softGrey
  },
  focusBorderStyle: {
    borderBottomColor: colors.primaryBlue
  },
  errorBorderStyle: {
    borderBottomColor: colors.error
  }
})

const CELL_COUNT = 6

const SaveStrengthCodeVerify = ({ navigation, route }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { rootStore } = useContext(AuthContext)
  const [value, setValue] = useState('')
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT })
  // eslint-disable-next-line no-unused-vars
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue
  })
  const [seconds, setSeconds] = useState(30)
  const [errorCodeVerify, setErrorCodeVerify] = useState(null)
  const { email } = route.params

  useEffect(() => {
    if (!route.params.verifyEmail) return

    Auth.currentAuthenticatedUser()
      .then((u) => {
        Auth.verifyUserAttribute(u, 'email').catch((errVerify) => notifyBugsnag(errVerify))
      })
      .catch((err) => {
        notifyBugsnag(err)
        setErrorCodeVerify(I18n.get('notification.error'))
      })
  }, [email, route.params.verifyEmail])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds <= 0) return
      setSeconds((sec) => sec - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [seconds])

  const verifyCode = () => {
    setIsLoading(true)
    Auth.verifyCurrentUserAttributeSubmit('email', value)
      .then(async (res) => {
        if (res === 'SUCCESS') {
          await rootStore.userStore.refreshUser()
          await rootStore.userStore.updateUser({ mail_stored: true })

          if (rootStore.spaceStore.active_space.context === 'ATTRACT') {
            const { partner_id } = rootStore.spaceStore.active_space
            await mutation('createPerson', {
              id: rootStore.spaceStore.active_space.id,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              email,
              owner: rootStore.userStore.username,
              authorized_create: [`__create_${partner_id}`],
              authorized_read: [`__read_low_${partner_id}`],
              authorized_update: [`__update_${partner_id}`],
              authorized_delete: [`__delete_${partner_id}`]
            })
          }

          updateEndpoint({ userStore: rootStore.userStore })

          trackEvent('MailStored')
          navigation.navigate('SaveStrengthSuccess')
        }

        setIsLoading(false)
      })
      .catch((err) => {
        switch (err.code) {
          case 'AliasExistsException':
            setErrorCodeVerify(I18n.get('global.alert.error.mail_already_reg'))
            break
          case 'CodeMismatchException':
            setErrorCodeVerify(I18n.get('global.alert.error.bad_code'))
            break
          default:
            setErrorCodeVerify(err)
            notifyBugsnag(err)
            break
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const resendCode = () => {
    setIsLoading(true)
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.updateUserAttributes(user, { email: email.toLowerCase() })
          .then((res) => {
            if (res === 'SUCCESS') {
              setSeconds(30)
              setErrorCodeVerify(null)
              setValue('')
            }
          })
          .catch((err) => {
            switch (err.code) {
              default:
                setErrorCodeVerify(err)
                break
            }
            notifyBugsnag(err)
          })
          .finally(() => setIsLoading(false))
      })
      .catch((err) => {
        notifyBugsnag(err)
        setIsLoading(false)
      })
  }

  return (
    <View style={styles.container}>
      <ModalHeader
        headerTitle='global.save_strength_code_verify.modal_header_title'
        onPress={() => navigation.goBack()}
        withBackButton
      />
      <View style={{ marginTop: 16, paddingHorizontal: 24 }}>
        <Typo.H2Blue translate center marginBottom={4}>
          global.save_strength_code_verify.code_send_information
        </Typo.H2Blue>
        <Typo.T1Black translate center marginTop={8} marginBottom={12}>
          {email}
        </Typo.T1Black>
        <Typo.T1LightBlack translate marginBottom={42} center>
          global.save_strength_code_verify.enter_text
        </Typo.T1LightBlack>
        <CodeField
          ref={ref}
          caretHidden={false}
          value={value}
          onChangeText={setValue}
          cellCount={CELL_COUNT}
          rootStyle={styles.codeFieldRoot}
          keyboardType='number-pad'
          textContentType='oneTimeCode'
          renderCell={({ index, symbol, isFocused }) => (
            <View
              key={index}
              style={[
                styles.borderStyle,
                isFocused ? styles.focusBorderStyle : {},
                errorCodeVerify ? styles.errorBorderStyle : {}
              ]}
            >
              <Text style={styles.cell} onLayout={getCellOnLayoutHandler(index)}>
                {symbol || (isFocused ? <Cursor /> : null)}
              </Text>
            </View>
          )}
        />
        <Button
          containerStyle={{ marginTop: 52 }}
          title='global.save_strength_code_verify.further_action'
          onPress={verifyCode}
          disabled={value.length !== CELL_COUNT}
          isLoading={isLoading}
        />
        {errorCodeVerify ? (
          <View style={{ marginTop: 18 }}>
            <Typo.T2Black center>{errorCodeVerify}</Typo.T2Black>
            <TouchableOpacity style={{ marginTop: 8 }} onPress={resendCode}>
              <Typo.ButtonBlue center translate>
                global.save_strength_code_verify.resend_action
              </Typo.ButtonBlue>
            </TouchableOpacity>
          </View>
        ) : (
          <View style={{ marginTop: 18 }}>
            {seconds > 0 ? (
              <Typo.T2Black center>
                {replaceTranslationKey(
                  I18n.get('global.save_strength_code_verify.resend_seconds'),
                  '{{seconds}}',
                  seconds
                )}
              </Typo.T2Black>
            ) : (
              <View style={[rowCenter, { alignSelf: 'center' }]}>
                <Typo.T2Black translate center>
                  global.save_strength_code_verify.resend_question
                </Typo.T2Black>
                <TouchableOpacity style={{ marginLeft: 8 }} onPress={resendCode}>
                  <Typo.ButtonBlue translate>
                    global.save_strength_code_verify.resend_action
                  </Typo.ButtonBlue>
                </TouchableOpacity>
              </View>
            )}
          </View>
        )}
      </View>
    </View>
  )
}

SaveStrengthCodeVerify.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
}

export default observer(SaveStrengthCodeVerify)
