import React, { useState } from 'react'
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native'
import PropTypes from 'prop-types'

import { Typo, Button } from '../../../../components'
import { ANSWER_OPTIONS } from '../data'
import colors from '../../../../theme/colors'

const s = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 20
  },
  contentContainer: {
    flex: 1 // pushes the footer to the end of the screen
  },
  footer: {
    paddingHorizontal: 24,
    paddingBottom: 24,
    paddingTop: 24
  },
  timeUpWrap: {
    padding: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const choiceStyle = StyleSheet.create({
  container: {
    height: 88,
    borderRadius: 4,
    borderWidth: 1,
    flex: 1,
    borderColor: colors.primaryBlue,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 5,
    ...Platform.select({
      ios: {
        shadowColor: '#E3E4E6',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.7,
        shadowRadius: 3.84,
        elevation: 5
      }
    })
  }
})

const Question = ({ item, buttonsDisabled, choicePressed }) => {
  const [timeIsUp] = useState(false)
  const [retries, setRetries] = useState(0)
  const timeout = null

  function onPressChoice(decision) {
    clearTimeout(timeout)
    choicePressed({ decision, retries })
  }

  function resetRound() {
    setRetries(retries + 1)
  }

  if (timeIsUp) {
    return <TimeIsUp onPress={() => resetRound()} />
  }

  return (
    <View style={s.container}>
      <Typo.H2LightBlack translate center>
        challenge.big_five.question_title
      </Typo.H2LightBlack>
      <Typo.NumberBlue translate center>
        {item.adjective}
      </Typo.NumberBlue>
      <View style={{ flexDirection: 'row' }}>
        {ANSWER_OPTIONS.map((option, idx) => (
          <Choice
            key={option.label}
            label={option.label}
            onPress={() => onPressChoice(option.value)}
            lastOption={idx === ANSWER_OPTIONS.length - 1}
            disabled={buttonsDisabled}
            noShadow
          />
        ))}
      </View>
    </View>
  )
}

const Choice = ({ onPress, disabled, label, lastOption }) => (
  <TouchableOpacity
    onPress={onPress}
    disabled={disabled}
    style={[choiceStyle.container, { paddingHorizontal: 8, marginRight: lastOption ? 0 : 10 }]}
  >
    <Typo.H2LightBlue translate>{label}</Typo.H2LightBlue>
  </TouchableOpacity>
)

const TimeIsUp = ({ onPress }) => (
  <View style={{ flex: 1 }}>
    <View style={s.contentContainer}>
      <View style={s.timeUpWrap}>
        <Typo.T1Black center translate>
          challenge.big_five.time_is_up
        </Typo.T1Black>
      </View>
    </View>
    <View style={s.footer}>
      <Button onPress={onPress} text='global.got_it' />
    </View>
  </View>
)

Choice.propTypes = {
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  lastOption: PropTypes.bool.isRequired
}

TimeIsUp.propTypes = {
  onPress: PropTypes.func.isRequired
}

Question.propTypes = {
  item: PropTypes.object.isRequired,
  buttonsDisabled: PropTypes.bool.isRequired,
  choicePressed: PropTypes.func.isRequired
}

export default Question
