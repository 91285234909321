/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
import React, { useState, useRef, useEffect, useContext } from 'react'
import {
  View,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  Image,
  Animated,
  Easing,
  StatusBar
} from 'react-native'
import PropTypes from 'prop-types'
import { PieChart } from 'react-native-svg-charts'
import { Text } from 'react-native-svg'
import { sample } from 'lodash'
import { I18n } from 'aws-amplify'
import { Ionicons } from '@expo/vector-icons'

import Intro from '../../../components/challenge/Intro'

import { ALL_CHALLENGES } from '../../../constants/challenges'
import ChallengeContainer from '../ChallengeContainer'
import FirstIntro from './components/FirstIntro'
import SecondIntro from './components/SecondIntro'
import ThirdIntro from './components/ThirdIntro'
import FourthIntro from './components/FourthIntro'
import Feedback from './components/Feedback'
import RoundIntro from './components/RoundIntro'
import {
  blockrandomSets,
  DECISION_TYPES,
  MOOD_EVENTS,
  initiateQuestions,
  JOB_TYPES,
  limitNumber,
  trainingData,
  getCurrentMoodBlock
} from './data'
import { Typo } from '../../../components'
import colors from '../../../theme/colors'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import metrics from '../../../theme/metrics'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import { trackEvent } from '../../../utils/tracking'
import { AuthContext } from '../../../context'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import { replaceTranslationKey } from '../../../utils/helpers'

const blockrandom = sample(blockrandomSets)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.skyGrey
  },
  winContainer: {
    width: '100%',
    height: 20
  },
  footer: {
    backgroundColor: colors.softGrey,
    paddingVertical: 24
  },
  button: {
    paddingHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: 8,
    width: '30%',
    paddingVertical: 8
  },
  buttonIconStyle: {
    height: 32,
    width: 32,
    marginBottom: 12,
    resizeMode: 'contain'
  }
})

const Delegation = ({ testRound, submit, navigation }) => {
  const { rootStore } = useContext(AuthContext)

  const [balances, setBalances] = useState({
    me: 1000,
    m1: 1000,
    m2: 1000,
    m3: 1000
  })
  const questions = useRef(testRound ? trainingData : initiateQuestions())
  const answers = useRef([])
  const moodEvents = useRef({})
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0)
  const [currentRoundIndex, setCurrentRoundIndex] = useState(0)
  const [familyMood, setFamilyMood] = useState(70)
  // const [testRoundType, setTestRoundType] = useState(TEST_ROUND_TYPES.PRIVATE)
  const t0 = useRef(new Date()) // useRef prevent reinit
  const t1 = useRef(null) // useRef prevent reinit
  const animatedValue = useRef(new Animated.Value(0)).current
  const animatedValueShip = useRef(new Animated.Value(0)).current
  const [flipHorizontal, setFlipHorizontal] = useState(false)
  const [familyHeight, setFamilyHeight] = useState(0)
  const [disabledButtons, setDisabledButtons] = useState(false)

  const translateX = animatedValueShip.interpolate({
    inputRange: [0, 1],
    outputRange: [0, metrics.screenWidth]
  })

  const [showDamagedShip, setShowDamagedShip] = useState(false)
  const [showFeedback, setShowFeedback] = useState(null)
  const [showRoundIntro, setShowRoundIntro] = useState(!testRound)
  const type = !testRound
    ? blockrandom[currentBlockIndex].group === 'self'
      ? JOB_TYPES.SMALL
      : JOB_TYPES.BIG
    : currentRoundIndex === 0
    ? JOB_TYPES.SMALL
    : JOB_TYPES.BIG

  const factor = type === JOB_TYPES.SMALL ? 1 : 4

  const currentQuestion = questions.current[currentBlockIndex][currentRoundIndex]

  useEffect(() => {
    Animated.loop(
      Animated.timing(animatedValue, {
        toValue: -2 * metrics.screenWidth,
        duration: 30000,
        useNativeDriver: false,
        easing: Easing.linear
      })
    ).start()
  }, [animatedValue, currentRoundIndex])

  useEffect(() => {
    if (!testRound) return

    if (currentRoundIndex === 1) {
      // setTestRoundType(TEST_ROUND_TYPES.FAMILY)
    }
  }, [currentRoundIndex, testRound])

  const startTimeTracking = () => {
    t0.current = new Date()
    t1.current = null
  }

  const stopTimeTracking = () => {
    t1.current = new Date()
    return t1.current - t0.current
  }

  const renderLeftHeader = () => {
    if (testRound) return null

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image
          source={require('../../../assets/challenges/delegation/coin.png')}
          style={{ height: 28, width: 28, marginRight: 6 }}
        />
        <Typo.ButtonBlue>
          {replaceTranslationKey(
            I18n.get('challenge.delegation.header.left'),
            '{{amount}}',
            balances.me + balances.m1 + balances.m2 + balances.m3
          )}
        </Typo.ButtonBlue>
      </View>
    )
  }

  const pieData = [
    { value: currentQuestion.showgain, svg: { fill: colors.buttonGreen } },
    { value: currentQuestion.showloss, svg: { fill: colors.buttonRed } },
    { value: currentQuestion.showgrey, svg: { fill: colors.softGrey } }
  ]

  const Labels = ({ slices }) =>
    slices.map((slice, index) => {
      const { pieCentroid, data } = slice
      if (data.value === 0) return null
      return (
        <Text
          key={index}
          x={pieCentroid[0]}
          y={pieCentroid[1]}
          fill={data.svg.fill === '#DDD' ? '#000' : 'white'}
          textAnchor='middle'
          alignmentBaseline='middle'
          fontSize={14}
          fontFamily='fira-sans-medium'
        >
          {`${data.value * 100}%`}
        </Text>
      )
    })

  const onClickButton = (decisionType) => {
    setDisabledButtons(true)
    const time_needed_in_ms = stopTimeTracking()
    let decision = 0
    let del_ship = 0
    let do_ship = 0
    let journeySuccess = 0
    let outcome = 0
    let familyFeedback = ''
    let familySmiley = ''
    let familyReaction = 0
    let familyMoodChange = 0
    let newFamilyMood = 0
    let moodText = null // determine if show mood change for next screen
    const potentialOutcome = null

    // determine decision (0 stay; 1 ship; 7 delegate)
    if (decisionType === DECISION_TYPES.DELEGATE) {
      decision = 7 // delegate
    } else if (decisionType === DECISION_TYPES.SHIP) {
      decision = 1 // ship
    } else {
      decision = 0 // stay
    }

    // determine outcomes
    if (decisionType === DECISION_TYPES.DELEGATE && currentQuestion.trueutility > 0) {
      del_ship = 1 // FAMILIE
      do_ship = del_ship // ENDGÜLTIG SHIP OR NOT
    } else if (decisionType === DECISION_TYPES.DELEGATE && currentQuestion.trueutility <= 0) {
      del_ship = 0
      do_ship = del_ship
    } else if (decisionType !== DECISION_TYPES.DELEGATE && currentQuestion.trueutility > 0) {
      del_ship = 1
      do_ship = decisionType === DECISION_TYPES.SHIP ? 1 : 0
    } else if (decisionType !== DECISION_TYPES.DELEGATE && currentQuestion.trueutility <= 0) {
      del_ship = 0
      do_ship = decisionType === DECISION_TYPES.SHIP ? 1 : 0
    }

    // journey_success
    const rn = Math.random()
    if (rn <= currentQuestion.success) {
      journeySuccess = 1
    } else {
      journeySuccess = 0
    }

    // determine outcome of decision
    // outcome miltiple 4 when family
    if (do_ship === 0) {
      outcome = 0
    } else if (do_ship === 1 && journeySuccess === 0) {
      outcome = parseInt(currentQuestion.loss)
    } else if (do_ship === 1 && journeySuccess === 1) {
      outcome = parseInt(currentQuestion.win)
    }

    const potentialLoss = parseInt(currentQuestion.loss) / 10
    const potentialWin = parseInt(currentQuestion.win) / 10

    // DECISION_TYPES
    // DONT_SHIP: 'DONT_SHIP',
    // DELEGATE: 'DELEGATE',
    // SHIP: 'SHIP'

    // Reactions of the siblings

    const delegated = decisionType === DECISION_TYPES.DELEGATE
    const ship = !!do_ship
    const familyShip = !!del_ship
    const condition = type === JOB_TYPES.SMALL ? 'SELF' : 'GROUP'
    const success = !!journeySuccess

    if (!delegated && !ship && !familyShip && condition === 'SELF') {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.1')
      familySmiley = '🙂'
      familyReaction = 1
    } else if (!delegated && !ship && !familyShip && condition === 'GROUP') {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.2')
      familySmiley = '🙂'
      familyReaction = 1
      familyMoodChange = 3
      newFamilyMood = familyMood + familyMoodChange
    } else if (!delegated && !ship && familyShip && condition === 'SELF' && success) {
      familyFeedback = replaceTranslationKey(
        `${I18n.get('challenge.delegation.feedback.family.3')}`,
        '{{amount}}',
        currentQuestion.win
      )
      familySmiley = '🤨'
      familyReaction = -1
    } else if (!delegated && !ship && familyShip && condition === 'SELF' && !success) {
      familyFeedback = replaceTranslationKey(
        I18n.get('challenge.delegation.feedback.family.4'),
        '{{amount}}',
        currentQuestion.loss
      )
      familySmiley = '🙂'
      familyReaction = 1
    } else if (!delegated && !ship && familyShip && condition === 'GROUP' && success) {
      familyFeedback = replaceTranslationKey(
        I18n.get('challenge.delegation.feedback.family.5'),
        '{{amount}}',
        currentQuestion.win
      )
      familySmiley = '🙄'
      familyReaction = -1
      familyMoodChange = potentialWin * -1
      newFamilyMood = familyMood - Math.abs(familyMoodChange)
    } else if (!delegated && !ship && familyShip && condition === 'GROUP' && !success) {
      familyFeedback = replaceTranslationKey(
        I18n.get('challenge.delegation.feedback.family.6'),
        '{{amount}}',
        currentQuestion.loss
      )
      familySmiley = '😅'
      familyReaction = 1
      familyMoodChange = Math.abs(0.5 * potentialLoss)
      newFamilyMood = familyMood + Math.abs(familyMoodChange)
    } else if (!delegated && ship && !familyShip && condition === 'SELF' && success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.7')
      familySmiley = '😄'
      familyReaction = 1
    } else if (!delegated && ship && !familyShip && condition === 'GROUP' && success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.8')
      familySmiley = '🤑'
      familyReaction = 1
      const g = limitNumber(outcome / 10, 0, 7)
      familyMoodChange = 0.7 * g
      newFamilyMood = familyMood + familyMoodChange
    } else if (!delegated && ship && familyShip && condition === 'SELF' && success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.9')
      familySmiley = '🙂'
      familyReaction = 1
    } else if (!delegated && ship && familyShip && condition === 'GROUP' && success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.10')
      familySmiley = '🤑'
      familyReaction = 1
      familyMoodChange = 0.5 * (outcome / 10)
      newFamilyMood = familyMood + familyMoodChange
    } else if (!delegated && ship && !familyShip && condition === 'SELF' && !success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.11')
      familySmiley = '🤨'
      familyReaction = -1
    } else if (!delegated && ship && !familyShip && condition === 'GROUP' && !success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.12')
      familySmiley = '😠'
      familyReaction = -1
      const v = limitNumber(outcome / 10, 0, 10)
      familyMoodChange = -1.5 * Math.abs(v)
      newFamilyMood = familyMood - Math.abs(familyMoodChange)
    } else if (!delegated && ship && familyShip && condition === 'SELF' && !success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.13')
      familySmiley = '😕'
      familyReaction = -1
    } else if (!delegated && ship && familyShip && condition === 'GROUP' && !success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.14')
      familySmiley = '😕'
      familyReaction = -1
      familyMoodChange = 0
      newFamilyMood = familyMood + familyMoodChange
    } else if (delegated && !ship && condition === 'SELF') {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.15')
      familySmiley = '😇'
      familyReaction = 0
    } else if (delegated && !ship && condition === 'GROUP') {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.16')
      familySmiley = '😇'
      familyReaction = 0
      familyMoodChange = 1
      newFamilyMood = familyMood + 3
    } else if (delegated && ship && condition === 'SELF' && success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.17')
      familySmiley = '😄'
      familyReaction = 0
    } else if (delegated && ship && condition === 'GROUP' && success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.18')
      familySmiley = '🤑'
      familyReaction = 1
      familyMoodChange = 0.3 * (outcome / 10)
      newFamilyMood = familyMood + familyMoodChange
    } else if (delegated && ship && condition === 'SELF' && !success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.19')
      familySmiley = '🙁'
      familyReaction = -1
    } else if (delegated && ship && condition === 'GROUP' && !success) {
      familyFeedback = I18n.get('challenge.delegation.feedback.family.20')
      familySmiley = '😓'
      familyReaction = -1
      familyMoodChange = 0
      newFamilyMood = familyMood + familyMoodChange
    }

    // console.log({
    //   familyFeedback,
    //   familySmiley,
    //   delegated,
    //   ship,
    //   familyReaction,
    //   familyShip,
    //   familyMoodChange,
    //   newFamilyMood,
    //   condition,
    //   success,
    //   V: potentialLoss,
    //   G: potentialWin
    // })

    // Stimmungsthermometer (change only when family round)
    if (type === JOB_TYPES.BIG) {
      setFamilyMood(limitNumber(Math.round(newFamilyMood * 100) / 100, 0, 100))
    }

    if (testRound === false) {
      answers.current = answers.current.concat([
        {
          blockId: `${blockrandom[currentBlockIndex].id}${
            type === JOB_TYPES.SMALL ? '001' : '004'
          }`,
          id: currentQuestion.id,
          decision,
          outcome,
          balance: balances.me + outcome,
          family_balance:
            balances.m1 +
            outcome +
            (balances.m2 + outcome) +
            (balances.m3 + outcome) +
            (balances.me + outcome),
          family_mood: familyMood,
          condition,
          time_needed_in_ms,
          new_family_mood:
            condition === 'SELF'
              ? familyMood
              : limitNumber(Math.round(newFamilyMood * 100) / 100, 0, 100),
          ...currentQuestion
        }
      ])
    }

    // ########################################################
    // Start Mood Event Handling
    // ########################################################

    if (type === JOB_TYPES.BIG) {
      // eslint-disable-next-line operator-assignment

      // eslint-disable-next-line no-restricted-syntax
      let foundMoodEvent = null
      const currentMoodBlock = getCurrentMoodBlock(familyMood)
      const newMoodBlock = getCurrentMoodBlock(newFamilyMood)

      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(MOOD_EVENTS)) {
        if (newFamilyMood >= key && currentMoodBlock !== newMoodBlock) {
          foundMoodEvent = key
        }
      }

      // ADJUST THAT THE FIRST TIME IS ONLY INITIAL DISPLAYED

      if (foundMoodEvent !== null) {
        // Found first time?
        if (
          moodEvents.current[foundMoodEvent] === undefined &&
          !(parseInt(newMoodBlock) < parseInt(currentMoodBlock) && parseInt(newMoodBlock) === 70)
        ) {
          // set initial
          moodEvents.current = {
            ...moodEvents.current,
            [foundMoodEvent]: {
              ...moodEvents.current[foundMoodEvent],
              INITIAL: MOOD_EVENTS[foundMoodEvent].INITIAL
            }
          }

          // fix by marking blocks that were skipped as "visited" as well.
          if (Math.abs(parseInt(newMoodBlock) - parseInt(currentMoodBlock)) > 10) {
            if (parseInt(newMoodBlock) < parseInt(currentMoodBlock)) {
              const a = parseInt(currentMoodBlock)
              const b = parseInt(newMoodBlock)
              let x = a - 10
              while (x !== b) {
                moodEvents.current = {
                  ...moodEvents.current,
                  [x.toString()]: {
                    ...moodEvents.current[x.toString()],
                    INITIAL: MOOD_EVENTS[x.toString()].INITIAL
                  }
                }
                x -= 10
              }
            } else {
              const a = parseInt(currentMoodBlock)
              const b = parseInt(newMoodBlock)
              let x = a + 10
              while (x !== b) {
                moodEvents.current = {
                  ...moodEvents.current,
                  [x.toString()]: {
                    ...moodEvents.current[x.toString()],
                    INITIAL: MOOD_EVENTS[x.toString()].INITIAL
                  }
                }
                x += 10
              }
            }
          }

          moodText = MOOD_EVENTS[foundMoodEvent].INITIAL
        } else if (
          (moodEvents.current[foundMoodEvent]?.ALTERNATE === undefined &&
            !(
              parseInt(newMoodBlock) < parseInt(currentMoodBlock) && parseInt(newMoodBlock) === 70
            ) &&
            parseInt(currentMoodBlock) < parseInt(newMoodBlock) &&
            (parseInt(newMoodBlock) !== 80 || parseInt(newMoodBlock) !== 90)) ||
          (parseInt(currentMoodBlock) > parseInt(newMoodBlock) &&
            (parseInt(newMoodBlock) === 80 || parseInt(newMoodBlock) === 90))
        ) {
          // set alternate
          moodEvents.current = {
            ...moodEvents.current,
            [foundMoodEvent]: {
              ...moodEvents.current[foundMoodEvent],
              ALTERNATE: MOOD_EVENTS[foundMoodEvent].ALTERNATE
            }
          }

          // fix by marking blocks that were skipped as "visited" as well.
          if (Math.abs(parseInt(newMoodBlock) - parseInt(currentMoodBlock)) > 10) {
            if (parseInt(newMoodBlock) < parseInt(currentMoodBlock)) {
              const a = parseInt(currentMoodBlock)
              const b = parseInt(newMoodBlock)
              let x = a - 10
              while (x !== b) {
                moodEvents.current = {
                  ...moodEvents.current,
                  [x.toString()]: {
                    ...moodEvents.current[x.toString()],
                    ALTERNATE: MOOD_EVENTS[x.toString()].ALTERNATE
                  }
                }
                x -= 10
              }
            } else {
              const a = parseInt(currentMoodBlock)
              const b = parseInt(newMoodBlock)
              let x = a + 10
              while (x !== b) {
                moodEvents.current = {
                  ...moodEvents.current,
                  [x.toString()]: {
                    ...moodEvents.current[x.toString()],
                    ALTERNATE: MOOD_EVENTS[x.toString()].ALTERNATE
                  }
                }
                x += 10
              }
            }
          }

          moodText = MOOD_EVENTS[foundMoodEvent].ALTERNATE
        }
      }
    }
    // ########################################################
    // End Mood Event Handling
    // ########################################################

    const newFeedback = {
      success: journeySuccess === 1,
      decisionType,
      familyFeedback,
      familyReaction,
      familySmiley,
      doShip: do_ship,
      outcome,
      familyMoodChange: Math.round(familyMoodChange * 100) / 100,
      moodText,
      potentialOutcome
    }

    function updateBalances() {
      if (type === JOB_TYPES.SMALL) {
        setBalances({ ...balances, me: balances.me + outcome })
      } else {
        setBalances({
          me: balances.me + outcome,
          m1: balances.m1 + outcome,
          m2: balances.m2 + outcome,
          m3: balances.m3 + outcome
        })
      }
    }

    if (do_ship === 0) {
      updateBalances()
      setShowFeedback(newFeedback)
      return
    }

    Animated.timing(animatedValueShip, {
      toValue: 1,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: false
    }).start(() => {
      setTimeout(() => {
        setFlipHorizontal(true)
        if (journeySuccess !== 1) {
          setShowDamagedShip(true)
        }
        Animated.timing(animatedValueShip, {
          toValue: 0,
          duration: 1000,
          easing: Easing.linear,
          useNativeDriver: false
        }).start(() => {
          setTimeout(() => {
            updateBalances()
            setShowFeedback(newFeedback)
          }, 1000)
        })
      }, 250)
    })
  }

  if (showRoundIntro) {
    return (
      <RoundIntro
        type={type}
        onPress={() => {
          setShowRoundIntro(false)
          startTimeTracking()
        }}
      />
    )
  }

  if (showFeedback !== null) {
    return (
      <Feedback
        type={type}
        balances={balances}
        familyMood={familyMood}
        familyMoodChange={showFeedback.familyMoodChange}
        onPress={() => {
          setDisabledButtons(false)
          setShowDamagedShip(false)
          setFlipHorizontal(false)
          animatedValue.setValue(0)

          const questionsLength = questions.current[currentBlockIndex].length - 1
          if (testRound && currentRoundIndex === questionsLength) {
            // TEST ROUND COMPLETED
            submit()
            return
          }

          // GAME COMPLETED
          if (currentBlockIndex === 3 && currentRoundIndex === questionsLength) {
            const submitAnswers = answers.current.reduce((result, answer, idx) => {
              result[`round_${idx + 1}`] = answer
              return result
            }, {})
            submit(submitAnswers)
            return
          }

          // NEXT BLOCK
          if (currentRoundIndex === questionsLength) {
            setCurrentBlockIndex(currentBlockIndex + 1)
            setShowRoundIntro(true)
            setCurrentRoundIndex(0)
            setShowFeedback(null)
          } else {
            // NEXT ROUND
            setCurrentRoundIndex(currentRoundIndex + 1)
            setShowFeedback(null)
            startTimeTracking()
          }
        }}
        success={showFeedback.success}
        testRound={testRound}
        decisionType={showFeedback.decisionType}
        familyFeedback={showFeedback.familyFeedback}
        familySmiley={showFeedback.familySmiley}
        familyReaction={showFeedback.familyReaction}
        doShip={showFeedback.doShip}
        outcome={showFeedback.outcome}
        moodText={showFeedback.moodText}
        potentialOutcome={showFeedback.potentialOutcome}
      />
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle='dark-content' />
      <ChallengeHeader
        leftHeader={renderLeftHeader()}
        title={
          testRound
            ? I18n.get('global.challenge.testround')
            : `${currentRoundIndex + 1}/${questions.current[currentBlockIndex].length}`
        }
        onPress={() => {
          trackEvent('ChallengePaused', { exam_id: 'FLANKER' })
          rootStore.pauseStore.start()
        }}
      />
      <Image
        style={{
          position: 'absolute',
          top: metrics.heightPercentageToDP('12'),
          left: 30,
          ...metrics.calculateResponsiveDimensions({ height: 52, width: 122 })
        }}
        source={require('../../../assets/challenges/delegation/cloud.png')}
      />
      <Image
        style={{
          position: 'absolute',
          top: metrics.heightPercentageToDP('18'),
          right: -12,
          ...metrics.calculateResponsiveDimensions({ height: 46, width: 73 })
        }}
        source={require('../../../assets/challenges/delegation/cloud-2.png')}
      />
      <Image
        style={{
          position: 'absolute',
          top: metrics.heightPercentageToDP('28'),
          left: -18,
          ...metrics.calculateResponsiveDimensions({ height: 60, width: 94 })
        }}
        source={require('../../../assets/challenges/delegation/cloud-3.png')}
      />

      <View style={{ flex: 1 }}>
        <PieChart
          padAngle={0}
          innerRadius={0}
          style={{
            ...metrics.calculateResponsiveDimensions({ width: 140, height: 140 }),
            alignSelf: 'center'
          }}
          data={pieData}
        >
          <Labels />
        </PieChart>

        <View style={{ position: 'absolute', alignSelf: 'center' }}>
          <Image
            style={{
              ...metrics.calculateResponsiveDimensions({ width: 140, height: 140 }),
              alignSelf: 'center'
              // transform: [{ rotate: '108deg' }]
            }}
            source={require('../../../assets/challenges/delegation/wheel-outline.png')}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginVertical: 12,
            alignItems: 'center'
          }}
        >
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 10,
              backgroundColor: colors.primaryGreen,
              borderRadius: 8
            }}
          >
            <Typo.ButtonWhite>
              {replaceTranslationKey(
                `${I18n.get('challenge.delegation.main.win')}`,
                '{{amount}}',
                currentQuestion.win * factor
              )}
            </Typo.ButtonWhite>
          </View>

          <Typo.ButtonBlack translate>global.or</Typo.ButtonBlack>
          <View
            style={{
              paddingVertical: 4,
              paddingHorizontal: 10,
              backgroundColor: colors.buttonRed,
              borderRadius: 8
            }}
          >
            <Typo.ButtonWhite>
              {replaceTranslationKey(
                `${I18n.get('challenge.delegation.main.loss')}`,
                '{{amount}}',
                currentQuestion.loss * factor * -1
              )}
            </Typo.ButtonWhite>
          </View>
        </View>
        <Animated.View
          style={{
            position: 'absolute',
            bottom: 0,
            width: 3 * metrics.screenWidth,
            flexDirection: 'row',
            transform: [{ translateX: animatedValue }]
          }}
        >
          {Array.from(Array(10).keys()).map(() => (
            <Image
              source={require('../../../assets/challenges/delegation/waves.png')}
              style={{
                width: metrics.screenWidth,
                height: metrics.heightPercentageToDP('24')
              }}
            />
          ))}
        </Animated.View>
        <Animated.View
          style={{
            width: '100%',
            alignItems: 'center',
            position: 'absolute',
            bottom: metrics.heightPercentageToDP('10'),
            transform: [{ translateX }]
          }}
        >
          <Image
            style={{
              ...metrics.calculateResponsiveDimensions({ height: 198, width: 217 }),
              transform: [{ scaleX: flipHorizontal ? -1 : 1 }]
            }}
            source={
              showDamagedShip === true
                ? require('../../../assets/challenges/delegation/main-ship-damaged.png')
                : require('../../../assets/challenges/delegation/main-ship.png')
            }
          />
        </Animated.View>
      </View>

      <View style={styles.footer}>
        <Image
          onLayout={(e) => setFamilyHeight(e.nativeEvent.layout.height)}
          style={{
            ...metrics.calculateResponsiveDimensions({ height: 109, width: 82 }),
            position: 'absolute',
            top: familyHeight * -1,
            left: 32
          }}
          source={require('../../../assets/challenges/delegation/me.png')}
        />
        <Image
          source={require('../../../assets/challenges/delegation/family-normal.png')}
          style={{
            position: 'absolute',
            right: 32,
            top: familyHeight * -1,
            ...metrics.calculateResponsiveDimensions({ height: 109, width: 177 })
          }}
        />
        <View
          style={{
            position: 'absolute',
            top: -12,
            width: '100%',
            alignItems: 'center'
          }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('Alert', {
                title:
                  type === JOB_TYPES.SMALL
                    ? I18n.get('challenge.delegation.alert.private.title')
                    : I18n.get('challenge.delegation.alert.family.title'),
                message:
                  type === JOB_TYPES.SMALL
                    ? I18n.get('challenge.delegation.alert.private.description')
                    : I18n.get('challenge.delegation.alert.family.description'),
                buttons: [
                  {
                    text: I18n.get('global.understood'),
                    onPress: () => {}
                  }
                ]
              })
            }}
            style={{
              backgroundColor: '#fff',
              paddingHorizontal: 8,
              paddingVertical: 2,
              borderRadius: 4,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Ionicons
              name='md-information-circle'
              size={16}
              color='black'
              style={{ marginRight: 4 }}
            />
            <Typo.SmallTextBlack translate>
              {type === JOB_TYPES.SMALL
                ? 'challenge.delegation.main.private'
                : 'challenge.delegation.main.family'}
            </Typo.SmallTextBlack>
          </TouchableOpacity>
        </View>
        <View
          style={{
            paddingHorizontal: 16,
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 16
          }}
        >
          <TouchableOpacity
            disabled={disabledButtons}
            onPress={() => onClickButton(DECISION_TYPES.DONT_SHIP)}
            style={styles.button}
          >
            <Image
              style={styles.buttonIconStyle}
              source={require('../../../assets/challenges/delegation/button-x-icon.png')}
            />
            <Typo.ButtonBlack center translate>
              challenge.delegation.button.stay_in_port
            </Typo.ButtonBlack>
          </TouchableOpacity>
          <TouchableOpacity
            disabled={disabledButtons}
            onPress={() => onClickButton(DECISION_TYPES.SHIP)}
            style={styles.button}
          >
            <Image
              style={styles.buttonIconStyle}
              source={require('../../../assets/challenges/delegation/button-ship-icon.png')}
            />
            <Typo.ButtonBlack center translate>
              challenge.delegation.button.ship
            </Typo.ButtonBlack>
          </TouchableOpacity>
          <TouchableOpacity
            disabled={disabledButtons}
            onPress={() => onClickButton(DECISION_TYPES.DELEGATE)}
            style={styles.button}
          >
            <Image
              style={styles.buttonIconStyle}
              source={require('../../../assets/challenges/delegation/button-family-icon.png')}
            />
            <Typo.ButtonBlack center translate>
              challenge.delegation.button.let_family_decide
            </Typo.ButtonBlack>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  )

  // return (
  //   <>
  //     <View
  //       style={[
  //         styles.container,
  //         borderStyle,
  //         (testRoundType === TEST_ROUND_TYPES.PRIVATE ||
  //           testRoundType === TEST_ROUND_TYPES.FAMILY) &&
  //           testRound && { opacity: 0.4 }
  //       ]}
  //     >
  //       <Header
  //         balance={balances.me}
  //         currentRound={currentRoundIndex + 1}
  //         roundsLength={questions.current[currentBlockIndex].length}
  //         currentBlock={currentBlockIndex + 1}
  //         blocksLength={questions.current.length}
  //         testRound={testRound}
  //       />
  //       {type === JOB_TYPES.SMALL ? (
  //         <View style={{ alignItems: 'center', paddingHorizontal: 20 }}>
  //           <Typo.H1White>Privater Auftrag:</Typo.H1White>
  //           <Typo.T1LightWhite center>
  //             Das Ergebnis dieser Reise betrifft <Typo.T1White>nur dich</Typo.T1White>.
  //           </Typo.T1LightWhite>
  //         </View>
  //       ) : (
  //         <View style={{ alignItems: 'center', paddingHorizontal: 20 }}>
  //           <Typo.H1White>Familienauftrag:</Typo.H1White>
  //           <Typo.T1LightWhite>
  //             Ihr <Typo.T1White>teilt</Typo.T1White> das Ergebnis dieser Reise{' '}
  //             <Typo.T1White>durch vier</Typo.T1White>.
  //           </Typo.T1LightWhite>
  //         </View>
  //       )}
  //       <View style={{ flex: 1, justifyContent: 'center' }}>
  //         <View style={[rowCenterEvenly, { marginBottom: 32 }]}>
  //           <Typo.H2White style={{ color: '#34c463', fontSize: 17 }}>{`${
  //             currentQuestion.win * factor
  //           } EUR Gewinn`}</Typo.H2White>
  //           <Typo.T1White>oder</Typo.T1White>
  //           <Typo.H2White style={{ color: '#ff6f6f', fontSize: 17 }}>{`${
  //             currentQuestion.loss * factor * -1
  //           } EUR Verlust`}</Typo.H2White>
  //         </View>
  //         <PieChart
  //           padAngle={0}
  //           innerRadius={0}
  //           style={{
  //             ...metrics.calculateResponsiveDimensions({ width: 150, height: 150 }),
  //             alignSelf: 'center'
  //           }}
  //           data={pieData}
  //         >
  //           <Labels />
  //         </PieChart>
  //         <View style={[rowCenterEvenly, { marginTop: 32 }]}>
  //           <Typo.H3White center style={{ color: '#2ee1d6' }}>{`${
  //             currentQuestion.showgain * 100
  //           }% Erfolg`}</Typo.H3White>
  //           <Typo.H3White center style={{ color: '#ffad6f' }}>{`${
  //             currentQuestion.showloss * 100
  //           }% Misserfolg`}</Typo.H3White>
  //           <Typo.H3White center style={{ color: '#DDD' }}>{`${
  //             currentQuestion.showgrey * 100
  //           }% Ungewiss`}</Typo.H3White>
  //         </View>
  //       </View>
  //       <View style={styles.footer}>
  //         <TouchableOpacity
  //           onPress={() => onClickButton(DECISION_TYPES.DONT_SHIP)}
  //           style={styles.button}
  //         >
  //           <FontAwesome5 name='times-circle' size={40} color={colors.primaryBlue} />
  //           <Typo.ButtonBlue marginTop={6} center style={{ fontSize: 12 }}>
  //             Im Hafen bleiben
  //           </Typo.ButtonBlue>
  //         </TouchableOpacity>
  //         <TouchableOpacity
  //           onPress={() => onClickButton(DECISION_TYPES.DELEGATE)}
  //           style={styles.button}
  //         >
  //           <FontAwesome5 name='users' size={40} color={colors.primaryBlue} />
  //           <Typo.ButtonBlue marginTop={6} center style={{ fontSize: 12 }}>
  //             Entscheidung abgeben
  //           </Typo.ButtonBlue>
  //         </TouchableOpacity>
  //         <TouchableOpacity
  //           onPress={() => onClickButton(DECISION_TYPES.SHIP)}
  //           style={styles.button}
  //         >
  //           <FontAwesome5 name='ship' size={40} color={colors.primaryBlue} />
  //           <Typo.ButtonBlue marginTop={6} center style={{ fontSize: 12 }}>
  //             Die Reise antreten
  //           </Typo.ButtonBlue>
  //         </TouchableOpacity>
  //       </View>
  //     </View>
  //     {testRoundType === TEST_ROUND_TYPES.PRIVATE && testRound && (
  //       <View style={{ position: 'absolute', paddingHorizontal: 24, bottom: 24 }}>
  //         <View
  //           style={{
  //             backgroundColor: '#fff',
  //             marginBottom: 8,
  //             padding: 12,
  //             borderRadius: 8,
  //             borderWidth: 1,
  //             borderColor: '#000'
  //           }}
  //         >
  //           <Typo.T1Black>
  //             Die Hintergrundfarbe und der Titel zeigen an, dass es sich um einen privaten Auftrag
  //             handelt. Denk daran, dass deine Entscheidung bei diesen Versuchen nur dich selbst
  //             betrifft. Kein Gruppenmitglied wird durch dein Handeln beeinflusst.{' '}
  //           </Typo.T1Black>
  //           <Typo.T1Black marginTop={16}>
  //             Jeglicher Gewinn oder Verlust, der sich aus deiner Entscheidung ergibt, wird nur zu
  //             deinem Vermögen hinzugefügt oder abgezogen.
  //           </Typo.T1Black>
  //         </View>
  //         <Button
  //           onPress={() => setTestRoundType(null)}
  //           color={colors.primaryBlue}
  //           mode='contained'
  //         >
  //           Verstanden
  //         </Button>
  //       </View>
  //     )}
  //     {testRoundType === TEST_ROUND_TYPES.FAMILY && testRound && (
  //       <View style={{ position: 'absolute', paddingHorizontal: 24, bottom: 24 }}>
  //         <View
  //           style={{
  //             backgroundColor: '#fff',
  //             marginBottom: 8,
  //             padding: 12,
  //             borderRadius: 8,
  //             borderWidth: 1,
  //             borderColor: '#000'
  //           }}
  //         >
  //           <Typo.T1Black>
  //             Die Hintergrundfarbe und der Titel zeigen an, dass es sich um einen Familienauftrag
  //             handelt. Denk daran, dass du bei diesen Aufträgen für deine gesamte Familie
  //             verantwortlich bist und dass deine Entscheidung direkte Auswirkungen auf die Bezahlung
  //             jedes einzelnen Familienmitglieds hat.
  //           </Typo.T1Black>
  //           <Typo.T1Black marginTop={16}>
  //             Jeder Gewinn oder Verlust, der sich aus Ihrer Entscheidung ergibt, wird direkt zum
  //             Vermögen jedes einzelnen Familienmitglieds (einschließlich dir selbst) hinzugefügt
  //             oder abgezogen.
  //           </Typo.T1Black>
  //         </View>
  //         <Button
  //           onPress={() => setTestRoundType(null)}
  //           color={colors.primaryBlue}
  //           mode='contained'
  //         >
  //           Verstanden
  //         </Button>
  //       </View>
  //     )}
  //   </>
  // )
}

Delegation.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

// const InstructionScreen = ({ nextScreen, exam }) => (
//   <Instruction onPress={() => nextScreen()} exam={exam} />
// )

const FirstGameIntroScreen = ({ nextScreen, exam, prevScreen }) => (
  <FirstIntro onPress={nextScreen} onPressBack={prevScreen} exam={exam} />
)

const SecondGameIntroScreen = ({ nextScreen, exam, prevScreen }) => (
  <SecondIntro onPress={nextScreen} exam={exam} onPressBack={prevScreen} />
)

const ThirdGameIntroScreen = ({ nextScreen, exam, prevScreen }) => (
  <ThirdIntro onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

const FourthGameIntroScreen = ({ nextScreen, exam, prevScreen }) => (
  <FourthIntro onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

const DelegationChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={Delegation}
    EXAM={ALL_CHALLENGES.DELEGATION}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      // { screen: InstructionScreen, name: 'InstructionScreen' }
      { screen: FirstGameIntroScreen, name: 'FirstGameIntroScreen' },
      { screen: SecondGameIntroScreen, name: 'SecondGameIntroScreen' },
      { screen: ThirdGameIntroScreen, name: 'ThirdGameIntroScreen' },
      { screen: FourthGameIntroScreen, name: 'FourthGameIntroScreen' }
    ]}
    MIDDLE_SCREENS={[{ screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' }]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

DelegationChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

DelegationChallenge.defaultProps = { onChallengeComplete: () => {} }

export default DelegationChallenge
