import HEROS_JOURNEY from './heros_journey/HerosJourney'
import EMOTIONS_GAME from './emotions_game/EmotionsGame'
import BIG_FIVE from './big_five/BigFive'
import TOWER_OF_LONDON from './tower_of_london'
import RIASEC_IMAGES from './riasec_images/RiasecImages' // eslint-disable-line
import PATH_FINDER from './path_finder/PathFinder'
import ANALOGICAL_REASONING from './analogical_reasoning'
import DIGIT_SPAN from './digit_span'
import FLANKER from './flanker'
import NBACK from './nback'
import DELEGATION from './delegation'
import NUMERACY from './numeracy'

export const CHALLENGE_SCREENS = {
  NBACK,
  HEROS_JOURNEY,
  EMOTIONS_GAME,
  TOWER_OF_LONDON,
  BIG_FIVE,
  RIASEC_IMAGES,
  PATH_FINDER,
  ANALOGICAL_REASONING,
  DIGIT_SPAN,
  FLANKER,
  DELEGATION,
  NUMERACY
}
