import { getInitialURL, useURL } from 'expo-linking'
import { useState, useEffect, useRef } from 'react'

const FALLBACK_URL = 'https://webapp.aivy.app'

// see (more or less) https://github.com/expo/expo/issues/23333
export const useBetterURL = () => {
  const url = useURL()
  const first = useRef(true)
  const [urlState, setUrlState] = useState(undefined)

  useEffect(() => {
    if (first.current) {
      getInitialURL().then((res) => setUrlState(res || FALLBACK_URL))

      first.current = false
      return
    }

    // detect url changes via use url hook
    if (url) setUrlState(url)
  }, [url])

  return urlState
}
