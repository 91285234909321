import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'

import Typo from '../Typo'
// import PauseButton from './PauseButton'

// const Separator = ({ backgroundColor }) => {
//  let color = Colors.white
//  if (backgroundColor === Colors.lightPaleGrey) {
//    color = '#DCE5FB'
//  } else if (backgroundColor === Colors.darkBlack) {
//    color = '#373E50'
//  }
//  return <View style={{ height: 14, width: 1, backgroundColor: color }} />
// }

const s = StyleSheet.create({
  container: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  left: {
    position: 'absolute',
    left: 16,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  right: {
    position: 'absolute',
    right: 16,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'flex-end'
  }
})

const ChallengeHeader = ({ title, leftHeader, titleStyle }) => (
  <View style={s.container}>
    {leftHeader && <View style={s.left}>{leftHeader}</View>}
    <Typo.ButtonBlue style={titleStyle} center>
      {title}
    </Typo.ButtonBlue>
    {/* <View style={s.right}>
      <PauseButton onPress={onPress} />
    </View> */}
  </View>
)

ChallengeHeader.propTypes = {
  // onPress: PropTypes.func.isRequired,
  title: PropTypes.string,
  leftHeader: PropTypes.node,
  titleStyle: PropTypes.object
}

ChallengeHeader.defaultProps = {
  title: '',
  leftHeader: undefined,
  titleStyle: {}
}

export default ChallengeHeader
