import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '@expo/vector-icons'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { I18n } from 'aws-amplify'
import { Button, Typo } from '../../../../components'
import colors from '../../../../theme/colors'
import metrics from '../../../../theme/metrics'

const SecondIntro = ({ onPress }) => {
  const insets = useSafeAreaInsets()
  return (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 20 + insets.top,
        backgroundColor: colors.softBlue
      }}
    >
      <View style={{ flex: 1 }}>
        <Typo.H1White translate>challenge.delegation.secondintro.title</Typo.H1White>
        <Typo.T1White marginTop={24} translate>
          challenge.delegation.secondintro.description
        </Typo.T1White>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',

            marginTop: 32
          }}
        >
          <FontAwesome5 name='box' size={48} color='#fff' />
          <View style={{ width: metrics.widthPercentageToDP('60') }}>
            <Typo.T1White translate>
              challenge.delegation.secondintro.private.description
            </Typo.T1White>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: 16
          }}
        >
          <FontAwesome5 name='boxes' size={48} color='#fff' />
          <View style={{ width: metrics.widthPercentageToDP('60') }}>
            <Typo.T1White translate>
              challenge.delegation.secondintro.family.description
            </Typo.T1White>
          </View>
        </View>
      </View>

      <View style={{ paddingBottom: 24 + insets.bottom }}>
        <Button
          onPress={onPress}
          type='white'
          title='challenge.delegation.secondintro.button'
          marginHorizontal={4}
        />
      </View>
    </View>
  )
}

SecondIntro.propTypes = {
  onPress: PropTypes.func.isRequired
}

SecondIntro.defaultProps = {}

export default SecondIntro
