import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'
import { Loader, ModalHeader, Typo } from '../../../../components'
import { getMarkdown } from '../../../../graphql'
import { AuthContext, NotificationContext } from '../../../../context'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  }
})

const MarkdownScreen = ({ navigation, route }) => {
  const { title, endpoint } = route.params
  const [isLoading, setIsLoading] = useState(true)
  const [markdown, setMarkdown] = useState('')
  const { error } = useContext(NotificationContext)
  const { rootStore } = useContext(AuthContext)

  useEffect(() => {
    const getMD = async () => {
      const response = await getMarkdown(endpoint)
      if (response.error) {
        error(`Fehler beim Laden der ${I18n.get(title)}`)
        return
      }

      // replace response
      const regex = /\[\[\[UNTERNEHMEN\]\]\]/g
      setMarkdown(response.replace(regex, rootStore.appSettingsStore.partnerName))

      setIsLoading(false)
    }
    getMD()
  }, [endpoint, error, rootStore.appSettingsStore.partnerName, title])

  return (
    <View style={styles.container}>
      <ModalHeader headerTitle={title} onPress={() => navigation.goBack()} withBackButton />
      {isLoading && <Loader />}
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{ padding: 15, paddingTop: 30 }}>
        <Typo.Markdown>{markdown}</Typo.Markdown>
      </ScrollView>
    </View>
  )
}

MarkdownScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
}

export default MarkdownScreen
