/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import { StyleSheet, View, Animated, TouchableOpacity, Easing, SafeAreaView } from 'react-native'
import { isEqual, cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import { I18n } from 'aws-amplify'
import Constants from 'expo-constants'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { StatusBar } from 'expo-status-bar'

import Pegs from './components/Pegs'
import LottieAnimation from '../../../components/challenge/LottieAnimation'
import metrics from '../../../theme/metrics'
import ChallengeHeader from '../../../components/challenge/ChallengeHeader'
import { trackEvent } from '../../../utils/tracking'
import TimeIcon from '../../../components/svgs/TimeIcon'
import CountDownTimer from '../../../components/challenge/CountDownTimer'
import { Loader, Typo } from '../../../components'
import colors from '../../../theme/colors'

import {
  ANIMATION_DURATION,
  LEVELS,
  PEGS_WIDTH,
  STONE_DIMENSIONS,
  TEST_LEVELS,
  TIME_TO_COMPLETE,
  getSourceForStone
} from './data'
import GoalState from './components/GoalState'
import { AuthContext } from '../../../context'
import ChallengeContainer from '../ChallengeContainer'
import { ALL_CHALLENGES } from '../../../constants/challenges'
import ChallengeResultWrapper from '../ChallengeResultWrapper'
import TestRoundComplete from '../../../components/challenge/TestRoundComplete'
import Instruction from '../../../components/challenge/Instruction'
import Intro from '../../../components/challenge/Intro'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFBFC',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  header: {
    width: '100%',
    zIndex: 2,
    position: 'absolute',
    top: Constants.statusBarHeight,
    left: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  windowView: {
    zIndex: 1,
    position: 'absolute',
    backgroundColor: '#222',
    opacity: 0.6
  },
  tutorialButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3399FF',
    width: 100,
    height: 60,
    borderRadius: 50,
    marginHorizontal: 10
  },
  tutorialButtonText: {
    color: '#FFF',
    fontSize: 25
  },
  congatsText: {
    zIndex: 2,
    fontSize: 40,
    color: '#3399FF',
    padding: 100
  },
  tower: {
    width: 18,
    backgroundColor: colors.paleBlue,
    alignSelf: 'flex-end'
  },
  separator: {
    marginHorizontal: 15,
    width: 1,
    height: 14,
    backgroundColor: 'rgba(77, 123, 243, 0.17)'
  }
})

const { screenWidth } = metrics
const { screenHeight } = metrics

const baseWidth = screenWidth / 4
const baseHeight = screenHeight / 8
const pegHeight = metrics.calculateResponsiveDimensions({ height: 208, width: PEGS_WIDTH }).height
const pegTop = screenHeight * 0.6
const pegXVals = [0.2 * screenWidth, 0.5 * screenWidth, 0.8 * screenWidth]
const baseYVal = pegTop + pegHeight

const TowerOfLondon = ({ testRound, submit }) => {
  const { rootStore } = useContext(AuthContext)
  const GAME_LEVELS = testRound ? TEST_LEVELS : LEVELS
  const [round, setRound] = useState(0)
  const currentRoundOutcome = Object.values(GAME_LEVELS[round]?.outcome)
  const [submitting, setSubmitting] = useState(false)

  const currentRoundOutcomeReversed = useMemo(() => {
    const newArr = Object.values(cloneDeep(GAME_LEVELS[round].outcome))
    return [[...newArr[0].reverse()], [...newArr[1].reverse()], [...newArr[2].reverse()]]
  }, [round])

  const currentRoundInitialState = GAME_LEVELS[round].towers

  const [discs, setDiscs] = useState([[], [], []])
  const [lifted, setLifted] = useState(null)
  const [blocked, setBlocked] = useState(false)
  const [redFlash, setRedFlash] = useState(-1)
  const [tutorialFlash, setTutorialFlash] = useState(-1)
  const [won, setWon] = useState(false)
  const [lost, setLost] = useState(false)

  const [numMoves, setNumMoves] = useState(0)
  const answers = useRef([])
  const prevPauseIsActive = useRef(rootStore.pauseStore.active)

  const t0_time_planning = useRef(null) // useRef prevent reinit
  const t1_time_planning = useRef(null) // useRef prevent reinit

  const t0_time_solving = useRef(null)
  const t1_time_solving = useRef(null)

  const countDownTimer = useRef(null)

  useEffect(() => {
    t0_time_planning.current = new Date()
    // eslint-disable-next-line no-use-before-define
    createDiscs()
  }, [round])

  /**
   * Handle Tutorial Hint
   */
  useEffect(() => {
    if (testRound && (round === 0 || round === 1)) {
      setTimeout(() => {
        setTutorialFlash(0)
        setTimeout(() => setTutorialFlash(-1), 1000)
      }, 1250)
    }
  }, [discs])

  useEffect(() => {
    if (numMoves === 1) {
      t1_time_planning.current = new Date() // End Tracking Time Planning
      t0_time_solving.current = new Date() // Start Tracking Time Solving
    }
  }, [numMoves])

  useEffect(() => {
    if (
      rootStore.pauseStore.active === true &&
      prevPauseIsActive.current === false &&
      countDownTimer?.current
    ) {
      countDownTimer.current.pauseTimer()
    }
    if (
      rootStore.pauseStore.active === false &&
      prevPauseIsActive.current === true &&
      countDownTimer?.current
    ) {
      countDownTimer.current.resumeTimer()
    }
    prevPauseIsActive.current = rootStore.pauseStore.active
  }, [rootStore.pauseStore.active])

  const concatAnswer = (success, finish) => {
    const time_planning =
      t1_time_planning.current !== null
        ? t1_time_planning.current - t0_time_planning.current
        : TIME_TO_COMPLETE * 1000
    const levelData = {
      time_planning,
      time_solving:
        success === true
          ? t1_time_solving.current - t0_time_solving.current
          : TIME_TO_COMPLETE * 1000 - (time_planning > 60000 ? 60000 : time_planning),
      pause_in_ms: rootStore.pauseStore.sum,
      draws: numMoves,
      success,
      finish,
      name: GAME_LEVELS[round].name,
      min_required_draws: GAME_LEVELS[round].min_required_draws
    }

    answers.current = answers.current.concat([levelData])
  }

  const flash = (index) => {
    /*
        flash(index) will make the Touchable Opacity of the provided index flash red for a short period of time
        to indicate that an illegal move has been attempted.
        */
    setRedFlash(index)
    setTimeout(() => setRedFlash(-1), 300)
  }

  const nextRound = () => {
    setLifted(null)
    setNumMoves(0)
    // eslint-disable-next-line no-shadow
    setRound((round) => round + 1)

    t0_time_solving.current = null
    t1_time_solving.current = null
    t1_time_planning.current = null
    if (countDownTimer?.current) {
      countDownTimer.current.resetTimer(TIME_TO_COMPLETE)
    }
    rootStore.pauseStore.reset()
  }

  const doSubmit = () => {
    setSubmitting(true)
    submit(
      answers.current.reduce((resultShadow, answer, idx) => {
        resultShadow[`level_${idx + 1}`] = answer
        return resultShadow
      }, {})
    )
  }

  const checkIfSubmitIsReady = () => {
    if ((testRound && round === 1) || round === GAME_LEVELS.length - 1) {
      doSubmit()
      return
    }

    nextRound()
  }

  const checkWon = () => {
    const mappedDisc = discs.map((stack) => stack.map((disc) => disc.id))
    if (isEqual(mappedDisc, currentRoundOutcome)) {
      setWon(true)
      t1_time_solving.current = new Date()
      const success = numMoves === GAME_LEVELS[round].min_required_draws
      concatAnswer(success, true)
      setTimeout(() => {
        setWon(false)

        checkIfSubmitIsReady()
      }, 2000)
    }
  }

  const getBackgroundColorForHint = (index) => {
    if (redFlash === index) {
      return colors.carnationError
    }
    if (tutorialFlash === index) {
      return colors.primaryBlue
    }
    return 'transparent'
  }

  const drop = (stackIndex) => {
    const liftedDisc = discs[lifted][discs[lifted].length - 1]
    const topDisc = discs[stackIndex][discs[stackIndex].length - (1 + (lifted === stackIndex))]

    const isIllegalMove =
      (stackIndex === 2 && discs[stackIndex].length === 1) ||
      (stackIndex === 1 && discs[stackIndex].length === 2)

    const isUndoMove =
      discs[stackIndex].length > 0 &&
      liftedDisc.id === discs[stackIndex][discs[stackIndex].length - 1].id

    if (isUndoMove) {
      Animated.timing(liftedDisc.position, {
        toValue: {
          x: pegXVals[stackIndex] - STONE_DIMENSIONS.width / 2,
          y: baseYVal - STONE_DIMENSIONS.height * discs[stackIndex].length
        },
        easing: Easing.ease,
        duration: ANIMATION_DURATION,
        useNativeDriver: false
      }).start(() => {
        setLifted(null)
        setBlocked(false)
      })
      return
    }

    if (isIllegalMove) {
      flash(stackIndex)
      setBlocked(false)
      return
    }

    discs[stackIndex].push(liftedDisc)
    discs[lifted].pop()

    Animated.timing(liftedDisc.position, {
      toValue: {
        x: pegXVals[stackIndex] - STONE_DIMENSIONS.width / 2,
        y: pegTop
      },
      easing: Easing.ease,
      duration: ANIMATION_DURATION,
      useNativeDriver: false
    }).start(() => {
      const targetY =
        (topDisc ? parseFloat(JSON.stringify(topDisc.position.getLayout().top)) : baseYVal) -
        STONE_DIMENSIONS.height

      Animated.timing(liftedDisc.position, {
        toValue: { x: pegXVals[stackIndex] - STONE_DIMENSIONS.width / 2, y: targetY },
        easing: Easing.ease,
        duration: ANIMATION_DURATION,
        useNativeDriver: false
      }).start(() => {
        checkWon()
        setLifted(null)
        setBlocked(false)
      })
    })
  }

  const lift = (stackIndex) => {
    const isStackEmpty = discs[stackIndex].length === 0

    if (isStackEmpty) {
      flash(stackIndex)
      setBlocked(false)
      return
    }

    // eslint-disable-next-line no-shadow
    setNumMoves((numMoves) => numMoves + 1)
    const disc = discs[stackIndex][discs[stackIndex].length - 1]

    setLifted(stackIndex)
    Animated.timing(disc.position, {
      toValue: {
        x: pegXVals[stackIndex] - STONE_DIMENSIONS.width / 2,
        y: pegTop
      },
      easing: Easing.ease,
      duration: ANIMATION_DURATION,
      useNativeDriver: false
    }).start(() => {
      setBlocked(false)
    })
  }

  const createDiscs = () => {
    const initState = Object.values(currentRoundInitialState)

    const newDiscs = initState.map((stack, stackIndex) =>
      stack.map((disc, discIndex) => ({
        id: disc,
        // eslint-disable-next-line no-nested-ternary
        color: disc === 0 ? '#5786FF' : disc === 2 ? '#FE79A7' : disc === 3 ? '#FFB628' : '#000',
        position: new Animated.ValueXY({
          x: pegXVals[stackIndex] - STONE_DIMENSIONS.width / 2,
          y: baseYVal - STONE_DIMENSIONS.height * (discIndex + 1)
        })
      }))
    )
    setDiscs(newDiscs)
  }

  const renderDiscs = () =>
    /*
        renderDiscs() returns Animated.Views to represent the discs. It maps the objects in the discs state to Animated.Views
        with the height, width, color, and position contained in the object.
        */

    discs.map((stack) =>
      stack.map((disc) => (
        <Animated.Image
          key={`${stack}-${disc}-disc-${disc.id}`}
          source={getSourceForStone(disc.id)}
          style={{
            position: 'absolute',
            opacity: won || lost ? 0.3 : 1,
            width: STONE_DIMENSIONS.width,
            height: STONE_DIMENSIONS.height,

            ...disc.position.getLayout()
          }}
        />
      ))
    )

  const countDownTimerOver = () => {
    if (won === true || lost === true) return
    setLost(true)
    concatAnswer(false, false)
    if (round !== GAME_LEVELS - 1) {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        countDownTimer.current && countDownTimer.current.resetTimer(TIME_TO_COMPLETE)
      }, 80)
    }

    setTimeout(() => {
      setLost(false)

      checkIfSubmitIsReady()
    }, 2000)
  }
  const renderLeftHeader = () => {
    if (testRound) return null
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TimeIcon containerStyle={{ marginRight: 4 }} color={colors.primaryBlue} />
        <CountDownTimer
          ref={countDownTimer}
          timeToShow={['M', 'S']}
          labelM=''
          labelS=''
          digitBgColor={colors.mainColor}
          until={TIME_TO_COMPLETE}
          digitTxtColor={colors.primaryBlue}
          onFinish={countDownTimerOver}
          size={14}
        />
        <View style={styles.separator} />
        <Typo.ButtonBlue center>{`${round + 1}/${GAME_LEVELS.length}`}</Typo.ButtonBlue>
        <View style={styles.separator} />
        <Typo.ButtonBlue>
          {`${I18n.get('challenge.tower_of_london.moves')}: ${numMoves}`}
        </Typo.ButtonBlue>
      </View>
    )
  }

  if (submitting) {
    return <Loader containerStyle={{ backgroundColor: colors.lightPaleGrey }} />
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.lightPaleGrey }}>
      <StatusBar animated hidden />
      <ChallengeHeader
        title={testRound ? I18n.get('global.challenge.testround') : ''}
        onPress={() => {
          trackEvent('ChallengePaused', { exam_id: 'TOWER_OF_LONDON' })
          rootStore.pauseStore.start()
        }}
        leftHeader={renderLeftHeader()}
      />
      <GoalState
        containerStyle={{ marginTop: 32 }}
        currentLevelOutcome={currentRoundOutcomeReversed}
        minRequiredDraws={GAME_LEVELS[round].min_required_draws}
        won={won}
        lost={lost}
      />

      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          opacity: won || lost ? 0.3 : 1
        }}
      >
        <Pegs positions={pegXVals} pegTop={pegTop} />
      </View>
      {renderDiscs()}
      {pegXVals.map((pegX, index) => (
        <TouchableOpacity
          disabled={won || lost}
          onPress={() => {
            if (blocked) return

            if (lifted === null) {
              setBlocked(true)
              lift(index)
            } else {
              setBlocked(true)
              drop(index)
            }
          }}
          key={`peg-${pegX}`}
          style={{
            position: 'absolute',
            width: baseWidth,
            height: pegHeight + 32,
            top: pegTop - 16,
            left: pegX - baseWidth / 2,
            backgroundColor: getBackgroundColorForHint(index),
            opacity: 0.4,
            borderRadius: baseHeight / 4,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {tutorialFlash === index && (
            <MaterialCommunityIcons name='gesture-tap' size={48} color='#fff' />
          )}
        </TouchableOpacity>
      ))}
      {won && (
        <LottieAnimation
          loop
          autoplay
          width='100%'
          height={300}
          animationData={require('../../../assets/challenges/tower-of-london/game-assets/animation.json')}
          style={{ overflow: 'hidden', marginHorizontal: 'auto', position: 'absolute' }}
        />
      )}
    </SafeAreaView>
  )
}

TowerOfLondon.propTypes = {
  testRound: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
}

const IntroScreen = ({ nextScreen, exam }) => <Intro onPress={nextScreen} exam={exam} />

IntroScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const InstructionScreen = ({ nextScreen, exam }) => (
  <Instruction onPress={() => nextScreen()} exam={exam} />
)

InstructionScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const TestRoundCompleteScreen = ({ nextScreen, exam, prevScreen }) => (
  <TestRoundComplete onPress={() => nextScreen()} exam={exam} onPressBack={prevScreen} />
)

TestRoundCompleteScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

const ResultScreen = ({ nextScreen, exam, answer }) => (
  <ChallengeResultWrapper onPress={() => nextScreen()} exam={exam} answer={answer} />
)

ResultScreen.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
}

const TowerOfLondonChallenge = ({ onChallengeComplete }) => (
  <ChallengeContainer
    CHALLENGE={TowerOfLondon}
    EXAM={ALL_CHALLENGES.TOWER_OF_LONDON}
    PRE_SCREENS={[
      { screen: IntroScreen, name: 'IntroScreen' },
      { screen: InstructionScreen, name: 'InstructionScreen' }
    ]}
    MIDDLE_SCREENS={[{ screen: TestRoundCompleteScreen, name: 'TestRoundCompleteScreen' }]}
    POST_SCREENS={[{ screen: ResultScreen, name: 'ResultScreen' }]}
    onChallengeComplete={onChallengeComplete}
  />
)

TowerOfLondonChallenge.propTypes = {
  onChallengeComplete: PropTypes.func
}

TowerOfLondonChallenge.defaultProps = { onChallengeComplete: () => {} }

export default TowerOfLondonChallenge
