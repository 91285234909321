import React, { useState, useEffect, useRef } from 'react'
import { View, StyleSheet, StatusBar, Image, TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import PropTypes from 'prop-types'

import colors from '../../theme/colors'
import Typo from '../Typo'
import metrics from '../../theme/metrics'
import Button from '../Button'
import { trackEvent } from '../../utils/tracking'
import asyncStorage from '../../utils/async-storage'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  }
})

const TestRoundComplete = ({ onPress, onPressBack, exam }) => {
  const insets = useSafeAreaInsets()
  const [isRetryButtonVisible, setIsRetryButtonVisible] = useState(false)
  const currentCount = useRef(0)

  const { exam_id } = exam
  const storageKey = `${exam_id}_retry_count`

  useEffect(() => {
    asyncStorage.getData(storageKey).then((count) => {
      if (count !== null) {
        currentCount.current = count
      }
      if (count === null || count < 3) {
        setIsRetryButtonVisible(true)
      }
    })
  }, [storageKey])

  return (
    <View style={styles.container}>
      <StatusBar barStyle='dark-content' />
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Typo.H2Blue translate>global.challenge.testround_modal.title</Typo.H2Blue>
        <Image
          source={require('../../assets/challenges/testround.png')}
          resizeMode='contain'
          style={{
            marginVertical: 32,
            ...metrics.calculateResponsiveDimensions({ width: 256, height: 256 })
          }}
        />
        <Typo.T1LightBlack translate>global.challenge.testround_modal.text</Typo.T1LightBlack>
      </View>
      <View style={{ paddingBottom: insets.bottom + 24, paddingHorizontal: 20 }}>
        <Button title='global.challenge.motivated_button' onPress={onPress} />
        {isRetryButtonVisible && (
          <TouchableOpacity
            style={{ alignItems: 'center', marginTop: 24 }}
            onPress={() => {
              asyncStorage.storeData(storageKey, currentCount.current + 1)
              trackEvent('RetryTestround')
              onPressBack()
            }}
          >
            <Typo.ButtonBlue translate>challenge.global.retryTestround</Typo.ButtonBlue>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

TestRoundComplete.propTypes = {
  onPress: PropTypes.func.isRequired,
  onPressBack: PropTypes.func.isRequired,
  exam: PropTypes.object.isRequired
}

export default TestRoundComplete
