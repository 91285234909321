import React from 'react'
import { View, Image, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { I18n } from 'aws-amplify'
import { Button, Typo } from '../../../../components'
import metrics from '../../../../theme/metrics'
import colors from '../../../../theme/colors'

const FourthIntro = ({ onPress, onPressBack }) => {
  const insets = useSafeAreaInsets()

  return (
    <View
      style={{
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 20 + insets.top,
        backgroundColor: colors.softBlue
      }}
    >
      <View style={{ flex: 1 }}>
        <Typo.H1White translate>challenge.delegation.fourthintro.title</Typo.H1White>

        <Image
          source={require('../../../../assets/challenges/delegation/family-mood-happy.png')}
          style={{
            marginVertical: 32,
            alignSelf: 'center',
            ...metrics.calculateResponsiveDimensions({ height: 155, width: 260 })
          }}
        />
        <Typo.T1White marginTop={8} translate>
          challenge.delegation.fourthintro.description
        </Typo.T1White>
      </View>
      <View style={{ paddingBottom: 24 + insets.bottom }}>
        <Button
          marginHorizontal={12}
          onPress={onPress}
          type='white'
          title='challenge.delegation.fourthintro.button'
        />
        <TouchableOpacity style={{ alignItems: 'center', marginTop: 24 }} onPress={onPressBack}>
          <Typo.ButtonWhite>{I18n.get('global.goback')}</Typo.ButtonWhite>
        </TouchableOpacity>
      </View>
    </View>
  )
}

FourthIntro.propTypes = {
  onPress: PropTypes.func.isRequired,
  onPressBack: PropTypes.func.isRequired
}

FourthIntro.defaultProps = {}

export default FourthIntro
