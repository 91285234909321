import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'

const ChallengeSubScreen = ({ EXAM, answer, Component, nextScreen, prevScreen }) => {
  if (!Component || typeof Component === 'string') {
    return null
  }

  return (
    <View style={{ flex: 1 }}>
      <Component
        exam={EXAM}
        nextScreen={() => nextScreen()}
        answer={answer}
        prevScreen={() => prevScreen()}
      />
    </View>
  )
}

ChallengeSubScreen.propTypes = {
  EXAM: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  Component: PropTypes.func.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired
}

export default ChallengeSubScreen
