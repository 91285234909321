import React from 'react'
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import PropTypes from 'prop-types'

const styles = StyleSheet.create({
  container: {
    height: 72,
    width: 72,
    borderRadius: 12,
    backgroundColor: '#fff',
    shadowColor: '#252D42',
    shadowOpacity: 0.1,
    shadowRadius: 24,
    shadowOffset: { height: 4, width: 0 },
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const DigitSpanButton = ({
  imageSource,
  imageHeight,
  imageWidth,
  onPress,
  containerStyle,
  disabled
}) => (
  <TouchableOpacity onPress={onPress} style={containerStyle} disabled={disabled}>
    <View style={styles.container}>
      <Image source={imageSource} style={{ height: imageHeight, width: imageWidth }} />
    </View>
  </TouchableOpacity>
)

DigitSpanButton.propTypes = {
  imageSource: PropTypes.any.isRequired,
  imageHeight: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
  onPress: PropTypes.func.isRequired,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool.isRequired
}

DigitSpanButton.defaultProps = {
  containerStyle: {}
}

export default DigitSpanButton
