import { useEffect } from 'react'
import { Platform } from 'react-native'

export const useBeforeUnload = () => {
  useEffect(() => {
    const eventListener = (event) => {
      // cancel the event as stated by the standard
      event.preventDefault()
      // chrome requires returnValue to be set
      event.returnValue = ''
    }

    Platform.OS === 'web' && window.addEventListener('beforeunload', eventListener)

    return () => {
      Platform.OS === 'web' && window.removeEventListener('beforeunload', eventListener)
    }
  }, [])
}
