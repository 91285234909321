import React, { useContext } from 'react'
import { StyleSheet, View, Image } from 'react-native'
import _ from 'lodash'
import { I18n } from 'aws-amplify'

import { observer } from 'mobx-react'
import metrics from '../../../theme/metrics'
import Typo from '../../Typo'
import {
  HEROS,
  valueToName,
  getPositionForMatrix,
  getResultText
} from '../../../screens/challenges/heros_journey/data/heros-journey'
import colors from '../../../theme/colors'
import HerosJourneyMatrix from './components/HerosJourneyMatrix'
import { AuthContext } from '../../../context'
import { notifyBugsnag } from '../../../utils/bugsnag'

const s = StyleSheet.create({
  cardStyle: {
    marginTop: 36,
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 22,
    elevation: 8,
    shadowColor: '#B6C7F2',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5
  },
  verticalLine: {
    height: 240,
    width: 2,
    backgroundColor: colors.lightBlue,
    position: 'relative',
    alignSelf: 'center'
  },
  horizontalLine: {
    width: '100%',
    height: 2,
    backgroundColor: colors.lightBlue,
    position: 'absolute',
    top: '50%',
    shadowColor: colors.primaryBlue,
    shadowOffset: {
      width: 0,
      height: 8
    },
    shadowOpacity: 0.16,
    shadowRadius: 2,
    elevation: 5
  }
})

const HerosJourneyResult = () => {
  const { rootStore } = useContext(AuthContext)
  const role_final = rootStore.userStore.userProfile.hero || 'presenter'
  const role_suggestion = rootStore.userStore.userProfile.suggested_hero || 'presenter'

  const hero = _.find(HEROS, (o) => o.value === role_final)

  if (!hero) {
    notifyBugsnag(new Error('Hero is undefined'))
  }

  return (
    <View>
      <View style={s.cardStyle}>
        <Typo.H4Black translate>challenge.heros_journey.result.your_mission</Typo.H4Black>
        <Typo.T2Black marginTop={16} translate>
          {hero?.desc}
        </Typo.T2Black>
        <View style={{ paddingVertical: 24 }}>
          <Image
            source={hero?.imageLarge}
            resizeMode='contain'
            style={{
              width: metrics.widthPercentageToDP(45),
              height: metrics.widthPercentageToDP(45),
              alignSelf: 'center'
            }}
          />
        </View>
        <Typo.T2Black style={{ fontFamily: 'fira-sans-semi-bold' }}>
          {I18n.get(valueToName(role_final))}{' '}
          <Typo.T2Black translate>challenge.heros_journey.result.mission_extra</Typo.T2Black>
        </Typo.T2Black>
      </View>
      <HerosJourneyMatrix
        containerStyle={{ marginTop: 16 }}
        positionOfPoint={getPositionForMatrix(role_suggestion)}
        resultText={getResultText(role_suggestion)}
      />
    </View>
  )
}

export default observer(HerosJourneyResult)
