import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { View, StyleSheet, TextInput, Image, Platform } from 'react-native'
import PropTypes from 'prop-types'

import Typo from './Typo'
import colors from '../theme/colors'

const styles = StyleSheet.create({
  textInputStyle: {
    height: 40,
    borderBottomColor: colors.softGrey,
    borderBottomWidth: 1,
    fontSize: 16,
    fontFamily: 'fira-sans-regular',
    color: colors.darkBlack,
    paddingLeft: 12
  },
  checkIconStyle: {
    width: 15,
    height: 10,
    position: 'absolute',
    right: 8,
    bottom: 15
  },
  leftIcon: {
    width: 16,
    height: 16,
    position: 'absolute',
    left: 2,
    bottom: 12
  },
  clearIcon: {
    width: 16,
    height: 16,
    position: 'absolute',
    right: 8,
    bottom: 12
  }
})

const AivyTextInput = forwardRef(
  (
    {
      containerStyle,
      label,
      onChangeText,
      onBlur,
      onFocus,
      value,
      error,
      errorText,
      keyboardType,
      isValid,
      icon,
      autoComplete,
      autoFocus
    },
    ref
  ) => {
    const textInput = useRef()
    const [isFocused, setIsFocused] = useState(false)
    let borderBottomColor

    if (error) {
      borderBottomColor = colors.buttonRed
    } else if (isFocused) {
      borderBottomColor = colors.mediumGrey
    } else {
      borderBottomColor = colors.softGrey
    }

    useImperativeHandle(ref, () => ({
      blur: () => {
        // eslint-disable-next-line no-unused-expressions
        textInput.current && textInput.current.blur()
      }
    }))

    return (
      <View style={containerStyle}>
        <Typo.SmallTextBlack translate style={{ opacity: 0.5 }}>
          {label}
        </Typo.SmallTextBlack>
        <View>
          {icon && <Image style={styles.leftIcon} source={icon} />}
          <TextInput
            ref={textInput}
            style={[
              styles.textInputStyle,
              { borderBottomColor },
              icon ? { paddingLeft: 2 + 16 + 8 } : {},
              Platform.OS === 'web' && { outlineColor: colors.lightBlue }
            ]}
            selectionColor={colors.primaryBlue}
            autoCapitalize='none'
            autoCorrect={false}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            keyboardType={keyboardType}
            onChangeText={onChangeText}
            onFocus={() => {
              setIsFocused(true)
              onFocus()
            }}
            onBlur={() => {
              setIsFocused(false)
              onBlur()
            }}
            value={value}
          />
          {isValid && value.length > 0 && (
            <Image
              style={styles.checkIconStyle}
              source={require('../assets/images/check-icon.png')}
            />
          )}
        </View>

        {error && errorText && (
          <Typo.SmallTextBlack translate marginTop={4} style={{ color: colors.buttonRed }}>
            {errorText}
          </Typo.SmallTextBlack>
        )}
      </View>
    )
  }
)

AivyTextInput.propTypes = {
  containerStyle: PropTypes.object,
  label: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  keyboardType: PropTypes.string,
  isValid: PropTypes.bool,
  errorText: PropTypes.string,
  icon: PropTypes.any,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool
}

AivyTextInput.defaultProps = {
  containerStyle: {},
  error: false,
  errorText: '',
  onBlur: () => {},
  onFocus: () => {},
  keyboardType: 'default',
  isValid: false,
  icon: undefined,
  autoComplete: 'email',
  autoFocus: true
}

export default AivyTextInput
