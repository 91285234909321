/* eslint-disable camelcase */

import { query } from '../graphql'
import { AWS_AUTH_MODES } from './helpers'

export const initializeProfileData = async (user_id, rootStore) => {
  const res = await query({
    query: 'customUniversalAppInit',
    variables: { lang: 'de', ref_link: rootStore.userStore.initialLink, user_id },
    authMode: AWS_AUTH_MODES.AMAZON_COGNITO_USER_POOLS
  })

  const { answers, space } = res.data.universalAppInit

  const parsedAnswers = answers
    ? answers?.map((answer) => ({
        ...answer,
        aivy_output: JSON.parse(answer.aivy_output),
        user_input: JSON.parse(answer.user_input)
      }))
    : []

  rootStore.spaceStore.initSpace(space)
  rootStore.answersStore.setAnswers(parsedAnswers)
  rootStore.challengesStore.initChallenges(parsedAnswers, rootStore)
}
