import React from 'react'
import { View, StyleSheet, StatusBar } from 'react-native'

import * as Updates from 'expo-updates'

import Typo from './Typo'
import Button from './Button'
import metrics from '../theme/metrics'
import LottieAnimation from './challenge/LottieAnimation'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24
  }
})

const Offline = () => (
  <View style={styles.container}>
    <StatusBar barStyle='dark-content' />
    <LottieAnimation
      key='error'
      loop
      width={metrics.widthPercentageToDP(40)}
      height={metrics.heightPercentageToDP(30)}
      autoplay
      animationData={require('../assets/animations/no-internet.json')}
    />
    <Typo.H2Black translate center>
      global.offline_boundary.message_1
    </Typo.H2Black>
    <Typo.T1Black translate center style={{ marginTop: 16 }}>
      global.offline_boundary.message_2
    </Typo.T1Black>
    <Button
      onPress={() => Updates.reloadAsync()}
      containerStyle={{ marginTop: 64 }}
      title='global.offline_boundary.action'
    />
  </View>
)

export default Offline
