export const CAREER = `{
  id
  name
  photo_url
  logo
  title
  status
  archived
  category
  partner_id
  partner_name
  external_link
  redirect_url
  related_career
  R
  I
  A
  S
  E
  C
  is_cluster
  is_active
  app_settings {
    components
    challenges
    usedAlgorithm
    hello_text
    custom_data_policy
  }
  career_cluster_url
  forced_dimension
  challenge_config_id
  imported_from
  createdAt
  updatedAt
}`
