import React from 'react'
import { StyleSheet, View, TouchableHighlight, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'

import colors from '../../../../theme/colors'
import Typo from '../../../../components/Typo'

const styles = StyleSheet.create({
  item: {
    marginVertical: 5,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.primaryBlue,
    backgroundColor: 'white',
    shadowColor: '#E3E4E6',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.7,
    shadowRadius: 3.84,
    elevation: 5
  },
  itemInner: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  itemActive: {
    backgroundColor: colors.primaryBlue,
    borderColor: colors.primaryBlue
  },
  indicator: {
    textAlign: 'center',
    fontSize: 20,
    marginTop: 2,
    marginRight: 15
  },
  body: {
    flex: 1
  },

  tag: {
    borderColor: colors.primaryBlue,
    marginRight: 8
  }
})

const ButtonChoice = ({ onPress, label, active, style, labelTextStyle, disabled, noShadow }) => (
  <TouchableHighlight
    underlayColor={colors.primaryBlue}
    activeOpacity={1}
    onPress={onPress}
    disabled={disabled}
    style={[
      styles.item,
      {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 8
      },
      style,
      active ? styles.itemActive : {},
      noShadow ? { elevation: 0 } : {}
    ]}
  >
    <View>
      <Typo.H2LightBlue
        style={{ color: active ? 'white' : colors.darkBlue, ...labelTextStyle }}
        center
      >
        {label}
      </Typo.H2LightBlue>
    </View>
  </TouchableHighlight>
)

ButtonChoice.propTypes = {
  onPress: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  style: PropTypes.object.isRequired,
  labelTextStyle: PropTypes.object,
  disabled: PropTypes.bool,
  noShadow: PropTypes.bool
}

ButtonChoice.defaultProps = {
  active: undefined,
  labelTextStyle: {},
  disabled: undefined,
  noShadow: undefined
}

const RowChoice = ({ onPress, label, active, renderRight, style }) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, style, active ? styles.itemActive : {}]}>
    <View style={styles.itemInner}>
      <View style={styles.body}>
        <Typo.T1Black
          style={{ fontSize: 18, color: colors.sapphire }}
          color={active ? 'white' : null}
        >
          {label}
        </Typo.T1Black>
      </View>
      {renderRight && renderRight()}
    </View>
  </TouchableOpacity>
)

RowChoice.propTypes = {
  onPress: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  renderRight: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired
}

const TagChoice = ({ onPress, label, active, style }) => (
  <TouchableHighlight
    underlayColor='#e9e9e9'
    activeOpacity={0.8}
    onPress={onPress}
    style={[styles.item, styles.tag, style, active ? styles.itemActive : {}]}
  >
    <Typo.T1Black color={active ? 'white' : colors.primaryBlue}>{label}</Typo.T1Black>
  </TouchableHighlight>
)

TagChoice.propTypes = {
  onPress: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired
}

export default { Row: RowChoice, Button: ButtonChoice, Tag: TagChoice }
